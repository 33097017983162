import { Component, OnInit } from '@angular/core';
import { EnvioLocalService } from './service/envio.local.service';
import { Envio } from 'src/app/model/envio.model';
import { Router, ActivatedRoute } from '@angular/router';
import { EnvioBuilder } from 'src/app/builder/envio.builder';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { ElementoGrupoDominio } from 'src/app/model/elementogrupodominio.model';
import { ElementoGrupoDominioLocalService } from '../grupodominio/service/elementogrupodominio.local.service';
import { Regional } from 'src/app/model/regional.model';
import { Missao } from 'src/app/model/missao.model';
import { CasaComunitaria } from 'src/app/model/casacomunitaria.model';
import { Apostolado } from 'src/app/model/apostolado.model';
import { Pessoa } from 'src/app/model/pessoa.model';
import { RegionalService } from '../../wop/regional/service/regional.service';
import { MissaoService } from '../../wop/missao/service/missao.service';
import { CasaComunitariaService } from '../../wop/casacomunitaria/service/casacomunitaria.service';
import { ApostoladoService } from '../../wop/dadosvocacionais/service/apostolado.service';
import { PessoaBuilder } from 'src/app/builder/pessoa.builder';
import { PessoaService } from '../../wop/pessoa/service/pessoa.service';

@Component({
    selector: 'envio-local-edit',
    templateUrl: './envio.local.edit.component.html'
})
export class EnvioLocalEditComponent implements OnInit {

    public envio: Envio;
    public envioId: number;
    public regionais: Array<Regional>;
    public missoes: Array<Missao>;
    public casasComunitarias: Array<CasaComunitaria>;
    public apostolados: Array<Apostolado>;
    public pessoa: Pessoa;
    public listaStatus: Array<ElementoGrupoDominio>;

    constructor(
        public envioService: EnvioLocalService,
        public regionalService: RegionalService,
        public missaoService: MissaoService,
        public casaComunitariaService: CasaComunitariaService,
        public apostoladoService: ApostoladoService,
        public envioBuilder: EnvioBuilder,
        public elementoGrupoDominioService: ElementoGrupoDominioLocalService,
        public pessoaService: PessoaService,
        public pessoaBuilder: PessoaBuilder,
        public swtAlert2Service: SwtAlert2Service,
        public route: ActivatedRoute,
        public router: Router
    ) 
    {
        this.pessoa = pessoaBuilder.getInstance();
        this.envio = this.envioBuilder.getInstance();
        this.listaStatus = new Array<ElementoGrupoDominio>();        
    }

    ngOnInit() {        
        this.envioId = Number(this.route.snapshot.paramMap.get('id'));   
        this.initialize();      
    }

    async initialize() : Promise<any> {
        await this.carregarEnvio(this.envioId);    
        this.carregarPessoa();
        this.carregarRegionais();
        this.carregarMissoes();
        this.carregarCasasComunitarias();
        this.carregarApostolados();
        this.carregarStatus();
           
    }

    async salvar(envio: Envio): Promise<any> {

        try 
        {
            if(this.validarEnvio(envio))
            {
                let response = await this.envioService.update(envio.id,envio);
                this.swtAlert2Service.successAlert(response.message);
                setTimeout(() => {
                    this.router.navigate(['../secured/local/envios']);
                },2000); 
            }            
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }

    }

    async carregarEnvio(id: number): Promise<any> {

        try 
        {
            let response = await this.envioService.find(id);
            this.envio = response.entity;            
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }

    } 

    async carregarPessoa(): Promise<any> {

        try 
        {
            let response = await this.pessoaService.find(this.envio.pessoaId);
            this.pessoa = response.entity;            
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }

    } 

    async carregarStatus(): Promise<any> {

        try 
        {
            let request = {
                grupoNome : "STATUS_ENVIO"
            }

            let response = await this.elementoGrupoDominioService.buscarPorGrupoDominioNome(request);
            this.listaStatus = response.entity.filter((st) => st.valor.localeCompare("CONCL") != 0);;
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }

    }

    async carregarRegionais() : Promise<any> {
        try 
        {
            let response = await this.regionalService.findAll();
            this.regionais = response.entity.sort((a,b) => a.nome.localeCompare(b.nome));
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }
    }

    async carregarMissoes() : Promise<any> {
        try 
        {
            let response = await this.missaoService.porRegional(this.envio.regionalId);
            this.missoes = response.entity.sort((a,b) => a.nome.localeCompare(b.nome));;
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }
    }

    async carregarCasasComunitarias() : Promise<any> {
        try 
        {
            let response = await this.casaComunitariaService.porMissao(this.envio.missaoId);
            this.casasComunitarias = response.entity.sort((a,b) => a.nome.localeCompare(b.nome));;
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }
    }

    async carregarApostolados() : Promise<any> {
        try 
        {
            let response = await this.apostoladoService.pesquisar({ativo:true});
            this.apostolados = response.entity;
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }
    }

    validarEnvio(envio: Envio) : boolean {

        if(envio.pessoaId == null)
        {
            this.swtAlert2Service.warningAlert("Selecione o missionário para o aenvio");
            return false;
        }
        if(envio.regionalId == null)
        {
            this.swtAlert2Service.warningAlert("Informe o regional para o envio.");
            return false;
        }
        if(envio.missaoId == null)
        {
            this.swtAlert2Service.warningAlert("Informe a missão aenvio");
            return false;
        }

        return true;

    }

}