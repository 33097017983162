import { Component, OnInit } from '@angular/core';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { CadastroPublicoLocalService } from '../../local/cadastropublico/service/cadastro.publico.local.service';
import { TermoUso } from 'src/app/model/termouso.model';
import { TermoUsoBuilder } from 'src/app/builder/termouso.builder';
import { AceiteTermoService } from './service/aceitetermouso.service';
import { AceiteTermo } from 'src/app/model/aceitetermo.model';
import { AceiteTermoBuilder } from 'src/app/builder/aceitetermo.builder';
import { Router } from '@angular/router';

@Component({
    selector: 'aceitetermouso',
    templateUrl: './aceitetermouso.component.html'
})
export class AceiteTermoComponent implements OnInit {

    public termoVigente : TermoUso;
    public aceiteTermoUso: AceiteTermo;

    constructor(
        public cadastroPublicoService: CadastroPublicoLocalService,
        public aceiteTermoUsoService: AceiteTermoService,
        public termoUsoBuilder : TermoUsoBuilder,
        public aceiteTermoUsoBuilder: AceiteTermoBuilder,
        public swtAlert2Service: SwtAlert2Service,
        private router: Router
    ) 
    {
        this.termoVigente = this.termoUsoBuilder.getInstance();
        this.aceiteTermoUso = this.aceiteTermoUsoBuilder.getInstance();
    }

    ngOnInit() {    
        this.initialize();
    }

    async initialize() : Promise<any> {
        await this.didUserAcceptUserTerm();
        await this.carregarTermoVigente(); 
    }

    async didUserAcceptUserTerm() : Promise<any> {

        try
        {
            let response = await this.aceiteTermoUsoService.usuarioAceitouTermo();
            let usuarioAceitouTermoUso = response.entity;

            if(usuarioAceitouTermoUso)
            {
                this.router.navigate(['/secured/']);
            }
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
            this.router.navigate(['/login']);
        }
        
    }

    async carregarTermoVigente() : Promise<any> {

        try
        {
            let response = await this.cadastroPublicoService.getTermoVigente();
            this.termoVigente = response.entity
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async aceitarTermoUso() : Promise<any> {

        try
        {
            let response = await this.aceiteTermoUsoService.aceitarTermoVigente();
            this.swtAlert2Service.successAlert(response.entity);
            sessionStorage.setItem("usuarioAceitouTermoUso", "1"); 
            setTimeout(() => {
                this.router.navigate(['/secured/']);
            },3000)
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
            this.router.navigate(['/login']);
        }

    }

}