export class ImportLoginSh {

    constructor(
        public possuiLoginWop: boolean,
        public username: string,
        public email: string,
        public aceiteTermoUso: boolean,
        public aceiteTermoTratamentoDados: boolean,
        public aceiteImportacaoLoginSh: boolean     
    ){}

}