/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./mensagem-rl-membro.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./mensagem-rl-membro.component";
import * as i4 from "../../service/mensagem-rl-shared-state.service";
var styles_MensagemRlMembroComponent = [i0.styles];
var RenderType_MensagemRlMembroComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MensagemRlMembroComponent, data: {} });
export { RenderType_MensagemRlMembroComponent as RenderType_MensagemRlMembroComponent };
function View_MensagemRlMembroComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "member-message-counter"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.notReadedMessageCounter; _ck(_v, 2, 0, currVal_0); }); }
export function View_MensagemRlMembroComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "container"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "sumary-selected": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["alt", "imagem do membro da comunidade"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 7, "div", [["class", "member-message-data"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [["class", "message-preview"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MensagemRlMembroComponent_1)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "container"; var currVal_1 = _ck(_v, 3, 0, (_co.memberPessoaId === _co.selectedMemberResumePessoaId)); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_5 = _co.notReadedMessageCounter; _ck(_v, 12, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.imageSrc; _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.memberName; _ck(_v, 7, 0, currVal_3); var currVal_4 = _co.lastMessage; _ck(_v, 10, 0, currVal_4); }); }
export function View_MensagemRlMembroComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-mensagem-rl-membro", [], null, null, null, View_MensagemRlMembroComponent_0, RenderType_MensagemRlMembroComponent)), i1.ɵdid(1, 245760, null, 0, i3.MensagemRlMembroComponent, [i4.MensagemRlSharedStateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MensagemRlMembroComponentNgFactory = i1.ɵccf("app-mensagem-rl-membro", i3.MensagemRlMembroComponent, View_MensagemRlMembroComponent_Host_0, { imageSrc: "imageSrc", memberName: "memberName", lastMessage: "lastMessage", notReadedMessageCounter: "notReadedMessageCounter", memberPessoaId: "memberPessoaId" }, {}, []);
export { MensagemRlMembroComponentNgFactory as MensagemRlMembroComponentNgFactory };
