export class Trabalho {
    
    constructor(
        public id: number,
        public pessoaId: number,
        public empresa: string,
        public cargo: string,
        public iniciadoEm: Date,
        public finalizadoEm: Date
    )
    {}

}