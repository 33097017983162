<secured>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h3 i18n>Autorização</h3>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">

            <form (ngSubmit)="salvar(autorizacao)" #usuarioForm="ngForm" novalidate>
                <div class="card card-default color-pallete-box">
                    <div class="card-header">
                        <h3 class="card-title">
                            <i class="fas fa-key"></i>
                            <a i18n> Dados da Autorização </a>
                        </h3>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-12 col-md-3 col-lg-3">
                                <div class="form-group">
                                    <label i18n for="na_nome_exibicao">Nome de exibição</label>
                                    <input [(ngModel)]="autorizacao.nomeExibicao" name="nome_exibicao" type="text" class="form-control" id="na_nome_exibicao" placeholder="Digite o nome de exibição" i18n-placeholder>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-3 col-lg-3">
                                <div class="form-group">
                                    <label i18n for="na_nome">Nome</label>
                                    <input [(ngModel)]="autorizacao.nome" name="na_nome" type="text" class="form-control" id="na_nome" placeholder="Digite o nome" i18n-placeholder>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <button type="submit" class="btn btn-primary float-right" i18n>Salvar</button>
                    </div>
                </div>
            </form>
        </section>
        <section class="content-header" name="gerencia-permissoes" *ngIf="autorizacao.id!=null">
            <div class="card card-default color-pallete-box">
                <div class="card-header">
                    <h3 class="card-title">
                        <i class="fas fa-user-lock"></i>
                        <a i18n> Permissões vinculadas </a>
                    </h3>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title">Vincular Permissão</h3>
                                </div>
                                <!-- /.card-header -->
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <div class="form-group">
                                                <label i18n for="av_permissao">Permissão</label>
                                                <select class="form-control" id="av_permissao" [(ngModel)]="permissaoSelecionada" name="av_permissao">
                                                <option [value]="null" i18n>Selecione uma Permissao</option>
                                                <option *ngFor="let perm of permissoesNaoVinculadas" [value]="perm.id">{{perm.nomeExibicao}}</option>
                                            </select>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <label>&nbsp;</label><br>
                                            <button class="btn btn-md btn-primary" (click)="vincularPermissao()" title="Vincular permissão" alt="Vincular permissão"><i class="fas fa-plus-square"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6" *ngIf="permissoes.length == 0">
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title">Copiar Permissões vinculadas a Autorização...</h3>
                                </div>
                                <!-- /.card-header -->
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <div class="form-group">
                                                <label i18n for="copy_autorizacao">Autorizacao</label>
                                                <select class="form-control" id="copy_autorizacao" [(ngModel)]="copiarPermissoesRequest.copiarDeAutorizacaoId" name="copy_autorizacao">
                                                    <option value="null" i18n>Selecione uma Autorização</option>
                                                    <option *ngFor="let auth of autorizacoes" [value]="auth.id">{{auth.nomeExibicao}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <label>&nbsp;</label><br>
                                            <button class="btn btn-md btn-primary" (click)="copiarPermissoes()" title="Copiar permissões" alt="Copiar permissões"><i class="fas fa-copy"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <table-component [tituloSessao]="'Permissões'" [cols]="4" [rows]="busca.quantityOfElements" [totalDePaginas]="totalDePaginas" (eventoBotao)="cliqueNoBotaoListener($event)">
                                <tr tableheader>
                                    <th style="width: 10px">#</th>
                                    <th>Nome de exibição</th>
                                    <th>Nome</th>
                                    <th class="text-center">Desvincular</th>
                                </tr>
                                <tr tablebody *ngFor="let permissao of permissoes">
                                    <td>{{permissao.id}}</td>
                                    <td>{{permissao.nomeExibicao}}</td>
                                    <td>{{permissao.nome}}</td>
                                    <td class="text-center">
                                        <button class="btn btn-sm btn-danger" (click)="desvincularPermissao(permissao.id)" title="Desvincular permissão" alt="Desvincular permissão"><i class="fas fa-minus-square"></i></button>
                                    </td>
                                </tr>
                            </table-component>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</secured>