import { SafeUrl } from '@angular/platform-browser';

export class Missionario {

    constructor(
        public pessoaId: number,
        public imagemId: number,
        public nome: string,
        public sobrenome: string,        
        public missaoId: number,
        public missaoNome: string,
        public difusaoId: number,
        public difusaoNome: string,
        public casaComunitariaId: number,
        public casaComunitariaNome: string,
        public sexo: string,
        public idade: number,
        public nacionalidadeId: number,
        public nacionalidadeNome: number,
        public estadoVida: string,
        public idiomas: string,
        public registroDuplicado: boolean,
        public thumbnail: SafeUrl
    ){}
}