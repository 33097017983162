import { Component, Input, OnInit } from '@angular/core';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { Missao } from 'src/app/model/missao.model';
import { MissaoService } from 'src/app/secured/wop/missao/service/missao.service';

@Component({
  selector: 'app-missao-display-data',
  templateUrl: './missao-display-data.component.html',
  styleUrls: ['./missao-display-data.component.css'],
})
export class MissaoDisplayDataComponent implements OnInit {
  @Input()
  missaoId: number;
  missao?: Missao;

  constructor(
    private missaoService: MissaoService,
    private swtAlert: SwtAlert2Service
  ) {}

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.carregarMissao();
  }

  async carregarMissao() {
    try {
      const response = await this.missaoService.find(this.missaoId);
      this.missao = response.entity;
    } catch (e) {
      this.swtAlert.errorAlert(e.error.errors);
    }
  }
}
