/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./message-content-component.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./message-content-component.component";
var styles_MessageContentComponentComponent = [i0.styles];
var RenderType_MessageContentComponentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MessageContentComponentComponent, data: {} });
export { RenderType_MessageContentComponentComponent as RenderType_MessageContentComponentComponent };
export function View_MessageContentComponentComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "article", [["id", "member-message-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "span", [["class", "source"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Origem: "])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "span", [["class", "source-text"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "em", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.source; _ck(_v, 6, 0, currVal_0); var currVal_1 = _co.message; _ck(_v, 8, 0, currVal_1); }); }
export function View_MessageContentComponentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-message-content-component", [], null, null, null, View_MessageContentComponentComponent_0, RenderType_MessageContentComponentComponent)), i1.ɵdid(1, 114688, null, 0, i2.MessageContentComponentComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MessageContentComponentComponentNgFactory = i1.ɵccf("app-message-content-component", i2.MessageContentComponentComponent, View_MessageContentComponentComponent_Host_0, { source: "source", message: "message" }, {}, []);
export { MessageContentComponentComponentNgFactory as MessageContentComponentComponentNgFactory };
