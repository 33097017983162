import { Component, OnInit } from '@angular/core';
import { CentroEvangelizacao } from 'src/app/model/centroevangelizacao.model';
import { Estado } from 'src/app/model/estado.model';
import { Pais } from 'src/app/model/pais.model';
import { PaisService } from '../pais/service/pais.service';
import { ElementoGrupoDominioService } from '../grupodominio/service/elementogrupodominio.service';
import { MissaoService } from '../missao/service/missao.service';
import { RegionalService } from '../regional/service/regional.service';
import { Missao } from 'src/app/model/missao.model';
import { Regional } from 'src/app/model/regional.model';
import { EstadoService } from '../estado/service/estado.service';
import { Cidade } from 'src/app/model/cidade.model';
import { CentroEvangelizacaoService } from '../centroevangelizacao/service/centroevangelizacao.service';
import { Ministerio } from 'src/app/model/ministerio.model';
import { MinisterioService } from './service/ministerio.service';
import { MinisterioBuilder } from 'src/app/builder/ministerio.builder';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';

@Component({
    selector: 'ministerio',
    templateUrl: './ministerio.component.html'
})
export class MinisterioComponent implements OnInit {

    public ministerio: Ministerio;
    public missoes : Array<Missao>;
    public regionalSelecionadoId : number;
    public missaoSelecionadoId : number;
    public centroEvangelizacaoId : number;
    public regionais: Array<Regional>;
    public paises: Array<Pais>;
    public estados: Array<Estado>;
    public cidades: Array<Cidade>;
    public centrosEvangelizacao: Array<CentroEvangelizacao>;
    public ministerioBuilder: MinisterioBuilder;

    constructor(
        public centroEvangelizacaoService: CentroEvangelizacaoService,
        public elementoGrupoDominioService: ElementoGrupoDominioService,
        public regionalService: RegionalService,
        public missaoService: MissaoService,
        public paisService: PaisService,
        public estadoService: EstadoService,
        public ministerioService: MinisterioService,
        public swtAlert2Service: SwtAlert2Service
    ) 
    {
        this.ministerioBuilder = new MinisterioBuilder();
        this.ministerio = this.ministerioBuilder.getInstance();
        this.regionais = new Array<Regional>();
        this.missoes = new Array<Missao>();
        this.paises = new Array<Pais>();
        this.estados = new Array<Estado>();
        this.cidades = new Array<Cidade>();
        this.centrosEvangelizacao = new Array<CentroEvangelizacao>();
    }

    ngOnInit() {   
        this.carregarRegionais();
        this.regionalSelecionadoId = null; 
        this.missaoSelecionadoId = null;
        this.missaoSelecionadoId = null;
    }

    carregarRegionais() : void {
        this.regionalService.findAll().then( (lista: any) => {
            this.regionais = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    carregarMissoes(regionalSelecionadoId : number): void {
        this.missaoService.porRegional(regionalSelecionadoId).then((response: any) => {
            this.missoes = response.entity;          
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }
   

    carregarCev(missaoId : number): void {
       this.centroEvangelizacaoService.porMissao(missaoId).then((response: any) => {
            this.centrosEvangelizacao = response.entity;          
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });;
    }

    salvar(ministerio: Ministerio) : void {

        ministerio.dataFundacao = new Date(ministerio.dataFundacao);
        this.ministerioService.create(ministerio).then( (response:any) => {
            this.ministerio = new Ministerio(null,null,null,null, null,null);
            this.swtAlert2Service.successAlert(response.message);

        }).catch( (err:any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

}