import { NgModule } from '@angular/core';
import { EstadoComponent } from './estado.component';
import { Routes, RouterModule } from '@angular/router';
import { EstadosComponent } from './estados.component';
import { EstadoEditComponent } from './estado.edit.component';

const estadoRoutes: Routes = [
    {
        path: 'secured/estado',
        component: EstadoComponent
    },
    {
        path: 'secured/estados',
        component: EstadosComponent
    },
    {
        path: 'secured/estado/:id',
        component: EstadoEditComponent
    }
]

@NgModule({
    imports: [
        RouterModule.forChild(estadoRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class EstadoRoutingModule {
    
}