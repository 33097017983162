import { Injectable } from '@angular/core';
import { Pessoa } from '../model/pessoa.model';
import { AbstractBuilder } from './abstract.builder';
import { VinculoDifusaoBuilder } from './vinculodifusao.builder';
import { VinculoMissaoBuilder } from './vinculomissao.builder';

@Injectable()
export class PessoaBuilder extends AbstractBuilder<Pessoa> {

    reset() : void {
        this.entity = new Pessoa(null, '', '', null, null, null, null, null, null, null, null, null, new Array<string>(),null,null,null,null,null,null, null, null,null,null, null,null,null,null,null,null,new VinculoMissaoBuilder().getInstance(),new VinculoDifusaoBuilder().getInstance(),false);
    }

}