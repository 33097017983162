import { GenericService } from 'src/app/core/services/generic.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from "@angular/core";
import { MembroComunidadeVida } from 'src/app/model/membrocomunidadevida.model';

@Injectable()
export class MembroComunidadeVidaService extends GenericService<MembroComunidadeVida> {    
    
    constructor(protected http: HttpClient, protected router:Router) {
        super(http,router);
        this.setEntityType('r/membrocv');
    }

} 