import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { IrradiacaoBuilder } from 'src/app/builder/irradiacao.builder';
export class IrradiacaoEditComponent {
    constructor(irradiacaoService, regionalService, missaoService, elementoGrupoDominioService, paisService, estadoService, cidadeService, swtAlert2Service, route, router) {
        this.irradiacaoService = irradiacaoService;
        this.regionalService = regionalService;
        this.missaoService = missaoService;
        this.elementoGrupoDominioService = elementoGrupoDominioService;
        this.paisService = paisService;
        this.estadoService = estadoService;
        this.cidadeService = cidadeService;
        this.swtAlert2Service = swtAlert2Service;
        this.route = route;
        this.router = router;
        this.irradiacaoBuilder = new IrradiacaoBuilder();
        this.irradiacao = this.irradiacaoBuilder.getInstance();
        this.regionais = new Array();
        this.missoes = new Array();
        this.paisSelecionadoId = null;
        this.estadoSelecionadoId = null;
        this.paises = new Array();
        this.estados = new Array();
        this.cidades = new Array();
        this.fasesIrradiacao = new Array();
    }
    ngOnInit() {
        this.irradiacaoId = Number(this.route.snapshot.paramMap.get('id'));
        this.initialize();
    }
    initialize() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.carregarRegionais();
            yield this.carregarIrradiacao(this.irradiacaoId);
            this.carregarFasesIrradiacao();
            this.carregarPaises();
            this.carregarEstados();
            this.carregarCidades();
            return null;
        });
    }
    carregarIrradiacao(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.irradiacaoService.find(id);
                this.irradiacao = response.entity;
                this.regionalSelecionadoId = this.irradiacao.missaoRegionalId;
                this.carregarMissoes();
                this.paisSelecionadoId = this.irradiacao.cidadeEstadoPaisId;
                this.estadoSelecionadoId = this.irradiacao.cidadeEstadoId;
                this.carregarEstados();
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
            return null;
        });
    }
    carregarRegionais() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.regionalService.findAll();
                this.regionais = response.entity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
            return null;
        });
    }
    carregarMissoes() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.missaoService.porRegional(this.regionalSelecionadoId);
                this.missoes = response.entity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
            return null;
        });
    }
    carregarPaises() {
        this.paisService.findAll().then((lista) => {
            this.paises = lista.entity;
            this.carregarIrradiacao(this.irradiacaoId);
        }).catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }
    carregarEstados() {
        let busca = {
            paisId: this.paisSelecionadoId,
            nome: undefined,
            pageNumber: 0,
            quantityOfElements: 1000
        };
        this.estadoService.buscarEstados(busca).then((lista) => {
            this.estados = lista.entity;
            this.carregarCidades();
        }).catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }
    carregarCidades() {
        let busca = {
            estadoId: this.estadoSelecionadoId,
            nome: undefined,
            pageNumber: 0,
            quantityOfElements: 1000
        };
        this.cidadeService.buscarCidades(busca).then((lista) => {
            this.cidades = lista.entity;
        }).catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }
    carregarFasesIrradiacao() {
        let buscaFase = {
            grupoNome: 'FASE_IRRADIACAO'
        };
        this.elementoGrupoDominioService.buscarPorGrupoDominioNome(buscaFase).then((lista) => {
            this.fasesIrradiacao = lista.entity;
        }).catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }
    salvar(irradiacao) {
        irradiacao.dataFundacao = new Date(irradiacao.dataFundacao);
        this.irradiacaoService.update(irradiacao.id, irradiacao).then((response) => {
            this.irradiacao = response.entity;
            this.swtAlert2Service.successAlert(response.message);
            setTimeout(() => {
                this.router.navigate(['../secured/irradiacoes']);
            }, 2000);
        }).catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }
}
