import { AbstractBuilder } from './abstract.builder';
import { Escolaridade } from '../model/escolaridade.model';


export class EscolaridadeBuilder extends AbstractBuilder<Escolaridade> {

    reset() : void {
        this.entity = new Escolaridade(null,null,null,null,null,null);
    }

}