import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DifusaoEditComponent } from './difusao.edit.component';
import { DifusaoComponent } from './difusao.component';
import { DifusoesComponent } from './difusoes.component';

const difusaoRoutes: Routes = [
    {
        path: 'secured/difusao',
        component: DifusaoComponent
    },
    {
        path: 'secured/difusoes',
        component: DifusoesComponent
    },
    {
        path: 'secured/difusao/:id',
        component: DifusaoEditComponent
    }
]

@NgModule({
    imports: [
        RouterModule.forChild(difusaoRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class DifusaoRoutingModule {
    
}