import { NgModule } from '@angular/core';
import { AceiteTermoTratamentoDadosComponent } from './aceitetermotratamentodados.component';
import { Routes, RouterModule } from '@angular/router';

const aceiteTermoUsoRoutes: Routes = [
    {
        path: 'aceitetermotratamentodados',
        component: AceiteTermoTratamentoDadosComponent
    }
]

@NgModule({
    imports: [
        RouterModule.forChild(aceiteTermoUsoRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class AceiteTermoTratamentoDadosRoutingModule {
    
}