<ng-container>
    <div class="row">
        <div class="col-sm-12 col-md-12 col-lg-12" >
            <form #missionarioMissaoAtualForm="ngForm"
                (ngSubmit)="salvar(salvarMissionarioMissaoAtual)"
                novalidate>
                <div class="card card-secondary">
                    <div class="card-header">
                        <h3 class="card-title">Informação sobre a sua missão atual</h3>
                    </div>
                    <div class="card-body">
                        <div class="row" *ngIf="false">
                            <div class="col-sm-12 col-md-4 col-lg-4">
                                <div class="form-group">
                                    <label for="missoes">Missão</label>
                                    <select class="form-control" name="missoes"
                                        [(ngModel)]="salvarMissionarioMissaoAtual.missaoId"
                                        (change)="carregarCasasComunitarias(salvarMissionarioMissaoAtual.missaoId)">
                                        <option disabled [value]="null">Selecione uma missão
                                        </option>
                                        <option *ngFor="let missao of missoes"
                                            [value]="missao.id">{{missao.nome}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-4 col-lg-4">
                                <div class="form-group">
                                    <label for="casas_comunitarias">Casa Comunitária</label>
                                    <select class="form-control" name="casas_comunitarias"
                                        [(ngModel)]="salvarMissionarioMissaoAtual.casaComunitariaId">
                                        <option disabled [value]="null">Selecione uma casa
                                            comunitária</option>
                                        <option *ngFor="let casa of casasComunitarias"
                                            [value]="casa.id">{{casa.nome}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-4 col-lg-4">
                                <div class="form-group">
                                    <label for="data_chegada">Data de Chegada</label>
                                    <input type="date" class="form-control" name="data_chegada"
                                        [(ngModel)]="salvarMissionarioMissaoAtual.dataChegada"
                                        required>
                                </div>
                            </div>                    
                        </div>
                        <div class="row" *ngIf="true">
                            <div class="col-sm-12 col-md-12 col-lg-12">
                                <div class="card card-default">
                                    <div class="card-body p-0 overflow-auto ">
                                        <table class="table table-stripped">
                                            <thead>
                                                <tr>
                                                    <th>Regional</th>
                                                    <th>Missão</th>
                                                    <th>Casa Comunitária</th>
                                                    <th>Data de Chegada</th>                            
                                                    <th>Apostolado</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngIf="dadosDetalhamentoMissionarioMissaoAtual.id != null">
                                                    <td>{{dadosDetalhamentoMissionarioMissaoAtual.regionalNome}}</td>
                                                    <td>{{dadosDetalhamentoMissionarioMissaoAtual.missaoNome}}</td>
                                                    <td>
                                                        <ng-container *ngIf="dadosDetalhamentoMissionarioMissaoAtual.isEditing != true">
                                                            {{dadosDetalhamentoMissionarioMissaoAtual.casaComunitariaNome}}
                                                        </ng-container>
                                                        <ng-container *ngIf="dadosDetalhamentoMissionarioMissaoAtual.isEditing == true">
                                                            <select class="form-control" name="casas_comunitarias"
                                                                [(ngModel)]="dadosDetalhamentoMissionarioMissaoAtual.casaComunitariaId">
                                                                <option disabled [value]="null">Selecione uma casa
                                                                    comunitária</option>
                                                                <option *ngFor="let casa of casasComunitarias"
                                                                    [value]="casa.id">{{casa.nome}}
                                                                </option>
                                                            </select>
                                                        </ng-container>
                                                    </td>
                                                    <td>{{dadosDetalhamentoMissionarioMissaoAtual.dataChegada | date : 'dd/MM/yyyy'}}</td>                            
                                                    <td>
                                                        <button type="button" class="btn btn-info" (click)="configModalApostoladoAtual(dadosDetalhamentoMissionarioMissaoAtual.id)"
                                                            data-toggle="modal"
                                                            data-target="#modal-info-apostolado-atual">
                                                            Informar Apostolado
                                                        </button>                                                            
                                                    </td>
                                                    <td class="text-right" style="min-width: 7rem;">
                                                        <button *ngIf="dadosDetalhamentoMissionarioMissaoAtual.isEditing != true" class="btn btn-sm btn-primary mr-2"
                                                            (click)="dadosDetalhamentoMissionarioMissaoAtual.isEditing = true; carregarCasasComunitarias(dadosDetalhamentoMissionarioMissaoAtual.missaoId)"><i
                                                                class="fas fa-edit"></i></button>
                                                        <button (click)="atualizar(dadosDetalhamentoMissionarioMissaoAtual)" *ngIf="dadosDetalhamentoMissionarioMissaoAtual.isEditing == true" class="btn btn-sm btn-success mr-2"
                                                                (click)="dadosDetalhamentoMissionarioMissaoAtual.isEditing = false"><i
                                                                    class="fas fa-save"></i></button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer text-right" *ngIf="dadosDetalhamentoMissionarioMissaoAtual.id == null">
                        <button type="submit" class="btn btn-primary">Salvar</button>
                    </div>
                </div>
            </form> 
        </div>    
    </div>
    <div class="modal fade" id="modal-info-apostolado-atual" style="display: none;" aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Apostolados exercídos na missão</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12">                                
                            <div class="card card-secondary">
                                <div class="card-header">
                                    <h3 class="card-title">Informação sobre os apostolados exercidos na missão</h3>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label for="formavida">Tipo</label>
                                                <select class="form-control" name="tipoapostolado" [(ngModel)]="tipoApostoladoSelecionadoId"
                                                (change)="carregarApostoladosPorTipo(tipoApostoladoSelecionadoId)">
                                                    <option disabled [value]="null">Selecione um tipo
                                                    </option>
                                                    <option *ngFor="let tipo of tiposApostolado"
                                                            [value]="tipo.id">{{tipo.nome}}
                                                        </option>                                                       
                                                </select>
                                            </div>
                                        </div>           
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label for="apostoladosatina">Apostolado</label>
                                                <select class="form-control" name="apostoladosatina"
                                                    [(ngModel)]="salvarMissionarioMissaoApostoladoAtual.apostoladoId">
                                                    <option disabled [value]="null">Selecione um apostolado
                                                    </option>
                                                    <option *ngFor="let apostolado of apostoladosSelecionados"
                                                        [value]="apostolado.id">{{apostolado.nome}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>                                           
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label for="data_inicio">Data do Início</label>
                                                <input type="date" class="form-control" name="data_inicio"
                                                    [(ngModel)]="salvarMissionarioMissaoApostoladoAtual.dataInicio"
                                                    required>
                                            </div>
                                        </div>                                        
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label for="duracao_anos">&nbsp;</label>
                                                <div class="checkbox icheck-concrete">
                                                    <input type="checkbox" id="isInterino" name="isInterino"
                                                        [(ngModel)]="salvarMissionarioMissaoApostoladoAtual.isInterino" />&nbsp;
                                                    <label class="form-check-label" for="isInterino">É interino?</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                                <div class="card-footer text-right">
                                    <button type="button" class="btn btn-primary" (click)="salvarApostolado(salvarMissionarioMissaoApostoladoAtual)">Salvar</button>
                                </div>                                  
                            </div>                                
                        </div>                            
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12">
                            <div class="card card-default">
                                <div class="card-body p-0">
                                    <table class="table table-stripped">
                                        <thead>
                                            <tr>
                                                <th>Apostolado</th>
                                                <th>Data do Início</th>
                                                <th>É interino?</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let missionarioApostolado of listaDetalhamentoMissionarioMissaoApostoladoAtual">
                                                <td>{{missionarioApostolado.apostoladoNome}}</td>
                                                <td>
                                                    <ng-container *ngIf="missionarioApostolado.isEditing != true">
                                                        {{missionarioApostolado.dataInicio}}
                                                    </ng-container>
                                                    <ng-container *ngIf="missionarioApostolado.isEditing == true">
                                                        <input class="form-control" type="date" [(ngModel)]="missionarioApostolado.dataInicio">                                                            
                                                    </ng-container>                                                        
                                                </td>
                                                <td>
                                                    <ng-container *ngIf="missionarioApostolado.isEditing != true">
                                                        {{missionarioApostolado.isInterino == true ? 'Sim' : 'Não'}}
                                                    </ng-container>
                                                    <ng-container *ngIf="missionarioApostolado.isEditing == true">
                                                        <div class="checkbox icheck-concrete">
                                                            <input type="checkbox" id="isInterino" name="isInterino"
                                                                [(ngModel)]="missionarioApostolado.isInterino" />                                                                
                                                        </div>                                                           
                                                    </ng-container>                                                        
                                                </td>                                                   
                                                <td class="text-right">
                                                    <button class="btn btn-sm btn-success mr-1" *ngIf="missionarioApostolado.isEditing == true;" (click)="atualizarApostolado(missionarioApostolado); missionarioApostolado.isEditing = false;" title="Atualizar Apostolado"><i class="fas fa-save"></i></button>
                                                    <button class="btn btn-sm btn-primary mr-1" *ngIf="missionarioApostolado.isEditing != true" (click)="missionarioApostolado.isEditing = true;" title="Editar Apostolado"><i class="fas fa-edit"></i></button>
                                                    <button class="btn btn-sm btn-danger"
                                                            (click)="deletarMissionarioMissaoApostoladoAtual(missionarioApostolado.id)"><i
                                                                class="fas fa-trash-alt"></i></button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer justify-content-right">
                    <button type="button" class="btn btn-default" data-dismiss="modal">Fechar</button>                        
                </div>
            </div>
        </div>
    </div>
</ng-container>


