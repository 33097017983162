
<body class="hold-transition">
    <section class="content">
        <form (ngSubmit)="aceitarTermoUso()" #form="ngForm" novalidate>
            <div class="card card-default color-pallete-box">
                <div class="card-header">
                    <h1 class="card-title text-center" style="width:100%;">
                        <i class="fas fa-file-alt mr-2"></i>
                        <a i18n><strong>TERMOS DE USO</strong> 
                        <br><small>Você está aqui por que termos de uso sofreram alguma atualização ou você ainda não os aceitou.</small>
                        <br><small>Aceite o termo de uso para prosseguir!</small> </a>                                                 
                    </h1>
                </div>
                <div class="card-body">
                    <div [ngxSummernoteView]="termoVigente?.termo"></div>
                </div>
                <div class="card-footer">
                    <button type="submit" class="btn btn-primary float-right" i18n>Aceitar</button>
                </div>
            </div>
        </form>
    </section>
</body>

