<secured>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h3 i18n>Missão</h3>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">

            <form (ngSubmit)="salvar(missao)" #usuarioForm="ngForm" novalidate>
                <div class="card card-default color-pallete-box">
                    <div class="card-header">
                        <h3 class="card-title">
                            <i class="fas fa-map-marked-alt"></i>
                            <a i18n> Dados do Missão </a>
                        </h3>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-12 col-md-3 col-lg-3">
                                <div class="form-group">
                                    <label i18n for="nmis_r">Regional</label>
                                    <select class="form-control" id="nmis_r" [(ngModel)]="missao.regionalId" name="nmis_r">
                                        <option [value]="null" i18n>Selecione um Regional</option>
                                        <option *ngFor="let reg of regionais" [value]="reg.id">{{reg.nome}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-3 col-lg-3">
                                <div class="form-group">
                                    <label i18n for="nmis_pais">País</label>
                                    <select class="form-control" id="nmis_pais" [(ngModel)]="paisSelecionadoId" (change)="carregarEstados()" name="nmis_pais">
                                        <option [value]="null" i18n>Selecione um País</option>
                                        <option *ngFor="let pais of paises" [value]="pais.id">{{pais.nome}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-3 col-lg-3">
                                <div class="form-group">
                                    <label i18n for="nmis_estado">Estado</label>
                                    <select class="form-control" id="nmis_estado" [(ngModel)]="estadoSelecionadoId" (change)="carregarCidades()" name="nmis_estado">
                                        <option [value]="null" i18n>Selecione um Estado</option>
                                        <option *ngFor="let estado of estados" [value]="estado.id">{{estado.nome}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-3 col-lg-3">
                                <div class="form-group">
                                    <label i18n for="nmis_cidade">Cidade</label>
                                    <select class="form-control" id="nmis_cidade" [(ngModel)]="missao.cidadeId" name="nmis_cidade">
                                        <option [value]="null" i18n>Selecione uma Cidade</option>
                                        <option *ngFor="let cidade of cidades" [value]="cidade.id">{{cidade.nome}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-3 col-lg-3">
                                <div class="form-group">
                                    <label i18n for="nmis_nome">Nome da Missão</label>
                                    <input [(ngModel)]="missao.nome" name="nmis_nome" type="text" class="form-control" id="nmis_nome" placeholder="Digite o nome" i18n-placeholder>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-3 col-lg-3">
                                <div class="form-group">
                                    <label i18n for="nmis_f">Fase da Missão</label>
                                    <select class="form-control" id="nmis_f" [(ngModel)]="missao.faseMissaoId" name="nmis_f">
                                        <option [value]="null" i18n>Selecione uma fase</option>
                                        <option *ngFor="let fase of fasesMissao" [value]="fase.id">{{fase.nome}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-3 col-lg-3">
                                <div class="form-group">
                                    <label i18n for="nmis_dtfund">Data de Fundação</label>
                                    <input [(ngModel)]="missao.dataFundacao" name="nmis_dtfund" type="date" class="form-control" id="nmis_dtfund" i18n-placeholder>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-3 col-lg-3">
                                <div class="form-group">
                                    <label i18n for="nmis_s">Status da Missão</label>
                                    <select class="form-control" id="nmis_s" [(ngModel)]="missao.statusId" name="nmis_s">
                                        <option [value]="null" i18n>Selecione uma status</option>
                                        <option *ngFor="let status of statusMissao" [value]="status.id">{{status.nome}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <button type="submit" class="btn btn-primary float-right" i18n>Salvar</button>
                    </div>
                </div>
            </form>
        </section>
    </div>
</secured>