<secured>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h3 i18n>Listagem de Cidades</h3>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <form (ngSubmit)="buscarCidades()" #permissoesForm="ngForm" novalidate>
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title">Buscar Cidades</h3>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label i18n for="lcit_pais">País</label>
                                                <select class="form-control" id="lcit_pais" [(ngModel)]="busca.paisId" (change)="carregarEstados()" name="lcit_pais">
                                                    <option [value]="null" i18n>Selecione um País</option>
                                                    <option *ngFor="let pais of paises" [value]="pais.id">{{pais.nome}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label i18n for="lcit_estado">Estado</label>
                                                <select class="form-control" id="lcit_estado" [(ngModel)]="busca.estadoId" name="lcit_estado">
                                                    <option [value]="null" i18n>Selecione um Estado</option>
                                                    <option *ngFor="let estado of estados" [value]="estado.id">{{estado.nome}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label i18n for="lcit_nome">Nome</label>
                                                <input [(ngModel)]="busca.nome" name="lcit_nome" type="text" class="form-control" id="lcit_nome" placeholder="Digite o nome" i18n-placeholder>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-footer">
                                    <button type="submit" class="btn btn-primary float-right" i18n>Buscar</button>
                                    <button type="reset" class="btn btn-default float-right" style="margin-right: 0.5rem;" i18n>Limpar</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">

                        <table-component [tituloSessao]="'Cidades'" [cols]="5" [rows]="busca.quantityOfElements" [totalDePaginas]="totalDePaginas" (eventoBotao)="cliqueNoBotaoListener($event)">
                            <tr tableheader>
                                <th style="width: 10px">#</th>
                                <th>País</th>
                                <th>Estado</th>
                                <th>Cidade</th>
                                <th></th>
                            </tr>
                            <tr tablebody *ngFor="let cidade of cidades">
                                <td>{{cidade.id}}</td>
                                <td>{{cidade.paisNome}}</td>
                                <td>{{cidade.estadoNome}}</td>
                                <td>{{cidade.nome}}</td>
                                <td class="text-center">
                                    <button class="btn btn-sm btn-primary" routerLink="../cidade/{{cidade.id}}" title="Editar cidade" alt="Editar cidade"><i class="fas fa-edit"></i></button>&nbsp;
                                    <button class="btn btn-sm btn-danger" (click)="deletarCidade(cidade.id)" title="Deletar cidade" alt="Deletar cidade"><i class="fas fa-trash-alt"></i></button>
                                </td>
                            </tr>
                        </table-component>
                    </div>
                </div>
            </div>
        </section>
    </div>
</secured>