<secured>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h5 i18n>Remanejamentos</h5>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <form (ngSubmit)="carregarRelatorios()" novalidate>
                            <div class="card card-gray-dark color-pallete-box collapsed-card">
                                <div class="card-header">
                                    <h3 class="card-title">Buscar Demandas</h3>
                                    <div class="card-tools"><button class="btn btn-tool" data-card-widget="collapse" type="button"><i class="fas fa-plus"></i></button></div>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label i18n for="ldemanda_desc">Descrição</label>
                                                <input name="ldemanda_desc" type="text" class="form-control" id="ldemanda_desc" placeholder="Digite a descrição" i18n-placeholder>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label i18n for="ldemanda_missao">Missão</label>
                                                <select class="form-control" id="ldemanda_missao" name="ldemanda_missao">
                                                    <option [value]="null" i18n>Selecione uma Missão</option>
                                                    <!-- <option *ngFor="let missao of missoes" [value]="missao.id">{{missao.nome}}</option> -->
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label i18n for="ldemanda_status">Status</label>
                                                <select class="form-control" id="ldemanda_status" name="ldemanda_status">
                                                    <option disabled value="null" i18n>Selecione um status</option>
                                                    <option disabled value="null" i18n>Pendente</option>
                                                    <option disabled value="null" i18n>Pré Aprovada</option>
                                                    <option disabled value="null" i18n>Discernida</option>
                                                    <option disabled value="null" i18n>Aprovada pelo Conselho</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>                                    
                                </div>
                                <div class="card-footer">
                                    <button type="submit" class="btn btn-primary float-right" i18n>Buscar</button>
                                    <button type="reset" class="btn btn-default float-right" style="margin-right: 0.5rem;" i18n>Limpar</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <div class="row">
                            <div class="col-3">
                                <div class="small-box bg-info">
                                    <div class="inner">
                                        <h3>999</h3>
                                        <p>Total de demandas</p>
                                    </div>
                                    <div class="icon">
                                        <i class="fas fa-list"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="small-box bg-info">
                                    <div class="inner">
                                        <h3>999</h3>
                                        <p>Demandas Pré-Aprovadas</p>
                                    </div>
                                    <div class="icon">
                                        <i class="fas fa-pen-square"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="small-box bg-success">
                                    <div class="inner">
                                        <h3>999</h3>
                                        <p>Demandas Discernidas</p>
                                    </div>
                                    <div class="icon">
                                        <i class="fas fa-check-square"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="small-box bg-warning">
                                    <div class="inner">
                                        <h3>999</h3>
                                        <p>Demandas Pendentes</p>
                                    </div>
                                    <div class="icon">
                                        <i class="fas fa-exclamation-triangle"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12" style='text-align: center'>
                        <!--table-component [cardBodyClass]="'table-responsive p-0'" [tableClass]="'table-striped'" [tituloSessao]="'Lista dos Missionários'" [cols]="7" [rows]="busca.quantityOfElements" [totalDePaginas]="totalDePaginas" (eventoBotao)="cliqueNoBotaoListener($event)"-->
                        <table-component [cardBodyClass]="'table-responsive p-0'" [tableClass]="'table-striped'" [tituloSessao]="'Lista das Demandas'" [cols]="7" [rows]="100" [totalDePaginas]="10" >
                            <tr tableheader>
                                <th>Demanda</th>
                                <th>Candidados</th>
                                <th>Candidatos Pré-Aprovados</th>
                                <th>Discernidos</th>                                
                                <th>Aprovados</th>
                                <th></th>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                            </tr>
                            <!-- tr tablebody *ngFor="let demanda of demandas">
                                <td>
                                    <div class="widget-user-image">
                                        <img class="profile-user-img img-fluid img-circle image-size-list elevation-2" [src]="pessoa.thumbnail == null ? 'assets/images/ppl.png' : pessoa.thumbnail" alt="User Avatar">
                                    </div>
                                </td>
                                <td>{{pessoa.nome}}</td>
                                <td>{{pessoa.sobrenome}}</td>
                                <td>{{pessoa.missaoAtualId}}</td>
                                <td class="text-center">
                                    <button class="btn btn-sm btn-primary" routerLink="../pessoa/{{pessoa.id}}" title="Visualizar os dados do missionario" alt="Visualizar os dados do missionario"><i class="fas fa-eye"></i></button>&nbsp;
                                </td>
                            </tr -->
                        </table-component>
                    </div>
                </div>
            </div>
        </section>
    </div>
</secured>