import * as tslib_1 from "tslib";
import { GenericService } from 'src/app/core/services/generic.service';
export class GrupoDeOracaoService extends GenericService {
    constructor(http, router) {
        super(http, router);
        this.http = http;
        this.router = router;
        this.setEntityType('r/grupodeoracao');
    }
    buscarGruposDeOracao(busca) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http.post(this.apiURL + '/buscar', JSON.stringify(busca), { 'headers': this.getHeaders() }).toPromise();
        });
    }
    unirGruposOracao(request) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http.post(this.apiURL + '/unirgrupos', JSON.stringify(request), { 'headers': this.getHeaders() }).toPromise();
        });
    }
    gruposSemPastores(busca) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http.post(this.apiURL + '/grupossempastores', JSON.stringify(busca), { 'headers': this.getHeaders() }).toPromise();
        });
    }
    quantidadeGruposComSemPastores(busca) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http.post(this.apiURL + '/relatorioquantidadegruposcomsempastor', JSON.stringify(busca), { 'headers': this.getHeaders() }).toPromise();
        });
    }
    relatorioGruposPorPublico(busca) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http.post(this.apiURL + '/relatorioquantidadegruposporpublico', JSON.stringify(busca), { 'headers': this.getHeaders() }).toPromise();
        });
    }
    relatorioPublicoMes(busca) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http.post(this.apiURL + '/relatoriopublicomes', JSON.stringify(busca), { 'headers': this.getHeaders() }).toPromise();
        });
    }
    relatorioPerserverancaTrimestre(busca) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http.post(this.apiURL + '/relatorioperseverancatrimestre', JSON.stringify(busca), { 'headers': this.getHeaders() }).toPromise();
        });
    }
    relatorioCrescimentoObra(busca) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http.post(this.apiURL + '/relatoriocrescimentoobra', JSON.stringify(busca), { 'headers': this.getHeaders() }).toPromise();
        });
    }
    relatorioPublicoMesPastor() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http.get(this.apiURL + '/relatoriopublicomespastor', { 'headers': this.getHeaders() }).toPromise();
        });
    }
    relatorioPerserverancaTrimestrePastor() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http.get(this.apiURL + '/relatorioperseverancatrimestrepastor', { 'headers': this.getHeaders() }).toPromise();
        });
    }
    relatorioCrescimentoObraPastor() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http.get(this.apiURL + '/relatoriocrescimentoobrapastor', { 'headers': this.getHeaders() }).toPromise();
        });
    }
    getAllNotInAcessoUsuarioEstrutura(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http.get(`${this.apiURL}/getallnotinacessousuarioestrutura/${id}`, { 'headers': this.getHeaders() }).toPromise();
        });
    }
    getAllFromAcessoUsuarioEstrutura(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http.get(`${this.apiURL}/getallfromacessousuarioestrutura/${id}`, { 'headers': this.getHeaders() }).toPromise();
        });
    }
    criarLinkCadastroGrupoOracao(request) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http.post(this.apiURL + '/criarlinkcadastrogrupooracao', JSON.stringify(request), { 'headers': this.getHeaders() }).toPromise();
        });
    }
    obterLinkCadastroGrupoOracao(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http.get(`${this.apiURL}/obterlinkcadastrogrupooracao/${id}`, { 'headers': this.getHeaders() }).toPromise();
        });
    }
}
