import { GenericService } from 'src/app/core/services/generic.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from "@angular/core";
import { AcessoUsuarioEstrutura } from 'src/app/model/acessousuarioestrutura.model';

@Injectable()
export class AcessoUsuarioEstruturaService extends GenericService<AcessoUsuarioEstrutura> {
    
    constructor(protected http: HttpClient, protected router:Router) {
        super(http,router);
        this.setEntityType('r/acessousuarioestrutura');
    }

    buscar(busca: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/buscar', JSON.stringify(busca), { 'headers' : this.getHeaders() }).toPromise();
    }

    findByUsuarioAutorizacao(request: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/obteracesso', JSON.stringify(request), { 'headers' : this.getHeaders() }).toPromise();
    }

    adicionarAcessoRegional(request: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/adicionaracessoregional', JSON.stringify(request), { 'headers' : this.getHeaders() }).toPromise();
    }

    removerAcessoRegional(request: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/removeracessoregional', JSON.stringify(request), { 'headers' : this.getHeaders() }).toPromise();
    }

    adicionarAcessoMissao(request: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/adicionaracessomissao', JSON.stringify(request), { 'headers' : this.getHeaders() }).toPromise();
    }

    removerAcessoMissao(request: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/removeracessomissao', JSON.stringify(request), { 'headers' : this.getHeaders() }).toPromise();
    }

    adicionarAcessoIrradiacao(request: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/adicionaracessoirradiacao', JSON.stringify(request), { 'headers' : this.getHeaders() }).toPromise();
    }

    removerAcessoIrradiacao(request: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/removeracessoirradiacao', JSON.stringify(request), { 'headers' : this.getHeaders() }).toPromise();
    }

    adicionarAcessoDifusao(request: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/adicionaracessodifusao', JSON.stringify(request), { 'headers' : this.getHeaders() }).toPromise();
    }

    removerAcessoDifusao(request: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/removeracessodifusao', JSON.stringify(request), { 'headers' : this.getHeaders() }).toPromise();
    }

    adicionarAcessoCentroEvangelizacao(request: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/adicionaracessocentroevangelizacao', JSON.stringify(request), { 'headers' : this.getHeaders() }).toPromise();
    }

    removerAcessoCentroEvangelizacao(request: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/removeracessocentroevangelizacao', JSON.stringify(request), { 'headers' : this.getHeaders() }).toPromise();
    }

    adicionarAcessoGrupoOracao(request: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/adicionaracessogrupooracao', JSON.stringify(request), { 'headers' : this.getHeaders() }).toPromise();
    }

    removerAcessoGrupoOracao(request: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/removeracessogrupooracao', JSON.stringify(request), { 'headers' : this.getHeaders() }).toPromise();
    }

    adicionarAcessoPublicoGrupoOracao(request: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/adicionaracessopublicogrupooracao', JSON.stringify(request), { 'headers' : this.getHeaders() }).toPromise();
    }

    removerAcessoPublicoGrupoOracao(request: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/removeracessopublicgrupooracao', JSON.stringify(request), { 'headers' : this.getHeaders() }).toPromise();
    }

    adicionarAcessoCelula(request: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/adicionaracessocelula', JSON.stringify(request), { 'headers' : this.getHeaders() }).toPromise();
    }

    removerAcessoCelula(request: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/removeracessocelula', JSON.stringify(request), { 'headers' : this.getHeaders() }).toPromise();
    }

    adicionarAcessoCasaComunitaria(request: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/adicionaracessocasacomunitaria', JSON.stringify(request), { 'headers' : this.getHeaders() }).toPromise();
    }

    removerAcessoCasaComunitaria(request: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/removeracessocasacomunitaria', JSON.stringify(request), { 'headers' : this.getHeaders() }).toPromise();
    }

    adicionarAcessoMinisterio(request: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/adicionaracessoministerio', JSON.stringify(request), { 'headers' : this.getHeaders() }).toPromise();
    }

    removerAcessoMinisterio(request: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/removeracessoministerio', JSON.stringify(request), { 'headers' : this.getHeaders() }).toPromise();
    }

    adicionarAcessoProjeto(request: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/adicionaracessoprojeto', JSON.stringify(request), { 'headers' : this.getHeaders() }).toPromise();
    }

    removerAcessoProjeto(request: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/removeracessoprojeto', JSON.stringify(request), { 'headers' : this.getHeaders() }).toPromise();
    }

}