<secured>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h5 i18n><i class="fa fa-list"></i>&nbsp;&nbsp;&nbsp;Discernimentos</h5>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <!-- <div class="row">
                    <div class="col-12">
                        Não há nenhum questionário disponível para informar parecer.
                    </div>
                </div> -->
                <div class="row">
                    <div class="col-md-12">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12" style='text-align: center'>
                                    <table-component [cardBodyClass]="'table-responsive p-0'"
                                        [tableClass]="'table-striped'"
                                        [tituloSessao]="'Questionários disponíveis para informar parecer'" [cols]="11"
                                        [rows]="buscaMembros.quantityOfElements" [totalDePaginas]="1"
                                        (eventoBotao)="cliqueNoBotaoListener($event)">
                                        <tr tableheader>
                                            <th>Foto de perfil</th>
                                            <th>Nome</th>
                                            <th>Nível Formativo</th>
                                            <th *ngIf="questionarioConfiguracao?.status == 'EM_DISCERNIMENTO'">Status
                                                Questionario</th>
                                            <th *ngIf="questionarioConfiguracao?.status == 'EM_DISCERNIMENTO'">Parecer
                                            </th>
                                            <th *ngIf="questionarioConfiguracao?.status == 'DIVULGADO'">Feedback</th>
                                        </tr>
                                        <tr tablebody *ngFor="let membro of membrosCelula">
                                            <td>
                                                <div class="widget-user-image">
                                                    <img class="profile-user-img img-fluid img-circle image-size-list elevation-2"
                                                        [src]="membro.thumbnail == null ? 'assets/images/ppl.png' : membro.thumbnail"
                                                        alt="User Avatar">
                                                </div>
                                            </td>
                                            <td>{{membro.pessoaNome}}</td>
                                            <td>{{membro.nivelFormativoNome}}</td>
                                            <td *ngIf="questionarioConfiguracao?.status == 'EM_DISCERNIMENTO'">
                                                <button *ngIf="membro.statusQuestionario == 'ENVIADO'"
                                                    class="btn btn-primary"
                                                    routerLink="/secured/questionariocal-view-answer/{{membro.idQuestionario}}"
                                                    title="Questionario CAL" alt="Questionario CAL">Visualizar</button>
                                                <button *ngIf="membro.statusQuestionario != 'ENVIADO'"
                                                    class="btn btn-primary" disabled title="Questionario CAL"
                                                    alt="Questionario CAL">{{membro.statusQuestionario}}</button>
                                            </td>
                                            <td *ngIf="questionarioConfiguracao?.status == 'EM_DISCERNIMENTO'">
                                                <button *ngIf="membro.statusParecer == 'ENVIADO'"
                                                    class="btn btn-primary" disabled title="Parecer CAL"
                                                    alt="Parecer CAL">Enviado</button>
                                                <button *ngIf="membro.statusParecer != 'ENVIADO'"
                                                    class="btn btn-primary"
                                                    (click)="consultarVinculoQuestionarioCAL(membro)"
                                                    title="Parecer CAL" alt="Parecer CAL">Responder</button>
                                            </td>
                                            <td *ngIf="questionarioConfiguracao?.status == 'DIVULGADO'">
                                                {{membro.caminhoFormativoFeedback ? membro.caminhoFormativoFeedback :
                                                'Feedback não informado'}}
                                            </td>
                                        </tr>
                                    </table-component>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</secured>