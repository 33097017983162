<left-sidebar-aside-container-component>
  <ng-container menu-content>
    <li class="nav-header">
      <strong>MENU</strong>
    </li>

    <left-sidebar-menu-component [icon]="'fas fa-id-card'" [name]="'Pessoa'" [isMenuActive]="isMenuActive('Pessoa')">
      <ng-container menu-items>

        <left-sidebar-menu-item-component [name]="'Perfil'" [path]="'/secured/perfil'"
          (isActiveEventEmmiter)="updateIsActive('Pessoa',$event)"></left-sidebar-menu-item-component>

        <left-sidebar-menu-item-component [name]="'Dados Vocacionais'" [path]="'/secured/dadosvocacionais'"
          (isActiveEventEmmiter)="updateIsActive('Pessoa',$event)"></left-sidebar-menu-item-component>

        <left-sidebar-menu-item-component [name]="'Saúde'" [path]="'/secured/saude'"
          (isActiveEventEmmiter)="updateIsActive('Pessoa',$event)"></left-sidebar-menu-item-component>


      </ng-container>
    </left-sidebar-menu-component>

    <left-sidebar-menu-component [icon]="'fas fa-thumbtack'" [name]="'Célula'" [isMenuActive]="isMenuActive('Célula')">
      <ng-container menu-items>

        <left-sidebar-menu-item-component [name]="'Listar'" [path]="'/secured/af/celulas'"
          (isActiveEventEmmiter)="updateIsActive('Célula',$event)"></left-sidebar-menu-item-component>

      </ng-container>
    </left-sidebar-menu-component>

    <li class="nav-header">
      <strong>CONFIGURAÇÂO</strong>
    </li>

    <left-sidebar-menu-component [icon]="'fas fa-thumbtack'" [name]="'Usuário'"
      [isMenuActive]="isMenuActive('Usuário')">
      <ng-container menu-items>

        <left-sidebar-menu-item-component [name]="'Meu Usuário'" [path]="'/secured/meuusuario'"
          (isActiveEventEmmiter)="updateIsActive('Usuário',$event)"></left-sidebar-menu-item-component>

      </ng-container>
    </left-sidebar-menu-component>
  </ng-container>
</left-sidebar-aside-container-component>