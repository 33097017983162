import { Injectable } from '@angular/core';
import { DadosDetalhamentoMissionarioMissaoApostoladoAtual } from '../model/dadosdetalhamentomissionariomissaoapostoladoatual.model';
import { AbstractBuilder } from './abstract.builder';

@Injectable()
export class DadosDetalhamentoMissionarioMissaoApostoladoAtualBuilder extends AbstractBuilder<DadosDetalhamentoMissionarioMissaoApostoladoAtual> {

    reset() : void {
        this.entity = new DadosDetalhamentoMissionarioMissaoApostoladoAtual(null,null,null,null,null,null,null,null);
    }

}