<secured>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h3 i18n>Grupo Domínio - Missionária</h3>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">

            <form (ngSubmit)="salvar(grupoDominio)" #gdForm="ngForm" novalidate>
                <div class="card card-default color-pallete-box">
                    <div class="card-header">
                        <h3 class="card-title">
                            <i class="fas fa-globe-americas"></i>
                            <a i18n> Dados do Grupo Domínio </a>
                        </h3>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-12 col-md-3 col-lg-3">
                                <div class="form-group">
                                    <label i18n for="ngd_nome">Nome</label>
                                    <input [(ngModel)]="grupoDominio.nome" name="ngd_nome" type="text" class="form-control" id="ngd_nome" placeholder="Digite o nome" i18n-placeholder>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <button type="submit" class="btn btn-primary float-right" i18n>Salvar</button>
                    </div>
                </div>
            </form>
        </section>
    </div>
</secured>