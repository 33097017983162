import { GenericService } from 'src/app/core/services/generic.service';
import { HttpClient } from '@angular/common/http';
import { Autorizacao } from 'src/app/model/autorizacao.model';
import { Router } from '@angular/router';
import { Injectable } from "@angular/core";


@Injectable()
export class AutorizacaoLocalService extends GenericService<Autorizacao> {
    
    constructor(protected http: HttpClient, protected router:Router) {
        super(http,router);
        this.setEntityType('autorizacao');
    }

    getByHierarquiaTutorCap(): Promise<any> {
        return this.http.get<any>(`${this.apiURL}/byhierarquiatutorcap`, { 'headers' : this.getHeaders() }).toPromise();
    }

    getByHierarquiaAssistenteApostolico(): Promise<any> {
        return this.http.get<any>(`${this.apiURL}/byhierarquiaassistenteapostolico`, { 'headers' : this.getHeaders() }).toPromise();
    }

    copiarPermissoes(request: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/copiarpermissoes', JSON.stringify(request), { 'headers' : this.getHeaders() }).toPromise();
    }

    buscarAutorizacoes(busca: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/buscar', JSON.stringify(busca), { 'headers' : this.getHeaders() }).toPromise();
    }

    buscarAutorizacoesPorPermissaoId(busca: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/porpermissao', JSON.stringify(busca), { 'headers' : this.getHeaders() }).toPromise();
    }

    buscarAutorizacoesNaoVinculadasPorPermissaoId(id: number) : Promise<any> {
        return this.http.get<any>(this.apiURL+'/notvinculated/'+id, { 'headers' : this.getHeaders() }).toPromise();
    }

    vincular(obj: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/vincular/', JSON.stringify(obj), { 'headers' : this.getHeaders() }).toPromise();
    }

    desvincular(obj: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/desvincular/', JSON.stringify(obj), { 'headers' : this.getHeaders() }).toPromise();
    }

    autorizacoesPorUsuarioId(id: number) : Promise<any> {
        return this.http.get<any>(this.apiURL+'/porusuario/'+id, { 'headers' : this.getHeaders() }).toPromise();
    }

    vincularUsuario(obj: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/vincularusuario/', JSON.stringify(obj), { 'headers' : this.getHeaders() }).toPromise();
    }

    desvincularUsuario(obj: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/desvincularusuario/', JSON.stringify(obj), { 'headers' : this.getHeaders() }).toPromise();
    }

}