import { Injectable } from '@angular/core';
import { PessoaInfo } from '../model/pessoainfo.model';
import { AbstractBuilder } from './abstract.builder';

@Injectable()
export class PessoaInfoBuilder extends AbstractBuilder<PessoaInfo> {

    reset() : void {
        this.entity = new PessoaInfo(null,null,null,null,null,);
    }

}