import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DetalhesDashboardCelula } from 'src/app/model/DetalhesDashboardCelula.model';
import { SearchCelulaType } from 'src/app/secured/wop/celula/celulas.component';
import { RestService } from 'src/app/service/rest.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ResumoCelulaService {
  constructor(private restService: RestService) {}

  obterDashboard(
    request: SearchCelulaType
  ): Observable<DetalhesDashboardCelula> {
    return this.restService.postRequest<
      SearchCelulaType,
      DetalhesDashboardCelula
    >(
      `${environment.moduloFormacao.urlModuloFormacao}celula/dashboard`,
      request
    );
  }
}
