export class DadosDetalhamentoMissionarioMissaoAtual {

    constructor(
        public id: number,
        public pessoaId: number,
        public pessoaNome: string,
        public regionalId: number,
        public regionalNome: string,
        public missaoId: number,
        public missaoNome: string,
        public casaComunitariaId: number,
        public casaComunitariaNome: string,
        public dataChegada: Date,
        public imagemId: number,
        public isEditing: boolean
    ){}

}