import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SecuredModule } from 'src/app/secured/layout/secured.module';
import { ListagemGrupoOracaoModalComponent } from './listagemgrupo.modal.component';



 @NgModule({
     declarations:[
         ListagemGrupoOracaoModalComponent
     ],
     imports:[
        CommonModule,
        RouterModule,
        FormsModule,
        SecuredModule
    ],
     exports:[
         ListagemGrupoOracaoModalComponent
     ]
 })
 export class ListagemGrupoOracaoModalModule {
     
 }