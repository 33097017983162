import { Component, OnInit } from '@angular/core';
import { Missao } from 'src/app/model/missao.model';
import { MissaoService } from './service/missao.service';
import { Alerta } from 'src/app/model/alerta.model';
import { Regional } from 'src/app/model/regional.model';
import { Pais } from 'src/app/model/pais.model';
import { Estado } from 'src/app/model/estado.model';
import { Cidade } from 'src/app/model/cidade.model';
import { ElementoGrupoDominio } from 'src/app/model/elementogrupodominio.model';
import { RegionalService } from '../regional/service/regional.service';
import { ElementoGrupoDominioService } from '../grupodominio/service/elementogrupodominio.service';
import { PaisService } from '../pais/service/pais.service';
import { EstadoService } from '../estado/service/estado.service';
import { CidadeService } from '../cidade/service/cidade.service';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';

@Component({
    selector: 'missoes',
    templateUrl: './missoes.component.html'
})
export class MissoesComponent implements OnInit {

    public entities: Array<Missao>;
    public busca: any;
    public totalDePaginas: number;
    public regionais: Array<Regional>;
    public paises: Array<Pais>;
    public estados: Array<Estado>;
    public cidades: Array<Cidade>;
    public fasesMissao: Array<ElementoGrupoDominio>;
    public statusMissao: Array<ElementoGrupoDominio>;

    constructor(
        public missaoService: MissaoService,
        public regionalService: RegionalService,
        public elementoGrupoDominioService: ElementoGrupoDominioService,
        public paisService: PaisService,
        public estadoService: EstadoService,
        public cidadeService: CidadeService,
        public swtAlert2Service: SwtAlert2Service
    ) 
    {
        this.entities = new Array<Missao>();
        this.busca = {
            regionalId: null,
            paisId: null,
            estadoId: null,
            cidadeId: null,
            nomeMissao: null,
            elementoGrupoDominioFaseMissaoId: null,
            dataFundacao: Date, 
            dataFundacaoField: null,  
            statusId: null,     
            pageNumber : 0, 
            quantityOfElements : 10
        };
        this.regionais = new Array<Regional>();
        this.paises = new Array<Pais>();
        this.estados = new Array<Estado>();
        this.cidades = new Array<Cidade>();
        this.fasesMissao = new Array<ElementoGrupoDominio>(); 
        this.statusMissao = new Array<ElementoGrupoDominio>(); 

    }

    ngOnInit() {
        this.buscar();
        this.carregarRegionais();   
        this.carregarFasesMissao();  
        this.carregarStatusMissao();
        this.carregarPaises();
    }   

    carregarRegionais() : void {
        this.regionalService.findAll().then( (lista: any) => {
            this.regionais = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    carregarFasesMissao() : void {

        let buscaFase = {
            grupoNome : 'FASE_MISSAO'
        }

        this.elementoGrupoDominioService.buscarPorGrupoDominioNome(buscaFase).then( (lista: any) => {
            this.fasesMissao = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    async carregarStatusMissao() : Promise<null> {

        try{
            let request = {
                grupoNome : 'MISSAO_STATUS'
            }
            let response = await this.elementoGrupoDominioService.buscarPorGrupoDominioNome(request);
            this.statusMissao = response.entity;

        }
        catch(err){
            this.swtAlert2Service.errorAlert(err.error.errors);
        }

        return null;
    }

    carregarPaises() : void {
        this.paisService.findAll().then( (lista: any) => {
            this.paises = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    carregarEstados() : void {

        let busca = {
            paisId: this.busca.paisId,
            nome: undefined,
            pageNumber : 0, 
            quantityOfElements : 1000
        };

        this.estadoService.buscarEstados(busca).then( (lista: any) => {
            this.estados = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });

    }

    carregarCidades() : void {

        let busca = {
            estadoId: this.busca.estadoId,
            nome: undefined,
            pageNumber : 0, 
            quantityOfElements : 1000
        };

        this.cidadeService.buscarCidades(busca).then( (lista: any) => {
            this.cidades = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });

    }

    buscar() : void {

        if(this.busca.dataFundacaoField)
        {
            this.busca.dataFundacao = new Date(this.busca.dataFundacaoField);
        }
        else
        {
            this.busca.dataFundacao = null;
        }

        this.missaoService.buscar(this.busca).then( (response: any) => {
            this.entities = response.entity;
            this.totalDePaginas = response.quantity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    cliqueNoBotaoListener(botao) {
        this.busca.pageNumber = botao.numero-1;
        this.buscar();
    }
    
    deletar(id: number) : void {

        if(confirm('Deseja deletar este missao?'))
        {
            this.missaoService.delete(id)
            .then( (response: any) => {
                this.swtAlert2Service.successAlert(response.message);
                this.buscar();
            })
            .catch((err) => {
                this.swtAlert2Service.errorAlert(err.error.errors);
            });
        }
    }
}