import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SecuredModule } from '../../layout/secured.module';
import { RouterModule } from '@angular/router';
import { ProjetoRoutingModule } from './projeto-routing.module';
import { ProjetoComponent } from './projeto.component';
import { ProjetosComponent } from './projetos.component';
import { ProjetoEditComponent } from './projeto.edit.component';
import { ProjetoService } from './service/projeto.service';



@NgModule({
    declarations: [ 
        ProjetoComponent,
        ProjetosComponent,
        ProjetoEditComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        ProjetoRoutingModule,
        FormsModule,
        SecuredModule
    ],
    providers: [
        ProjetoService
    ]
})
export class ProjetoModule {

}