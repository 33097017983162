import { GenericService } from 'src/app/core/services/generic.service';
import { HttpClient } from '@angular/common/http';
import { Envio } from 'src/app/model/envio.model';
import { Router } from '@angular/router';
import { Injectable } from "@angular/core";


@Injectable()
export class EnvioLocalService extends GenericService<Envio> {
    
    constructor(protected http: HttpClient, protected router:Router) {
        super(http,router);
        this.setEntityType('envio');
    }

    pesquisar(entity: Envio): Promise<any> {
        return this.http.post<Envio>(`${this.apiURL}/pesquisar`, entity, { 'headers' : this.getHeaders() }).toPromise();
    } 
    
}