<ng-container>
  <div class="card card-gray-dark">
    <div class="card-header">
      <h3 class="card-title">
        {{!coordenadorColegiado?.id ? 'Cadastrar' : ''}} Coordenador de Colegiado
      </h3>
    </div>
    <div class="card-body">
      <div class="row" *ngIf="!coordenadorColegiado?.id">
        <div class="col-12">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="form-group">
                <label i18n for="npastor_pessoa">Pessoa</label>
                <span class="form-control">{{pessoaCoordenadorColegiadoSelecionado?.nome}}
                  {{pessoaCoordenadorColegiadoSelecionado?.sobrenome}}</span>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label i18n for="button-add-pessoa">&nbsp;</label>
                <button name="button-add-pessoa" data-toggle="modal"
                  data-target="#listagem-pessoa-coordenador-colegiado" style="cursor: pointer" class="btn btn-primary"
                  i18n>Selecionar o Coordenador de Colegiado</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="coordenadorColegiado?.id">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>#</th>
              <th>Foto</th>
              <th>Nome</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{coordenadorColegiado.id}}</td>
              <td>
                <img class="profile-user-img img-fluid img-circle"
                  [src]="coordenadorColegiado.thumbnail == null ? 'assets/images/ppl.png' : coordenadorColegiado.thumbnail"
                  alt="Imagem do coordenador de colegiado">
              </td>
              <td>{{coordenadorColegiado.pessoaNome}}</td>
              <td>
                <button class="btn btn-sm btn-danger ml-1"
                  (click)="removerCoordenadorColegiado(coordenadorColegiado.id)"
                  title="Remover Coordenador de Colegiado" alt="Remover Coordenador de Colegiado"><i
                    class="fas fa-trash-alt"></i></button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="card-footer">
      <div class="row justify-content-center" *ngIf="!coordenadorColegiado?.id">
        <button type="submit" [disabled]="coordenadorColegiado?.id" (click)="adicionarCoordenadorColegiado()"
          name="button-add-cord-col" class="form-control btn btn-primary" i18n>Adicionar Coordenador de
          Colegiado</button>
      </div>
    </div>
  </div>
  <listagem-pessoainfo-modal-component [modalId]="'listagem-pessoa-coordenador-colegiado'" [onlyMissionaries]="true"
    [bringNotInformedWayOfLife]="false" [filterByUserAccess]="true" [bringEntitiesWithoutLinkedUser]="false"
    [modalTitle]="'Selecione o Coordenador de Colegiado'" [buttonText]="'Selecionar'"
    [altButtonText]="'Selecionar Coordenador'" (eventEmitter)="selecionarCoordenadorColegiado($event)">
  </listagem-pessoainfo-modal-component>
</ng-container>