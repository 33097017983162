import { GenericService } from 'src/app/core/services/generic.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from "@angular/core";
import { Trabalho } from 'src/app/model/trabalho.model';
import { AceiteTermoTratamentoDados } from 'src/app/model/aceitetermotratamentodados.model';

@Injectable()
export class AceiteTermoTratamentoDadosService extends GenericService<AceiteTermoTratamentoDados> {    
    
    constructor(protected http: HttpClient, protected router:Router) {
        super(http,router);
        this.setEntityType('r/aceitetermotratamentodados');
    }

    async findByPessoaId(id: number): Promise<any> {
        return this.http.get<Trabalho>(this.apiURL+'/porpessoa/'+id, { 'headers' : this.getHeaders() }).toPromise();
    }

    async aceitarTermoVigente(): Promise<any> {
        return this.http.get<Trabalho>(this.apiURL+'/aceitartermovigente', { 'headers' : this.getHeaders() }).toPromise();
    }

    async usuarioAceitouTermo(): Promise<any> {
        return this.http.get<Trabalho>(this.apiURL+'/usuarioaceitoutermovigente', { 'headers' : this.getHeaders() }).toPromise();
    }
    
} 