import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { Router } from '@angular/router';
import { PessoaBuilder } from 'src/app/builder/pessoa.builder';
import { environment } from "src/environments/environment";
export class PessoaComponent {
    constructor(pessoaService, missaoService, paisService, elementoGrupoDominioService, swtAlert2Service, router, usuarioService) {
        this.pessoaService = pessoaService;
        this.missaoService = missaoService;
        this.paisService = paisService;
        this.elementoGrupoDominioService = elementoGrupoDominioService;
        this.swtAlert2Service = swtAlert2Service;
        this.router = router;
        this.usuarioService = usuarioService;
        this.missoes = new Array();
        this.paises = new Array();
        this.estados = new Array();
        this.cidades = new Array();
        this.estadosCivil = new Array();
        this.tipoIdentidade = new Array();
        this.usuariosPorUsuarioCriador = new Array();
        this.pessoaBuilder = new PessoaBuilder();
        this.pessoa = this.pessoaBuilder.getInstance();
        this.urlBaseImagem = environment.imagem.urlBaseImagem;
    }
    ngOnInit() {
        this.getUsuarioAtual();
        this.carregarPaises();
        this.carregarEstadosCivil();
        this.url = environment.usuario.selectPorUsername;
    }
    getUsuarioAtual() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.usuarioService.getCurrentUser();
                this.usuarioAtual = response.entity;
                this.getUsuariosPorUsuarioCriador();
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    getUsuariosPorUsuarioCriador() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.usuarioService.getUsuariosPorUsuarioCriador();
                this.usuariosPorUsuarioCriador = response.entity;
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    carregarPaises() {
        this.paisService.findAll().then((lista) => {
            this.paises = lista.entity;
        }).catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }
    carregarEstadosCivil() {
        let buscaEstadosCivil = {
            grupoNome: 'ESTADO_CIVIL'
        };
        this.elementoGrupoDominioService.buscarPorGrupoDominioNome(buscaEstadosCivil).then((lista) => {
            this.estadosCivil = lista.entity;
        }).catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }
    carregarMissoes() {
        this.missaoService.findAll().then((lista) => {
            this.missoes = lista.entity;
        }).catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }
    salvar(pessoa) {
        pessoa.dataNascimento = new Date(pessoa.dataNascimento);
        this.pessoaService.create(pessoa).then((response) => {
            this.pessoa = this.pessoaBuilder.getInstance();
            this.swtAlert2Service.successAlert(response.message);
            setTimeout(() => {
                this.router.navigate(['../secured/pessoa/' + response.entity.id]);
            }, 2000);
        }).catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }
    setarElementoSelecionado(valor) {
        this.pessoa.usuarioId = valor;
    }
}
