import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CadastroPublicoComponent } from './cadastro.publico.component';
import { CadastroPublicoRoutingModule } from './cadastro-publico-routing.module';
import { CadastroPublicoLocalService } from './service/cadastro.publico.local.service';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { NgxSummernoteModule } from 'ngx-summernote';
import { TermoUsoBuilder } from 'src/app/builder/termouso.builder';
import { ConfirmacaoCadastroComponent } from './confirmacao.cadastro.component';

@NgModule({
    declarations :[
        CadastroPublicoComponent,
        ConfirmacaoCadastroComponent
    ],
    imports: [
        CadastroPublicoRoutingModule,
        FormsModule,        
        CommonModule,
        NgxSummernoteModule
    ],
    providers: [
        TermoUsoBuilder,
        CadastroPublicoLocalService,
        SwtAlert2Service
    ]
})
export class CadastroPublicoModule {

}