
export class PessoaInfo {

    constructor(
        public id: number,
        public nome: string,
        public sobrenome: string,
        public dataNascimento: Date,
        public imagemId: number,
    ){}

}