import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ConfirmacaoCadastroComponent } from './confirmacao.cadastro.component';
import { ConfirmacaoCadastroRoutingModule } from './confirmacaocadastro.routing.module';
import { ConfirmacaoCadastroService } from './service/confirmacaocadastro.service';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { NgxSummernoteModule } from 'ngx-summernote';
import { TermoUsoBuilder } from 'src/app/builder/termouso.builder';

@NgModule({
    declarations :[
        ConfirmacaoCadastroComponent
    ],
    imports: [
        ConfirmacaoCadastroRoutingModule,
        FormsModule,        
        CommonModule,
        NgxSummernoteModule
    ],
    providers: [
        TermoUsoBuilder,
        ConfirmacaoCadastroService,
        SwtAlert2Service
    ]
})
export class ConfirmacaoCadastroModule {

}