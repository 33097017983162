import { Envio } from '../model/envio.model';
import { AbstractBuilder } from './abstract.builder';
import { Injectable } from '@angular/core';

@Injectable()
export class EnvioBuilder extends AbstractBuilder<Envio> {

    reset() : void {
        this.entity = new Envio(null,null,null,null,null,null,null,null,null,null,null,null,null);
    }

}