import { Component, OnInit } from '@angular/core';
import { PermissaoLocalService } from './service/permissao.local.service';
import { Permissao } from 'src/app/model/permissao.model';
import { Router, ActivatedRoute } from '@angular/router';
import { Autorizacao } from 'src/app/model/autorizacao.model';
import { AutorizacaoLocalService } from '../autorizacao/service/autorizacao.local.service';
import { PermissaoBuilder } from 'src/app/builder/permissao.builder';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';

@Component({
    selector: 'permissao-local-edit',
    templateUrl: './permissao.local.edit.component.html'
})
export class PermissaoLocalEditComponent implements OnInit {

    public permissao: Permissao;
    public permissaoId: number;

/*
    public alerta : Alerta;
    public alertaAutorizacoes: Alerta;
    public alertaVinculacao: Alerta;
    */

    public autorizacoes: Array<Autorizacao>;
    public autorizacoesNaoVinculadas: Array<Autorizacao>;
    public busca: any;
    public totalDePaginas: number;
    public autorizacaoSelecionada: Autorizacao;
    public permissaoBuilder: PermissaoBuilder;

    constructor(
        public permissaoService: PermissaoLocalService,
        public autorizacaoService: AutorizacaoLocalService,
        public swtAlert2Service: SwtAlert2Service,
        public route: ActivatedRoute,
        public router: Router
    ) 
    {
        this.permissaoBuilder = new PermissaoBuilder;
        this.permissao = this.permissaoBuilder.getInstance();
        this.autorizacoes = new Array<Autorizacao>();
        this.autorizacoesNaoVinculadas = new Array<Autorizacao>();
        this.busca = {
            permissaoId: this.permissaoId,
            pageNumber: 0,
            quantityOfElements: 10
        };
        this.autorizacaoSelecionada = null;
    }

    ngOnInit() {        
        this.permissaoId = Number(this.route.snapshot.paramMap.get('id'));
        this.carregarPermissao(this.permissaoId);
        this.carregarAutorizacoesNaoVinculadas(this.permissaoId);
        this.buscarAutorizacoesPorPermissao();
    }

    vincularAutorizacao(): void {

        if (this.autorizacaoSelecionada != null) {

            let vincularForm = {
                permissaoId: this.permissaoId,
                autorizacaoId: this.autorizacaoSelecionada
            }

            this.autorizacaoService.vincular(vincularForm).then((response: any) => {

                this.carregarAutorizacoesNaoVinculadas(this.permissaoId);
                this.buscarAutorizacoesPorPermissao();
                this.autorizacaoSelecionada = null;
                this.swtAlert2Service.successAlert(response.message);

            }).catch((err: any) => {
                this.swtAlert2Service.errorAlert(err.error.errors);
            });

        }
        else {
            this.swtAlert2Service.warningAlert('Selecione uma autorização');
        }

    }

    desvincularAutorizacao(id: number): void {

        if (confirm('Deseja desvincular esta autorizacao?')) {

            let vincularForm = {
                permissaoId: this.permissaoId,
                autorizacaoId: id
            }

            this.autorizacaoService.desvincular(vincularForm).then((response: any) => {

                this.carregarAutorizacoesNaoVinculadas(this.permissaoId);
                this.buscarAutorizacoesPorPermissao();
                this.autorizacaoSelecionada = null;

                this.swtAlert2Service.successAlert(response.message);
            }).catch((err: any) => {
                this.swtAlert2Service.errorAlert(err.error.errors);
            });

        }

    }

    carregarAutorizacoesNaoVinculadas(id: number): void {
        this.autorizacaoService.buscarAutorizacoesNaoVinculadasPorPermissaoId(id).then((response: any) => {
            this.autorizacoesNaoVinculadas = response.entity;
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }

    carregarPermissao(id: number): void {
        this.permissaoService.find(id).then((response: any) => {
            this.permissao = response.entity;           
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }

    salvar(permissao: Permissao) : void {

        this.permissaoService.update(permissao.id,permissao).then( (response:any) => {
            this.swtAlert2Service.successAlert(response.message);
            setTimeout(() => {
                this.router.navigate(['../secured/local/permissoes']);
            },2000); 

        }).catch( (err:any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }

    buscarAutorizacoesPorPermissao(): void {

        this.busca.permissaoId = this.permissaoId;

        this.autorizacaoService.buscarAutorizacoesPorPermissaoId(this.busca).then((response: any) => {
            this.autorizacoes = response.content;
            this.totalDePaginas = response.totalPages;
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }

    cliqueNoBotaoListener(botao) {
        this.busca.pageNumber = botao.numero - 1;
        this.buscarAutorizacoesPorPermissao();
    }

}