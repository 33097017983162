import { Injectable } from '@angular/core';
import { SalvarMissionarioMissaoApostoladoAtual } from '../model/salvarmissionariomissaoapostoladoatual.model';
import { AbstractBuilder } from './abstract.builder';

@Injectable()
export class SalvarMissionarioMissaoApostoladoAtualBuilder extends AbstractBuilder<SalvarMissionarioMissaoApostoladoAtual> {

    reset() : void {
        this.entity = new SalvarMissionarioMissaoApostoladoAtual(null,null,null,null);
    }

}