import { GenericService } from 'src/app/core/services/generic.service';
import { HttpClient } from '@angular/common/http';
import { Pais } from 'src/app/model/pais.model';
import { Router } from '@angular/router';
import { Injectable } from "@angular/core";


@Injectable()
export class PaisService extends GenericService<Pais> {
    
    constructor(protected http: HttpClient, protected router:Router) {
        super(http,router);
        this.setEntityType('r/pais');
    }

    buscarPaises(busca: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/buscar', JSON.stringify(busca), { 'headers' : this.getHeaders() }).toPromise();
    }

}