import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SaudeEditComponent } from './saude.edit.component';

const pessoaRoutes: Routes = [
    {
        path: 'secured/saude',
        component: SaudeEditComponent
    }
] 

@NgModule({
    imports: [
        RouterModule.forChild(pessoaRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class SaudeRoutingModule {
    
}