import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { GenericService } from 'src/app/core/services/generic.service';
export class LoginService extends GenericService {
    constructor(http, router, swtAlert2Service) {
        super(http, router);
        this.http = http;
        this.router = router;
        this.swtAlert2Service = swtAlert2Service;
    }
    fazerLogin(login) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                localStorage.clear();
                sessionStorage.clear();
                let response = yield this.getToken(login);
                // this.router.navigate(['/', 'secured']);
                window.location.replace("secured");
            }
            catch (e) {
                if (e.error.error === "unauthorized")
                    this.swtAlert2Service.errorAlert('Acesso Negado' + ' - ' + e.error.error_description);
                else
                    this.swtAlert2Service.errorAlert(e.error.error + ' - ' + e.error.error_description);
                console.log(e);
            }
        });
    }
    fazerLogout() {
        localStorage.clear();
        sessionStorage.clear();
        this.token = undefined;
        this.router.navigate(['/', 'login']);
        //window.location.replace("/");
    }
}
