import * as tslib_1 from "tslib";
import { GenericService } from 'src/app/core/services/generic.service';
export class ElementoGrupoDominioService extends GenericService {
    constructor(http, router) {
        super(http, router);
        this.http = http;
        this.router = router;
        this.setEntityType('r/elementogrupodominio');
    }
    buscarElementosGrupoDominio(busca) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http.post(this.apiURL + '/buscar', JSON.stringify(busca), { 'headers': this.getHeaders() }).toPromise();
        });
    }
    buscarPorGrupoDominioNome(busca) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http.post(this.apiURL + '/pornomegrupo', JSON.stringify(busca), { 'headers': this.getHeaders() }).toPromise();
        });
    }
    getAllNotInAcessoUsuarioEstrutura(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http.get(`${this.apiURL}/getallnotinacessousuarioestrutura/${id}`, { 'headers': this.getHeaders() }).toPromise();
        });
    }
    getAllFromAcessoUsuarioEstrutura(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http.get(`${this.apiURL}/getallfromacessousuarioestrutura/${id}`, { 'headers': this.getHeaders() }).toPromise();
        });
    }
}
