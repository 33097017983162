import { Component, OnDestroy, OnInit } from '@angular/core';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { Page } from 'src/app/model/page.model';
import {
  MissaoConfigService,
  MissaoConfiguracao,
  QuestionarioConfiguracao,
} from '../../service/missao-config.service';
import { MissaoConfiguracaoSharedDataService } from '../../service/missao-configuracao-shared-data.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-listagem-periodo-liberacao-questionario-parecer',
  templateUrl:
    './listagem-periodo-liberacao-questionario-parecer.component.html',
  styleUrls: [
    './listagem-periodo-liberacao-questionario-parecer.component.css',
  ],
})
export class ListagemPeriodoLiberacaoQuestionarioParecerComponent
  implements OnInit, OnDestroy
{
  missaoConfiguracao?: MissaoConfiguracao = null;
  paginaPeriodoLiberacao?: Page<QuestionarioConfiguracao>;
  unsubscribe$ = new Subject<null>();

  constructor(
    private missaoConfiguracaoSharedDataService: MissaoConfiguracaoSharedDataService,
    private missaoConfigService: MissaoConfigService,
    private swtAlert: SwtAlert2Service
  ) {}

  ngOnInit() {
    this.missaoConfiguracaoSharedDataService.missaoConfiguracao$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((missaoConfiguracao: MissaoConfiguracao) => {
        this.missaoConfiguracao = missaoConfiguracao;
        if (this.missaoConfiguracao) {
          this.carregarConfiguracaoQuestionario();
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  carregarConfiguracaoQuestionario() {
    this.missaoConfigService
      .obterPaginaPorMissaoConfiguracaoId(this.missaoConfiguracao.id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((page) => {
        this.paginaPeriodoLiberacao = page;
      });
  }

  atualizarPeriodo(periodo: QuestionarioConfiguracao) {
    this.missaoConfigService
      .atualizarQuestionarioConfiguracao(periodo)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        () => {
          this.swtAlert.successAlert(
            'Configuração do questionário atualizada com sucesso!'
          );
          this.carregarConfiguracaoQuestionario();
        },
        (error) => {
          this.swtAlert.errorAlert(error.error.errors);
        }
      );
  }
}
