import { NgModule } from '@angular/core';
import { AutorizacaoComponent } from './autorizacao.component';
import { Routes, RouterModule } from '@angular/router';
import { AutorizacoesComponent } from './autorizacoes.component';
import { AutorizacaoEditComponent } from './autorizacao.edit.component';

const autorizacaoRoutes: Routes = [
    {
        path: 'secured/autorizacao',
        component: AutorizacaoComponent
    },
    {
        path: 'secured/autorizacoes',
        component: AutorizacoesComponent
    },
    {
        path: 'secured/autorizacao/:id',
        component: AutorizacaoEditComponent
    }
]

@NgModule({
    imports: [
        RouterModule.forChild(autorizacaoRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class AutorizacaoRoutingModule {
    
}