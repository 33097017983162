import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
export class HeaderComponent {
    constructor(loginService, usuarioService, pessoaService, loggedInUserSharedDataService) {
        this.loginService = loginService;
        this.usuarioService = usuarioService;
        this.pessoaService = pessoaService;
        this.loggedInUserSharedDataService = loggedInUserSharedDataService;
    }
    ngOnInit() {
        this.initialize();
    }
    initialize() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.loadLoggedInUser();
            if (this.loggedInUserObject) {
                yield this.loadLoggedInPerson();
            }
        });
    }
    loadLoggedInUser() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield this.usuarioService.getCurrentUser();
                if (response.entity) {
                    this.loggedInUserObject = response.entity;
                    this.loggedInUserSharedDataService.updateLoggedInUser(this.loggedInUserObject);
                }
            }
            catch (error) {
                console.log(error);
            }
        });
    }
    loadLoggedInPerson() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield this.pessoaService.buscaPorUsuarioId(this.loggedInUserObject.id);
                if (response.entity) {
                    this.loggedInUserSharedDataService.updateLoggedInPerson(response.entity);
                }
            }
            catch (error) {
                console.log(error);
            }
        });
    }
    logout() {
        this.loggedInUserSharedDataService.updateLoggedInUser(null);
        this.loggedInUserSharedDataService.updateLoggedInPerson(null);
        this.loginService.fazerLogout();
    }
}
