import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { BemVindoComponent } from './bemvindo.component';
import { BemVindoRoutingModule } from './bemvindo-routing.module';
import { SecuredModule } from '../../layout/secured.module';

@NgModule({
    declarations :[
        BemVindoComponent
    ],
    imports: [
        BemVindoRoutingModule,
        FormsModule,        
        CommonModule,
        SecuredModule
    ],
    providers: [
        SwtAlert2Service
    ]
})
export class BemVindoModule {

}