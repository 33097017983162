import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-message-content-component',
  templateUrl: './message-content-component.component.html',
  styleUrls: ['./message-content-component.component.css'],
})
export class MessageContentComponentComponent implements OnInit {
  @Input()
  source: string;

  @Input()
  message: string;

  constructor() {}

  ngOnInit() {}
}
