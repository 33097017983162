import { Component, OnInit } from '@angular/core';
import { Alerta } from 'src/app/model/alerta.model';
import { Router, ActivatedRoute } from '@angular/router';
import { Regional } from 'src/app/model/regional.model';
import { Pais } from 'src/app/model/pais.model';
import { Estado } from 'src/app/model/estado.model';
import { Cidade } from 'src/app/model/cidade.model';
import { ElementoGrupoDominio } from 'src/app/model/elementogrupodominio.model';
import { RegionalService } from '../regional/service/regional.service';
import { ElementoGrupoDominioService } from '../grupodominio/service/elementogrupodominio.service';
import { PaisService } from '../pais/service/pais.service';
import { EstadoService } from '../estado/service/estado.service';
import { CidadeService } from '../cidade/service/cidade.service';
import { Difusao } from 'src/app/model/difusao.model';
import { DifusaoService } from './service/difusao.service';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { DifusaoBuilder } from 'src/app/builder/difusao.builder';

@Component({
    selector: 'difusao-edit',
    templateUrl: './difusao.component.html'
})
export class DifusaoEditComponent implements OnInit {

    public difusaoId: number;
    public difusao: Difusao;
    public regionais: Array<Regional>;
    public paises: Array<Pais>;
    public estados: Array<Estado>;
    public cidades: Array<Cidade>;
    public paisSelecionadoId: number;
    public estadoSelecionadoId: number;
    public fasesDifusao: Array<ElementoGrupoDominio>;
    public etapasDifusao: Array<ElementoGrupoDominio>;
    public difusaoBuilder: DifusaoBuilder;

    constructor(
        public difusaoService: DifusaoService,
        public regionalService: RegionalService,
        public elementoGrupoDominioService: ElementoGrupoDominioService,
        public paisService: PaisService,
        public estadoService: EstadoService,
        public cidadeService: CidadeService,
        public route: ActivatedRoute,
        public router: Router,
        public swtAlert2Service: SwtAlert2Service
    ) 
    {
        this.difusaoBuilder = new DifusaoBuilder();
        this.difusao = this.difusaoBuilder.getInstance();
        this.regionais = new Array<Regional>();
        this.paisSelecionadoId = null;
        this.estadoSelecionadoId = null;
        this.paises = new Array<Pais>();
        this.estados = new Array<Estado>();
        this.cidades = new Array<Cidade>();
        this.fasesDifusao = new Array<ElementoGrupoDominio>();
        this.etapasDifusao = new Array<ElementoGrupoDominio>();
    }

    ngOnInit() {  
        this.difusaoId = Number(this.route.snapshot.paramMap.get('id'));
        this.carregarRegionais();   
        this.carregarFasesDifusao();  
        this.carregarEtapasDifusao();
        this.carregarPaises();         
    }

    carregarDifusao(id: number): void {
        this.difusaoService.find(id).then((response: any) => {
            this.difusao = response.entity;
            this.paisSelecionadoId = response.entity.cidadeEstadoPaisId;
            this.carregarEstados();            
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }

    carregarRegionais() : void {
        this.regionalService.findAll().then( (lista: any) => {
            this.regionais = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    carregarEtapasDifusao() : void {

        let buscaFase = {
            grupoNome : 'ETAPA_DIFUSAO'
        }

        this.elementoGrupoDominioService.buscarPorGrupoDominioNome(buscaFase).then( (lista: any) => {
            this.etapasDifusao = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    carregarFasesDifusao() : void {

        let buscaFase = {
            grupoNome : 'FASE_DIFUSAO'
        }

        this.elementoGrupoDominioService.buscarPorGrupoDominioNome(buscaFase).then( (lista: any) => {
            this.fasesDifusao = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    carregarPaises() : void {
        this.paisService.findAll().then( (lista: any) => {
            this.paises = lista.entity;      
            this.carregarDifusao(this.difusaoId);      
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    carregarEstados() : void {

        let busca = {
            paisId: this.paisSelecionadoId,
            nome: undefined,
            pageNumber : 0, 
            quantityOfElements : 1000
        };

        this.estadoService.buscarEstados(busca).then( (lista: any) => {
            this.estados = lista.entity;
            this.estadoSelecionadoId = this.difusao.cidadeEstadoId;
            this.carregarCidades();
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });

    }

    carregarCidades() : void {

        let busca = {
            estadoId: this.estadoSelecionadoId,
            nome: undefined,
            pageNumber : 0, 
            quantityOfElements : 1000
        };

        this.cidadeService.buscarCidades(busca).then( (lista: any) => {
            this.cidades = lista.entity;
        }).catch( (err: any) => {
            console.log(err);
        });

    }

    salvar(difusao: Difusao) : void {

        difusao.dataFundacao = new Date(difusao.dataFundacao);

        this.difusaoService.update(difusao.id,difusao).then( (response:any) => {
            this.difusao = new Difusao(null,null,null,null,null,null,null,null,null);
            this.paisSelecionadoId = null;
            this.estadoSelecionadoId = null;
            this.swtAlert2Service.successAlert(response.message);
            setTimeout(() => {
                this.router.navigate(['../secured/difusoes']);
            }, 2000);
        }).catch( (err:any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }


}