import { GenericService } from 'src/app/core/services/generic.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from "@angular/core";
import { MembroComunidadeAlianca } from 'src/app/model/membrocomunidadealianca.model';

@Injectable()
export class MembroComunidadeAliancaService extends GenericService<MembroComunidadeAlianca> {    
    
    constructor(protected http: HttpClient, protected router:Router) {
        super(http,router);
        this.setEntityType('r/membroca');
    }

} 