<div class="user-panel mt-3 pb-3 mb-3 d-flex">
  <div class="image">
    <img class="img-circle elevation-2" [src]="thumbnail == null ? 'assets/images/ppl.png' : thumbnail"
      alt="User Avatar">
  </div>
  <div class="info">
    <a [routerLink]="'/secured/perfil'" class="d-block">
      <small>{{nome != null ? nome : ''}}</small>
    </a>
  </div>
</div>