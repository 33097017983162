import { Component, OnInit, ViewChild } from '@angular/core';
import { ElementoGrupoDominio } from 'src/app/model/elementogrupodominio.model';
import { Pessoa } from 'src/app/model/pessoa.model';
import { DadoVocacional } from 'src/app/model/dadovocacional.model';
import { PessoaBuilder } from 'src/app/builder/pessoa.builder';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { Usuario } from 'src/app/model/usuario.model';
import { RespostaParecer } from 'src/app/model/respostaparecer.model';
import { ParecerResponsavel } from 'src/app/model/parecerresponsavel.model';
import { QuestionarioFormando } from 'src/app/model/questionarioformando.model';
import { Estado } from 'src/app/model/estado.model';
import { Pais } from 'src/app/model/pais.model';
import { Cidade } from 'src/app/model/cidade.model';
import { CaminhoFormativo } from 'src/app/model/caminhoformativo.model';
import { PessoaInfo } from 'src/app/model/pessoainfo.model';
import { Missao } from 'src/app/model/missao.model';
import { DadoFormandoCABuilder } from 'src/app/builder/dadoformandoca.builder';
import { CaminhoFormativoBuilder } from 'src/app/builder/caminhoformativo.builder';
import { QuestionarioFormandoBuilder } from 'src/app/builder/questionarioformando.builder';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { RespostaParecerBuilder } from 'src/app/builder/respostaparecer.builder';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { PessoaInfoBuilder } from 'src/app/builder/pessoainfo.builder';
import { CaminhoFormativoService } from '../service/caminhoformativo.service';
import { QuestionarioFormandoService } from '../../questionario/service/questionarioformando.service';
import { ParecerResponsavelService } from '../service/parecerresponsavel.service';
import { RespostaParecerService } from '../service/respostaparecer.service';
import { WopGetAllService } from 'src/app/service/getall.service';
import { DadoFormandoCA } from 'src/app/model/dadoformandoca.model';
import { DadoFormandoCAService } from '../service/dadoformandoca.service';
import { DadoFormandoCV } from 'src/app/model/dadoformandocv.model';
import { NgForm } from '@angular/forms';
import { PessoaService } from 'src/app/secured/wop/pessoa/service/pessoa.service';
import { ImagemService } from 'src/app/secured/wop/imagem/service/imagem.service';
import { PaisService } from 'src/app/secured/wop/pais/service/pais.service';
import { EstadoService } from 'src/app/secured/wop/estado/service/estado.service';
import { CidadeService } from 'src/app/secured/wop/cidade/service/cidade.service';
import { ElementoGrupoDominioService } from 'src/app/secured/wop/grupodominio/service/elementogrupodominio.service';
import { ElementoGrupoDominioLocalService } from '../../grupodominio/service/elementogrupodominio.local.service';
import { MissaoService } from 'src/app/secured/wop/missao/service/missao.service';
import { UsuarioService } from 'src/app/secured/wop/usuario/service/usuario.service';
import { DadoVocacionalService } from 'src/app/secured/wop/dadosvocacionais/service/dadovocacional.service';

@Component({
  selector: 'app-parecer-cal-fc-answer',
  templateUrl: './parecer-cal-fc-answer.component.html',
  styleUrls: [],
})
export class ParecerCALFCAnswerComponent implements OnInit {
  @ViewChild('dadosPessoaisForm', { static: true })
  dadosPessoaisForm!: NgForm;

  public pessoaResponsavel: Pessoa;
  public missoesVinculo: Array<Missao>;
  public missoes: Array<Missao>;
  public pessoaFormandoId: number;
  public tabNumber: number;
  public imageChangedEvent: any;
  public croppedImage: any;
  public nextClicked: boolean;
  public url: string;
  public intlTelInputCalled: boolean = false;
  public intlTellInputInstance: any;
  public formasVida: Array<ElementoGrupoDominio>;
  public niveisFormacao: Array<ElementoGrupoDominio>;
  public pessoaFormadorPessoalSelecionado: PessoaInfo;
  public dadoVocacional: DadoVocacional;
  public dadoFormandoCA: DadoFormandoCA;
  public dadoFormandoCV: DadoFormandoCV;
  public caminhoFormativoFormando: CaminhoFormativo;
  public urlBaseImagem: string;
  public usuarioAtual: Usuario;
  public formando: Pessoa;

  public imageUrl: any;

  public tabNames: Array<string>;
  public activeTabNumber: number;

  public paises: Array<Pais>;
  public estados: Array<Estado>;
  public cidades: Array<Cidade>;

  public parecerResponsavelId: number;
  public parecerResponsavel: ParecerResponsavel;
  public questionarioFormando: QuestionarioFormando;

  public respostas: Array<RespostaParecer>;

  public resposta21: RespostaParecer;
  public resposta22: RespostaParecer;
  public resposta23: RespostaParecer;
  public resposta24: RespostaParecer;
  public resposta25: RespostaParecer;
  public resposta26: RespostaParecer;
  public resposta27: RespostaParecer;
  public resposta28: RespostaParecer;
  public resposta29: RespostaParecer;
  public resposta30: RespostaParecer;
  public resposta31: RespostaParecer;
  public resposta32: RespostaParecer;
  public resposta33: RespostaParecer;
  public resposta34: RespostaParecer;
  public resposta35: RespostaParecer;

  constructor(
    public wopGetAllPublic: WopGetAllService,
    public pessoaService: PessoaService,
    public imagemService: ImagemService,
    public paisService: PaisService,
    public estadoService: EstadoService,
    public cidadeService: CidadeService,
    public elementoGrupoDominioService: ElementoGrupoDominioService,
    public elementoGrupoDominioLocalService: ElementoGrupoDominioLocalService,
    public missaoService: MissaoService,
    public swtAlert2Service: SwtAlert2Service,
    public usuarioService: UsuarioService,
    public pessoaBuilder: PessoaBuilder,
    public pessoaInfoBuilder: PessoaInfoBuilder,
    public dadoVocacionalService: DadoVocacionalService,
    public dadoFormandoCAService: DadoFormandoCAService,
    public caminhoFormativoService: CaminhoFormativoService,
    public respostaParecerService: RespostaParecerService,
    public questionarioFormandoService: QuestionarioFormandoService,
    public parecerResponsavelService: ParecerResponsavelService,
    public dadoFormandoCABuilder: DadoFormandoCABuilder,
    public caminhoFormativoFormandoBuilder: CaminhoFormativoBuilder,
    public respostaParecerBuilder: RespostaParecerBuilder,
    public questionarioFormandoBuilder: QuestionarioFormandoBuilder,
    public route: ActivatedRoute,
    public router: Router
  ) {
    this.missoesVinculo = new Array<Missao>();
    this.missoes = new Array<Missao>();
    this.formasVida = new Array<ElementoGrupoDominio>();
    this.niveisFormacao = new Array<ElementoGrupoDominio>();
    this.dadoFormandoCA = this.dadoFormandoCABuilder.getInstance();
    this.caminhoFormativoFormando =
      this.caminhoFormativoFormandoBuilder.getInstance();
    this.imageChangedEvent = '';
    this.croppedImage = '';
    this.nextClicked = false;

    this.pessoaResponsavel = this.pessoaBuilder.getInstance();
    this.formando = this.pessoaBuilder.getInstance();

    this.urlBaseImagem = environment.imagem.urlBaseImagem;
    this.url = environment.usuario.selectPorUsername;

    this.questionarioFormando = this.questionarioFormandoBuilder.getInstance();

    this.tabNames = ['dados-pessoais-tab-content', 'questionario-tab-content'];

    this.activeTabNumber = 0;

    this.resposta21 = respostaParecerBuilder.getInstance();
    this.resposta22 = respostaParecerBuilder.getInstance();
    this.resposta23 = respostaParecerBuilder.getInstance();
    this.resposta24 = respostaParecerBuilder.getInstance();
    this.resposta25 = respostaParecerBuilder.getInstance();
    this.resposta26 = respostaParecerBuilder.getInstance();
    this.resposta27 = respostaParecerBuilder.getInstance();
    this.resposta28 = respostaParecerBuilder.getInstance();
    this.resposta29 = respostaParecerBuilder.getInstance();
    this.resposta30 = respostaParecerBuilder.getInstance();
    this.resposta31 = respostaParecerBuilder.getInstance();
    this.resposta32 = respostaParecerBuilder.getInstance();
    this.resposta33 = respostaParecerBuilder.getInstance();
    this.resposta34 = respostaParecerBuilder.getInstance();
    this.resposta35 = respostaParecerBuilder.getInstance();
  }

  ngOnInit(): void {
    this.initialization();
  }

  imprimirValorDoForm() {
    console.log('Valor do Form', JSON.stringify(this.dadosPessoaisForm.value));
  }

  async initialization(): Promise<any> {
    await this.getUsuarioAtual();
    this.carregarFormasVida();
    this.carregarNiveisFormacao();

    await this.carregarMissoesVinculo();
    this.parecerResponsavelId = Number(this.route.snapshot.paramMap.get('id'));
    await this.carregarParecerResponsavel();
    await this.carregarQuestionarioFormando();
    await this.carregarPessoaFormando(this.questionarioFormando.pessoaId);
    this.pessoaResponsavel = await this.carregarPessoaResponsavel(
      this.usuarioAtual.id
    );
    await this.carregarDadoVocacional();
    await this.carregarDadoFormandoCA();
    await this.carregarCaminhoFormativo();
    await this.carregarRespostas();
    // this.imprimirValorDoForm();
  }

  async getUsuarioAtual(): Promise<any> {
    try {
      let response = await this.usuarioService.getCurrentUser();
      this.usuarioAtual = response.entity;
    } catch (e) {
      console.log(e);
    }
  }

  async carregarMissoesVinculo(): Promise<any> {
    try {
      let response = await this.wopGetAllPublic.getAllMissao();
      this.missoesVinculo = response.entity;
      this.missoesVinculo = this.missoesVinculo.sort((a: Missao, b: Missao) =>
        a.nome.localeCompare(b.nome)
      );
    } catch (e) {
      this.swtAlert2Service.errorAlert(e.error.errors);
    }
  }

  async carregarPessoaFormando(pessoaId: number): Promise<any> {
    try {
      let response = await this.pessoaService.buscaPorId(pessoaId);

      this.formando = response.entity;

      this.pessoaFormandoId = this.formando.id;

      if (this.formando.imagemId != null) {
        let blogImage = await this.getPessoaImagem(this.formando.imagemId);

        this.imagemService.createImage(blogImage, this.formando);
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async carregarPessoaResponsavel(usuarioId: number): Promise<any> {
    try {
      let response = await this.pessoaService.buscaPorUsuarioId(usuarioId);

      return response.entity;
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  carregarFormasVida(): void {
    let buscaFormaVida = {
      grupoNome: 'FORMA_VIDA',
    };

    this.elementoGrupoDominioService
      .buscarPorGrupoDominioNome(buscaFormaVida)
      .then((lista: any) => {
        this.formasVida = lista.entity;
      })
      .catch((err: any) => {
        this.swtAlert2Service.errorAlert(err.error.errors);
      });
  }

  carregarNiveisFormacao(): void {
    let busca = {
      grupoNome: 'NIVEL_FORMACAO',
    };

    this.elementoGrupoDominioService
      .buscarPorGrupoDominioNome(busca)
      .then((lista: any) => {
        this.niveisFormacao = lista.entity;
      })
      .catch((err: any) => {
        this.swtAlert2Service.errorAlert(err.error.errors);
      });
  }

  async carregarPaises(): Promise<any> {
    try {
      let response = await this.paisService.findAll();
      this.paises = response.entity;
    } catch (e) {
      this.swtAlert2Service.errorAlert(e.error.errors);
    }
  }

  async carregarEstados(paisId: number): Promise<any> {
    try {
      let request = {
        paisId: paisId,
        nome: undefined,
        pageNumber: 0,
        quantityOfElements: 1000,
      };
      let response = await this.estadoService.buscarEstados(request);
      this.estados = response.entity;
    } catch (e) {
      this.swtAlert2Service.errorAlert(e.error.errors);
    }
  }
  async carregarCidades(estadoId: number): Promise<any> {
    try {
      let request = {
        estadoId: estadoId,
        nome: undefined,
        pageNumber: 0,
        quantityOfElements: 1000,
      };
      let response = await this.cidadeService.buscarCidades(request);
      this.cidades = response.entity;
    } catch (e) {
      this.swtAlert2Service.errorAlert(e.error.errors);
    }
  }

  logOnConsole(dadosPessoaisForm: any): void {
    console.log(dadosPessoaisForm);
  }

  activateTab(tab: string): void {
    $('.nav-tabs a[href="#' + tab + '"]').removeClass('disabled');
    $('.nav-tabs a[href="#' + tab + '"]').tab('show');
  }

  activateTab2(action: string): void {
    if (action.localeCompare('previous') == 0) {
      if (this.activeTabNumber > 0) {
        this.activeTabNumber -= 1;
      }
    } else if (action.localeCompare('next') == 0) {
      if (this.activeTabNumber < this.tabNames.length - 1) {
        this.activeTabNumber += 1;
      }
    }
    $(
      '.nav-tabs a[href="#' + this.tabNames[this.activeTabNumber] + '"]'
    ).removeClass('disabled');
    $('.nav-tabs a[href="#' + this.tabNames[this.activeTabNumber] + '"]').tab(
      'show'
    );
  }

  async salvar(): Promise<any> {
    //this.atualizarCaminhoFormativo();
    this.atualizarParecerResponsavel('INICIADO');
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    console.log(event);
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  imageLoaded() {
    console.log('Imagem carregada');
  }

  cropperReady() {
    console.log('Imagem cortada');
  }

  loadImageFailed() {
    console.log('Carregamento da imagem falhou!');
  }

  async carregarDadoVocacional(): Promise<any> {
    try {
      let response = await this.dadoVocacionalService.porPessoaId(
        this.formando.id
      );
      if (response.entity != undefined && response.entity != null) {
        this.dadoVocacional = response.entity;
        this.pessoaFormadorPessoalSelecionado =
          this.pessoaInfoBuilder.getInstance();
        this.pessoaFormadorPessoalSelecionado.nome =
          this.dadoVocacional.formadorPessoalPessoaNome;
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async selecionarFormadorComunitario(pessoa: PessoaInfo): Promise<any> {
    //this.dadoVocacional.formadorPessoalPessoaId = pessoa.id;
    //this.pessoaFormadorComunitarioSelecionado = pessoa;
    //$('#listagem-pessoa-formador-comunitario').modal('hide');
  }

  async getPessoaImagem(id: number): Promise<any> {
    try {
      let response = await this.imagemService.getPessoaPhoto(id);
      return response.body;
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async carregarDadoFormandoCA(): Promise<any> {
    try {
      let response = await this.dadoFormandoCAService.porPessoaId(
        this.formando.id
      );
      if (response != null && response != undefined) {
        this.dadoFormandoCA = response;
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async carregarCaminhoFormativo(): Promise<any> {
    try {
      let response = await this.caminhoFormativoService.porPessoaId(
        this.formando.id
      );
      if (response != null && response != undefined) {
        this.caminhoFormativoFormando = response;
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async atualizarCaminhoFormativo(): Promise<any> {
    try {
      if (this.caminhoFormativoFormando.id != null) {
        let response = await this.caminhoFormativoService.atualizar(
          this.caminhoFormativoFormando
        );
        this.caminhoFormativoFormando = response;
        this.swtAlert2Service.successAlert(response.message);
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async carregarRespostas(): Promise<any> {
    try {
      let response = await this.respostaParecerService.porPessoaId(
        this.pessoaFormandoId
      );
      if (response != null && response != undefined) {
        this.respostas = response.content;
        this.respostas.forEach((resposta) => {
          switch (resposta.parecer_pergunta_id) {
            case 21:
              this.resposta21 = resposta;
              break;
            case 22:
              this.resposta22 = resposta;
              break;
            case 23:
              this.resposta23 = resposta;
              break;
            case 24:
              this.resposta24 = resposta;
              break;
            case 25:
              this.resposta25 = resposta;
              break;
            case 26:
              this.resposta26 = resposta;
              break;
            case 27:
              this.resposta27 = resposta;
              break;
            case 28:
              this.resposta28 = resposta;
              break;
            case 29:
              this.resposta29 = resposta;
              break;
            case 30:
              this.resposta30 = resposta;
              break;
            case 31:
              this.resposta31 = resposta;
              break;
            case 32:
              this.resposta32 = resposta;
              break;
            case 33:
              this.resposta33 = resposta;
              break;
            case 34:
              this.resposta34 = resposta;
              break;
            case 35:
              this.resposta35 = resposta;
              break;
          }
        });
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async carregarParecerResponsavel(): Promise<any> {
    try {
      let response = await this.parecerResponsavelService.buscarPorId(
        this.parecerResponsavelId
      );
      if (response != null && response != undefined) {
        this.parecerResponsavel = response;
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async carregarQuestionarioFormando(): Promise<any> {
    try {
      let response = await this.questionarioFormandoService.buscarPorId(
        this.parecerResponsavel.questionarioFormandoId
      );
      if (response != null && response != undefined) {
        this.questionarioFormando = response;
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async atualizarResposta(resposta: RespostaParecer): Promise<any> {
    try {
      resposta.pessoaId = this.pessoaFormandoId;
      if (resposta.id == null) {
        let response = await this.respostaParecerService.cadastrar(resposta);
        this.swtAlert2Service.successAlert(response.message);
        return response;
      } else {
        let response = await this.respostaParecerService.atualizar(
          resposta.id,
          resposta
        );
        this.swtAlert2Service.successAlert(response.message);
        return response;
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async salvarQuestionario() {
    if (!this.respostaVazia(this.resposta21)) {
      this.resposta21.parecer_pergunta_id = 21;
      this.resposta21 = await this.atualizarResposta(this.resposta21);
    }
    if (!this.respostaVazia(this.resposta22)) {
      this.resposta22.parecer_pergunta_id = 22;
      this.resposta22 = await this.atualizarResposta(this.resposta22);
    }
    if (!this.respostaVazia(this.resposta23)) {
      this.resposta23.parecer_pergunta_id = 23;
      this.resposta23 = await this.atualizarResposta(this.resposta23);
    }
    if (!this.respostaVazia(this.resposta24)) {
      this.resposta24.parecer_pergunta_id = 24;
      this.resposta24 = await this.atualizarResposta(this.resposta24);
    }
    if (!this.respostaVazia(this.resposta25)) {
      this.resposta25.parecer_pergunta_id = 25;
      this.resposta25 = await this.atualizarResposta(this.resposta25);
    }
    if (!this.respostaVazia(this.resposta26)) {
      this.resposta26.parecer_pergunta_id = 26;
      this.resposta26 = await this.atualizarResposta(this.resposta26);
    }
    if (!this.respostaVazia(this.resposta27)) {
      this.resposta27.parecer_pergunta_id = 27;
      this.resposta27 = await this.atualizarResposta(this.resposta27);
    }
    if (!this.respostaVazia(this.resposta28)) {
      this.resposta28.parecer_pergunta_id = 28;
      this.resposta28 = await this.atualizarResposta(this.resposta28);
    }
    if (!this.respostaVazia(this.resposta29)) {
      this.resposta29.parecer_pergunta_id = 29;
      this.resposta29 = await this.atualizarResposta(this.resposta29);
    }
    if (!this.respostaVazia(this.resposta30)) {
      this.resposta30.parecer_pergunta_id = 30;
      this.resposta30 = await this.atualizarResposta(this.resposta30);
    }
    if (!this.respostaVazia(this.resposta31)) {
      this.resposta31.parecer_pergunta_id = 31;
      this.resposta31 = await this.atualizarResposta(this.resposta31);
    }
    if (!this.respostaVazia(this.resposta32)) {
      this.resposta32.parecer_pergunta_id = 32;
      this.resposta32 = await this.atualizarResposta(this.resposta32);
    }
    if (!this.respostaVazia(this.resposta33)) {
      this.resposta33.parecer_pergunta_id = 33;
      this.resposta33 = await this.atualizarResposta(this.resposta33);
    }
    if (!this.respostaVazia(this.resposta34)) {
      this.resposta34.parecer_pergunta_id = 34;
      this.resposta34 = await this.atualizarResposta(this.resposta34);
    }
    if (!this.respostaVazia(this.resposta35)) {
      this.resposta35.parecer_pergunta_id = 35;
      this.resposta35 = await this.atualizarResposta(this.resposta35);
    }

    // let status = this.definirStatusQuestionario();
    const caminhoFormativoSugeridoFC =
      this.caminhoFormativoFormando.caminhoFormativoSugeridoFC;
    await this.atualizarParecerResponsavel('INICIADO');
    if (!this.caminhoFormativoFormando.id) {
      await this.carregarCaminhoFormativo();
      this.caminhoFormativoFormando.caminhoFormativoSugeridoFC =
        caminhoFormativoSugeridoFC;
    }
    this.atualizarCaminhoFormativo();
  }

  definirStatusQuestionario(): string {
    let status = 'INICIADO';

    let todoRespondido = false;

    if (
      !this.respostaVazia(this.resposta21) &&
      !this.respostaVazia(this.resposta22) &&
      !this.respostaVazia(this.resposta23) &&
      !this.respostaVazia(this.resposta24) &&
      !this.respostaVazia(this.resposta25) &&
      !this.respostaVazia(this.resposta26) &&
      !this.respostaVazia(this.resposta27) &&
      !this.respostaVazia(this.resposta28) &&
      !this.respostaVazia(this.resposta29) &&
      !this.respostaVazia(this.resposta30) &&
      !this.respostaVazia(this.resposta31) &&
      !this.respostaVazia(this.resposta32) &&
      // !this.respostaVazia(this.resposta33) && - Aqui é o caminho formativo proposto pelo FC
      !this.respostaVazia(this.resposta34) &&
      !this.respostaVazia(this.resposta35)
    ) {
      todoRespondido = true;
    }

    if (todoRespondido) {
      status = 'ENVIADO';
    }

    return status;
  }

  respostaVazia(resposta: any): boolean {
    return (
      resposta.resposta == null ||
      resposta.resposta == undefined ||
      resposta.resposta == ''
    );
  }

  async enviarQuestionario(): Promise<any> {
    await this.salvarQuestionario();
    await this.atualizarParecerResponsavel('ENVIADO');
    this.router.navigate(['secured/parecer-agradecimento']);
  }

  async atualizarParecerResponsavel(status: string): Promise<any> {
    try {
      if (this.parecerResponsavel.id != null) {
        this.parecerResponsavel.status = status;
        if (status == 'ENVIADO') {
          this.parecerResponsavel.data_envio = new Date();
        }
        let response = await this.parecerResponsavelService.atualizar(
          this.parecerResponsavel.id,
          this.parecerResponsavel
        );
        this.parecerResponsavel = response;
        this.swtAlert2Service.successAlert(response.message);
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }
}
