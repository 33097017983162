import { NgModule } from '@angular/core';
import { PermissaoComponent } from './permissao.component';
import { Routes, RouterModule } from '@angular/router';
import { PermissoesComponent } from './permissoes.component';
import { PermissaoEditComponent } from './permissao.edit.component';

const permissaoRoutes: Routes = [
    {
        path: 'secured/permissao',
        component: PermissaoComponent
    },
    {
        path: 'secured/permissoes',
        component: PermissoesComponent
    },
    {
        path: 'secured/permissao/:id',
        component: PermissaoEditComponent
    }
]

@NgModule({
    imports: [
        RouterModule.forChild(permissaoRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class PermissaoRoutingModule {
    
}