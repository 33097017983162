import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
export class MensagemRlSidebarComponent {
    constructor(mensagemRlSharedStateService, mensagemRLService, imageHandlerService) {
        this.mensagemRlSharedStateService = mensagemRlSharedStateService;
        this.mensagemRLService = mensagemRLService;
        this.imageHandlerService = imageHandlerService;
        this.setTimeoutId = null;
    }
    ngOnInit() {
        this.selectedFilterButtonSubscription =
            this.mensagemRlSharedStateService.currentFilterButton.subscribe((state) => (this.selectedFilterButton = state));
        this.mensagemRlSummaryListSubscription = this.mensagemRLService
            .obterMensagemsParaRLLogado()
            .subscribe((response) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.mensagemRlSummaryList = yield Promise.all(response.map((summary) => this.obterThumbnail(summary)));
            this.displayableSummaryList = this.mensagemRlSummaryList;
        }));
    }
    ngOnDestroy() {
        this.selectedFilterButtonSubscription.unsubscribe();
        this.mensagemRlSummaryListSubscription.unsubscribe();
    }
    obterThumbnail(summary) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            summary =
                yield this.imageHandlerService.inserirThumbnailsEmDetalhesMensagemParaRL(summary);
            return summary;
        });
    }
    setFilterButtonState(newValue) {
        this.mensagemRlSharedStateService.updateSelectedFilterButtonState(newValue);
        this.applyFilterOnSummaryList(newValue);
    }
    applyFilterOnSummaryList(filter) {
        switch (filter) {
            case 'Todas': {
                this.displayableSummaryList = this.mensagemRlSummaryList;
                break;
            }
            case 'Não Lidas': {
                this.displayableSummaryList = this.mensagemRlSummaryList.filter((msg) => msg.possuiMensagensNaoLidas === true);
                break;
            }
            case 'Lidas': {
                this.displayableSummaryList = this.mensagemRlSummaryList.filter((msg) => msg.possuiMensagensNaoLidas === false);
                break;
            }
        }
    }
    selectMemberSummary(memberMessagesSummary) {
        memberMessagesSummary.possuiMensagensNaoLidas = false;
        memberMessagesSummary.quantidadeMensagensNaoLidas = null;
        this.mensagemRlSharedStateService.updateSelectedMemberMessageSummary(memberMessagesSummary);
    }
    filterByInput(event) {
        const inputElement = event.target;
        if (inputElement.value === '') {
            this.applyFilterOnSummaryList(this.selectedFilterButton);
        }
        clearTimeout(this.setTimeoutId);
        this.setTimeoutId = setTimeout(() => {
            this.displayableSummaryList = this.displayableSummaryList.filter((msg) => msg.pessoaNome.toLowerCase().includes(inputElement.value.toLowerCase()));
        }, 300);
    }
}
