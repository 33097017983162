<secured>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h5 i18n><i class="fa fa-list"></i>&nbsp;&nbsp;&nbsp;Questionários</h5>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-12" style='text-align: center'>
                                        <table-component [cardBodyClass]="'table-responsive p-0'" [tableClass]="'table-striped'" [tituloSessao]="'Questionários disponíveis para informar parecer'" [cols]="11" [rows]="busca.quantityOfElements" [totalDePaginas]="totalDePaginas" (eventoBotao)="cliqueNoBotaoListener($event)">
                                            <tr tableheader>                                                
                                                <th>Foto de perfil</th>
                                                <th>Nome</th>                                                
                                                <th>Nível Formativo</th>
                                                <th>Status Questionario</th>
                                                <th></th>
                                            </tr>
                                            <tr tablebody *ngFor="let membro of d2sCasaComunitaria">                                                
                                                <td>
                                                    <div class="widget-user-image">
                                                        <img class="profile-user-img img-fluid img-circle image-size-list elevation-2" [src]="membro.thumbnail == null ? 'assets/images/ppl.png' : membro.thumbnail" alt="User Avatar">
                                                    </div>
                                                </td>
                                                <td>{{membro.pessoaNome}}</td>
                                                <td>{{membro.nivelFormativoNome}}</td>
                                                <td>
                                                    <button *ngIf="membro.statusQuestionario == 'ENVIADO'" class="btn btn-primary" routerLink="/secured/questionariod2-view-answer/{{membro.idQuestionario}}" title="Questionario D2" alt="Questionario D2">Visualizar</button>
                                                    <button *ngIf="membro.statusQuestionario != 'ENVIADO'" class="btn btn-primary" disabled title="Questionario D2" alt="Questionario D2">{{membro.statusQuestionario}}</button>
                                                <td>
                                                    <button *ngIf="membro.statusParecer == 'ENVIADO'" class="btn btn-primary" disabled title="Parecer D2" alt="Parecer D2">Enviado</button>
                                                    <button *ngIf="membro.statusParecer != 'ENVIADO'"class="btn btn-primary" (click)="consultarVinculoQuestionarioD2(membro)" title="Parecer D2" alt="Parecer D2">Responder</button>
                                                </td>
                                            </tr>
                                        </table-component>
                                    </div>
                                </div>
                                <!--div *ngIf="(nivelFormacao != 'D2' && nivelFormacao != 'D2 Ext.')" class="row">
                                    <div class="col-12">
                                        Não há nenhum questionário disponível.
                                    </div>
                                </div-->
                                <!--div div *ngIf="(nivelFormacao == 'D2 Ext')" class="row">
                                    <div class="col-3">
                                        &nbsp;
                                    </div>
                                    <div class="col-6" >
                                        <div class="form-group">
                                            <label>Parecer do Formador Comunitãrio
                                                1º Questionario | Pastoreio | D2 - CV</label>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        <button class="btn btn-primary" routerLink="../questionariod2-ext-answer" title="Questionario D2" alt="Questionario D2">Responder</button>
                                    </div>
                                </div>  
                                <div *ngIf="(nivelFormacao == 'D2' || nivelFormacao == 'D2 Ext.')" class="row">
                                    <div class="col-sm-12 col-md-12 col-lg-12" *ngIf="!verificouDados">                            
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" [(ngModel)]="verificouDados">
                                            <label class="form-check-label">É essencial que você mantenha os seus dados atualizados. Verificou se os seus dados vocacionais e do perfil estão corretos?</label>
                                        </div>                            
                                    </div>
                                </div-->                                                                  
                            </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</secured>