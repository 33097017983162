
import { Cidade } from '../model/cidade.model';
import { AbstractBuilder } from './abstract.builder';

export class CidadeBuilder extends AbstractBuilder<Cidade> {

    reset() : void {
        this.entity = new Cidade(null,null,null,null, null, null);
    }

}