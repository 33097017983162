/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./mensagem-rl.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../layout/secured.component.ngfactory";
import * as i3 from "../../layout/secured.component";
import * as i4 from "../../../login/service/login.service";
import * as i5 from "../../wop/usuario/service/usuario.service";
import * as i6 from "../../layout/routermanager.service";
import * as i7 from "../../../core/services/app-route-change.service";
import * as i8 from "../../wop/aceitetermouso/service/aceitetermouso.service";
import * as i9 from "../../wop/aceitetermotratamentodados/service/aceitetermotratamentodados.service";
import * as i10 from "../cadastropublico/service/cadastro.publico.local.service";
import * as i11 from "../../../core/swtalert2/swtalert2.service";
import * as i12 from "@angular/router";
import * as i13 from "./components/mensagem-rl-sidebar/mensagem-rl-sidebar.component.ngfactory";
import * as i14 from "./components/mensagem-rl-sidebar/mensagem-rl-sidebar.component";
import * as i15 from "./service/mensagem-rl-shared-state.service";
import * as i16 from "./service/mensagemrl.service";
import * as i17 from "./service/image.haddler.service";
import * as i18 from "./components/mensagem-rl-content/mensagem-rl-content.component.ngfactory";
import * as i19 from "./components/mensagem-rl-content/mensagem-rl-content.component";
import * as i20 from "./mensagem-rl.component";
var styles_MensagemRlComponent = [i0.styles];
var RenderType_MensagemRlComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MensagemRlComponent, data: {} });
export { RenderType_MensagemRlComponent as RenderType_MensagemRlComponent };
export function View_MensagemRlComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "secured", [], null, null, null, i2.View_SecuredComponent_0, i2.RenderType_SecuredComponent)), i1.ɵdid(1, 4308992, null, 0, i3.SecuredComponent, [i4.LoginService, i5.UsuarioService, i6.RouterManagerService, i7.AppRouteChangeService, i8.AceiteTermoService, i9.AceiteTermoTratamentoDadosService, i10.CadastroPublicoLocalService, i11.SwtAlert2Service, i12.Router, i1.Renderer2], null, null), (_l()(), i1.ɵeld(2, 0, null, 0, 5, "div", [["class", "content-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-mensagem-rl-sidebar", [], null, null, null, i13.View_MensagemRlSidebarComponent_0, i13.RenderType_MensagemRlSidebarComponent)), i1.ɵdid(5, 245760, null, 0, i14.MensagemRlSidebarComponent, [i15.MensagemRlSharedStateService, i16.MensagemRLService, i17.ImageHandlerService], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "app-mensagem-rl-content", [], null, null, null, i18.View_MensagemRlContentComponent_0, i18.RenderType_MensagemRlContentComponent)), i1.ɵdid(7, 245760, null, 0, i19.MensagemRlContentComponent, [i15.MensagemRlSharedStateService, i16.MensagemRLService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 5, 0); _ck(_v, 7, 0); }, null); }
export function View_MensagemRlComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-mensagem-rl", [], null, null, null, View_MensagemRlComponent_0, RenderType_MensagemRlComponent)), i1.ɵdid(1, 245760, null, 0, i20.MensagemRlComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MensagemRlComponentNgFactory = i1.ɵccf("app-mensagem-rl", i20.MensagemRlComponent, View_MensagemRlComponent_Host_0, {}, {}, []);
export { MensagemRlComponentNgFactory as MensagemRlComponentNgFactory };
