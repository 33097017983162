import { Injectable } from '@angular/core';
import { AbstractBuilder } from './abstract.builder';
import { TermoTratamentoDados } from '../model/termotratamentodados.model';

@Injectable()
export class TermoTratamentoDadosBuilder extends AbstractBuilder<TermoTratamentoDados> {

    reset() : void {
        this.entity = new TermoTratamentoDados(null,null,null,null);
    }

}