<body class="hold-transition login-page">
    <div class="login-box">
        <div class="login-logo">

            <a i18n href="../../login"><b>WoP - </b>MISSIONÁRIA<b></b></a>
        </div>

        <!-- /.login-logo -->
        <div class="card">
            <div class="card-body login-card-body">
                <p i18n class="login-box-msg">Faça o login para iniciar sua sessão</p>

                <form #loginForm="ngForm">
                    <div class="input-group mb-3">
                        <input type="username" class="form-control" placeholder="Username" i18n-placeholder name="username" [(ngModel)]="login.username">
                        <div class="input-group-append input-group-text">
                            <span class="fas fa-user"></span>
                        </div>
                    </div>
                    <div class="input-group mb-3">
                        <input type="{{passwordFieldType}}" class="form-control" placeholder="Password" i18n-placeholder name="password" [(ngModel)]="login.password">
                        <div class="input-group-append input-group-text" (click)="togglePasswordVisibility()">
                            <span class="fas fa-eye"></span>
                        </div>
                        <div class="input-group-append input-group-text">
                            <span class="fas fa-lock"></span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-8">
                            <a class="cursor-pointer" style="line-height: 2rem;" data-toggle="modal" data-target="#modal-esqueceu-senha">Esqueceu a senha?</a>
                        </div>
                        <!-- /.col -->
                        <div class="col-4">
                            <button i18n type="submit" class="btn btn-primary btn-block btn-flat" (click)="fazerLogin()" [disabled]="!loginForm.form.valid">Login</button>
                        </div>
                        <!-- /.col -->
                    </div>
                </form>

                <!-- <div class="social-auth-links text-center mb-3">
                    <p>- OR -</p>
                    <a href="#" class="btn btn-block btn-primary">
                        <i class="fab fa-facebook mr-2"></i> Sign in using Facebook
                    </a>
                    <a href="#" class="btn btn-block btn-danger">
                        <i class="fab fa-google-plus mr-2"></i> Sign in using Google+
                    </a>
                </div> -->
                <!-- /.social-auth-links -->

                <!-- <p class="mb-1">
                    <a href="#">I forgot my password</a>
                </p> -->
                <!--p class="mb-1 mt-3">
                    Não possui cadastro? <a i18n routerLink="/cadastro" class="text-center">Cadastre-se aqui!</a>
                </p>

                <p class="mb-0 mt-3">                    
                    <a class="btn btn-secondary btn-sm btn-block cursor-pointer" style="line-height: 2rem; color: white;" data-toggle="modal" data-target="#modal-import-loginsh">Importação LoginSH</a>                    
                </p-->
            </div>
            <!-- /.login-card-body -->
        </div>
    </div>
    <!-- /.login-box -->
    <div class="modal fade" id="modal-esqueceu-senha" aria-modal="true" role="dialog">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Esqueceu a Senha?</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <p>
                        Digite seu e-mail de cadastro abaixo para recuperar sua senha!<br>
                        <small>Um e-mail será enviado com um link de recuperação!</small><br>
                        <small>Caso não esteja na caixa de entrada do seu e-mail, verifique também sua pasta de Spam ou Lixo Eletronico!</small>
                    </p>
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label for="email">E-mail</label>
                                <input class="form-control" [(ngModel)]="emailRecuperacao" name="email" type="text" placeholder="E-mail cadastrado">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer justify-content-between">
                    <button type="button" class="btn btn-default" data-dismiss="modal">Fechar</button>
                    <button type="button" (click)="recuperarSenha()" class="btn btn-primary">Recuperar senha!</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="modal-import-loginsh" aria-modal="true" role="dialog">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Importar dados do LoginSH</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-12">
                            <div class="callout callout-info">
                                <p>
                                    <i class="fas fa-info pr-2"></i> <small>Se você já possui cadastro no WOP informe o usuário já cadastrado, se não, informe o nome do usuário a ser criado.</small>
                                </p>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-6">
                            <div class="form-group">
                                <label for="usuario-wop">Login de usuário WOP</label>
                                <input class="form-control" [(ngModel)]="importLoginShForm.username" name="usuario-wop" type="text" placeholder="Nome de usuário WOP">
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-6">
                            <div class="form-group">
                                <label for="email">E-mail cadastrado no LoginSH</label>
                                <input class="form-control" [(ngModel)]="importLoginShForm.email" name="email" type="email" placeholder="E-mail cadastrado no LoginSH">
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-12">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" [(ngModel)]="importLoginShForm.possuiLoginWop" [ngModelOptions]="{standalone: true}">
                                <label class="form-check-label">Possui cadastro no WOP?</label>
                            </div>
                        </div>                        
                        <div class="col-sm-12 col-md-12 col-lg-12">                            
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" [(ngModel)]="importLoginShForm.aceiteTermoUso" [ngModelOptions]="{standalone: true}">
                                <label class="form-check-label">Li e concordo com os <a i18n data-toggle="modal" data-target="#termo-uso" style="cursor: pointer" class="text-center text-bold">Termos de uso</a></label>
                            </div>                            
                        </div>         
                        <div class="col-sm-12 col-md-12 col-lg-12" *ngIf="termoTratamentoDadosVigente != null">                            
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" [(ngModel)]="importLoginShForm.aceiteTermoUso" [ngModelOptions]="{standalone: true}">
                                <label class="form-check-label">Li e concordo com os <a i18n data-toggle="modal" data-target="#termo-tratamento-dados" style="cursor: pointer" class="text-center text-bold">Termos de tratamento de dados</a></label>
                            </div>                            
                        </div>               
                        <div class="col-sm-12 col-md-12 col-lg-12">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" [(ngModel)]="importLoginShForm.aceiteImportacaoLoginSh" [ngModelOptions]="{standalone: true}">
                                <label class="form-check-label">Aceito a importação dos meus dados do sistema LoginSH</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer justify-content-between">
                    <button type="button" class="btn btn-default" data-dismiss="modal">Fechar</button>
                    <button type="button" (click)="importarDadosLoginSh()" class="btn btn-primary">Importar!</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="termo-uso">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Termo de Uso</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div [ngxSummernoteView]="termoVigente?.termo"></div>
                </div>
                <div class="modal-footer justify-content-right">
                    <button type="button" class="btn btn-default" data-dismiss="modal">Fechar</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="termo-tratamento-dados">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Termo de Tratamento de Dados</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div [ngxSummernoteView]="termoTratamentoDadosVigente?.termo"></div>
                </div>
                <div class="modal-footer justify-content-right">
                    <button type="button" class="btn btn-default" data-dismiss="modal">Fechar</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="confirmacao-importacao-loginsh">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Importante</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    
                    <p>
                        Um e-mail de recuperação de senha foi enviado para seu e-mail cadastrado.<br/>
                        Realize o processo de recuperação de senha para ter acesso a sua conta no WOP!
                    </p>                    

                </div>
                <div class="modal-footer justify-content-right">
                    <button type="button" class="btn btn-default" data-dismiss="modal">Fechar</button>
                </div>
            </div>
        </div>
    </div>
</body>