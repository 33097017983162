import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
export class EnviosLocalComponent {
    constructor(envioService, regionalService, missaoService, casaComunitariaService, apostoladoService, elementoGrupoDominioService, swtAlert2Service) {
        this.envioService = envioService;
        this.regionalService = regionalService;
        this.missaoService = missaoService;
        this.casaComunitariaService = casaComunitariaService;
        this.apostoladoService = apostoladoService;
        this.elementoGrupoDominioService = elementoGrupoDominioService;
        this.swtAlert2Service = swtAlert2Service;
        this.statusPendenteId = 21;
        this.envios = new Array();
        this.busca = {
            nome: null,
            sobrenome: null,
            regionalId: null,
            missaoId: null,
            casaComunitariaId: null,
            apostoladoId: null,
            statusId: this.statusPendenteId,
            pageNumber: 0,
            quantityOfElements: 10,
        };
    }
    ngOnInit() {
        this.carregarRegionais();
        this.carregarStatus();
        this.carregarApostolados();
        this.buscarEnvios();
    }
    cliqueNoBotaoListener(botao) {
        this.busca.pageNumber = botao.numero - 1;
        this.buscarEnvios();
    }
    buscarEnvios() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.envioService.pesquisar(this.busca);
                this.envios = response.entity;
                this.totalDePaginas = response.quantity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    deletarEnvio(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (confirm('Deseja deletar este envio?')) {
                    let response = yield this.envioService.delete(id);
                    this.swtAlert2Service.successAlert(response.message);
                    this.buscarEnvios();
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarStatus() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let request = {
                    grupoNome: 'STATUS_ENVIO',
                };
                let response = yield this.elementoGrupoDominioService.buscarPorGrupoDominioNome(request);
                this.listaStatus = response.entity.filter((st) => st.valor.localeCompare('CONCL') != 0);
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarRegionais() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.regionalService.findAll();
                this.regionais = response.entity.sort((a, b) => a.nome.localeCompare(b.nome));
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarMissoes() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.missaoService.porRegional(this.busca.regionalId);
                this.missoes = response.entity.sort((a, b) => a.nome.localeCompare(b.nome));
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarCasasComunitarias() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.casaComunitariaService.porMissao(this.busca.missaoId);
                this.casasComunitarias = response.entity.sort((a, b) => a.nome.localeCompare(b.nome));
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarApostolados() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.apostoladoService.pesquisar({ ativo: true });
                this.apostolados = response.entity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    validaStatus(id) {
        let retorno = true;
        this.listaStatus.forEach((el) => {
            if (el.id == id) {
                if (el.valor.localeCompare('DIVUL') == 0) {
                    retorno = false;
                }
            }
        });
        return retorno;
    }
}
