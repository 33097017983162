import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IrradiacaoService } from './service/irradiacao.service';
import { CommonModule } from '@angular/common';
import { SecuredModule } from '../../layout/secured.module';
import { RouterModule } from '@angular/router';
import { IrradiacaoComponent } from './irradiacao.component';
import { IrradiacaoRoutingModule } from './irradiacao-routing.module';
import { IrradiacoesComponent } from './irradiacoes.component';
import { IrradiacaoEditComponent } from './irradiacao.edit.component';

@NgModule({
    declarations: [ 
        IrradiacaoComponent,
        IrradiacoesComponent,
        IrradiacaoEditComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        IrradiacaoRoutingModule,
        FormsModule,
        SecuredModule
    ],
    providers: [
        IrradiacaoService
    ]
})
export class IrradiacaoModule {

}