import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CasaComunitariaService } from '../../wop/casacomunitaria/service/casacomunitaria.service';
import { CommonModule } from '@angular/common';
import { SecuredModule } from '../../layout/secured.module';
import { RouterModule } from '@angular/router';
import { CasaComunitariaLocalRoutingModule } from './casacomunitaria-routing.module';
import { CasaComunitariaLocalManageComponent } from './casacomunitaria.manager.component';
import { AdicaoMembroCasaComunitariaModule } from './components/adicao-membro-casa-comunitaria/adicao-membro-casa-comunitaria.module';
import { IgxTabsModule } from 'igniteui-angular';
import { ImageCropperModule } from 'ngx-image-cropper';
import { AdicaoEquipeFormacaoModule } from './components/adicao-equipe-formacao-casa-comunitaria/adicao-equipe-formacao.module';

@NgModule({
    declarations: [ 
        CasaComunitariaLocalManageComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        CasaComunitariaLocalRoutingModule,
        FormsModule,
        SecuredModule,
        IgxTabsModule,
        ImageCropperModule,
        AdicaoMembroCasaComunitariaModule,
        AdicaoEquipeFormacaoModule
    ],
    providers: [
        CasaComunitariaService
    ]
})
export class CasaComunitariaLocalModule {

}