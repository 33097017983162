import { Injectable } from '@angular/core';
import { PessoaNacionalidade } from '../model/pessoanacionalidade.model';
import { AbstractBuilder } from './abstract.builder';

@Injectable()
export class PessoaNacionalidadeBuilder extends AbstractBuilder<PessoaNacionalidade> {

    reset() : void {
        this.entity = new PessoaNacionalidade(null,null,null,null);
    }

}