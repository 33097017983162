/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./cadastro-coordenador-colegiado.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../wop/pessoa/modulo/listagem-pessoainfo-modal/listagempessoainfo.modal.component.ngfactory";
import * as i4 from "../../../../wop/pessoa/modulo/listagem-pessoainfo-modal/listagempessoainfo.modal.component";
import * as i5 from "../../../../wop/pessoa/service/pessoa.service";
import * as i6 from "../../../../wop/pais/service/pais.service";
import * as i7 from "../../../../wop/missao/service/missao.service";
import * as i8 from "../../../cadastropublico/service/cadastro.publico.local.service";
import * as i9 from "../../../../../core/swtalert2/swtalert2.service";
import * as i10 from "../../../../wop/imagem/service/imagem.service";
import * as i11 from "./cadastro-coordenador-colegiado.component";
import * as i12 from "../../../colegiado/coordenadorcolegiado.service";
import * as i13 from "../../service/missao-configuracao-shared-data.service";
var styles_CadastroCoordenadorColegiadoComponent = [i0.styles];
var RenderType_CadastroCoordenadorColegiadoComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CadastroCoordenadorColegiadoComponent, data: {} });
export { RenderType_CadastroCoordenadorColegiadoComponent as RenderType_CadastroCoordenadorColegiadoComponent };
function View_CadastroCoordenadorColegiadoComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 12, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 5, "div", [["class", "col-sm-12 col-md-12 col-lg-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "label", [["for", "npastor_pessoa"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Pessoa"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["class", "form-control"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", " ", ""])), (_l()(), i1.ɵeld(9, 0, null, null, 5, "div", [["class", "col-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 4, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "label", [["for", "button-add-pessoa"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00A0"])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["data-target", "#listagem-pessoa-coordenador-colegiado"], ["data-toggle", "modal"], ["name", "button-add-pessoa"], ["style", "cursor: pointer"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Selecionar o Coordenador de Colegiado"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.pessoaCoordenadorColegiadoSelecionado == null) ? null : _co.pessoaCoordenadorColegiadoSelecionado.nome); var currVal_1 = ((_co.pessoaCoordenadorColegiadoSelecionado == null) ? null : _co.pessoaCoordenadorColegiadoSelecionado.sobrenome); _ck(_v, 8, 0, currVal_0, currVal_1); }); }
function View_CadastroCoordenadorColegiadoComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 21, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 20, "table", [["class", "table table-striped"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 8, "thead", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 7, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["#"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Foto"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Nome"])), (_l()(), i1.ɵeld(10, 0, null, null, 0, "th", [], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 10, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 9, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(14, null, ["", ""])), (_l()(), i1.ɵeld(15, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 0, "img", [["alt", "Imagem do coordenador de colegiado"], ["class", "profile-user-img img-fluid img-circle"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(18, null, ["", ""])), (_l()(), i1.ɵeld(19, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 1, "button", [["alt", "Remover Coordenador de Colegiado"], ["class", "btn btn-sm btn-danger ml-1"], ["title", "Remover Coordenador de Colegiado"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removerCoordenadorColegiado(_co.coordenadorColegiado.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 0, "i", [["class", "fas fa-trash-alt"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.coordenadorColegiado.id; _ck(_v, 14, 0, currVal_0); var currVal_1 = ((_co.coordenadorColegiado.thumbnail == null) ? "assets/images/ppl.png" : _co.coordenadorColegiado.thumbnail); _ck(_v, 16, 0, currVal_1); var currVal_2 = _co.coordenadorColegiado.pessoaNome; _ck(_v, 18, 0, currVal_2); }); }
function View_CadastroCoordenadorColegiadoComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "row justify-content-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "button", [["class", "form-control btn btn-primary"], ["name", "button-add-cord-col"], ["type", "submit"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.adicionarCoordenadorColegiado() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Adicionar Coordenador de Colegiado"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.coordenadorColegiado == null) ? null : _co.coordenadorColegiado.id); _ck(_v, 1, 0, currVal_0); }); }
export function View_CadastroCoordenadorColegiadoComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "div", [["class", "card card-gray-dark"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "card-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h3", [["class", "card-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " Coordenador de Colegiado "])), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CadastroCoordenadorColegiadoComponent_1)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CadastroCoordenadorColegiadoComponent_2)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "card-footer"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CadastroCoordenadorColegiadoComponent_3)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 1, "listagem-pessoainfo-modal-component", [], null, [[null, "eventEmitter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("eventEmitter" === en)) {
        var pd_0 = (_co.selecionarCoordenadorColegiado($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ListagemPessoaInfoModalComponent_0, i3.RenderType_ListagemPessoaInfoModalComponent)), i1.ɵdid(14, 114688, null, 0, i4.ListagemPessoaInfoModalComponent, [i5.PessoaService, i6.PaisService, i7.MissaoService, i8.CadastroPublicoLocalService, i9.SwtAlert2Service, i10.ImagemService], { modalId: [0, "modalId"], modalTitle: [1, "modalTitle"], buttonText: [2, "buttonText"], altButtonText: [3, "altButtonText"], onlyMissionaries: [4, "onlyMissionaries"], bringNotInformedWayOfLife: [5, "bringNotInformedWayOfLife"], filterByUserAccess: [6, "filterByUserAccess"], bringEntitiesWithoutLinkedUser: [7, "bringEntitiesWithoutLinkedUser"] }, { eventEmitter: "eventEmitter" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = !((_co.coordenadorColegiado == null) ? null : _co.coordenadorColegiado.id); _ck(_v, 7, 0, currVal_1); var currVal_2 = ((_co.coordenadorColegiado == null) ? null : _co.coordenadorColegiado.id); _ck(_v, 9, 0, currVal_2); var currVal_3 = !((_co.coordenadorColegiado == null) ? null : _co.coordenadorColegiado.id); _ck(_v, 12, 0, currVal_3); var currVal_4 = "listagem-pessoa-coordenador-colegiado"; var currVal_5 = "Selecione o Coordenador de Colegiado"; var currVal_6 = "Selecionar"; var currVal_7 = "Selecionar Coordenador"; var currVal_8 = true; var currVal_9 = false; var currVal_10 = true; var currVal_11 = false; _ck(_v, 14, 0, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (!((_co.coordenadorColegiado == null) ? null : _co.coordenadorColegiado.id) ? "Cadastrar" : ""); _ck(_v, 4, 0, currVal_0); }); }
export function View_CadastroCoordenadorColegiadoComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-cadastro-coordenador-colegiado", [], null, null, null, View_CadastroCoordenadorColegiadoComponent_0, RenderType_CadastroCoordenadorColegiadoComponent)), i1.ɵdid(1, 245760, null, 0, i11.CadastroCoordenadorColegiadoComponent, [i12.CoordenadorColegiadoService, i13.MissaoConfiguracaoSharedDataService, i10.ImagemService, i9.SwtAlert2Service], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CadastroCoordenadorColegiadoComponentNgFactory = i1.ɵccf("app-cadastro-coordenador-colegiado", i11.CadastroCoordenadorColegiadoComponent, View_CadastroCoordenadorColegiadoComponent_Host_0, {}, {}, []);
export { CadastroCoordenadorColegiadoComponentNgFactory as CadastroCoordenadorColegiadoComponentNgFactory };
