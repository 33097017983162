import { Injectable } from '@angular/core';
import { Nacionalidade } from '../model/nacionalidade.model';
import { AbstractBuilder } from './abstract.builder';

@Injectable()
export class NacionalidadeBuilder extends AbstractBuilder<Nacionalidade> {

    reset() : void {
        this.entity = new Nacionalidade(null,null,null,null);
    }

}