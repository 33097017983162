import { Component, OnInit } from '@angular/core';
import { CentroEvangelizacaoService } from './service/centroevangelizacao.service';
import { CentroEvangelizacao } from 'src/app/model/centroevangelizacao.model';
import { Alerta } from 'src/app/model/alerta.model';
import { EstadoService } from '../estado/service/estado.service';
import { Estado } from 'src/app/model/estado.model';
import { Pais } from 'src/app/model/pais.model';
import { PaisService } from '../pais/service/pais.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ElementoGrupoDominio } from 'src/app/model/elementogrupodominio.model';
import { Missao } from 'src/app/model/missao.model';
import { Regional } from 'src/app/model/regional.model';
import { ElementoGrupoDominioService } from '../grupodominio/service/elementogrupodominio.service';
import { RegionalService } from '../regional/service/regional.service';
import { MissaoService } from '../missao/service/missao.service';
import { Endereco } from 'src/app/model/endereco.model';
import { Cidade } from 'src/app/model/cidade.model';
import { CidadeService } from '../cidade/service/cidade.service';
import { CentroEvangelizacaoBuilder } from 'src/app/builder/cenntroevangelizacao.builder';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { Irradiacao } from 'src/app/model/irradiacao.model';
import { IrradiacaoService } from '../irradiacao/service/irradiacao.service';
import { Difusao } from 'src/app/model/difusao.model';
import { DifusaoService } from '../difusao/service/difusao.service';

@Component({
    selector: 'centroevangelizacaoedit',
    templateUrl: './centroevangelizacao.edit.component.html'
})
export class CentroEvangelizacaoEditComponent implements OnInit {

    public centroEvangelizacao: CentroEvangelizacao;
    public fases : Array<ElementoGrupoDominio>;
    public missoes : Array<Missao>;
    public difusoes : Array<Difusao>;
    public irradiacoes : Array<Irradiacao>;
    public regionalSelecionadoId : number;
    public regionais: Array<Regional>;
    public paises: Array<Pais>;
    public estados: Array<Estado>;
    public cidades: Array<Cidade>;
    public centroEvangelizacaoId: number;
    public centroevangelizacaoBuilder: CentroEvangelizacaoBuilder;

    constructor(
        public centroEvangelizacaoService: CentroEvangelizacaoService,
        public elementoGrupoDominioService: ElementoGrupoDominioService,
        public regionalService: RegionalService,
        public missaoService: MissaoService,
        public difusaoService: DifusaoService,
        public irradiacaoService: IrradiacaoService,
        public paisService: PaisService,
        public estadoService: EstadoService,
        public cidadeService: CidadeService,
        public route: ActivatedRoute,
        public router: Router,
        public swtAlert2Service: SwtAlert2Service
    ) 
    {
        this.centroevangelizacaoBuilder = new CentroEvangelizacaoBuilder();
        this.centroEvangelizacao = this.centroevangelizacaoBuilder.getInstance();
        this.fases = new Array<ElementoGrupoDominio>();
        this.regionais = new Array<Regional>();
        this.missoes = new Array<Missao>();
        this.difusoes = new Array<Difusao>();
        this.irradiacoes = new Array<Irradiacao>();
        this.paises = new Array<Pais>();
        this.estados = new Array<Estado>();
        this.cidades = new Array<Cidade>();
    }

    ngOnInit() { 
        this.centroEvangelizacaoId = Number(this.route.snapshot.paramMap.get('id'));
        this.carregarRegionais();  
        this.carregarFasesDoCentreDeEvangelizacao();  
        this.carregarPaises(); 
        this.carregarCidades();
        this.regionalSelecionadoId = null; 
        this.carregarCentroEvangelizacao(this.centroEvangelizacaoId);
    }

    carregarRegionais() : void {
        this.regionalService.findAll().then( (lista: any) => {
            this.regionais = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    carregarMissoes(): void {
        this.missaoService.porRegional(this.regionalSelecionadoId).then((response: any) => {
            this.missoes = response.entity; 
            this.carregarIrradiacoes();         
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    async carregarDifusoes() : Promise<any> {

        try {
            let response = await this.difusaoService.porRegional(this.regionalSelecionadoId);
            this.difusoes = response.entity; 
        } catch (err) {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }
        
    }

    carregarIrradiacoes(): void {
        this.irradiacaoService.porMissao(this.centroEvangelizacao.missaoId).then((response: any) => {
            this.irradiacoes = response.entity;          
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }

    carregarFasesDoCentreDeEvangelizacao() : void {

        let buscaFase = {
            grupoNome : 'FASE_CENTRO_EVANGELIZACAO'
        }

        this.elementoGrupoDominioService.buscarPorGrupoDominioNome(buscaFase).then( (lista: any) => {
            this.fases = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });

    }

    carregarPaises() : void {
        this.paisService.findAll().then( (lista: any) => {
            this.paises = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    carregarEstados() : void {

        let busca = {
            paisId: this.centroEvangelizacao.endereco.paisId,
            nome: undefined,
            pageNumber : 0, 
            quantityOfElements : 1000
        };

        this.estadoService.buscarEstados(busca).then( (lista: any) => {
            this.estados = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });

    }

    carregarCidades() : void {

        let busca = {
            estadoId: this.centroEvangelizacao.endereco.estadoId,
            nome: undefined,
            pageNumber : 0, 
            quantityOfElements : 1000
        };

        this.cidadeService.buscarCidades(busca).then( (lista: any) => {
            this.cidades = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });

    }

    salvar(centroEvangelizacao: CentroEvangelizacao) : void {

        this.centroEvangelizacaoService.update(centroEvangelizacao.id, centroEvangelizacao).then( (response:any) => {
            this.swtAlert2Service.successAlert(response.message);
            setTimeout(() => {
                this.router.navigate(['../secured/centrosevangelizacao']);
            }, 2000);

        }).catch( (err:any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    } 
    
    carregarCentroEvangelizacao(id: number): void {
        this.centroEvangelizacaoService.find(id).then((response: any) => {
            this.centroEvangelizacao = response.entity;   
            if(response.entity.missaoRegionalId != null)
            {
                this.regionalSelecionadoId = response.entity.missaoRegionalId;
                this.carregarMissoes();
            }
            else if(response.entity.difusaoRegionalId != null)
            {
                this.regionalSelecionadoId = response.entity.difusaoRegionalId;
                this.carregarDifusoes();
            }  
            this.carregarEstados();
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }

}