import { Endereco } from './endereco.model';

export class CasaComunitaria {

    constructor(
        public id: number,
        public nome: string,
        public dataFundacao: Date,
        public regionalId: number,
        public missaoId: number,
        public missaoNome: string,
        public endereco: Endereco,
        public statusId: number
    ){}

}