import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MissionarioMissaoAtualComponent } from "./missionariomissaoatual.component";
import { DadosDetalhamentoMissionarioMissaoAtualBuilder } from "src/app/builder/dadosdetalhamentomissionariomissaoatual.builder";
import { SalvarMissionarioMissaoAtualBuilder } from "src/app/builder/salvarmissionariomissaoatual.builder";
import { MissionarioMissaoAtualService } from "../service/missionariomissaoatual.service";
import { SalvarMissionarioMissaoApostoladoAtualBuilder } from "src/app/builder/salvarmissionariomissaoapostoladoatual.builder";
import { DadosDetalhamentoMissionarioMissaoApostoladoAtualBuilder } from "src/app/builder/dadosdetalhamentomissionariomissaoapostoladoatual.builder";


@NgModule({
    declarations:[
        MissionarioMissaoAtualComponent
    ],
    imports:[
        CommonModule,
        FormsModule
    ],
    exports: [
        MissionarioMissaoAtualComponent
    ],
    providers: [
        DadosDetalhamentoMissionarioMissaoAtualBuilder,
        SalvarMissionarioMissaoAtualBuilder,
        MissionarioMissaoAtualService,
        SalvarMissionarioMissaoApostoladoAtualBuilder,
        DadosDetalhamentoMissionarioMissaoApostoladoAtualBuilder
    ]
 })
  export class MissionarioMissaoAtualModule {
     
 }