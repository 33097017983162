<secured>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h3 i18n>Listagem de Estados</h3>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <form (ngSubmit)="buscarEstados()" #permissoesForm="ngForm" novalidate>
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title">Buscar Estados</h3>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label i18n for="lest_pais">País</label>
                                                <select class="form-control" id="lest_pais" [(ngModel)]="busca.paisId" name="lest_pais">
                                                        <option [value]="null" i18n>Selecione um País</option>
                                                        <option *ngFor="let pais of paises" [value]="pais.id">{{pais.nome}}</option>
                                                    </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label i18n for="lest_nome">Estado</label>
                                                <input [(ngModel)]="busca.nome" name="lest_nome" type="text" class="form-control" id="lest_nome" placeholder="Digite o nome" i18n-placeholder>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-footer">
                                    <button type="submit" class="btn btn-primary float-right" i18n>Buscar</button>
                                    <button type="reset" class="btn btn-default float-right" style="margin-right: 0.5rem;" i18n>Limpar</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">

                        <table-component [tituloSessao]="'Estados'" [cols]="4" [rows]="busca.quantityOfElements" [totalDePaginas]="totalDePaginas" (eventoBotao)="cliqueNoBotaoListener($event)">
                            <tr tableheader>
                                <th style="width: 10px">#</th>
                                <th>País</th>
                                <th>Estado</th>
                                <th></th>
                            </tr>
                            <tr tablebody *ngFor="let estado of estados">
                                <td>{{estado.id}}</td>
                                <td>{{estado.paisNome}}</td>
                                <td>{{estado.nome}}</td>
                                <td class="text-center">
                                    <button class="btn btn-sm btn-primary" routerLink="../estado/{{estado.id}}" title="Editar estado" alt="Editar estado"><i class="fas fa-edit"></i></button>&nbsp;
                                    <button class="btn btn-sm btn-danger" (click)="deletarEstado(estado.id)" title="Deletar estado" alt="Deletar estado"><i class="fas fa-trash-alt"></i></button>
                                </td>
                            </tr>
                        </table-component>
                    </div>
                </div>
            </div>
        </section>
    </div>
</secured>