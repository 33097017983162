import { Component, OnInit } from '@angular/core';
import { CasaComunitariaBuilder } from 'src/app/builder/casacomunitaria.builder';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { CasaComunitaria } from 'src/app/model/casacomunitaria.model';
import { Cidade } from 'src/app/model/cidade.model';
import { ElementoGrupoDominio } from 'src/app/model/elementogrupodominio.model';
import { Estado } from 'src/app/model/estado.model';
import { Missao } from 'src/app/model/missao.model';
import { Pais } from 'src/app/model/pais.model';
import { Regional } from 'src/app/model/regional.model';
import { CidadeService } from '../cidade/service/cidade.service';
import { EstadoService } from '../estado/service/estado.service';
import { ElementoGrupoDominioService } from '../grupodominio/service/elementogrupodominio.service';
import { MissaoService } from '../missao/service/missao.service';
import { PaisService } from '../pais/service/pais.service';
import { RegionalService } from '../regional/service/regional.service';
import { CasaComunitariaService } from './service/casacomunitaria.service';

@Component({
    selector: 'casacomunitaria',
    templateUrl: './casacomunitaria.component.html'
})
export class CasaComunitariaComponent implements OnInit {

    public casaComunitaria: CasaComunitaria;
    public missoes : Array<Missao>;
    public regionalSelecionadoId : number;
    public regionais: Array<Regional>;
    public paises: Array<Pais>;
    public estados: Array<Estado>;
    public cidades: Array<Cidade>;
    public statusCasaComunitaria: Array<ElementoGrupoDominio>;
    public casacomunitariaBuilder: CasaComunitariaBuilder;

    constructor(
        public casaComunitariaService: CasaComunitariaService,
        public regionalService: RegionalService,
        public missaoService: MissaoService,
        public paisService: PaisService,
        public estadoService: EstadoService,
        public cidadeService: CidadeService,
        public elementoGrupoDominioService: ElementoGrupoDominioService,
        public swtAlert2Service: SwtAlert2Service
    ) 
    {
        this.casacomunitariaBuilder = new CasaComunitariaBuilder();
        this.casaComunitaria = this.casacomunitariaBuilder.getInstance();
        this.regionais = new Array<Regional>();
        this.missoes = new Array<Missao>();
        this.paises = new Array<Pais>();
        this.estados = new Array<Estado>();
        this.cidades = new Array<Cidade>();
        this.statusCasaComunitaria = new Array<ElementoGrupoDominio>();
    }

    ngOnInit() { 
        this.carregarRegionais();   
        this.carregarPaises(); 
        this.carregarStatusCasaComunitaria();
        this.regionalSelecionadoId = null; 
    }

    async carregarStatusCasaComunitaria() : Promise<null> {

        try{
            let request = {
                grupoNome : 'CASA_COMUNITARIA_STATUS'
            }
            let response = await this.elementoGrupoDominioService.buscarPorGrupoDominioNome(request);
            this.statusCasaComunitaria = response.entity;

        }
        catch(err){
            this.swtAlert2Service.errorAlert(err.error.errors);
        }

        return null;
    }

    carregarRegionais() : void {
        this.regionalService.findAll().then( (lista: any) => {
            this.regionais = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    carregarMissoes(): void {
        this.missaoService.porRegional(this.regionalSelecionadoId).then((response: any) => {
            this.missoes = response.entity;          
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }

   
    carregarPaises() : void {
        this.paisService.findAll().then( (lista: any) => {
            this.paises = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    carregarEstados() : void {

        let busca = {
            paisId: this.casaComunitaria.endereco.paisId,
            nome: undefined,
            pageNumber : 0, 
            quantityOfElements : 1000
        };

        this.estadoService.buscarEstados(busca).then( (lista: any) => {
            this.estados = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });

    }

    carregarCidades() : void {

        let busca = {
            estadoId: this.casaComunitaria.endereco.estadoId,
            nome: undefined,
            pageNumber : 0, 
            quantityOfElements : 1000
        };

        this.cidadeService.buscarCidades(busca).then( (lista: any) => {
            this.cidades = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });

    }

    salvar(casaComunitaria: CasaComunitaria) : void {

        casaComunitaria.dataFundacao = new Date(casaComunitaria.dataFundacao);

        this.casaComunitariaService.create(casaComunitaria).then( (response:any) => {
            this.regionalSelecionadoId = null;
            this.casaComunitaria = this.casacomunitariaBuilder.getInstance();
            this.swtAlert2Service.successAlert(response.message);

        }).catch( (err:any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

}