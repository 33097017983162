import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
export class EnvioLocalEditComponent {
    constructor(envioService, regionalService, missaoService, casaComunitariaService, apostoladoService, envioBuilder, elementoGrupoDominioService, pessoaService, pessoaBuilder, swtAlert2Service, route, router) {
        this.envioService = envioService;
        this.regionalService = regionalService;
        this.missaoService = missaoService;
        this.casaComunitariaService = casaComunitariaService;
        this.apostoladoService = apostoladoService;
        this.envioBuilder = envioBuilder;
        this.elementoGrupoDominioService = elementoGrupoDominioService;
        this.pessoaService = pessoaService;
        this.pessoaBuilder = pessoaBuilder;
        this.swtAlert2Service = swtAlert2Service;
        this.route = route;
        this.router = router;
        this.pessoa = pessoaBuilder.getInstance();
        this.envio = this.envioBuilder.getInstance();
        this.listaStatus = new Array();
    }
    ngOnInit() {
        this.envioId = Number(this.route.snapshot.paramMap.get('id'));
        this.initialize();
    }
    initialize() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.carregarEnvio(this.envioId);
            this.carregarPessoa();
            this.carregarRegionais();
            this.carregarMissoes();
            this.carregarCasasComunitarias();
            this.carregarApostolados();
            this.carregarStatus();
        });
    }
    salvar(envio) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (this.validarEnvio(envio)) {
                    let response = yield this.envioService.update(envio.id, envio);
                    this.swtAlert2Service.successAlert(response.message);
                    setTimeout(() => {
                        this.router.navigate(['../secured/local/envios']);
                    }, 2000);
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarEnvio(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.envioService.find(id);
                this.envio = response.entity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarPessoa() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.pessoaService.find(this.envio.pessoaId);
                this.pessoa = response.entity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarStatus() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let request = {
                    grupoNome: "STATUS_ENVIO"
                };
                let response = yield this.elementoGrupoDominioService.buscarPorGrupoDominioNome(request);
                this.listaStatus = response.entity.filter((st) => st.valor.localeCompare("CONCL") != 0);
                ;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarRegionais() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.regionalService.findAll();
                this.regionais = response.entity.sort((a, b) => a.nome.localeCompare(b.nome));
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarMissoes() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.missaoService.porRegional(this.envio.regionalId);
                this.missoes = response.entity.sort((a, b) => a.nome.localeCompare(b.nome));
                ;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarCasasComunitarias() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.casaComunitariaService.porMissao(this.envio.missaoId);
                this.casasComunitarias = response.entity.sort((a, b) => a.nome.localeCompare(b.nome));
                ;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarApostolados() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.apostoladoService.pesquisar({ ativo: true });
                this.apostolados = response.entity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    validarEnvio(envio) {
        if (envio.pessoaId == null) {
            this.swtAlert2Service.warningAlert("Selecione o missionário para o aenvio");
            return false;
        }
        if (envio.regionalId == null) {
            this.swtAlert2Service.warningAlert("Informe o regional para o envio.");
            return false;
        }
        if (envio.missaoId == null) {
            this.swtAlert2Service.warningAlert("Informe a missão aenvio");
            return false;
        }
        return true;
    }
}
