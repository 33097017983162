<secured>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h3 i18n>Listagem de Envios</h3>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <form (ngSubmit)="buscarEnvios()" #enviosForm="ngForm" novalidate>
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title">Buscar Envios</h3>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label i18n for="pessoa_nome">Nome</label>
                                                <input [(ngModel)]="busca.nome" name="pessoa_nome" type="text" class="form-control" id="pessoa_nome" placeholder="Digite o nome do missionário" i18n-placeholder>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-6 col-lg-6">
                                            <div class="form-group">
                                                <label i18n for="pessoa_sobrenome">Sobrenome</label>
                                                <input [(ngModel)]="busca.sobrenome" name="pessoa_sobrenome" type="text" class="form-control" id="pessoa_sobrenome" placeholder="Digite o sobrenome do missionário" i18n-placeholder>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label i18n for="regional">Status</label>
                                                <select class="form-control" id="status" [(ngModel)]="busca.statusId" name="status">
                                                    <option [value]="null" i18n>Selecione um Status</option>
                                                    <option *ngFor="let stt of listaStatus" [value]="stt.id">{{stt.nome}}</option>
                                                </select>
                                            </div>
                                        </div> 
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label i18n for="regional">Regional</label>
                                                <select class="form-control" id="regional" [(ngModel)]="busca.regionalId" (change)="carregarMissoes();" name="regional">
                                                    <option [value]="null" i18n>Selecione um Regional</option>
                                                    <option *ngFor="let reg of regionais" [value]="reg.id">{{reg.nome}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label i18n for="missao">Missão</label>
                                                <select class="form-control" id="missao" [(ngModel)]="busca.missaoId" (change)="carregarCasasComunitarias();" name="missao">
                                                    <option [value]="null" i18n>Selecione uma Missão</option>
                                                    <option *ngFor="let missao of missoes" [value]="missao.id">{{missao.nome}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label i18n for="casacomunitaria">Casas Comunitárias</label>
                                                <select class="form-control" id="casacomunitaria" [(ngModel)]="busca.casaComunitariaId" (change)="carregarCasasComunitarias();" name="casacomunitaria">
                                                    <option [value]="null" i18n>Selecione uma Casa Comunitária</option>
                                                    <option *ngFor="let cc of casasComunitarias" [value]="cc.id">{{cc.nome}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label i18n for="apostolado">Apostolados</label>
                                                <select class="form-control" id="apostolado" [(ngModel)]="busca.apostoladoId" name="apostolado">
                                                    <option [value]="null" i18n>Selecione uma Apostolado</option>
                                                    <option *ngFor="let aptl of apostolados" [value]="aptl.id">{{aptl.nome}}</option>
                                                </select>
                                            </div>
                                        </div>  
                                    </div>
                                </div>
                                <div class="card-footer">
                                    <button type="submit" class="btn btn-primary float-right" i18n>Buscar</button>
                                    <button type="reset" class="btn btn-default float-right" style="margin-right: 0.5rem;" i18n>Limpar</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">

                        <table-component [tituloSessao]="'Envios'" [cols]="8" [rows]="busca.quantityOfElements" [totalDePaginas]="totalDePaginas" (eventoBotao)="cliqueNoBotaoListener($event)">
                            <tr tableheader>
                                <th style="width: 10px">#</th>
                                <th>Missionário</th>
                                <th>Regional</th>
                                <th>Missão</th>
                                <th>Casa Comunitária</th>
                                <th>Apostolado</th>
                                <th>Status</th>
                                <th></th>
                            </tr>
                            <tr tablebody *ngFor="let envio of envios">
                                <td>{{envio.id}}</td>
                                <td>{{envio.pessoaNome}}</td>
                                <td>{{envio.regionalNome}}</td>
                                <td>{{envio.missaoNome}}</td>
                                <td>{{envio.casaComunitariaNome}}</td>
                                <td>{{envio.apostoladoNome}}</td>
                                <td>{{envio.statusNome}}</td>
                                <td class="text-center">
                                    <ng-container *ngIf="validaStatus(envio.statusId)">
                                        <button class="btn btn-sm btn-primary" routerLink="../envio/{{envio.id}}" title="Editar envio" alt="Editar envio"><i class="fas fa-edit"></i></button>&nbsp;
                                        <button class="btn btn-sm btn-danger" (click)="deletarEnvio(envio.id)" title="Deletar envio" alt="Deletar envio"><i class="fas fa-trash-alt"></i></button>
                                    </ng-container>                                    
                                </td>
                            </tr>
                        </table-component>
                    </div>
                </div>
            </div>
        </section>
    </div>
</secured>