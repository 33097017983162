import { GenericService } from 'src/app/core/services/generic.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CoordenadorColegiado } from 'src/app/model/coordenadorcolegiado.model';
import { Observable } from 'rxjs';

@Injectable()
export class CoordenadorColegiadoService extends GenericService<any> {
  constructor(protected http: HttpClient, protected router: Router) {
    super(http, router);
    this.setEntityType('coordenadorcolegiado');
  }

  cadastrar(dados: CoordenadorColegiado): Observable<CoordenadorColegiado> {
    return this.http.post<CoordenadorColegiado>(
      `${this.apiURL}`,
      JSON.stringify(dados),
      {
        headers: this.getHeaders(),
      }
    );
  }

  obterPorMissaoConfiguracaoId(
    missaoConfiguracaoId: number
  ): Observable<CoordenadorColegiado> {
    return this.http.get<CoordenadorColegiado>(
      `${this.apiURL}/pormissaoconfiguracaoid/${missaoConfiguracaoId}`,
      {
        headers: this.getHeaders(),
      }
    );
  }

  remover(coordenadorColegiadoId: number): Observable<void> {
    return this.http.delete<void>(`${this.apiURL}/${coordenadorColegiadoId}`, {
      headers: this.getHeaders(),
    });
  }

  obterFormadoresPorMissaoDoCoordenadorColegiado(
    page: number,
    size: number
  ): Promise<any> {
    let url = `${environment.moduloFormacao.urlModuloFormacao}coordenadorcolegiado/formadorespormissao?page=${page}&size=${size}`;
    return this.http.get<any>(url, { headers: this.getHeaders() }).toPromise();
  }
}
