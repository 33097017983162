import { Component, OnInit } from '@angular/core';
import { EnvioLocalService } from './service/envio.local.service';
import { Envio } from 'src/app/model/envio.model';
import { EnvioBuilder } from 'src/app/builder/envio.builder';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { Regional } from 'src/app/model/regional.model';
import { Missao } from 'src/app/model/missao.model';
import { MissaoService } from '../../wop/missao/service/missao.service';
import { RegionalService } from '../../wop/regional/service/regional.service';
import { CasaComunitaria } from 'src/app/model/casacomunitaria.model';
import { CasaComunitariaService } from '../../wop/casacomunitaria/service/casacomunitaria.service';
import { Apostolado } from 'src/app/model/apostolado.model';
import { ApostoladoService } from '../../wop/dadosvocacionais/service/apostolado.service';
import { PessoaInfo } from 'src/app/model/pessoainfo.model';
import { PessoaInfoBuilder } from 'src/app/builder/pessoainfo.builder';

@Component({
    selector: 'envio-local',
    templateUrl: './envio.local.component.html'
})
export class EnvioLocalComponent implements OnInit {

    public envio: Envio;
    public regionais: Array<Regional>;
    public missoes: Array<Missao>;
    public casasComunitarias: Array<CasaComunitaria>;
    public apostolados: Array<Apostolado>;
    public pessoaMissionariaSelecionada: PessoaInfo;

    constructor(
        public envioService: EnvioLocalService,
        public regionalService: RegionalService,
        public missaoService: MissaoService,
        public casaComunitariaService: CasaComunitariaService,
        public apostoladoService: ApostoladoService,
        public envioBuilder: EnvioBuilder,
        public pessoaInfoBuilder: PessoaInfoBuilder,
        public swtAlert2Service: SwtAlert2Service
    ) 
    {
        this.envioBuilder = new EnvioBuilder();
        this.envio = this.envioBuilder.getInstance();
        this.regionais = new Array<Regional>();
        this.missoes = new Array<Missao>();
        this.pessoaMissionariaSelecionada = this.pessoaInfoBuilder.getInstance();
    }

    ngOnInit() { 
        this.initialize();       
    }

    async initialize() : Promise<any> {
        this.carregarRegionais();
        this.carregarApostolados();
    }

    async salvar(envio: Envio) : Promise<any> {

        try 
        {
            if(this.validarEnvio(envio))
            {
                let response = await this.envioService.create(envio);
                this.envio = this.envioBuilder.getInstance();
                this.pessoaMissionariaSelecionada = this.pessoaInfoBuilder.getInstance();
                this.swtAlert2Service.successAlert(response.message);
            }
            
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }

    }

    async carregarRegionais() : Promise<any> {
        try 
        {
            let response = await this.regionalService.findAll();
            this.regionais = response.entity.sort((a,b) => a.nome.localeCompare(b.nome));
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }
    }

    async carregarMissoes() : Promise<any> {
        try 
        {
            let response = await this.missaoService.porRegional(this.envio.regionalId);
            this.missoes = response.entity.sort((a,b) => a.nome.localeCompare(b.nome));;
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }
    }

    async carregarCasasComunitarias() : Promise<any> {
        try 
        {
            let response = await this.casaComunitariaService.porMissao(this.envio.missaoId);
            this.casasComunitarias = response.entity.sort((a,b) => a.nome.localeCompare(b.nome));;
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }
    }

    async carregarApostolados() : Promise<any> {
        try 
        {
            let response = await this.apostoladoService.pesquisar({ativo:true});
            this.apostolados = response.entity;
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }
    }

    async selecionarMissionario(pessoa: PessoaInfo): Promise<any> {
        this.envio.pessoaId = pessoa.id;
        this.pessoaMissionariaSelecionada = pessoa;
        $('#listagem-pessoa-missionario').modal('hide');
    }

    validarEnvio(envio: Envio) : boolean {

        if(envio.pessoaId == null)
        {
            this.swtAlert2Service.warningAlert("Selecione o missionário para o aenvio");
            return false;
        }
        if(envio.regionalId == null)
        {
            this.swtAlert2Service.warningAlert("Informe o regional para o envio.");
            return false;
        }
        if(envio.missaoId == null)
        {
            this.swtAlert2Service.warningAlert("Informe a missão aenvio");
            return false;
        }

        return true;

    }

}