<secured>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h3 i18n>Criar Acesso - Usuário/Estruturas</h3>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">

            <form (ngSubmit)="salvar(acessoUsuarioEstrutura)" #acessoForm="ngForm" novalidate>
                <div class="card card-default color-pallete-box">
                    <div class="card-header">
                        <h3 class="card-title">
                            <i class="fas fa-user-lock"></i>
                            <a i18n> Selecione a Pessoa e sua Autorização <br><small>O resultado trará [usuário] - [nome da pessoa]</small> </a>
                        </h3>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-12 col-md-8 col-lg-8">
                                <div class="form-group">
                                    <label i18n for="pessoa">Pessoa</label>
                                    <select2-component [name]="'pessoa'" [url]="pessoaSearchUrl" [multiplo]="false" (elementoSelecionado)="setarElementoSelecionado($event)" [required]="true">
                                        <option id="s2options" value="">Pesquisar pessoas</option>
                                    </select2-component>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-4 col-lg-4">
                                <div class="form-group">
                                    <label i18n for="naue_autorizacao">Autorização</label>
                                    <select class="form-control" id="naue_autorizacao" [(ngModel)]="acessoUsuarioEstrutura.autorizacaoId" name="naue_autorizacao">
                                        <option [value]="null" i18n>Selecione uma autorização</option>
                                        <option *ngFor="let autorizacao of autorizacoes" [value]="autorizacao.id">{{autorizacao.nomeExibicao}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <button type="submit" class="btn btn-primary float-right" i18n>Salvar</button>
                    </div>
                </div>
            </form>
        </section>
    </div>
</secured>