import { GenericService } from 'src/app/core/services/generic.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from "@angular/core";
import { Trabalho } from 'src/app/model/trabalho.model';

@Injectable()
export class TrabalhoService extends GenericService<Trabalho> {    
    
    constructor(protected http: HttpClient, protected router:Router) {
        super(http,router);
        this.setEntityType('r/trabalho');
    }

    async findByPessoaId(id: number): Promise<any> {
        return this.http.get<Trabalho>(this.apiURL+'/porpessoa/'+id, { 'headers' : this.getHeaders() }).toPromise();
    }
    
} 