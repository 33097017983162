export class Apostolado {

    constructor(
        public id: number,
        public nome: string,
        public servicoAutoridade: boolean,
        public ativo: boolean,
        public tipoId: number,
        public tipoNome: string
    ){}

}