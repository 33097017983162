import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
export class MissaoConfiguracaoSharedDataService {
    constructor() {
        this.missaoConfiguracao$ = new BehaviorSubject(null);
        this.updateMissaoConfiguracao = (missaoConfiguracao) => {
            this.missaoConfiguracao$.next(missaoConfiguracao);
        };
    }
}
MissaoConfiguracaoSharedDataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MissaoConfiguracaoSharedDataService_Factory() { return new MissaoConfiguracaoSharedDataService(); }, token: MissaoConfiguracaoSharedDataService, providedIn: "root" });
