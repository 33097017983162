<secured>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h3 i18n>Listagem de Países</h3>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <form (ngSubmit)="buscarPaises()" #permissoesForm="ngForm" novalidate>
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title">Buscar Países</h3>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label i18n for="la_nome">Nome</label>
                                                <input [(ngModel)]="busca.nome" name="na_nome" type="text" class="form-control" id="la_nome" placeholder="Digite o nome" i18n-placeholder>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-footer">
                                    <button type="submit" class="btn btn-primary float-right" i18n>Buscar</button>
                                    <button type="reset" class="btn btn-default float-right" style="margin-right: 0.5rem;" i18n>Limpar</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">

                        <table-component [tituloSessao]="'Países'" [cols]="3" [rows]="busca.quantityOfElements" [totalDePaginas]="totalDePaginas" (eventoBotao)="cliqueNoBotaoListener($event)">
                            <tr tableheader>
                                <th style="width: 10px">#</th>
                                <th>Nome</th>
                                <th></th>
                            </tr>
                            <tr tablebody *ngFor="let pais of paises">
                                <td>{{pais.id}}</td>
                                <td>{{pais.nome}}</td>
                                <td class="text-center">
                                    <button class="btn btn-sm btn-primary" routerLink="../pais/{{pais.id}}" title="Editar país" alt="Editar país"><i class="fas fa-edit"></i></button>&nbsp;
                                    <button class="btn btn-sm btn-danger" (click)="deletarPais(pais.id)" title="Deletar país" alt="Deletar país"><i class="fas fa-trash-alt"></i></button>
                                </td>
                            </tr>
                        </table-component>
                    </div>
                </div>
            </div>
        </section>
    </div>
</secured>