<secured>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h3 i18n>Envio</h3>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">            
            <div class="card card-default color-pallete-box">
                <div class="card-header">
                    <h3 class="card-title">
                        <i class="fas fa-share"></i>
                        <a i18n> Formulário de Envio de Missionário </a>
                    </h3>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-9">
                            <div class="form-group">
                                <label i18n for="pessoa-pai-info">Nome do missionário</label>
                                <span class="form-control">{{pessoaMissionariaSelecionada.nome}}&nbsp;{{pessoaMissionariaSelecionada.sobrenome}}</span>
                            </div>
                        </div>  
                        <div class="col-3">
                            <div class="form-group">
                                <label i18n
                                    for="button-add-pai">&nbsp;</label><br>
                                <button name="button-add-missionario"
                                    data-toggle="modal"
                                    data-target="#listagem-pessoa-missionario"
                                    style="cursor: pointer"
                                    class="btn btn-primary"
                                    i18n>Selecionar missionário</button>
                            </div>
                        </div>                          
                        <div class="col-sm-12 col-md-3 col-lg-3">
                            <div class="form-group">
                                <label i18n for="regional">Regional</label>
                                <select class="form-control" id="regional" [(ngModel)]="envio.regionalId" (change)="carregarMissoes();" name="regional">
                                    <option [value]="null" i18n>Selecione um Regional</option>
                                    <option *ngFor="let reg of regionais" [value]="reg.id">{{reg.nome}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-3 col-lg-3">
                            <div class="form-group">
                                <label i18n for="missao">Missão</label>
                                <select class="form-control" id="missao" [(ngModel)]="envio.missaoId" (change)="carregarCasasComunitarias();" name="missao">
                                    <option [value]="null" i18n>Selecione uma Missão</option>
                                    <option *ngFor="let missao of missoes" [value]="missao.id">{{missao.nome}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-3 col-lg-3">
                            <div class="form-group">
                                <label i18n for="casacomunitaria">Casas Comunitárias</label>
                                <select class="form-control" id="casacomunitaria" [(ngModel)]="envio.casaComunitariaId" (change)="carregarCasasComunitarias();" name="casacomunitaria">
                                    <option [value]="null" i18n>Selecione uma Casa Comunitária</option>
                                    <option *ngFor="let cc of casasComunitarias" [value]="cc.id">{{cc.nome}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-3 col-lg-3">
                            <div class="form-group">
                                <label i18n for="apostolado">Apostolados</label>
                                <select class="form-control" id="apostolado" [(ngModel)]="envio.apostoladoId" name="apostolado">
                                    <option [value]="null" i18n>Selecione uma Apostolado</option>
                                    <option *ngFor="let aptl of apostolados" [value]="aptl.id">{{aptl.nome}}</option>
                                </select>
                            </div>
                        </div>                            
                    </div>
                </div>
                <div class="card-footer">
                    <button type="submit" (click)="salvar(envio)" class="btn btn-primary float-right" i18n>Salvar</button>
                </div>
            </div>            
        </section>
        <listagem-pessoainfo-modal-component [modalId]="'listagem-pessoa-missionario'"
            [modalTitle]="'Selecione o missionario'" [buttonText]="'Selecionar'"
            [altButtonText]="'Selecionar missionario'"
            (eventEmitter)="selecionarMissionario($event)"></listagem-pessoainfo-modal-component>
    </div>
</secured>