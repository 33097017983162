export class TermoTratamentoDados {
    
    constructor(
        public id: number,
        public termo: string,
        public termoVigente: boolean,
        public dataCriacao: Date
    )
    {}

}