import { Component, OnInit } from '@angular/core';
import { Estado } from 'src/app/model/estado.model';
import { EstadoService } from './service/estado.service';
import { PaisService } from '../pais/service/pais.service';
import { Pais } from 'src/app/model/pais.model';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';

@Component({
    selector: 'estados',
    templateUrl: './estados.component.html'
})
export class EstadosComponent implements OnInit {

    public estados: Array<Estado>;
    public paises: Array<Pais>;
    public busca: any;
    public totalDePaginas: number;

    constructor(
        public estadoService: EstadoService,
        public paisService: PaisService,
        public swtAlert2Service: SwtAlert2Service
    ) 
    {
        this.paises = new Array<Pais>(); 
        this.estados = new Array<Estado>();     
        this.busca = {
            paisId: null,
            nome: undefined,
            pageNumber : 0, 
            quantityOfElements : 10
        };
    }

    ngOnInit() {
        this.buscarEstados();
        this.carregarPaises();
    }   

    carregarPaises() : void {
        this.paisService.findAll().then( (lista: any) => {
            this.paises = lista.entity;
        }).catch( (err: any) => {
            console.log(err);
        });
    }

    cliqueNoBotaoListener(botao) {
        this.busca.pageNumber = botao.numero-1;
        this.buscarEstados();
    }

    buscarEstados() : void {
        this.estadoService.buscarEstados(this.busca).then( (response: any) => {
            this.estados = response.entity;
            this.totalDePaginas = response.quantity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }
    
    deletarEstado(id: number) : void {

        if(confirm('Deseja deletar este estado?'))
        {
            this.estadoService.delete(id)
            .then( (response: any) => {
                this.swtAlert2Service.successAlert(response.message);
                this.buscarEstados();
            })
            .catch((err) => {
                this.swtAlert2Service.errorAlert(err.error.errors);
            });
        }
    }
}