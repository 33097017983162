<secured>
    <ng-container *ngIf="isLoaded; else elseTemplate">
        <div class="content-wrapper">
            <section class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-12">
                            <h3 i18n>Gerenciamento - Casa Comunitária</h3>
                        </div>
                    </div>
                </div>
            </section>
            <section class="content" name="grupooracao">
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <div class="card card-gray-dark color-pallete-box">
                            <div class="card-header">
                                <h3 class="card-title">
                                    <i class="fas fa-home"></i> Minha Casa Comunitária
                                </h3>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-12 col-md-4 col-lg-4">
                                        <div class="form-group">
                                            <label>Nome:</label>
                                            <br>
                                            <span class="form-control">{{ casaComunitaria?.nome != undefined ? casaComunitaria?.nome : '' }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-4 col-lg-4">
                                        <div class="form-group">
                                            <label>Data de Fundação:</label>
                                            <br>
                                            <span class="form-control">{{ casaComunitaria?.dataFundacao | date : 'dd/MM/yyyy' }}</span>
                                        </div>
                                    </div>                                    
                                    <div class="col-sm-12 col-md-4 col-lg-4">
                                        <div class="form-group">
                                            <label>Missao:</label>
                                            <br>
                                            <span class="form-control">{{ casaComunitaria?.missaoNome != undefined ? casaComunitaria?.missaoNome : '' }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <!-- <div class="col-sm-12 col-md-12 col-lg-12">
                        <div class="card card-gray-dark color-pallete-box">
                            <div class="card-header">
                                <h3 class="card-title">
                                    <i class="fas fa-user-circle"></i> Responsáveis
                                </h3>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-12 col-md-3 col-lg-3">
                                        <div class="card card-success card-outline">
                                            <div class="card-body box-profile">
                                                <div class="text-center">
                                                    <img class="profile-user-img img-fluid img-circle" [src]="pastorGrupoOracao?.thumbnail == null ? 'assets/images/ppl.png' : pastorGrupoOracao?.thumbnail" alt="Foto pastor grupo de oração">
                                                </div>
                                                <h3 class="profile-username text-center">{{pastorGrupoOracao?.pessoaNome}}</h3>
                                                <p class="text-muted text-center"><b>Pastor</b></p>
                                                <ul class="list-group list-group-unbordered mb-3">
                                                    <li class="list-group-item">
                                                        <b>Data de entrada:</b> <a class="float-right">{{pastorGrupoOracao?.dataEntrada | date : 'dd/MM/yyyy'}}</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-3 col-lg-3" *ngFor="let nucleo of nucleosGrupoOracao">
                                        <div class="card card-yellow card-outline">
                                            <div class="card-body box-profile">
                                                <div class="text-center">
                                                    <img class="profile-user-img img-fluid img-circle" [src]="nucleo?.thumbnail == null ? 'assets/images/ppl.png' : nucleo?.thumbnail" alt="Foto pastor grupo de oração">
                                                </div>
                                                <h3 class="profile-username text-center">{{nucleo?.pessoaNome}}</h3>
                                                <p class="text-muted text-center"><b>Núcleo</b></p>
                                                <ul class="list-group list-group-unbordered mb-3">
                                                    <li class="list-group-item">
                                                        <b>Data de entrada:</b> <a class="float-right">{{nucleo?.dataEntrada | date : 'dd/MM/yyyy'}}</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <adicao-equipe-formacao-component [usuarioLogado]="usuario" [casaComunitaria]="casaComunitaria"></adicao-equipe-formacao-component>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <adicao-membro-casa-comunitaria-component [usuarioLogado]="usuario" [casaComunitaria]="casaComunitaria" (eventEmitter)="carregamentoConcluido($event)"></adicao-membro-casa-comunitaria-component>
                    </div>
                    
                    <!-- <div class="col-sm-12 col-md-12 col-lg-12">
                        <div class="card card-gray-dark color-pallete-box">
                            <div class="card-header">
                                <h3 class="card-title">
                                    <i class="fas fa-child"></i>
                                    <a i18n> Membros </a>
                                </h3>
                                <div class="card-tools"><button class="btn btn-tool" data-card-widget="collapse" type="button"><i class="fas fa-minus"></i></button></div>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-12 col-md-6 col-lg-6">
                                        <div class="row">
                                            <div class="col-sm-12 col-md-12 col-lg-12">
                                                <div class="form-group">
                                                    <label i18n for="npastor_pessoa">Pessoa</label>
                                                    <span class="form-control">{{pessoaMembroSelecionada.nome}} {{pessoaMembroSelecionada.sobrenome}}</span>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12 col-lg-12">
                                                <div class="form-group">
                                                    <label i18n for="button-add-pessoa">&nbsp;</label>
                                                    <button name="button-add-pessoa" data-toggle="modal" data-target="#listagem-pessoa-membro" style="cursor: pointer" class="btn btn-primary" i18n>Selecionar Membro</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-3 col-lg-3">
                                        <div class="form-group">
                                            <label i18n for="nmembro_dtentrada">Data de Entrada</label>
                                            <input [(ngModel)]="membroCasaComunitariaFormulario.dataEntrada" name="nmembro_dtentrada" type="date" class="form-control" id="nmembro_dtentrada" i18n-placeholder>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-3">
                                        <div class="form-group">
                                            <label i18n for="button-part-submit">&nbsp;</label>
                                            <button type="submit" (click)="salvarMembroCasaComunitaria()" name="button-part-submit" class="form-control btn btn-primary" i18n>Adicionar Membro</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="card card-gray-dark color-pallete-box">
                                            <div class="card-header">
                                                <h3 class="card-title">
                                                    <i class="fas fa-people"></i>
                                                    <a i18n> Membros da Casa Comunitária </a>
                                                </h3>
                                                <div class="card-tools"><button class="btn btn-tool" data-card-widget="collapse" type="button"><i class="fas fa-minus"></i></button></div>
                                            </div>
                                            <div class="card-body table-responsive p-0">
                                                <table class="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>#</th>
                                                            <th>Imagem</th>
                                                            <th>Nome</th>
                                                            <th>Data Entrada</th>
                                                            <th>Data Saída</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr tablebody *ngFor="let membro of membrosCasaComunitaria">
                                                            <td>{{membro.id}}</td>
                                                            <td>
                                                                <div class="text-center">                                                                    
                                                                    <img class="profile-user-img img-fluid img-circle" [src]="membro.thumbnail == null ? 'assets/images/ppl.png' : membro.thumbnail" alt="Imagem do membro">                                                                    
                                                                </div>
                                                            </td>
                                                            <td>{{membro.pessoaNome}}</td>
                                                            <td>{{membro.dataEntrada | date : 'dd/MM/yyyy'}}</td>
                                                            <td>
                                                                <ng-container *ngIf="membro.editando == false || membro.editando == undefined">
                                                                    {{membro.dataSaida != null ? (membro.dataSaida | date : 'dd/MM/yyyy') : 'Membro Atual'}}
                                                                </ng-container>
                                                                <ng-container *ngIf="membro.editando == true">
                                                                    <input [(ngModel)]="membro.dataSaida" name="nmembro_dtsaida" type="date" class="form-control" id="nmembro_dtsaida">
                                                                </ng-container>
                                                            </td>
                                                            <td class="text-center">
                                                                <button *ngIf="membro.editando == true" class="btn btn-sm btn-success" (click)="atualizarMembro(membro)" title="Atualizar membro" alt="Atualizar membro"><i class="fas fa-save"></i></button>
                                                                <button *ngIf="membro.editando == false || membro.editando == undefined" class="btn btn-sm btn-primary" (click)="membro.editando = true" title="Editar membro" alt="Editar membro"><i class="fas fa-edit"></i></button>
                                                                <button class="btn btn-sm btn-danger ml-1" (click)="removerMembro(membro.id)" title="Remover membro" alt="Remover membro"><i class="fas fa-trash-alt"></i></button>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </section>
        </div>
    </ng-container>
    <ng-template #elseTemplate>
        <div class="content-wrapper">
            <section class="content-header">
                <div class="container-fluid">
                    <h1 class="loading">Carregando...</h1>
                </div>
            </section>
        </div>
    </ng-template>
    <!-- <listagem-pessoainfo-modal-component [modalId]="'listagem-pessoa-membro'" [onlyMissionaries]="false" [bringNotInformedWayOfLife]="true" [filterByUserAccess]="true"
            [modalTitle]="'Selecione o Membro do Grupo'" [buttonText]="'Selecionar'" [altButtonText]="'Selecionar Membro'"
            (eventEmitter)="selecionarMembro($event)"></listagem-pessoainfo-modal-component> -->
</secured>