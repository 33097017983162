<secured>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h3 i18n>Permissões</h3>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">

            <form (ngSubmit)="salvar(permissao)" #usuarioForm="ngForm" novalidate>
                <div class="card card-default color-pallete-box">
                    <div class="card-header">
                        <h3 class="card-title">
                            <i class="fas fa-user-lock"></i>
                            <a i18n> Dados da Permissão </a>
                        </h3>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-12 col-md-3 col-lg-3">
                                <div class="form-group">
                                    <label i18n for="np_nome_exibicao">Nome de Exibição</label>
                                    <input [(ngModel)]="permissao.nomeExibicao" name="nomeExibicao" type="text" class="form-control" id="np_nome_exibicao" placeholder="Digite o nome de exibição" i18n-placeholder>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-3 col-lg-3">
                                <div class="form-group">
                                    <label i18n for="np_nome">Nome</label>
                                    <input [(ngModel)]="permissao.nome" name="np_nome" type="text" class="form-control" id="np_nome" placeholder="Digite o nome" i18n-placeholder>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <button type="submit" class="btn btn-primary float-right" i18n>Salvar</button>
                    </div>
                </div>
            </form>
        </section>
        <section class="content-header" name="gerencia-autorizacoes" *ngIf="permissao.id!=null">
            <div class="card card-default color-pallete-box">
                <div class="card-header">
                    <h3 class="card-title">
                        <i class="fas fa-user-lock"></i>
                        <a i18n> Autorizações vinculadas </a>
                    </h3>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title">Vincular Autorizacao</h3>
                                </div>
                                <!-- /.card-header -->
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label i18n for="pv_autorizacao">Autorização</label>
                                                <select class="form-control" id="pv_autorizacao" [(ngModel)]="autorizacaoSelecionada" name="pv_autorizacao">
                                                    <option [value]="null" i18n>Selecione uma Autorizacao</option>
                                                    <option *ngFor="let auth of autorizacoesNaoVinculadas" [value]="auth.id">{{auth.nomeExibicao}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <label>&nbsp;</label><br>
                                            <button class="btn btn-md btn-primary" (click)="vincularAutorizacao()" title="Vincular autorização" alt="Vincular autorização"><i class="fas fa-plus-square"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <table-component [tituloSessao]="'Autorizacoes'" [cols]="4" [rows]="busca.quantityOfElements" [totalDePaginas]="totalDePaginas" (eventoBotao)="cliqueNoBotaoListener($event)">
                                <tr tableheader>
                                    <th style="width: 10px">#</th>
                                    <th>Nome de exibição</th>
                                    <th>Nome</th>
                                    <th class="text-center">Desvincular</th>
                                </tr>
                                <tr tablebody *ngFor="let autorizacao of autorizacoes">
                                    <td>{{autorizacao.id}}</td>
                                    <td>{{autorizacao.nomeExibicao}}</td>
                                    <td>{{autorizacao.nome}}</td>
                                    <td class="text-center">
                                        <button class="btn btn-sm btn-danger" (click)="desvincularAutorizacao(autorizacao.id)" title="Desvincular autorização" alt="Desvincular autorização"><i class="fas fa-minus-square"></i></button>
                                    </td>
                                </tr>
                            </table-component>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</secured>