import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'left-sidebar-user-panel-component',
  templateUrl: './left-sidebar-user-panel.component.html',
})
export class LeftSidebarUserPanelComponent implements OnInit {
  @Input()
  thumbnail: string;

  @Input()
  nome: string;

  ngOnInit(): void {}
}
