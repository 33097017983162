import { GenericService } from 'src/app/core/services/generic.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from "@angular/core";
import { Apostolado } from 'src/app/model/apostolado.model';


@Injectable()
export class ApostoladoService extends GenericService<Apostolado> {
    
    constructor(protected http: HttpClient, protected router:Router) {
        super(http,router);
        this.setEntityType('r/apostolado');
    }

    pesquisar(request: any) : Promise<any> {
        return this.http.post<any>(`${this.apiURL}/pesquisar`, JSON.stringify(request), { 'headers' : this.getHeaders() }).toPromise();
    }   
    
    porTipoId(id: number) : Promise<any> {
        return this.http.get<any>(`${this.apiURL}/portipo/${id}`, { 'headers' : this.getHeaders() }).toPromise();
    } 
 
}