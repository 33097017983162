import { Inject, Injectable } from '@angular/core';
import { AceiteTermoTratamentoDados } from '../model/aceitetermotratamentodados.model';
import { AbstractBuilder } from './abstract.builder';


@Injectable()
export class AceiteTermoTratamentoDadosBuilder extends AbstractBuilder<AceiteTermoTratamentoDados> {

    reset() : void {
        this.entity = new AceiteTermoTratamentoDados(null,null,null,null,null);
    }

}