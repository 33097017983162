import { Component, OnInit } from "@angular/core";
import { Pessoa } from 'src/app/model/pessoa.model';
import { Pais } from 'src/app/model/pais.model';
import { PessoaService } from './service/pessoa.service';
import { PaisService } from '../pais/service/pais.service';
import { environment } from "src/environments/environment";
import { SwtAlert2Service } from "src/app/core/swtalert2/swtalert2.service";
import { ImagemService } from "../imagem/service/imagem.service";

 @Component({
     selector: 'pessoa',
     templateUrl: './pessoas.component.html', styleUrls:['./style/style.css']

 })
 export class PessoasComponent implements OnInit{
     
    public pessoas: Array<Pessoa>;
    public paises: Array<Pais>;
    public busca: any;
    public totalDePaginas: number; 
    public urlBaseImagem: string;

    constructor(
        public pessoaService: PessoaService,
        public paisService: PaisService,
        public swtAlert2Service: SwtAlert2Service,
        public imagemService: ImagemService
    ){
        this.pessoas = new Array<Pessoa>();
        this.urlBaseImagem = environment.imagem.urlBaseImagem;
        this.paises = new Array<Pais>();
        this.busca = {
            nome: null,
            sobrenome: null,
            genero: null,
            anonascimento: null,
            nacionalidadeId: null,
            pageNumber: 0,
            quantityOfElements : 10
        };

    }

    ngOnInit(): void {
        this.carregarPaises();
        this.buscarPessoas();
    }

    carregarPaises() : void {
        this.paisService.findAll().then( (lista: any) => {
            this.paises = lista.entity;
        }).catch( (err: any) => {
            console.log(err);
        });
    }

    async buscarPessoas() : Promise<any> {

        try
        {
            let response = await this.pessoaService.buscarPessoas(this.busca);

            this.pessoas = response.entity;

            this.totalDePaginas = response.quantity;

            for(let i = 0; i < this.pessoas.length ; i++)
            {

                if(this.pessoas[i].imagemId != null)
                {
                    let blogImage = await this.getPessoaImagem(this.pessoas[i].imagemId);

                    this.imagemService.createImage(blogImage, this.pessoas[i]);
                }  
                
            } 

        }
        catch(err)
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }

    }

    async getPessoaImagem(id:number): Promise<any> {

        try
        {
            let response = await this.imagemService.getPessoaPhoto(id);
            return response.body;
        }
        catch(err)
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }

    }

    deletarPessoa(id: number) : void {

        if(confirm('Deseja deletar esta pessoa?'))
        {
            this.pessoaService.delete(id)
            .then( (response: any) => {
                this.swtAlert2Service.successAlert(response.message);
                this.buscarPessoas();
            })
            .catch((err) => {
                this.swtAlert2Service.errorAlert(err.error.errors);
            });
        }
    }

    async setarRegistroDuplicado(pessoaId: number) : Promise<any> {

        try 
        {
            let response = await this.pessoaService.setarRegistroDuplicado(pessoaId);   
            this.swtAlert2Service.successAlert(response.message);
        } 
        catch (err) {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }
    }

    
    cliqueNoBotaoListener(botao) {
        this.busca.pageNumber = botao.numero-1;
        this.buscarPessoas();
    }

    getUrlImagem(imagemId) : string {
        return this.urlBaseImagem+imagemId;
    }
 }