<div class="row">
    <div class="col-sm-12 col-md-12 col-lg-12">
        <div class="card card-gray-dark color-pallete-box">
            <div class="card-header">
                <h3 class="card-title">
                    <i class="fas fa-child"></i>
                    <a i18n> Membros </a>
                </h3>
                <div class="card-tools"><button class="btn btn-tool" data-card-widget="collapse" type="button"><i
                            class="fas fa-minus"></i></button></div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-12 col-md-6 col-lg-6">
                        <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12">
                                <div class="form-group">
                                    <label i18n for="npastor_pessoa">Pessoa</label>
                                    <span class="form-control">{{pessoaMembroSelecionada.nome}}
                                        {{pessoaMembroSelecionada.sobrenome}}</span>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-12">
                                <div class="form-group">
                                    <label i18n for="button-add-pessoa">&nbsp;</label>
                                    <button name="button-add-pessoa" data-toggle="modal"
                                        data-target="#listagem-pessoa-membro" style="cursor: pointer"
                                        class="btn btn-primary" i18n>Selecionar Membro</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-3 col-lg-3">
                        <div class="form-group">
                            <label i18n for="nmembro_dtchegada">Data de Chegada</label>
                            <input [(ngModel)]="salvarMissionarioMissaoAtual.dataChegada" name="nmembro_dtchegada"
                                type="date" class="form-control" id="nmembro_dtentrada" i18n-placeholder>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-3">
                        <div class="form-group">
                            <label i18n for="button-part-submit">&nbsp;</label>
                            <button type="submit" (click)="adicionarMembroCasaComunitaria()" name="button-part-submit"
                                class="form-control btn btn-primary" i18n>Adicionar Membro</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card card-gray-dark color-pallete-box">
                            <div class="card-header">
                                <h3 class="card-title">
                                    <i class="fas fa-people"></i>
                                    <a i18n> Membros da Casa Comunitária </a>
                                </h3>
                                <div class="card-tools"><button class="btn btn-tool" data-card-widget="collapse"
                                        type="button"><i class="fas fa-minus"></i></button></div>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-12" style='text-align: center'>
                                        <table-component [cardBodyClass]="'table-responsive p-0'" [tableClass]="'table-striped'" [tituloSessao]="'Listagem de Missionários'" [cols]="11" [rows]="busca.quantityOfElements" [totalDePaginas]="totalDePaginas" (eventoBotao)="cliqueNoBotaoListener($event)">
                                            <tr tableheader>                                                
                                                <th>Foto de perfil</th>
                                                <th>Nome</th>                                                
                                                <th>Data de Entrada</th>
                                                <th>Forma de Vida</th>
                                                <th>Nível Formativo</th>
                                                <th>Estado de Vida</th>
                                                <th *ngIf="podeRemoverMissionario"></th>
                                            </tr>
                                            <tr tablebody *ngFor="let membro of membrosCasaComunitaria">                                                
                                                <td>
                                                    <div class="widget-user-image">
                                                        <img class="profile-user-img img-fluid img-circle image-size-list elevation-2" [src]="membro.thumbnail == null ? 'assets/images/ppl.png' : membro.thumbnail" alt="User Avatar">
                                                    </div>
                                                </td>
                                                <td>{{membro.pessoaNome}}</td>                                                
                                                <td>{{membro.dataChegadaCasaComunitaria | date : 'dd/MM/yyyy'}}</td>
                                                <td>{{membro.formaVidaNome}}</td>
                                                <td>{{membro.nivelFormativoNome}}</td>
                                                <td>{{membro.estadoVidaNome}}</td>
                                                <td *ngIf="podeRemoverMissionario">
                                                    <button 
                                                        name="button-remove-missionario" 
                                                        data-toggle="modal" 
                                                        data-target="#remocao-membro-casa-comunitaria" 
                                                        style="cursor: pointer" class="btn btn-primary" (click)="membroSelecionado = membro;" i18n>Registrar Saída</button>
                                                </td>
                                            </tr>
                                        </table-component>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <listagem-pessoainfo-modal-component [modalId]="'listagem-pessoa-membro'" [onlyMissionaries]="true"
            [bringNotInformedWayOfLife]="true" [filterByUserAccess]="true" [modalTitle]="'Selecione o Membro do Grupo'"
            [buttonText]="'Selecionar'" [altButtonText]="'Selecionar Membro'"
            (eventEmitter)="selecionarMembro($event)"></listagem-pessoainfo-modal-component>
        <remocao-membro-casa-comunitaria-component 
            [modalId]="'remocao-membro-casa-comunitaria'"
            [modalTitle]="'Remover missionário da casa comunitária'"
            [buttonText]="'Remover'" 
            [altButtonText]="'Remover Missionário'"
            [membroCasaComunitaria]="membroSelecionado"
            (eventEmitter)="recarregarMembros($event)"
        ></remocao-membro-casa-comunitaria-component>
    </div>
</div>