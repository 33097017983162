import { Component, OnInit } from '@angular/core';
import { PessoaBuilder } from 'src/app/builder/pessoa.builder';
import { Pessoa } from 'src/app/model/pessoa.model';
import { Questionario } from 'src/app/model/questionario.model';
import { Usuario } from 'src/app/model/usuario.model';
import { QuestionarioService } from './service/questionario.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { UsuarioService } from '../../wop/usuario/service/usuario.service';
import { PessoaService } from '../../wop/pessoa/service/pessoa.service';

@Component({
  selector: 'app-questionario',
  templateUrl: './questionario.component.html',
  styleUrls: ['./questionario.component.css'],
})
export class QuestionarioComponent implements OnInit {
  public usuario: Usuario;
  public pessoa: Pessoa;
  public pessoaBuilder: PessoaBuilder;

  public totalDePaginas: number = 1;

  public questionario: Questionario;
  public questionarios: Array<any>;
  public origem: string;

  constructor(
    public usuarioService: UsuarioService,
    public pessoaService: PessoaService,
    //public questionarioBuilder: QuestionarioBuilder,
    public questionarioService: QuestionarioService,
    public route: ActivatedRoute,
    public router: Router,
    public swtAlert2Service: SwtAlert2Service
  ) {
    this.pessoaBuilder = new PessoaBuilder();
    //    this.questionarios = new Array<any>();
    //    this.questionario = this.questionarioBuilder.getInstance();
  }

  ngOnInit(): void {
    const url = this.route.snapshot.url
      .map((segment) => segment.path)
      .join('/');
    // Verifica se o caminho contém celula ou casacomunitaria
    if (url.includes('celula')) {
      this.origem = 'celula';
    } else if (url.includes('casacomunitaria')) {
      this.origem = 'casacomunitaria';
    }
    this.initializer();
  }

  async initializer(): Promise<any> {
    await this.carregarUsuario();
    await this.carregarPessoa();
    await this.buscarQuestionarios();
  }

  async buscarQuestionarios() {
    try {
      let response = await this.questionarioService.buscarAbertos();
      this.questionarios = response.content;
      this.totalDePaginas = response.totalPages;
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  /*  carregarQuestionarioConfiguracao(questionarioId: number) {
    const missaoId = this.pessoa.vinculoMissao.missaoAtualId;
    this.missaoConfiguracaoService
      .obterConfiguracaoQuestionarioPorMissaoEQuestionario(
        missaoId,
        questionarioId
      )
      .subscribe(
        (questionarioConfiguracao) =>
          (this.questionarioConfiguracao = questionarioConfiguracao)
      );
  }
*/
  async carregarUsuario(): Promise<any> {
    try {
      let response = await this.usuarioService.getCurrentUser();
      this.usuario = response.entity;
    } catch (e) {
      console.log(e);
    }
  }

  async carregarPessoa(): Promise<any> {
    try {
      let response = await this.pessoaService.buscaPorUsuarioId(
        this.usuario.id
      );
      this.pessoa = response.entity;
    } catch (e) {
      console.log(e);
    }
  }
}
