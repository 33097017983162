import { Component, OnInit } from '@angular/core';
import 'select2';
import { environment } from "src/environments/environment";
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { ImagemService } from "../../wop/imagem/service/imagem.service";
import { Missao } from 'src/app/model/missao.model';
import { Pais } from 'src/app/model/pais.model';
import { ElementoGrupoDominio } from 'src/app/model/elementogrupodominio.model';
import { Apostolado } from 'src/app/model/apostolado.model';
import { ApostoladoBuilder } from 'src/app/builder/apostolado.builder';
import { Pessoa } from 'src/app/model/pessoa.model';
import { PessoaService } from '../../wop/pessoa/service/pessoa.service';
import { Endereco } from 'src/app/model/endereco.model';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { EnderecoService } from '../../wop//endereco/service/endereco.service';
import intlTelInput from 'intl-tel-input';
import { Documento } from 'src/app/model/documento.model';
import { ElementoGrupoDominioService } from '../../wop/grupodominio/service/elementogrupodominio.service';
import * as bootastrap from 'bootstrap';
import { PessoaBuilder } from 'src/app/builder/pessoa.builder';
import { MembroComunidadeAlianca } from 'src/app/model/membrocomunidadealianca.model';
import { MembroComunidadeVida } from 'src/app/model/membrocomunidadevida.model';
import { RegionalService } from '../../wop/regional/service/regional.service';
import { Regional } from 'src/app/model/regional.model';
import { MissaoService } from '../../wop//missao/service/missao.service';
import { DifusaoService } from '../../wop//difusao/service/difusao.service';
import { Difusao } from 'src/app/model/difusao.model';
import { MembroComunidadeAliancaService } from '../../wop//membrocomunidadealianca/servico/membrocomunidadealianca.service';
import { MembroComunidadeVidaService } from '../../wop//membrocomunidadevida/servico/membrocomunidadevida.service';
import { Aptidao } from 'src/app/model/aptidao.model';
import { Escolaridade } from 'src/app/model/escolaridade.model';
import { Trabalho } from 'src/app/model/trabalho.model';
import { AptidaoBuilder } from 'src/app/builder/aptidao.builder';
import { EscolaridadeBuilder } from 'src/app/builder/escolaridade.builder';
import { TrabalhoBuilder } from 'src/app/builder/trabalho.builder';
import { AptidaoService } from '../../wop/aptidao/service/aptidao.service';
import { EscolaridadeService } from '../../wop/escolaridade/service/escolaridade.service';
import { TrabalhoService } from '../../wop/trabalho/service/trabalho.service';
import { Usuario } from 'src/app/model/usuario.model';
import { UsuarioService } from '../../wop/usuario/service/usuario.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PaisService } from '../../wop/pais/service/pais.service';
import { PessoaNacionalidadeBuilder } from 'src/app/builder/pessoanacionalidade.builder';
import { PessoaNacionalidadeService } from '../../wop/pessoa/service/pessoanacionalidade.service';
import { PessoaNacionalidade } from 'src/app/model/pessoanacionalidade.model';
import { Nacionalidade } from 'src/app/model/nacionalidade.model';
import { NacionalidadeService } from '../../wop/nacionalidade/service/obra/service/nacionalidade.service';
import { Visto } from 'src/app/model/visto.model';
import { VistoLocalService } from '../visto/service/visto.local.service';
import { VistoBuilder } from 'src/app/builder/visto.builder';
import { Filho } from 'src/app/model/filho.model';
import { FilhoBuilder } from 'src/app/builder/filho.builder';
import { PessoaInfo } from 'src/app/model/pessoainfo.model';
import { PessoaInfoBuilder } from 'src/app/builder/pessoainfo.builder';
import { FilhoService } from '../../wop/dadosvocacionais/service/filho.service';
import { DadoVocacional } from 'src/app/model/dadovocacional.model';
import { DadoVocacionalBuilder } from 'src/app/builder/dadovocacional.builder';
import { Celibatario } from 'src/app/model/celibatario.model';
import { HistoricoMissionarioMissao } from 'src/app/model/historicomissionariomissao.model';
import { HistoricoMissionarioMissaoApostolado } from 'src/app/model/historicomissionariomissaoapostolado.model';
import { Relacionamento } from 'src/app/model/relacionamento.model';
import { Sacerdote } from 'src/app/model/sacerdote.model';
import { CelibatarioBuilder } from 'src/app/builder/celibatario.builder';
import { HistoricoMissionarioMissaoApostoladoBuilder } from 'src/app/builder/historicomissionariomissaoapostolado.builder';
import { RelacionamentoBuilder } from 'src/app/builder/relacionamento.builder';
import { SacerdoteBuilder } from 'src/app/builder/sacerdote.builder';
import { DadoVocacionalService } from '../../wop/dadosvocacionais/service/dadovocacional.service';
import { CelibatarioService } from '../../wop/dadosvocacionais/service/celibatario.service';
import { HistoricoMissionarioMissaoService } from '../../wop/dadosvocacionais/service/historicomissionariomissao.service';
import { HistoricoMissionarioMissaoApostoladoService } from '../../wop/dadosvocacionais/service/historicomissionariomissaoapostolado.service';
import { RelacionamentoService } from '../../wop/dadosvocacionais/service/relacionamento.service';
import { SacerdoteService } from '../../wop/dadosvocacionais/service/sacerdote.service';
import { HistoricoMissionarioMissaoBuilder } from 'src/app/builder/historicomissionariomissao.builder';
import { CasaComunitariaService } from '../../wop/casacomunitaria/service/casacomunitaria.service';
import { CasaComunitaria } from 'src/app/model/casacomunitaria.model';
import { ApostoladoService } from '../../wop/dadosvocacionais/service/apostolado.service';
import { DocumentoService } from '../../wop/documento/service/documento.service';
import { FamiliaInfo } from 'src/app/model/familiainfo.model';
import { FamiliaInfoLocalService } from '../familiainfo/service/familiainfo.local.service';
import { FamiliaInfoBuilder } from 'src/app/builder/familiainfo.builder';



@Component({
  selector: 'missionario',
  templateUrl: './missionario.view.component.html',
  styleUrls: ['../../wop/pessoa/style/style.css', '../../wop/pessoa/style/normalize.css']  
})
export class MissionarioViewComponent implements OnInit {

    public pessoa: Pessoa;
    public membroComunidadeAlianca: MembroComunidadeAlianca;
    public membroComunidadeVida: MembroComunidadeVida;
    public aptidao: Aptidao;
    public escolaridade: Escolaridade;
    public trabalho: Trabalho;
    public missoesVinculo: Array<Missao>;
    public difusoesVinculo: Array<Difusao>;
    public missoes: Array<Missao>;
    public difusoes: Array<Difusao>;
    public escolaridades: Array<Escolaridade>;
    public trabalhos: Array<Trabalho>;
    public pessoaId: number;
    public tabNumber: number;
    public imageChangedEvent: any;
    public croppedImage: any;
    public nextClicked: boolean;
    public url: string;
    public endereco: Endereco;
    public telefone: string;
    public intlTelInputCalled: boolean = false;
    public intlTellInputInstance : any;
    public tipoDocumentoSelecionado: ElementoGrupoDominio;
    public documento: Documento;
    public documentos: Array<Documento>;
    public tiposDocumento: Array<ElementoGrupoDominio>;
    public estadosCivil: Array<ElementoGrupoDominio>;
    public formasVida: Array<ElementoGrupoDominio>;
    public formaVidaSelecionada: ElementoGrupoDominio;
    public habilidades: Array<ElementoGrupoDominio>;
    public linguas: Array<ElementoGrupoDominio>;
    public niveisEscolaridade: Array<ElementoGrupoDominio>;
    public pessoaBuilder: PessoaBuilder;
    public aptidaoBuilder: AptidaoBuilder;
    public escolaridadeBuilder: EscolaridadeBuilder;
    public trabalhoBuilder: TrabalhoBuilder;    
    public niveisFormacao: Array<ElementoGrupoDominio>;
    public estadosVida: Array<ElementoGrupoDominio>;
    public tipoOrigem: string;
    public regionais: Array<Regional>;
    public urlBaseImagem: string;
    public usuarioAtual: Usuario;
    public paises: Array<Pais>;
    public visto: Visto;
    public regionalSelecionadoId: number;

    public nacionalidades: Array<Nacionalidade>;
    public vistos: Array<Visto>;

    public pessoaNacionalidade: PessoaNacionalidade;
    public pessoaNacionalidades: Array<PessoaNacionalidade>;    

    public nivelEscolaridadeSelecionado: ElementoGrupoDominio;

    public imageUrl : any;
    public formaVidaSelecionadaId: number;
    public estadoVidaSelecionadoId: number;
    public tiposRelacionamento: Array<ElementoGrupoDominio>;
    public hasFilho: boolean;
    public filho: Filho;
    public familiaInfo: FamiliaInfo;
    public pessoaFilhoSelecionado: PessoaInfo;
    public pessoaFormadorPessoalSelecionado: PessoaInfo;
    public pessoaRelacionamentoSelecionada: PessoaInfo;
    public dadoVocacional: DadoVocacional;
    public apostolados: Array<Apostolado>;
    public apostoladosSelecionados: Array<Apostolado>;
    public apostolado: Apostolado;
    public celibatario: Celibatario;
    public historicoMissionarioMissao: HistoricoMissionarioMissao;
    public historicosMissionarioMissao: Array<HistoricoMissionarioMissao>;
    public historicoMissionarioMissaoApostolado: HistoricoMissionarioMissaoApostolado;
    public historicosMissionarioMissaoApostolados: Array<HistoricoMissionarioMissaoApostolado>;
    public relacionamento: Relacionamento;
    public sacerdote: Sacerdote;
    public filhos: Array<Filho>;
    public casasComunitarias: Array<CasaComunitaria>;
    public missaoSelecionadaId: number;
    public paisSelecionadoId: number;
    public estadoSelecionadoId: number;
    public tipoApostoladoSelecionadoId: number;

    constructor(
        public pessoaService: PessoaService,
        public enderecoService: EnderecoService,
        public imagemService: ImagemService,
        public elementoGrupoDominioService: ElementoGrupoDominioService,
        public regionalService: RegionalService,
        public missaoService: MissaoService,
        public difusaoService: DifusaoService,
        public membroComunidadeAliancaService: MembroComunidadeAliancaService,
        public membroComunidadeVidaService: MembroComunidadeVidaService,
        public escolaridadeService: EscolaridadeService,
        public trabalhoService: TrabalhoService,
        public aptidaoService: AptidaoService,
        public documentoService: DocumentoService,
        public swtAlert2Service: SwtAlert2Service,
        public vistoService: VistoLocalService,
        public vistoBuilder: VistoBuilder,
        public pessoaNacionalidadeService: PessoaNacionalidadeService,
        public pessoaNacionalidadeBuilder: PessoaNacionalidadeBuilder,
        public nacionalidadeService: NacionalidadeService,
        public casaComunitariaService: CasaComunitariaService,
        public usuarioService: UsuarioService,
        public paisService: PaisService,
        public filhoService: FilhoService,
        public familiaInfoService: FamiliaInfoLocalService,
        public familiaInfoBuilder: FamiliaInfoBuilder,
        public dadoVocacionalService: DadoVocacionalService,
        public celibatarioService: CelibatarioService,
        public apostoladoService: ApostoladoService,
        public historicoMissionarioMissaoService: HistoricoMissionarioMissaoService,
        public historicoMissionarioMissaoApostoladoService: HistoricoMissionarioMissaoApostoladoService,
        public relacionamentoService: RelacionamentoService,
        public sacerdoteService: SacerdoteService,
        public pessoaInfoBuilder: PessoaInfoBuilder,
        public filhoBuilder: FilhoBuilder,
        public dadoVocacionalBuilder: DadoVocacionalBuilder,
        public celibatarioBuilder: CelibatarioBuilder,
        public apostoladoBuilder: ApostoladoBuilder,
        public historicoMissionarioMissaoBuilder: HistoricoMissionarioMissaoBuilder,
        public historicoMissionarioMissaoApostoladoBuilder: HistoricoMissionarioMissaoApostoladoBuilder,
        public relacionamentoBuilder: RelacionamentoBuilder,
        public sacerdoteBuilder: SacerdoteBuilder,        
        public route: ActivatedRoute,
        public router: Router        
    ) {        
        
        this.regionais = new Array<Regional>();
        this.missoesVinculo = new Array<Missao>();
        this.difusoesVinculo = new Array<Difusao>();
        this.missoes = new Array<Missao>();
        this.difusoes = new Array<Difusao>();
        this.escolaridades = new Array<Escolaridade>();
        this.trabalhos = new Array<Trabalho>();
        this.estadosCivil = new Array<ElementoGrupoDominio>();
        this.tiposDocumento = new Array<ElementoGrupoDominio>();
        this.formasVida = new Array<ElementoGrupoDominio>();
        this.niveisFormacao = new Array<ElementoGrupoDominio>();
        this.estadosVida = new Array<ElementoGrupoDominio>();       
        this.habilidades = new Array<ElementoGrupoDominio>();       
        this.linguas = new Array<ElementoGrupoDominio>();
        this.niveisEscolaridade = new Array<ElementoGrupoDominio>();  
        this.pessoaNacionalidades = new Array<PessoaNacionalidade>();
        this.nacionalidades = new Array<Nacionalidade>();
        this.vistos = new Array<Visto>();     
        this.imageChangedEvent = '';
        this.croppedImage = '';
        this.nextClicked = false;
        
        this.pessoaBuilder = new PessoaBuilder();
        this.aptidaoBuilder = new AptidaoBuilder();
        this.escolaridadeBuilder = new EscolaridadeBuilder();
        this.trabalhoBuilder = new TrabalhoBuilder();

        this.pessoa = this.pessoaBuilder.getInstance();
        this.aptidao = this.aptidaoBuilder.getInstance();
        this.escolaridade = this.escolaridadeBuilder.getInstance();
        this.trabalho = this.trabalhoBuilder.getInstance();
        this.pessoaNacionalidade = this.pessoaNacionalidadeBuilder.getInstance();
        this.familiaInfo = this.familiaInfoBuilder.getInstance();
        this.visto = this.vistoBuilder.getInstance();         

        this.urlBaseImagem = environment.imagem.urlBaseImagem;
        this.url = environment.usuario.selectPorUsername;

        this.regionalSelecionadoId = null;
        this.formaVidaSelecionadaId = null;
        this.estadoVidaSelecionadoId = null;
        this.paisSelecionadoId = null;
        this.estadoSelecionadoId = null;
        this.missaoSelecionadaId = null;
        this.pessoa = this.pessoaBuilder.getInstance();   
        this.filho = this.filhoBuilder.getInstance();  
        this.pessoaFilhoSelecionado = this.pessoaInfoBuilder.getInstance();
        this.pessoaFormadorPessoalSelecionado = this.pessoaInfoBuilder.getInstance();
        this.pessoaRelacionamentoSelecionada = this.pessoaInfoBuilder.getInstance();
        this.dadoVocacional = this.dadoVocacionalBuilder.getInstance();
        this.celibatario = this.celibatarioBuilder.getInstance();
        this.apostolado = this.apostoladoBuilder.getInstance();
        this.historicoMissionarioMissao = this.historicoMissionarioMissaoBuilder.getInstance();
        this.historicoMissionarioMissaoApostolado = this.historicoMissionarioMissaoApostoladoBuilder.getInstance();
        this.relacionamento = this.relacionamentoBuilder.getInstance();
        this.sacerdote = this.sacerdoteBuilder.getInstance();
        this.filhos = new Array<Filho>();   
        this.paises = new Array<Pais>();
        this.apostolados = new Array<Apostolado>();
        this.historicosMissionarioMissao = new Array<HistoricoMissionarioMissao>();
        this.historicosMissionarioMissaoApostolados = new Array<HistoricoMissionarioMissaoApostolado>();

    }

    ngOnInit() {        
        this.initializacao();
    }

    async initializacao() : Promise<any> {

        this.pessoaId = Number(this.route.snapshot.paramMap.get('id')); 
        await this.getUsuarioAtual();
        await this.carregarPaises();
        await this.carregarFormasVida();
        await this.carregarEstadosCivil();
        await this.carregarNiveisFormacao();
        await this.carregarEstadoVida();
        await this.carregarLinguas();
        await this.carregarHabilidades();
        await this.carregarNiveisEscolaridade();
        await this.carregarMissoesVinculo();
        await this.carregarDifusoesVinculo();
        //this.carregarMissoes();
        this.carregarTipoRelacionamento();
        this.carregarApostolados();
        await this.getUsuarioAtual();   
        await this.carregarDadoVocacional();
        await this.carregarCelibatario();
        await this.carregarRelacionamento();
        await this.carregarSacerdote();    
        await this.carregarFamilia();    
        this.carregarFilhos();   
        this.carregarHistoricoMissionarioMissao();

        $("select[name='linguas-select']").select2({
            theme: "bootstrap4"            
        });

        $("select[name='habilidades-select']").select2({
            theme: "bootstrap4"
        });

        await this.carregarPessoa(this.pessoaId);
    }

    carregarPaises() : void {
        this.paisService.findAll().then( (lista: any) => {
            this.paises = lista.entity;
        }).catch( (err: any) => {
            console.log(err);
        });
    }

    carregarOrigem() 
    {
        if(this.regionalSelecionadoId != undefined)
        {
            if(this.tipoOrigem == "M")
            {
                this.carregarMissoes();                
            }
            else if(this.tipoOrigem == "D")
            {
                this.carregarDifusoes();                
            }
        }
    }

    async carregarMissoesVinculo(): Promise<any> {

        try 
        {
            let response = await this.missaoService.findAll();   
            this.missoesVinculo = response.entity;     
            this.missoesVinculo = this.missoesVinculo.sort( (a:Missao, b:Missao) => a.nome.localeCompare(b.nome)); 
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async carregarDifusoesVinculo(): Promise<any> {

        try 
        {
            let response = await this.difusaoService.findAll();   
            this.difusoesVinculo = response.entity;     
            this.difusoesVinculo = this.difusoesVinculo.sort( (a:Difusao, b:Difusao) => a.nome.localeCompare(b.nome)); 
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    carregarMissoes(): void {
        this.missaoService.porRegional(this.regionalSelecionadoId).then((response: any) => {
            this.missoes = response.entity;   
            this.difusoes = null;       
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }

    carregarDifusoes(): void {
        this.difusaoService.porRegional(this.regionalSelecionadoId).then((response: any) => {
            this.difusoes = response.entity;  
            this.missoes = null;        
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }

    async carregarPessoaNacionalidades(pessoaId: number): Promise<null> {
        
        try
        {
            let response = await this.pessoaNacionalidadeService.porPessoaId(pessoaId);
            this.pessoaNacionalidades = response.entity;    
        }
        catch(err)
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
            this.swtAlert2Service.errorAlert(err.error.errors);             
        }

        return null;
    }

    async carregarNacionalidades(): Promise<null> {
        
        try
        {
            let response = await this.nacionalidadeService.findAll();
            this.nacionalidades = response.entity;    
            this.nacionalidades = this.nacionalidades.sort((a, b) => a.descricao.localeCompare(b.descricao, 'fi'));
        }
        catch(err)
        {
            this.swtAlert2Service.errorAlert(err.error.errors);          
        }

        return null;
    }
    async carregarVistos(pessoaId: number): Promise<null> {
        
        try
        {
            let response = await this.vistoService.porPessoaId(pessoaId);
            this.vistos = response.entity;    
        }
        catch(err)
        {
            this.swtAlert2Service.errorAlert(err.error.errors);   
        }

        return null;
    }

    ngAfterViewChecked() {

        if(!this.intlTelInputCalled && $('#pessoa-telefone').length == 1)
        {
            var input = document.querySelector("#pessoa-telefone");
            this.intlTellInputInstance = intlTelInput(input, {
                initialCountry: "auto",
                geoIpLookup: callback => {
                    fetch("https://ipapi.co/json")
                      .then(res => res.json())
                      .then(data => callback(data.country_code))
                      .catch(() => callback("us"));
                },
                utilsScript : 'assets/scripts/utils.js'
            });
            this.intlTelInputCalled = true;
        }
        
    }

    async carregarPessoa(Id: number) : Promise<any> {

        try
        {
            let response = await this.pessoaService.find(Id);

            this.pessoa = response.entity;

            if(this.pessoa.imagemId != null)
            {
                let blogImage = await this.getPessoaImagem(this.pessoa.imagemId);

                this.imagemService.createImage(blogImage, this.pessoa);
            }            

            if(this.pessoa.usuarioId != null)
            {
                $("select[name='usuario']").append(new Option(this.pessoa.usuarioUsername, this.pessoa.usuarioId.toString(), false, true));
            }
            if(this.pessoa.formaVidaId != null)
            {
                this.setarFormaVidaSelecionada();
            }
            
            if(this.pessoa.aptidaoId != null)
            {
                this.carregarAptidao(this.pessoa.aptidaoId);
            }
            if(this.pessoa.trabalhoIds.length > 0)
            {
                this.encontrarTrabalhoPorPessoaId(this.pessoaId);
            }
            this.encontrarDocumentosPorPessoaId(this.pessoaId);
            this.formaVidaSelecionadaId = this.pessoa.formaVidaId;
            this.estadoVidaSelecionadoId = this.pessoa.estadoVidaId;
        }
        catch(err)
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }

    }

    /* carregarObra(obraId: number): void {
        this.obraService.find(obraId).then((response: any) => {
            this.obra = response.entity;
        }).catch((err: any) => {
            console.log(err);
        });
    }

    carregarMembroComunidadeAlianca(membroComunidadeAliancaId: number): void {
        this.membroComunidadeAliancaService.find(membroComunidadeAliancaId).then((response: any) => {
            this.membroComunidadeAlianca = response.entity;      
            this.regionalSelecionadoId = this.membroComunidadeAlianca.regionalId;   
            if(this.membroComunidadeAlianca.missaoOrigemId != null) 
            {
                this.tipoOrigem = 'M';
            }
            else if(this.membroComunidadeAlianca.difusaoOrigemId != null) 
            {
                this.tipoOrigem = 'D';
            }
            this.carregarOrigem();
        }).catch((err: any) => {
            console.log(err);
        });
    }

    carregarMembroComunidadeVida(membroComunidadeVidaId: number): void {
        this.membroComunidadeVidaService.find(membroComunidadeVidaId).then((response: any) => {
            this.membroComunidadeVida = response.entity;
            this.regionalSelecionadoId = this.membroComunidadeVida.regionalId;            
            if(this.membroComunidadeVida.missaoOrigemId != null) 
            {
                this.tipoOrigem = 'M';
            }
            else if(this.membroComunidadeVida.difusaoOrigemId != null) 
            {
                this.tipoOrigem = 'D';
            }
            this.carregarOrigem();
        }).catch((err: any) => {
            console.log(err);
        });
    } */

    async carregarAptidao(aptidaoId: number): Promise<null> {
        
        try
        {
            let response = await this.aptidaoService.find(aptidaoId);
            this.aptidao = response.entity;    
            this.encontrarEscolaridadesPorAptidaoId(this.aptidao.id);
            $("select[name='linguas-select']").val(this.aptidao.linguaIds).trigger('change');
            $("select[name='habilidades-select']").val(this.aptidao.habilidadeIds).trigger('change');
        }
        catch(err)
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);            
        }

        return null;
    }

    async encontrarEscolaridadesPorAptidaoId(aptidaoId: number): Promise<null> {
        
        try
        {        
            let response = await this.escolaridadeService.findByAptidaoId(aptidaoId);
            this.escolaridades = response.entity;       
        }
        catch(err)
        {
            console.log(err);            
        }

        return null;
    }

    async encontrarTrabalhoPorPessoaId(pessoaId: number): Promise<null> {
        
        try
        {        
            let response = await this.trabalhoService.findByPessoaId(pessoaId);
            this.trabalhos = response.entity;       
        }
        catch(err)
        {
            console.log(err);            
        }

        return null;
    }

    async carregarTipoDocumento() : Promise<any> {

        let buscaDocumentos = {
            grupoNome : 'TIPOS_DOCUMENTOS_PESSOA'
        }

        try
        {
            let response = await this.elementoGrupoDominioService.buscarPorGrupoDominioNome(buscaDocumentos);
            this.tiposDocumento = response.entity;
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async carregarEstadosCivil() : Promise<any> 
    {
        let buscaEstadosCivil = {
            grupoNome : 'ESTADO_CIVIL'
        }

        try
        {
            let response = await this.elementoGrupoDominioService.buscarPorGrupoDominioNome(buscaEstadosCivil);
            this.estadosCivil = response.entity;
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async carregarFormasVida() : Promise<any> 
    {
        let buscaFormaVida = {
            grupoNome : 'FORMA_VIDA'
        }

        try
        {
            let response = await this.elementoGrupoDominioService.buscarPorGrupoDominioNome(buscaFormaVida);
            this.formasVida = response.entity;
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async carregarFamilia(): Promise<any> {

        try
        {
            let response = await this.familiaInfoService.porPessoaId(this.pessoaId);

            if(response.entity != null)
            {
                this.familiaInfo = response.entity;
            }
            
        }
        catch(err)
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }

    }

    encontrarDocumentosPorPessoaId(pessoaId: number): void {
        this.documentoService.buscarPorPessoaId(pessoaId).then((response: any) => {
            this.documentos = response.entity;
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors); 
        });
    }

    async carregarNiveisFormacao() : Promise<any> 
    {
        let busca = {
            grupoNome : 'NIVEL_FORMACAO'
        }

        try
        {
            let response = await this.elementoGrupoDominioService.buscarPorGrupoDominioNome(busca);
            this.niveisFormacao = response.entity;
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }
    
    async carregarEstadoVida() : Promise<any> 
    {
        let busca = {
            grupoNome : 'ESTADO_VIDA'
        }

        try
        {
            let response = await this.elementoGrupoDominioService.buscarPorGrupoDominioNome(busca);
            this.estadosVida = response.entity;
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async carregarHabilidades() : Promise<null> 
    {
        let busca = {
            grupoNome : 'HABILIDADE'
        }

        try
        {            
            let response = await this.elementoGrupoDominioService.buscarPorGrupoDominioNome(busca);       
            this.habilidades = response.entity;
        }
        catch(e)
        {
            console.log(e);
        };

        return null;
    }

    async carregarLinguas() : Promise<null> 
    {
        let busca = {
            grupoNome : 'LINGUA'
        }

        try
        {            
            let response = await this.elementoGrupoDominioService.buscarPorGrupoDominioNome(busca);       
            this.linguas = response.entity;
        }
        catch(e)
        {
            console.log(e);
        };

        return null;
    }

    async carregarNiveisEscolaridade() : Promise<null> 
    {
        let busca = {
            grupoNome : 'NIVEL_ESCOLARIDADE'
        }

        try
        {            
            let response = await this.elementoGrupoDominioService.buscarPorGrupoDominioNome(busca);       
            this.niveisEscolaridade = response.entity;
        }
        catch(e)
        {
            console.log(e);
        };

        return null;
    }

    get valorTipoDocumento() {
        let tipoSelecionado : Array<ElementoGrupoDominio>  = this.tiposDocumento.filter((tipo) => {
            return (tipo.id == this.documento.elementoGrupoDominioTipoId);
        });

        return tipoSelecionado.length > 0 ? tipoSelecionado[0].valor : '';
    }

    logOnConsole(dadosPessoaisForm:any):void {
        console.log(dadosPessoaisForm);       
    }

    activateTab(tab:string) : void {
        $('.nav-tabs a[href="#' + tab + '"]').removeClass('disabled')
        $('.nav-tabs a[href="#' + tab + '"]').tab('show');        
    }

    setarElementoSelecionado(valor) {
        this.pessoa.usuarioId = valor;
    }

    fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
        console.log(event);
    }

    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
    }

    imageLoaded() {
        console.log('Imagem carregada');        
    }

    cropperReady() {
        console.log('Imagem cortada');
        
    }

    loadImageFailed() {
        console.log('Carregamento da imagem falhou!');        
    }

    setarFormaVidaSelecionada() : void {
        for(let i = 0; i < this.formasVida.length; i++)
        {
            if(this.formasVida[i].id == this.pessoa.formaVidaId)
            {
                this.formaVidaSelecionada = this.formasVida[i];
            }
        }
    }

    setarNivelEscolaridadeSelecionado() : void {
        for(let i = 0; i < this.niveisEscolaridade.length; i++)
        {
            if(this.niveisEscolaridade[i].id == this.escolaridade.nivelEscolaridadeId)
            {
                this.nivelEscolaridadeSelecionado = this.niveisEscolaridade[i];
            }
        }
    }

    async getPessoaImagem(id:number): Promise<any> {

        try
        {
            let response = await this.imagemService.getPessoaPhoto(id);
            return response.body;
        }
        catch(err)
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }

    } 

    async carregarCasasComunitarias(missaoId: number) : Promise<any> 
    {
        try 
        {
            let response = await this.casaComunitariaService.porMissao(missaoId);
            this.casasComunitarias = response.entity;
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors)
        }
    }
    async carregarDadoVocacional() : Promise<any> 
    {
        try 
        {
            let response = await this.dadoVocacionalService.porPessoaId(this.pessoaId);
            if(response.entity != undefined && response.entity != null)
            {
                this.dadoVocacional = response.entity;
                this.pessoaFormadorPessoalSelecionado = this.pessoaInfoBuilder.getInstance();
                this.pessoaFormadorPessoalSelecionado.nome = this.dadoVocacional.formadorPessoalPessoaNome;
            }
            
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors)
        }
    }
    async carregarCelibatario() : Promise<any> 
    {
        try 
        {
            let response = await this.celibatarioService.porPessoaId(this.pessoaId);
            if(response.entity != undefined && response.entity != null)
            {
                this.celibatario = response.entity;
            }
            
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors)
        }
    }
    async carregarRelacionamento() : Promise<any> 
    {
        try 
        {
            let response = await this.relacionamentoService.porPessoaId(this.pessoaId);
            if(response.entity != undefined && response.entity != null)
            {
                this.relacionamento = response.entity;
                this.pessoaRelacionamentoSelecionada = this.pessoaInfoBuilder.getInstance();
                if(this.relacionamento.pessoa1Id == this.pessoaId)
                {
                    this.pessoaRelacionamentoSelecionada.nome = this.relacionamento.pessoa2Nome;
                }
                else if(this.relacionamento.pessoa2Id == this.pessoaId)
                {
                    this.pessoaRelacionamentoSelecionada.nome = this.relacionamento.pessoa1Nome;
                }
                
            }
            
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors)
        }
    }
    async carregarSacerdote() : Promise<any> 
    {
        try 
        {
            let response = await this.sacerdoteService.porPessoaId(this.pessoaId);
            if(response.entity != undefined && response.entity != null)
            {
                this.sacerdote = response.entity;                
            }
            
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors)
        }
    }
    async carregarHistoricoMissionarioMissao() : Promise<any> 
    {
        try 
        {
            let response = await this.historicoMissionarioMissaoService.porPessoaId(this.pessoaId);
            if(response.entity != undefined && response.entity != null)
            {
                this.historicosMissionarioMissao = response.entity;                
            }
            
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors)
        }
    }
    async carregarHistoricoMissionarioMissaoApostolado(historicoMissionarioMissaoID: number) : Promise<any> 
    {
        try 
        {
            let response = await this.historicoMissionarioMissaoApostoladoService.findByHistoricoMissionarioMissaoId(historicoMissionarioMissaoID);
            if(response.entity != undefined && response.entity != null)
            {
                this.historicosMissionarioMissaoApostolados = response.entity;                
            }
            
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors)
        }
    }

    async carregarTipoRelacionamento() : Promise<any> 
    {
        let request = {
            grupoNome : 'TIPO_RELACIONAMENTO'
        }
        try 
        {
            let response = await this.elementoGrupoDominioService.buscarPorGrupoDominioNome(request);
            this.tiposRelacionamento = response.entity;
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors)
        }
    }

    async carregarApostolados() : Promise<any> 
    {
        let request = {
            ativo : null
        }
        try 
        {
            let response = await this.apostoladoService.pesquisar(request);
            this.apostolados = response.entity;
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors)
        }
    }

    async getUsuarioAtual() : Promise<any> {
        try 
        {
            let response = await this.usuarioService.getCurrentUser();   
            this.usuarioAtual = response.entity;      
        }
        catch(err)
        {
            this.swtAlert2Service.errorAlert(err.error.errors)
        }
    }

    async carregarFilhos() : Promise<any> {
        try
        {
            let response = null;
            
            if(this.pessoa.genero == 'masculino')
            {
                response = await await this.filhoService.porPaiId(this.pessoaId);
            }
            else if(this.pessoa.genero == 'feminino')
            {
                response = await await this.filhoService.porMaeId(this.pessoaId);
            }
            if(response != null)
            {
                this.filhos = response.entity;
                if(this.filhos.length > 0)
                {
                    this.hasFilho = true;
                }
            }
        }
        catch(err)
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }
    }
    async configModalHistoricoApostolado(historicoMissionarioMissaoId: number) : Promise<any> {
        this.historicoMissionarioMissaoApostolado.historicoMissionarioMissaoId = historicoMissionarioMissaoId;
        this.carregarHistoricoMissionarioMissaoApostolado(historicoMissionarioMissaoId);
    }

    textoStatus(pessoaNacionalidade: PessoaNacionalidade) {

        let returnText = '';

        $('select[name=pessoa_nac_status]').find('option').each(function() {
            if($(this).val() == pessoaNacionalidade.status) {
                returnText = $(this).text();
            }
        })

        return returnText;
    }

    textoNacionalidade(pessoaNacionalidade: PessoaNacionalidade) {

        let returnText = '';
        
        $('select[name=pessoa_nac]').find('option').each(function() {
            if($(this).val() == pessoaNacionalidade.nacionalidadeId) {
                returnText = $(this).text();
            }
        })

        return returnText;
    }
}