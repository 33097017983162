/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./confirmacao.cadastro.component";
import * as i2 from "./service/confirmacaocadastro.service";
import * as i3 from "@angular/router";
import * as i4 from "../../../core/swtalert2/swtalert2.service";
var styles_ConfirmacaoCadastroComponent = [];
var RenderType_ConfirmacaoCadastroComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ConfirmacaoCadastroComponent, data: {} });
export { RenderType_ConfirmacaoCadastroComponent as RenderType_ConfirmacaoCadastroComponent };
export function View_ConfirmacaoCadastroComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "body", [["class", "hold-transition login-page"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 11, "div", [["class", "login-page-item"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 10, "div", [["class", "callout callout-info"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 6, "div", [["class", "login-logo"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 5, "a", [], null, null, null, null, null)), (_l()(), i0.ɵeld(5, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Way Of "])), (_l()(), i0.ɵted(-1, null, ["PEACE"])), (_l()(), i0.ɵeld(8, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" System "])), (_l()(), i0.ɵeld(10, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i0.ɵeld(11, 0, null, null, 0, "i", [["class", "fas fa-info"]], null, null, null, null, null)), (_l()(), i0.ɵted(12, null, ["\u00A0\u00A0\u00A0\u00A0", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.mensagemConfirmacao; _ck(_v, 12, 0, currVal_0); }); }
export function View_ConfirmacaoCadastroComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "confirmacaocadastro", [], null, null, null, View_ConfirmacaoCadastroComponent_0, RenderType_ConfirmacaoCadastroComponent)), i0.ɵdid(1, 114688, null, 0, i1.ConfirmacaoCadastroComponent, [i2.ConfirmacaoCadastroService, i3.ActivatedRoute, i3.Router, i4.SwtAlert2Service], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConfirmacaoCadastroComponentNgFactory = i0.ɵccf("confirmacaocadastro", i1.ConfirmacaoCadastroComponent, View_ConfirmacaoCadastroComponent_Host_0, {}, {}, []);
export { ConfirmacaoCadastroComponentNgFactory as ConfirmacaoCadastroComponentNgFactory };
