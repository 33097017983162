import { GenericService } from 'src/app/core/services/generic.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from "@angular/core";
import { Difusao } from 'src/app/model/difusao.model';


@Injectable()
export class DifusaoService extends GenericService<Difusao> {
    
    constructor(protected http: HttpClient, protected router:Router) {
        super(http,router);
        this.setEntityType('r/difusao');
    }

    buscar(busca: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/buscar', JSON.stringify(busca), { 'headers' : this.getHeaders() }).toPromise();
    }

    porRegional(regionalId: number) : Promise<any> {
        return this.http.get<any>(this.apiURL+'/porregional/'+regionalId, { 'headers' : this.getHeaders() }).toPromise();
    }

    getAllNotInAcessoUsuarioEstrutura(id: number): Promise<any> {
        return this.http.get<any>(`${this.apiURL}/getallnotinacessousuarioestrutura/${id}`, { 'headers' : this.getHeaders() }).toPromise();
    }

    getAllFromAcessoUsuarioEstrutura(id: number): Promise<any> {
        return this.http.get<any>(`${this.apiURL}/getallfromacessousuarioestrutura/${id}`, { 'headers' : this.getHeaders() }).toPromise();
    }

}