import { OnInit, Component } from '@angular/core';

@Component({
    selector: 'relacionamentosedit',
    templateUrl: './relacionamentos.edit.component.html',
    styleUrls: ['../pessoa/style/style.css', '../pessoa/style/normalize.css']
})

export class RelacionamentosEditComponent implements OnInit {
      
    constructor(
    ) { }
  
    ngOnInit(): void {
      this.setarEstadoVidaSelecionado();
    }

    setarEstadoVidaSelecionado() : void {
    }
}