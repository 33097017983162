import { Component, OnDestroy, OnInit } from '@angular/core';
import { MensagemRlSharedStateService } from '../../service/mensagem-rl-shared-state.service';
import {
  DetalhesMensagemParaRL,
  MensagemDeMembroParaRLOrigem,
  MensagemRLService,
} from '../../service/mensagemrl.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-mensagem-rl-content',
  templateUrl: './mensagem-rl-content.component.html',
  styleUrls: ['./mensagem-rl-content.component.css'],
})
export class MensagemRlContentComponent implements OnInit, OnDestroy {
  hideMessageContent: boolean = true;
  selectedSummary?: DetalhesMensagemParaRL = null;
  mensagensMembro?: Array<MensagemDeMembroParaRLOrigem>;

  private obterThumbnailSubscription: Subscription;
  private obterMensagensDoMembroPorPessoaIdSubscription: Subscription;

  imageSrc: string = 'assets/images/ppl.png';

  constructor(
    public mensagemRlSharedStateService: MensagemRlSharedStateService,
    public mensagemRlService: MensagemRLService
  ) {}

  ngOnInit() {
    this.obterThumbnailSubscription =
      this.mensagemRlSharedStateService.currentMessageSummary.subscribe(
        this.obterThumbnail
      );
    this.obterMensagensDoMembroPorPessoaIdSubscription =
      this.mensagemRlSharedStateService.currentMessageSummary.subscribe(
        this.obterMensagensDoMembroPorPessoaId
      );
  }

  ngOnDestroy(): void {
    this.obterThumbnailSubscription.unsubscribe();
    this.obterMensagensDoMembroPorPessoaIdSubscription.unsubscribe();
  }

  obterThumbnail = (summary: DetalhesMensagemParaRL) => {
    if (summary) {
      this.selectedSummary = summary;
      if (this.selectedSummary && this.selectedSummary.thumbnail) {
        this.imageSrc = this.selectedSummary.thumbnail;
      }
      this.hideMessageContent = false;
    }
  };

  obterMensagensDoMembroPorPessoaId = (summary: DetalhesMensagemParaRL) => {
    if (summary) {
      this.mensagemRlService
        .obterMensagemDeMembroParaRLPorPessoaId(summary.pessoaId)
        .subscribe((mensagens) => (this.mensagensMembro = mensagens));
    }
  };
}
