import { NgModule } from '@angular/core';
import { AceiteTermoComponent } from './aceitetermouso.component';
import { Routes, RouterModule } from '@angular/router';

const aceiteTermoUsoRoutes: Routes = [
    {
        path: 'aceitetermouso',
        component: AceiteTermoComponent
    }
]

@NgModule({
    imports: [
        RouterModule.forChild(aceiteTermoUsoRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class AceiteTermoRoutingModule {
    
}