import { GenericService } from 'src/app/core/services/generic.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from "@angular/core";
import { HistoricoMissionarioMissao } from 'src/app/model/historicomissionariomissao.model';


@Injectable()
export class HistoricoMissionarioMissaoService extends GenericService<HistoricoMissionarioMissao> {
    
    constructor(protected http: HttpClient, protected router:Router) {
        super(http,router);
        this.setEntityType('historicomissionariomissao');
    }

    adicionarMembroNaCasaComunitaria(membro: HistoricoMissionarioMissao) : Promise<any> {
        return this.http.post<any>(`${this.apiURL}/adicionarmembrocasacomunitaria/`, JSON.stringify(membro), { 'headers' : this.getHeaders() }).toPromise();
    }  

    porPessoaId(id: number) : Promise<any> {
        return this.http.get<any>(`${this.apiURL}/porpessoa/${id}`, { 'headers' : this.getHeaders() }).toPromise();
    }
 
}