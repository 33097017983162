import { Component, OnInit } from '@angular/core';
import { Regional } from 'src/app/model/regional.model';
import { Router, ActivatedRoute } from '@angular/router';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { AcessoUsuarioEstruturaService } from './service/acessousuarioestrutura.service';
import { RegionalService } from '../regional/service/regional.service';
import { AutorizacaoService } from '../autorizacao/service/autorizacao.service';
import { Autorizacao } from 'src/app/model/autorizacao.model';
import { environment } from 'src/environments/environment';
import { Missao } from 'src/app/model/missao.model';
import { MissaoService } from '../missao/service/missao.service';
import { IrradiacaoService } from '../irradiacao/service/irradiacao.service';
import { DifusaoService } from '../difusao/service/difusao.service';
import { CentroEvangelizacaoService } from '../centroevangelizacao/service/centroevangelizacao.service';
import { GrupoDeOracaoService } from '../grupodeoracao/grupodeoracao.service';
import { CelulaService } from '../celula/service/celula.service';
import { CasaComunitariaService } from '../casacomunitaria/service/casacomunitaria.service';
import { MinisterioService } from '../ministerio/service/ministerio.service';
import { ProjetoService } from '../projeto/service/projeto.service';
import { Irradiacao } from 'src/app/model/irradiacao.model';
import { Difusao } from 'src/app/model/difusao.model';
import { CentroEvangelizacao } from 'src/app/model/centroevangelizacao.model';
import { GrupoDeOracao } from 'src/app/model/grupodeoracao.model';
import { Celula } from 'src/app/model/celula.model';
import { CasaComunitaria } from 'src/app/model/casacomunitaria.model';
import { Ministerio } from 'src/app/model/ministerio.model';
import { Projeto } from 'src/app/model/projeto.model';
import { AcessoUsuarioEstrutura } from 'src/app/model/acessousuarioestrutura.model';
import { AcessoUsuarioEstruturaBuilder } from 'src/app/builder/acessousuarioestrutura.builder';
import { ElementoGrupoDominio } from 'src/app/model/elementogrupodominio.model';
import { ElementoGrupoDominioService } from '../grupodominio/service/elementogrupodominio.service';

@Component({
    selector: 'acesso-usuario-estrutura-edit',
    templateUrl: './acessousuarioestrutura.edit.component.html'
})
export class AcessoUsuarioEstruturaEditComponent implements OnInit {

    public acessoUsuarioEstrutura: AcessoUsuarioEstrutura;
    public acessoUsuarioEstruturaId: number;
    public pessoaSearchUrl: string;
    public autorizacoes: Array<Autorizacao>;
    public regionais : Array<Regional>;
    public regionaisAdicionados : Array<Regional>;  
    public regionalToAddId: number;     
    public missoes : Array<Missao>;
    public missoesAdicionados : Array<Missao>;  
    public missaoToAddId: number; 
    public irradiacoes : Array<Irradiacao>;
    public irradiacoesAdicionados : Array<Irradiacao>;  
    public irradiacaoToAddId: number;   
    public difusoes : Array<Difusao>;
    public difusoesAdicionados : Array<Difusao>;  
    public difusaoToAddId: number; 
    public centrosEvangelizacao : Array<CentroEvangelizacao>;
    public centrosEvangelizacaoAdicionados : Array<CentroEvangelizacao>;  
    public centroEvangelizacaoToAddId: number;
    public gruposOracao : Array<GrupoDeOracao>;
    public gruposOracaoAdicionados : Array<GrupoDeOracao>;  
    public grupoOracaoToAddId: number;
    public publicosGrupoOracao : Array<ElementoGrupoDominio>;
    public publicosGrupoOracaoAdicionados : Array<ElementoGrupoDominio>;  
    public publicoGrupoOracaoToAddId: number;
    public celulas : Array<Celula>;
    public celulasAdicionados : Array<Celula>;  
    public celulaToAddId: number;
    public casasComunitarias : Array<CasaComunitaria>;
    public casasComunitariasAdicionados : Array<CasaComunitaria>;  
    public casaComunitariaToAddId: number;
    public ministerios : Array<Ministerio>;
    public ministeriosAdicionados : Array<Ministerio>;  
    public ministerioToAddId: number;
    public projetos : Array<Projeto>;
    public projetosAdicionados : Array<Projeto>;  
    public projetoToAddId: number;

    constructor(
        public acessoUsuarioEstruturaService: AcessoUsuarioEstruturaService,
        public acessoUsuarioEstruturaBuilder: AcessoUsuarioEstruturaBuilder,
        public regionalService: RegionalService,
        public missaoService: MissaoService,
        public irradiacaoService: IrradiacaoService,
        public difusaoService: DifusaoService,
        public centroEvangelizacaoService: CentroEvangelizacaoService,
        public grupoOracaoService: GrupoDeOracaoService,
        public celulaService: CelulaService,
        public casaComunitariaService: CasaComunitariaService,
        public ministerioService: MinisterioService,
        public projetoService: ProjetoService,
        public elementoGrupoDominioService: ElementoGrupoDominioService,
        public autorizacaoService: AutorizacaoService,
        public swtAlert2Service: SwtAlert2Service,
        public route: ActivatedRoute,
        public router: Router
    ) 
    {
        this.acessoUsuarioEstrutura = this.acessoUsuarioEstruturaBuilder.getInstance();
        this.pessoaSearchUrl = environment.pessoa.usuarioIdPorPessoaNomeURL;
        this.regionais = new Array<Regional>();
        this.regionaisAdicionados = new Array<Regional>();
        this.regionalToAddId = null;
        this.missoes = new Array<Missao>();
        this.missoesAdicionados = new Array<Missao>();
        this.missaoToAddId = null;
        this.irradiacoes = new Array<Irradiacao>();
        this.irradiacoesAdicionados = new Array<Irradiacao>();
        this.irradiacaoToAddId = null;
        this.difusoes = new Array<Difusao>();
        this.difusoesAdicionados = new Array<Difusao>();
        this.difusaoToAddId = null;
        this.centrosEvangelizacao = new Array<CentroEvangelizacao>();
        this.centrosEvangelizacaoAdicionados = new Array<CentroEvangelizacao>();
        this.centroEvangelizacaoToAddId = null;
        this.gruposOracao = new Array<GrupoDeOracao>();
        this.gruposOracaoAdicionados = new Array<GrupoDeOracao>();
        this.grupoOracaoToAddId = null;
        this.publicosGrupoOracao = new Array<ElementoGrupoDominio>();
        this.publicosGrupoOracaoAdicionados = new Array<ElementoGrupoDominio>();
        this.publicoGrupoOracaoToAddId = null;
        this.celulas = new Array<Celula>();
        this.celulasAdicionados = new Array<Celula>();
        this.celulaToAddId = null;
        this.casasComunitarias = new Array<CasaComunitaria>();
        this.casasComunitariasAdicionados = new Array<CasaComunitaria>();
        this.casaComunitariaToAddId = null;
        this.ministerios = new Array<Ministerio>();
        this.ministeriosAdicionados = new Array<Ministerio>();
        this.ministerioToAddId = null;
        this.projetos = new Array<Projeto>();
        this.projetosAdicionados = new Array<Projeto>();
        this.projetoToAddId = null;
    }

    ngOnInit() { 
        this.initialize();
    }

    async initialize() : Promise<any> {
        this.acessoUsuarioEstruturaId = Number(this.route.snapshot.paramMap.get('id'));                 
        this.carregarAcessoUsuarioEstrutura(this.acessoUsuarioEstruturaId);
        this.carregarAutorizacao();
        this.carregarEstruturas();
    }

    async carregarAutorizacao() : Promise<any> {

        try 
        {
            let response = await this.autorizacaoService.findAll();
            this.autorizacoes = response.entity;
            
        } catch (err) {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }

    }

    async carregarAcessoUsuarioEstrutura(id: number) : Promise<any> {

        try 
        {
            let response = await this.acessoUsuarioEstruturaService.find(id);
            this.acessoUsuarioEstrutura = response.entity;
            if(this.acessoUsuarioEstrutura.usuarioId != null)
            {
                $("select[name='pessoa']").append(new Option(this.acessoUsuarioEstrutura.usuarioUsername.concat(' - ').concat(this.acessoUsuarioEstrutura.pessoaNome), this.acessoUsuarioEstrutura.usuarioId.toString(), false, true));
            }
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }

    }

    // Regionais

    async carregarRegionais() : Promise<any> {

        try 
        {
            let response = await this.regionalService.getAllNotInAcessoUsuarioEstrutura(this.acessoUsuarioEstruturaId);
            this.regionais = response.entity;
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors);    
        }

    }

    async carregarRegionaisAdicionados() : Promise<any> {

        try 
        {
            let response = await this.regionalService.getAllFromAcessoUsuarioEstrutura(this.acessoUsuarioEstruturaId);
            this.regionaisAdicionados = response.entity;
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors);    
        }

    }

    async adicionarAcessoRegional(id: number) : Promise<any> {

        try 
        {
            let request = {
                acessoUsuarioEstruturaId : this.acessoUsuarioEstruturaId,
                regionalId : id
            }

            let response = await this.acessoUsuarioEstruturaService.adicionarAcessoRegional(request);
            this.swtAlert2Service.successAlert(response.message);
            this.regionalToAddId = null;
            await this.carregarEstruturas();
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors); 
        }

    }

    async removerAcessoRegional(id: number) : Promise<any> {

        if(confirm("Deseja remover o acesso a esta estrutura?"))
        {
            try 
            {
                let request = {
                    acessoUsuarioEstruturaId : this.acessoUsuarioEstruturaId,
                    regionalId : id
                }

                let response = await this.acessoUsuarioEstruturaService.removerAcessoRegional(request);
                this.swtAlert2Service.successAlert(response.message);
                this.regionalToAddId = null;
                this.regionais = new Array<Regional>();
                await this.carregarEstruturas();
            } 
            catch (err) 
            {
                this.swtAlert2Service.errorAlert(err.error.errors); 
            }
        }
        
    }

    // Missões

    async carregarMissoes() : Promise<any> {

        if(this.regionaisAdicionados.length > 0)
        {
            try 
            {
                let response = await this.missaoService.getAllNotInAcessoUsuarioEstrutura(this.acessoUsuarioEstruturaId);
                this.missoes = response.entity;
            } 
            catch (err) 
            {
                this.swtAlert2Service.errorAlert(err.error.errors);    
            }
        }        

    }

    async carregarMissoesAdicionados() : Promise<any> {

        try 
        {
            let response = await this.missaoService.getAllFromAcessoUsuarioEstrutura(this.acessoUsuarioEstruturaId);
            this.missoesAdicionados = response.entity;
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors);    
        }

    }

    async adicionarAcessoMissao(id: number) : Promise<any> {

        try 
        {
            let request = {
                acessoUsuarioEstruturaId : this.acessoUsuarioEstruturaId,
                missaoId : id
            }

            let response = await this.acessoUsuarioEstruturaService.adicionarAcessoMissao(request);
            this.swtAlert2Service.successAlert(response.message);
            this.missaoToAddId = null;
            await this.carregarEstruturas();
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors); 
        }

    }

    async removerAcessoMissao(id: number) : Promise<any> {

        if(confirm("Deseja remover o acesso a esta estrutura?"))
        {
            try 
            {
                let request = {
                    acessoUsuarioEstruturaId : this.acessoUsuarioEstruturaId,
                    missaoId : id
                }

                let response = await this.acessoUsuarioEstruturaService.removerAcessoMissao(request);
                this.swtAlert2Service.successAlert(response.message);
                this.missaoToAddId = null;
                this.missoes = new Array<Missao>();
                await this.carregarEstruturas();
            } 
            catch (err) 
            {
                this.swtAlert2Service.errorAlert(err.error.errors); 
            }
        }
        
    }

    // Irradiacoes

    async carregarIrradiacoes() : Promise<any> {

        if(this.missoesAdicionados.length > 0)
        {
            try 
            {
                let response = await this.irradiacaoService.getAllNotInAcessoUsuarioEstrutura(this.acessoUsuarioEstruturaId);
                this.irradiacoes = response.entity;
            } 
            catch (err) 
            {
                this.swtAlert2Service.errorAlert(err.error.errors);    
            }
        }        

    }

    async carregarIrradiacoesAdicionados() : Promise<any> {

        try 
        {
            let response = await this.irradiacaoService.getAllFromAcessoUsuarioEstrutura(this.acessoUsuarioEstruturaId);
            this.irradiacoesAdicionados = response.entity;
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors);    
        }

    }

    async adicionarAcessoIrradiacao(id: number) : Promise<any> {

        try 
        {
            let request = {
                acessoUsuarioEstruturaId : this.acessoUsuarioEstruturaId,
                irradiacaoId : id
            }

            let response = await this.acessoUsuarioEstruturaService.adicionarAcessoIrradiacao(request);
            this.swtAlert2Service.successAlert(response.message);
            this.irradiacaoToAddId = null;
            await this.carregarEstruturas();
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors); 
        }

    }

    async removerAcessoIrradiacao(id: number) : Promise<any> {

        if(confirm("Deseja remover o acesso a esta estrutura?"))
        {
            try 
            {
                let request = {
                    acessoUsuarioEstruturaId : this.acessoUsuarioEstruturaId,
                    irradiacaoId : id
                }

                let response = await this.acessoUsuarioEstruturaService.removerAcessoIrradiacao(request);
                this.swtAlert2Service.successAlert(response.message);
                this.irradiacaoToAddId = null;
                this.irradiacoes = new Array<Irradiacao>();
                await this.carregarEstruturas();
            } 
            catch (err) 
            {
                this.swtAlert2Service.errorAlert(err.error.errors); 
            }
        }
        
    }

    // Difusões

    async carregarDifusoes() : Promise<any> {

        if(this.regionaisAdicionados.length > 0)
        {
            try 
            {
                let response = await this.difusaoService.getAllNotInAcessoUsuarioEstrutura(this.acessoUsuarioEstruturaId);
                this.difusoes = response.entity;
            } 
            catch (err) 
            {
                this.swtAlert2Service.errorAlert(err.error.errors);    
            }
        }        

    }

    async carregarDifusoesAdicionados() : Promise<any> {

        try 
        {
            let response = await this.difusaoService.getAllFromAcessoUsuarioEstrutura(this.acessoUsuarioEstruturaId);
            this.difusoesAdicionados = response.entity;
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors);    
        }

    }

    async adicionarAcessoDifusao(id: number) : Promise<any> {

        try 
        {
            let request = {
                acessoUsuarioEstruturaId : this.acessoUsuarioEstruturaId,
                difusaoId : id
            }

            let response = await this.acessoUsuarioEstruturaService.adicionarAcessoDifusao(request);
            this.swtAlert2Service.successAlert(response.message);
            this.difusaoToAddId = null;
            await this.carregarEstruturas();
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors); 
        }

    }

    async removerAcessoDifusao(id: number) : Promise<any> {

        if(confirm("Deseja remover o acesso a esta estrutura?"))
        {
            try 
            {
                let request = {
                    acessoUsuarioEstruturaId : this.acessoUsuarioEstruturaId,
                    difusaoId : id
                }

                let response = await this.acessoUsuarioEstruturaService.removerAcessoDifusao(request);
                this.swtAlert2Service.successAlert(response.message);
                this.difusaoToAddId = null;
                this.difusoes = new Array<Difusao>();
                await this.carregarEstruturas();
            } 
            catch (err) 
            {
                this.swtAlert2Service.errorAlert(err.error.errors); 
            }
        }
        
    }

    // Centro de Evangelização

    async carregarCentrosEvangelizacao() : Promise<any> {

        if(this.missoesAdicionados.length > 0 || this.irradiacoesAdicionados.length > 0 || this.difusoesAdicionados.length > 0)
        {
            try 
            {
                let response = await this.centroEvangelizacaoService.getAllNotInAcessoUsuarioEstrutura(this.acessoUsuarioEstruturaId);
                this.centrosEvangelizacao = response.entity;
            } 
            catch (err) 
            {
                this.swtAlert2Service.errorAlert(err.error.errors);    
            }
        }        

    }

    async carregarCentrosEvangelizacaoAdicionados() : Promise<any> {

        try 
        {
            let response = await this.centroEvangelizacaoService.getAllFromAcessoUsuarioEstrutura(this.acessoUsuarioEstruturaId);
            this.centrosEvangelizacaoAdicionados = response.entity;
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors);    
        }

    }

    async adicionarAcessoCentroEvangelizacao(id: number) : Promise<any> {

        try 
        {
            let request = {
                acessoUsuarioEstruturaId : this.acessoUsuarioEstruturaId,
                centroEvangelizacaoId : id
            }

            let response = await this.acessoUsuarioEstruturaService.adicionarAcessoCentroEvangelizacao(request);
            this.swtAlert2Service.successAlert(response.message);
            this.centroEvangelizacaoToAddId = null;
            await this.carregarEstruturas();
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors); 
        }

    }

    async removerAcessoCentroEvangelizacao(id: number) : Promise<any> {

        if(confirm("Deseja remover o acesso a esta estrutura?"))
        {
            try 
            {
                let request = {
                    acessoUsuarioEstruturaId : this.acessoUsuarioEstruturaId,
                    centroEvangelizacaoId : id
                }

                let response = await this.acessoUsuarioEstruturaService.removerAcessoCentroEvangelizacao(request);
                this.swtAlert2Service.successAlert(response.message);
                this.centroEvangelizacaoToAddId = null;
                this.centrosEvangelizacao = new Array<CentroEvangelizacao>();
                await this.carregarEstruturas();
            } 
            catch (err) 
            {
                this.swtAlert2Service.errorAlert(err.error.errors); 
            }
        }
        
    }

    // Grupo de Oração

    async carregarGruposOracao() : Promise<any> {

        if(this.centrosEvangelizacaoAdicionados.length > 0)
        {
            try 
            {
                let response = await this.grupoOracaoService.getAllNotInAcessoUsuarioEstrutura(this.acessoUsuarioEstruturaId);
                this.gruposOracao = response.entity;
            } 
            catch (err) 
            {
                this.swtAlert2Service.errorAlert(err.error.errors);    
            }
        }        

    }

    async carregarGruposOracaoAdicionados() : Promise<any> {

        try 
        {
            let response = await this.grupoOracaoService.getAllFromAcessoUsuarioEstrutura(this.acessoUsuarioEstruturaId);
            this.gruposOracaoAdicionados = response.entity;
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors);    
        }

    }

    async adicionarAcessoGrupoOracao(id: number) : Promise<any> {

        try 
        {
            let request = {
                acessoUsuarioEstruturaId : this.acessoUsuarioEstruturaId,
                grupoOracaoId : id
            }

            let response = await this.acessoUsuarioEstruturaService.adicionarAcessoGrupoOracao(request);
            this.swtAlert2Service.successAlert(response.message);
            this.grupoOracaoToAddId = null;
            await this.carregarEstruturas();
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors); 
        }

    }

    async removerAcessoGrupoOracao(id: number) : Promise<any> {

        if(confirm("Deseja remover o acesso a esta estrutura?"))
        {
            try 
            {
                let request = {
                    acessoUsuarioEstruturaId : this.acessoUsuarioEstruturaId,
                    grupoOracaoId : id
                }

                let response = await this.acessoUsuarioEstruturaService.removerAcessoGrupoOracao(request);
                this.swtAlert2Service.successAlert(response.message);
                this.grupoOracaoToAddId = null;
                this.gruposOracao = new Array<GrupoDeOracao>();
                await this.carregarEstruturas();
            } 
            catch (err) 
            {
                this.swtAlert2Service.errorAlert(err.error.errors); 
            }
        }
        
    }

    // Público Grupo de Oração

    async carregarPublicosGrupoOracao() : Promise<any> {

        try 
        {
            let response = await this.elementoGrupoDominioService.getAllNotInAcessoUsuarioEstrutura(this.acessoUsuarioEstruturaId);
            this.publicosGrupoOracao = response.entity;
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors);    
        }              

    }

    async carregarPublicosGrupoOracaoAdicionados() : Promise<any> {

        try 
        {
            let response = await this.elementoGrupoDominioService.getAllFromAcessoUsuarioEstrutura(this.acessoUsuarioEstruturaId);
            this.publicosGrupoOracaoAdicionados = response.entity;
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors);    
        }

    }

    async adicionarAcessoPublicoGrupoOracao(id: number) : Promise<any> {

        try 
        {
            let request = {
                acessoUsuarioEstruturaId : this.acessoUsuarioEstruturaId,
                publicoGrupoOracaoId : id
            }

            let response = await this.acessoUsuarioEstruturaService.adicionarAcessoPublicoGrupoOracao(request);
            this.swtAlert2Service.successAlert(response.message);
            this.publicoGrupoOracaoToAddId = null;
            await this.carregarEstruturas();
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors); 
        }

    }

    async removerAcessoPublicoGrupoOracao(id: number) : Promise<any> {

        if(confirm("Deseja remover o acesso a esta estrutura?"))
        {
            try 
            {
                let request = {
                    acessoUsuarioEstruturaId : this.acessoUsuarioEstruturaId,
                    publicoGrupoOracaoId : id
                }

                let response = await this.acessoUsuarioEstruturaService.removerAcessoPublicoGrupoOracao(request);
                this.swtAlert2Service.successAlert(response.message);
                this.publicoGrupoOracaoToAddId = null;
                this.publicosGrupoOracao = new Array<ElementoGrupoDominio>();
                await this.carregarEstruturas();
            } 
            catch (err) 
            {
                this.swtAlert2Service.errorAlert(err.error.errors); 
            }
        }
        
    }

    // Célula

    async carregarCelulas() : Promise<any> {

        if(this.centrosEvangelizacaoAdicionados.length > 0)
        {
            try 
            {
                let response = await this.celulaService.getAllNotInAcessoUsuarioEstrutura(this.acessoUsuarioEstruturaId);
                this.celulas = response.entity;
            } 
            catch (err) 
            {
                this.swtAlert2Service.errorAlert(err.error.errors);    
            }
        }        

    }

    async carregarCelulasAdicionados() : Promise<any> {

        try 
        {
            let response = await this.celulaService.getAllFromAcessoUsuarioEstrutura(this.acessoUsuarioEstruturaId);
            this.celulasAdicionados = response.entity;
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors);    
        }

    }

    async adicionarAcessoCelula(id: number) : Promise<any> {

        try 
        {
            let request = {
                acessoUsuarioEstruturaId : this.acessoUsuarioEstruturaId,
                celulaId : id
            }

            let response = await this.acessoUsuarioEstruturaService.adicionarAcessoCelula(request);
            this.swtAlert2Service.successAlert(response.message);
            this.celulaToAddId = null;
            await this.carregarEstruturas();
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors); 
        }

    }

    async removerAcessoCelula(id: number) : Promise<any> {

        if(confirm("Deseja remover o acesso a esta estrutura?"))
        {
            try 
            {
                let request = {
                    acessoUsuarioEstruturaId : this.acessoUsuarioEstruturaId,
                    celulaId : id
                }

                let response = await this.acessoUsuarioEstruturaService.removerAcessoCelula(request);
                this.swtAlert2Service.successAlert(response.message);
                this.celulaToAddId = null;
                this.celulas = new Array<Celula>();
                await this.carregarEstruturas();
            } 
            catch (err) 
            {
                this.swtAlert2Service.errorAlert(err.error.errors); 
            }
        }
        
    }

    // Casa Comunitária

    async carregarCasasComunitarias() : Promise<any> {

        if(this.missoesAdicionados.length > 0)
        {
            try 
            {
                let response = await this.casaComunitariaService.getAllNotInAcessoUsuarioEstrutura(this.acessoUsuarioEstruturaId);
                this.casasComunitarias = response.entity;
            } 
            catch (err) 
            {
                this.swtAlert2Service.errorAlert(err.error.errors);    
            }
        }        

    }

    async carregarCasasComunitariasAdicionados() : Promise<any> {

        try 
        {
            let response = await this.casaComunitariaService.getAllFromAcessoUsuarioEstrutura(this.acessoUsuarioEstruturaId);
            this.casasComunitariasAdicionados = response.entity;
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors);    
        }

    }

    async adicionarAcessoCasaComunitaria(id: number) : Promise<any> {

        try 
        {
            let request = {
                acessoUsuarioEstruturaId : this.acessoUsuarioEstruturaId,
                casaComunitariaId : id
            }

            let response = await this.acessoUsuarioEstruturaService.adicionarAcessoCasaComunitaria(request);
            this.swtAlert2Service.successAlert(response.message);
            this.casaComunitariaToAddId = null;
            await this.carregarEstruturas();
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors); 
        }

    }

    async removerAcessoCasaComunitaria(id: number) : Promise<any> {

        if(confirm("Deseja remover o acesso a esta estrutura?"))
        {
            try 
            {
                let request = {
                    acessoUsuarioEstruturaId : this.acessoUsuarioEstruturaId,
                    casaComunitariaId : id
                }

                let response = await this.acessoUsuarioEstruturaService.removerAcessoCasaComunitaria(request);
                this.swtAlert2Service.successAlert(response.message);
                this.casaComunitariaToAddId = null;
                this.casasComunitarias = new Array<CasaComunitaria>();
                await this.carregarEstruturas();
            } 
            catch (err) 
            {
                this.swtAlert2Service.errorAlert(err.error.errors); 
            }
        }
        
    }

    // Ministério

    async carregarMinisterios() : Promise<any> {

        if(this.centrosEvangelizacaoAdicionados.length > 0)
        {
            try 
            {
                let response = await this.ministerioService.getAllNotInAcessoUsuarioEstrutura(this.acessoUsuarioEstruturaId);
                this.ministerios = response.entity;
            } 
            catch (err) 
            {
                this.swtAlert2Service.errorAlert(err.error.errors);    
            }
        }        

    }

    async carregarMinisteriosAdicionados() : Promise<any> {

        try 
        {
            let response = await this.ministerioService.getAllFromAcessoUsuarioEstrutura(this.acessoUsuarioEstruturaId);
            this.ministeriosAdicionados = response.entity;
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors);    
        }

    }

    async adicionarAcessoMinisterio(id: number) : Promise<any> {

        try 
        {
            let request = {
                acessoUsuarioEstruturaId : this.acessoUsuarioEstruturaId,
                ministerioId : id
            }

            let response = await this.acessoUsuarioEstruturaService.adicionarAcessoMinisterio(request);
            this.swtAlert2Service.successAlert(response.message);
            this.ministerioToAddId = null;
            await this.carregarEstruturas();
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors); 
        }

    }

    async removerAcessoMinisterio(id: number) : Promise<any> {

        if(confirm("Deseja remover o acesso a esta estrutura?"))
        {
            try 
            {
                let request = {
                    acessoUsuarioEstruturaId : this.acessoUsuarioEstruturaId,
                    ministerioId : id
                }

                let response = await this.acessoUsuarioEstruturaService.removerAcessoMinisterio(request);
                this.swtAlert2Service.successAlert(response.message);
                this.ministerioToAddId = null;
                this.ministerios = new Array<Ministerio>();
                await this.carregarEstruturas();
            } 
            catch (err) 
            {
                this.swtAlert2Service.errorAlert(err.error.errors); 
            }
        }
        
    }

    // Projeto

    async carregarProjetos() : Promise<any> {

        if(this.centrosEvangelizacaoAdicionados.length > 0)
        {
            try 
            {
                let response = await this.projetoService.getAllNotInAcessoUsuarioEstrutura(this.acessoUsuarioEstruturaId);
                this.projetos = response.entity;
            } 
            catch (err) 
            {
                this.swtAlert2Service.errorAlert(err.error.errors);    
            }
        }        

    }

    async carregarProjetosAdicionados() : Promise<any> {

        try 
        {
            let response = await this.projetoService.getAllFromAcessoUsuarioEstrutura(this.acessoUsuarioEstruturaId);
            this.projetosAdicionados = response.entity;
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors);    
        }

    }

    async adicionarAcessoProjeto(id: number) : Promise<any> {

        try 
        {
            let request = {
                acessoUsuarioEstruturaId : this.acessoUsuarioEstruturaId,
                projetoId : id
            }

            let response = await this.acessoUsuarioEstruturaService.adicionarAcessoProjeto(request);
            this.swtAlert2Service.successAlert(response.message);
            this.projetoToAddId = null;
            await this.carregarEstruturas();
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors); 
        }

    }

    async removerAcessoProjeto(id: number) : Promise<any> {

        if(confirm("Deseja remover o acesso a esta estrutura?"))
        {
            try 
            {
                let request = {
                    acessoUsuarioEstruturaId : this.acessoUsuarioEstruturaId,
                    projetoId : id
                }

                let response = await this.acessoUsuarioEstruturaService.removerAcessoProjeto(request);
                this.swtAlert2Service.successAlert(response.message);
                this.projetoToAddId = null;
                this.projetos = new Array<Projeto>();
                await this.carregarEstruturas();
            } 
            catch (err) 
            {
                this.swtAlert2Service.errorAlert(err.error.errors); 
            }
        }
        
    }

    async carregarEstruturas() : Promise<any> {
        this.carregarRegionais();
        await this.carregarRegionaisAdicionados();
        this.carregarMissoes();
        await this.carregarMissoesAdicionados();
        this.carregarIrradiacoes();
        await this.carregarIrradiacoesAdicionados();
        this.carregarDifusoes();
        await this.carregarDifusoesAdicionados();
        this.carregarCentrosEvangelizacao();
        await this.carregarCentrosEvangelizacaoAdicionados();
        this.carregarGruposOracao();
        await this.carregarGruposOracaoAdicionados();
        this.carregarPublicosGrupoOracao();
        await this.carregarPublicosGrupoOracaoAdicionados();
        this.carregarCelulas();
        await this.carregarCelulasAdicionados();
        this.carregarCasasComunitarias();
        await this.carregarCasasComunitariasAdicionados();
        this.carregarMinisterios();
        await this.carregarMinisteriosAdicionados();
        this.carregarProjetos();
        await this.carregarProjetosAdicionados();
    }

    async salvar(acessoUsuarioEstrutura: AcessoUsuarioEstrutura) : Promise<any> {

        try 
        {
            let response = await this.acessoUsuarioEstruturaService.update(acessoUsuarioEstrutura.id,acessoUsuarioEstrutura);
            this.acessoUsuarioEstrutura = response.entity;
            this.swtAlert2Service.successAlert(response.message);
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors); 
        }

    }

    setarElementoSelecionado(valor) {
        this.acessoUsuarioEstrutura.usuarioId = valor;
    }

}