import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SearchCelulaAssistenteFormacaoComponent } from './component/search-celula/search-celula.assistenteformacao.component';
import { ParecerCelulaAssistenteFormacaoComponent } from './component/discernimento/parecer.celula.assistenteformacao.component';

const routes: Routes = [
  {
    path: 'secured/af/celulas',
    component: SearchCelulaAssistenteFormacaoComponent,
  },
  {
    path: 'secured/celula/af/discernimento/:id',
    component: ParecerCelulaAssistenteFormacaoComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SearchCelulaAssistenteFormacaoRoutingModule {}
