import { GenericService } from 'src/app/core/services/generic.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from "@angular/core";
import { Parecer } from 'src/app/model/parecer.model';
import { Observable } from 'rxjs';


@Injectable()
export class ParecerService extends GenericService<Parecer> {
    
    constructor(protected http: HttpClient, protected router:Router) {
        super(http,router);
        this.setEntityType('parecer');
    }

    porPessoaId(id: number) : Promise<any> {
        return this.http.get<any>(`${this.apiURL}/porpessoa/${id}`, { 'headers' : this.getHeaders() }).toPromise();
    }  

    porQuestionarioId(id: number) : Promise<any> {
        return this.http.get<any>(`${this.apiURL}/porquestionario/${id}`, { 'headers' : this.getHeaders() }).toPromise();
    }     
    
    salvarParecer(parecer: Parecer): Observable<Parecer> {
        return this.http.post<Parecer>(this.apiURL, parecer);
    }

    getParecer(parecerId: number): Observable<Parecer> {
        const url = `${this.apiURL}/${parecerId}`;
        return this.http.get<Parecer>(url);
    }
 
}