import { Component, OnInit } from '@angular/core';
import { CentroEvangelizacao } from 'src/app/model/centroevangelizacao.model';
import { Estado } from 'src/app/model/estado.model';
import { Pais } from 'src/app/model/pais.model';
import { PaisService } from '../pais/service/pais.service';
import { ElementoGrupoDominio } from 'src/app/model/elementogrupodominio.model';
import { ElementoGrupoDominioService } from '../grupodominio/service/elementogrupodominio.service';
import { MissaoService } from '../missao/service/missao.service';
import { RegionalService } from '../regional/service/regional.service';
import { Missao } from 'src/app/model/missao.model';
import { Regional } from 'src/app/model/regional.model';
import { EstadoService } from '../estado/service/estado.service';
import { Cidade } from 'src/app/model/cidade.model';
import { CentroEvangelizacaoService } from '../centroevangelizacao/service/centroevangelizacao.service';
import { Celula } from 'src/app/model/celula.model';
import { Projeto } from 'src/app/model/projeto.model';
import { ProjetoService } from './service/projeto.service';
import { ProjetoBuilder } from 'src/app/builder/projeto.builder';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';

@Component({
    selector: 'projeto',
    templateUrl: './projeto.component.html'
})
export class ProjetoComponent implements OnInit {

    public projeto: Projeto;
    public fases : Array<ElementoGrupoDominio>; 
    public missoes : Array<Missao>;
    public regionalSelecionadoId : number;
    public missaoSelecionadoId : number;
    public centroEvangelizacaoId : number;
    public regionais: Array<Regional>;
    public paises: Array<Pais>;
    public estados: Array<Estado>;
    public cidades: Array<Cidade>;
    public centrosEvangelizacao: Array<CentroEvangelizacao>;
    public projetoBuilder: ProjetoBuilder;

    constructor(
        public centroEvangelizacaoService: CentroEvangelizacaoService,
        public elementoGrupoDominioService: ElementoGrupoDominioService,
        public regionalService: RegionalService,
        public missaoService: MissaoService,
        public paisService: PaisService,
        public estadoService: EstadoService,
        public projetoService: ProjetoService,
        public swtAlert2Service: SwtAlert2Service
    ) 
    {
        this.projetoBuilder = new ProjetoBuilder();
        this.projeto = this.projetoBuilder.getInstance();
        this.fases = new Array<ElementoGrupoDominio>();
        this.regionais = new Array<Regional>();
        this.missoes = new Array<Missao>();
        this.paises = new Array<Pais>();
        this.estados = new Array<Estado>();
        this.cidades = new Array<Cidade>();
        this.centrosEvangelizacao = new Array<CentroEvangelizacao>();
    }

    ngOnInit() { 
        this.carregarTiposProjeto();  
        this.carregarRegionais();
        this.regionalSelecionadoId = null; 
        this.missaoSelecionadoId = null;
        this.missaoSelecionadoId = null;
    }

    carregarRegionais() : void {
        this.regionalService.findAll().then( (lista: any) => {
            this.regionais = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    carregarMissoes(regionalSelecionadoId : number): void {
        this.missaoService.porRegional(regionalSelecionadoId).then((response: any) => {
            this.missoes = response.entity;          
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }

    carregarTiposProjeto() : void {

        let buscaFase = {
            grupoNome : 'TIPO_PROJETO'
        }

        this.elementoGrupoDominioService.buscarPorGrupoDominioNome(buscaFase).then( (lista: any) => {
            this.fases = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });

    }
   

    carregarCev(missaoId : number): void {
       this.centroEvangelizacaoService.porMissao(missaoId).then((response: any) => {
            this.centrosEvangelizacao = response.entity;          
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });;
    }

    salvar(projeto: Projeto) : void {

        projeto.dataFundacao = new Date(projeto.dataFundacao);
        this.projetoService.create(projeto).then( (response:any) => {
            this.projeto = new Projeto(null,null,null,null, null,null, null);
            this.swtAlert2Service.successAlert(response.message);

        }).catch( (err:any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

}