import { Injectable } from '@angular/core';
import { AbstractBuilder } from './abstract.builder';
import { CaminhoFormativo } from '../model/caminhoformativo.model';
import { RespostaQuestionario } from '../model/respostaquestionario.model';

@Injectable()
export class RespostaQuestionarioBuilder extends AbstractBuilder<RespostaQuestionario> {

    reset() : void {
        this.entity = new RespostaQuestionario(null,null,null,null);
    }

}