import { ElementoGrupoDominio } from '../model/elementogrupodominio.model';
import { AbstractBuilder } from './abstract.builder';


export class ElementoGrupoDominioBuilder extends AbstractBuilder<ElementoGrupoDominio> {

    reset() : void {
        this.entity = new ElementoGrupoDominio(null,null,null,null,null,false,null,false);
    }

}