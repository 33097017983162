import { Component, OnInit } from '@angular/core';
import { EstadoService } from './service/estado.service';
import { Estado } from 'src/app/model/estado.model';
import { Router, ActivatedRoute } from '@angular/router';
import { PaisService } from '../pais/service/pais.service';
import { Pais } from 'src/app/model/pais.model';
import { EstadoBuilder } from 'src/app/builder/estado.builder';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';

@Component({
    selector: 'estado-edit',
    templateUrl: './estado.component.html'
})
export class EstadoEditComponent implements OnInit {

    public estado: Estado;
    public estadoId: number;
    public estadoBuilder: EstadoBuilder;
    public busca: any;
    public totalDePaginas: number;
    public paises : Array<Pais>;

    constructor(
        public estadoService: EstadoService,
        public paisService: PaisService,
        public swtAlert2Service: SwtAlert2Service,
        public route: ActivatedRoute,
        public router: Router
    ) {
        this.estadoBuilder = new EstadoBuilder();
        this.estado = this.estadoBuilder.getInstance();
        this.paises = new Array<Pais>();
    }

    ngOnInit() {
        this.estadoId = Number(this.route.snapshot.paramMap.get('id'));
        this.carregarPaises();
        this.carregarEstado(this.estadoId);
    }

    carregarPaises() : void {
        this.paisService.findAll().then( (lista: any) => {
            this.paises = lista.entity;
        }).catch( (err: any) => {
            console.log(err);
        });
    }

    carregarEstado(id: number): void {
        this.estadoService.find(id).then((response: any) => {
            this.estado = response.entity;
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }

    salvar(estado: Estado): void {

        this.estadoService.update(estado.id, estado).then((response: any) => {
            this.swtAlert2Service.successAlert(response.message);
            setTimeout(() => {
                this.router.navigate(['../secured/estados']);
            }, 2000);

        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }


}