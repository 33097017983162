import { NgModule } from '@angular/core';
import { MissaoComponent } from './missao.component';
import { Routes, RouterModule } from '@angular/router';
import { MissoesComponent } from './missoes.component';
import { MissaoEditComponent } from './missao.edit.component';

const missaoRoutes: Routes = [
    {
        path: 'secured/missao',
        component: MissaoComponent
    },
    {
        path: 'secured/missoes',
        component: MissoesComponent
    },
    {
        path: 'secured/missao/:id',
        component: MissaoEditComponent
    }
]

@NgModule({
    imports: [
        RouterModule.forChild(missaoRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class MissaoRoutingModule {
    
}