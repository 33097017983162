import { MembroComunidadeAlianca } from '../model/membrocomunidadealianca.model';
import { AbstractBuilder } from './abstract.builder';


export class MembroComunidadeAliancaBuilder extends AbstractBuilder<MembroComunidadeAlianca> {

    reset() : void {
        this.entity = new MembroComunidadeAlianca(null,null,null,null,null,null,null,null,null,null,null,null,null,null);
    }

}