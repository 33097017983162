import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
export class MissionarioMissaoAtualComponent {
    constructor(missaoService, casaComunitariaService, apostoladoService, elementoGrupoDominioService, missionarioMissaoAtualService, salvarMissionarioMissaoAtualBuilder, dadosDetalhementoMissionarioMissaoAtualBuilder, missionarioMissaoApostoladoAtualBuilder, swtAlert2Service) {
        this.missaoService = missaoService;
        this.casaComunitariaService = casaComunitariaService;
        this.apostoladoService = apostoladoService;
        this.elementoGrupoDominioService = elementoGrupoDominioService;
        this.missionarioMissaoAtualService = missionarioMissaoAtualService;
        this.salvarMissionarioMissaoAtualBuilder = salvarMissionarioMissaoAtualBuilder;
        this.dadosDetalhementoMissionarioMissaoAtualBuilder = dadosDetalhementoMissionarioMissaoAtualBuilder;
        this.missionarioMissaoApostoladoAtualBuilder = missionarioMissaoApostoladoAtualBuilder;
        this.swtAlert2Service = swtAlert2Service;
        this.tipoApostoladoSelecionadoId = null;
        this.salvarMissionarioMissaoAtual = this.salvarMissionarioMissaoAtualBuilder.getInstance();
        this.dadosDetalhamentoMissionarioMissaoAtual = this.dadosDetalhementoMissionarioMissaoAtualBuilder.getInstance();
        this.salvarMissionarioMissaoApostoladoAtual = this.missionarioMissaoApostoladoAtualBuilder.getInstance();
        this.listaDetalhamentoMissionarioMissaoApostoladoAtual = new Array();
        this.apostolados = new Array();
    }
    ngOnInit() {
        this.initialization();
    }
    initialization() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.carregarMissoes();
            this.carregarApostolados();
            this.carregarTipoApostolado();
            this.carregarDetalhamentoDoUsuarioLogado();
        });
    }
    carregarMissoes() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.missaoService.getAllAnyStatus();
                this.missoes = response.entity;
                this.missoes = this.missoes.sort((a, b) => a.nome.localeCompare(b.nome, 'fi'));
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarCasasComunitarias(missaoId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.casaComunitariaService.porMissao(missaoId);
                this.casasComunitarias = response.entity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    salvar(salvarMissionarioMissaoAtual) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.salvarMissionarioMissaoAtual.pessoaId = this.pessoaId;
                let response = yield this.missionarioMissaoAtualService.create(salvarMissionarioMissaoAtual);
                this.dadosDetalhamentoMissionarioMissaoAtual = response;
                this.swtAlert2Service.successAlert("Missionário vinculado a missão informada com sucesso!");
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    atualizar(atualizarMissionarioMissaoAtual) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.missionarioMissaoAtualService.update(atualizarMissionarioMissaoAtual.id, atualizarMissionarioMissaoAtual);
                this.swtAlert2Service.successAlert("Atualização realizada com sucesso!");
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    salvarApostolado(salvarMissionarioMissaoApostoladoAtual) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.missionarioMissaoAtualService.salvarApostolado(salvarMissionarioMissaoApostoladoAtual);
                let missionarioMissaoAtualId = this.salvarMissionarioMissaoApostoladoAtual.missionarioMissaoAtualId;
                this.salvarMissionarioMissaoApostoladoAtual = this.missionarioMissaoApostoladoAtualBuilder.getInstance();
                this.configModalApostoladoAtual(missionarioMissaoAtualId);
                this.swtAlert2Service.successAlert("Apostolado salvo com sucesso!");
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    atualizarApostolado(atualizarMissionarioMissaoApostoladoAtual) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.missionarioMissaoAtualService.atualizarApostolado(atualizarMissionarioMissaoApostoladoAtual);
                this.carregarMissionarioMissaoApostolado(this.dadosDetalhamentoMissionarioMissaoAtual.id);
                this.swtAlert2Service.successAlert("Apostolado atualizado com sucesso!");
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    deletarMissionarioMissaoApostoladoAtual(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (confirm("Deseja deletar esse registro de apostolado?")) {
                try {
                    let response = yield this.missionarioMissaoAtualService.deletarApostolado(id);
                    this.carregarMissionarioMissaoApostolado(this.dadosDetalhamentoMissionarioMissaoAtual.id);
                    this.swtAlert2Service.successAlert("Apostolado deletado com sucesso!");
                }
                catch (e) {
                    this.swtAlert2Service.errorAlert(e.error.errors);
                }
            }
        });
    }
    carregarDetalhamentoDoUsuarioLogado() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.missionarioMissaoAtualService.detalhamentoPorUsuarioLogado();
                this.dadosDetalhamentoMissionarioMissaoAtual = response;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    configModalApostoladoAtual(missionarioMissaoAtualId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.salvarMissionarioMissaoApostoladoAtual.missionarioMissaoAtualId = missionarioMissaoAtualId;
            this.carregarMissionarioMissaoApostolado(missionarioMissaoAtualId);
        });
    }
    carregarMissionarioMissaoApostolado(missionarioMissaoAtualId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.missionarioMissaoAtualService.listarApostoladosPorMissionarioMissaoAtualId(missionarioMissaoAtualId);
                if (response != undefined && response != null) {
                    this.listaDetalhamentoMissionarioMissaoApostoladoAtual = response;
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarTipoApostolado() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let request = {
                grupoNome: 'TIPO_APOSTOLADO'
            };
            try {
                let response = yield this.elementoGrupoDominioService.buscarPorGrupoDominioNome(request);
                this.tiposApostolado = response.entity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarApostolados() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let request = {
                ativo: null
            };
            try {
                let response = yield this.apostoladoService.pesquisar(request);
                this.apostolados = response.entity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarApostoladosPorTipo(tipoApostoladoId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.apostoladosSelecionados = this.apostolados.filter(a => a.tipoId == tipoApostoladoId);
        });
    }
}
