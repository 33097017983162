import { NgModule } from '@angular/core';
import { PermissaoLocalComponent } from './permissao.local.component';
import { Routes, RouterModule } from '@angular/router';
import { PermissoesLocalComponent } from './permissoes.local.component';
import { PermissaoLocalEditComponent } from './permissao.local.edit.component';

const permissaoRoutes: Routes = [
    {
        path: 'secured/local/permissao',
        component: PermissaoLocalComponent
    },
    {
        path: 'secured/local/permissoes',
        component: PermissoesLocalComponent
    },
    {
        path: 'secured/local/permissao/:id',
        component: PermissaoLocalEditComponent
    }
]

@NgModule({
    imports: [
        RouterModule.forChild(permissaoRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class PermissaoRoutingModule {
    
}