import { Component, OnInit } from '@angular/core';
import { IrradiacaoService } from './service/irradiacao.service';
import { Irradiacao } from 'src/app/model/irradiacao.model';
import { Router, ActivatedRoute } from '@angular/router';
import { Regional } from 'src/app/model/regional.model';
import { Pais } from 'src/app/model/pais.model';
import { Estado } from 'src/app/model/estado.model';
import { Cidade } from 'src/app/model/cidade.model';
import { ElementoGrupoDominio } from 'src/app/model/elementogrupodominio.model';
import { RegionalService } from '../regional/service/regional.service';
import { ElementoGrupoDominioService } from '../grupodominio/service/elementogrupodominio.service';
import { PaisService } from '../pais/service/pais.service';
import { EstadoService } from '../estado/service/estado.service';
import { CidadeService } from '../cidade/service/cidade.service';
import { IrradiacaoBuilder } from 'src/app/builder/irradiacao.builder';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { Missao } from 'src/app/model/missao.model';
import { MissaoService } from '../missao/service/missao.service';

@Component({
    selector: 'irradiacao-edit',
    templateUrl: './irradiacao.edit.component.html'
})
export class IrradiacaoEditComponent implements OnInit {

    public irradiacaoId: number;
    public irradiacao: Irradiacao;
    public regionais: Array<Regional>;
    public missoes: Array<Missao>;
    public paises: Array<Pais>;
    public estados: Array<Estado>;
    public cidades: Array<Cidade>;
    public paisSelecionadoId: number;
    public estadoSelecionadoId: number;
    public fasesIrradiacao: Array<ElementoGrupoDominio>;
    public irradiacaoBuilder: IrradiacaoBuilder;
    public regionalSelecionadoId : number;

    constructor(
        public irradiacaoService: IrradiacaoService,
        public regionalService: RegionalService,
        public missaoService: MissaoService,
        public elementoGrupoDominioService: ElementoGrupoDominioService,
        public paisService: PaisService,
        public estadoService: EstadoService,
        public cidadeService: CidadeService,
        public swtAlert2Service: SwtAlert2Service,
        public route: ActivatedRoute,
        public router: Router
    ) 
    {
        this.irradiacaoBuilder = new IrradiacaoBuilder();
        this.irradiacao = this.irradiacaoBuilder.getInstance();
        this.regionais = new Array<Regional>();
        this.missoes = new Array<Missao>();
        this.paisSelecionadoId = null;
        this.estadoSelecionadoId = null;
        this.paises = new Array<Pais>();
        this.estados = new Array<Estado>();
        this.cidades = new Array<Cidade>();
        this.fasesIrradiacao = new Array<ElementoGrupoDominio>();        
    }

    ngOnInit() {  
        this.irradiacaoId = Number(this.route.snapshot.paramMap.get('id'));
        this.initialize();
    }

    async initialize() : Promise<null> {

        await this.carregarRegionais();
        await this.carregarIrradiacao(this.irradiacaoId);
        this.carregarFasesIrradiacao();
        this.carregarPaises();
        this.carregarEstados();
        this.carregarCidades();

        return null;
    }

    async carregarIrradiacao(id: number): Promise<Irradiacao> {

        try
        {
                let response = await this.irradiacaoService.find(id);
                this.irradiacao = response.entity;
                this.regionalSelecionadoId = this.irradiacao.missaoRegionalId;
                this.carregarMissoes();
                this.paisSelecionadoId = this.irradiacao.cidadeEstadoPaisId;
                this.estadoSelecionadoId = this.irradiacao.cidadeEstadoId;
                this.carregarEstados();
        }
        catch(err)
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }

        return null;
    }

    async carregarRegionais() : Promise<null> {

        try
        {
            let response = await this.regionalService.findAll();
            this.regionais = response.entity;
        }
        catch(err)
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }

        return null;
    }

    async carregarMissoes() : Promise<null> {

        try
        {
            let response = await this.missaoService.porRegional(this.regionalSelecionadoId);
            this.missoes = response.entity;
        }
        catch(err)
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }

        return null;
    }

    carregarPaises() : void {

        this.paisService.findAll().then((lista: any) => {
            this.paises = lista.entity;
            this.carregarIrradiacao(this.irradiacaoId);
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
       
    }

    carregarEstados() : void {

            let busca = {
                paisId: this.paisSelecionadoId,
                nome: undefined,
                pageNumber : 0, 
                quantityOfElements : 1000
            };
            this.estadoService.buscarEstados(busca).then((lista: any) => {
                this.estados = lista.entity;
                this.carregarCidades();
            }).catch((err: any) => {
                this.swtAlert2Service.errorAlert(err.error.errors);
                console.log(err);
            });       
    }

    carregarCidades() : void {

        let busca = {
            estadoId: this.estadoSelecionadoId,
            nome: undefined,
            pageNumber : 0, 
            quantityOfElements : 1000
        };

        this.cidadeService.buscarCidades(busca).then( (lista: any) => {
            this.cidades = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });

    }

    carregarFasesIrradiacao() : void {

        let buscaFase = {
            grupoNome : 'FASE_IRRADIACAO'
        }

        this.elementoGrupoDominioService.buscarPorGrupoDominioNome(buscaFase).then( (lista: any) => {
            this.fasesIrradiacao = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    salvar(irradiacao: Irradiacao) : void {

        irradiacao.dataFundacao = new Date(irradiacao.dataFundacao);

        this.irradiacaoService.update(irradiacao.id,irradiacao).then( (response:any) => {
            this.irradiacao = response.entity;
            this.swtAlert2Service.successAlert(response.message);
            setTimeout(() => {
                this.router.navigate(['../secured/irradiacoes']);
            }, 2000);
        }).catch( (err:any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }


}