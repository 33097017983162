import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AcessoUsuarioEstruturaBuilder } from 'src/app/builder/acessousuarioestrutura.builder';
export class AcessoUsuarioEstruturaComponent {
    constructor(acessoUsuarioEstruturaBuilder, acessoUsuarioEstruturaService, autorizacaoService, swtAlert2Service) {
        this.acessoUsuarioEstruturaBuilder = acessoUsuarioEstruturaBuilder;
        this.acessoUsuarioEstruturaService = acessoUsuarioEstruturaService;
        this.autorizacaoService = autorizacaoService;
        this.swtAlert2Service = swtAlert2Service;
        this.acessoUsuarioEstruturaBuilder = new AcessoUsuarioEstruturaBuilder();
        this.acessoUsuarioEstrutura = this.acessoUsuarioEstruturaBuilder.getInstance();
        this.pessoaSearchUrl = environment.pessoa.usuarioIdPorPessoaNomeURL;
    }
    ngOnInit() {
        this.init();
    }
    init() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.carregarAutorizacao();
        });
    }
    carregarAutorizacao() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.autorizacaoService.findAll();
                this.autorizacoes = response.entity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    salvar(acessoUsuarioEstrutura) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.acessoUsuarioEstruturaService.create(acessoUsuarioEstrutura);
                this.acessoUsuarioEstrutura = this.acessoUsuarioEstruturaBuilder.getInstance();
                this.swtAlert2Service.successAlert(response.message);
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    setarElementoSelecionado(valor) {
        this.acessoUsuarioEstrutura.usuarioId = valor;
    }
}
