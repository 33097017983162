<div class="row">
  <div class="col-12">
    <div class="card card-gray-dark">
      <div class="card-header">
        <h3 class="card-title">Missão - <strong>{{missao?.nome}}</strong></h3>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-sm-12 col-md-3 col-lg-3">
            <div class="form-group">
              <label>Regional</label>
              <span class="form-control">{{missao?.regionalNome}}</span>
            </div>
          </div>
          <div class="col-sm-12 col-md-3 col-lg-3">
            <div class="form-group">
              <label>Cidade</label>
              <span class="form-control">{{missao?.cidadeNome}}</span>
            </div>
          </div>
          <div class="col-sm-12 col-md-3 col-lg-3">
            <div class="form-group">
              <label>Fase</label>
              <span class="form-control">{{missao?.faseMissaoNome}}</span>
            </div>
          </div>
          <div class="col-sm-12 col-md-3 col-lg-3">
            <div class="form-group">
              <label>Data de Fundação</label>
              <span class="form-control">{{missao?.dataFundacao | date: 'dd/MM/yyyy'}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>