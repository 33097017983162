import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { UsuarioBuilder } from 'src/app/builder/usuario.builder';
import { AppRouteChangeService } from 'src/app/core/services/app-route-change.service';
import { LoginService } from 'src/app/login/service/login.service';
import { Usuario } from 'src/app/model/usuario.model';
import { UsuarioService } from '../wop/usuario/service/usuario.service';
import { RouterManagerService } from './routermanager.service';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { Router } from '@angular/router';
import { AceiteTermoService } from '../wop/aceitetermouso/service/aceitetermouso.service';
import { AceiteTermoTratamentoDadosService } from '../wop/aceitetermotratamentodados/service/aceitetermotratamentodados.service';
import { TermoTratamentoDados } from 'src/app/model/termotratamentodados.model';
import { TermoUso } from 'src/app/model/termouso.model';
import { CadastroPublicoLocalService } from '../local/cadastropublico/service/cadastro.publico.local.service';

@Component({
  selector: 'secured',
  templateUrl: './secured.component.html',
})
export class SecuredComponent implements OnInit, AfterViewInit {
  @ViewChild('acessoNegado', { static: true })
  menuAcessoNegadoRef: ElementRef;
  @ViewChild('missionario', { static: true })
  menuMissionarioRef: ElementRef;
  @ViewChild('formadorComunitario', { static: true })
  menuFormadorComunitarioRef: ElementRef;
  @ViewChild('assistenteMissionario', { static: true })
  menuAssistenteMissionarioRef: ElementRef;
  @ViewChild('acompanhadorMissionario', { static: true })
  menuAcompanhadorMissionarioRef: ElementRef;
  @ViewChild('comissaoRemanejamento', { static: true })
  menuComissaoRemanejamentoRef: ElementRef;
  @ViewChild('responsavelLocal', { static: true })
  menuResponsavelLocalRef: ElementRef;
  @ViewChild('assistenteFormacao', { static: true })
  menuAssistenteFormacaoRef: ElementRef;
  @ViewChild('admin', { static: true })
  menuAdminRef: ElementRef;

  private allSidebarsElementsRef = new Array<ElementRef>();

  public usuarioAtual: Usuario;
  private usuarioBuilder: UsuarioBuilder;
  public usuarioAceitouTermoUso: boolean;
  public usuarioAceitouTermoTratamentoDados: boolean;
  public hasTermoUsoVigente: boolean;
  public hasTermoTratamentoDadosVigente: boolean;
  public termoUsoVigente: TermoUso;
  public termoTratamentoDadosVigente: TermoTratamentoDados;

  constructor(
    public loginService: LoginService,
    public usuarioService: UsuarioService,
    public routerManagerService: RouterManagerService,
    public appRouteChangeService: AppRouteChangeService, //necessário para rodar as mudanças de rota na aplicação
    public aceiteTermoService: AceiteTermoService,
    public aceiteTermoTratamentoDadosService: AceiteTermoTratamentoDadosService,
    public cadastroPublicoService: CadastroPublicoLocalService,
    public swtAlert2Service: SwtAlert2Service,
    private router: Router,
    private renderer: Renderer2
  ) {
    this.usuarioBuilder = new UsuarioBuilder();
    this.usuarioAtual = this.usuarioBuilder.getInstance();
  }

  ngOnInit() {
    this.initialization();
  }

  async initialization(): Promise<any> {
    this.loadTermsInfo();

    if (this.hasTermoUsoVigente == undefined) {
      await this.carregarTermoUsoVigente();
    }

    if (this.hasTermoTratamentoDadosVigente == undefined) {
      await this.carregarTermoTratamentoDadosVigente();
    }

    if (this.hasTermoUsoVigente && this.usuarioAceitouTermoUso != true) {
      await this.didUserAcceptUserTerm();
    }

    if (
      this.hasTermoTratamentoDadosVigente &&
      this.usuarioAceitouTermoTratamentoDados != true
    ) {
      await this.didUserAcceptDataProcessingTerm();
    }
  }

  ngAfterViewInit(): void {
    this.allSidebarsElementsRef.push(
      this.menuAcessoNegadoRef,
      this.menuMissionarioRef,
      this.menuFormadorComunitarioRef,
      this.menuAssistenteMissionarioRef,
      this.menuAcompanhadorMissionarioRef,
      this.menuComissaoRemanejamentoRef,
      this.menuResponsavelLocalRef,
      this.menuAssistenteFormacaoRef,
      this.menuAdminRef
    );
    this.initializationAfterRender();
  }

  async initializationAfterRender(): Promise<any> {
    await this.getUsuarioAtual();
    await this.verificarUsuario();
    await this.liberarMenuPorAutorizacao();
  }

  async liberarMenuPorAutorizacao(): Promise<any> {
    let autorizacaoUsuarioLogado =
      this.usuarioAtual.autorizacoesNome.length > 0
        ? this.usuarioAtual.autorizacoesNome[0]
        : undefined;

    this.allSidebarsElementsRef.forEach((el) =>
      this.renderer.removeChild(el.nativeElement.parentNode, el.nativeElement)
    );

    switch (autorizacaoUsuarioLogado) {
      case 'ROLE_MISSIONARIO': {
        this.renderer.appendChild(
          this.menuMissionarioRef.nativeElement.parentNode,
          this.menuMissionarioRef.nativeElement
        );
        break;
      }
      case 'ROLE_ASSISTENTE_MISSIONARIO': {
        this.renderer.appendChild(
          this.menuAssistenteMissionarioRef.nativeElement.parentNode,
          this.menuAssistenteMissionarioRef.nativeElement
        );
        break;
      }
      case 'ROLE_ACOMPANHADOR_MISSAO': {
        this.renderer.appendChild(
          this.menuAcompanhadorMissionarioRef.nativeElement.parentNode,
          this.menuAcompanhadorMissionarioRef.nativeElement
        );
        break;
      }
      case 'ROLE_COMISSAO_REMANEJAMENTO': {
        this.renderer.appendChild(
          this.menuComissaoRemanejamentoRef.nativeElement.parentNode,
          this.menuComissaoRemanejamentoRef.nativeElement
        );
        break;
      }
      case 'ROLE_FORMADOR_COMUNITARIO': {
        this.renderer.appendChild(
          this.menuFormadorComunitarioRef.nativeElement.parentNode,
          this.menuFormadorComunitarioRef.nativeElement
        );
        break;
      }
      case 'ROLE_RESPONSAVEL_LOCAL': {
        this.renderer.appendChild(
          this.menuResponsavelLocalRef.nativeElement.parentNode,
          this.menuResponsavelLocalRef.nativeElement
        );
        break;
      }
      case 'ROLE_ASSISTENTE_FORMACAO': {
        this.renderer.appendChild(
          this.menuAssistenteFormacaoRef.nativeElement.parentNode,
          this.menuAssistenteFormacaoRef.nativeElement
        );
        break;
      }
      case 'ROLE_ADMIN': {
        this.renderer.appendChild(
          this.menuAdminRef.nativeElement.parentNode,
          this.menuAdminRef.nativeElement
        );
        break;
      }
      default: {
        this.renderer.appendChild(
          this.menuAcessoNegadoRef.nativeElement.parentNode,
          this.menuAcessoNegadoRef.nativeElement
        );
      }
    }
  }

  async carregarTermoUsoVigente(): Promise<any> {
    try {
      let response = await this.cadastroPublicoService.getTermoVigente();
      this.termoUsoVigente = response.entity;
      this.hasTermoUsoVigente = this.termoUsoVigente ? true : false;
      sessionStorage.setItem(
        'hasTermoUsoVigente',
        this.hasTermoUsoVigente ? '1' : '0'
      );
    } catch (e) {
      this.swtAlert2Service.errorAlert(e.error.errors);
    }
  }

  async carregarTermoTratamentoDadosVigente(): Promise<any> {
    try {
      let response =
        await this.cadastroPublicoService.getTermoTratamentoDadosVigente();
      this.termoTratamentoDadosVigente = response.entity;
      this.hasTermoTratamentoDadosVigente = this.termoTratamentoDadosVigente
        ? true
        : false;
      sessionStorage.setItem(
        'hasTermoTratamentoDadosVigente',
        this.hasTermoTratamentoDadosVigente ? '1' : '0'
      );
    } catch (e) {
      this.swtAlert2Service.errorAlert(e.error.errors);
    }
  }

  async getUsuarioAtual(): Promise<any> {
    try {
      let response2 = await this.usuarioService.getCurrentUser();
      this.usuarioAtual = response2.entity;
    } catch (e) {
      this.swtAlert2Service.errorAlert(
        'Não foi possível realizar o login. O sistema pode estar em manutenção, caso o problema persista tente realizar o login novamente mais tarde!'
      );
      this.router.navigate(['/login']);
    }
  }

  async didUserAcceptUserTerm(): Promise<any> {
    try {
      let response = await this.aceiteTermoService.usuarioAceitouTermo();
      this.usuarioAceitouTermoUso = response.entity;

      sessionStorage.setItem(
        'usuarioAceitouTermoUso',
        this.usuarioAceitouTermoUso ? '1' : '0'
      );

      if (!this.usuarioAceitouTermoUso) {
        this.router.navigate(['/aceitetermouso']);
      }
    } catch (e) {
      this.swtAlert2Service.errorAlert(e.error.errors);
      this.router.navigate(['/login']);
    }
  }

  async didUserAcceptDataProcessingTerm(): Promise<any> {
    try {
      let response =
        await this.aceiteTermoTratamentoDadosService.usuarioAceitouTermo();
      this.usuarioAceitouTermoTratamentoDados = response.entity;
      sessionStorage.setItem(
        'usuarioAceitouTermoTratamentoDados',
        this.usuarioAceitouTermoTratamentoDados ? '1' : '0'
      );
      if (!this.usuarioAceitouTermoTratamentoDados) {
        this.router.navigate(['/aceitetermotratamentodados']);
      }
    } catch (e) {
      this.swtAlert2Service.errorAlert(e.error.errors);
      this.router.navigate(['/login']);
    }
  }

  async verificarUsuario(): Promise<any> {
    await this.routerManagerService.verificarUsuario(this.usuarioAtual);
  }

  loadTermsInfo(): void {
    if (sessionStorage.getItem('usuarioAceitouTermoUso') != null)
      this.usuarioAceitouTermoUso =
        sessionStorage.getItem('usuarioAceitouTermoUso').localeCompare('1') == 0
          ? true
          : false;
    if (sessionStorage.getItem('usuarioAceitouTermoTratamentoDados') != null)
      this.usuarioAceitouTermoTratamentoDados =
        sessionStorage
          .getItem('usuarioAceitouTermoTratamentoDados')
          .localeCompare('1') == 0
          ? true
          : false;
    if (sessionStorage.getItem('hasTermoUsoVigente') != null)
      this.hasTermoUsoVigente =
        sessionStorage.getItem('hasTermoUsoVigente').localeCompare('1') == 0
          ? true
          : false;
    if (sessionStorage.getItem('hasTermoTratamentoDadosVigente') != null)
      this.hasTermoTratamentoDadosVigente =
        sessionStorage
          .getItem('hasTermoTratamentoDadosVigente')
          .localeCompare('1') == 0
          ? true
          : false;
  }
}
