<secured>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h5 i18n><i class="fas fa-fw fa-heartbeat "></i>Saúde Física e Emocional</h5>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card card-secondary">
                            <div class="card-header">
                                <h3 class="card-title">Saúde Física e Emocional</h3>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <div class="checkbox icheck-concrete">
                                                <input type="checkbox" id="temEnfermidadeFisica" name="temEnfermidadeFisica"
                                                    [(ngModel)]="saude.temEnfermidadeFisica" />&nbsp;
                                                <label class="form-check-label" for="temEnfermidadeFisica">Tem diagnosticada alguma enfermidade física?</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" *ngIf="saude.temEnfermidadeFisica">
                                    <div class="col-12">
                                        <div class="card card-secondary">
                                            <div class="card-header">
                                                <h3 class="card-title">Qual(is)?</h3>
                                            </div>
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="form-group">
                                                            <label for="enfermidade_fisica">Escreva-as abaixo, se
                                                                tiver mais de uma as separe com ponto e vírgula ( ; )</label>
                                                            <input type="text" class="form-control"
                                                                name="enfermidade_fisica"
                                                                [(ngModel)]="saude.enfermidadesFisicas">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <div class="checkbox icheck-concrete">
                                                <input type="checkbox" id="temEnfermidadePsicologica" name="temEnfermidadePsicologica"
                                                    [(ngModel)]="saude.temEnfermidadePsicologica" />&nbsp;
                                                <label class="form-check-label" for="temEnfermidadePsicologica">Tem diagnosticada alguma enfermidade psicológica?</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" *ngIf="saude.temEnfermidadePsicologica">
                                    <div class="col-12">
                                        <div class="card card-secondary">
                                            <div class="card-header">
                                                <h3 class="card-title">Qual(is)?</h3>
                                            </div>
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="form-group">
                                                            <label for="enfermidade_psicologica">Escreva-as abaixo, se
                                                                tiver mais de uma as separe com ponto e vírgula ( ; )</label>
                                                            <input type="text" class="form-control"
                                                                name="enfermidade_psicologica"
                                                                [(ngModel)]="saude.enfermidadesPsicologicas">                                                                
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <div class="checkbox icheck-concrete">
                                                <input type="checkbox" id="tomaMedicacao" name="tomaMedicacao"
                                                    [(ngModel)]="saude.tomaMedicacao" />&nbsp;
                                                <label class="form-check-label" for="tomaMedicacao">Faz uso de medicação controlada?</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md-3 col-lg-3">
                                        <div class="form-group">
                                            <div class="checkbox icheck-concrete">
                                                <input type="checkbox" id="fazTerapia" name="fazTerapia"
                                                    [(ngModel)]="saude.fazTerapia" />&nbsp;
                                                <label class="form-check-label" for="fazTerapia">Faz terapia?</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" *ngIf="saude.fazTerapia">
                                    <div class="col-12">
                                        <div class="card card-secondary">
                                            <div class="card-header">
                                                <h3 class="card-title">Há quanto tempo?</h3>
                                            </div>
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-sm-12 col-md-3 col-lg-6">
                                                        <div class="form-group">
                                                            <label for="duracao_anos">Duração Anos</label>
                                                            <input type="number" class="form-control" name="duracao_anos"
                                                                [(ngModel)]="saude.terapiaDuracaoAnos">
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-12 col-md-3 col-lg-6">
                                                        <div class="form-group">
                                                            <label for="duracao_meses">Meses</label>
                                                            <input type="number" class="form-control" name="duracao_meses"
                                                                [(ngModel)]="saude.terapiaDuracaoMeses">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer text-right">
                                <button type="button" class="btn btn-primary" (click)="atualizarSaude()">Salvar</button>
                            </div> 
                        </div> 
                    </div>
                </div>
            </div>
        </section>
    </div>
</secured>