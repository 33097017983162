import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { GenericService } from 'src/app/core/services/generic.service';
import { ComissaoDiscernimento } from 'src/app/model/comissaodiscernimento.model';

@Injectable()
export class ComissaoDiscernimentoService extends GenericService<any> {
  constructor(protected http: HttpClient, protected router: Router) {
    super(http, router);
    this.setEntityType('comissaodiscernimento');
  }

  cadastrar(dados: ComissaoDiscernimento): Observable<ComissaoDiscernimento> {
    return this.http.post<ComissaoDiscernimento>(
      `${this.apiURL}`,
      JSON.stringify(dados),
      {
        headers: this.getHeaders(),
      }
    );
  }

  obterPorMissaoConfiguracaoId(
    missaoConfiguracaoId: number
  ): Observable<Array<ComissaoDiscernimento>> {
    return this.http.get<Array<ComissaoDiscernimento>>(
      `${this.apiURL}/pormissaoconfiguracaoid/${missaoConfiguracaoId}`,
      {
        headers: this.getHeaders(),
      }
    );
  }

  remover(conselhoId: number): Observable<void> {
    return this.http.delete<void>(`${this.apiURL}/${conselhoId}`, {
      headers: this.getHeaders(),
    });
  }
}
