import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { SecuredModule } from '../../layout/secured.module';
import { IgxTabsModule } from 'igniteui-angular';
import { ImageCropperModule } from 'ngx-image-cropper';
import { RemanejamentoComponent } from './remanejamento.component';
import { RemanejamentoRoutingModule } from './remanejamento-routing.module';
//import { RemanejamentoBuilder } from 'src/app/builder/missionarios.builder';
//import { MissionarioService } from './service/missionario.service';

@NgModule({
    declarations:[
        RemanejamentoComponent
    ],
    imports:[
        CommonModule,
        RouterModule,
        RemanejamentoRoutingModule,
        FormsModule,
        SecuredModule,
        IgxTabsModule,
        ImageCropperModule
    ],
     providers:[
         /*MissionarioService,
         RemanejamentoBuilder*/
     ]
 })
  export class RemanejamentoModule {
     
 }