import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoggedInUserSharedDataService } from '../../layout/header/service/logged-in-user-shared-data.service';
import { Pessoa } from 'src/app/model/pessoa.model';
import { Subject } from 'rxjs';
import { MissaoConfiguracaoSharedDataService } from './service/missao-configuracao-shared-data.service';
import { MissaoConfiguracao } from './service/missao-config.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-missao-config',
  templateUrl: './missao-config.component.html',
  styleUrls: ['./missao-config.component.css'],
})
export class MissaoConfigComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject<null>();
  responsavelLocalMissaoId: number;
  missaoConfiguracao: MissaoConfiguracao;

  constructor(
    private loggedInUserSharedDataService: LoggedInUserSharedDataService,
    private missaoConfiguracaoSharedDataService: MissaoConfiguracaoSharedDataService
  ) {}

  ngOnInit() {
    this.loggedInUserSharedDataService.loggedInPerson$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((person: Pessoa) => {
        if (person) {
          this.responsavelLocalMissaoId = person.vinculoMissao.missaoAtualId;
        }
      });
    this.missaoConfiguracaoSharedDataService.missaoConfiguracao$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (missaoConfig) => (this.missaoConfiguracao = missaoConfig),
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
