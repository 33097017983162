import { Component, OnInit } from '@angular/core';
import { GrupoDominioService } from './service/grupodominio.service';
import { GrupoDominio } from 'src/app/model/grupodominio.model';
import { Router, ActivatedRoute } from '@angular/router';
import { ElementoGrupoDominio } from 'src/app/model/elementogrupodominio.model';
import { ElementoGrupoDominioService } from './service/elementogrupodominio.service';
import { GrupoDominioBuilder } from 'src/app/builder/grupodominio.builder';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { ElementoGrupoDominioBuilder } from 'src/app/builder/elementogrupodominio.builder';

@Component({
    selector: 'grupodominio',
    templateUrl: './grupodominio.edit.component.html'
})
export class GrupoDominioEditComponent implements OnInit {

    public grupoDominio: GrupoDominio;
    public grupoDominioBuilder: GrupoDominioBuilder;
    public grupoDominioId : number;
    public elementoGrupoDominioBuilder: ElementoGrupoDominioBuilder;
    public elementoGrupoDominio: ElementoGrupoDominio;
    public elementosGrupoDominio: Array<ElementoGrupoDominio>;
    public busca : any;
    public totalDePaginas: number;

    constructor(
        public grupoDominioService: GrupoDominioService,
        public elementoGrupoDominioService: ElementoGrupoDominioService,
        public swtAlert2Service: SwtAlert2Service,
        public route: ActivatedRoute,
        public router: Router
    ) 
    {
        this.grupoDominioBuilder = new GrupoDominioBuilder();
        this.elementoGrupoDominioBuilder = new ElementoGrupoDominioBuilder();
        this.grupoDominio = this.grupoDominioBuilder.getInstance();
        this.grupoDominioId = Number(this.route.snapshot.paramMap.get('id'));
        this.elementoGrupoDominio = this.elementoGrupoDominioBuilder.getInstance();
        this.elementoGrupoDominio.grupoId = this.grupoDominioId;
        this.elementosGrupoDominio = new Array<ElementoGrupoDominio>();
        this.busca = {
            grupoId: this.grupoDominioId,
            nome: undefined,
            pageNumber : 0, 
            quantityOfElements : 10
        };
    }

    ngOnInit() {          
        this.carregarGrupoDominio(this.grupoDominioId);   
        this.buscarElementosGruposDominio();   
    }

    carregarGrupoDominio(id: number): void {
        this.grupoDominioService.find(id).then((response: any) => {
            this.grupoDominio = response.entity;
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }

    buscarElementosGruposDominio() : void {

        this.elementoGrupoDominioService.buscarElementosGrupoDominio(this.busca).then( (response: any) => {
            this.elementosGrupoDominio = response.entity;
            this.totalDePaginas = response.quantity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }

    salvar(grupoDominio: GrupoDominio) : void {

        this.grupoDominioService.update(grupoDominio.id,grupoDominio).then( (response:any) => {
            this.grupoDominio = new GrupoDominio(null,null,[]);
            this.swtAlert2Service.successAlert(response.message);
            setTimeout(() => {
                this.router.navigate(['../secured/gruposdominio']);
            }, 2000);
        }).catch( (err:any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });

    }

    salvarElemento(elementoGrupoDominio: ElementoGrupoDominio) : void {

        this.elementoGrupoDominioService.create(elementoGrupoDominio).then( (response:any) => {
            this.elementoGrupoDominio = new ElementoGrupoDominio(null,null,null,null,null,false,this.grupoDominio.id,false);
            this.swtAlert2Service.successAlert(response.message);
            this.buscarElementosGruposDominio();
        }).catch( (err:any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });

    }

    atualizarElemento(elementoGrupoDominio: ElementoGrupoDominio) : void {

        this.elementoGrupoDominioService.update(elementoGrupoDominio.id, elementoGrupoDominio).then( (response:any) => {
            this.swtAlert2Service.successAlert(response.message);
            this.buscarElementosGruposDominio();
        }).catch( (err:any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
        
    }

    deletarElementoGrupoDominio(id: number) : void {

        if(confirm('Deseja deletar este grupo domínio?'))
        {
            this.elementoGrupoDominioService.delete(id)
            .then( (response: any) => {
                this.swtAlert2Service.successAlert(response.message);
                this.buscarElementosGruposDominio();
            })
            .catch((err) => {
                this.swtAlert2Service.errorAlert(err.error.errors);
            });
        }
    }

    cliqueNoBotaoListener(botao) {
        this.busca.pageNumber = botao.numero-1;
        this.buscarElementosGruposDominio();
    }

}