import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { EnvioLocalService } from './service/envio.local.service';
import { CommonModule } from '@angular/common';
import { SecuredModule } from '../../layout/secured.module';
import { RouterModule } from '@angular/router';
import { EnvioRoutingModule } from './envio-local-routing.module';
import { EnvioLocalComponent } from './envio.local.component';
import { EnviosLocalComponent } from './envios.local.component';
import { EnvioLocalEditComponent } from './envio.local.edit.component';
import { EnvioBuilder } from 'src/app/builder/envio.builder';
import { ListagemPessoaInfoModalModule } from '../../wop/pessoa/modulo/listagem-pessoainfo-modal/listagempessoainfo.modal.module';

@NgModule({
    declarations: [ 
        EnvioLocalComponent,
        EnviosLocalComponent,
        EnvioLocalEditComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        EnvioRoutingModule,
        FormsModule,
        SecuredModule,
        ListagemPessoaInfoModalModule
    ],
    providers: [
        EnvioLocalService,
        EnvioBuilder
    ]
})
export class EnvioLocalModule {
}