export class HistoricoMissionarioMissaoApostolado {

    constructor(
        public id: number,
        public historicoMissionarioMissaoId: number,
        public apostoladoId: number,
        public apostoladoNome: string,
        public duracaoAnos: number,
        public duracaoMeses: number,
        public apostoladoAtual: boolean,
        public tipoApostoladoId: number,
        public isEditing: boolean
    ){}

}