import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MissaoBuilder } from 'src/app/builder/missao.builder';
export class MissaoComponent {
    constructor(missaoService, regionalService, elementoGrupoDominioService, paisService, estadoService, cidadeService, swtAlert2Service) {
        this.missaoService = missaoService;
        this.regionalService = regionalService;
        this.elementoGrupoDominioService = elementoGrupoDominioService;
        this.paisService = paisService;
        this.estadoService = estadoService;
        this.cidadeService = cidadeService;
        this.swtAlert2Service = swtAlert2Service;
        this.missaoBuilder = new MissaoBuilder();
        this.missao = this.missaoBuilder.getInstance();
        this.regionais = new Array();
        this.paisSelecionadoId = null;
        this.estadoSelecionadoId = null;
        this.paises = new Array();
        this.estados = new Array();
        this.cidades = new Array();
        this.fasesMissao = new Array();
        this.statusMissao = new Array();
    }
    ngOnInit() {
        this.carregarRegionais();
        this.carregarFasesMissao();
        this.carregarStatusMissao();
        this.carregarPaises();
    }
    carregarRegionais() {
        this.regionalService.findAll().then((lista) => {
            this.regionais = lista.entity;
        }).catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }
    carregarFasesMissao() {
        let buscaFase = {
            grupoNome: 'FASE_MISSAO'
        };
        this.elementoGrupoDominioService.buscarPorGrupoDominioNome(buscaFase).then((lista) => {
            this.fasesMissao = lista.entity;
        }).catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }
    carregarStatusMissao() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let request = {
                    grupoNome: 'MISSAO_STATUS'
                };
                let response = yield this.elementoGrupoDominioService.buscarPorGrupoDominioNome(request);
                this.statusMissao = response.entity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
            return null;
        });
    }
    carregarPaises() {
        this.paisService.findAll().then((lista) => {
            this.paises = lista.entity;
        }).catch((err) => {
            console.log(err);
        });
    }
    carregarEstados() {
        let busca = {
            paisId: this.paisSelecionadoId,
            nome: undefined,
            pageNumber: 0,
            quantityOfElements: 1000
        };
        this.estadoService.buscarEstados(busca).then((lista) => {
            this.estados = lista.entity;
        }).catch((err) => {
            console.log(err);
        });
    }
    carregarCidades() {
        let busca = {
            estadoId: this.estadoSelecionadoId,
            nome: undefined,
            pageNumber: 0,
            quantityOfElements: 1000
        };
        this.cidadeService.buscarCidades(busca).then((lista) => {
            this.cidades = lista.entity;
        }).catch((err) => {
            console.log(err);
        });
    }
    salvar(missao) {
        missao.dataFundacao = new Date(missao.dataFundacao);
        this.missaoService.create(missao).then((response) => {
            this.missao = this.missaoBuilder.getInstance();
            this.paisSelecionadoId = null;
            this.estadoSelecionadoId = null;
            this.swtAlert2Service.successAlert(response.message);
        }).catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }
}
