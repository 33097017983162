<secured>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h3 i18n>Centro De Evangelização</h3>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">

            <form (ngSubmit)="salvar(centroEvangelizacao)" #centroEvangelizacaoForm="ngForm" novalidate>
                <div class="card card-default color-pallete-box">
                    <div class="card-header">
                        <h3 class="card-title">
                            <i class="fas fa-globe-americas"></i>
                            <a i18n> Dados do Centro De Evangelização </a>
                        </h3>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-12 col-md-4 col-lg-4">
                                <div class="form-group">
                                    <label i18n for="ncev_nome">Nome</label>
                                    <input [(ngModel)]="centroEvangelizacao.nome" name="ncev_nome" type="text" class="form-control" id="ncev_nome" placeholder="Digite o nome" i18n-placeholder>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-4 col-lg-4">
                                <div class="form-group">
                                    <label i18n for="ncev_dtfund">Data de Fundação</label>
                                    <input [(ngModel)]="centroEvangelizacao.dataFundacao" name="ncev_dtfund" type="date" class="form-control" id="ncev_dtfund" i18n-placeholder>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-4 col-lg-4">
                                <div class="form-group">
                                    <label i18n for="ncev_fase">Fase</label>
                                    <select class="form-control" id="ncev_fase" [(ngModel)]="centroEvangelizacao.faseCentroEvangelizacaoId" name="ncev_fase">
                                        <option [value]="null" i18n>Selecione uma Fase</option>
                                        <option *ngFor="let fase of fases" [value]="fase.id">{{fase.nome}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-4 col-lg-4">
                                <div class="form-group">
                                    <label i18n for="ncev_r">Regional</label>
                                    <select class="form-control" id="ncev_r" [(ngModel)]="regionalSelecionadoId" (change)="carregarMissoes()" name="ncev_r">
                                        <option [value]="null" i18n>Selecione um Regional</option>
                                        <option *ngFor="let reg of regionais" [value]="reg.id">{{reg.nome}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-4 col-lg-4">
                                <div class="form-group">
                                    <label i18n for="ncev_missao">Missão</label>
                                    <select class="form-control" id="ncev_missao" [(ngModel)]="centroEvangelizacao.missaoId" (change)="carregarIrradiacoes()" name="ncev_missao">
                                        <option [value]="null" i18n>Selecione uma Missão</option>
                                        <option *ngFor="let missao of missoes" [value]="missao.id">{{missao.nome}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-4 col-lg-4" *ngIf="centroEvangelizacao.missaoId != null">
                                <div class="form-group">
                                    <label i18n for="ncev_irradiacao">Irradiação</label>
                                    <select class="form-control" id="ncev_irradiacao" [(ngModel)]="centroEvangelizacao.irradiacaoId" name="ncev_irradiacao">
                                        <option [value]="null" i18n>Selecione uma Irradiação</option>
                                        <option *ngFor="let irradiacao of irradiacoes" [value]="irradiacao.id">{{irradiacao.text}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-4 col-lg-4">
                                <div class="form-group">
                                    <label i18n for="ncev_difusao">Difusão</label>
                                    <select class="form-control" id="ncev_difusao" [(ngModel)]="centroEvangelizacao.difusaoId" name="ncev_difusao">
                                        <option [value]="null" i18n>Selecione uma Difusão</option>
                                        <option *ngFor="let difusao of difusoes" [value]="difusao.id">{{difusao.nome}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-12">
                                <fieldset>
                                    <legend>Endereço</legend>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label i18n for="ncev_pais">País</label>
                                                <select class="form-control" id="ncev_pais" [(ngModel)]="centroEvangelizacao.endereco.paisId" (change)="carregarEstados()" name="ncev_pais">
                                                    <option [value]="null" i18n>Selecione um País</option>
                                                    <option *ngFor="let pais of paises" [value]="pais.id">{{pais.nome}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label i18n for="ncev_estado">Estado</label>
                                                <select class="form-control" id="ncev_estado" [(ngModel)]="centroEvangelizacao.endereco.estadoId" (change)="carregarCidades()" name="ncev_estado">
                                                    <option [value]="null" i18n>Selecione um Estado</option>
                                                    <option *ngFor="let estado of estados" [value]="estado.id">{{estado.nome}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label i18n for="ncev_cidade">Cidade</label>
                                                <select class="form-control" id="ncev_cidade" [(ngModel)]="centroEvangelizacao.endereco.cidadeId" name="ncev_cidade">
                                                    <option [value]="null" i18n>Selecione uma Cidade</option>
                                                    <option *ngFor="let cidade of cidades" [value]="cidade.id">{{cidade.nome}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-8 col-lg-8">
                                            <div class="form-group">
                                                <label i18n for="ncev_endereco">Endereço</label>
                                                <input [(ngModel)]="centroEvangelizacao.endereco.endereco" name="ncev_endereco" type="text" class="form-control" id="ncev_endereco" placeholder="Digite o endereço" i18n-placeholder>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label i18n for="ncev_complemento">Complemento</label>
                                                <input [(ngModel)]="centroEvangelizacao.endereco.complemento" name="ncev_complemento" type="text" class="form-control" id="ncev_complemento" placeholder="Digite o complemento" i18n-placeholder>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label i18n for="ncev_bairro">Bairro</label>
                                                <input [(ngModel)]="centroEvangelizacao.endereco.bairro" name="ncev_bairro" type="text" class="form-control" id="ncev_bairro" placeholder="Digite o bairro" i18n-placeholder>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-2 col-lg-2">
                                            <div class="form-group">
                                                <label i18n for="ncev_cep">CEP</label>
                                                <input [(ngModel)]="centroEvangelizacao.endereco.cep" name="ncev_cep" type="text" class="form-control" id="ncev_cep" placeholder="Digite o cep" i18n-placeholder>
                                            </div>
                                        </div>
                                    </div>
                                </fieldset>

                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <button type="submit" class="btn btn-primary float-right" i18n>Salvar</button>
                    </div>
                </div>
            </form>
        </section>
        <!-- <section class="content-header" name="gerencia-autorizacoes" *ngIf="pais.id!=null">
            <div class="card card-default color-pallete-box">
                <div class="card-header">
                    <h3 class="card-title">
                        <i class="fas fa-user-lock"></i>
                        <a i18n> Autorizações vinculadas </a>
                    </h3>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title">Vincular Autorizacao</h3>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <alerta [alert]="alertaVinculacao"></alerta>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label i18n for="pv_autorizacao">Autorização</label>
                                                <select class="form-control" id="pv_autorizacao" [(ngModel)]="autorizacaoSelecionada" name="pv_autorizacao">
                                                    <option [value]="null" i18n>Selecione uma Autorizacao</option>
                                                    <option *ngFor="let auth of autorizacoesNaoVinculadas" [value]="auth.id">{{auth.nomeExibicao}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <label>&nbsp;</label><br>
                                            <button class="btn btn-md btn-primary" (click)="vincularAutorizacao()" title="Vincular autorização" alt="Vincular autorização"><i class="fas fa-plus-square"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <alerta [alert]="alertaAutorizacoes"></alerta>
                            <table-component [tituloSessao]="'Autorizacoes'" [cols]="4" [rows]="busca.quantityOfElements" [totalDePaginas]="totalDePaginas" (eventoBotao)="cliqueNoBotaoListener($event)">
                                <tr tableheader>
                                    <th style="width: 10px">#</th>
                                    <th>Nome de exibição</th>
                                    <th>Nome</th>
                                    <th class="text-center">Desvincular</th>
                                </tr>
                                <tr tablebody *ngFor="let autorizacao of autorizacoes">
                                    <td>{{autorizacao.id}}</td>
                                    <td>{{autorizacao.nomeExibicao}}</td>
                                    <td>{{autorizacao.nome}}</td>
                                    <td class="text-center">
                                        <button class="btn btn-sm btn-danger" (click)="desvincularAutorizacao(autorizacao.id)" title="Desvincular autorização" alt="Desvincular autorização"><i class="fas fa-minus-square"></i></button>
                                    </td>
                                </tr>
                            </table-component>
                        </div>
                    </div>
                </div>
            </div>
        </section> -->
    </div>
</secured>