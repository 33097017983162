import { Component, Input, Output, EventEmitter, OnInit, OnChanges } from '@angular/core';
import { Botao } from './botao.model';

@Component({
    selector: 'table-component',
    templateUrl: './table.component.html'
})
export class TableComponent implements OnInit, OnChanges {

    @Input() 
    public cols: number;
    @Input()
    public rows: number;
    @Input()
    public totalDePaginas: number;
    @Output() 
    public eventoBotao = new EventEmitter();
    @Input()
    public tituloSessao : string;
    @Input()
    public tableClass : string;
    @Input()
    public cardBodyClass : string;
    public botoes: Array<Botao> = new Array<Botao>();
    public botaoSelecionado: number;

    ngOnInit() {
        this.botaoSelecionado = 1;
        this.calcularListaDePaginacao();
    }
    
    ngOnChanges(changes) {
        if(changes.totalDePaginas)
        {
            if(changes.totalDePaginas.currentValue != changes.totalDePaginas.previousValue){
                this.calcularListaDePaginacao();
            }
        }
    }

    getClassName(variable: string) : string {
        return variable;
    }

    cliqueNoBotao(botao) {
        this.botaoSelecionado = botao.numero;
        this.calcularListaDePaginacao();
        this.eventoBotao.emit(botao);
    }

    calcularListaDePaginacao() : void {

        this.botoes = new Array<Botao>();

        switch(this.totalDePaginas <= 3)
        {
            case true: {
                for(let i = 1; i <= this.totalDePaginas; i++){

                    let status = '';

                    if(i == this.botaoSelecionado)
                    {
                        status = 'active';
                    }

                    let botao: Botao = new Botao();

                    botao.status = status;
                    botao.numero = i;   
                    botao.simbolo = i+'';                                    

                    this.botoes.push(botao);
                }

                break;
            }
            case false: {				

                //First Button
                if(this.botaoSelecionado > 2)
                {               
                    let primeirobotao: Botao = new Botao();

                    primeirobotao.simbolo = "«";
                    primeirobotao.numero = 1;
                    primeirobotao.status = '';

                    this.botoes.push(primeirobotao)
                }

                if(this.botaoSelecionado == 1) {
                    for(let i = this.botaoSelecionado; i <= this.botaoSelecionado+2 ; i++)
                    {
                        let status = '';

                        if(i == this.botaoSelecionado)
                        {
                            status = 'active';
                        }

                        let botao: Botao = new Botao();

                        botao.status = status;
                        botao.numero = i;   
                        botao.simbolo = i+''; 

                        this.botoes.push(botao)
                    }
                }

                if(this.botaoSelecionado > 1 && this.botaoSelecionado < this.totalDePaginas) {
                    for(let i = this.botaoSelecionado-1; i <= this.botaoSelecionado+1 ; i++)
                    {
                        let status = '';

                        if(i == this.botaoSelecionado)
                        {
                            status = 'active';
                        }

                        let botao: Botao = new Botao();

                        botao.status = status;
                        botao.numero = i;   
                        botao.simbolo = i+''; 

                        this.botoes.push(botao)
                    }
                }

                if(this.botaoSelecionado == this.totalDePaginas) {
                    for(let i = this.botaoSelecionado-2; i <= this.botaoSelecionado ; i++)
                    {
                        let status = '';

                        if(i == this.botaoSelecionado)
                        {
                            status = 'active';
                        }

                        let botao: Botao = new Botao();

                        botao.status = status;
                        botao.numero = i;   
                        botao.simbolo = i+''; 

                        this.botoes.push(botao)
                    }
                }

                //First Button
                if(this.botaoSelecionado < this.totalDePaginas-1)
                {
                    let botao: Botao = new Botao();

                    botao.status = status;
                    botao.numero = this.totalDePaginas;   
                    botao.simbolo = "»"; 

                    this.botoes.push(botao);                    
                }


                break;
            }
        }

    }

}