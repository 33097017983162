import { Injectable } from '@angular/core';
import { HistoricoMissionarioMissao } from '../model/historicomissionariomissao.model';
import { AbstractBuilder } from './abstract.builder';

@Injectable()
export class HistoricoMissionarioMissaoBuilder extends AbstractBuilder<HistoricoMissionarioMissao> {

    reset() : void {
        this.entity = new HistoricoMissionarioMissao(null,null,null,null,null,null,null,null,null,null,false);
    }

}