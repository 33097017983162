<secured>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h3 i18n>Listagem dos Centros de Evangelização</h3>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <form (ngSubmit)="buscarCentrosEvangelizacao()" #permissoesForm="ngForm" novalidate>
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title">Buscar Centros de Evangelização</h3>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label i18n for="lcit_nome">Nome</label>
                                                <input [(ngModel)]="busca.nome" name="lcit_nome" type="text" class="form-control" id="lcit_nome" placeholder="Digite o nome" i18n-placeholder>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label i18n for="ncev_dtfund">Ano Fundação</label>
                                                <input [(ngModel)]="busca.ano" name="ncev_dtfund" type="number" class="form-control" id="ncev_dtfund" i18n-placeholder>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label i18n for="ncev_fase">Fase</label>
                                                <select class="form-control" id="ncev_fase" [(ngModel)]="busca.faseId" name="ncev_fase">
                                                    <option [value]="null" i18n>Selecione uma Fase</option>
                                                    <option *ngFor="let fase of fases" [value]="fase.id">{{fase.nome}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label i18n for="ncev_r">Regional</label>
                                                <select class="form-control" id="ncev_r" [(ngModel)]="busca.regionalId" (change)="carregarMissoes(); carregarDifusoes();" name="ncev_r">
                                                    <option [value]="null" i18n>Selecione um Regional</option>
                                                    <option *ngFor="let reg of regionais" [value]="reg.id">{{reg.nome}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label i18n for="ncev_missao">Missão</label>
                                                <select class="form-control" id="ncev_missao" [(ngModel)]="busca.missaoId" (change)="carregarIrradiacoes()" name="ncev_missao">
                                                    <option [value]="null" i18n>Selecione uma Missão</option>
                                                    <option *ngFor="let missao of missoes" [value]="missao.id">{{missao.nome}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-4 col-lg-4" *ngIf="busca.missaoId != null && busca.missaoId != 'null'">
                                            <div class="form-group">
                                                <label i18n for="ncev_irradiacao">Irradiação</label>
                                                <select class="form-control" id="ncev_irradiacao" [(ngModel)]="busca.irradiacaoId" name="ncev_irradiacao">
                                                    <option [value]="null" i18n>Selecione uma Irradiação</option>
                                                    <option *ngFor="let irradiacao of irradiacoes" [value]="irradiacao.id">{{irradiacao.text}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label i18n for="ncev_difusao">Difusão</label>
                                                <select class="form-control" id="ncev_difusao" [(ngModel)]="busca.difusaoId" name="ncev_difusao">
                                                    <option [value]="null" i18n>Selecione uma Difusão</option>
                                                    <option *ngFor="let difusao of difusoes" [value]="difusao.id">{{difusao.nome}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label i18n for="ncev_pais">País</label>
                                                <select class="form-control" id="ncev_pais" [(ngModel)]="busca.paisId" (change)="carregarEstados()" name="ncev_pais">
                                                    <option [value]="null" i18n>Selecione um País</option>
                                                    <option *ngFor="let pais of paises" [value]="pais.id">{{pais.nome}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label i18n for="ncev_estado">Estado</label>
                                                <select class="form-control" id="ncev_estado" [(ngModel)]="busca.estadoId" (change)="carregarCidades()" name="ncev_estado">
                                                    <option [value]="null" i18n>Selecione um Estado</option>
                                                    <option *ngFor="let estado of estados" [value]="estado.id">{{estado.nome}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label i18n for="ncev_cidade">Cidade</label>
                                                <select class="form-control" id="ncev_cidade" [(ngModel)]="busca.cidadeId" name="ncev_cidade">
                                                    <option [value]="null" i18n>Selecione uma Cidade</option>
                                                    <option *ngFor="let cidade of cidades" [value]="cidade.id">{{cidade.nome}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-footer">
                                    <button type="submit" class="btn btn-primary float-right" i18n>Buscar</button>
                                    <button type="reset" class="btn btn-default float-right" style="margin-right: 0.5rem;" i18n>Limpar</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">

                        <table-component [tituloSessao]="'Centros de Evangelização'" [cols]="5" [rows]="busca.quantityOfElements" [totalDePaginas]="totalDePaginas" (eventoBotao)="cliqueNoBotaoListener($event)">
                            <tr tableheader>
                                <th style="width: 10px">#</th>
                                <th>Nome</th>
                                <th>Missão/Difusão</th>
                                <th>Fase</th>
                                <th></th>
                            </tr>
                            <tr tablebody *ngFor="let centro of centrosEvangelizacao">
                                <td>{{centro.id}}</td>
                                <td>{{centro.nome}}</td>
                                <td>{{centro.missaoNome != null ? centro.missaoNome : ''}}{{centro.difusaoNome != null ? centro.difusaoNome : ''}}</td>
                                <td>{{centro.faseCentroEvangelizacaoNome}}</td>
                                <td class="text-center">
                                    <button class="btn btn-sm btn-primary" routerLink="../centroevangelizacao/{{centro.id}}" title="Editar centro de evangelização" alt="Editar centro de evangelização"><i class="fas fa-edit"></i></button>&nbsp;
                                    <button class="btn btn-sm btn-danger" (click)="deletarCentroEvangelizacao(centro.id)" title="Deletar centro de evangelização" alt="Deletar centro de evangelização"><i class="fas fa-trash-alt"></i></button>
                                </td>
                            </tr>
                        </table-component>
                    </div>
                </div>
            </div>
        </section>
    </div>
</secured>