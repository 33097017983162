<secured>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h3 i18n>Permissões - Missionária</h3>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">

            <form (ngSubmit)="salvar(permissao)" #usuarioForm="ngForm" novalidate>
                <div class="card card-default color-pallete-box">
                    <div class="card-header">
                        <h3 class="card-title">
                            <i class="fas fa-user-lock"></i>
                            <a i18n> Dados da Permissão </a>
                        </h3>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-12 col-md-6 col-lg-6">
                                <div class="form-group">
                                    <label i18n for="np_nome_exibicao">Nome de Exibição</label>
                                    <input [(ngModel)]="permissao.nomeExibicao" name="nomeExibicao" type="text" class="form-control" id="np_nome_exibicao" placeholder="Digite o nome de exibição" i18n-placeholder>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-6">
                                <div class="form-group">
                                    <label i18n for="np_nome">Nome</label>
                                    <input [(ngModel)]="permissao.nome" name="np_nome" type="text" class="form-control" id="np_nome" placeholder="Digite o nome" i18n-placeholder>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <button type="submit" class="btn btn-primary float-right" i18n>Salvar</button>
                    </div>
                </div>
            </form>
        </section>
    </div>
</secured>