import { NgModule } from '@angular/core';
import { RegionalComponent } from './regional.component';
import { Routes, RouterModule } from '@angular/router';
import { RegionaisComponent } from './regionais.component';
import { RegionalEditComponent } from './regional.edit.component';

const regionalRoutes: Routes = [
    {
        path: 'secured/regional',
        component: RegionalComponent
    },
    {
        path: 'secured/regionais',
        component: RegionaisComponent
    },
    {
        path: 'secured/regional/:id',
        component: RegionalEditComponent
    }
]

@NgModule({
    imports: [
        RouterModule.forChild(regionalRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class RegionalRoutingModule {
    
}