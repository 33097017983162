import { NgModule } from '@angular/core';
import { PessoaComponent } from './pessoa.component';
import { PessoaEditComponent } from './pessoa.edit.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PessoaRoutingModule } from './pessoa-routing.module';
import { FormsModule } from '@angular/forms';
import { SecuredModule } from '../../layout/secured.module';
import { PessoaService } from './service/pessoa.service';
import { PessoasComponent } from './pessoas.component';
import { IgxTabsModule } from 'igniteui-angular';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ImagemService } from '../imagem/service/imagem.service';
import { EnderecoService } from '../endereco/service/endereco.service';
import { DocumentoService } from '../documento/service/documento.service';
import { ObraService } from '../obra/service/obra.service';
import { MembroComunidadeAliancaService } from '../membrocomunidadealianca/servico/membrocomunidadealianca.service';
import { MembroComunidadeVidaService } from '../membrocomunidadevida/servico/membrocomunidadevida.service';
import { AptidaoService } from '../aptidao/service/aptidao.service';
import { EscolaridadeService } from '../escolaridade/service/escolaridade.service';
import { TrabalhoService } from '../trabalho/service/trabalho.service';
import { PessoaBuilder } from 'src/app/builder/pessoa.builder';
import { PessoaInfoBuilder } from 'src/app/builder/pessoainfo.builder';

 @NgModule({
     declarations:[
         PessoaComponent,
         PessoasComponent,
         PessoaEditComponent
     ],
     imports:[
         CommonModule,
         RouterModule,
         PessoaRoutingModule,
         FormsModule,
         SecuredModule,
         IgxTabsModule,
         ImageCropperModule
     ],
     providers:[
         PessoaService,
         EnderecoService,
         ImagemService,
         DocumentoService,
         ObraService,
         MembroComunidadeAliancaService,
         MembroComunidadeVidaService,
         AptidaoService,
         EscolaridadeService,
         TrabalhoService,
         PessoaBuilder,
         PessoaInfoBuilder
     ]
 })
 export class PessoaModule{
     
 }