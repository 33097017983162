import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CentroEvangelizacaoComponent } from './centroevangelizacao.component';
import { CentrosEvangelizacaoComponent } from './centrosevangelizacao.component';
import { CentroEvangelizacaoEditComponent } from './centroevangelizacao.edit.component';

const centroEvangelizacaoRoutes: Routes = [
    {
        path: 'secured/centroevangelizacao',
        component: CentroEvangelizacaoComponent
    },
    {
        path: 'secured/centrosevangelizacao',
        component: CentrosEvangelizacaoComponent
    },
    {
        path: 'secured/centroevangelizacao/:id',
        component: CentroEvangelizacaoEditComponent
    }
]

@NgModule({
    imports: [
        RouterModule.forChild(centroEvangelizacaoRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class CentroEvangelizacaoRoutingModule {
    
}