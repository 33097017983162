import { Injectable } from '@angular/core';
import { SalvarMissionarioMissaoAtual } from '../model/salvarmissionariomissaoatual.model';
import { AbstractBuilder } from './abstract.builder';

@Injectable()
export class SalvarMissionarioMissaoAtualBuilder extends AbstractBuilder<SalvarMissionarioMissaoAtual> {

    reset() : void {
        this.entity = new SalvarMissionarioMissaoAtual(null,null,null,null);
    }

}