import { MembroComunidadeVida } from '../model/membrocomunidadevida.model';
import { AbstractBuilder } from './abstract.builder';


export class MembroComunidadeVidaBuilder extends AbstractBuilder<MembroComunidadeVida> {

    reset() : void {
        this.entity = new MembroComunidadeVida(null,null,null,null,null,null,null,null,null,null,null,null,null,null);
    }

}