import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IgxTabsModule } from 'igniteui-angular';
import { ImageCropperModule } from 'ngx-image-cropper';
import { SecuredModule } from 'src/app/secured/layout/secured.module';
import { AdicaoEquipeFormacaoComponent } from './adicao-equipe-formacao.component';

 @NgModule({
     declarations:[
        AdicaoEquipeFormacaoComponent
     ],
     imports:[
        CommonModule,
        RouterModule,
        FormsModule,
        SecuredModule,
        IgxTabsModule,
        ImageCropperModule
    ],
     exports:[
        AdicaoEquipeFormacaoComponent
     ]
 })
 export class AdicaoEquipeFormacaoModule {
     
 }