import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class RestService {
    constructor(httpClient) {
        this.httpClient = httpClient;
        this.token = localStorage.getItem('token')
            ? JSON.parse(localStorage.getItem('token'))
            : null;
        this.apiURL = environment.configServidor.apiUrl;
    }
    postRequest(url, data, custonHttpHeaders) {
        let httpHeader = custonHttpHeaders ? custonHttpHeaders : this.getHeaders();
        return this.httpClient.post(url, data, { headers: httpHeader });
    }
    putRequest(url, data, custonHttpHeaders) {
        let httpHeader = custonHttpHeaders ? custonHttpHeaders : this.getHeaders();
        return this.httpClient.put(url, JSON.stringify(data), {
            headers: httpHeader,
        });
    }
    patchRequest(url, data, custonHttpHeaders) {
        let httpHeader = custonHttpHeaders ? custonHttpHeaders : this.getHeaders();
        return this.httpClient.put(url, JSON.stringify(data), {
            headers: httpHeader,
        });
    }
    deleteRequest(url, custonHttpHeaders) {
        let httpHeader = custonHttpHeaders ? custonHttpHeaders : this.getHeaders();
        return this.httpClient.delete(url, {
            headers: httpHeader,
        });
    }
    getRequest(url, custonHttpHeaders) {
        let httpHeader = custonHttpHeaders ? custonHttpHeaders : this.getHeaders();
        return this.httpClient.get(url, {
            headers: httpHeader,
        });
    }
    getHeaders() {
        this.getTokenFromLocalStorage();
        return new HttpHeaders({
            'Content-Type': 'application/json; charset=UTF-8',
            Authorization: 'Bearer ' + (this.token ? this.token.access_token : ''),
        });
    }
    getCustomHeaders(enctype) {
        this.getTokenFromLocalStorage();
        return new HttpHeaders({
            'Content-Type': enctype,
            Authorization: 'Bearer ' + (this.token ? this.token.access_token : ''),
        });
    }
    getHeadersNoContentType() {
        this.getTokenFromLocalStorage();
        return new HttpHeaders({
            Authorization: 'Bearer ' + (this.token ? this.token.access_token : ''),
        });
    }
    getTokenFromLocalStorage() {
        if (!this.token) {
            this.token = localStorage.getItem('token')
                ? JSON.parse(localStorage.getItem('token'))
                : null;
        }
    }
}
RestService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RestService_Factory() { return new RestService(i0.ɵɵinject(i1.HttpClient)); }, token: RestService, providedIn: "root" });
