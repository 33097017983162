import { Component, OnInit } from '@angular/core';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { Permissao } from 'src/app/model/permissao.model';
import { PermissaoLocalService } from './service/permissao.local.service';

@Component({
    selector: 'permissoes-local',
    templateUrl: './permissoes.local.component.html'
})
export class PermissoesLocalComponent implements OnInit {

    public permissoes: Array<Permissao>;
    public busca: any;
    public totalDePaginas: number;

    constructor(
        public permissaoService: PermissaoLocalService,
        public swtAlert2Service: SwtAlert2Service
    ) 
    {
        this.permissoes = new Array<Permissao>();   
        this.busca = {
            nomeExibicao: undefined,
            nome: undefined,
            pageNumber : 0, 
            quantityOfElements : 10
        };
    }

    ngOnInit() {
        this.buscarPermissoes();
    }   

    cliqueNoBotaoListener(botao) {
        this.busca.pageNumber = botao.numero-1;
        this.buscarPermissoes();
    }

    buscarPermissoes() : void {
        this.permissaoService.buscarPermissoes(this.busca).then( (response: any) => {
            this.permissoes = response.content;
            this.totalDePaginas = response.totalPages;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }
    
    deletarPermissao(id: number) : void {

        if(confirm('Deseja deletar esta permissão?'))
        {
            this.permissaoService.delete(id)
            .then( (response: any) => {
                this.swtAlert2Service.successAlert(response.message);
                this.buscarPermissoes();
            })
            .catch((err) => {
                this.swtAlert2Service.errorAlert(err.error.errors);
            });
        }
    }
}