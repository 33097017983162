<secured>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h3 i18n>Dados Vocacionais</h3>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="card card-primary card-outline card-outline-tabs">
                <div class="card-header p-0 border-bottom-0">
                    <ul class="nav nav-tabs" id="tabs-dadosmissionarios-form" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" id="dados-vocacionais-tab" data-toggle="pill"
                                href="#dados-vocacionais-tab-content" role="tab" aria-controls="dados-vocacionais-tab"
                                aria-selected="true">Dados Vocacionais</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="estado-de-vida-tab" data-toggle="pill"
                                href="#estado-de-vida-tab-content" role="tab" aria-controls="estado-de-vida-tab"
                                aria-selected="false">Estado de Vida</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="missoes-tab" data-toggle="pill" href="#missoes-tab-content"
                                role="tab" aria-controls="missoes-tab" aria-selected="false">Missões</a>
                        </li>
                    </ul>
                </div>
                <div class="card-body">
                    <div class="tab-content" id="tabs-dadosmissionarios-formContent">
                        <div class="tab-pane fade show active" id="dados-vocacionais-tab-content" role="tabpanel"
                            aria-labelledby="dados-vocacionais-tab">
                            <div class="row">
                                <div class="col-sm-12 col-md-4 col-lg-4">
                                    <div class="card card-secondary">
                                        <div class="card-header">
                                            <h3 class="card-title">Informação sobre a Forma de Vida</h3>
                                        </div>
                                        <form (ngSubmit)="atualizarFormaVida()" #formaVidaForm="ngForm" novalidate>
                                            <div class="card-body">
                                                <div class="form-group">
                                                    <label for="formavida">Forma de Vida</label>
                                                    <select class="form-control" name="formavida"
                                                        [(ngModel)]="formaVidaSelecionadaId">
                                                        <option disabled value="null">Selecione uma forma de vida
                                                        </option>
                                                        <option *ngFor="let formaVida of formasVida"
                                                            [value]="formaVida.id">
                                                            {{formaVida.nome}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="card-footer text-right">
                                                <button type="submit" class="btn btn-primary">Salvar</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-8 col-lg-8">
                                    <div class="card card-secondary">
                                        <div class="card-header">
                                            <h3 class="card-title">Informação sobre Dados Vocacionais</h3>
                                        </div>
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-sm-12 col-md-6">
                                                    <div class="form-group">
                                                        <label for="nivel_form">Nível Formação</label>
                                                        <select class="form-control" name="nivel_form"
                                                            [(ngModel)]="dadoVocacional.nivelFormacaoId">
                                                            <option disabled value="null">Selecione um nível de formação
                                                            </option>
                                                            <option *ngFor="let nivelFormacao of niveisFormacao"
                                                                [value]="nivelFormacao.id">
                                                                {{nivelFormacao.nome}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-6">
                                                    <div class="form-group">
                                                        <label for="ano_ingresso">Ano Ingresso (Postulantado)</label>
                                                        <input type="number" class="form-control" name="ano_ingresso"
                                                            [(ngModel)]="dadoVocacional.anoIngresso">
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-6">
                                                    <div class="form-group">
                                                        <label for="ano_prim_prom">Ano Primeiras Promessas</label>
                                                        <input type="number" class="form-control" name="ano_prim_prom"
                                                            [(ngModel)]="dadoVocacional.anoPrimeiraPromessa">
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-6">
                                                    <div class="form-group">
                                                        <label for="ano_prom_def">Ano Promessas Definitivas</label>
                                                        <input type="number" class="form-control" name="ano_prom_def"
                                                            [(ngModel)]="dadoVocacional.anoPromessaDefinitiva">
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <label for="form_pessoal">Formador Pessoal</label>
                                                        <span class="form-control"
                                                            name="form_pessoal">{{pessoaFormadorPessoalSelecionado.nome}}
                                                            {{pessoaFormadorPessoalSelecionado.sobrenome}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <label i18n for="button-add-pessoa">&nbsp;</label>
                                                        <button name="button-add-pessoa" data-toggle="modal"
                                                            data-target="#listagem-pessoa-formador-pessoal"
                                                            style="cursor: pointer" class="btn btn-primary"
                                                            i18n>Selecionar Formador Pessoal</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-footer text-right">
                                            <button type="submit" (click)="atualizarDadoVocacional()"
                                                class="btn btn-primary">Salvar</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12"
                                    *ngIf="pessoa.id != null && pessoa.formaVidaValor == 'CV'">
                                    <missionario-missao-atual [pessoaId]="pessoa.id"></missionario-missao-atual>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="estado-de-vida-tab-content" role="tabpanel"
                            aria-labelledby="estado-de-vida-tab">
                            <div class="row">
                                <div class="col-sm-12 col-md-4 col-lg-4">
                                    <div class="card card-secondary">
                                        <div class="card-header">
                                            <h3 class="card-title">Informação sobre o Estado de Vida</h3>
                                        </div>
                                        <form (ngSubmit)="atualizarEstadoVida()" #formaEstadoForm="ngForm" novalidate>
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="form-group">
                                                            <label for="estadovida">Estado de Vida</label>
                                                            <select class="form-control" name="estadovida"
                                                                [(ngModel)]="estadoVidaSelecionadoId">
                                                                <option disabled [value]="null">Selecione um estado
                                                                    de vida</option>
                                                                <option *ngFor="let estadoVida of estadosVida"
                                                                    [value]="estadoVida.id">{{estadoVida.nome}}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card-footer text-right">
                                                <button type="submit" class="btn btn-primary">Salvar</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-8 col-lg-8">
                                    <div class="card card-secondary">
                                        <div class="card-header">
                                            <h3 class="card-title">Informação sobre Filhos</h3>
                                        </div>
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <div class="checkbox icheck-concrete">
                                                            <input type="checkbox" id="hasFilho" name="hasFilho"
                                                                [(ngModel)]="hasFilho" />&nbsp;
                                                            <label class="form-check-label" for="hasFilho">Tem
                                                                filhos?</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="hasFilho">
                                                <div class="col-12">
                                                    <div class="card card-secondary collapsed-card">
                                                        <div class="card-header">
                                                            <h3 class="card-title">Informações sobre Filhos</h3>
                                                            <div class="card-tools">
                                                                <button type="button" class="btn btn-tool"
                                                                    data-card-widget="collapse">
                                                                    <i class="fas fa-plus"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div class="card-body">
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <div class="row">
                                                                        <div class="col-12"
                                                                            *ngIf="this.pessoa.genero == 'masculino'">
                                                                            <div class="form-group">
                                                                                <div class="checkbox icheck-concrete">
                                                                                    <input type="checkbox"
                                                                                        id="maeIsMembro"
                                                                                        name="maeIsMembro"
                                                                                        [(ngModel)]="maeIsMembro" />&nbsp;
                                                                                    <label class="form-check-label"
                                                                                        for="maeIsMembro">A mãe é membro
                                                                                        da comunidade?</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-12"
                                                                            *ngIf="this.pessoa.genero == 'feminino'">
                                                                            <div class="form-group">
                                                                                <div class="checkbox icheck-concrete">
                                                                                    <input type="checkbox"
                                                                                        id="paiIsMembro"
                                                                                        name="paiIsMembro"
                                                                                        [(ngModel)]="paiIsMembro" />&nbsp;
                                                                                    <label class="form-check-label"
                                                                                        for="paiIsMembro">O pai é membro
                                                                                        da comunidade?</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-12" *ngIf="paiIsMembro">
                                                                            <div class="row">
                                                                                <div class="col-12">
                                                                                    <div class="form-group">
                                                                                        <label i18n
                                                                                            for="button-add-pai">&nbsp;</label>
                                                                                        <button name="button-add-pai"
                                                                                            data-toggle="modal"
                                                                                            data-target="#listagem-pessoa-pai"
                                                                                            style="cursor: pointer"
                                                                                            class="btn btn-primary"
                                                                                            i18n>Selecionar pai</button>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-12">
                                                                                    <div class="form-group">
                                                                                        <label i18n
                                                                                            for="pessoa-pai-info">Nome
                                                                                            do pai</label>
                                                                                        <span
                                                                                            class="form-control">{{pessoaPaiSelecionado.nome}}&nbsp;{{pessoaPaiSelecionado.sobrenome}}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-12" *ngIf="maeIsMembro">
                                                                            <div class="row">
                                                                                <div class="col-12">
                                                                                    <div class="form-group">
                                                                                        <label i18n
                                                                                            for="button-add-mae">&nbsp;</label>
                                                                                        <button name="button-add-mae"
                                                                                            data-toggle="modal"
                                                                                            data-target="#listagem-pessoa-mae"
                                                                                            style="cursor: pointer"
                                                                                            class="btn btn-primary"
                                                                                            i18n>Selecionar mãe</button>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="col-12">
                                                                                    <div class="form-group">
                                                                                        <label i18n
                                                                                            for="pessoa-pai-info">Nome
                                                                                            da mãe</label>
                                                                                        <span
                                                                                            class="form-control">{{pessoaMaeSelecionado.nome}}&nbsp;{{pessoaMaeSelecionado.sobrenome}}</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row">
                                                                        <div class="col-12">
                                                                            <div class="form-group">
                                                                                <div class="checkbox icheck-concrete">
                                                                                    <input type="checkbox"
                                                                                        id="filhoIsMembro"
                                                                                        name="filhoIsMembro"
                                                                                        [(ngModel)]="filho.isMembro" />&nbsp;
                                                                                    <label class="form-check-label"
                                                                                        for="filhoIsMembro">Seu filho é
                                                                                        membro
                                                                                        da comunidade?</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row" *ngIf="filho.isMembro == false">
                                                                        <div class="col-sm-12 col-md-9 col-lg-9">
                                                                            <div class="form-group">
                                                                                <label for="filhonome">Nome</label>
                                                                                <input type="text" class="form-control"
                                                                                    name="filhonome"
                                                                                    [(ngModel)]="filho.filhoNome"
                                                                                    required>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                                                            <div class="form-group">
                                                                                <label for="data_nascimento">Data
                                                                                    Nascimento</label>
                                                                                <input type="date" class="form-control"
                                                                                    name="data_nascimento"
                                                                                    [(ngModel)]="filho.dataNascimento"
                                                                                    required>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row" *ngIf="filho.isMembro == true">
                                                                        <div class="col-12">
                                                                            <div class="form-group">
                                                                                <label i18n
                                                                                    for="button-add-pessoa">&nbsp;</label>
                                                                                <button name="button-add-pessoa"
                                                                                    data-toggle="modal"
                                                                                    data-target="#listagem-pessoa-filho"
                                                                                    style="cursor: pointer"
                                                                                    class="btn btn-primary"
                                                                                    i18n>Selecionar Filho</button>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-12">
                                                                            <div class="form-group">
                                                                                <label for="filhonome">Nome</label>
                                                                                <span class="form-control"
                                                                                    name="filhonome">{{pessoaFilhoSelecionado.nome}}
                                                                                    {{pessoaFilhoSelecionado.sobrenome}}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row">
                                                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                                                            <div class="form-group">
                                                                                <label i18n for="pais">País do
                                                                                    Nascimento</label>
                                                                                <select class="form-control" id="pais"
                                                                                    [(ngModel)]="paisSelecionadoId"
                                                                                    (change)="carregarEstados(paisSelecionadoId)"
                                                                                    name="pais" required>
                                                                                    <option [value]="null" i18n>
                                                                                        Selecione um País</option>
                                                                                    <option *ngFor="let pais of paises"
                                                                                        [value]="pais.id">{{pais.nome}}
                                                                                    </option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                                                            <div class="form-group">
                                                                                <label i18n for="ncev_estado">Estado do
                                                                                    Nascimento</label>
                                                                                <select class="form-control"
                                                                                    id="ncev_estado"
                                                                                    [(ngModel)]="estadoSelecionadoId"
                                                                                    (change)="carregarCidades(estadoSelecionadoId)"
                                                                                    name="ncev_estado" required>
                                                                                    <option [value]="null" i18n>
                                                                                        Selecione um Estado</option>
                                                                                    <option
                                                                                        *ngFor="let estado of estados"
                                                                                        [value]="estado.id">
                                                                                        {{estado.nome}}</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                                                            <div class="form-group">
                                                                                <label i18n for="ncev_cidade">Cidade do
                                                                                    Nascimento</label>
                                                                                <select class="form-control"
                                                                                    id="ncev_cidade"
                                                                                    [(ngModel)]="filho.cidadeNascimento"
                                                                                    name="ncev_cidade" required>
                                                                                    <option [value]="null" i18n>
                                                                                        Selecione uma Cidade</option>
                                                                                    <option
                                                                                        *ngFor="let cidade of cidades"
                                                                                        [value]="cidade.id">
                                                                                        {{cidade.nome}}</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-12 col-md-12 col-lg-12">
                                                                            <div class="form-group">
                                                                                <label for="cidade_nao_cadastrada">Se
                                                                                    sua cidade não estiver cadastrada,
                                                                                    escreva abaixo o País, Estado e
                                                                                    Cidade</label>
                                                                                <input type="text" class="form-control"
                                                                                    name="cidade_nao_cadastrada"
                                                                                    [(ngModel)]="filho.cidadeNaoCadastrada">
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-12 col-md-12 col-lg-12">
                                                                            <div class="form-group">
                                                                                <label
                                                                                    for="observacao">Observações</label>
                                                                                <input type="text" class="form-control"
                                                                                    name="observacao"
                                                                                    [(ngModel)]="filho.observacao">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="card-footer text-right">
                                                            <button type="submit" class="btn btn-primary"
                                                                (click)="salvarFilho(filho)">salvar</button>&nbsp;
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="card card-default">
                                                        <div class="card-body p-0">
                                                            <table class="table table-stripped">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Nome</th>
                                                                        <th>Data de Nascimento</th>
                                                                        <th>Cidade de Nascimento</th>
                                                                        <th>Observações</th>
                                                                        <th></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr *ngFor="let filho of filhos">
                                                                        <td>{{filho.filhoNome}}</td>
                                                                        <td>{{filho.dataNascimento | date :
                                                                            'dd/MM/yyyy'}}</td>
                                                                        <td>{{filho.cidadeNascimento == null ?
                                                                            filho.cidadeNaoCadastrada :
                                                                            filho.cidadeNascimentoNome}}</td>
                                                                        <td>{{filho.observacao}}</td>
                                                                        <td>
                                                                            <button class="btn btn-sm btn-danger"
                                                                                (click)="deletarFilho(filho.id)"
                                                                                title="Deletar filho"
                                                                                alt="Deletar filho"><i
                                                                                    class="fas fa-trash-alt"></i></button>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="pessoa.estadoVidaValor == 'CVT' || pessoa.estadoVidaValor == 'CVP'">
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                    <div class="card card-secondary">
                                        <div class="card-header">
                                            <h3 class="card-title">Informação sobre o Celibato</h3>
                                            <div class="card-tools"
                                                *ngIf="celibatario.id != undefined && celibatario.id != null">
                                                <button type="button" (click)="deletarCelibatario(celibatario.id)"
                                                    class="btn btn-danger" title="Deletar dados de Celibato">
                                                    <i class="fas fa-trash-alt"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <form (ngSubmit)="atualizarCelibatario()" #formaEstadoForm="ngForm" novalidate>
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-sm-12 col-md-4 col-lg-4">
                                                        <div class="form-group">
                                                            <label for="dt-voto-temp">Data dos Votos Temporários</label>
                                                            <input class="form-control" id="dt-voto-temp"
                                                                name="dt-voto-temp" type="date"
                                                                [(ngModel)]="celibatario.dataVotosTemporarios" />
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-12 col-md-4 col-lg-4"
                                                        *ngIf="pessoa.estadoVidaValor == 'CVP'">
                                                        <div class="form-group">
                                                            <label for="dt-voto-def">Data dos Votos Definitivos</label>
                                                            <input class="form-control" id="dt-voto-def"
                                                                name="dt-voto-def" type="date"
                                                                [(ngModel)]="celibatario.dataVotosDefinitivos" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card-footer text-right">
                                                <button type="submit" class="btn btn-primary">Salvar</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div class="row"
                                *ngIf="pessoa.estadoVidaValor == 'SC' || pessoa.estadoVidaValor == 'SNAM' || pessoa.estadoVidaValor == 'SNOI' || pessoa.estadoVidaValor == 'MT'">
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                    <div class="card card-secondary">
                                        <div class="card-header">
                                            <h3 class="card-title">Informação sobre Relacionamento</h3>
                                            <div class="card-tools"
                                                *ngIf="relacionamento.id != undefined && relacionamento.id != null">
                                                <button type="button" (click)="deletarRelacionamento(relacionamento.id)"
                                                    class="btn btn-danger" title="Deletar dados de relacionamento">
                                                    <i class="fas fa-trash-alt"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-sm-12 col-md-4 col-lg-4">
                                                    <div class="form-group">
                                                        <label for="relacao_com_membro_comunidade">A pessoa com quem
                                                            você se relaciona é membro da comunidade?</label>
                                                        <select [(ngModel)]="relacionamento.pessoa2IsMembro"
                                                            class="form-control" name="relacao_com_membro_comunidade"
                                                            id="relacao_com_membro_comunidade">
                                                            <option [ngValue]="null">Selecione uma opção</option>
                                                            <option [ngValue]="true">Sim</option>
                                                            <option [ngValue]="false">Não</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-sm-12 col-md-8 col-lg-8"
                                                    *ngIf="relacionamento.pessoa2IsMembro === false">
                                                    <div class="form-group">
                                                        <label for="nome_pessoa_2">Digite o nome da pessoa</label>
                                                        <input type="text" class="form-control"
                                                            [(ngModel)]="relacionamento.pessoa2Nome"
                                                            [required]="relacionamento.pessoa2IsMembro===false" />
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-8 col-lg-8"
                                                    *ngIf="relacionamento.pessoa2IsMembro === true">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label for="filhonome">Nome</label>
                                                                <span class="form-control"
                                                                    name="filhonome">{{pessoaRelacionamentoSelecionada.nome}}
                                                                    {{pessoaRelacionamentoSelecionada.sobrenome}}</span>
                                                            </div>
                                                        </div>
                                                        <div class="col-12">
                                                            <div class="form-group">
                                                                <label i18n for="button-add-pessoa">&nbsp;</label>
                                                                <button name="button-add-pessoa" data-toggle="modal"
                                                                    data-target="#listagem-pessoa-relacionamento"
                                                                    style="cursor: pointer" class="btn btn-primary"
                                                                    i18n>Selecionar Pessoa</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 com-md-4 col-lg-4"
                                                    *ngIf="relacionamento.pessoa2IsMembro != null">
                                                    <div class="form-group">
                                                        <label for="tipo_relacionamento">Tipo de Relacionamento</label>
                                                        <select class="form-control" name="tipo_relacionamento"
                                                            [(ngModel)]="relacionamento.tipoRelacionamentoId">
                                                            <option disabled [value]="null">Selecione um tipo de
                                                                relacionamento</option>
                                                            <option *ngFor="let tipo of tiposRelacionamento"
                                                                [value]="tipo.id">{{tipo.nome}}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 com-md-3 col-lg-3"
                                                    *ngIf="(pessoa.estadoVidaValor == 'SC' || pessoa.estadoVidaValor == 'SNAM' || pessoa.estadoVidaValor == 'SNOI' || pessoa.estadoVidaValor == 'MT') && relacionamento.pessoa2IsMembro != null">
                                                    <div class="form-group">
                                                        <label for="data_in_cam">Data de início da caminhada</label>
                                                        <input type="date" class="form-control" name="data_in_cam"
                                                            [(ngModel)]="relacionamento.dataInicioCaminhada" />
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 com-md-3 col-lg-3"
                                                    *ngIf="(pessoa.estadoVidaValor == 'SNAM' || pessoa.estadoVidaValor == 'SNOI' || pessoa.estadoVidaValor == 'MT') && relacionamento.pessoa2IsMembro != null">
                                                    <div class="form-group">
                                                        <label for="data_in_nam">Data de início do namoro</label>
                                                        <input type="date" class="form-control" name="data_in_nam"
                                                            [(ngModel)]="relacionamento.dataInicioNamoro" />
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 com-md-3 col-lg-3"
                                                    *ngIf="(pessoa.estadoVidaValor == 'SNOI' || pessoa.estadoVidaValor == 'MT') && relacionamento.pessoa2IsMembro != null">
                                                    <div class="form-group">
                                                        <label for="data_in_noi">Data de início do noivado</label>
                                                        <input type="date" class="form-control" name="data_in_noi"
                                                            [(ngModel)]="relacionamento.dataInicioNoivado" />
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 com-md-3 col-lg-3"
                                                    *ngIf="pessoa.estadoVidaValor == 'MT' && relacionamento.pessoa2IsMembro != null">
                                                    <div class="form-group">
                                                        <label for="data_mt">Data do Matrimônio</label>
                                                        <input type="date" class="form-control" name="data_mt"
                                                            [(ngModel)]="relacionamento.dataMatrimonio" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-footer text-right">
                                            <button type="submit" (click)="atualizarRelacionamento()"
                                                class="btn btn-primary">Salvar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="pessoa.estadoVidaValor == 'SAC'">
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                    <div class="card card-secondary">
                                        <div class="card-header">
                                            <h3 class="card-title">Informação sobre o Sacerdócio</h3>
                                            <div class="card-tools"
                                                *ngIf="sacerdote.id != undefined && sacerdote.id != null">
                                                <button type="button" (click)="deletarSacerdote(sacerdote.id)"
                                                    class="btn btn-danger" title="Deletar dados de Sacerdócio">
                                                    <i class="fas fa-trash-alt"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <form (ngSubmit)="atualizarSacerdote()" #sacerdoteForm="ngForm" novalidate>
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-sm-12 col-md-8 col-lg-8">
                                                        <div class="form-group">
                                                            <label for="lema_sac">Lema Sacerdotal</label>
                                                            <input class="form-control" id="lema_sac" name="lema_sac"
                                                                type="text" [(ngModel)]="sacerdote.lemaSacerdotal" />
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-12 col-md-4 col-lg-4">
                                                        <div class="form-group">
                                                            <label for="dt_ord">Data da Ordenação</label>
                                                            <input class="form-control" id="dt_ord" name="dt_ord"
                                                                type="date" [(ngModel)]="sacerdote.dataOrdenacao" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card-footer text-right">
                                                <button type="submit" class="btn btn-primary">Salvar</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="missoes-tab-content" role="tabpanel"
                            aria-labelledby="missoes-tab">
                            <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                    <form #historicoMissionarioMissaoForm="ngForm"
                                        (ngSubmit)="salvarHistoricoMissionarioMissao(historicoMissionarioMissao)"
                                        novalidate>
                                        <div class="card card-secondary">
                                            <div class="card-header">
                                                <h3 class="card-title">
                                                    Informação sobre as missões que você já participou
                                                </h3>
                                            </div>
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-sm-12 col-md-3 col-lg-3">
                                                        <div class="form-group">
                                                            <label for="missoes">Missão</label>
                                                            <select class="form-control" name="missoes"
                                                                [(ngModel)]="missaoSelecionadaId"
                                                                (change)="carregarCasasComunitarias(missaoSelecionadaId)">
                                                                <option disabled [value]="null">Selecione uma missão
                                                                </option>
                                                                <option *ngFor="let missao of missoes"
                                                                    [value]="missao.id">{{missao.nome}}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-12 col-md-3 col-lg-3">
                                                        <div class="form-group">
                                                            <label for="casas_comunitarias">Casa Comunitária</label>
                                                            <select class="form-control" name="casas_comunitarias"
                                                                [(ngModel)]="historicoMissionarioMissao.casaComunitariaId">
                                                                <option disabled [value]="null">Selecione uma casa
                                                                    comunitária</option>
                                                                <option *ngFor="let casa of casasComunitarias"
                                                                    [value]="casa.id">{{casa.nome}}
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-12 col-md-3 col-lg-3">
                                                        <div class="form-group">
                                                            <label for="data_chegada">Data de Chegada</label>
                                                            <input type="date" class="form-control" name="data_chegada"
                                                                [(ngModel)]="historicoMissionarioMissao.dataChegada"
                                                                required>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-12 col-md-3 col-lg-3">
                                                        <div class="form-group">
                                                            <label for="data_saida">Data de Saída</label>
                                                            <input type="date" class="form-control" name="data_saida"
                                                                [(ngModel)]="historicoMissionarioMissao.dataSaida"
                                                                required>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card-footer text-right">
                                                <button type="submit" class="btn btn-primary">Salvar</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                    <div class="card card-default">
                                        <div class="card-body p-0 overflow-auto ">
                                            <table class="table table-stripped">
                                                <thead>
                                                    <tr>
                                                        <th>Regional</th>
                                                        <th>Missão</th>
                                                        <th>Casa Comunitária</th>
                                                        <th>Data de Chegada</th>
                                                        <th>Data de Saída</th>
                                                        <th>Apostolado</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let historico of historicosMissionarioMissao">
                                                        <td>{{historico.regionalNome}}</td>
                                                        <td>
                                                            <ng-container *ngIf="historico.isEditing != true">
                                                                {{historico.missaoNome}}
                                                            </ng-container>
                                                            <ng-container *ngIf="historico.isEditing == true">
                                                                <select class="form-control" name="missoes"
                                                                    [(ngModel)]="historico.missaoId"
                                                                    (change)="carregarCasasComunitarias(historico.missaoId)">
                                                                    <option disabled [value]="null">Selecione uma missão
                                                                    </option>
                                                                    <option *ngFor="let missao of missoes"
                                                                        [value]="missao.id">{{missao.nome}}
                                                                    </option>
                                                                </select>
                                                            </ng-container>
                                                        </td>
                                                        <td>
                                                            <ng-container *ngIf="historico.isEditing != true">
                                                                {{historico.casaComunitariaNome}}
                                                            </ng-container>
                                                            <ng-container *ngIf="historico.isEditing == true">
                                                                <select class="form-control" name="casas_comunitarias"
                                                                    [(ngModel)]="historico.casaComunitariaId">
                                                                    <option disabled [value]="null">Selecione uma casa
                                                                        comunitária</option>
                                                                    <option *ngFor="let casa of casasComunitarias"
                                                                        [value]="casa.id">{{casa.nome}}
                                                                    </option>
                                                                </select>
                                                            </ng-container>
                                                        </td>
                                                        <td>
                                                            <ng-container *ngIf="historico.isEditing != true">
                                                                {{historico.dataChegada | date : 'dd/MM/yyyy'}}
                                                            </ng-container>
                                                            <ng-container *ngIf="historico.isEditing == true">
                                                                <input title="historico-data-chegada" type="date"
                                                                    class="form-control"
                                                                    [(ngModel)]="historico.dataChegada" />
                                                            </ng-container>
                                                        </td>
                                                        <td>
                                                            <ng-container *ngIf="historico.isEditing != true">
                                                                {{historico.dataSaida != null ? (historico.dataSaida |
                                                                date : 'dd/MM/yyyy') : 'Missão Atual'}}
                                                            </ng-container>
                                                            <ng-container *ngIf="historico.isEditing == true">
                                                                <input title="historico-data-saida" type="date"
                                                                    class="form-control"
                                                                    [(ngModel)]="historico.dataSaida" />
                                                            </ng-container>
                                                        </td>
                                                        <td>
                                                            <button type="button" class="btn btn-info"
                                                                (click)="configModalHistoricoApostolado(historico.id)"
                                                                data-toggle="modal"
                                                                data-target="#modal-info-apostolado">
                                                                Informar Apostolado
                                                            </button>
                                                        </td>
                                                        <td class="text-right" style="min-width: 7rem;">
                                                            <button *ngIf="historico.isEditing != true"
                                                                class="btn btn-sm btn-primary mr-2"
                                                                (click)="historico.isEditing = true; carregarCasasComunitarias(historico.missaoId);"><i
                                                                    class="fas fa-edit"></i></button>
                                                            <button
                                                                (click)="salvarHistoricoMissionarioMissao(historico)"
                                                                *ngIf="historico.isEditing == true"
                                                                class="btn btn-sm btn-success mr-2"
                                                                (click)="historico.isEditing = false"><i
                                                                    class="fas fa-save"></i></button>
                                                            <button class="btn btn-sm btn-danger"
                                                                (click)="deletarHistoricoMissionarioMissao(historico.id)"><i
                                                                    class="fas fa-trash-alt"></i></button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="modal fade" id="modal-info-apostolado" style="display: none;" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Apostolados exercídos na missão</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12">
                                <div class="card card-secondary">
                                    <div class="card-header">
                                        <h3 class="card-title">Informação sobre os apostolados exercidos na missão</h3>
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-sm-12 col-md-3 col-lg-3">
                                                <div class="form-group">
                                                    <label for="formavida">Tipo</label>
                                                    <select class="form-control" name="tipoapostolado"
                                                        [(ngModel)]="tipoApostoladoSelecionadoId"
                                                        (change)="carregarApostoladosPorTipo(tipoApostoladoSelecionadoId)">
                                                        <option disabled [value]="null">Selecione um tipo
                                                        </option>
                                                        <option *ngFor="let tipo of tiposApostolado" [value]="tipo.id">
                                                            {{tipo.nome}}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-3 col-lg-3">
                                                <div class="form-group">
                                                    <label for="apostoladosatina">Apostolado</label>
                                                    <select class="form-control" name="apostoladosatina"
                                                        [(ngModel)]="historicoMissionarioMissaoApostolado.apostoladoId">
                                                        <option disabled [value]="null">Selecione um apostolado
                                                        </option>
                                                        <option *ngFor="let apostolado of apostoladosSelecionados"
                                                            [value]="apostolado.id">{{apostolado.nome}}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-3 col-lg-3">
                                                <div class="form-group">
                                                    <label for="duracao_anos">Duração Anos</label>
                                                    <input type="number" class="form-control" name="duracao_anos"
                                                        [(ngModel)]="historicoMissionarioMissaoApostolado.duracaoAnos"
                                                        required>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-3 col-lg-3">
                                                <div class="form-group">
                                                    <label for="duracao_meses">Duração Meses</label>
                                                    <input type="number" class="form-control" name="duracao_meses"
                                                        [(ngModel)]="historicoMissionarioMissaoApostolado.duracaoMeses"
                                                        required>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-footer text-right">
                                        <button type="button" class="btn btn-primary"
                                            (click)="salvarHistoricoMissionarioMissaoApostolado(historicoMissionarioMissaoApostolado)">Salvar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12">
                                <div class="card card-default">
                                    <div class="card-body p-0">
                                        <table class="table table-stripped">
                                            <thead>
                                                <tr>
                                                    <th>Apostolado</th>
                                                    <th>Duração Anos</th>
                                                    <th>Duração Meses</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    *ngFor="let historicoApostolado of historicosMissionarioMissaoApostolados">
                                                    <td>{{historicoApostolado.apostoladoNome}}</td>
                                                    <td>
                                                        <ng-container *ngIf="historicoApostolado.isEditing != true">
                                                            {{historicoApostolado.duracaoAnos}}
                                                        </ng-container>
                                                        <ng-container *ngIf="historicoApostolado.isEditing == true">
                                                            <input class="form-control" type="number"
                                                                [(ngModel)]="historicoApostolado.duracaoAnos">
                                                        </ng-container>
                                                    </td>
                                                    <td>
                                                        <ng-container *ngIf="historicoApostolado.isEditing != true">
                                                            {{historicoApostolado.duracaoMeses}}
                                                        </ng-container>
                                                        <ng-container *ngIf="historicoApostolado.isEditing == true">
                                                            <input class="form-control" type="number"
                                                                [(ngModel)]="historicoApostolado.duracaoMeses">
                                                        </ng-container>
                                                    </td>
                                                    <td class="text-right">
                                                        <button class="btn btn-sm btn-success mr-1"
                                                            *ngIf="historicoApostolado.isEditing == true;"
                                                            (click)="salvarHistoricoMissionarioMissaoApostolado(historicoApostolado); historicoApostolado.isEditing = false;"
                                                            title="Salvar Apostolado"><i
                                                                class="fas fa-save"></i></button>
                                                        <button class="btn btn-sm btn-primary mr-1"
                                                            *ngIf="historicoApostolado.isEditing != true"
                                                            (click)="historicoApostolado.isEditing = true;"
                                                            title="Editar Apostolado"><i
                                                                class="fas fa-edit"></i></button>
                                                        <button class="btn btn-sm btn-danger"
                                                            (click)="deletarHistoricoMissionarioMissaoApostolado(historicoApostolado.id,historicoApostolado.historicoMissionarioMissaoId)"><i
                                                                class="fas fa-trash-alt"></i></button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer justify-content-right">
                        <button type="button" class="btn btn-default" data-dismiss="modal">Fechar</button>
                    </div>
                </div>
            </div>
        </div>
        <listagem-pessoainfo-modal-component [modalId]="'listagem-pessoa-pai'" [filtrosExtras]="['missao']"
            [filterByUserAccess]="false" [onlyMissionaries]="true" [modalTitle]="'Selecione o pai'"
            [buttonText]="'Selecionar'" [altButtonText]="'Selecionar pai'" (eventEmitter)="selecionarPai($event)">
        </listagem-pessoainfo-modal-component>
        <listagem-pessoainfo-modal-component [modalId]="'listagem-pessoa-mae'" [filtrosExtras]="['missao']"
            [filterByUserAccess]="false" [onlyMissionaries]="true" [modalTitle]="'Selecione a mãe'"
            [buttonText]="'Selecionar'" [altButtonText]="'Selecionar Mãe'" (eventEmitter)="selecionarMae($event)">
        </listagem-pessoainfo-modal-component>
        <listagem-pessoainfo-modal-component [modalId]="'listagem-pessoa-filho'" [filtrosExtras]="['missao']"
            [filterByUserAccess]="false" [onlyMissionaries]="true" [modalTitle]="'Selecione o seu filho(a)'"
            [buttonText]="'Selecionar'" [altButtonText]="'Selecionar Filho(a)'"
            (eventEmitter)="selecionarFilho($event)">
        </listagem-pessoainfo-modal-component>
        <listagem-pessoainfo-modal-component [modalId]="'listagem-pessoa-formador-pessoal'" [filtrosExtras]="['missao']"
            [filterByUserAccess]="false" [onlyMissionaries]="true" [modalTitle]="'Selecione o seu formador(a) pessoal'"
            [altButtonText]="'Selecionar Formador(a)'" [buttonText]="'Selecionar'"
            (eventEmitter)="selecionarFormadorPessoal($event)">
        </listagem-pessoainfo-modal-component>
        <listagem-pessoainfo-modal-component [modalId]="'listagem-pessoa-relacionamento'" [onlyMissionaries]="true"
            [filtrosExtras]="['missao']" [filterByUserAccess]="false" [modalTitle]="'Selecione a pessoa'"
            [buttonText]="'Selecionar'" [altButtonText]="'Selecionar Pessoa'"
            (eventEmitter)="selecionarPessoaRelacionamento($event)">
        </listagem-pessoainfo-modal-component>
    </div>
</secured>