export class Irradiacao {

    constructor(
        public id: number,
        public nome: string,
        public dataFundacao: Date,        
        public cidadeId: number,
        public cidadeEstadoId: number,
        public cidadeEstadoPaisId: number,
        public missaoId: number,
        public missaoRegionalId: number,
        public missaoNome: string,
        public centrosEvangelizacaoIds: Array<number>,
        public faseIrradiacaoId: number
    ){
        this.centrosEvangelizacaoIds = new Array<number>();
    }

}