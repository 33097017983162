import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
export class AcessoUsuarioEstruturaEditComponent {
    constructor(acessoUsuarioEstruturaService, acessoUsuarioEstruturaBuilder, regionalService, missaoService, irradiacaoService, difusaoService, centroEvangelizacaoService, grupoOracaoService, celulaService, casaComunitariaService, ministerioService, projetoService, elementoGrupoDominioService, autorizacaoService, swtAlert2Service, route, router) {
        this.acessoUsuarioEstruturaService = acessoUsuarioEstruturaService;
        this.acessoUsuarioEstruturaBuilder = acessoUsuarioEstruturaBuilder;
        this.regionalService = regionalService;
        this.missaoService = missaoService;
        this.irradiacaoService = irradiacaoService;
        this.difusaoService = difusaoService;
        this.centroEvangelizacaoService = centroEvangelizacaoService;
        this.grupoOracaoService = grupoOracaoService;
        this.celulaService = celulaService;
        this.casaComunitariaService = casaComunitariaService;
        this.ministerioService = ministerioService;
        this.projetoService = projetoService;
        this.elementoGrupoDominioService = elementoGrupoDominioService;
        this.autorizacaoService = autorizacaoService;
        this.swtAlert2Service = swtAlert2Service;
        this.route = route;
        this.router = router;
        this.acessoUsuarioEstrutura = this.acessoUsuarioEstruturaBuilder.getInstance();
        this.pessoaSearchUrl = environment.pessoa.usuarioIdPorPessoaNomeURL;
        this.regionais = new Array();
        this.regionaisAdicionados = new Array();
        this.regionalToAddId = null;
        this.missoes = new Array();
        this.missoesAdicionados = new Array();
        this.missaoToAddId = null;
        this.irradiacoes = new Array();
        this.irradiacoesAdicionados = new Array();
        this.irradiacaoToAddId = null;
        this.difusoes = new Array();
        this.difusoesAdicionados = new Array();
        this.difusaoToAddId = null;
        this.centrosEvangelizacao = new Array();
        this.centrosEvangelizacaoAdicionados = new Array();
        this.centroEvangelizacaoToAddId = null;
        this.gruposOracao = new Array();
        this.gruposOracaoAdicionados = new Array();
        this.grupoOracaoToAddId = null;
        this.publicosGrupoOracao = new Array();
        this.publicosGrupoOracaoAdicionados = new Array();
        this.publicoGrupoOracaoToAddId = null;
        this.celulas = new Array();
        this.celulasAdicionados = new Array();
        this.celulaToAddId = null;
        this.casasComunitarias = new Array();
        this.casasComunitariasAdicionados = new Array();
        this.casaComunitariaToAddId = null;
        this.ministerios = new Array();
        this.ministeriosAdicionados = new Array();
        this.ministerioToAddId = null;
        this.projetos = new Array();
        this.projetosAdicionados = new Array();
        this.projetoToAddId = null;
    }
    ngOnInit() {
        this.initialize();
    }
    initialize() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.acessoUsuarioEstruturaId = Number(this.route.snapshot.paramMap.get('id'));
            this.carregarAcessoUsuarioEstrutura(this.acessoUsuarioEstruturaId);
            this.carregarAutorizacao();
            this.carregarEstruturas();
        });
    }
    carregarAutorizacao() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.autorizacaoService.findAll();
                this.autorizacoes = response.entity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarAcessoUsuarioEstrutura(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.acessoUsuarioEstruturaService.find(id);
                this.acessoUsuarioEstrutura = response.entity;
                if (this.acessoUsuarioEstrutura.usuarioId != null) {
                    $("select[name='pessoa']").append(new Option(this.acessoUsuarioEstrutura.usuarioUsername.concat(' - ').concat(this.acessoUsuarioEstrutura.pessoaNome), this.acessoUsuarioEstrutura.usuarioId.toString(), false, true));
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    // Regionais
    carregarRegionais() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.regionalService.getAllNotInAcessoUsuarioEstrutura(this.acessoUsuarioEstruturaId);
                this.regionais = response.entity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarRegionaisAdicionados() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.regionalService.getAllFromAcessoUsuarioEstrutura(this.acessoUsuarioEstruturaId);
                this.regionaisAdicionados = response.entity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    adicionarAcessoRegional(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let request = {
                    acessoUsuarioEstruturaId: this.acessoUsuarioEstruturaId,
                    regionalId: id
                };
                let response = yield this.acessoUsuarioEstruturaService.adicionarAcessoRegional(request);
                this.swtAlert2Service.successAlert(response.message);
                this.regionalToAddId = null;
                yield this.carregarEstruturas();
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    removerAcessoRegional(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (confirm("Deseja remover o acesso a esta estrutura?")) {
                try {
                    let request = {
                        acessoUsuarioEstruturaId: this.acessoUsuarioEstruturaId,
                        regionalId: id
                    };
                    let response = yield this.acessoUsuarioEstruturaService.removerAcessoRegional(request);
                    this.swtAlert2Service.successAlert(response.message);
                    this.regionalToAddId = null;
                    this.regionais = new Array();
                    yield this.carregarEstruturas();
                }
                catch (err) {
                    this.swtAlert2Service.errorAlert(err.error.errors);
                }
            }
        });
    }
    // Missões
    carregarMissoes() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.regionaisAdicionados.length > 0) {
                try {
                    let response = yield this.missaoService.getAllNotInAcessoUsuarioEstrutura(this.acessoUsuarioEstruturaId);
                    this.missoes = response.entity;
                }
                catch (err) {
                    this.swtAlert2Service.errorAlert(err.error.errors);
                }
            }
        });
    }
    carregarMissoesAdicionados() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.missaoService.getAllFromAcessoUsuarioEstrutura(this.acessoUsuarioEstruturaId);
                this.missoesAdicionados = response.entity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    adicionarAcessoMissao(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let request = {
                    acessoUsuarioEstruturaId: this.acessoUsuarioEstruturaId,
                    missaoId: id
                };
                let response = yield this.acessoUsuarioEstruturaService.adicionarAcessoMissao(request);
                this.swtAlert2Service.successAlert(response.message);
                this.missaoToAddId = null;
                yield this.carregarEstruturas();
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    removerAcessoMissao(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (confirm("Deseja remover o acesso a esta estrutura?")) {
                try {
                    let request = {
                        acessoUsuarioEstruturaId: this.acessoUsuarioEstruturaId,
                        missaoId: id
                    };
                    let response = yield this.acessoUsuarioEstruturaService.removerAcessoMissao(request);
                    this.swtAlert2Service.successAlert(response.message);
                    this.missaoToAddId = null;
                    this.missoes = new Array();
                    yield this.carregarEstruturas();
                }
                catch (err) {
                    this.swtAlert2Service.errorAlert(err.error.errors);
                }
            }
        });
    }
    // Irradiacoes
    carregarIrradiacoes() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.missoesAdicionados.length > 0) {
                try {
                    let response = yield this.irradiacaoService.getAllNotInAcessoUsuarioEstrutura(this.acessoUsuarioEstruturaId);
                    this.irradiacoes = response.entity;
                }
                catch (err) {
                    this.swtAlert2Service.errorAlert(err.error.errors);
                }
            }
        });
    }
    carregarIrradiacoesAdicionados() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.irradiacaoService.getAllFromAcessoUsuarioEstrutura(this.acessoUsuarioEstruturaId);
                this.irradiacoesAdicionados = response.entity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    adicionarAcessoIrradiacao(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let request = {
                    acessoUsuarioEstruturaId: this.acessoUsuarioEstruturaId,
                    irradiacaoId: id
                };
                let response = yield this.acessoUsuarioEstruturaService.adicionarAcessoIrradiacao(request);
                this.swtAlert2Service.successAlert(response.message);
                this.irradiacaoToAddId = null;
                yield this.carregarEstruturas();
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    removerAcessoIrradiacao(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (confirm("Deseja remover o acesso a esta estrutura?")) {
                try {
                    let request = {
                        acessoUsuarioEstruturaId: this.acessoUsuarioEstruturaId,
                        irradiacaoId: id
                    };
                    let response = yield this.acessoUsuarioEstruturaService.removerAcessoIrradiacao(request);
                    this.swtAlert2Service.successAlert(response.message);
                    this.irradiacaoToAddId = null;
                    this.irradiacoes = new Array();
                    yield this.carregarEstruturas();
                }
                catch (err) {
                    this.swtAlert2Service.errorAlert(err.error.errors);
                }
            }
        });
    }
    // Difusões
    carregarDifusoes() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.regionaisAdicionados.length > 0) {
                try {
                    let response = yield this.difusaoService.getAllNotInAcessoUsuarioEstrutura(this.acessoUsuarioEstruturaId);
                    this.difusoes = response.entity;
                }
                catch (err) {
                    this.swtAlert2Service.errorAlert(err.error.errors);
                }
            }
        });
    }
    carregarDifusoesAdicionados() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.difusaoService.getAllFromAcessoUsuarioEstrutura(this.acessoUsuarioEstruturaId);
                this.difusoesAdicionados = response.entity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    adicionarAcessoDifusao(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let request = {
                    acessoUsuarioEstruturaId: this.acessoUsuarioEstruturaId,
                    difusaoId: id
                };
                let response = yield this.acessoUsuarioEstruturaService.adicionarAcessoDifusao(request);
                this.swtAlert2Service.successAlert(response.message);
                this.difusaoToAddId = null;
                yield this.carregarEstruturas();
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    removerAcessoDifusao(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (confirm("Deseja remover o acesso a esta estrutura?")) {
                try {
                    let request = {
                        acessoUsuarioEstruturaId: this.acessoUsuarioEstruturaId,
                        difusaoId: id
                    };
                    let response = yield this.acessoUsuarioEstruturaService.removerAcessoDifusao(request);
                    this.swtAlert2Service.successAlert(response.message);
                    this.difusaoToAddId = null;
                    this.difusoes = new Array();
                    yield this.carregarEstruturas();
                }
                catch (err) {
                    this.swtAlert2Service.errorAlert(err.error.errors);
                }
            }
        });
    }
    // Centro de Evangelização
    carregarCentrosEvangelizacao() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.missoesAdicionados.length > 0 || this.irradiacoesAdicionados.length > 0 || this.difusoesAdicionados.length > 0) {
                try {
                    let response = yield this.centroEvangelizacaoService.getAllNotInAcessoUsuarioEstrutura(this.acessoUsuarioEstruturaId);
                    this.centrosEvangelizacao = response.entity;
                }
                catch (err) {
                    this.swtAlert2Service.errorAlert(err.error.errors);
                }
            }
        });
    }
    carregarCentrosEvangelizacaoAdicionados() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.centroEvangelizacaoService.getAllFromAcessoUsuarioEstrutura(this.acessoUsuarioEstruturaId);
                this.centrosEvangelizacaoAdicionados = response.entity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    adicionarAcessoCentroEvangelizacao(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let request = {
                    acessoUsuarioEstruturaId: this.acessoUsuarioEstruturaId,
                    centroEvangelizacaoId: id
                };
                let response = yield this.acessoUsuarioEstruturaService.adicionarAcessoCentroEvangelizacao(request);
                this.swtAlert2Service.successAlert(response.message);
                this.centroEvangelizacaoToAddId = null;
                yield this.carregarEstruturas();
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    removerAcessoCentroEvangelizacao(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (confirm("Deseja remover o acesso a esta estrutura?")) {
                try {
                    let request = {
                        acessoUsuarioEstruturaId: this.acessoUsuarioEstruturaId,
                        centroEvangelizacaoId: id
                    };
                    let response = yield this.acessoUsuarioEstruturaService.removerAcessoCentroEvangelizacao(request);
                    this.swtAlert2Service.successAlert(response.message);
                    this.centroEvangelizacaoToAddId = null;
                    this.centrosEvangelizacao = new Array();
                    yield this.carregarEstruturas();
                }
                catch (err) {
                    this.swtAlert2Service.errorAlert(err.error.errors);
                }
            }
        });
    }
    // Grupo de Oração
    carregarGruposOracao() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.centrosEvangelizacaoAdicionados.length > 0) {
                try {
                    let response = yield this.grupoOracaoService.getAllNotInAcessoUsuarioEstrutura(this.acessoUsuarioEstruturaId);
                    this.gruposOracao = response.entity;
                }
                catch (err) {
                    this.swtAlert2Service.errorAlert(err.error.errors);
                }
            }
        });
    }
    carregarGruposOracaoAdicionados() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.grupoOracaoService.getAllFromAcessoUsuarioEstrutura(this.acessoUsuarioEstruturaId);
                this.gruposOracaoAdicionados = response.entity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    adicionarAcessoGrupoOracao(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let request = {
                    acessoUsuarioEstruturaId: this.acessoUsuarioEstruturaId,
                    grupoOracaoId: id
                };
                let response = yield this.acessoUsuarioEstruturaService.adicionarAcessoGrupoOracao(request);
                this.swtAlert2Service.successAlert(response.message);
                this.grupoOracaoToAddId = null;
                yield this.carregarEstruturas();
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    removerAcessoGrupoOracao(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (confirm("Deseja remover o acesso a esta estrutura?")) {
                try {
                    let request = {
                        acessoUsuarioEstruturaId: this.acessoUsuarioEstruturaId,
                        grupoOracaoId: id
                    };
                    let response = yield this.acessoUsuarioEstruturaService.removerAcessoGrupoOracao(request);
                    this.swtAlert2Service.successAlert(response.message);
                    this.grupoOracaoToAddId = null;
                    this.gruposOracao = new Array();
                    yield this.carregarEstruturas();
                }
                catch (err) {
                    this.swtAlert2Service.errorAlert(err.error.errors);
                }
            }
        });
    }
    // Público Grupo de Oração
    carregarPublicosGrupoOracao() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.elementoGrupoDominioService.getAllNotInAcessoUsuarioEstrutura(this.acessoUsuarioEstruturaId);
                this.publicosGrupoOracao = response.entity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarPublicosGrupoOracaoAdicionados() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.elementoGrupoDominioService.getAllFromAcessoUsuarioEstrutura(this.acessoUsuarioEstruturaId);
                this.publicosGrupoOracaoAdicionados = response.entity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    adicionarAcessoPublicoGrupoOracao(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let request = {
                    acessoUsuarioEstruturaId: this.acessoUsuarioEstruturaId,
                    publicoGrupoOracaoId: id
                };
                let response = yield this.acessoUsuarioEstruturaService.adicionarAcessoPublicoGrupoOracao(request);
                this.swtAlert2Service.successAlert(response.message);
                this.publicoGrupoOracaoToAddId = null;
                yield this.carregarEstruturas();
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    removerAcessoPublicoGrupoOracao(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (confirm("Deseja remover o acesso a esta estrutura?")) {
                try {
                    let request = {
                        acessoUsuarioEstruturaId: this.acessoUsuarioEstruturaId,
                        publicoGrupoOracaoId: id
                    };
                    let response = yield this.acessoUsuarioEstruturaService.removerAcessoPublicoGrupoOracao(request);
                    this.swtAlert2Service.successAlert(response.message);
                    this.publicoGrupoOracaoToAddId = null;
                    this.publicosGrupoOracao = new Array();
                    yield this.carregarEstruturas();
                }
                catch (err) {
                    this.swtAlert2Service.errorAlert(err.error.errors);
                }
            }
        });
    }
    // Célula
    carregarCelulas() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.centrosEvangelizacaoAdicionados.length > 0) {
                try {
                    let response = yield this.celulaService.getAllNotInAcessoUsuarioEstrutura(this.acessoUsuarioEstruturaId);
                    this.celulas = response.entity;
                }
                catch (err) {
                    this.swtAlert2Service.errorAlert(err.error.errors);
                }
            }
        });
    }
    carregarCelulasAdicionados() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.celulaService.getAllFromAcessoUsuarioEstrutura(this.acessoUsuarioEstruturaId);
                this.celulasAdicionados = response.entity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    adicionarAcessoCelula(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let request = {
                    acessoUsuarioEstruturaId: this.acessoUsuarioEstruturaId,
                    celulaId: id
                };
                let response = yield this.acessoUsuarioEstruturaService.adicionarAcessoCelula(request);
                this.swtAlert2Service.successAlert(response.message);
                this.celulaToAddId = null;
                yield this.carregarEstruturas();
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    removerAcessoCelula(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (confirm("Deseja remover o acesso a esta estrutura?")) {
                try {
                    let request = {
                        acessoUsuarioEstruturaId: this.acessoUsuarioEstruturaId,
                        celulaId: id
                    };
                    let response = yield this.acessoUsuarioEstruturaService.removerAcessoCelula(request);
                    this.swtAlert2Service.successAlert(response.message);
                    this.celulaToAddId = null;
                    this.celulas = new Array();
                    yield this.carregarEstruturas();
                }
                catch (err) {
                    this.swtAlert2Service.errorAlert(err.error.errors);
                }
            }
        });
    }
    // Casa Comunitária
    carregarCasasComunitarias() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.missoesAdicionados.length > 0) {
                try {
                    let response = yield this.casaComunitariaService.getAllNotInAcessoUsuarioEstrutura(this.acessoUsuarioEstruturaId);
                    this.casasComunitarias = response.entity;
                }
                catch (err) {
                    this.swtAlert2Service.errorAlert(err.error.errors);
                }
            }
        });
    }
    carregarCasasComunitariasAdicionados() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.casaComunitariaService.getAllFromAcessoUsuarioEstrutura(this.acessoUsuarioEstruturaId);
                this.casasComunitariasAdicionados = response.entity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    adicionarAcessoCasaComunitaria(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let request = {
                    acessoUsuarioEstruturaId: this.acessoUsuarioEstruturaId,
                    casaComunitariaId: id
                };
                let response = yield this.acessoUsuarioEstruturaService.adicionarAcessoCasaComunitaria(request);
                this.swtAlert2Service.successAlert(response.message);
                this.casaComunitariaToAddId = null;
                yield this.carregarEstruturas();
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    removerAcessoCasaComunitaria(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (confirm("Deseja remover o acesso a esta estrutura?")) {
                try {
                    let request = {
                        acessoUsuarioEstruturaId: this.acessoUsuarioEstruturaId,
                        casaComunitariaId: id
                    };
                    let response = yield this.acessoUsuarioEstruturaService.removerAcessoCasaComunitaria(request);
                    this.swtAlert2Service.successAlert(response.message);
                    this.casaComunitariaToAddId = null;
                    this.casasComunitarias = new Array();
                    yield this.carregarEstruturas();
                }
                catch (err) {
                    this.swtAlert2Service.errorAlert(err.error.errors);
                }
            }
        });
    }
    // Ministério
    carregarMinisterios() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.centrosEvangelizacaoAdicionados.length > 0) {
                try {
                    let response = yield this.ministerioService.getAllNotInAcessoUsuarioEstrutura(this.acessoUsuarioEstruturaId);
                    this.ministerios = response.entity;
                }
                catch (err) {
                    this.swtAlert2Service.errorAlert(err.error.errors);
                }
            }
        });
    }
    carregarMinisteriosAdicionados() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.ministerioService.getAllFromAcessoUsuarioEstrutura(this.acessoUsuarioEstruturaId);
                this.ministeriosAdicionados = response.entity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    adicionarAcessoMinisterio(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let request = {
                    acessoUsuarioEstruturaId: this.acessoUsuarioEstruturaId,
                    ministerioId: id
                };
                let response = yield this.acessoUsuarioEstruturaService.adicionarAcessoMinisterio(request);
                this.swtAlert2Service.successAlert(response.message);
                this.ministerioToAddId = null;
                yield this.carregarEstruturas();
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    removerAcessoMinisterio(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (confirm("Deseja remover o acesso a esta estrutura?")) {
                try {
                    let request = {
                        acessoUsuarioEstruturaId: this.acessoUsuarioEstruturaId,
                        ministerioId: id
                    };
                    let response = yield this.acessoUsuarioEstruturaService.removerAcessoMinisterio(request);
                    this.swtAlert2Service.successAlert(response.message);
                    this.ministerioToAddId = null;
                    this.ministerios = new Array();
                    yield this.carregarEstruturas();
                }
                catch (err) {
                    this.swtAlert2Service.errorAlert(err.error.errors);
                }
            }
        });
    }
    // Projeto
    carregarProjetos() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.centrosEvangelizacaoAdicionados.length > 0) {
                try {
                    let response = yield this.projetoService.getAllNotInAcessoUsuarioEstrutura(this.acessoUsuarioEstruturaId);
                    this.projetos = response.entity;
                }
                catch (err) {
                    this.swtAlert2Service.errorAlert(err.error.errors);
                }
            }
        });
    }
    carregarProjetosAdicionados() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.projetoService.getAllFromAcessoUsuarioEstrutura(this.acessoUsuarioEstruturaId);
                this.projetosAdicionados = response.entity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    adicionarAcessoProjeto(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let request = {
                    acessoUsuarioEstruturaId: this.acessoUsuarioEstruturaId,
                    projetoId: id
                };
                let response = yield this.acessoUsuarioEstruturaService.adicionarAcessoProjeto(request);
                this.swtAlert2Service.successAlert(response.message);
                this.projetoToAddId = null;
                yield this.carregarEstruturas();
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    removerAcessoProjeto(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (confirm("Deseja remover o acesso a esta estrutura?")) {
                try {
                    let request = {
                        acessoUsuarioEstruturaId: this.acessoUsuarioEstruturaId,
                        projetoId: id
                    };
                    let response = yield this.acessoUsuarioEstruturaService.removerAcessoProjeto(request);
                    this.swtAlert2Service.successAlert(response.message);
                    this.projetoToAddId = null;
                    this.projetos = new Array();
                    yield this.carregarEstruturas();
                }
                catch (err) {
                    this.swtAlert2Service.errorAlert(err.error.errors);
                }
            }
        });
    }
    carregarEstruturas() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.carregarRegionais();
            yield this.carregarRegionaisAdicionados();
            this.carregarMissoes();
            yield this.carregarMissoesAdicionados();
            this.carregarIrradiacoes();
            yield this.carregarIrradiacoesAdicionados();
            this.carregarDifusoes();
            yield this.carregarDifusoesAdicionados();
            this.carregarCentrosEvangelizacao();
            yield this.carregarCentrosEvangelizacaoAdicionados();
            this.carregarGruposOracao();
            yield this.carregarGruposOracaoAdicionados();
            this.carregarPublicosGrupoOracao();
            yield this.carregarPublicosGrupoOracaoAdicionados();
            this.carregarCelulas();
            yield this.carregarCelulasAdicionados();
            this.carregarCasasComunitarias();
            yield this.carregarCasasComunitariasAdicionados();
            this.carregarMinisterios();
            yield this.carregarMinisteriosAdicionados();
            this.carregarProjetos();
            yield this.carregarProjetosAdicionados();
        });
    }
    salvar(acessoUsuarioEstrutura) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.acessoUsuarioEstruturaService.update(acessoUsuarioEstrutura.id, acessoUsuarioEstrutura);
                this.acessoUsuarioEstrutura = response.entity;
                this.swtAlert2Service.successAlert(response.message);
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    setarElementoSelecionado(valor) {
        this.acessoUsuarioEstrutura.usuarioId = valor;
    }
}
