import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GrupoDeOracaoComponent } from './grupodeoracao.component';
import { GrupoDeOracaoEditComponent } from './grupodeoracao.edit.component';
import { GruposDeOracaoComponent } from './gruposdeoracao.component';


const grupoDeOracaoRoutes: Routes = [
    {
        path: 'secured/grupodeoracao',
        component: GrupoDeOracaoComponent
    },
    {
        path: 'secured/gruposdeoracao',
        component: GruposDeOracaoComponent
    },
    {
        path: 'secured/grupodeoracao/:id',
        component: GrupoDeOracaoEditComponent
    }
]

@NgModule({
    imports: [
        RouterModule.forChild(grupoDeOracaoRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class GrupoDeOracaoRoutingModule {
    
}