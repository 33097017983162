import { GenericService } from 'src/app/core/services/generic.service';
import { Usuario } from 'src/app/model/usuario.model';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from "@angular/core";


@Injectable()
export class UsuarioService extends GenericService<Usuario> {
    
    constructor(protected http: HttpClient, protected router: Router) {
        super(http,router);
        this.setEntityType('r/usuario');
    }

    getCurrentUser(): Promise<any> {
        return this.http.get<Usuario>(this.apiURL+'/currentuser', { 'headers' : this.getHeaders() }).toPromise();
    }

    buscarUsuarios(busca: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/buscar', JSON.stringify(busca), { 'headers' : this.getHeaders() }).toPromise();
    }

    getUsuariosPorUsuarioCriador(): Promise<any> {
        return this.http.get<Array<Usuario>>(this.apiURL+'/porusuariocriador', { 'headers' : this.getHeaders() }).toPromise();
    }

    vincular(obj: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/vincular/', JSON.stringify(obj), { 'headers' : this.getHeaders() }).toPromise();
    }

    desvincular(obj: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/desvincular/', JSON.stringify(obj), { 'headers' : this.getHeaders() }).toPromise();
    }

}