import { NgModule } from '@angular/core';
import { PaisComponent } from './pais.component';
import { Routes, RouterModule } from '@angular/router';
import { PaisesComponent } from './paises.component';
import { PaisEditComponent } from './pais.edit.component';

const paisRoutes: Routes = [
    {
        path: 'secured/pais',
        component: PaisComponent
    },
    {
        path: 'secured/paises',
        component: PaisesComponent
    },
    {
        path: 'secured/pais/:id',
        component: PaisEditComponent
    }
]

@NgModule({
    imports: [
        RouterModule.forChild(paisRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class PaisRoutingModule {
    
}