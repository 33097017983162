import { Component, OnInit } from '@angular/core';
import { CidadeService } from './service/cidade.service';
import { Cidade } from 'src/app/model/cidade.model';
import { EstadoService } from '../estado/service/estado.service';
import { Estado } from 'src/app/model/estado.model';
import { Pais } from 'src/app/model/pais.model';
import { PaisService } from '../pais/service/pais.service';
import { CidadeBuilder } from 'src/app/builder/cidade.builder';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';

@Component({
    selector: 'cidade',
    templateUrl: './cidade.component.html'
})
export class CidadeComponent implements OnInit {

    public cidade: Cidade;
    public cidadeBuilder: CidadeBuilder;
    public estados : Array<Estado>;
    public paises : Array<Pais>;
    public paisSelecionadoId : number;

    constructor(
        public cidadeService: CidadeService,
        public estadoService: EstadoService,
        public paisService: PaisService,
        public swtAlert2Service: SwtAlert2Service
    ) 
    {
        this.cidadeBuilder = new CidadeBuilder();
        this.cidade = this.cidadeBuilder.getInstance();
        this.estados = new Array<Estado>();
        this.paises = new Array<Pais>();
    }

    ngOnInit() { 
        this.carregarPaises();     
        this.paisSelecionadoId = null; 
    }

    carregarPaises() : void {
        this.paisService.findAll().then( (lista: any) => {
            this.paises = lista.entity;
        }).catch( (err: any) => {
            console.log(err);
        });
    }

    carregarEstados() : void {

        this.cidade.estadoId = null;

        let busca = {
            paisId: this.paisSelecionadoId,
            nome: undefined,
            pageNumber : 0, 
            quantityOfElements : 1000
        };

        this.estadoService.buscarEstados(busca).then( (lista: any) => {
            this.estados = lista.entity;
        }).catch( (err: any) => {
            console.log(err);
        });
    }

    salvar(cidade: Cidade) : void {

        this.cidadeService.create(cidade).then( (response:any) => {
            this.paisSelecionadoId = null;
            this.cidade = new Cidade(null,null,null,null, null, null);
            this.swtAlert2Service.successAlert(response.message);

        }).catch( (err:any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

}