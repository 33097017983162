<secured>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h5 i18n>Listagem das Missionários</h5>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <form (ngSubmit)="buscarMissionarios()" #permissoesForm="ngForm" novalidate>
                            <div class="card">
                                <div class="card-header">
                                    <h6 class="card-title">Buscar Missionários</h6>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label i18n for="lpessoa_nome">Nome</label>
                                                <input [(ngModel)]="busca.nome" name="lpessoa_nome" type="text" class="form-control" id="lpessoa_nome" placeholder="Digite o nome" i18n-placeholder>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label i18n for="lpessoa_sobrenome">Sobrenome</label>
                                                <input [(ngModel)]="busca.sobrenome" name="lpessoa_sobrenome" type="text" class="form-control" id="lpessoa_sobrenome" placeholder="Digite o sobrenome" i18n-placeholder>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label i18n for="lpessoa_genero">Sexo</label>
                                                <select class="form-control" id="lpessoa_genero" [(ngModel)]="busca.genero" name="lpessoa_genero">
                                                    <option value="null" i18n>Selecione o sexo</option>
                                                    <option value="masculino" i18n>Masculino</option>
                                                    <option value="feminino" i18n>Feminino</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label i18n for="lpessoa_nacionalidade">Nacionalidade</label>
                                                <select class="form-control" id="lpessoa_nacionalidade" [(ngModel)]="busca.nacionalidadeId" name="lpessoa_nacionalidade">
                                                    <option [value]="null" i18n>Selecione a Nacionalidade</option>
                                                    <option *ngFor="let pais of paises" [value]="pais.id">{{pais.nome}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label i18n for="ngo_missao">Missão</label>
                                                <select class="form-control" id="ngo_missao" name="ngo_missao" [(ngModel)]="busca.missaoAtualId" (change)="carregarCasasComunitarias()">
                                                    <option [value]="null" i18n>Selecione uma Missão</option>
                                                    <option *ngFor="let missao of missoes" [value]="missao.id">{{missao.nome}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label i18n for="difusoes">Difusão</label>
                                                <select class="form-control" id="difusoes" name="ngo_missao" [(ngModel)]="busca.difusaoAtualId">
                                                    <option [value]="null" i18n>Selecione uma Difusão</option>
                                                    <option *ngFor="let difusao of difusoes" [value]="difusao.id">{{difusao.nome}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label i18n for="casa_comunitaria">Casa Comunitária</label>
                                                <select class="form-control" id="casa_comunitaria" name="ngo_missao" [(ngModel)]="busca.casaComunitariaId">
                                                    <option [value]="null" i18n>Selecione uma Casa Comunitária</option>
                                                    <option *ngFor="let casaComunitaria of casasComunitarias" [value]="casaComunitaria.id">{{casaComunitaria.nome}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label i18n for="npessoa_formavida">Forma de Vida</label>
                                                <select class="form-control" id="npessoa_formavida" name="npessoa_formavida"  [(ngModel)]="busca.formaVidaId" disabled>
                                                    <option value="null" i18n>Selecione a sua forma de vida</option>
                                                    <option *ngFor="let formaVida of formasVida" [value]="formaVida.id">{{formaVida.nome}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label i18n for="npessoa_formavida">Estado de Vida</label>
                                                <select class="form-control" id="npessoa_estadovida" name="npessoa_estadovida" [(ngModel)]="busca.estadoVidaId">
                                                    <option value="null" i18n>Selecione o seu estado de vida</option>
                                                    <option *ngFor="let estadoVida of estadoVida" [value]="estadoVida.id">{{estadoVida.nome}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-footer">
                                    <button type="submit" class="btn btn-primary float-right" i18n>Buscar</button>
                                    <button type="reset" class="btn btn-default float-right" style="margin-right: 0.5rem;" i18n>Limpar</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12" style='text-align: center'>

                        <table-component [cardBodyClass]="'table-responsive p-0'" [tableClass]="'table-striped'" [tituloSessao]="'Listagem de Missionários'" [cols]="11" [rows]="busca.quantityOfElements" [totalDePaginas]="totalDePaginas" (eventoBotao)="cliqueNoBotaoListener($event)">
                            <tr tableheader>
                                <th style="width: 10px">#</th>
                                <th>Foto de perfil</th>
                                <th>Nome</th>
                                <th>Sobrenome</th>
                                <th>Missao</th>
                                <th>Difusão</th>
                                <th>Sexo</th>
                                <th>Nacionalidade</th>
                                <th>Estado de Vida</th>
                                <th>Registro Duplicado?</th>
                                <th></th>
                            </tr>
                            <tr tablebody *ngFor="let pessoa of pessoas">
                                <th>{{pessoa.id}}</th>
                                <td>
                                    <div class="widget-user-image">
                                        <img class="profile-user-img img-fluid img-circle image-size-list elevation-2" [src]="pessoa.thumbnail == null ? 'assets/images/ppl.png' : pessoa.thumbnail" alt="User Avatar">
                                    </div>
                                </td>
                                <td>{{pessoa.nome}}</td>
                                <td>{{pessoa.sobrenome}}</td>
                                <td>{{ (pessoa.vinculoMissao != null && pessoa.vinculoMissao != undefined) ? pessoa.vinculoMissao.missaoAtualNome : 'Não Informada'}}</td>
                                <td>{{ (pessoa.vinculoDifusao != null && pessoa.vinculoDifusao != undefined) ? pessoa.vinculoDifusao.difusaoAtualNome : 'Não Informada'}}</td>
                                <td>{{pessoa.genero}}</td>
                                <td>{{pessoa.nacionalidadeNome}}</td>
                                <td>{{pessoa.estadoVidaNome}}</td>
                                <td><input type="checkbox" [(ngModel)]="pessoa.registroDuplicado" (change)="setarRegistroDuplicado(pessoa.id)" name="registro_duplicado" id="registro_duplicado"></td>
                                <td class="text-center">
                                    <button class="btn btn-sm btn-primary" routerLink="../missionario/{{pessoa.id}}" title="Visualizar os dados do missionário" alt="Visualizar os dados do missionário"><i class="fas fa-eye"></i></button>&nbsp;
                                </td>
                            </tr>
                        </table-component>
                    </div>
                </div>
            </div>
        </section>
    </div>
</secured>