<secured>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h5 i18n><i class="fa fa-list"></i>&nbsp;&nbsp;&nbsp;Questionários</h5>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <!--div *ngIf="questionarios.lenght"  class="row">
                        <div class="col-12">
                            Não há nenhum questionário disponível.
                        </div>
                    </div-->
                <div class="row">
                    <div class="col-md-12">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12" style='text-align: center'>
                                    <table-component [cardBodyClass]="'table-responsive p-0'"
                                        [tableClass]="'table-striped'" [tituloSessao]="'Questionários Disponíveis'"
                                        [cols]="11" [rows]="20" [totalDePaginas]="1">
                                        <tr tableheader>
                                            <th>Descricão</th>
                                            <th>Forma de Vida</th>
                                            <th>Status</th>
                                            <th></th>
                                        </tr>
                                        <tr tablebody *ngFor="let questionario of questionarios">
                                            <td>{{questionario.titulo}}</td>
                                            <td>{{ questionario.formaDeVida === 16 ? 'CA' : (questionario.formaDeVida
                                                === 17 ? 'CV' : '') }}</td>
                                            <td>{{ questionario.status === 'ABERTO' ? 'Disponível' : 'Indisponível'}}
                                            </td>
                                            <!--td *ngIf="questionarioConfiguracao?.status == 'EM_DISCERNIMENTO'">
                                                    <button *ngIf="membro.statusQuestionario == 'ENVIADO'"
                                                        class="btn btn-primary"
                                                        routerLink="/secured/questionariocal-view-answer/{{membro.idQuestionario}}"
                                                        title="Questionario CAL" alt="Questionario CAL">Visualizar</button>
                                                    <button *ngIf="membro.statusQuestionario != 'ENVIADO'"
                                                        class="btn btn-primary" disabled title="Questionario CAL"
                                                        alt="Questionario CAL">{{membro.statusQuestionario}}</button>
                                                </td-->
                                            <td><button class="btn btn-primary"
                                                    routerLink="/secured/questionario/{{origem}}/{{questionario.id}}"
                                                    title="Questionario CAL" alt="Questionario CAL">Visualizar</button>
                                            </td>
                                        </tr>
                                    </table-component>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</secured>