import { Builder } from './builder.interface';

export abstract class AbstractBuilder<T> implements Builder<T> {

    protected entity: T;

    constructor() {
        this.reset();
    }

    public reset(): void {        
    }

    getInstance(): T {
        const result = this.entity;
        this.reset();
        return result;
    }

}