export class DadoVocacional {

    constructor(
        public id: number,
        public pessoaId: number,
        public anoIngresso: number,
        public anoPrimeiraPromessa: number,
        public anoPromessaDefinitiva: number,
        public nivelFormacaoId: number,
        public nivelFormacaoNome: string,
        public formadorPessoalPessoaId: number,
        public formadorPessoalPessoaNome: string,
        public apostoladoAtualId: number,
        public apostoladoAtualNome: string     
    ){}

}