import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import 'select2';
import intlTelInput from 'intl-tel-input';
import { PessoaBuilder } from 'src/app/builder/pessoa.builder';
import { EnderecoBuilder } from 'src/app/builder/endereco.builder';
import { DocumentoBuilder } from 'src/app/builder/documento.builder';
import { ObraBuilder } from 'src/app/builder/obra.builder';
import { MembroComunidadeAliancaBuilder } from 'src/app/builder/membrocomunidadealianca.builder';
import { MembroComunidadeVidaBuilder } from 'src/app/builder/membrocomunidadevida.builder';
import { AptidaoBuilder } from 'src/app/builder/aptidao.builder';
import { EscolaridadeBuilder } from 'src/app/builder/escolaridade.builder';
import { TrabalhoBuilder } from 'src/app/builder/trabalho.builder';
import { environment } from 'src/environments/environment';
export class PessoaEditComponent {
    constructor(pessoaService, enderecoService, paisService, estadoService, cidadeService, imagemService, elementoGrupoDominioService, documentoService, obraService, regionalService, missaoService, difusaoService, membroComunidadeAliancaService, membroComunidadeVidaService, escolaridadeService, trabalhoService, aptidaoService, swtAlert2Service, usuarioService, route, router) {
        this.pessoaService = pessoaService;
        this.enderecoService = enderecoService;
        this.paisService = paisService;
        this.estadoService = estadoService;
        this.cidadeService = cidadeService;
        this.imagemService = imagemService;
        this.elementoGrupoDominioService = elementoGrupoDominioService;
        this.documentoService = documentoService;
        this.obraService = obraService;
        this.regionalService = regionalService;
        this.missaoService = missaoService;
        this.difusaoService = difusaoService;
        this.membroComunidadeAliancaService = membroComunidadeAliancaService;
        this.membroComunidadeVidaService = membroComunidadeVidaService;
        this.escolaridadeService = escolaridadeService;
        this.trabalhoService = trabalhoService;
        this.aptidaoService = aptidaoService;
        this.swtAlert2Service = swtAlert2Service;
        this.usuarioService = usuarioService;
        this.route = route;
        this.router = router;
        this.intlTelInputCalled = false;
        this.regionais = new Array();
        this.missoesVinculo = new Array();
        this.difusoesVinculo = new Array();
        this.missoes = new Array();
        this.difusoes = new Array();
        this.paises = new Array();
        this.estados = new Array();
        this.cidades = new Array();
        this.escolaridades = new Array();
        this.trabalhos = new Array();
        this.estadosCivil = new Array();
        this.tiposDocumento = new Array();
        this.formasVida = new Array();
        this.niveisFormacao = new Array();
        this.estadosVida = new Array();
        this.habilidades = new Array();
        this.linguas = new Array();
        this.niveisEscolaridade = new Array();
        this.imageChangedEvent = '';
        this.croppedImage = '';
        this.nextClicked = false;
        this.pessoaBuilder = new PessoaBuilder();
        this.enderecoBuilder = new EnderecoBuilder();
        this.documentoBuilder = new DocumentoBuilder();
        this.obraBuilder = new ObraBuilder();
        this.membroComunidadeAliancaBuilder = new MembroComunidadeAliancaBuilder();
        this.membroComunidadeVidaBuilder = new MembroComunidadeVidaBuilder();
        this.aptidaoBuilder = new AptidaoBuilder();
        this.escolaridadeBuilder = new EscolaridadeBuilder();
        this.trabalhoBuilder = new TrabalhoBuilder();
        this.pessoa = this.pessoaBuilder.getInstance();
        this.endereco = this.enderecoBuilder.getInstance();
        this.documento = this.documentoBuilder.getInstance();
        this.obra = this.obraBuilder.getInstance();
        this.membroComunidadeAlianca = this.membroComunidadeAliancaBuilder.getInstance();
        this.membroComunidadeVida = this.membroComunidadeVidaBuilder.getInstance();
        this.aptidao = this.aptidaoBuilder.getInstance();
        this.escolaridade = this.escolaridadeBuilder.getInstance();
        this.trabalho = this.trabalhoBuilder.getInstance();
        this.urlBaseImagem = environment.imagem.urlBaseImagem;
        this.url = environment.usuario.selectPorUsername;
    }
    ngOnInit() {
        this.initializacao();
    }
    initializacao() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.pessoaId = Number(this.route.snapshot.paramMap.get('id'));
            yield this.getUsuarioAtual();
            yield this.carregarPaises();
            yield this.carregarFormasVida();
            yield this.carregarTipoDocumento();
            yield this.carregarEstadosCivil();
            yield this.carregarNiveisFormacao();
            yield this.carregarEstadoVida();
            yield this.carregarRegionais();
            yield this.carregarLinguas();
            yield this.carregarHabilidades();
            yield this.carregarNiveisEscolaridade();
            yield this.carregarMissoesVinculo();
            yield this.carregarDifusoesVinculo();
            yield this.carregarPessoaStatus();
            $("select[name='linguas-select']").select2({
                theme: "bootstrap4"
            });
            $("select[name='habilidades-select']").select2({
                theme: "bootstrap4"
            });
            yield this.carregarPessoa(this.pessoaId);
            yield this.encontrarDocumentosPorPessoaId(this.pessoaId);
            yield this.encontrarTrabalhoPorPessoaId(this.pessoaId);
        });
    }
    getUsuarioAtual() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.usuarioService.getCurrentUser();
                this.usuarioAtual = response.entity;
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    carregarRegionais() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.regionalService.findAll();
                this.regionais = response.entity;
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    carregarOrigem() {
        if (this.regionalSelecionadoId != undefined) {
            if (this.tipoOrigem == "M") {
                this.carregarMissoes();
            }
            else if (this.tipoOrigem == "D") {
                this.carregarDifusoes();
            }
        }
    }
    carregarMissoesVinculo() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.missaoService.findAll();
                this.missoesVinculo = response.entity;
                this.missoesVinculo = this.missoesVinculo.sort((a, b) => a.nome.localeCompare(b.nome));
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    carregarDifusoesVinculo() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.difusaoService.findAll();
                this.difusoesVinculo = response.entity;
                this.difusoesVinculo = this.difusoesVinculo.sort((a, b) => a.nome.localeCompare(b.nome));
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    carregarMissoes() {
        this.missaoService.porRegional(this.regionalSelecionadoId).then((response) => {
            this.missoes = response.entity;
            this.difusoes = null;
        }).catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }
    carregarDifusoes() {
        this.difusaoService.porRegional(this.regionalSelecionadoId).then((response) => {
            this.difusoes = response.entity;
            this.missoes = null;
        }).catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }
    ngAfterViewChecked() {
        if (!this.intlTelInputCalled && $('#pessoa-telefone').length == 1) {
            var input = document.querySelector("#pessoa-telefone");
            this.intlTellInputInstance = intlTelInput(input, {
                initialCountry: "auto",
                geoIpLookup: callback => {
                    fetch("https://ipapi.co/json")
                        .then(res => res.json())
                        .then(data => callback(data.country_code))
                        .catch(() => callback("us"));
                },
                utilsScript: 'assets/scripts/utils.js'
            });
            this.intlTelInputCalled = true;
        }
    }
    carregarPaises() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.paisService.findAll();
                this.paises = response.entity;
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    carregarEstados(paisId) {
        let busca = {
            paisId: paisId,
            nome: undefined,
            pageNumber: 0,
            quantityOfElements: 1000
        };
        this.estadoService.buscarEstados(busca).then((lista) => {
            this.estados = lista.entity;
        }).catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }
    carregarCidades(estadoId) {
        let busca = {
            estadoId: estadoId,
            nome: undefined,
            pageNumber: 0,
            quantityOfElements: 1000
        };
        this.cidadeService.buscarCidades(busca).then((lista) => {
            this.cidades = lista.entity;
        }).catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }
    carregarPessoa(Id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.pessoaService.find(Id);
                this.pessoa = response.entity;
                if (this.pessoa.imagemId != null) {
                    let blogImage = yield this.getPessoaImagem(this.pessoa.imagemId);
                    this.imagemService.createImage(blogImage, this.pessoa);
                }
                if (this.pessoa.enderecoId != null) {
                    this.carregarEndereco(this.pessoa.enderecoId);
                }
                if (this.pessoa.usuarioId != null) {
                    $("select[name='usuario']").append(new Option(this.pessoa.usuarioUsername, this.pessoa.usuarioId.toString(), false, true));
                }
                if (this.pessoa.formaVidaId != null) {
                    this.setarFormaVidaSelecionada();
                }
                /* if(this.pessoa.obraId != null)
                {
                    this.carregarObra(this.pessoa.obraId);
                } */
                /* if(this.pessoa.membroId != null)
                {
                    if(this.pessoa.formaVidaValor == 'CA')
                    {
                        this.carregarMembroComunidadeAlianca(this.pessoa.membroId);
                    }
                    else
                    if(this.pessoa.formaVidaValor == 'CV')
                    {
                        this.carregarMembroComunidadeVida(this.pessoa.membroId);
                    }
                } */
                if (this.pessoa.aptidaoId != null) {
                    this.carregarAptidao(this.pessoa.aptidaoId);
                }
                if (this.pessoa.trabalhoIds.length > 0) {
                    this.encontrarTrabalhoPorPessoaId(this.pessoa.id);
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarEndereco(enderecoId) {
        this.enderecoService.find(enderecoId).then((response) => {
            this.endereco = response.entity;
            this.carregarEstados(this.endereco.paisId);
            this.carregarCidades(this.endereco.estadoId);
        }).catch((err) => {
            console.log(err);
        });
    }
    /* carregarObra(obraId: number): void {
        this.obraService.find(obraId).then((response: any) => {
            this.obra = response.entity;
        }).catch((err: any) => {
            console.log(err);
        });
    }

    carregarMembroComunidadeAlianca(membroComunidadeAliancaId: number): void {
        this.membroComunidadeAliancaService.find(membroComunidadeAliancaId).then((response: any) => {
            this.membroComunidadeAlianca = response.entity;
            this.regionalSelecionadoId = this.membroComunidadeAlianca.regionalId;
            if(this.membroComunidadeAlianca.missaoOrigemId != null)
            {
                this.tipoOrigem = 'M';
            }
            else if(this.membroComunidadeAlianca.difusaoOrigemId != null)
            {
                this.tipoOrigem = 'D';
            }
            this.carregarOrigem();
        }).catch((err: any) => {
            console.log(err);
        });
    }

    carregarMembroComunidadeVida(membroComunidadeVidaId: number): void {
        this.membroComunidadeVidaService.find(membroComunidadeVidaId).then((response: any) => {
            this.membroComunidadeVida = response.entity;
            this.regionalSelecionadoId = this.membroComunidadeVida.regionalId;
            if(this.membroComunidadeVida.missaoOrigemId != null)
            {
                this.tipoOrigem = 'M';
            }
            else if(this.membroComunidadeVida.difusaoOrigemId != null)
            {
                this.tipoOrigem = 'D';
            }
            this.carregarOrigem();
        }).catch((err: any) => {
            console.log(err);
        });
    } */
    carregarAptidao(aptidaoId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.aptidaoService.find(aptidaoId);
                this.aptidao = response.entity;
                this.encontrarEscolaridadesPorAptidaoId(this.aptidao.id);
                $("select[name='linguas-select']").val(this.aptidao.linguaIds).trigger('change');
                $("select[name='habilidades-select']").val(this.aptidao.habilidadeIds).trigger('change');
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
                console.log(err);
            }
            return null;
        });
    }
    encontrarDocumentosPorPessoaId(pessoaId) {
        this.documentoService.buscarPorPessoaId(pessoaId).then((response) => {
            this.documentos = response.entity;
        }).catch((err) => {
            console.log(err);
        });
    }
    encontrarEscolaridadesPorAptidaoId(aptidaoId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.escolaridadeService.findByAptidaoId(aptidaoId);
                this.escolaridades = response.entity;
            }
            catch (err) {
                console.log(err);
            }
            return null;
        });
    }
    encontrarTrabalhoPorPessoaId(pessoaId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.trabalhoService.findByPessoaId(pessoaId);
                this.trabalhos = response.entity;
            }
            catch (err) {
                console.log(err);
            }
            return null;
        });
    }
    carregarTipoDocumento() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let buscaDocumentos = {
                grupoNome: 'TIPOS_DOCUMENTOS_PESSOA'
            };
            try {
                let response = yield this.elementoGrupoDominioService.buscarPorGrupoDominioNome(buscaDocumentos);
                this.tiposDocumento = response.entity;
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    carregarEstadosCivil() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let buscaEstadosCivil = {
                grupoNome: 'ESTADO_CIVIL'
            };
            try {
                let response = yield this.elementoGrupoDominioService.buscarPorGrupoDominioNome(buscaEstadosCivil);
                this.estadosCivil = response.entity;
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    carregarFormasVida() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let buscaFormaVida = {
                grupoNome: 'FORMA_VIDA'
            };
            try {
                let response = yield this.elementoGrupoDominioService.buscarPorGrupoDominioNome(buscaFormaVida);
                this.formasVida = response.entity;
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    carregarNiveisFormacao() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let busca = {
                grupoNome: 'NIVEL_FORMACAO'
            };
            try {
                let response = yield this.elementoGrupoDominioService.buscarPorGrupoDominioNome(busca);
                this.niveisFormacao = response.entity;
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    carregarEstadoVida() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let busca = {
                grupoNome: 'ESTADO_VIDA'
            };
            try {
                let response = yield this.elementoGrupoDominioService.buscarPorGrupoDominioNome(busca);
                this.estadosVida = response.entity;
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    carregarHabilidades() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let busca = {
                grupoNome: 'HABILIDADE'
            };
            try {
                let response = yield this.elementoGrupoDominioService.buscarPorGrupoDominioNome(busca);
                this.habilidades = response.entity;
            }
            catch (e) {
                console.log(e);
            }
            ;
            return null;
        });
    }
    carregarLinguas() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let busca = {
                grupoNome: 'LINGUA'
            };
            try {
                let response = yield this.elementoGrupoDominioService.buscarPorGrupoDominioNome(busca);
                this.linguas = response.entity;
            }
            catch (e) {
                console.log(e);
            }
            ;
            return null;
        });
    }
    carregarNiveisEscolaridade() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let busca = {
                grupoNome: 'NIVEL_ESCOLARIDADE'
            };
            try {
                let response = yield this.elementoGrupoDominioService.buscarPorGrupoDominioNome(busca);
                this.niveisEscolaridade = response.entity;
            }
            catch (e) {
                console.log(e);
            }
            ;
            return null;
        });
    }
    carregarPessoaStatus() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let busca = {
                grupoNome: 'PESSOA_STATUS'
            };
            try {
                let response = yield this.elementoGrupoDominioService.buscarPorGrupoDominioNome(busca);
                this.pessoaStatus = response.entity;
            }
            catch (e) {
                console.log(e);
            }
            ;
            return null;
        });
    }
    get valorTipoDocumento() {
        let tipoSelecionado = this.tiposDocumento.filter((tipo) => {
            return (tipo.id == this.documento.elementoGrupoDominioTipoId);
        });
        return tipoSelecionado.length > 0 ? tipoSelecionado[0].valor : '';
    }
    logOnConsole(dadosPessoaisForm) {
        console.log(dadosPessoaisForm);
    }
    activateTab(tab) {
        $('.nav-tabs a[href="#' + tab + '"]').removeClass('disabled');
        $('.nav-tabs a[href="#' + tab + '"]').tab('show');
    }
    setarElementoSelecionado(valor) {
        this.pessoa.usuarioId = valor;
    }
    salvar(pessoa) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.pessoaService.update(pessoa.id, pessoa);
                this.pessoa = response.entity;
                if (this.pessoa.imagemId != null) {
                    let blogImage = yield this.getPessoaImagem(this.pessoa.imagemId);
                    this.imagemService.createImage(blogImage, this.pessoa);
                }
                this.swtAlert2Service.successAlert(response.message);
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    salvarEndereco(endereco) {
        if (this.endereco.id == null) {
            this.enderecoService.create(endereco).then((response) => {
                this.endereco = response.entity;
                this.pessoa.enderecoId = response.entity.id;
                this.salvar(this.pessoa);
            }).catch((err) => {
                this.swtAlert2Service.errorAlert(err.error.errors);
                console.log(err);
            });
        }
        else {
            this.enderecoService.update(endereco.id, endereco).then((response) => {
                this.endereco = response.entity;
                this.swtAlert2Service.successAlert(response.message);
            }).catch((err) => {
                this.swtAlert2Service.errorAlert(err.error.errors);
                console.log(err);
            });
        }
    }
    salvarImagem() {
        this.imagemService.salvarCropped({ 'imageData': this.croppedImage }).then((response) => {
            this.swtAlert2Service.successAlert(response.message);
            this.pessoa.imagemId = response.entity.id;
            this.salvar(this.pessoa);
            $('#modal-pessoa-imagem').modal('hide');
        }).catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }
    salvarDocumento(documento) {
        documento.pessoaId = this.pessoa.id;
        this.documentoService.create(documento).then((response) => {
            this.documento = this.documentoBuilder.getInstance();
            this.pessoa.documentosId.push(response.entity.id);
            this.encontrarDocumentosPorPessoaId(this.pessoaId);
            this.swtAlert2Service.successAlert(response.message);
        }).catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }
    salvarObra(obra) {
        obra.pessoaId = this.pessoa.id;
        this.obraService.create(obra).then((response) => {
            this.obra = response.entity;
            this.pessoa.obraId = response.entity.id;
            this.salvar(this.pessoa);
        }).catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }
    salvarCAAsync(membroComunidadeAlianca) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let mensagem;
            let formaVidaInicial = this.pessoa.formaVidaValor;
            if (this.formaVidaSelecionada.valor == 'CA' && this.pessoa.membroId == null) {
                try {
                    membroComunidadeAlianca.pessoaId = this.pessoa.id;
                    let membroCAResponse = yield this.membroComunidadeAliancaService.createAsync(membroComunidadeAlianca);
                    this.membroComunidadeAlianca = membroCAResponse.entity;
                    this.pessoa.membroId = this.membroComunidadeAlianca.id;
                    this.swtAlert2Service.successAlert(membroCAResponse.message);
                }
                catch (err) {
                    this.swtAlert2Service.errorAlert(err.error.errors);
                }
            }
            else if (this.formaVidaSelecionada.valor == 'CA' && this.pessoa.membroId != null && membroComunidadeAlianca.id != null) {
                try {
                    membroComunidadeAlianca.pessoaId = this.pessoa.id;
                    let membroCAResponse = yield this.membroComunidadeAliancaService.updateAsync(membroComunidadeAlianca.id, membroComunidadeAlianca);
                    this.membroComunidadeAlianca = membroCAResponse.entity;
                    this.swtAlert2Service.successAlert(membroCAResponse.message);
                }
                catch (err) {
                    this.swtAlert2Service.errorAlert(err.error.errors);
                }
            }
            /**
             * Verifica se a forma de vida inicial é a mesma da atualização, se for diferente, deleta a forma de vida inicial e salva a alteração em seguida
             */
            else if (formaVidaInicial != this.formaVidaSelecionada.valor || membroComunidadeAlianca.id == null) {
                try {
                    if (formaVidaInicial == 'CV' && this.pessoa.membroId != null) {
                        // Deleta o registro de membro da comunidade de vida
                        let deleteResponse = yield this.membroComunidadeVidaService.deleteAsync(this.pessoa.membroId);
                        this.membroComunidadeVida = this.membroComunidadeVidaBuilder.getInstance();
                    }
                    // Cria o registro de membro da comunidade de aliança
                    membroComunidadeAlianca.pessoaId = this.pessoa.id;
                    let membroCAResponse = yield this.membroComunidadeAliancaService.createAsync(membroComunidadeAlianca);
                    this.membroComunidadeAlianca = membroCAResponse.entity;
                    this.pessoa.membroId = this.membroComunidadeAlianca.id;
                    this.membroComunidadeVida = this.membroComunidadeVidaBuilder.getInstance();
                    this.swtAlert2Service.successAlert(membroCAResponse.message);
                }
                catch (err) {
                    this.swtAlert2Service.errorAlert(err.error.errors);
                }
            }
            /*
            *   Código que analisa quais dados foram carregados, missão ou difusão
            *   e verifica se a missão ou difusão foi carregada na entidade, caso sim,
            *   seta a missão ou difusão como null para evitar que ambas sejam salvas no BD
            */
            if (this.missoes == null) {
                if (this.membroComunidadeAlianca.difusaoOrigemId != null) {
                    this.membroComunidadeAlianca.missaoOrigemId = null;
                }
            }
            else if (this.difusoes == null) {
                if (this.membroComunidadeAlianca.missaoOrigemId != null) {
                    this.membroComunidadeAlianca.difusaoOrigemId = null;
                }
            }
            /**
             * Atualiza a pessoa pra salvar o novo membro id e atualizar missão ou difusão de origem
             */
            try {
                let pessoaResponse = yield this.pessoaService.updateAsync(this.pessoaId, this.pessoa);
                this.pessoa = pessoaResponse.entity;
                this.swtAlert2Service.successAlert(pessoaResponse.message);
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
            return mensagem;
        });
    }
    salvarCVAsync(membroComunidadeVida) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let mensagem;
            let formaVidaInicial = this.pessoa.formaVidaValor;
            if (this.formaVidaSelecionada.valor == 'CV' && this.pessoa.membroId == null) {
                try {
                    membroComunidadeVida.pessoaId = this.pessoa.id;
                    let membroCVResponse = yield this.membroComunidadeVidaService.createAsync(membroComunidadeVida);
                    this.membroComunidadeVida = membroCVResponse.entity;
                    this.pessoa.membroId = this.membroComunidadeVida.id;
                    this.swtAlert2Service.successAlert(membroCVResponse.message);
                }
                catch (err) {
                    this.swtAlert2Service.errorAlert(err.error.errors);
                }
            }
            else if (this.formaVidaSelecionada.valor == 'CV' && this.pessoa.membroId != null && membroComunidadeVida.id != null) {
                try {
                    membroComunidadeVida.pessoaId = this.pessoa.id;
                    let membroCVResponse = yield this.membroComunidadeVidaService.updateAsync(membroComunidadeVida.id, membroComunidadeVida);
                    this.membroComunidadeVida = membroCVResponse.entity;
                    this.swtAlert2Service.successAlert(membroCVResponse.message);
                }
                catch (err) {
                    this.swtAlert2Service.errorAlert(err.error.errors);
                }
            }
            /**
             * Verifica se a forma de vida inicial é a mesma da atualização, se for diferente, deleta a forma de vida inicial e salva a alteração em seguida
             */
            else if (formaVidaInicial != this.formaVidaSelecionada.valor || membroComunidadeVida.id == null) {
                try {
                    if (formaVidaInicial == 'CA' && this.pessoa.membroId != null) {
                        // Deleta o registro de membro da comunidade de aliança
                        let deleteResponse = yield this.membroComunidadeAliancaService.deleteAsync(this.pessoa.membroId);
                        this.membroComunidadeAlianca = this.membroComunidadeAliancaBuilder.getInstance();
                    }
                    // Cria o registro de membro da comunidade de vida
                    membroComunidadeVida.pessoaId = this.pessoa.id;
                    let membroCVResponse = yield this.membroComunidadeVidaService.createAsync(membroComunidadeVida);
                    this.membroComunidadeVida = membroCVResponse.entity;
                    this.pessoa.membroId = this.membroComunidadeVida.id;
                    this.membroComunidadeAlianca = this.membroComunidadeAliancaBuilder.getInstance();
                    this.swtAlert2Service.successAlert(membroCVResponse.message);
                }
                catch (err) {
                    this.swtAlert2Service.errorAlert(err.error.errors);
                }
            }
            /*
            *   Código que analisa quais dados foram carregados, missão ou difusão
            *   e verifica se a missão ou difusão foi carregada na entidade, caso sim,
            *   seta a missão ou difusão como null para evitar que ambas sejam salvas no BD
            */
            if (this.missoes == null) {
                if (this.membroComunidadeVida.difusaoOrigemId != null) {
                    this.membroComunidadeVida.missaoOrigemId = null;
                }
            }
            else if (this.difusoes == null) {
                if (this.membroComunidadeVida.missaoOrigemId != null) {
                    this.membroComunidadeVida.difusaoOrigemId = null;
                }
            }
            /**
             * Atualiza a pessoa pra salvar o novo membro id e atualizar missão ou difusão de origem
             */
            try {
                let pessoaResponse = yield this.pessoaService.updateAsync(this.pessoaId, this.pessoa);
                this.pessoa = pessoaResponse.entity;
                this.swtAlert2Service.successAlert(pessoaResponse.message);
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
            return mensagem;
        });
    }
    salvarAptidao(aptidao) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.aptidao.pessoaId = this.pessoa.id;
            this.aptidao.linguaIds = $("select[name='linguas-select']").val();
            this.aptidao.habilidadeIds = $("select[name='habilidades-select']").val();
            try {
                if (this.aptidao.id == null) {
                    let response = yield this.aptidaoService.createAsync(aptidao);
                    this.aptidao = response.entity;
                    this.pessoa.aptidaoId = this.aptidao.id;
                    this.swtAlert2Service.successAlert(response.message);
                }
                else {
                    let response = yield this.aptidaoService.updateAsync(aptidao.id, aptidao);
                    this.aptidao = response.entity;
                    this.swtAlert2Service.successAlert(response.message);
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
            return null;
        });
    }
    salvarEscolaridade(escolaridade) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.aptidao.id == null) {
                yield this.salvarAptidao(this.aptidao);
            }
            this.escolaridade.aptidaoId = this.aptidao.id;
            try {
                let response = yield this.escolaridadeService.createAsync(escolaridade);
                this.escolaridade = response.entity;
                this.aptidao.escolaridadeIds.push(this.escolaridade.id);
                this.encontrarEscolaridadesPorAptidaoId(this.aptidao.id);
                this.escolaridade = this.escolaridadeBuilder.getInstance();
                this.swtAlert2Service.successAlert(response.message);
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
            return null;
        });
    }
    salvarTrabalho(trabalho) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.trabalho.pessoaId = this.pessoa.id;
            try {
                let response = yield this.trabalhoService.createAsync(trabalho);
                this.pessoa.trabalhoIds.push(response.entity.id);
                this.encontrarTrabalhoPorPessoaId(this.pessoa.id);
                this.trabalho = this.trabalhoBuilder.getInstance();
                this.swtAlert2Service.successAlert(response.message);
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
            return null;
        });
    }
    adicionarTelefone() {
        if (this.intlTellInputInstance.getNumber().localeCompare("") != 0) {
            this.pessoa.telefones.push(this.intlTellInputInstance.getNumber());
            this.salvar(this.pessoa);
            $("#pessoa-telefone").val("");
        }
    }
    removerTelefone(tel) {
        if (confirm('Deseja deletar este telefone?')) {
            let index = this.pessoa.telefones.indexOf(tel);
            if (index > -1) {
                this.pessoa.telefones.splice(index, 1);
                this.salvar(this.pessoa);
            }
        }
    }
    removerDocumento(id) {
        if (confirm('Deseja deletar este documento?')) {
            this.documentoService.delete(id).then((response) => {
                this.swtAlert2Service.successAlert(response.message);
                this.encontrarDocumentosPorPessoaId(this.pessoaId);
            }).catch((err) => {
                this.swtAlert2Service.errorAlert(err.error.errors);
            });
        }
    }
    removerEscolaridade(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (confirm('Deseja deletar esta escolaridade?')) {
                try {
                    let response = yield this.escolaridadeService.deleteAsync(id);
                    this.swtAlert2Service.successAlert(response.message);
                    this.carregarAptidao(this.pessoa.aptidaoId);
                }
                catch (err) {
                    this.swtAlert2Service.errorAlert(err.error.errors);
                }
            }
            return null;
        });
    }
    removerTrabalho(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (confirm('Deseja deletar este trabalho?')) {
                try {
                    let response = yield this.trabalhoService.deleteAsync(id);
                    this.swtAlert2Service.successAlert(response.message);
                    this.carregarPessoa(this.pessoa.id);
                    this.encontrarTrabalhoPorPessoaId(this.pessoa.id);
                }
                catch (err) {
                    this.swtAlert2Service.errorAlert(err.error.errors);
                }
            }
            return null;
        });
    }
    fileChangeEvent(event) {
        this.imageChangedEvent = event;
        console.log(event);
    }
    imageCropped(event) {
        this.croppedImage = event.base64;
    }
    imageLoaded() {
        console.log('Imagem carregada');
    }
    cropperReady() {
        console.log('Imagem cortada');
    }
    loadImageFailed() {
        console.log('Carregamento da imagem falhou!');
    }
    setarFormaVidaSelecionada() {
        for (let i = 0; i < this.formasVida.length; i++) {
            if (this.formasVida[i].id == this.pessoa.formaVidaId) {
                this.formaVidaSelecionada = this.formasVida[i];
            }
        }
    }
    setarNivelEscolaridadeSelecionado() {
        for (let i = 0; i < this.niveisEscolaridade.length; i++) {
            if (this.niveisEscolaridade[i].id == this.escolaridade.nivelEscolaridadeId) {
                this.nivelEscolaridadeSelecionado = this.niveisEscolaridade[i];
            }
        }
    }
    getPessoaImagem(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.imagemService.getPessoaPhoto(id);
                return response.body;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
}
