import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-mensagem-rl',
  templateUrl: './mensagem-rl.component.html',
  styleUrls: ['./mensagem-rl.component.css'],
})
export class MensagemRlComponent implements OnInit, OnDestroy {
  constructor() {}

  ngOnInit() {}

  ngOnDestroy(): void {}
}
