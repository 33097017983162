import { Endereco } from '../model/endereco.model';
import { AbstractBuilder } from './abstract.builder';


export class EnderecoBuilder extends AbstractBuilder<Endereco> {

    reset() : void {
        this.entity = new Endereco(null, null, null, null, null, null, null, null);
    }

}