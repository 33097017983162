import { NgModule } from '@angular/core';
import { IrradiacaoComponent } from './irradiacao.component';
import { Routes, RouterModule } from '@angular/router';
import { IrradiacoesComponent } from './irradiacoes.component';
import { IrradiacaoEditComponent } from './irradiacao.edit.component';

const irradiacaoRoutes: Routes = [
    {
        path: 'secured/irradiacao',
        component: IrradiacaoComponent
    },
    {
        path: 'secured/irradiacoes',
        component: IrradiacoesComponent
    },
    {
        path: 'secured/irradiacao/:id',
        component: IrradiacaoEditComponent
    }
]

@NgModule({
    imports: [
        RouterModule.forChild(irradiacaoRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class IrradiacaoRoutingModule {
    
}