export class Endereco {

    constructor(
        public id: number,
        public cidadeId: number,
        public estadoId: number,
        public paisId: number,
        public cep: string,
        public bairro: string,
        public endereco: string,
        public complemento: string
    ) {}

}