import { GenericService } from 'src/app/core/services/generic.service';
import { HttpClient } from '@angular/common/http';
import { Aptidao } from 'src/app/model/aptidao.model';
import { Router } from '@angular/router';
import { Injectable } from "@angular/core";

@Injectable()
export class AptidaoService extends GenericService<Aptidao> {    
    
    constructor(protected http: HttpClient, protected router:Router) {
        super(http,router);
        this.setEntityType('r/aptidao');
    }

} 