import { Injectable } from '@angular/core';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { ImagemService } from 'src/app/secured/wop/imagem/service/imagem.service';
import { DetalhesMensagemParaRL } from './mensagemrl.service';

@Injectable()
export class ImageHandlerService {
  constructor(
    public imagemService: ImagemService,
    public swtAlert2Service: SwtAlert2Service
  ) {}

  async inserirThumbnailsEmDetalhesMensagemParaRL(
    detalhes: DetalhesMensagemParaRL
  ): Promise<DetalhesMensagemParaRL> {
    detalhes.thumbnail = await this.getPessoaImagem(detalhes.pessoaImagemId);

    return detalhes;
  }

  public async getPessoaImagem(imagemId: number): Promise<any> {
    try {
      let response = await this.imagemService.getPessoaPhoto(imagemId);
      return response.body;
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }
}
