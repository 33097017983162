import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AcessoUsuarioEstruturaComponent } from './acessousuarioestrutura.component';
import { AcessoUsuariosEstruturasListagemComponent } from './acessousuariosestruturas.component';
import { AcessoUsuarioEstruturaEditComponent } from './acessousuarioestrutura.edit.component';
import { AcessoUsuariosEstruturasListagemCAPComponent } from './telas-cap/acessousuariosestruturas.cap.component';
import { AcessoUsuarioEstruturaEditCAPComponent } from './telas-cap/acessousuarioestrutura.edit.cap.component';
import { AcessoUsuariosEstruturasListagemAssistenteApostolicoComponent } from './telas-assistente-apostolico/acessousuariosestruturas.aa.component';
import { AcessoUsuarioEstruturaEditAssistenteApostolicoComponent } from './telas-assistente-apostolico/acessousuarioestrutura.edit.aa.component';

const acessoUsuarioEstruturaRoutes: Routes = [
    {
        path: 'secured/acessousuarioestrutura',
        component: AcessoUsuarioEstruturaComponent
    },
    {
        path: 'secured/acessosusuarioestrutura',
        component: AcessoUsuariosEstruturasListagemComponent
    },
    {
        path: 'secured/acessousuarioestrutura/:id',
        component: AcessoUsuarioEstruturaEditComponent
    },
    {
        path: 'secured/cap/acessosusuarioestrutura',
        component: AcessoUsuariosEstruturasListagemCAPComponent
    },
    {
        path: 'secured/cap/acessousuarioestrutura/:id',
        component: AcessoUsuarioEstruturaEditCAPComponent
    },
    {
        path: 'secured/aa/acessosusuarioestrutura',
        component: AcessoUsuariosEstruturasListagemAssistenteApostolicoComponent
    },
    {
        path: 'secured/aa/acessousuarioestrutura/:id',
        component: AcessoUsuarioEstruturaEditAssistenteApostolicoComponent
    }
]

@NgModule({
    imports: [
        RouterModule.forChild(acessoUsuarioEstruturaRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class AcessoUsuarioEstruturaRoutingModule {
    
}