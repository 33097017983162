import { Component, OnDestroy, OnInit } from '@angular/core';
import { MensagemRlSharedStateService } from '../../service/mensagem-rl-shared-state.service';
import {
  DetalhesMensagemParaRL,
  MensagemRLService,
} from '../../service/mensagemrl.service';
import { ImageHandlerService } from '../../service/image.haddler.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-mensagem-rl-sidebar',
  templateUrl: './mensagem-rl-sidebar.component.html',
  styleUrls: ['./mensagem-rl-sidebar.component.css'],
})
export class MensagemRlSidebarComponent implements OnInit, OnDestroy {
  public selectedFilterButton?: string;
  private selectedFilterButtonSubscription: Subscription;
  public mensagemRlSummaryList: Array<DetalhesMensagemParaRL>;
  private mensagemRlSummaryListSubscription: Subscription;
  public displayableSummaryList: Array<DetalhesMensagemParaRL>;
  private setTimeoutId: NodeJS.Timer = null;

  constructor(
    public mensagemRlSharedStateService: MensagemRlSharedStateService,
    public mensagemRLService: MensagemRLService,
    public imageHandlerService: ImageHandlerService
  ) {}

  ngOnInit() {
    this.selectedFilterButtonSubscription =
      this.mensagemRlSharedStateService.currentFilterButton.subscribe(
        (state) => (this.selectedFilterButton = state)
      );

    this.mensagemRlSummaryListSubscription = this.mensagemRLService
      .obterMensagemsParaRLLogado()
      .subscribe(async (response) => {
        this.mensagemRlSummaryList = await Promise.all(
          response.map((summary) => this.obterThumbnail(summary))
        );
        this.displayableSummaryList = this.mensagemRlSummaryList;
      });
  }

  ngOnDestroy(): void {
    this.selectedFilterButtonSubscription.unsubscribe();
    this.mensagemRlSummaryListSubscription.unsubscribe();
  }

  async obterThumbnail(summary: DetalhesMensagemParaRL) {
    summary =
      await this.imageHandlerService.inserirThumbnailsEmDetalhesMensagemParaRL(
        summary
      );
    return summary;
  }

  setFilterButtonState(newValue: string) {
    this.mensagemRlSharedStateService.updateSelectedFilterButtonState(newValue);
    this.applyFilterOnSummaryList(newValue);
  }

  applyFilterOnSummaryList(filter: string) {
    switch (filter) {
      case 'Todas': {
        this.displayableSummaryList = this.mensagemRlSummaryList;
        break;
      }
      case 'Não Lidas': {
        this.displayableSummaryList = this.mensagemRlSummaryList.filter(
          (msg) => msg.possuiMensagensNaoLidas === true
        );
        break;
      }
      case 'Lidas': {
        this.displayableSummaryList = this.mensagemRlSummaryList.filter(
          (msg) => msg.possuiMensagensNaoLidas === false
        );
        break;
      }
    }
  }

  selectMemberSummary(memberMessagesSummary: DetalhesMensagemParaRL) {
    memberMessagesSummary.possuiMensagensNaoLidas = false;
    memberMessagesSummary.quantidadeMensagensNaoLidas = null;
    this.mensagemRlSharedStateService.updateSelectedMemberMessageSummary(
      memberMessagesSummary
    );
  }

  filterByInput(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    if (inputElement.value === '') {
      this.applyFilterOnSummaryList(this.selectedFilterButton);
    }
    clearTimeout(this.setTimeoutId);
    this.setTimeoutId = setTimeout(() => {
      this.displayableSummaryList = this.displayableSummaryList.filter((msg) =>
        msg.pessoaNome.toLowerCase().includes(inputElement.value.toLowerCase())
      );
    }, 300);
  }
}
