export class AcessoUsuarioEstrutura {

    constructor(
        public id: number,
        public usuarioId: number,
        public usuarioUsername: string,
        public autorizacaoId: number,
        public autorizacaoNomeExibicao: string,
        public pessoaNome: string,
        public regionaisIds: Array<number>,
        public missoesIds: Array<number>,
        public irradiacoesIds: Array<number>,
        public difusoesIds: Array<number>,
        public centrosEvangelizacaoIds: Array<number>,
        public gruposOracaoIds: Array<number>,
        public celulasIds: Array<number>,
        public casasComunitariasIds: Array<number>,
        public ministeriosIds: Array<number>,
        public projetosIds: Array<number>
    )
    {}

}