import { Component, OnInit } from '@angular/core';
import { CidadeService } from './service/cidade.service';
import { Cidade } from 'src/app/model/cidade.model';
import { EstadoService } from '../estado/service/estado.service';
import { Estado } from 'src/app/model/estado.model';
import { Pais } from 'src/app/model/pais.model';
import { PaisService } from '../pais/service/pais.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CidadeBuilder } from 'src/app/builder/cidade.builder';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';

@Component({
    selector: 'cidade',
    templateUrl: './cidade.component.html'
})
export class CidadeEditComponent implements OnInit {

    public cidade: Cidade;
    public cidadeId: number;
    public estados : Array<Estado>;
    public paises : Array<Pais>;
    public paisSelecionadoId : number;
    public cidadeBuilder: CidadeBuilder;

    constructor(
        public cidadeService: CidadeService,
        public estadoService: EstadoService,
        public paisService: PaisService,
        public swtAlert2Service: SwtAlert2Service,
        public route: ActivatedRoute,
        public router: Router
    ) 
    {
        this.cidadeBuilder = new CidadeBuilder();
        this.cidade = this.cidadeBuilder.getInstance();
        this.estados = new Array<Estado>();
        this.paises = new Array<Pais>();
    }

    ngOnInit() { 
        this.paisSelecionadoId = null; 
        this.cidadeId = Number(this.route.snapshot.paramMap.get('id'));
        this.carregarPaises();             
        this.carregarCidade(this.cidadeId);
    }

    carregarCidade(id: number): void {
        this.cidadeService.find(id).then((response: any) => {
            this.cidade = response.entity;   
            this.paisSelecionadoId = this.cidade.paisId;
            this.carregarEstados();
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }

    carregarPaises() : void {
        this.paisService.findAll().then( (lista: any) => {
            this.paises = lista.entity;
        }).catch( (err: any) => {
            console.log(err);
        });
    }

    carregarEstados() : void {

        let busca = {
            paisId: this.paisSelecionadoId,
            nome: undefined,
            pageNumber : 0, 
            quantityOfElements : 1000
        };

        this.estadoService.buscarEstados(busca).then( (lista: any) => {
            this.estados = lista.entity;
        }).catch( (err: any) => {
            console.log(err);
        });
    }

    salvar(cidade: Cidade) : void {

        this.cidadeService.update(cidade.id,cidade).then( (response:any) => {
            this.swtAlert2Service.successAlert(response.message);
            setTimeout(() => {
                this.router.navigate(['../secured/cidades']);
            }, 2000);

        }).catch( (err:any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

}