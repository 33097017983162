import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment';


@Injectable()
export class WopGetAllService {
    
    constructor(protected http: HttpClient, protected router:Router) {
    }

    public getHeaders() : HttpHeaders {
        return new HttpHeaders({
            'Content-Type' : 'application/json; charset=UTF-8'            
        }); 
    }

    elementosGrupoDominioPorNomeGrupo(obj: any) : Promise<any> {
        return this.http.post<any>(environment.elementoGrupoDominio.porNomeGrupo, JSON.stringify(obj), { 'headers' : this.getHeaders() }).toPromise();
    }

    getAllPais(): Promise<any> {
        return this.http.get<any>(environment.pais.getAllPublic).toPromise();
    }

    getAllMissao(): Promise<any> {
        return this.http.get<any>(environment.missao.getAllPublic).toPromise();
    }

    getAllDifusao(): Promise<any> {
        return this.http.get<any>(environment.difusao.getAllPublic).toPromise();
    }

}