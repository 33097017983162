import { OnInit, Component } from '@angular/core';
import { Pessoa } from 'src/app/model/pessoa.model';
import { Pais } from 'src/app/model/pais.model';
import { Estado } from 'src/app/model/estado.model';
import { Cidade } from 'src/app/model/cidade.model';
import { PaisService } from '../pais/service/pais.service';
import { EstadoService } from '../estado/service/estado.service';
import { CidadeService } from '../cidade/service/cidade.service';
import { ActivatedRoute, Router } from '@angular/router';
import 'select2';
import { ElementoGrupoDominio } from 'src/app/model/elementogrupodominio.model';
import { ElementoGrupoDominioService } from '../grupodominio/service/elementogrupodominio.service';
import { PessoaBuilder } from 'src/app/builder/pessoa.builder';
import { Usuario } from 'src/app/model/usuario.model';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { UsuarioService } from '../usuario/service/usuario.service';
import { PessoaService } from '../pessoa/service/pessoa.service';
import { Filho } from 'src/app/model/filho.model';
import { FilhoBuilder } from 'src/app/builder/filho.builder';
import { PessoaInfo } from 'src/app/model/pessoainfo.model';
import { PessoaInfoBuilder } from 'src/app/builder/pessoainfo.builder';
import { FilhoService } from './service/filho.service';
import { DadoVocacional } from 'src/app/model/dadovocacional.model';
import { DadoVocacionalBuilder } from 'src/app/builder/dadovocacional.builder';
import { Apostolado } from 'src/app/model/apostolado.model';
import { Celibatario } from 'src/app/model/celibatario.model';
import { HistoricoMissionarioMissao } from 'src/app/model/historicomissionariomissao.model';
import { HistoricoMissionarioMissaoApostolado } from 'src/app/model/historicomissionariomissaoapostolado.model';
import { Relacionamento } from 'src/app/model/relacionamento.model';
import { Sacerdote } from 'src/app/model/sacerdote.model';
import { CelibatarioBuilder } from 'src/app/builder/celibatario.builder';
import { ApostoladoBuilder } from 'src/app/builder/apostolado.builder';
import { HistoricoMissionarioMissaoApostoladoBuilder } from 'src/app/builder/historicomissionariomissaoapostolado.builder';
import { RelacionamentoBuilder } from 'src/app/builder/relacionamento.builder';
import { SacerdoteBuilder } from 'src/app/builder/sacerdote.builder';
import { DadoVocacionalService } from './service/dadovocacional.service';
import { CelibatarioService } from './service/celibatario.service';
import { ApostoladoService } from './service/apostolado.service';
import { HistoricoMissionarioMissaoService } from './service/historicomissionariomissao.service';
import { HistoricoMissionarioMissaoApostoladoService } from './service/historicomissionariomissaoapostolado.service';
import { RelacionamentoService } from './service/relacionamento.service';
import { SacerdoteService } from './service/sacerdote.service';
import { HistoricoMissionarioMissaoBuilder } from 'src/app/builder/historicomissionariomissao.builder';
import { Missao } from 'src/app/model/missao.model';
import { CasaComunitariaService } from '../casacomunitaria/service/casacomunitaria.service';
import { CasaComunitaria } from 'src/app/model/casacomunitaria.model';
import { MissaoService } from '../missao/service/missao.service';
import { MissionarioMissaoAtualService } from './service/missionariomissaoatual.service';
import { SalvarMissionarioMissaoAtual } from 'src/app/model/salvarmissionariomissaoatual.model';
import { SalvarMissionarioMissaoAtualBuilder } from 'src/app/builder/salvarmissionariomissaoatual.builder';
import { DadosDetalhamentoMissionarioMissaoAtual } from 'src/app/model/dadosdetalhamentomissionariomissaoatual.model';
import { DadosDetalhamentoMissionarioMissaoAtualBuilder } from 'src/app/builder/dadosdetalhamentomissionariomissaoatual.builder';

@Component({
  selector: 'dadosvocacionaisedit',
  templateUrl: './dadosvocacionais.edit.component.html',
  styleUrls: ['../pessoa/style/style.css', '../pessoa/style/normalize.css'],
})
export class DadosVocacionaisEditComponent implements OnInit {
  public usuarioAtual: Usuario;
  public pessoa: Pessoa;
  public formasVida: Array<ElementoGrupoDominio>;
  public formaVidaSelecionadaId: number;
  public estadosVida: Array<ElementoGrupoDominio>;
  public estadoVidaSelecionadoId: number;
  public niveisFormacao: Array<ElementoGrupoDominio>;
  public tiposRelacionamento: Array<ElementoGrupoDominio>;
  public hasFilho: boolean;
  public filho: Filho;
  public pessoaPaiSelecionado: PessoaInfo;
  public pessoaMaeSelecionado: PessoaInfo;
  public pessoaFilhoSelecionado: PessoaInfo;
  public pessoaFormadorPessoalSelecionado: PessoaInfo;
  public pessoaRelacionamentoSelecionada: PessoaInfo;
  public dadoVocacional: DadoVocacional;
  public tiposApostolado: Array<ElementoGrupoDominio>;
  public apostolados: Array<Apostolado>;
  public apostoladosSelecionados: Array<Apostolado>;
  public apostolado: Apostolado;
  public celibatario: Celibatario;
  public historicoMissionarioMissao: HistoricoMissionarioMissao;
  public historicosMissionarioMissao: Array<HistoricoMissionarioMissao>;
  public historicoMissionarioMissaoApostolado: HistoricoMissionarioMissaoApostolado;
  public historicosMissionarioMissaoApostolados: Array<HistoricoMissionarioMissaoApostolado>;
  public relacionamento: Relacionamento;
  public sacerdote: Sacerdote;
  public filhos: Array<Filho>;
  public paises: Array<Pais>;
  public estados: Array<Estado>;
  public cidades: Array<Cidade>;
  public missoes: Array<Missao>;
  public casasComunitarias: Array<CasaComunitaria>;
  public missaoSelecionadaId: number;
  public paisSelecionadoId: number;
  public estadoSelecionadoId: number;
  public tipoApostoladoSelecionadoId: number;
  public paiIsMembro: boolean;
  public maeIsMembro: boolean;
  public salvarMissionarioMissaoAtual: SalvarMissionarioMissaoAtual;
  public dadosDetalhamentoMissionarioMissaoAtual: DadosDetalhamentoMissionarioMissaoAtual;

  constructor(
    public missaoService: MissaoService,
    public casaComunitariaService: CasaComunitariaService,
    public usuarioService: UsuarioService,
    public pessoaService: PessoaService,
    public paisService: PaisService,
    public estadoService: EstadoService,
    public cidadeService: CidadeService,
    public elementoGrupoDominioService: ElementoGrupoDominioService,
    public filhoService: FilhoService,
    public dadoVocacionalService: DadoVocacionalService,
    public celibatarioService: CelibatarioService,
    public apostoladoService: ApostoladoService,
    public historicoMissionarioMissaoService: HistoricoMissionarioMissaoService,
    public historicoMissionarioMissaoApostoladoService: HistoricoMissionarioMissaoApostoladoService,
    public relacionamentoService: RelacionamentoService,
    public sacerdoteService: SacerdoteService,
    public pessoaBuilder: PessoaBuilder,
    public pessoaInfoBuilder: PessoaInfoBuilder,
    public filhoBuilder: FilhoBuilder,
    public dadoVocacionalBuilder: DadoVocacionalBuilder,
    public celibatarioBuilder: CelibatarioBuilder,
    public apostoladoBuilder: ApostoladoBuilder,
    public historicoMissionarioMissaoBuilder: HistoricoMissionarioMissaoBuilder,
    public historicoMissionarioMissaoApostoladoBuilder: HistoricoMissionarioMissaoApostoladoBuilder,
    public relacionamentoBuilder: RelacionamentoBuilder,
    public sacerdoteBuilder: SacerdoteBuilder,
    public missionarioMissaoAtualService: MissionarioMissaoAtualService,
    public salvarMissionarioMissaoAtualBuilder: SalvarMissionarioMissaoAtualBuilder,
    public dadosDetalhementoMissionarioMissaoAtualBuilder: DadosDetalhamentoMissionarioMissaoAtualBuilder,
    public swtAlert2Service: SwtAlert2Service,
    public route: ActivatedRoute,
    public router: Router
  ) {
    this.formaVidaSelecionadaId = null;
    this.estadoVidaSelecionadoId = null;
    this.paisSelecionadoId = null;
    this.estadoSelecionadoId = null;
    this.missaoSelecionadaId = null;
    this.tipoApostoladoSelecionadoId = null;
    this.pessoa = this.pessoaBuilder.getInstance();
    this.filho = this.filhoBuilder.getInstance();
    this.pessoaPaiSelecionado = this.pessoaInfoBuilder.getInstance();
    this.pessoaMaeSelecionado = this.pessoaInfoBuilder.getInstance();
    this.pessoaFilhoSelecionado = this.pessoaInfoBuilder.getInstance();
    this.pessoaFormadorPessoalSelecionado =
      this.pessoaInfoBuilder.getInstance();
    this.pessoaRelacionamentoSelecionada = this.pessoaInfoBuilder.getInstance();
    this.dadoVocacional = this.dadoVocacionalBuilder.getInstance();
    this.celibatario = this.celibatarioBuilder.getInstance();
    this.apostolado = this.apostoladoBuilder.getInstance();
    this.historicoMissionarioMissao =
      this.historicoMissionarioMissaoBuilder.getInstance();
    this.historicoMissionarioMissaoApostolado =
      this.historicoMissionarioMissaoApostoladoBuilder.getInstance();
    this.relacionamento = this.relacionamentoBuilder.getInstance();
    this.sacerdote = this.sacerdoteBuilder.getInstance();
    this.filhos = new Array<Filho>();
    this.paises = new Array<Pais>();
    this.estados = new Array<Estado>();
    this.cidades = new Array<Cidade>();
    this.apostolados = new Array<Apostolado>();
    this.historicosMissionarioMissao = new Array<HistoricoMissionarioMissao>();
    this.historicosMissionarioMissaoApostolados =
      new Array<HistoricoMissionarioMissaoApostolado>();
    this.salvarMissionarioMissaoAtual =
      this.salvarMissionarioMissaoAtualBuilder.getInstance();
    this.dadosDetalhamentoMissionarioMissaoAtual =
      this.dadosDetalhementoMissionarioMissaoAtualBuilder.getInstance();
  }
  ngOnInit() {
    this.initialization();
  }
  async initialization(): Promise<any> {
    this.carregarMissoes();
    this.carregarFormasVida();
    this.carregarEstadoVida();
    this.carregarNivelFormacao();
    this.carregarTipoRelacionamento();
    this.carregarPaises();
    this.carregarTipoApostolado();
    this.carregarApostolados();
    await this.getUsuarioAtual();
    await this.carregarPessoa();
    await this.carregarDadoVocacional();
    await this.carregarCelibatario();
    await this.carregarRelacionamento();
    await this.carregarSacerdote();
    this.carregarFilhos();
    this.carregarHistoricoMissionarioMissao();
  }
  async carregarMissoes(): Promise<any> {
    try {
      let response = await this.missaoService.getAllAnyStatus();
      this.missoes = response.entity;
      this.missoes = this.missoes.sort((a, b) =>
        a.nome.localeCompare(b.nome, 'fi')
      );
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }
  async carregarCasasComunitarias(missaoId: number): Promise<any> {
    try {
      let response = await this.casaComunitariaService.porMissao(missaoId);
      this.casasComunitarias = response.entity;
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }
  async carregarFormasVida(): Promise<any> {
    let request = {
      grupoNome: 'FORMA_VIDA',
    };
    try {
      let response =
        await this.elementoGrupoDominioService.buscarPorGrupoDominioNome(
          request
        );
      this.formasVida = response.entity;
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }
  async carregarEstadoVida(): Promise<any> {
    let request = {
      grupoNome: 'ESTADO_VIDA',
    };
    try {
      let response =
        await this.elementoGrupoDominioService.buscarPorGrupoDominioNome(
          request
        );
      this.estadosVida = response.entity;
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }
  async carregarDadoVocacional(): Promise<any> {
    try {
      let response = await this.dadoVocacionalService.porPessoaId(
        this.pessoa.id
      );
      if (response.entity != undefined && response.entity != null) {
        this.dadoVocacional = response.entity;
        this.pessoaFormadorPessoalSelecionado =
          this.pessoaInfoBuilder.getInstance();
        this.pessoaFormadorPessoalSelecionado.nome =
          this.dadoVocacional.formadorPessoalPessoaNome;
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }
  async carregarCelibatario(): Promise<any> {
    try {
      let response = await this.celibatarioService.porPessoaId(this.pessoa.id);
      if (response.entity != undefined && response.entity != null) {
        this.celibatario = response.entity;
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }
  async carregarRelacionamento(): Promise<any> {
    try {
      let response = await this.relacionamentoService.porPessoaId(
        this.pessoa.id
      );
      if (response.entity != undefined && response.entity != null) {
        this.relacionamento = response.entity;
        this.pessoaRelacionamentoSelecionada =
          this.pessoaInfoBuilder.getInstance();
        if (this.relacionamento.pessoa1Id == this.pessoa.id) {
          this.pessoaRelacionamentoSelecionada.nome =
            this.relacionamento.pessoa2Nome;
        } else if (this.relacionamento.pessoa2Id == this.pessoa.id) {
          this.pessoaRelacionamentoSelecionada.nome =
            this.relacionamento.pessoa1Nome;
        }
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }
  async carregarSacerdote(): Promise<any> {
    try {
      let response = await this.sacerdoteService.porPessoaId(this.pessoa.id);
      if (response.entity != undefined && response.entity != null) {
        this.sacerdote = response.entity;
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }
  async carregarHistoricoMissionarioMissao(): Promise<any> {
    try {
      let response = await this.historicoMissionarioMissaoService.porPessoaId(
        this.pessoa.id
      );
      if (response.entity != undefined && response.entity != null) {
        this.historicosMissionarioMissao = response.entity;
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }
  async carregarHistoricoMissionarioMissaoApostolado(
    historicoMissionarioMissaoID: number
  ): Promise<any> {
    try {
      let response =
        await this.historicoMissionarioMissaoApostoladoService.findByHistoricoMissionarioMissaoId(
          historicoMissionarioMissaoID
        );
      if (response.entity != undefined && response.entity != null) {
        this.historicosMissionarioMissaoApostolados = response.entity;
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }
  async carregarNivelFormacao(): Promise<any> {
    let request = {
      grupoNome: 'NIVEL_FORMACAO',
    };
    try {
      let response =
        await this.elementoGrupoDominioService.buscarPorGrupoDominioNome(
          request
        );
      this.niveisFormacao = response.entity;
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }
  async carregarTipoRelacionamento(): Promise<any> {
    let request = {
      grupoNome: 'TIPO_RELACIONAMENTO',
    };
    try {
      let response =
        await this.elementoGrupoDominioService.buscarPorGrupoDominioNome(
          request
        );
      this.tiposRelacionamento = response.entity;
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async carregarTipoApostolado(): Promise<any> {
    let request = {
      grupoNome: 'TIPO_APOSTOLADO',
    };
    try {
      let response =
        await this.elementoGrupoDominioService.buscarPorGrupoDominioNome(
          request
        );
      this.tiposApostolado = response.entity;
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async carregarApostolados(): Promise<any> {
    let request = {
      ativo: null,
    };
    try {
      let response = await this.apostoladoService.pesquisar(request);
      this.apostolados = response.entity;
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async carregarApostoladosPorTipo(tipoApostoladoId: number): Promise<any> {
    this.apostoladosSelecionados = this.apostolados.filter(
      (a) => a.tipoId == tipoApostoladoId
    );
  }

  async getUsuarioAtual(): Promise<any> {
    try {
      let response = await this.usuarioService.getCurrentUser();
      this.usuarioAtual = response.entity;
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }
  async carregarPessoa(): Promise<any> {
    try {
      let response = await this.pessoaService.buscaPorUsuarioId(
        this.usuarioAtual.id
      );
      this.pessoa = response.entity;
      this.formaVidaSelecionadaId = this.pessoa.formaVidaId;
      this.estadoVidaSelecionadoId = this.pessoa.estadoVidaId;
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }
  async carregarFilhos(): Promise<any> {
    try {
      let response = null;

      if (this.pessoa.genero == 'masculino') {
        response = await await this.filhoService.porPaiId(this.pessoa.id);
      } else if (this.pessoa.genero == 'feminino') {
        response = await await this.filhoService.porMaeId(this.pessoa.id);
      }

      this.filhos = response.entity;
      if (this.filhos.length > 0) {
        this.hasFilho = true;
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }
  async atualizarFormaVida(): Promise<any> {
    try {
      let request = {
        pessoaId: this.pessoa.id,
        elementoGrupoDominioFormaVidaId: this.formaVidaSelecionadaId,
      };
      let response = await this.pessoaService.atualizarFormaVida(request);
      this.swtAlert2Service.successAlert(response.message);
      await this.carregarPessoa();
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }
  async atualizarEstadoVida(): Promise<any> {
    try {
      let request = {
        pessoaId: this.pessoa.id,
        elementoGrupoDominioEstadoVidaId: this.estadoVidaSelecionadoId,
      };
      let response = await this.pessoaService.atualizarEstadoVida(request);
      this.swtAlert2Service.successAlert(response.message);
      await this.carregarPessoa();
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }
  async atualizarDadoVocacional(): Promise<any> {
    try {
      if (this.dadoVocacional.id == null) {
        this.dadoVocacional.pessoaId = this.pessoa.id;
        let response = await this.dadoVocacionalService.create(
          this.dadoVocacional
        );
        this.dadoVocacional = response.entity;
        this.swtAlert2Service.successAlert(response.message);
      } else {
        let response = await this.dadoVocacionalService.update(
          this.dadoVocacional.id,
          this.dadoVocacional
        );
        this.dadoVocacional = response.entity;
        this.swtAlert2Service.successAlert(response.message);
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }
  async atualizarCelibatario(): Promise<any> {
    try {
      if (this.celibatario.id == null) {
        this.celibatario.pessoaId = this.pessoa.id;
        let response = await this.celibatarioService.create(this.celibatario);
        this.celibatario = response.entity;
        this.swtAlert2Service.successAlert(response.message);
      } else {
        let response = await this.celibatarioService.update(
          this.celibatario.id,
          this.celibatario
        );
        this.celibatario = response.entity;
        this.swtAlert2Service.successAlert(response.message);
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }
  async atualizarRelacionamento(): Promise<any> {
    try {
      if (
        this.relacionamento.pessoa2IsMembro == null ||
        this.relacionamento.tipoRelacionamentoId == null
      ) {
        this.swtAlert2Service.warningAlert(
          'Preencha todos os campos do formulário, incluindo pessoa, tipo de relacionamento e data(s) do relacionamento.'
        );
      } else if (this.relacionamento.id == null) {
        this.relacionamento.pessoa1Id = this.pessoa.id;
        this.relacionamento.pessoa1IsMembro = true;
        let response = await this.relacionamentoService.create(
          this.relacionamento
        );
        this.relacionamento = response.entity;
        this.swtAlert2Service.successAlert(response.message);
      } else {
        let response = await this.relacionamentoService.update(
          this.relacionamento.id,
          this.relacionamento
        );
        this.relacionamento = response.entity;
        this.swtAlert2Service.successAlert(response.message);
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }
  async atualizarSacerdote(): Promise<any> {
    try {
      if (this.sacerdote.id == null) {
        this.sacerdote.pessoaId = this.pessoa.id;
        let response = await this.sacerdoteService.create(this.sacerdote);
        this.sacerdote = response.entity;
        this.swtAlert2Service.successAlert(response.message);
      } else {
        let response = await this.sacerdoteService.update(
          this.sacerdote.id,
          this.sacerdote
        );
        this.sacerdote = response.entity;
        this.swtAlert2Service.successAlert(response.message);
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }
  async carregarPaises(): Promise<any> {
    try {
      let response = await this.paisService.findAll();
      this.paises = response.entity;
    } catch (e) {
      this.swtAlert2Service.errorAlert(e.error.errors);
    }
  }
  async carregarEstados(paisId: number): Promise<any> {
    try {
      let request = {
        paisId: paisId,
        nome: undefined,
        pageNumber: 0,
        quantityOfElements: 1000,
      };
      let response = await this.estadoService.buscarEstados(request);
      this.estados = response.entity;
    } catch (e) {
      this.swtAlert2Service.errorAlert(e.error.errors);
    }
  }
  async carregarCidades(estadoId: number): Promise<any> {
    try {
      let request = {
        estadoId: estadoId,
        nome: undefined,
        pageNumber: 0,
        quantityOfElements: 1000,
      };
      let response = await this.cidadeService.buscarCidades(request);
      this.cidades = response.entity;
    } catch (e) {
      this.swtAlert2Service.errorAlert(e.error.errors);
    }
  }
  async salvarFilho(filho: Filho): Promise<any> {
    try {
      if (this.pessoa.genero == 'masculino') {
        filho.paiId = this.pessoa.id;
      } else if (this.pessoa.genero == 'feminino') {
        filho.maeId = this.pessoa.id;
      }
      if (filho.isMembro == true) {
        filho.filhoId = this.pessoaFilhoSelecionado.id;
      }
      let response = await this.filhoService.create(filho);
      this.swtAlert2Service.successAlert(response.message);
      this.filho = this.filhoBuilder.getInstance();
      this.pessoaPaiSelecionado = this.pessoaInfoBuilder.getInstance();
      this.pessoaMaeSelecionado = this.pessoaInfoBuilder.getInstance();
      this.pessoaFilhoSelecionado = this.pessoaInfoBuilder.getInstance();
      this.maeIsMembro = false;
      this.paiIsMembro = false;
      await this.carregarFilhos();
    } catch (e) {
      this.swtAlert2Service.errorAlert(e.error.errors);
    }
  }
  async salvarHistoricoMissionarioMissao(
    historicoMissionarioMissao: HistoricoMissionarioMissao
  ): Promise<any> {
    try {
      if (historicoMissionarioMissao.id == null) {
        historicoMissionarioMissao.missaoId = this.missaoSelecionadaId;
      }
      let response = null;
      if (historicoMissionarioMissao.id == null) {
        historicoMissionarioMissao.pessoaId = this.pessoa.id;
        response = await this.historicoMissionarioMissaoService.create(
          historicoMissionarioMissao
        );
        this.historicoMissionarioMissao =
          this.historicoMissionarioMissaoBuilder.getInstance();
      } else {
        response = await this.historicoMissionarioMissaoService.update(
          historicoMissionarioMissao.id,
          historicoMissionarioMissao
        );
      }
      this.swtAlert2Service.successAlert(response.message);
      await this.carregarHistoricoMissionarioMissao();
    } catch (e) {
      this.swtAlert2Service.errorAlert(e.error.errors);
    }
  }

  async salvarHistoricoMissionarioMissaoApostolado(
    historicoMissionarioMissaoApostolado: HistoricoMissionarioMissaoApostolado
  ): Promise<any> {
    if (historicoMissionarioMissaoApostolado.apostoladoId != null) {
      if (
        historicoMissionarioMissaoApostolado.duracaoAnos == null ||
        historicoMissionarioMissaoApostolado.duracaoMeses == null
      ) {
        this.swtAlert2Service.warningAlert(
          'Informe a duração de anos e meses. Caso não saiba o período completo, coloque o tempo aproximado.'
        );
      } else {
        try {
          let historicoMissionarioMissaoId =
            historicoMissionarioMissaoApostolado.historicoMissionarioMissaoId;
          let response = null;

          if (historicoMissionarioMissaoApostolado.id != null) {
            response =
              await this.historicoMissionarioMissaoApostoladoService.update(
                historicoMissionarioMissaoApostolado.id,
                historicoMissionarioMissaoApostolado
              );
          } else {
            response =
              await this.historicoMissionarioMissaoApostoladoService.create(
                historicoMissionarioMissaoApostolado
              );
          }

          this.swtAlert2Service.successAlert(response.message);
          await this.carregarHistoricoMissionarioMissaoApostolado(
            historicoMissionarioMissaoId
          );
          this.historicoMissionarioMissaoApostolado =
            this.historicoMissionarioMissaoApostoladoBuilder.getInstance();
          this.historicoMissionarioMissaoApostolado.historicoMissionarioMissaoId =
            historicoMissionarioMissaoId;
        } catch (e) {
          this.swtAlert2Service.errorAlert(e.error.errors);
        }
      }
    } else {
      this.swtAlert2Service.warningAlert(
        'Preencha os dados do formulário antes de salvar o apostolado.'
      );
    }
  }
  async deletarFilho(id: number): Promise<any> {
    if (confirm('Deseja deletar esse filho?')) {
      try {
        let response = await this.filhoService.delete(id);
        this.swtAlert2Service.successAlert(response.message);
        this.carregarFilhos();
      } catch (e) {
        this.swtAlert2Service.errorAlert(e.error.errors);
      }
    }
  }
  async deletarCelibatario(id: number): Promise<any> {
    if (confirm('Deseja deletar os dados do celibato?')) {
      try {
        let response = await this.celibatarioService.delete(id);
        this.swtAlert2Service.successAlert(response.message);
        this.celibatario = this.celibatarioBuilder.getInstance();
        this.carregarCelibatario();
      } catch (e) {
        this.swtAlert2Service.errorAlert(e.error.errors);
      }
    }
  }
  async deletarRelacionamento(id: number): Promise<any> {
    if (confirm('Deseja deletar os dados do relacionamento?')) {
      try {
        let response = await this.relacionamentoService.delete(id);
        this.swtAlert2Service.successAlert(response.message);
        this.relacionamento = this.relacionamentoBuilder.getInstance();
        this.pessoaRelacionamentoSelecionada =
          this.pessoaInfoBuilder.getInstance();
        this.carregarRelacionamento();
      } catch (e) {
        this.swtAlert2Service.errorAlert(e.error.errors);
      }
    }
  }
  async deletarSacerdote(id: number): Promise<any> {
    if (confirm('Deseja deletar os dados do sacerdócio?')) {
      try {
        let response = await this.sacerdoteService.delete(id);
        this.swtAlert2Service.successAlert(response.message);
        this.sacerdote = this.sacerdoteBuilder.getInstance();
        this.carregarSacerdote();
      } catch (e) {
        this.swtAlert2Service.errorAlert(e.error.errors);
      }
    }
  }
  async deletarHistoricoMissionarioMissao(id: number): Promise<any> {
    if (confirm('Deseja deletar os dados de histórico na missão?')) {
      try {
        let response = await this.historicoMissionarioMissaoService.delete(id);
        this.swtAlert2Service.successAlert(response.message);
        this.carregarHistoricoMissionarioMissao();
      } catch (e) {
        this.swtAlert2Service.errorAlert(e.error.errors);
      }
    }
  }
  async deletarHistoricoMissionarioMissaoApostolado(
    id: number,
    historicoMissionarioMissaoId: number
  ): Promise<any> {
    if (
      confirm(
        'Deseja deletar os dados de histórico deste apostolado na missão?'
      )
    ) {
      try {
        let response =
          await this.historicoMissionarioMissaoApostoladoService.delete(id);
        this.swtAlert2Service.successAlert(response.message);
        this.carregarHistoricoMissionarioMissaoApostolado(
          historicoMissionarioMissaoId
        );
      } catch (e) {
        this.swtAlert2Service.errorAlert(e.error.errors);
      }
    }
  }

  async selecionarPai(pessoa: PessoaInfo): Promise<any> {
    this.filho.paiId = pessoa.id;
    this.pessoaPaiSelecionado = pessoa;
    $('#listagem-pessoa-pai').modal('hide');
  }

  async selecionarMae(pessoa: PessoaInfo): Promise<any> {
    this.filho.maeId = pessoa.id;
    this.pessoaMaeSelecionado = pessoa;
    $('#listagem-pessoa-mae').modal('hide');
  }

  async selecionarFilho(pessoa: PessoaInfo): Promise<any> {
    this.filho.filhoId = pessoa.id;
    this.pessoaFilhoSelecionado = pessoa;
    $('#listagem-pessoa-filho').modal('hide');
  }
  async selecionarFormadorPessoal(pessoa: PessoaInfo): Promise<any> {
    this.dadoVocacional.formadorPessoalPessoaId = pessoa.id;
    this.pessoaFormadorPessoalSelecionado = pessoa;
    $('#listagem-pessoa-formador-pessoal').modal('hide');
  }
  async selecionarPessoaRelacionamento(pessoa: PessoaInfo): Promise<any> {
    this.relacionamento.pessoa2Id = pessoa.id;
    this.relacionamento.pessoa2IsMembro = true;
    this.pessoaRelacionamentoSelecionada = pessoa;
    $('#listagem-pessoa-relacionamento').modal('hide');
  }
  async configModalHistoricoApostolado(
    historicoMissionarioMissaoId: number
  ): Promise<any> {
    this.historicoMissionarioMissaoApostolado.historicoMissionarioMissaoId =
      historicoMissionarioMissaoId;
    this.carregarHistoricoMissionarioMissaoApostolado(
      historicoMissionarioMissaoId
    );
  }
}
