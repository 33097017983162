import { GenericService } from 'src/app/core/services/generic.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from "@angular/core";
import { Imagem } from 'src/app/model/imagem.model';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Injectable()
export class ImagemService extends GenericService<Imagem> {
    
    constructor(
        protected http: HttpClient, 
        protected router:Router,
        protected sanitizer: DomSanitizer) {
        super(http,router);
        this.setEntityType('imagem');
    }

    salvarCropped(imageData: any) : Promise<any> {
        return this.http.post<any>(environment.configServidor.wopApiUrl+'imagem'+'/cropped/salvar', JSON.stringify(imageData), { 'headers' : this.getHeaders() }).toPromise();
    }

    getPessoaPhoto(id: number) : Promise<any> {

        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+ (this.token ? this.token.access_token : '')  
            }),
    
            // Ignore this part or  if you want full response you have 
            // to explicitly give as 'boby'as http client by default give res.json()
            observe:'response' as 'body',
    
           // have to explicitly give as 'blob' or 'json'
            responseType: 'blob' as 'blob'  
        };


        return this.http.get(environment.imagem.urlBaseImagemRemote + id, options).toPromise();
        
    }

    createImage(image: Blob, pessoa: any) : any {
        if (image && image.size > 0) {
          let reader = new FileReader();
    
          reader.addEventListener("load", () => {
            let urlStringImage:any = reader.result;
            pessoa.thumbnail = this.sanitizer.bypassSecurityTrustUrl(urlStringImage.replace("text/xml","image/png"));
          }, false);
    
          reader.readAsDataURL(image);
        }
    }

}