import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MissaoConfiguracao } from './missao-config.service';

@Injectable({
  providedIn: 'root',
})
export class MissaoConfiguracaoSharedDataService {
  missaoConfiguracao$ = new BehaviorSubject<MissaoConfiguracao | null>(null);

  constructor() {}

  updateMissaoConfiguracao = (missaoConfiguracao: MissaoConfiguracao) => {
    this.missaoConfiguracao$.next(missaoConfiguracao);
  };
}
