export class DadosDetalhamentoMissionarioMissaoApostoladoAtual {

    constructor(
        public id: number,
        public missionarioMissaoAtualId: number,
        public apostoladoId: number,
        public apostoladoNome: string,
        public dataInicio: Date,
        public dataFim: Date,
        public isInterino: boolean,
        public isEditing: boolean
    ){}

}