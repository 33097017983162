import { Component, OnInit, ViewChild } from "@angular/core";
import { Missao } from 'src/app/model/missao.model';
import { Pais } from 'src/app/model/pais.model';
import { Estado } from 'src/app/model/estado.model';
import { Cidade } from 'src/app/model/cidade.model';
import { PessoaService } from './service/pessoa.service';
import { MissaoService } from '../missao/service/missao.service';
import { PaisService } from '../pais/service/pais.service';
import { Pessoa } from 'src/app/model/pessoa.model';
import { ElementoGrupoDominio } from 'src/app/model/elementogrupodominio.model';
import { ElementoGrupoDominioService } from '../grupodominio/service/elementogrupodominio.service';

import { IgxTabsComponent } from 'igniteui-angular';
import { Router, ActivatedRoute } from '@angular/router';
import { PessoaBuilder } from 'src/app/builder/pessoa.builder';
import { SwtAlert2Service } from "src/app/core/swtalert2/swtalert2.service";
import { UsuarioService } from "../usuario/service/usuario.service";
import { Usuario } from "src/app/model/usuario.model";
import { environment } from "src/environments/environment";



 @Component({
    selector: 'pessoa',
    templateUrl: './pessoa.component.html'
 })

 export class PessoaComponent implements OnInit{

   
    public pessoa: Pessoa;
    public missoes: Array<Missao>;
    public paises: Array<Pais>;
    public estados: Array<Estado>;
    public cidades: Array<Cidade>;
    public estadosCivil: Array<ElementoGrupoDominio>;
    public pessoaBuilder: PessoaBuilder;
    public usuarioAtual: Usuario;
    public usuariosPorUsuarioCriador: Array<Usuario>;
    public urlBaseImagem: string;

    public tab: IgxTabsComponent;
    public tabNumber: number;
    public url: string;

    public tipoIdentidade: Array<ElementoGrupoDominio>;
    

    

    constructor(
        public pessoaService: PessoaService,
        public missaoService: MissaoService,
        public paisService: PaisService,
        public elementoGrupoDominioService: ElementoGrupoDominioService,
        public swtAlert2Service: SwtAlert2Service,
        public router: Router,
        public usuarioService: UsuarioService
    ){
        this.missoes = new Array<Missao>();
        this.paises = new Array<Pais>();
        this.estados = new Array<Estado>();
        this.cidades = new Array<Cidade>();
        this.estadosCivil = new Array<ElementoGrupoDominio>();
        this.tipoIdentidade = new Array<ElementoGrupoDominio>();
        this.usuariosPorUsuarioCriador = new Array<Usuario>();
        this.pessoaBuilder = new PessoaBuilder();
        this.pessoa = this.pessoaBuilder.getInstance();
        this.urlBaseImagem = environment.imagem.urlBaseImagem
    }

    ngOnInit(): void {
        this.getUsuarioAtual();
        this.carregarPaises(); 
        this.carregarEstadosCivil();
        this.url = environment.usuario.selectPorUsername;
        
    }

    async getUsuarioAtual() : Promise<any> {

        try 
        {
            let response = await this.usuarioService.getCurrentUser();   
            this.usuarioAtual = response.entity;      
            this.getUsuariosPorUsuarioCriador();  
        }
        catch(e)
        {
            console.log(e);
        }

    }

    async getUsuariosPorUsuarioCriador() : Promise<any> {

        try 
        {
            let response = await this.usuarioService.getUsuariosPorUsuarioCriador();   
            this.usuariosPorUsuarioCriador = response.entity;        
        }
        catch(e)
        {
            console.log(e);
        }

    }

    carregarPaises() : void {
        this.paisService.findAll().then( (lista: any) => {
            this.paises = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    carregarEstadosCivil() : void 
    {
        let buscaEstadosCivil = {
            grupoNome : 'ESTADO_CIVIL'
        }

        this.elementoGrupoDominioService.buscarPorGrupoDominioNome(buscaEstadosCivil).then( (lista: any) => {
            this.estadosCivil = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });

    }
    
    carregarMissoes(): void {
        this.missaoService.findAll().then((lista: any) => {
            this.missoes = lista.entity;   
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }


    salvar(pessoa: Pessoa) : void {

        
        pessoa.dataNascimento = new Date(pessoa.dataNascimento);

        this.pessoaService.create(pessoa).then( (response:any) => {
            this.pessoa = this.pessoaBuilder.getInstance();
            this.swtAlert2Service.successAlert( response.message);
            setTimeout(() => {
                this.router.navigate(['../secured/pessoa/'+response.entity.id]);
            },2000); 
        }).catch( (err:any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }
    
    setarElementoSelecionado(valor) {
        this.pessoa.usuarioId = valor;
    }

 }