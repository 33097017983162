import { Component, OnInit } from '@angular/core';
import { MissaoService } from './service/missao.service';
import { Missao } from 'src/app/model/missao.model';
import { Alerta } from 'src/app/model/alerta.model';
import { Regional } from 'src/app/model/regional.model';
import { RegionalService } from '../regional/service/regional.service';
import { ElementoGrupoDominioService } from '../grupodominio/service/elementogrupodominio.service';
import { ElementoGrupoDominio } from 'src/app/model/elementogrupodominio.model';
import { PaisService } from '../pais/service/pais.service';
import { EstadoService } from '../estado/service/estado.service';
import { CidadeService } from '../cidade/service/cidade.service';
import { Pais } from 'src/app/model/pais.model';
import { Cidade } from 'src/app/model/cidade.model';
import { Estado } from 'src/app/model/estado.model';
import { MissaoBuilder } from 'src/app/builder/missao.builder';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';

@Component({
    selector: 'missao',
    templateUrl: './missao.component.html'
})
export class MissaoComponent implements OnInit {

    public missao: Missao;
    public regionais: Array<Regional>;
    public paises: Array<Pais>;
    public estados: Array<Estado>;
    public cidades: Array<Cidade>;
    public paisSelecionadoId: number;
    public estadoSelecionadoId: number;
    public fasesMissao: Array<ElementoGrupoDominio>;
    public statusMissao: Array<ElementoGrupoDominio>;
    public missaoBuilder: MissaoBuilder;

    constructor(
        public missaoService: MissaoService,
        public regionalService: RegionalService,
        public elementoGrupoDominioService: ElementoGrupoDominioService,
        public paisService: PaisService,
        public estadoService: EstadoService,
        public cidadeService: CidadeService,
        public swtAlert2Service: SwtAlert2Service
    ) 
    {
        this.missaoBuilder = new MissaoBuilder();
        this.missao = this.missaoBuilder.getInstance();
        this.regionais = new Array<Regional>();
        this.paisSelecionadoId = null;
        this.estadoSelecionadoId = null;
        this.paises = new Array<Pais>();
        this.estados = new Array<Estado>();
        this.cidades = new Array<Cidade>();
        this.fasesMissao = new Array<ElementoGrupoDominio>(); 
        this.statusMissao = new Array<ElementoGrupoDominio>();        
    }

    ngOnInit() {  
        this.carregarRegionais();   
        this.carregarFasesMissao();  
        this.carregarStatusMissao(); 
        this.carregarPaises(); 
    }

    carregarRegionais() : void {
        this.regionalService.findAll().then( (lista: any) => {
            this.regionais = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    carregarFasesMissao() : void {

        let buscaFase = {
            grupoNome : 'FASE_MISSAO'
        }

        this.elementoGrupoDominioService.buscarPorGrupoDominioNome(buscaFase).then( (lista: any) => {
            this.fasesMissao = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    async carregarStatusMissao() : Promise<null> {

        try{
            let request = {
                grupoNome : 'MISSAO_STATUS'
            }
            let response = await this.elementoGrupoDominioService.buscarPorGrupoDominioNome(request);
            this.statusMissao = response.entity;

        }
        catch(err){
            this.swtAlert2Service.errorAlert(err.error.errors);
        }

        return null;
    }

    carregarPaises() : void {
        this.paisService.findAll().then( (lista: any) => {
            this.paises = lista.entity;
        }).catch( (err: any) => {
            console.log(err);
        });
    }

    carregarEstados() : void {

        let busca = {
            paisId: this.paisSelecionadoId,
            nome: undefined,
            pageNumber : 0, 
            quantityOfElements : 1000
        };

        this.estadoService.buscarEstados(busca).then( (lista: any) => {
            this.estados = lista.entity;
        }).catch( (err: any) => {
            console.log(err);
        });

    }

    carregarCidades() : void {

        let busca = {
            estadoId: this.estadoSelecionadoId,
            nome: undefined,
            pageNumber : 0, 
            quantityOfElements : 1000
        };

        this.cidadeService.buscarCidades(busca).then( (lista: any) => {
            this.cidades = lista.entity;
        }).catch( (err: any) => {
            console.log(err);
        });

    }

    salvar(missao: Missao) : void {

        missao.dataFundacao = new Date(missao.dataFundacao);

        this.missaoService.create(missao).then( (response:any) => {
            this.missao = this.missaoBuilder.getInstance();
            this.paisSelecionadoId = null;
            this.estadoSelecionadoId = null;
            this.swtAlert2Service.successAlert(response.message);

        }).catch( (err:any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

}