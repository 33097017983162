import { Injectable } from '@angular/core';
import { AbstractBuilder } from './abstract.builder';
import { DadoFormandoCV } from '../model/dadoformandocv.model';

@Injectable()
export class DadoFormandoCVBuilder extends AbstractBuilder<DadoFormandoCV> {

    reset() : void {
        this.entity = new DadoFormandoCV(null,null,null,null,null,null,null);
    }

}