import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
export class QuestionarioD2AnswerComponent {
    constructor(cadastroPublicoLocalServce, pessoaService, imagemService, paisService, estadoService, cidadeService, elementoGrupoDominioService, elementoGrupoDominioLocalService, missaoService, swtAlert2Service, usuarioService, pessoaBuilder, pessoaInfoBuilder, dadoVocacionalService, dadoFormandoCVService, caminhoFormativoService, mensagemService, respostaQuestionarioService, questionarioFormandoService, dadoFormandoCVBuilder, caminhoFormativoBuilder, mensagemBuilder, respostaQuestionarioBuilder, questionarioFormandoBuilder, route, router) {
        this.cadastroPublicoLocalServce = cadastroPublicoLocalServce;
        this.pessoaService = pessoaService;
        this.imagemService = imagemService;
        this.paisService = paisService;
        this.estadoService = estadoService;
        this.cidadeService = cidadeService;
        this.elementoGrupoDominioService = elementoGrupoDominioService;
        this.elementoGrupoDominioLocalService = elementoGrupoDominioLocalService;
        this.missaoService = missaoService;
        this.swtAlert2Service = swtAlert2Service;
        this.usuarioService = usuarioService;
        this.pessoaBuilder = pessoaBuilder;
        this.pessoaInfoBuilder = pessoaInfoBuilder;
        this.dadoVocacionalService = dadoVocacionalService;
        this.dadoFormandoCVService = dadoFormandoCVService;
        this.caminhoFormativoService = caminhoFormativoService;
        this.mensagemService = mensagemService;
        this.respostaQuestionarioService = respostaQuestionarioService;
        this.questionarioFormandoService = questionarioFormandoService;
        this.dadoFormandoCVBuilder = dadoFormandoCVBuilder;
        this.caminhoFormativoBuilder = caminhoFormativoBuilder;
        this.mensagemBuilder = mensagemBuilder;
        this.respostaQuestionarioBuilder = respostaQuestionarioBuilder;
        this.questionarioFormandoBuilder = questionarioFormandoBuilder;
        this.route = route;
        this.router = router;
        this.intlTelInputCalled = false;
        this.missoesVinculo = new Array();
        this.missoes = new Array();
        this.formasVida = new Array();
        this.niveisFormacao = new Array();
        this.estadosVida = new Array();
        this.pessoaFormadorPessoalSelecionado = this.pessoaInfoBuilder.getInstance();
        this.pessoaFormadorComunitarioSelecionado = this.pessoaInfoBuilder.getInstance();
        this.dadoFormandoCV = this.dadoFormandoCVBuilder.getInstance();
        this.caminhoFormativo = this.caminhoFormativoBuilder.getInstance();
        this.mensagemAF = this.mensagemBuilder.getInstance();
        this.mensagemRL = this.mensagemBuilder.getInstance();
        this.imageChangedEvent = '';
        this.croppedImage = '';
        this.nextClicked = false;
        this.pessoa = this.pessoaBuilder.getInstance();
        this.urlBaseImagem = environment.imagem.urlBaseImagem;
        this.url = environment.usuario.selectPorUsername;
        this.questionarioFormando = this.questionarioFormandoBuilder.getInstance();
        this.tabNames = [
            'dados-pessoais-tab-content',
            'questionario-tab-content'
        ];
        this.activeTabNumber = 0;
        this.resposta1 = respostaQuestionarioBuilder.getInstance();
        this.resposta2 = respostaQuestionarioBuilder.getInstance();
        this.resposta3 = respostaQuestionarioBuilder.getInstance();
        this.resposta4 = respostaQuestionarioBuilder.getInstance();
        this.resposta5 = respostaQuestionarioBuilder.getInstance();
        this.resposta6 = respostaQuestionarioBuilder.getInstance();
        this.resposta7 = respostaQuestionarioBuilder.getInstance();
        this.resposta8 = respostaQuestionarioBuilder.getInstance();
        this.resposta9 = respostaQuestionarioBuilder.getInstance();
        this.resposta10 = respostaQuestionarioBuilder.getInstance();
        this.resposta11 = respostaQuestionarioBuilder.getInstance();
        this.resposta12 = respostaQuestionarioBuilder.getInstance();
        this.resposta13 = respostaQuestionarioBuilder.getInstance();
        this.resposta14 = respostaQuestionarioBuilder.getInstance();
        this.resposta15 = respostaQuestionarioBuilder.getInstance();
        this.resposta16 = respostaQuestionarioBuilder.getInstance();
        this.resposta17 = respostaQuestionarioBuilder.getInstance();
        this.resposta18 = respostaQuestionarioBuilder.getInstance();
        this.resposta19 = respostaQuestionarioBuilder.getInstance();
        this.resposta20 = respostaQuestionarioBuilder.getInstance();
    }
    ngOnInit() {
        this.initialization();
    }
    initialization() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.getUsuarioAtual();
            this.carregarPaises();
            this.carregarFormasVida();
            this.carregarNiveisFormacao();
            this.carregarEstadoVida();
            yield this.carregarMissoesVinculo();
            yield this.carregarPessoa();
            this.questionarioFormandoId = Number(this.route.snapshot.paramMap.get('id'));
            yield this.carregarDadoVocacional();
            yield this.carregarDadoFormandoCV();
            yield this.carregarCaminhoFormativo();
            yield this.carregarQuestionarioFormando();
            yield this.carregarRespostas();
        });
    }
    getUsuarioAtual() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.usuarioService.getCurrentUser();
                this.usuarioAtual = response.entity;
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    carregarMissoesVinculo() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.cadastroPublicoLocalServce.getAllMissao();
                this.missoesVinculo = response.entity;
                this.missoesVinculo = this.missoesVinculo.sort((a, b) => a.nome.localeCompare(b.nome));
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    carregarPessoa() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.pessoaService.buscaPorUsuarioId(this.usuarioAtual.id);
                this.pessoa = response.entity;
                this.pessoaId = this.pessoa.id;
                if (this.pessoa.imagemId != null) {
                    let blogImage = yield this.getPessoaImagem(this.pessoa.imagemId);
                    this.imagemService.createImage(blogImage, this.pessoa);
                }
                if (this.pessoa.formaVidaId != null) {
                    this.setarFormaVidaSelecionada();
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarFormasVida() {
        let buscaFormaVida = {
            grupoNome: 'FORMA_VIDA'
        };
        this.elementoGrupoDominioService.buscarPorGrupoDominioNome(buscaFormaVida).then((lista) => {
            this.formasVida = lista.entity;
        }).catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }
    carregarNiveisFormacao() {
        let busca = {
            grupoNome: 'NIVEL_FORMACAO'
        };
        this.elementoGrupoDominioService.buscarPorGrupoDominioNome(busca).then((lista) => {
            this.niveisFormacao = lista.entity;
        }).catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }
    carregarEstadoVida() {
        let busca = {
            grupoNome: 'ESTADO_VIDA'
        };
        this.elementoGrupoDominioService.buscarPorGrupoDominioNome(busca).then((lista) => {
            this.estadosVida = lista.entity;
        }).catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }
    carregarPaises() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.paisService.findAll();
                this.paises = response.entity;
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    carregarEstados(paisId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let request = {
                    paisId: paisId,
                    nome: undefined,
                    pageNumber: 0,
                    quantityOfElements: 1000
                };
                let response = yield this.estadoService.buscarEstados(request);
                this.estados = response.entity;
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    carregarCidades(estadoId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let request = {
                    estadoId: estadoId,
                    nome: undefined,
                    pageNumber: 0,
                    quantityOfElements: 1000
                };
                let response = yield this.cidadeService.buscarCidades(request);
                this.cidades = response.entity;
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    logOnConsole(dadosPessoaisForm) {
        console.log(dadosPessoaisForm);
    }
    activateTab(tab) {
        $('.nav-tabs a[href="#' + tab + '"]').removeClass('disabled');
        $('.nav-tabs a[href="#' + tab + '"]').tab('show');
    }
    activateTab2(action) {
        if (action.localeCompare('previous') == 0) {
            if (this.activeTabNumber > 0) {
                this.activeTabNumber -= 1;
            }
        }
        else if (action.localeCompare('next') == 0) {
            if (this.activeTabNumber < this.tabNames.length - 1) {
                this.activeTabNumber += 1;
            }
        }
        $('.nav-tabs a[href="#' + this.tabNames[this.activeTabNumber] + '"]').removeClass('disabled');
        $('.nav-tabs a[href="#' + this.tabNames[this.activeTabNumber] + '"]').tab('show');
    }
    setarElementoSelecionado(valor) {
        this.pessoa.usuarioId = valor;
    }
    salvar(pessoa) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.pessoaService.update(pessoa.id, pessoa);
                this.pessoa = response.entity;
                if (this.pessoa.imagemId != null) {
                    let blogImage = yield this.getPessoaImagem(this.pessoa.imagemId);
                    this.imagemService.createImage(blogImage, this.pessoa);
                }
                this.swtAlert2Service.successAlert(response.message);
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
            this.atualizarDadoFormandoCV();
            this.atualizarCaminhoFormativo();
            this.atualizarQuestionarioFormando('INICIADO');
        });
    }
    salvarImagem() {
        this.imagemService.salvarCropped({ 'imageData': this.croppedImage }).then((response) => {
            this.swtAlert2Service.successAlert(response.message);
            this.pessoa.imagemId = response.entity.id;
            this.salvar(this.pessoa);
            $('#modal-pessoa-imagem').modal('hide');
        }).catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }
    fileChangeEvent(event) {
        this.imageChangedEvent = event;
        console.log(event);
    }
    imageCropped(event) {
        this.croppedImage = event.base64;
    }
    imageLoaded() {
        console.log('Imagem carregada');
    }
    cropperReady() {
        console.log('Imagem cortada');
    }
    loadImageFailed() {
        console.log('Carregamento da imagem falhou!');
    }
    setarFormaVidaSelecionada() {
        for (let i = 0; i < this.formasVida.length; i++) {
            if (this.formasVida[i].id == this.pessoa.formaVidaId) {
                this.formaVidaSelecionada = this.formasVida[i];
            }
        }
    }
    carregarDadoVocacional() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.dadoVocacionalService.porPessoaId(this.pessoa.id);
                if (response.entity != undefined && response.entity != null) {
                    this.dadoVocacional = response.entity;
                    this.pessoaFormadorPessoalSelecionado = this.pessoaInfoBuilder.getInstance();
                    this.pessoaFormadorPessoalSelecionado.nome = this.dadoVocacional.formadorPessoalPessoaNome;
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    selecionarFormadorPessoal(pessoa) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.dadoVocacional.formadorPessoalPessoaId = pessoa.id;
            this.pessoaFormadorPessoalSelecionado = pessoa;
            $('#listagem-pessoa-formador-pessoal').modal('hide');
        });
    }
    selecionarFormadorComunitario(pessoa) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            //this.dadoVocacional.formadorPessoalPessoaId = pessoa.id;
            this.pessoaFormadorComunitarioSelecionado = pessoa;
            $('#listagem-pessoa-formador-comunitario').modal('hide');
        });
    }
    getPessoaImagem(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.imagemService.getPessoaPhoto(id);
                return response.body;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarDadoFormandoCV() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.dadoFormandoCVService.porPessoaId(this.pessoa.id);
                if (response != null && response != undefined) {
                    this.dadoFormandoCV = response;
                    this.carregarEstados(this.dadoFormandoCV.pais_origem_id);
                    this.carregarCidades(this.dadoFormandoCV.estado_origem_id);
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarCaminhoFormativo() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.caminhoFormativoService.porPessoaId(this.pessoa.id);
                if (response != null && response != undefined) {
                    this.caminhoFormativo = response;
                    console.log(this.caminhoFormativo);
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    atualizarCaminhoFormativo() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.caminhoFormativo.missao_atual_id = this.pessoa.vinculoMissao.missaoAtualId;
                this.caminhoFormativo.nome_formador_pessoal = this.pessoaFormadorPessoalSelecionado.nome;
                if (this.caminhoFormativo.id == null) {
                    this.caminhoFormativo.pessoaId = this.pessoa.id;
                    let response = yield this.caminhoFormativoService.cadastrar(this.caminhoFormativo);
                    this.caminhoFormativo = response.entity;
                    this.swtAlert2Service.successAlert(response.message);
                }
                else {
                    let response = yield this.caminhoFormativoService.atualizar(this.caminhoFormativo);
                    this.caminhoFormativo = response.entity;
                    this.swtAlert2Service.successAlert(response.message);
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarRespostas() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.respostaQuestionarioService.porPessoaId(this.pessoa.id);
                if (response != null && response != undefined) {
                    this.respostas = response.content;
                    console.log(this.respostas);
                    this.respostas.forEach((resposta) => {
                        switch (resposta.questionario_pergunta_id) {
                            case 1:
                                this.resposta1 = resposta;
                            case 2:
                                this.resposta2 = resposta;
                            case 3:
                                this.resposta3 = resposta;
                            case 4:
                                this.resposta4 = resposta;
                            case 5:
                                this.resposta5 = resposta;
                            case 6:
                                this.resposta6 = resposta;
                            case 7:
                                this.resposta7 = resposta;
                            case 8:
                                this.resposta8 = resposta;
                            case 9:
                                this.resposta9 = resposta;
                            case 10:
                                this.resposta10 = resposta;
                            case 11:
                                this.resposta11 = resposta;
                            case 12:
                                this.resposta12 = resposta;
                            case 13:
                                this.resposta13 = resposta;
                            case 14:
                                this.resposta14 = resposta;
                            case 15:
                                this.resposta15 = resposta;
                            case 16:
                                this.resposta16 = resposta;
                            case 17:
                                this.resposta17 = resposta;
                            case 18:
                                this.resposta18 = resposta;
                            case 19:
                                this.resposta19 = resposta;
                            case 20:
                                this.resposta20 = resposta;
                        }
                    });
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarQuestionarioFormando() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.questionarioFormandoService.buscarPorId(this.questionarioFormandoId);
                if (response != null && response != undefined) {
                    this.questionarioFormando = response;
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarMensagemAF() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.dadoFormandoCVService.porPessoaId(this.pessoa.id);
                if (response != null && response != undefined) {
                    this.dadoFormandoCV = response;
                    this.carregarEstados(this.dadoFormandoCV.pais_origem_id);
                    this.carregarCidades(this.dadoFormandoCV.estado_origem_id);
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarMensagemRL() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.dadoFormandoCVService.porPessoaId(this.pessoa.id);
                if (response != null && response != undefined) {
                    this.dadoFormandoCV = response;
                    this.carregarEstados(this.dadoFormandoCV.pais_origem_id);
                    this.carregarCidades(this.dadoFormandoCV.estado_origem_id);
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    atualizarDadoFormandoCV() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (this.dadoFormandoCV.id == null) {
                    this.dadoFormandoCV.pessoaId = this.pessoa.id;
                    console.log(this.dadoFormandoCV);
                    let response = yield this.dadoFormandoCVService.cadastrar(this.dadoFormandoCV);
                    this.dadoFormandoCV = response;
                    this.swtAlert2Service.successAlert(response.message);
                }
                else {
                    let response = yield this.dadoFormandoCVService.atualizar(this.dadoFormandoCV);
                    this.dadoFormandoCV = response;
                    this.swtAlert2Service.successAlert(response.message);
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    atualizarResposta(resposta) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                resposta.pessoaId = this.pessoa.id;
                if (resposta.id == null) {
                    console.log(resposta);
                    let response = yield this.respostaQuestionarioService.cadastrar(resposta);
                    resposta = response;
                    this.swtAlert2Service.successAlert(response.message);
                }
                else {
                    let response = yield this.respostaQuestionarioService.atualizar(resposta.id, resposta);
                    resposta = response;
                    this.swtAlert2Service.successAlert(response.message);
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    atualizarMensagemAF() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (this.mensagemAF.id == null) {
                    this.mensagemAF.origem = 1;
                    this.mensagemAF.origem_id = this.questionarioFormandoId;
                    this.mensagemAF.lida = 0;
                    let response = yield this.mensagemService.cadastrarMensagemAF(this.mensagemAF);
                    this.mensagemAF = response;
                    this.swtAlert2Service.successAlert(response.message);
                }
                else {
                    let response = yield this.mensagemService.atualizarMensagemAF(this.mensagemAF);
                    this.mensagemAF = response;
                    this.swtAlert2Service.successAlert(response.message);
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    atualizarMensagemRL() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (this.mensagemRL.id == null) {
                    this.mensagemRL.origem = 1;
                    this.mensagemRL.origem_id = this.questionarioFormandoId;
                    this.mensagemRL.lida = 0;
                    let response = yield this.mensagemService.cadastrarMensagemRL(this.mensagemRL);
                    this.mensagemRL = response;
                    this.swtAlert2Service.successAlert(response.message);
                }
                else {
                    let response = yield this.mensagemService.atualizarMensagemRL(this.mensagemRL);
                    this.mensagemRL = response;
                    this.swtAlert2Service.successAlert(response.message);
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    salvarQuestionario() {
        this.resposta1.questionario_pergunta_id = 1;
        this.atualizarResposta(this.resposta1);
        this.resposta2.questionario_pergunta_id = 2;
        this.atualizarResposta(this.resposta2);
        this.resposta3.questionario_pergunta_id = 3;
        this.atualizarResposta(this.resposta3);
        this.resposta4.questionario_pergunta_id = 4;
        this.atualizarResposta(this.resposta4);
        this.resposta5.questionario_pergunta_id = 5;
        this.atualizarResposta(this.resposta5);
        this.resposta6.questionario_pergunta_id = 6;
        this.atualizarResposta(this.resposta6);
        this.resposta7.questionario_pergunta_id = 7;
        this.atualizarResposta(this.resposta7);
        this.resposta8.questionario_pergunta_id = 8;
        this.atualizarResposta(this.resposta8);
        this.resposta9.questionario_pergunta_id = 9;
        this.atualizarResposta(this.resposta9);
        this.resposta10.questionario_pergunta_id = 10;
        this.atualizarResposta(this.resposta10);
        this.resposta11.questionario_pergunta_id = 11;
        this.atualizarResposta(this.resposta11);
        this.resposta12.questionario_pergunta_id = 12;
        this.atualizarResposta(this.resposta12);
        this.resposta13.questionario_pergunta_id = 13;
        this.atualizarResposta(this.resposta13);
        this.resposta14.questionario_pergunta_id = 14;
        this.atualizarResposta(this.resposta14);
        this.resposta15.questionario_pergunta_id = 15;
        this.atualizarResposta(this.resposta15);
        this.resposta16.questionario_pergunta_id = 16;
        this.atualizarResposta(this.resposta16);
        this.resposta17.questionario_pergunta_id = 17;
        this.atualizarResposta(this.resposta17);
        this.resposta18.questionario_pergunta_id = 18;
        this.atualizarResposta(this.resposta18);
        this.resposta19.questionario_pergunta_id = 19;
        this.atualizarResposta(this.resposta19);
        this.resposta20.questionario_pergunta_id = 20;
        this.atualizarResposta(this.resposta20);
        if (this.mensagemAF.mensagem != null)
            this.atualizarMensagemAF();
        if (this.mensagemRL.mensagem != null)
            this.atualizarMensagemRL();
        this.atualizarQuestionarioFormando('INICIADO');
    }
    enviarQuestionario() {
        this.salvarQuestionario();
        this.atualizarQuestionarioFormando('ENVIADO');
        this.router.navigate(['secured/questionario-agradecimento']);
    }
    atualizarQuestionarioFormando(status) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (this.questionarioFormando.id != null) {
                    this.questionarioFormando.status = status;
                    if (status == 'ENVIADO') {
                        this.questionarioFormando.data_envio = new Date();
                    }
                    let response = yield this.questionarioFormandoService.atualizar(this.questionarioFormando.id, this.questionarioFormando);
                    this.questionarioFormando = response;
                    this.swtAlert2Service.successAlert(response.message);
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
}
