import { Injectable } from '@angular/core';
import { Filho } from '../model/filho.model';
import { AbstractBuilder } from './abstract.builder';

@Injectable()
export class FilhoBuilder extends AbstractBuilder<Filho> {

    reset() : void {
        this.entity = new Filho(null,null,null,false,null,null,null,null,null,null,null);
    }

}