import { Component, OnDestroy, OnInit } from '@angular/core';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { Pessoa } from 'src/app/model/pessoa.model';
import { LoggedInUserSharedDataService } from 'src/app/secured/layout/header/service/logged-in-user-shared-data.service';
import {
  MissaoConfigService,
  MissaoConfiguracao,
} from '../../service/missao-config.service';
import { MissaoConfiguracaoSharedDataService } from '../../service/missao-configuracao-shared-data.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-missao-config-form-component',
  templateUrl: './missao-config-form-component.component.html',
  styleUrls: ['./missao-config-form-component.component.css'],
})
export class MissaoConfigFormComponentComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject<null>();
  missaoId?: number;
  hasColegiado: boolean = false;
  hasComissaoDiscernimento: boolean = false;
  missaoConfiguracao: MissaoConfiguracao = {
    id: null,
    missaoId: null,
    hasColegiado: false,
    hasComissaoDiscernimento: false,
  };

  constructor(
    private loggedInUserSharedDataService: LoggedInUserSharedDataService,
    private missaoConfiguracaoService: MissaoConfigService,
    private missaoConfiguracaoSharedDataService: MissaoConfiguracaoSharedDataService,
    private swtAlert: SwtAlert2Service
  ) {}

  ngOnInit() {
    this.loggedInUserSharedDataService.loggedInPerson$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((person: Pessoa) => {
        if (person) {
          this.missaoId = person.vinculoMissao.missaoAtualId;
          if (this.missaoId) {
            this.missaoConfiguracao.missaoId = this.missaoId;
            this.carregarMissaoConfiguracao();
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  carregarMissaoConfiguracao() {
    this.missaoConfiguracaoService
      .obterPorResponsavelLocal()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (missaoConfiguracao) => {
          this.missaoConfiguracao = missaoConfiguracao;
          this.hasColegiado = this.missaoConfiguracao.hasColegiado;
          this.hasComissaoDiscernimento =
            this.missaoConfiguracao.hasComissaoDiscernimento;
          this.missaoConfiguracaoSharedDataService.updateMissaoConfiguracao(
            this.missaoConfiguracao
          );
        },
        (error) => {
          this.swtAlert.errorAlert(JSON.stringify(error.error.errors));
        }
      );
  }

  salvarConfiguracao($event?: Event) {
    if ($event) $event.preventDefault();

    this.missaoConfiguracao.hasColegiado = this.hasColegiado;
    this.missaoConfiguracao.hasComissaoDiscernimento =
      this.hasComissaoDiscernimento;

    if (!this.missaoConfiguracao.id) {
      this.missaoConfiguracaoService
        .cadastrar(this.missaoConfiguracao)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((dadosRetorno) => {
          this.missaoConfiguracao = dadosRetorno;
          this.missaoConfiguracaoSharedDataService.updateMissaoConfiguracao(
            this.missaoConfiguracao
          );
        });
    } else {
      this.missaoConfiguracaoService
        .atualizar(this.missaoConfiguracao)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((dadosRetorno) => {
          this.missaoConfiguracao = dadosRetorno;
          this.missaoConfiguracaoSharedDataService.updateMissaoConfiguracao(
            this.missaoConfiguracao
          );
          this.swtAlert.successAlert(
            'Configuração da missão atualizada com sucesso!'
          );
        });
    }
  }
}
