import { Component, OnInit } from '@angular/core';
import { CentroEvangelizacao } from 'src/app/model/centroevangelizacao.model';

import { PaisService } from '../pais/service/pais.service';
import { EstadoService } from '../estado/service/estado.service';
import { Estado } from 'src/app/model/estado.model';
import { Pais } from 'src/app/model/pais.model';
import { Cidade } from 'src/app/model/cidade.model';
import { RegionalService } from '../regional/service/regional.service';
import { MissaoService } from '../missao/service/missao.service';
import { Missao } from 'src/app/model/missao.model';
import { Regional } from 'src/app/model/regional.model';
import { ElementoGrupoDominioService } from '../grupodominio/service/elementogrupodominio.service';
import { ElementoGrupoDominio } from 'src/app/model/elementogrupodominio.model';
import { CentroEvangelizacaoService } from '../centroevangelizacao/service/centroevangelizacao.service';
import { Celula } from 'src/app/model/celula.model';
import { CelulaService } from './service/celula.service';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';

export type SearchCelulaType = {
  nome: string;
  ano: number;
  faseId: number;
  regionalId: number;
  missaoId: number;
  centroEvangelizacaoId: number;
  paisId: number;
  estadoId: number;
  pageNumber: number;
  quantityOfElements: number;
};

@Component({
  selector: 'celulas',
  templateUrl: './celulas.component.html',
})
export class CelulasComponent implements OnInit {
  public fases: Array<ElementoGrupoDominio>;
  public estados: Array<Estado>;
  public paises: Array<Pais>;
  public cidades: Array<Cidade>;
  public regionais: Array<Regional>;
  public missoes: Array<Missao>;
  public centrosEvangelizacao: Array<CentroEvangelizacao>;
  public celulas: Array<Celula>;
  public busca: SearchCelulaType;
  public totalDePaginas: number;

  constructor(
    public regionalService: RegionalService,
    public missaoService: MissaoService,
    public paisService: PaisService,
    public estadoService: EstadoService,
    public centroEvangelizacaoService: CentroEvangelizacaoService,
    public celulaService: CelulaService,
    public elementoGrupoDominioService: ElementoGrupoDominioService,
    public swtAlert2Service: SwtAlert2Service
  ) {
    this.fases = new Array<ElementoGrupoDominio>();
    this.estados = new Array<Estado>();
    this.paises = new Array<Pais>();
    this.cidades = new Array<Cidade>();
    this.regionais = new Array<Regional>();
    this.missoes = new Array<Missao>();
    this.centrosEvangelizacao = new Array<CentroEvangelizacao>();

    this.busca = {
      nome: null,
      ano: null,
      faseId: null,
      regionalId: null,
      missaoId: null,
      centroEvangelizacaoId: null,
      paisId: null,
      estadoId: null,
      pageNumber: 0,
      quantityOfElements: 10,
    };
  }

  ngOnInit() {
    this.carregarRegionais();
    this.carregarNiveisDaCelula();
    this.carregarPaises();
    this.buscarCelula();
  }

  cliqueNoBotaoListener(botao) {
    this.busca.pageNumber = botao.numero - 1;
    this.buscarCelula();
  }

  carregarRegionais(): void {
    this.regionalService
      .findAll()
      .then((lista: any) => {
        this.regionais = lista.entity;
      })
      .catch((err: any) => {
        this.swtAlert2Service.errorAlert(err.error.errors);
        console.log(err);
      });
  }

  carregarCev(missaoId: number): void {
    this.centroEvangelizacaoService
      .porMissao(missaoId)
      .then((response: any) => {
        this.centrosEvangelizacao = response.entity;
      })
      .catch((err: any) => {
        this.swtAlert2Service.errorAlert(err.error.errors);
      });
  }

  carregarMissoes(regionalSelecionadoId: number): void {
    this.missaoService
      .porRegional(regionalSelecionadoId)
      .then((response: any) => {
        this.missoes = response.entity;
      })
      .catch((err: any) => {
        this.swtAlert2Service.errorAlert(err.error.errors);
      });
  }

  carregarNiveisDaCelula(): void {
    let buscaFase = {
      grupoNome: 'FASE_CELULA',
    };

    this.elementoGrupoDominioService
      .buscarPorGrupoDominioNome(buscaFase)
      .then((lista: any) => {
        this.fases = lista.entity;
      })
      .catch((err: any) => {
        this.swtAlert2Service.errorAlert(err.error.errors);
        console.log(err);
      });
  }

  carregarPaises(): void {
    this.paisService
      .findAll()
      .then((lista: any) => {
        this.paises = lista.entity;
      })
      .catch((err: any) => {
        this.swtAlert2Service.errorAlert(err.error.errors);
        console.log(err);
      });
  }

  carregarEstados(paisSelecionadoId: number): void {
    let busca = {
      paisId: paisSelecionadoId,
      nome: undefined,
      pageNumber: 0,
      quantityOfElements: 1000,
    };

    this.estadoService
      .buscarEstados(busca)
      .then((lista: any) => {
        this.estados = lista.entity;
      })
      .catch((err: any) => {
        this.swtAlert2Service.errorAlert(err.error.errors);
        console.log(err);
      });
  }

  buscarCelula(): void {
    this.celulaService
      .buscarCelulas(this.busca)
      .then((response: any) => {
        this.celulas = response.entity;
        this.totalDePaginas = response.quantity;
      })
      .catch((err: any) => {
        this.swtAlert2Service.errorAlert(err.error.errors);
      });
  }

  deletarCelula(id: number): void {
    if (confirm('Deseja deletar esta célula?')) {
      this.celulaService
        .delete(id)
        .then((response: any) => {
          this.swtAlert2Service.successAlert(response.message);
          this.buscarCelula();
        })
        .catch((err) => {
          this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }
  }
}
