import { NgModule } from '@angular/core';
import { LoginComponent } from './login.component';
import { LoginRoutingModule } from './login-routing.module';
import { FormsModule } from '@angular/forms';
import { LoginService } from './service/login.service';
import { SwtAlert2Service } from '../core/swtalert2/swtalert2.service';
import { EsqueceuSenhaService } from '../secured/wop/usuario/esqueceusenha/service/esqueceusenha.service';
import { NgxSummernoteModule } from 'ngx-summernote';
import { TermoTratamentoDadosBuilder } from '../builder/termotratamentodados.builder';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations :[
        LoginComponent
    ],
    imports: [
        LoginRoutingModule,
        FormsModule,
        NgxSummernoteModule,
        CommonModule
    ],
    providers: [
        LoginService,
        SwtAlert2Service,
        EsqueceuSenhaService,
        TermoTratamentoDadosBuilder
    ]
})
export class LoginModule {

}