import * as tslib_1 from "tslib";
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
export class GenericService {
    constructor(http, router) {
        this.http = http;
        this.router = router;
        this.apiURL = environment.configServidor.apiUrl;
        this.tokenURL = environment.configServidor.tokenUrl;
        this.token = JSON.parse(localStorage.getItem("token"));
        this.tokenHeaders = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            'Authorization': environment.authorization
        });
        this.getHeaders();
    }
    setEntityType(entityType) {
        this.apiURL += entityType;
    }
    create(entity) {
        return this.http.post(this.apiURL, entity, { 'headers': this.getHeaders() }).toPromise();
    }
    createAsync(entity) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http.post(this.apiURL, entity, { 'headers': this.getHeaders() }).toPromise();
        });
    }
    update(id, entity) {
        return this.http.put(this.apiURL + '/' + id, entity, { 'headers': this.getHeaders() }).toPromise();
    }
    updateAsync(id, entity) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http.put(this.apiURL + '/' + id, entity, { 'headers': this.getHeaders() }).toPromise();
        });
    }
    delete(id) {
        return this.http.delete(this.apiURL + '/' + id, { 'headers': this.getHeaders() }).toPromise();
    }
    deleteAsync(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http.delete(this.apiURL + '/' + id, { 'headers': this.getHeaders() }).toPromise();
        });
    }
    find(id) {
        return this.http.get(this.apiURL + '/' + id, { 'headers': this.getHeaders() }).toPromise();
    }
    findAsync(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http.get(this.apiURL + '/' + id, { 'headers': this.getHeaders() }).toPromise();
        });
    }
    findAll() {
        return this.http.get(this.apiURL, { 'headers': this.getHeaders() }).toPromise();
    }
    findAllAsync() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http.get(this.apiURL, { 'headers': this.getHeaders() }).toPromise();
        });
    }
    getToken(login) {
        return this.http.post(this.tokenURL, login.toString(), { 'headers': this.tokenHeaders })
            .toPromise()
            .then(response => {
            localStorage.setItem("token", JSON.stringify(response));
            this.getHeaders();
            return response;
        })
            .catch(err => this.handleError(err));
    }
    refreshToken() {
        return this.http.post(this.tokenURL, { "grant_type": "refresh_token", "refresh_token": this.token.refresh_token }, { 'headers': this.tokenHeaders })
            .toPromise()
            .then(response => {
            localStorage.setItem("token", JSON.stringify(response));
            this.getHeaders();
        })
            .catch(err => this.handleError(err));
    }
    handleError(response) {
        return Promise.reject(response);
    }
    getHeaders() {
        this.token = JSON.parse(localStorage.getItem("token"));
        return new HttpHeaders({
            'Content-Type': 'application/json; charset=UTF-8',
            'Authorization': 'Bearer ' + (this.token ? this.token.access_token : '')
        });
    }
    getCustomHeaders(enctype) {
        this.token = JSON.parse(localStorage.getItem("token"));
        return new HttpHeaders({
            'Content-Type': enctype,
            'Authorization': 'Bearer ' + (this.token ? this.token.access_token : '')
        });
    }
    getHeadersNoContentType() {
        this.token = JSON.parse(localStorage.getItem("token"));
        return new HttpHeaders({
            'Authorization': 'Bearer ' + (this.token ? this.token.access_token : '')
        });
    }
}
