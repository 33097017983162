import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { Login } from '../model/login.model';
import { ImportLoginSh } from '../model/importloginsh.model';
export class LoginComponent {
    constructor(esqueceuSenhaService, loginService, termoUsoBuilder, termoTratamentoDadosBuilder, cadastroPublicoService, swtAlert2Service) {
        this.esqueceuSenhaService = esqueceuSenhaService;
        this.loginService = loginService;
        this.termoUsoBuilder = termoUsoBuilder;
        this.termoTratamentoDadosBuilder = termoTratamentoDadosBuilder;
        this.cadastroPublicoService = cadastroPublicoService;
        this.swtAlert2Service = swtAlert2Service;
        this.passwordFieldType = 'password';
        this.importLoginShForm = new ImportLoginSh(false, null, null, false, false, false);
        this.termoVigente = this.termoUsoBuilder.getInstance();
        this.termoTratamentoDadosVigente =
            this.termoTratamentoDadosBuilder.getInstance();
    }
    ngOnInit() {
        this.login = new Login('', '');
        this.carregarTermoVigente();
        this.carregarTermoTratamentoDadosVigente();
    }
    fazerLogin() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.login.username = this.login.username.trim();
            this.loginService.fazerLogin(this.login);
        });
    }
    togglePasswordVisibility() {
        this.passwordFieldType =
            this.passwordFieldType === 'password' ? 'text' : 'password';
    }
    recuperarSenha() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.emailRecuperacao != '' &&
                this.emailRecuperacao != undefined &&
                this.emailRecuperacao != null) {
                try {
                    let response = yield this.esqueceuSenhaService.esqueceuSenha(this.emailRecuperacao);
                    this.swtAlert2Service.infoAlert(response.message);
                    $('#modal-esqueceu-senha').modal('hide');
                }
                catch (err) {
                    this.swtAlert2Service.errorAlert(err.error.errors);
                }
            }
            else {
                this.swtAlert2Service.warningAlert('Informe o e-mail de recuperação!');
            }
        });
    }
    carregarTermoVigente() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.cadastroPublicoService.getTermoVigente();
                this.termoVigente = response.entity;
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    carregarTermoTratamentoDadosVigente() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.cadastroPublicoService.getTermoTratamentoDadosVigente();
                this.termoTratamentoDadosVigente = response.entity;
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    importarDadosLoginSh() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.importLoginShForm.username.length > 20) {
                this.swtAlert2Service.warningAlert('O nome de usuário deve conter no máximo 20 characteres.');
            }
            else if (!this.validarImportacao(this.importLoginShForm)) {
                this.swtAlert2Service.warningAlert('Para continuar com a importação preencha os campos usuario wop e e-mail e aceite os termos para importação dos seus dados.');
            }
            else {
                try {
                    let response = yield this.cadastroPublicoService.importarDadosLoginSH(this.importLoginShForm);
                    this.importLoginShForm = new ImportLoginSh(false, null, null, false, false, false);
                    $('#modal-import-loginsh').modal('hide');
                    $('#confirmacao-importacao-loginsh').modal('show');
                }
                catch (e) {
                    this.swtAlert2Service.errorAlert(e.error.errors);
                }
            }
        });
    }
    validarImportacao(importLoginShForm) {
        let retorno = true;
        if (importLoginShForm.username == null ||
            importLoginShForm.username == '' ||
            importLoginShForm.username.length > 20 ||
            importLoginShForm.email == null ||
            importLoginShForm.email == '' ||
            (this.termoVigente != null &&
                importLoginShForm.aceiteTermoUso == false) ||
            (this.termoTratamentoDadosVigente != null &&
                importLoginShForm.aceiteTermoTratamentoDados == false) ||
            importLoginShForm.aceiteImportacaoLoginSh == false) {
            retorno = false;
        }
        return retorno;
    }
}
