import { GenericService } from 'src/app/core/services/generic.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from "@angular/core";
import { Sacerdote } from 'src/app/model/sacerdote.model';


@Injectable()
export class SacerdoteService extends GenericService<Sacerdote> {
    
    constructor(protected http: HttpClient, protected router:Router) {
        super(http,router);
        this.setEntityType('sacerdote');
    }

    porPessoaId(id: number) : Promise<any> {
        return this.http.get<any>(`${this.apiURL}/porpessoa/${id}`, { 'headers' : this.getHeaders() }).toPromise();
    }   
 
}