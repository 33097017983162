import * as tslib_1 from "tslib";
export class RouterManagerService {
    constructor(router) {
        this.router = router;
    }
    verificarUsuario(usuarioAtual) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (!this.jaExecutado && window.location.pathname.match('/secured*') != null) {
                this.jaExecutado = true;
                if (usuarioAtual.autorizacoesNome.includes('ROLE_ADMIN')) {
                    this.router.navigate(['/secured/']);
                }
                else if (usuarioAtual.autorizacoesNome.includes('ROLE_MEMBRO_GRUPO_ORACAO')) {
                    this.router.navigate(['/secured/local/bemvindo']);
                }
            }
        });
    }
}
