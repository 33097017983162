import { Component, OnInit } from '@angular/core';
import { Cidade } from 'src/app/model/cidade.model';
import { CidadeService } from './service/cidade.service';
import { PaisService } from '../pais/service/pais.service';
import { EstadoService } from '../estado/service/estado.service';
import { Estado } from 'src/app/model/estado.model';
import { Pais } from 'src/app/model/pais.model';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';

@Component({
    selector: 'cidades',
    templateUrl: './cidades.component.html'
})
export class CidadesComponent implements OnInit {

    public estados : Array<Estado>;
    public paises : Array<Pais>;
    public cidades: Array<Cidade>;
    public busca: any;
    public totalDePaginas: number;

    constructor(
        public paisService: PaisService,
        public estadoService: EstadoService,
        public cidadeService: CidadeService,
        public swtAlert2Service: SwtAlert2Service
    ) 
    {
        this.estados = new Array<Estado>();
        this.paises = new Array<Pais>();
        this.cidades = new Array<Cidade>();  
        this.busca = {
            paisId: null,
            estadoId: null,
            nome: undefined,
            pageNumber : 0, 
            quantityOfElements : 10
        };
    }

    ngOnInit() {
        this.carregarPaises();
        this.buscarCidades();
    }   

    cliqueNoBotaoListener(botao) {
        this.busca.pageNumber = botao.numero-1;
        this.buscarCidades();
    }

    carregarPaises() : void {
        this.paisService.findAll().then( (lista: any) => {
            this.paises = lista.entity;
        }).catch( (err: any) => {
            console.log(err);
        });
    }

    carregarEstados() : void {

        this.busca.estadoId = null;

        let busca = {
            paisId: this.busca.paisId,
            nome: undefined,
            pageNumber : 0, 
            quantityOfElements : 1000
        };

        this.estadoService.buscarEstados(busca).then( (lista: any) => {
            this.estados = lista.entity;
        }).catch( (err: any) => {
            console.log(err);
        });
    }

    buscarCidades() : void {
        this.cidadeService.buscarCidades(this.busca).then( (response: any) => {
            this.cidades = response.entity;
            this.totalDePaginas = response.quantity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }
    
    deletarCidade(id: number) : void {

        if(confirm('Deseja deletar esta cidade?'))
        {
            this.cidadeService.delete(id)
            .then( (response: any) => {
                this.swtAlert2Service.successAlert(response.message);
                this.buscarCidades();
            })
            .catch((err) => {
                this.swtAlert2Service.errorAlert(err.error.errors);
            });
        }
    }
}