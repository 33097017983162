import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AutorizacaoLocalService } from './service/autorizacao.local.service';
import { CommonModule } from '@angular/common';
import { SecuredModule } from '../../layout/secured.module';
import { RouterModule } from '@angular/router';
import { AutorizacaoLocalComponent } from './autorizacao.local.component';
import { AutorizacaoLocalRoutingModule } from './autorizacao-local-routing.module';
import { PermissaoLocalService } from '../permissao/service/permissao.local.service';
import { AutorizacoesLocalComponent } from './autorizacoes.local.component';
import { AutorizacaoLocalEditComponent } from './autorizacao.local.edit.component';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { NgpSortModule } from 'ngp-sort-pipe';

@NgModule({
    declarations: [ 
        AutorizacaoLocalComponent,
        AutorizacoesLocalComponent,
        AutorizacaoLocalEditComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        AutorizacaoLocalRoutingModule,
        FormsModule,
        SecuredModule,
        NgpSortModule
    ],
    providers: [
        PermissaoLocalService,
        AutorizacaoLocalService,
        SwtAlert2Service
    ]
})
export class AutorizacaoLocalModule {

}