import { Routes } from '@angular/router';
const ɵ0 = () => import("./secured/local/mensagem-rl/mensagem-rl.module.ngfactory").then((m) => m.MensagemRlModuleNgFactory);
const routes = [
    {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full',
    },
    {
        path: 'secured/mensagens-rl',
        loadChildren: ɵ0,
    },
];
export class AppRoutingModule {
}
export { ɵ0 };
