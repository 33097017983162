export class Projeto {

    constructor(
        public id: number,
        public nome: string,
        public dataFundacao: Date,
        public centroEvangelizacaoId: number,
        public regionalId: number,
        public missaoId: number,
        public tipoProjetoId: number
    ) {}

}