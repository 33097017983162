import { GenericService } from 'src/app/core/services/generic.service';
import { HttpClient } from '@angular/common/http';
import { GrupoDeOracao } from 'src/app/model/grupodeoracao.model';
import { Router } from '@angular/router';
import { Injectable } from "@angular/core";

@Injectable()
export class GrupoDeOracaoService extends GenericService<GrupoDeOracao> {
    
    constructor(protected http: HttpClient, protected router:Router) {
        super(http,router);
        this.setEntityType('r/grupodeoracao');
    }  
    
    async buscarGruposDeOracao(busca: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/buscar', JSON.stringify(busca), { 'headers' : this.getHeaders() }).toPromise();
    }

    async unirGruposOracao(request: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/unirgrupos', JSON.stringify(request), { 'headers' : this.getHeaders() }).toPromise();
    }

    async gruposSemPastores(busca: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/grupossempastores', JSON.stringify(busca), { 'headers' : this.getHeaders() }).toPromise();
    }

    async quantidadeGruposComSemPastores(busca: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/relatorioquantidadegruposcomsempastor', JSON.stringify(busca), { 'headers' : this.getHeaders() }).toPromise();
    }

    async relatorioGruposPorPublico(busca: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/relatorioquantidadegruposporpublico', JSON.stringify(busca), { 'headers' : this.getHeaders() }).toPromise();
    }

    async relatorioPublicoMes(busca: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/relatoriopublicomes', JSON.stringify(busca), { 'headers' : this.getHeaders() }).toPromise();
    }

    async relatorioPerserverancaTrimestre(busca: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/relatorioperseverancatrimestre', JSON.stringify(busca), { 'headers' : this.getHeaders() }).toPromise();
    }

    async relatorioCrescimentoObra(busca: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/relatoriocrescimentoobra', JSON.stringify(busca), { 'headers' : this.getHeaders() }).toPromise();
    }

    async relatorioPublicoMesPastor() : Promise<any> {
        return this.http.get<any>(this.apiURL+'/relatoriopublicomespastor', { 'headers' : this.getHeaders() }).toPromise();
    }

    async relatorioPerserverancaTrimestrePastor() : Promise<any> {
        return this.http.get<any>(this.apiURL+'/relatorioperseverancatrimestrepastor', { 'headers' : this.getHeaders() }).toPromise();
    }

    async relatorioCrescimentoObraPastor() : Promise<any> {
        return this.http.get<any>(this.apiURL+'/relatoriocrescimentoobrapastor', { 'headers' : this.getHeaders() }).toPromise();
    }

    async getAllNotInAcessoUsuarioEstrutura(id: number): Promise<any> {
        return this.http.get<any>(`${this.apiURL}/getallnotinacessousuarioestrutura/${id}`, { 'headers' : this.getHeaders() }).toPromise();
    }

    async getAllFromAcessoUsuarioEstrutura(id: number): Promise<any> {
        return this.http.get<any>(`${this.apiURL}/getallfromacessousuarioestrutura/${id}`, { 'headers' : this.getHeaders() }).toPromise();
    }

    async criarLinkCadastroGrupoOracao(request: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/criarlinkcadastrogrupooracao', JSON.stringify(request), { 'headers' : this.getHeaders() }).toPromise();
    }

    async obterLinkCadastroGrupoOracao(id: number): Promise<any> {
        return this.http.get<any>(`${this.apiURL}/obterlinkcadastrogrupooracao/${id}`, { 'headers' : this.getHeaders() }).toPromise();
    }

} 