
import { Estado } from '../model/estado.model';
import { AbstractBuilder } from './abstract.builder';

export class EstadoBuilder extends AbstractBuilder<Estado> {

    reset() : void {
        this.entity = new Estado(null,null,null,[]);
    }

}