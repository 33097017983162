import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CidadeComponent } from './cidade.component';
import { CidadesComponent } from './cidades.component';
import { CidadeEditComponent } from './cidade.edit.component';

const cidadeRoutes: Routes = [
    {
        path: 'secured/cidade',
        component: CidadeComponent
    },
    {
        path: 'secured/cidades',
        component: CidadesComponent
    },
    {
        path: 'secured/cidade/:id',
        component: CidadeEditComponent
    }
]

@NgModule({
    imports: [
        RouterModule.forChild(cidadeRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class CidadeRoutingModule {
    
}