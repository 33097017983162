import { Component, OnDestroy, OnInit } from '@angular/core';
import { Pessoa } from 'src/app/model/pessoa.model';
import { MissaoConfiguracaoSharedDataService } from '../../service/missao-configuracao-shared-data.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MissaoConfiguracao } from '../../service/missao-config.service';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { ImagemService } from 'src/app/secured/wop/imagem/service/imagem.service';
import { Conselho } from 'src/app/model/conselho.model';
import { ConselhoService } from '../../service/conselho.service';

@Component({
  selector: 'app-cadastro-conselho',
  templateUrl: './cadastro-conselho.component.html',
  styleUrls: ['./cadastro-conselho.component.css'],
})
export class CadastroConselhoComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject<null>();
  conselho: Conselho = {} as Conselho;
  membrosConselho: Array<Conselho> = new Array<Conselho>();
  pessoaConselhoSelecionado: Pessoa;
  missaoConfiguracao: MissaoConfiguracao;

  constructor(
    private conselhoService: ConselhoService,
    private missaoConfiguracaoSharedDataService: MissaoConfiguracaoSharedDataService,
    private imagemService: ImagemService,
    private swtAlert: SwtAlert2Service
  ) {}

  ngOnInit() {
    this.missaoConfiguracaoSharedDataService.missaoConfiguracao$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (missaoConfiguracao) => {
          if (missaoConfiguracao) {
            this.missaoConfiguracao = missaoConfiguracao;
            this.carregarConselhoPorMissaoConfiguracaoId(missaoConfiguracao.id);
          }
        },
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  async selecionarConselho(pessoa): Promise<any> {
    this.conselho.pessoaId = pessoa.id;
    this.pessoaConselhoSelecionado = pessoa;
    $('#listagem-pessoa-conselho').modal('hide');
  }

  async adicionarConselho(): Promise<any> {
    try {
      this.conselho.missaoConfiguracaoId = this.missaoConfiguracao.id;
      const response = await this.conselhoService
        .cadastrar(this.conselho)
        .toPromise();
      this.conselho = {} as Conselho;
      this.pessoaConselhoSelecionado = undefined;
      await this.carregarConselhoPorMissaoConfiguracaoId(
        this.missaoConfiguracao.id
      );
      this.swtAlert.successAlert('Membro do Conselho cadastrado com sucesso!');
    } catch (err) {
      this.swtAlert.errorAlert(err.error.errors);
    }
  }

  async carregarConselhoPorMissaoConfiguracaoId(id: number): Promise<any> {
    try {
      const response = await this.conselhoService
        .obterPorMissaoConfiguracaoId(id)
        .toPromise();
      if (response) {
        this.membrosConselho = response;

        if (this.membrosConselho != null && this.membrosConselho.length > 0) {
          for (let i = 0; i < this.membrosConselho.length; i++) {
            if (this.membrosConselho[i].imagemId != null) {
              let responseImage = await this.imagemService.getPessoaPhoto(
                this.membrosConselho[i].imagemId
              );
              let blogImage = responseImage.body;
              this.imagemService.createImage(
                blogImage,
                this.membrosConselho[i]
              );
            }
          }
        }
      }
    } catch (err) {
      this.swtAlert.errorAlert(err.error.errors);
    }
  }

  async removerConselho(conselhoId: number): Promise<any> {
    if (confirm('Deseja remover o membro do conselho?')) {
      try {
        await this.conselhoService.remover(conselhoId).toPromise();
        await this.carregarConselhoPorMissaoConfiguracaoId(
          this.missaoConfiguracao.id
        );
        this.swtAlert.successAlert('Membro do Conselho removido com sucesso!');
        this.conselho = {} as Conselho;
      } catch (err) {
        this.swtAlert.errorAlert(err.error.errors);
      }
    }
  }
}
