import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
export class CadastroConselhoComponent {
    constructor(conselhoService, missaoConfiguracaoSharedDataService, imagemService, swtAlert) {
        this.conselhoService = conselhoService;
        this.missaoConfiguracaoSharedDataService = missaoConfiguracaoSharedDataService;
        this.imagemService = imagemService;
        this.swtAlert = swtAlert;
        this.unsubscribe$ = new Subject();
        this.conselho = {};
        this.membrosConselho = new Array();
    }
    ngOnInit() {
        this.missaoConfiguracaoSharedDataService.missaoConfiguracao$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
            next: (missaoConfiguracao) => {
                if (missaoConfiguracao) {
                    this.missaoConfiguracao = missaoConfiguracao;
                    this.carregarConselhoPorMissaoConfiguracaoId(missaoConfiguracao.id);
                }
            },
        });
    }
    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
    selecionarConselho(pessoa) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.conselho.pessoaId = pessoa.id;
            this.pessoaConselhoSelecionado = pessoa;
            $('#listagem-pessoa-conselho').modal('hide');
        });
    }
    adicionarConselho() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.conselho.missaoConfiguracaoId = this.missaoConfiguracao.id;
                const response = yield this.conselhoService
                    .cadastrar(this.conselho)
                    .toPromise();
                this.conselho = {};
                this.pessoaConselhoSelecionado = undefined;
                yield this.carregarConselhoPorMissaoConfiguracaoId(this.missaoConfiguracao.id);
                this.swtAlert.successAlert('Membro do Conselho cadastrado com sucesso!');
            }
            catch (err) {
                this.swtAlert.errorAlert(err.error.errors);
            }
        });
    }
    carregarConselhoPorMissaoConfiguracaoId(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield this.conselhoService
                    .obterPorMissaoConfiguracaoId(id)
                    .toPromise();
                if (response) {
                    this.membrosConselho = response;
                    if (this.membrosConselho != null && this.membrosConselho.length > 0) {
                        for (let i = 0; i < this.membrosConselho.length; i++) {
                            if (this.membrosConselho[i].imagemId != null) {
                                let responseImage = yield this.imagemService.getPessoaPhoto(this.membrosConselho[i].imagemId);
                                let blogImage = responseImage.body;
                                this.imagemService.createImage(blogImage, this.membrosConselho[i]);
                            }
                        }
                    }
                }
            }
            catch (err) {
                this.swtAlert.errorAlert(err.error.errors);
            }
        });
    }
    removerConselho(conselhoId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (confirm('Deseja remover o membro do conselho?')) {
                try {
                    yield this.conselhoService.remover(conselhoId).toPromise();
                    yield this.carregarConselhoPorMissaoConfiguracaoId(this.missaoConfiguracao.id);
                    this.swtAlert.successAlert('Membro do Conselho removido com sucesso!');
                    this.conselho = {};
                }
                catch (err) {
                    this.swtAlert.errorAlert(err.error.errors);
                }
            }
        });
    }
}
