import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { PessoaBuilder } from 'src/app/builder/pessoa.builder';
import { QuestionarioFormando } from 'src/app/model/questionarioformando.model';
import { QuestionarioFormandoBuilder } from 'src/app/builder/questionarioformando.builder';
import { QuestionarioFormandoService } from '../questionario/service/questionarioformando.service';
import { ParecerResponsavel } from 'src/app/model/parecerresponsavel.model';
import { ParecerResponsavelBuilder } from 'src/app/builder/parecerresponsavel.builder';
import { ParecerResponsavelService } from './service/parecerresponsavel.service';
import { Celula } from 'src/app/model/celula.model';
import { MembroComunidadeBuilder } from 'src/app/builder/membro.comunidade.builder';
import { MembroComunidade } from 'src/app/model/membro.comunidade.model';
import { FormadorComunitario } from 'src/app/model/formadorcomunitario.model';
import { Usuario } from 'src/app/model/usuario.model';
import { Pessoa } from 'src/app/model/pessoa.model';
import { UsuarioService } from '../../wop/usuario/service/usuario.service';
import { ImagemService } from '../../wop/imagem/service/imagem.service';
import { PessoaService } from '../../wop/pessoa/service/pessoa.service';
import { CelulaService } from '../../wop/celula/service/celula.service';
import { MembroComunidadeService } from '../../wop/celula/service/membro.comunidade.service';
import { FormadorComunitarioService } from '../../wop/celula/service/formador.comunitario.service';
import {
  MissaoConfigService,
  QuestionarioConfiguracao,
} from '../missao-config-comp/service/missao-config.service';

@Component({
  selector: 'app-parecer',
  templateUrl: './parecer.celula.component.html',
  styleUrls: [],
})
export class ParecerCelulaComponent implements OnInit {
  public usuario: Usuario;
  public pessoa: Pessoa;
  public celula: Celula;
  public formadorComunitario: FormadorComunitario;
  public membroComunidadeBuilder: MembroComunidadeBuilder;
  public membroComunidade: MembroComunidade;
  public membrosCelula: Array<any>;
  public pessoaBuilder: PessoaBuilder;

  public totalDePaginas: number = 1;

  public buscaMembros: any;
  public totalDePaginasMembros: number;
  public hasCelulaAssociada: boolean;

  public questionarioFormando: QuestionarioFormando;
  public parecerResponsavel: ParecerResponsavel;

  questionarioConfiguracao: QuestionarioConfiguracao;

  constructor(
    private missaoConfiguracaoService: MissaoConfigService,
    public usuarioService: UsuarioService,
    public imagemService: ImagemService,
    public pessoaService: PessoaService,
    public celulaService: CelulaService,
    public membroService: MembroComunidadeService,
    public formadorComunitarioService: FormadorComunitarioService,
    public questionarioFormandoBuilder: QuestionarioFormandoBuilder,
    public questionarioFormandoService: QuestionarioFormandoService,
    public parecerResponsavelBuilder: ParecerResponsavelBuilder,
    public parecerResponsavelService: ParecerResponsavelService,
    public route: ActivatedRoute,
    public router: Router,
    public swtAlert2Service: SwtAlert2Service
  ) {
    this.pessoaBuilder = new PessoaBuilder();
    this.membrosCelula = new Array<any>();
    this.membroComunidadeBuilder = new MembroComunidadeBuilder();
    this.membroComunidade = this.membroComunidadeBuilder.getInstance();
    this.questionarioFormando = this.questionarioFormandoBuilder.getInstance();
    this.parecerResponsavel = this.parecerResponsavelBuilder.getInstance();
  }

  ngOnInit(): void {
    this.initializer();
    this.hasCelulaAssociada = false;
    this.buscaMembros = {
      celulaId: 0,
      pageNumber: 0,
      quantityOfElements: 0,
    };
  }

  async initializer(): Promise<any> {
    await this.carregarUsuario();
    await this.carregarPessoa();
    await this.carregarFormadorComunitario();
    if (
      this.formadorComunitario != undefined &&
      this.formadorComunitario.casaCelulaId != null
    ) {
      await this.carregarCelula();
      this.buscaMembros = {
        celulaId: this.celula.id,
        pageNumber: 0,
        quantityOfElements: 4,
      };
      await this.carregarMembrosCelula();
    } else this.hasCelulaAssociada = false;
  }

  carregarQuestionarioConfiguracao(questionarioId: number) {
    const missaoId = this.pessoa.vinculoMissao.missaoAtualId;
    this.missaoConfiguracaoService
      .obterConfiguracaoQuestionarioPorMissaoEQuestionario(
        missaoId,
        questionarioId
      )
      .subscribe(
        (questionarioConfiguracao) =>
          (this.questionarioConfiguracao = questionarioConfiguracao)
      );
  }

  async carregarUsuario(): Promise<any> {
    try {
      let response = await this.usuarioService.getCurrentUser();
      this.usuario = response.entity;
    } catch (e) {
      console.log(e);
    }
  }

  async carregarPessoa(): Promise<any> {
    try {
      let response = await this.pessoaService.buscaPorUsuarioId(
        this.usuario.id
      );
      this.pessoa = response.entity;

      /*if(this.pessoa.imagemId != null)
        {
            let blogImage = await this.getPessoaImagem(this.pessoa.imagemId);

            this.imagemService.createImage(blogImage, this.pessoa);
        } */
    } catch (e) {
      console.log(e);
    }
  }

  async carregarFormadorComunitario(): Promise<any> {
    try {
      let response =
        await this.formadorComunitarioService.buscarCelulaPorPessoaId(
          this.pessoa.id
        );
      this.formadorComunitario = response;

      /*if(this.formadorComunitario.pessoaImagemId != null)
        {
            let blogImage = await this.getPessoaImagem(this.formadorComunitario.pessoaImagemId);

            this.imagemService.createImage(blogImage, this.formadorComunitario);
        } */
    } catch (e) {
      console.log(e);
    }
  }

  async carregarCelula(): Promise<any> {
    try {
      let response = await this.celulaService.find(
        this.formadorComunitario.casaCelulaId
      );
      this.celula = response.entity;
    } catch (e) {
      console.log(e);
    }
  }

  async carregarMembrosCelula(): Promise<any> {
    try {
      let response = await this.membroService.listarMembrosCelula(
        this.celula.id
      );
      this.membrosCelula = response;
      this.totalDePaginasMembros = 1;
      //this.membrosCelula = response.content;
      //this.totalDePaginasMembros = response.totalPages;

      if (this.membrosCelula.length > 0) {
        this.membrosCelula = this.membrosCelula.sort((a, b) =>
          a.pessoaNome.localeCompare(b.pessoaNome)
        );
      }

      for (let i = 0; i < this.membrosCelula.length; i++) {
        if (this.membrosCelula[i].pessoaImagemId != null) {
          let blogImage = await this.getPessoaImagem(
            this.membrosCelula[i].pessoaImagemId
          );
          this.imagemService.createImage(blogImage, this.membrosCelula[i]);
        }
        let questionarioFormando: QuestionarioFormando =
          await this.carregarQuestionario(this.membrosCelula[i].pessoaId);

        if (questionarioFormando != null) {
          if (!this.questionarioConfiguracao) {
            this.carregarQuestionarioConfiguracao(
              questionarioFormando.questionarioId
            );
          }

          this.membrosCelula[i].statusQuestionario =
            questionarioFormando.status;
          this.membrosCelula[i].idQuestionario = questionarioFormando.id;

          let parecerResponsavelAtual: ParecerResponsavel =
            await this.carregarParecer(questionarioFormando.id);
          if (parecerResponsavelAtual != null) {
            this.membrosCelula[i].statusParecer =
              parecerResponsavelAtual.status;
            this.membrosCelula[i].idParecer = parecerResponsavelAtual.id;
          } else {
            this.membrosCelula[i].statusParecer = 'Não iniciado';
            this.membrosCelula[i].idParecer = null;
          }
        }
        if (questionarioFormando == null) {
          this.membrosCelula[i].statusQuestionario = 'Não iniciado';
          this.membrosCelula[i].idQuestionario = null;
        }
        console.log(this.membrosCelula);
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async carregarQuestionario(pessoaId: number): Promise<any> {
    try {
      let response = await this.questionarioFormandoService.buscarPorPessoaId(
        pessoaId
      );
      if (response != undefined && response != null) {
        return response;
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async carregarParecer(idQuestionario: number): Promise<any> {
    try {
      let response =
        await this.parecerResponsavelService.buscarPorQuestionarioFormandoId(
          idQuestionario
        );
      return response;
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async cadastrarVinculoQuestionarioCAL(pessoaId: number): Promise<any> {
    try {
      let responsePorPessoa =
        await this.questionarioFormandoService.buscarPorPessoaId(pessoaId);
      if (
        responsePorPessoa != undefined &&
        responsePorPessoa != null &&
        responsePorPessoa.questionarioId != 3
      ) {
        this.questionarioFormando = responsePorPessoa;
        this.cadastrarVinculoParecerCAL(this.questionarioFormando.id);
      } else {
        this.questionarioFormando.anoFormativoId = 2;
        this.questionarioFormando.questionarioId = 3;
        this.questionarioFormando.pessoaId = pessoaId;
        let response = await this.questionarioFormandoService.cadastrar(
          this.questionarioFormando
        );
        if (response != undefined && response != null) {
          this.questionarioFormando = response;
          this.cadastrarVinculoParecerCAL(this.questionarioFormando.id);
        }
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async cadastrarVinculoParecerCAL(
    questionarioFormandoId: number
  ): Promise<any> {
    try {
      this.parecerResponsavel.questionarioFormandoId = questionarioFormandoId;
      this.parecerResponsavel.parecer_id = 2;
      this.parecerResponsavel.pessoaResponsavelId = this.pessoa.id;
      let response = await this.parecerResponsavelService.cadastrar(
        this.parecerResponsavel
      );
      if (response != undefined && response != null) {
        this.parecerResponsavel = response;
        this.router.navigate([
          '/secured/parecer-cal-answer/' + this.parecerResponsavel.id,
        ]);
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async getPessoaImagem(id: number): Promise<any> {
    try {
      let response = await this.imagemService.getPessoaPhoto(id);
      return response.body;
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async consultarVinculoQuestionarioCAL(formando: any): Promise<any> {
    try {
      if (formando.idQuestionario != null) {
        let response =
          await this.parecerResponsavelService.buscarPorQuestionarioFormandoId(
            formando.idQuestionario
          );
        if (response != undefined && response != null) {
          this.parecerResponsavel = response;
          // this.router.navigate(['/secured/parecer-cal-answer/' + this.parecerResponsavel.id]);
          this.router.navigate([
            '/secured/parecer-cal-answer/' + this.parecerResponsavel.id,
          ]);
        } else {
          this.cadastrarVinculoParecerCAL(formando.idQuestionario);
        }
      }
      if (formando.idQuestionario == null) {
        this.cadastrarVinculoQuestionarioCAL(formando.pessoaId);
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  cliqueNoBotaoListener(botao) {
    this.buscaMembros.pageNumber = botao.numero - 1;
    this.carregarMembrosCelula();
  }
}
