import { Injectable } from '@angular/core';
import { RemocaoMembroCasaComunitaria } from '../model/remocaomembrocasacomunitaria.model.';
import { AbstractBuilder } from './abstract.builder';

@Injectable()
export class RemocaoMembroCasaComunitariaBuilder extends AbstractBuilder<RemocaoMembroCasaComunitaria> {

    reset() : void {
        this.entity = new RemocaoMembroCasaComunitaria(null,null,null,null);
    }

}