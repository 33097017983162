<secured>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h3 i18n>Listagem dos Ministérios</h3>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <form (ngSubmit)="buscarMinisterio()" #permissoesForm="ngForm" novalidate>
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title">Buscar ministério</h3>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label i18n for="lcit_nome">Nome</label>
                                                <input [(ngModel)]="busca.nome" name="lcit_nome" type="text" class="form-control" id="lcit_nome" placeholder="Digite o nome" i18n-placeholder>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label i18n for="nministerio_dtfund">Ano De Fundação</label>
                                                <input [(ngModel)]="busca.ano" name="nministerio_dtfund" type="number" class="form-control" id="nministerio_dtfund" i18n-placeholder>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label i18n for="nministerio_r">Regional</label>
                                                <select class="form-control" id="nministerio_r" [(ngModel)]="regionalSelecionadoId" (change)="carregarMissoes(regionalSelecionadoId)" name="nministerio_r">
                                                    <option [value]="null" i18n>Selecione um Regional</option>
                                                    <option *ngFor="let reg of regionais" [value]="reg.id">{{reg.nome}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label i18n for="nministerio_missao">Missão</label>
                                                <select class="form-control" id="nministerio_missao" [(ngModel)]="missaoSelecionadoId" (change)="carregarCev(missaoSelecionadoId)" name="nministerio_missao">
                                                    <option [value]="null" i18n>Selecione uma Missão</option>
                                                    <option *ngFor="let missao of missoes" [value]="missao.id">{{missao.nome}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label i18n for="nministerio_cev">Centro De Evangelização</label>
                                                <select class="form-control" id="nministerio_cev" [(ngModel)]="busca.centroEvangelizacaoId" name="nministerio_cev">
                                                    <option [value]="null" i18n>Selecione um centro de Evangelização</option>
                                                    <option *ngFor="let cev of centrosEvangelizacao" [value]="cev.id">{{cev.nome}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label i18n for="nministerio_pais">País</label>
                                                <select class="form-control" id="nministerio_pais" [(ngModel)]="busca.paisId" (change)="carregarEstados(busca.paisId)" name="nministerio_pais">
                                                    <option [value]="null" i18n>Selecione um País</option>
                                                    <option *ngFor="let pais of paises" [value]="pais.id">{{pais.nome}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label i18n for="nministerio_estado">Estado</label>
                                                <select class="form-control" id="nministerio_estado" [(ngModel)]="busca.estadoId" name="nministerio_estado">
                                                    <option [value]="null" i18n>Selecione um Estado</option>
                                                    <option *ngFor="let estado of estados" [value]="estado.id">{{estado.nome}}</option>
                                                </select>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="card-footer">
                                    <button type="submit" class="btn btn-primary float-right" i18n>Buscar</button>
                                    <button type="reset" class="btn btn-default float-right" style="margin-right: 0.5rem;" i18n>Limpar</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">

                        <table-component [tituloSessao]="'Ministérios'" [cols]="5" [rows]="busca.quantityOfElements" [totalDePaginas]="totalDePaginas" (eventoBotao)="cliqueNoBotaoListener($event)">
                            <tr tableheader>
                                <th style="width: 10px">#</th>
                                <th>Nome</th>
                                <th>Missâo</th>
                                <th>Centro De Evangelização</th>
                                <th></th>
                            </tr>
                            <tr tablebody *ngFor="let ministerio of ministerios">
                                <td>{{ministerio.id}}</td>
                                <td>{{ministerio.nome}}</td>
                                <td>{{ministerio.missaoNome}}</td>
                                <td>{{ministerio.centroEvangelizacaoNome}}
                                    <td class="text-center">
                                        <button class="btn btn-sm btn-primary" routerLink="../ministerio/{{ministerio.id}}" title="Editar ministério" alt="Editar os dados do ministério"><i class="fas fa-edit"></i></button>&nbsp;
                                        <button class="btn btn-sm btn-danger" (click)="deletarMinisterio(ministerio.id)" title="Deletar ministério" alt="Deletar ministério"><i class="fas fa-trash-alt"></i></button>
                                    </td>
                            </tr>
                        </table-component>
                    </div>
                </div>
            </div>
        </section>
    </div>
</secured>