import { Component, OnInit } from '@angular/core';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { GrupoDominio } from 'src/app/model/grupodominio.model';
import { GrupoDominioLocalService } from './service/grupodominio.local.service';

@Component({
    selector: 'gruposdominiolocal',
    templateUrl: './gruposdominio.local.component.html'
})
export class GruposDominioLocalComponent implements OnInit {

    public gruposDominio: Array<GrupoDominio>;
    public busca: any;
    public totalDePaginas: number;

    constructor(
        public grupoDominioService: GrupoDominioLocalService,
        public swtAlert2Service: SwtAlert2Service
    ) 
    {
        this.gruposDominio = new Array<GrupoDominio>();     
        this.busca = {
            nome: undefined,
            pageNumber : 0, 
            quantityOfElements : 10
        };
    }

    ngOnInit() {
        this.buscarGruposDominio();
    }   

    cliqueNoBotaoListener(botao) {
        this.busca.pageNumber = botao.numero-1;
        this.buscarGruposDominio();
    }

    buscarGruposDominio() : void {
        this.grupoDominioService.buscarGruposDominio(this.busca).then( (response: any) => {
            this.gruposDominio = response.entity;
            this.totalDePaginas = response.quantity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }
    
    deletarGrupoDominio(id: number) : void {

        if(confirm('Deseja deletar este grupo domínio?'))
        {
            this.grupoDominioService.delete(id)
            .then( (response: any) => {
                this.swtAlert2Service.successAlert(response.message);
                this.buscarGruposDominio();
            })
            .catch((err) => {
                this.swtAlert2Service.errorAlert(err.error.errors);
            });
        }
    }
}