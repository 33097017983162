<div class="row">
    <div class="col-sm-12 col-md-12 col-lg-12">
        <div class="card card-gray-dark color-pallete-box">
            <div class="card-header">
                <h3 class="card-title">
                    <i class="fas fa-child"></i>
                    <a i18n> Equipe de Formação </a>
                </h3>
                <div class="card-tools"><button class="btn btn-tool" data-card-widget="collapse" type="button"><i
                            class="fas fa-minus"></i></button></div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-sm-12 col-md-3 col-lg-3">
                        <div class="form-group">
                            <label i18n for="npastor_pessoa">Missionário</label>
                            <select name="missionarios_casa_comunitaria" class="form-control" [(ngModel)]="cadastroEquipeFormacaoFormulario.pessoaId">
                                <option [value]="null">Selecione um missionário</option>
                                <option [value]="detalhesMissionario.pessoaId" *ngFor="let detalhesMissionario of detalhesMissionariosDaCasaComunitaria">{{detalhesMissionario.pessoaNome}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-3 col-lg-3">
                        <div class="form-group">
                            <label i18n for="nmembro_dtchegada">Data de Chegada</label>
                            <input [(ngModel)]="cadastroEquipeFormacaoFormulario.dataEntrada" name="nmembro_dtchegada"
                                type="date" class="form-control" id="nmembro_dtentrada" i18n-placeholder>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-3 col-lg-3">
                        <div class="form-group">
                            <label i18n for="npastor_pessoa">Apostolado</label>
                            <select name="apostolados_cc" class="form-control" [(ngModel)]="cadastroEquipeFormacaoFormulario.apostoladoId">
                                <option [value]="null">Selecione um apostolado</option>
                                <option [value]="apostolado.id" *ngFor="let apostolado of listaApostolados">{{apostolado.nome}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-3">
                        <div class="form-group">
                            <label i18n for="button-part-submit">&nbsp;</label>
                            <button type="submit" (click)="adicionarMembroEquipeFormacao()" name="button-part-submit"
                                class="form-control btn btn-primary" i18n>Adicionar Membro</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card card-gray-dark color-pallete-box">
                            <div class="card-header">
                                <h3 class="card-title">
                                    <i class="fas fa-people"></i>
                                    <a i18n> Membros da Equipe de Formação </a>
                                </h3>
                                <div class="card-tools"><button class="btn btn-tool" data-card-widget="collapse"
                                        type="button"><i class="fas fa-minus"></i></button></div>
                            </div>
                            <div class="card-body overflow-auto table-responsive p-0">
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>Foto de perfil</th>
                                            <th>Nome</th>                                                
                                            <th>Data de Entrada</th>
                                            <th>Forma de Vida</th>
                                            <th>Nível Formativo</th>
                                            <th>Estado de Vida</th>
                                            <th>Autoridade</th>
                                        </tr>                                        
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let membro of listaEquipeFormacao">                                                
                                            <td>
                                                <div class="widget-user-image">
                                                    <img class="profile-user-img img-fluid img-circle image-size-list elevation-2" [src]="membro.thumbnail == null ? 'assets/images/ppl.png' : membro.thumbnail" alt="User Avatar">
                                                </div>
                                            </td>
                                            <td>{{membro.pessoaNome}}</td>                                                
                                            <td>{{membro.dataChegadaCasaComunitaria | date : 'dd/MM/yyyy'}}</td>
                                            <td>{{membro.formaVidaNome}}</td>
                                            <td>{{membro.nivelFormativoNome}}</td>
                                            <td>{{membro.estadoVidaNome}}</td>
                                            <td>{{membro.apostoladoAutoridadeNome}}</td>                                            
                                        </tr>
                                    </tbody>                                    
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>