export class FamiliaInfo {

    constructor(
        public id: number,
        public pessoaId: number,
        public nomeCompletoPai: string,
        public nomeCompletoMae: string,
        public telefone: string,
        public endereco: string
    )
    {}

}