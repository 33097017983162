import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CasaComunitariaComponent } from './casacomunitaria.component';
import { CasascomunitariaComponent } from './casascomunitaria.component';
import { CasaComunitariaEditComponent } from './casacomunitaria.edit.component';

const casaComunitariaRoutes: Routes = [
    {
        path: 'secured/casacomunitaria',
        component: CasaComunitariaComponent
    },
    {
        path: 'secured/casascomunitaria',
        component: CasascomunitariaComponent
    },
    {
        path: 'secured/casacomunitaria/:id',
        component: CasaComunitariaEditComponent
    }
]

@NgModule({
    imports: [
        RouterModule.forChild(casaComunitariaRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class CasaComunitariaRoutingModule {
    
}