import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DetalhesMensagemParaRL } from './mensagemrl.service';

@Injectable()
export class MensagemRlSharedStateService implements OnDestroy {
  private selectedFilterButton = new BehaviorSubject<string>('Todas');
  private selectedMemberMessageSummary =
    new BehaviorSubject<DetalhesMensagemParaRL>(null);

  currentFilterButton = this.selectedFilterButton.asObservable();
  currentMessageSummary = this.selectedMemberMessageSummary.asObservable();

  constructor() {}

  updateSelectedFilterButtonState(newState: string) {
    this.selectedFilterButton.next(newState);
  }

  updateSelectedMemberMessageSummary(newState: DetalhesMensagemParaRL) {
    this.selectedMemberMessageSummary.next(newState);
    console.log(newState);
  }

  ngOnDestroy(): void {
    this.selectedFilterButton.complete();
    this.selectedMemberMessageSummary.complete();
  }
}
