import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SecuredModule } from '../../layout/secured.module';
import { MissaoConfigFormComponentComponent } from './components/missao-config-form-component/missao-config-form-component.component';
import { MissaoConfigComponent } from './missao-config.component';
import { MissaoConfigRoutes } from './missao-config.routing';
import { MissaoConfigService } from './service/missao-config.service';
import { MissaoDisplayDataComponent } from './components/missao-display-data-component/missao-display-data.component';
import { ListagemPeriodoLiberacaoQuestionarioParecerComponent } from './components/listagem-periodo-liberacao-questionario-parecer-component/listagem-periodo-liberacao-questionario-parecer.component';
import { MissaoConfiguracaoSharedDataService } from './service/missao-configuracao-shared-data.service';
import { CadastroCoordenadorColegiadoComponent } from './components/cadastro-coordenador-colegiado-component/cadastro-coordenador-colegiado.component';
import { ListagemPessoaInfoModalModule } from '../../wop/pessoa/modulo/listagem-pessoainfo-modal/listagempessoainfo.modal.module';
import { CoordenadorColegiadoService } from '../colegiado/coordenadorcolegiado.service';
import { ConselhoService } from './service/conselho.service';
import { CadastroComissaoDiscernimentoComponent } from './components/cadastro-comissao-discernimento-component/cadastro-comissao-discernimento.component';
import { CadastroConselhoComponent } from './components/cadastro-conselho-component/cadastro-conselho.component';
import { ComissaoDiscernimentoService } from './service/comissaodiscernimento.service';

@NgModule({
  imports: [
    CommonModule,
    MissaoConfigRoutes,
    SecuredModule,
    FormsModule,
    ListagemPessoaInfoModalModule,
  ],
  declarations: [
    MissaoConfigComponent,
    MissaoConfigFormComponentComponent,
    MissaoDisplayDataComponent,
    ListagemPeriodoLiberacaoQuestionarioParecerComponent,
    CadastroCoordenadorColegiadoComponent,
    CadastroConselhoComponent,
    CadastroComissaoDiscernimentoComponent,
  ],
  providers: [
    MissaoConfigService,
    MissaoConfiguracaoSharedDataService,
    CoordenadorColegiadoService,
    ConselhoService,
    ComissaoDiscernimentoService,
  ],
})
export class MissaoConfigModule {}
