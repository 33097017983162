export class DadoFormandoCA {

    constructor(
        public id: number,
        public pessoaId: number,
        public ja_permaneceu: boolean,
        public nivel_permaneceu: number,
        public situacao_profissional: number,
        public alianca_missionaria:boolean,
        public casa_comunitaria_id: number,
        public casa_comunitaria_desc:string   
    ){}

}