<secured>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h3 i18n>Listagem dos projetos / Secretarias</h3>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <form (ngSubmit)="buscarProjetos()" #permissoesForm="ngForm" novalidate>
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title">Buscar Projeto</h3>
                                </div>
                                <div class="card-body">
                                    <div class="row">

                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label i18n for="nprojeto_tipo">Tipo do Projeto</label>
                                                <select class="form-control" id="nprojeto_tipo" [(ngModel)]="busca.faseId" name="nprojeto_tipo">
                                                    <option [value]="null" i18n>Selecione o tipo do Projeto</option>
                                                    <option *ngFor="let tipo of tiposProjeto" [value]="tipo.id">{{tipo.nome}}</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label i18n for="lcit_nome">Nome</label>
                                                <input [(ngModel)]="busca.nome" name="lcit_nome" type="text" class="form-control" id="lcit_nome" placeholder="Digite o nome" i18n-placeholder>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label i18n for="nprojeto_dtfund">Ano De Fundação</label>
                                                <input [(ngModel)]="busca.ano" name="nprojeto_dtfund" type="number" class="form-control" id="nprojeto_dtfund" i18n-placeholder>
                                            </div>
                                        </div>

                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label i18n for="nprojeto_r">Regional</label>
                                                <select class="form-control" id="nprojeto_r" [(ngModel)]="busca.regionalId" (change)="carregarMissoes(busca.regionalId)" name="nprojeto_r">
                                                    <option [value]="null" i18n>Selecione um Regional</option>
                                                    <option *ngFor="let reg of regionais" [value]="reg.id">{{reg.nome}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label i18n for="nprojeto_missao">Missão</label>
                                                <select class="form-control" id="nprojeto_missao" [(ngModel)]="busca.missaoId" (change)="carregarCev(busca.missaoId)" name="nprojeto_missao">
                                                    <option [value]="null" i18n>Selecione uma Missão</option>
                                                    <option *ngFor="let missao of missoes" [value]="missao.id">{{missao.nome}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label i18n for="nprojeto_cev">Centro De Evangelização</label>
                                                <select class="form-control" id="nprojeto_cev" [(ngModel)]="busca.centroEvangelizacaoId" name="nprojeto_cev">
                                                    <option [value]="null" i18n>Selecione um centro de Evangelização</option>
                                                    <option *ngFor="let cev of centrosEvangelizacao" [value]="cev.id">{{cev.nome}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label i18n for="nprojeto_pais">País</label>
                                                <select class="form-control" id="nprojeto_pais" [(ngModel)]="busca.paisId" (change)="carregarEstados(busca.paisId)" name="nprojeto_pais">
                                                    <option [value]="null" i18n>Selecione um País</option>
                                                    <option *ngFor="let pais of paises" [value]="pais.id">{{pais.nome}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label i18n for="nprojeto_estado">Estado</label>
                                                <select class="form-control" id="nprojeto_estado" [(ngModel)]="busca.estadoId" name="nprojeto_estado">
                                                    <option [value]="null" i18n>Selecione um Estado</option>
                                                    <option *ngFor="let estado of estados" [value]="estado.id">{{estado.nome}}</option>
                                                </select>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="card-footer">
                                    <button type="submit" class="btn btn-primary float-right" i18n>Buscar</button>
                                    <button type="reset" class="btn btn-default float-right" style="margin-right: 0.5rem;" i18n>Limpar</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">

                        <table-component [tituloSessao]="'Projetos / Secretarias'" [cols]="5" [rows]="busca.quantityOfElements" [totalDePaginas]="totalDePaginas" (eventoBotao)="cliqueNoBotaoListener($event)">
                            <tr tableheader>
                                <th style="width: 10px">#</th>
                                <th>Tipo do Projeto</th>
                                <th>Nome</th>
                                <th>Centro De Evangelização</th>
                                <th></th>
                            </tr>
                            <tr tablebody *ngFor="let prj of projetos">
                                <td>{{prj.id}}</td>
                                <td>{{prj.tipoProjetoNome}}</td>
                                <td>{{prj.nome}}</td>
                                <td>{{prj.centroEvangelizacaoNome}}
                                    <td class="text-center">
                                        <button class="btn btn-sm btn-primary" routerLink="../projeto/{{prj.id}}" title="Editar Projeto/secretaria" alt="Editar os dados do projeto"><i class="fas fa-edit"></i></button>&nbsp;
                                        <button class="btn btn-sm btn-danger" (click)="deletarProjeto(prj.id)" title="Deletar Projeto/secretaria" alt="Deletar o Projeto"><i class="fas fa-trash-alt"></i></button>
                                    </td>
                            </tr>
                        </table-component>
                    </div>
                </div>
            </div>
        </section>
    </div>
</secured>