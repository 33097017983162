import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SecuredModule } from '../../layout/secured.module';
import { RouterModule } from '@angular/router';
import { GrupoDominioLocalComponent } from './grupodominio.local.component';
import { GrupoDominioLocalRoutingModule } from './grupodominio-local-routing.module';
import { GrupoDominioLocalService } from './service/grupodominio.local.service';
import { GruposDominioLocalComponent } from './gruposdominio.local.component';
import { GrupoDominioLocalEditComponent } from './grupodominio.local.edit.component';
import { ElementoGrupoDominioLocalService } from './service/elementogrupodominio.local.service';

@NgModule({
    declarations: [ 
        GrupoDominioLocalComponent,
        GruposDominioLocalComponent,
        GrupoDominioLocalEditComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        GrupoDominioLocalRoutingModule,
        FormsModule,
        SecuredModule
    ],
    providers: [
        GrupoDominioLocalService,
        ElementoGrupoDominioLocalService
    ]
})
export class GrupoDominioLocalModule {

}