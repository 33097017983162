<div class="row">
  <div class="col-12">
    <table-component [cols]="6" [rows]="10"
      [tituloSessao]="'Período de Liberação de Preenchimento - Questionário / Parecer'"
      [totalDePaginas]="paginaPeriodoLiberacao?.totalPages">
      <tr tableheader>
        <th>#</th>
        <th>Questionário</th>
        <th>Período Início</th>
        <th>Período Fim</th>
        <th>Status</th>
        <th></th>
      </tr>
      <tr tablebody *ngFor="let periodo of paginaPeriodoLiberacao?.content">
        <td>{{periodo.questionarioId}}</td>
        <td>{{periodo.questionarioNome}}</td>
        <td>{{periodo.periodoInicio | date : 'dd/MM/yyyy'}} às {{periodo.periodoInicio | date : 'HH:mm:ss'}}</td>
        <td>{{periodo.periodoFim | date : 'dd/MM/yyyy'}} às {{periodo.periodoFim | date : 'HH:mm:ss'}}</td>
        <td>
          <span *ngIf="!periodo.editando">{{periodo.status}}</span>
          <select *ngIf="periodo.editando" class="form-control" [(ngModel)]="periodo.status">
            <option [value]="'EM_DISCERNIMENTO'">Em Discernimento</option>
            <option [value]="'DIVULGADO'">Divulgado</option>
          </select>
        </td>
        <td>
          <div class="row justify-content-center">
            <button *ngIf="!periodo.editando && periodo.status != 'DIVULGADO'" (click)="periodo.editando = true"
              class="btn btn-primary btn-sm">
              <i class="far fa-edit"></i>
            </button>
            <button *ngIf="periodo.editando" (click)="atualizarPeriodo(periodo); periodo.editando = false;"
              class="btn btn-success btn-sm">
              <i class="far fa-save"></i>
            </button>
          </div>
        </td>
      </tr>
    </table-component>
  </div>
</div>