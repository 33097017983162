import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { UsuarioBuilder } from 'src/app/builder/usuario.builder';
export class UsuarioEditMembroComponent {
    constructor(usuarioService, swtAlert2Service, route, router) {
        this.usuarioService = usuarioService;
        this.swtAlert2Service = swtAlert2Service;
        this.route = route;
        this.router = router;
        this.usuarioBuilder = new UsuarioBuilder();
        this.usuario = this.usuarioBuilder.getInstance();
    }
    ngOnInit() {
        this.getUsuarioAtual();
    }
    getUsuarioAtual() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.usuarioService.getCurrentUser();
                this.usuario = response.entity;
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    salvar(usuario) {
        this.usuarioService.update(usuario.id, usuario).then((response) => {
            this.swtAlert2Service.successAlert(response.message);
        }).catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }
}
