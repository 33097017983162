<body class="hold-transition sidebar-mini layout-fixed layout-navbar-fixed layout-footer-fixed" style="height:auto;">
    <div class="wrapper">
        <header-comp></header-comp>

        <div #missionario>
            <left-sidebar-missionario-comp></left-sidebar-missionario-comp>
        </div>
        <div #assistenteMissionario>
            <left-sidebar-assistente-missionario-comp></left-sidebar-assistente-missionario-comp>
        </div>
        <div #acompanhadorMissionario>
            <left-sidebar-acomp-missionario-comp></left-sidebar-acomp-missionario-comp>
        </div>
        <div #comissaoRemanejamento>
            <left-sidebar-comissao-remanejamento-comp></left-sidebar-comissao-remanejamento-comp>
        </div>
        <div #responsavelLocal>
            <left-sidebar-responsavel-local-comp></left-sidebar-responsavel-local-comp>
        </div>
        <div #formadorComunitario>
            <left-sidebar-formador-comunitario-comp></left-sidebar-formador-comunitario-comp>
        </div>
        <div #assistenteFormacao>
            <left-sidebar-assistente-formacao-component></left-sidebar-assistente-formacao-component>
        </div>
        <div #admin>
            <left-sidebar-comp></left-sidebar-comp>
        </div>
        <div #acessoNegado>
            <left-sidebar-acesso-negado-comp></left-sidebar-acesso-negado-comp>
        </div>        
        <span #ref>
            <ng-content></ng-content>
        </span>
        <span *ngIf="!ref.innerHTML.trim()">
            <div class="content-wrapper">
            </div>
        </span>
        <footer-comp></footer-comp>
    </div>
</body>