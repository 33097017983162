import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Token } from '../model/token.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RestService {
  public token: Token;
  public apiURL: string;

  constructor(private httpClient: HttpClient) {
    this.token = localStorage.getItem('token')
      ? JSON.parse(localStorage.getItem('token')!)
      : null;

    this.apiURL = environment.configServidor.apiUrl;
  }

  public postRequest<T, R>(
    url: string,
    data: T,
    custonHttpHeaders?: HttpHeaders
  ): Observable<R> {
    let httpHeader = custonHttpHeaders ? custonHttpHeaders : this.getHeaders();
    return this.httpClient.post<R>(url, data, { headers: httpHeader });
  }

  public putRequest<T, R>(
    url: string,
    data: T,
    custonHttpHeaders?: HttpHeaders
  ): Observable<R> {
    let httpHeader = custonHttpHeaders ? custonHttpHeaders : this.getHeaders();
    return this.httpClient.put<R>(url, JSON.stringify(data), {
      headers: httpHeader,
    });
  }

  public patchRequest<T, R>(
    url: string,
    data: T,
    custonHttpHeaders?: HttpHeaders
  ): Observable<R> {
    let httpHeader = custonHttpHeaders ? custonHttpHeaders : this.getHeaders();
    return this.httpClient.put<R>(url, JSON.stringify(data), {
      headers: httpHeader,
    });
  }

  public deleteRequest<R>(
    url: string,
    custonHttpHeaders?: HttpHeaders
  ): Observable<R> {
    let httpHeader = custonHttpHeaders ? custonHttpHeaders : this.getHeaders();
    return this.httpClient.delete<R>(url, {
      headers: httpHeader,
    });
  }

  public getRequest<R>(
    url: string,
    custonHttpHeaders?: HttpHeaders
  ): Observable<R> {
    let httpHeader = custonHttpHeaders ? custonHttpHeaders : this.getHeaders();
    return this.httpClient.get<R>(url, {
      headers: httpHeader,
    });
  }

  private getHeaders(): HttpHeaders {
    this.getTokenFromLocalStorage();
    return new HttpHeaders({
      'Content-Type': 'application/json; charset=UTF-8',
      Authorization: 'Bearer ' + (this.token ? this.token.access_token : ''),
    });
  }

  private getCustomHeaders(enctype: string): HttpHeaders {
    this.getTokenFromLocalStorage();
    return new HttpHeaders({
      'Content-Type': enctype,
      Authorization: 'Bearer ' + (this.token ? this.token.access_token : ''),
    });
  }

  private getHeadersNoContentType(): HttpHeaders {
    this.getTokenFromLocalStorage();
    return new HttpHeaders({
      Authorization: 'Bearer ' + (this.token ? this.token.access_token : ''),
    });
  }

  private getTokenFromLocalStorage() {
    if (!this.token) {
      this.token = localStorage.getItem('token')
        ? JSON.parse(localStorage.getItem('token')!)
        : null;
    }
  }
}
