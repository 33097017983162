<secured>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h3 i18n>Criar Acesso - Usuário/Estruturas</h3>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <form (ngSubmit)="salvar(acessoUsuarioEstrutura)" #acessoForm="ngForm" novalidate>
                <div class="card card-default color-pallete-box">
                    <div class="card-header">
                        <h3 class="card-title">
                            <i class="fas fa-user-lock"></i>
                            <a i18n> Selecione a Pessoa e sua Autorização <br><small>O resultado trará [usuário] - [nome da pessoa]</small> </a>
                        </h3>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-12 col-md-8 col-lg-8">
                                <div class="form-group">
                                    <label i18n for="pessoa">Pessoa</label>
                                    <select2-component [name]="'pessoa'" [url]="pessoaSearchUrl" [multiplo]="false" (elementoSelecionado)="setarElementoSelecionado($event)" [required]="true">
                                        <option id="s2options" value="">Pesquisar pessoas</option>
                                    </select2-component>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-4 col-lg-4">
                                <div class="form-group">
                                    <label i18n for="naue_autorizacao">Autorização</label>
                                    <select class="form-control" id="naue_autorizacao" [(ngModel)]="acessoUsuarioEstrutura.autorizacaoId" name="naue_autorizacao">
                                        <option [value]="null" i18n>Selecione uma autorização</option>
                                        <option *ngFor="let autorizacao of autorizacoes" [value]="autorizacao.id">{{autorizacao.nomeExibicao}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <button type="submit" class="btn btn-primary float-right" i18n>Salvar</button>
                    </div>
                </div>
            </form>
        </section>
        <section class="content">
            <div class="row">
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <div class="card card-default color-pallete-box">
                        <div class="card-header">
                            <h3 class="card-title">
                                <i class="fas fa-map-marked-alt"></i>
                                <a i18n> Regionais </a>
                            </h3>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label i18n for="reg">Regional</label>
                                        <select class="form-control" id="reg" [(ngModel)]="regionalToAddId" name="reg">
                                            <option [value]="null" i18n>Selecione um Regional</option>
                                            <option *ngFor="let reg of regionais" [value]="reg.id">{{reg.nome}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <button (click)="adicionarAcessoRegional(regionalToAddId)" class="btn btn-block btn-primary">Adicionar</button>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="row">
                                <div class="col-12">
                                    <table class="table table-sm">
                                        <thead>
                                            <tr>                                                
                                                <th>Regional</th>
                                                <th></th>                                                
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let reg of regionaisAdicionados">
                                                <td>{{reg.nome}}</td>
                                                <td class="text-center"><button class="btn btn-sm btn-danger" (click)="removerAcessoRegional(reg.id)" title="Deletar acesso" alt="Deletar acesso"><i class="fas fa-trash-alt"></i></button></td>                                                
                                            </tr>                                        
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <div class="card card-default color-pallete-box">
                        <div class="card-header">
                            <h3 class="card-title">
                                <i class="fas fa-map-marked"></i>
                                <a i18n> Missões </a>
                            </h3>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label i18n for="mi">Missão</label>
                                        <select class="form-control" id="mi" [(ngModel)]="missaoToAddId" name="mi">
                                            <option [value]="null" i18n>Selecione uma Missão</option>
                                            <option *ngFor="let missao of missoes" [value]="missao.id">{{missao.nome}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <button (click)="adicionarAcessoMissao(missaoToAddId)" class="btn btn-block btn-primary">Adicionar</button>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="row">
                                <div class="col-12">
                                    <table class="table table-sm">
                                        <thead>
                                            <tr>                                                
                                                <th>Missão</th>
                                                <th></th>                                                
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let mi of missoesAdicionados">
                                                <td>{{mi.nome}}</td>
                                                <td class="text-center"><button class="btn btn-sm btn-danger" (click)="removerAcessoMissao(mi.id)" title="Deletar acesso" alt="Deletar acesso"><i class="fas fa-trash-alt"></i></button></td>                                                
                                            </tr>                                        
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <div class="card card-default color-pallete-box">
                        <div class="card-header">
                            <h3 class="card-title">
                                <i class="fas fa-broadcast-tower"></i>
                                <a i18n> Irradiações </a>
                            </h3>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label i18n for="ir">Irradiação</label>
                                        <select class="form-control" id="ir" [(ngModel)]="irradiacaoToAddId" name="ir">
                                            <option [value]="null" i18n>Selecione uma Irradiação</option>
                                            <option *ngFor="let irr of irradiacoes" [value]="irr.id">{{irr.nome}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <button (click)="adicionarAcessoIrradiacao(irradiacaoToAddId)" class="btn btn-block btn-primary">Adicionar</button>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="row">
                                <div class="col-12">
                                    <table class="table table-sm">
                                        <thead>
                                            <tr>                                                
                                                <th>Irradiação</th>
                                                <th></th>                                                
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let ir of irradiacoesAdicionados">
                                                <td>{{ir.nome}}</td>
                                                <td class="text-center"><button class="btn btn-sm btn-danger" (click)="removerAcessoIrradiacao(ir.id)" title="Deletar acesso" alt="Deletar acesso"><i class="fas fa-trash-alt"></i></button></td>                                                
                                            </tr>                                        
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <div class="card card-default color-pallete-box">
                        <div class="card-header">
                            <h3 class="card-title">
                                <i class="fas fa-wifi"></i>
                                <a i18n> Difusões </a>
                            </h3>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label i18n for="dif">Difusão</label>
                                        <select class="form-control" id="dif" [(ngModel)]="difusaoToAddId" name="dif">
                                            <option [value]="null" i18n>Selecione uma Difusão</option>
                                            <option *ngFor="let dif of difusoes" [value]="dif.id">{{dif.nome}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <button (click)="adicionarAcessoDifusao(difusaoToAddId)" class="btn btn-block btn-primary">Adicionar</button>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="row">
                                <div class="col-12">
                                    <table class="table table-sm">
                                        <thead>
                                            <tr>                                                
                                                <th>Difusão</th>
                                                <th></th>                                                
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let di of difusoesAdicionados">
                                                <td>{{di.nome}}</td>
                                                <td class="text-center"><button class="btn btn-sm btn-danger" (click)="removerAcessoDifusao(di.id)" title="Deletar acesso" alt="Deletar acesso"><i class="fas fa-trash-alt"></i></button></td>                                                
                                            </tr>                                        
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <div class="card card-default color-pallete-box">
                        <div class="card-header">
                            <h3 class="card-title">
                                <i class="fas fa-place-of-worship"></i>
                                <a i18n> Centros de Evangelização </a>
                            </h3>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label i18n for="cev">Centro de Evangelização</label>
                                        <select class="form-control" id="cev" [(ngModel)]="centroEvangelizacaoToAddId" name="cev">
                                            <option [value]="null" i18n>Selecione um CEV</option>
                                            <option *ngFor="let cev of centrosEvangelizacao" [value]="cev.id">{{cev.nome}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <button (click)="adicionarAcessoCentroEvangelizacao(centroEvangelizacaoToAddId)" class="btn btn-block btn-primary">Adicionar</button>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="row">
                                <div class="col-12">
                                    <table class="table table-sm">
                                        <thead>
                                            <tr>                                                
                                                <th>Centro de Evangelização</th>
                                                <th></th>                                                
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let cev of centrosEvangelizacaoAdicionados">
                                                <td>{{cev.nome}}</td>
                                                <td class="text-center"><button class="btn btn-sm btn-danger" (click)="removerAcessoCentroEvangelizacao(cev.id)" title="Deletar acesso" alt="Deletar acesso"><i class="fas fa-trash-alt"></i></button></td>                                                
                                            </tr>                                        
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <div class="card card-default color-pallete-box">
                        <div class="card-header">
                            <h3 class="card-title">
                                <i class="fas fa-place-of-worship"></i>
                                <a i18n> Grupos de Oração </a>
                            </h3>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label i18n for="go">Grupo de Oração</label>
                                        <select class="form-control" id="go" [(ngModel)]="grupoOracaoToAddId" name="go">
                                            <option [value]="null" i18n>Selecione um Grupo de Oração</option>
                                            <option *ngFor="let go of gruposOracao" [value]="go.id">{{go.nome}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <button (click)="adicionarAcessoGrupoOracao(grupoOracaoToAddId)" class="btn btn-block btn-primary">Adicionar</button>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="row">
                                <div class="col-12">
                                    <table class="table table-sm">
                                        <thead>
                                            <tr>                                                
                                                <th>Grupo de Oração</th>
                                                <th></th>                                                
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let go of gruposOracaoAdicionados">
                                                <td>{{go.nome}}</td>
                                                <td class="text-center"><button class="btn btn-sm btn-danger" (click)="removerAcessoGrupoOracao(go.id)" title="Deletar acesso" alt="Deletar acesso"><i class="fas fa-trash-alt"></i></button></td>                                                
                                            </tr>                                        
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <div class="card card-default color-pallete-box">
                        <div class="card-header">
                            <h3 class="card-title">
                                <i class="fas fa-user-lock"></i>
                                <a i18n> Público Grupo de Oração </a>
                            </h3>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label i18n for="pgo">Público Grupo de Oração</label>
                                        <select class="form-control" id="pgo" [(ngModel)]="publicoGrupoOracaoToAddId" name="pgo">
                                            <option [value]="null" i18n>Selecione um Público de Grupo de Oração</option>
                                            <option *ngFor="let pgo of publicosGrupoOracao" [value]="pgo.id">{{pgo.nome}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <button (click)="adicionarAcessoPublicoGrupoOracao(publicoGrupoOracaoToAddId)" class="btn btn-block btn-primary">Adicionar</button>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="row">
                                <div class="col-12">
                                    <table class="table table-sm">
                                        <thead>
                                            <tr>                                                
                                                <th>Público de Grupo de Oração</th>
                                                <th></th>                                                
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let pgo of publicosGrupoOracaoAdicionados">
                                                <td>{{pgo.nome}}</td>
                                                <td class="text-center"><button class="btn btn-sm btn-danger" (click)="removerAcessoPublicoGrupoOracao(pgo.id)" title="Deletar acesso" alt="Deletar acesso"><i class="fas fa-trash-alt"></i></button></td>                                                
                                            </tr>                                        
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <div class="card card-default color-pallete-box">
                        <div class="card-header">
                            <h3 class="card-title">
                                <i class="fas fa-thumbtack"></i>
                                <a i18n> Células </a>
                            </h3>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label i18n for="cel">Célula</label>
                                        <select class="form-control" id="cel" [(ngModel)]="celulaToAddId" name="cel">
                                            <option [value]="null" i18n>Selecione uma Célula</option>
                                            <option *ngFor="let ce of celulas" [value]="ce.id">{{ce.nome}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <button (click)="adicionarAcessoCelula(celulaToAddId)" class="btn btn-block btn-primary">Adicionar</button>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="row">
                                <div class="col-12">
                                    <table class="table table-sm">
                                        <thead>
                                            <tr>                                                
                                                <th>Célula</th>
                                                <th></th>                                                
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let ce of celulasAdicionados">
                                                <td>{{ce.nome}}</td>
                                                <td class="text-center"><button class="btn btn-sm btn-danger" (click)="removerAcessoCelula(ce.id)" title="Deletar acesso" alt="Deletar acesso"><i class="fas fa-trash-alt"></i></button></td>                                                
                                            </tr>                                        
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <div class="card card-default color-pallete-box">
                        <div class="card-header">
                            <h3 class="card-title">
                                <i class="fas fa-home"></i>
                                <a i18n> Casas Comunitárias </a>
                            </h3>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label i18n for="cc">Casa Comunitária</label>
                                        <select class="form-control" id="cc" [(ngModel)]="casaComunitariaToAddId" name="cc">
                                            <option [value]="null" i18n>Selecione uma Casa Comunitária</option>
                                            <option *ngFor="let cc of casasComunitarias" [value]="cc.id">{{cc.nome}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <button (click)="adicionarAcessoCasaComunitaria(casaComunitariaToAddId)" class="btn btn-block btn-primary">Adicionar</button>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="row">
                                <div class="col-12">
                                    <table class="table table-sm">
                                        <thead>
                                            <tr>                                                
                                                <th>Casa Comunitária</th>
                                                <th></th>                                                
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let cc of casasComunitariasAdicionados">
                                                <td>{{cc.nome}}</td>
                                                <td class="text-center"><button class="btn btn-sm btn-danger" (click)="removerAcessoCasaComunitaria(cc.id)" title="Deletar acesso" alt="Deletar acesso"><i class="fas fa-trash-alt"></i></button></td>                                                
                                            </tr>                                        
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <div class="card card-default color-pallete-box">
                        <div class="card-header">
                            <h3 class="card-title">
                                <i class="fas fa-guitar"></i>
                                <a i18n> Ministérios </a>
                            </h3>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label i18n for="mn">Ministério</label>
                                        <select class="form-control" id="mn" [(ngModel)]="ministerioToAddId" name="mn">
                                            <option [value]="null" i18n>Selecione um Ministério</option>
                                            <option *ngFor="let mn of ministerios" [value]="mn.id">{{mn.nome}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <button (click)="adicionarAcessoMinisterio(ministerioToAddId)" class="btn btn-block btn-primary">Adicionar</button>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="row">
                                <div class="col-12">
                                    <table class="table table-sm">
                                        <thead>
                                            <tr>                                                
                                                <th>Ministérios</th>
                                                <th></th>                                                
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let mn of ministeriosAdicionados">
                                                <td>{{mn.nome}}</td>
                                                <td class="text-center"><button class="btn btn-sm btn-danger" (click)="removerAcessoMinisterio(mn.id)" title="Deletar acesso" alt="Deletar acesso"><i class="fas fa-trash-alt"></i></button></td>                                                
                                            </tr>                                        
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-4 col-lg-4">
                    <div class="card card-default color-pallete-box">
                        <div class="card-header">
                            <h3 class="card-title">
                                <i class="fas fa-folder"></i>
                                <a i18n> Projetos </a>
                            </h3>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label i18n for="pj">Projeto</label>
                                        <select class="form-control" id="pj" [(ngModel)]="projetoToAddId" name="pj">
                                            <option [value]="null" i18n>Selecione um Projeto</option>
                                            <option *ngFor="let pj of projetos" [value]="pj.id">{{pj.nome}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <button (click)="adicionarAcessoProjeto(projetoToAddId)" class="btn btn-block btn-primary">Adicionar</button>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="row">
                                <div class="col-12">
                                    <table class="table table-sm">
                                        <thead>
                                            <tr>                                                
                                                <th>Projetos</th>
                                                <th></th>                                                
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let pj of projetosAdicionados">
                                                <td>{{pj.nome}}</td>
                                                <td class="text-center"><button class="btn btn-sm btn-danger" (click)="removerAcessoProjeto(pj.id)" title="Deletar acesso" alt="Deletar acesso"><i class="fas fa-trash-alt"></i></button></td>                                                
                                            </tr>                                        
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</secured>