export class Login {

    constructor(
        public username : string,
        public password : string,
        public grant_type : string = 'password'
    ) {}

    public toString() : string {
        return "username="+this.username+"&password="+this.password+"&grant_type="+this.grant_type;
    }
}