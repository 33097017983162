/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./style/styles.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./left-sidebar-menu-item.component";
var styles_LeftSidebarMenuItemComponent = [i0.styles];
var RenderType_LeftSidebarMenuItemComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LeftSidebarMenuItemComponent, data: {} });
export { RenderType_LeftSidebarMenuItemComponent as RenderType_LeftSidebarMenuItemComponent };
export function View_LeftSidebarMenuItemComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { menuItem: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 10, "li", [["class", "nav-item menu-item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, [[1, 0], ["menuItem", 1]], null, 9, "a", [["class", "nav-link "], ["routerLinkActive", "active"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 671744, [[3, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(4, 1), i1.ɵdid(5, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, [2, i2.RouterLink], [2, i2.RouterLinkWithHref]], { routerLinkActiveOptions: [0, "routerLinkActiveOptions"], routerLinkActive: [1, "routerLinkActive"] }, null), i1.ɵqud(603979776, 2, { links: 1 }), i1.ɵqud(603979776, 3, { linksWithHrefs: 1 }), i1.ɵpod(8, { exact: 0 }), (_l()(), i1.ɵeld(9, 0, null, null, 0, "i", [["class", "far fa-circle nav-icon "]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _ck(_v, 4, 0, _co.path); _ck(_v, 3, 0, currVal_2); var currVal_3 = _ck(_v, 8, 0, true); var currVal_4 = "active"; _ck(_v, 5, 0, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 3).target; var currVal_1 = i1.ɵnov(_v, 3).href; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_5 = _co.name; _ck(_v, 11, 0, currVal_5); }); }
export function View_LeftSidebarMenuItemComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "left-sidebar-menu-item-component", [], null, null, null, View_LeftSidebarMenuItemComponent_0, RenderType_LeftSidebarMenuItemComponent)), i1.ɵdid(1, 4308992, null, 0, i4.LeftSidebarMenuItemComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LeftSidebarMenuItemComponentNgFactory = i1.ɵccf("left-sidebar-menu-item-component", i4.LeftSidebarMenuItemComponent, View_LeftSidebarMenuItemComponent_Host_0, { path: "path", name: "name" }, { isActiveEventEmmiter: "isActiveEventEmmiter" }, []);
export { LeftSidebarMenuItemComponentNgFactory as LeftSidebarMenuItemComponentNgFactory };
