import { Component, OnInit } from '@angular/core';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { CadastroPublicoLocalService } from '../../local/cadastropublico/service/cadastro.publico.local.service';
import { Router } from '@angular/router';
import { TermoTratamentoDados } from 'src/app/model/termotratamentodados.model';
import { AceiteTermoTratamentoDados } from 'src/app/model/aceitetermotratamentodados.model';
import { AceiteTermoTratamentoDadosService } from './service/aceitetermotratamentodados.service';
import { TermoTratamentoDadosBuilder } from 'src/app/builder/termotratamentodados.builder';
import { AceiteTermoTratamentoDadosBuilder } from 'src/app/builder/aceitetermotratamentodados.builder';

@Component({
    selector: 'aceitetermotratamentodados',
    templateUrl: './aceitetermotratamentodados.component.html'
})
export class AceiteTermoTratamentoDadosComponent implements OnInit {

    public termoVigente : TermoTratamentoDados;
    public aceiteTermoUso: AceiteTermoTratamentoDados;

    constructor(
        public cadastroPublicoService: CadastroPublicoLocalService,
        public aceiteTermoTratamentoDadosService: AceiteTermoTratamentoDadosService,
        public termoTratamentoDadosBuilder : TermoTratamentoDadosBuilder,
        public aceiteTermoTratamentoDadosBuilder: AceiteTermoTratamentoDadosBuilder,
        public swtAlert2Service: SwtAlert2Service,
        private router: Router
    ) 
    {
        this.termoVigente = this.termoTratamentoDadosBuilder.getInstance();
        this.aceiteTermoUso = this.aceiteTermoTratamentoDadosBuilder.getInstance();
    }

    ngOnInit() {    
        this.initialize();
    }

    async initialize() : Promise<any> {
        await this.didUserAcceptDataProcessingTerm();
        await this.carregarTermoVigente(); 
    }

    async didUserAcceptDataProcessingTerm() : Promise<any> {

        try
        {
            let response = await this.aceiteTermoTratamentoDadosService.usuarioAceitouTermo();
            let usuarioAceitouTermoTratamentoDados = response.entity;

            if(usuarioAceitouTermoTratamentoDados)
            {
                this.router.navigate(['/secured/']);
            }
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
            this.router.navigate(['/login']);
        }
        
    }

    async carregarTermoVigente() : Promise<any> {

        try
        {
            let response = await this.cadastroPublicoService.getTermoTratamentoDadosVigente();
            this.termoVigente = response.entity
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async aceitarTermoTratamentoDados() : Promise<any> {

        try
        {
            let response = await this.aceiteTermoTratamentoDadosService.aceitarTermoVigente();
            this.swtAlert2Service.successAlert(response.entity);
            sessionStorage.setItem("usuarioAceitouTermoTratamentoDados", "1");
            setTimeout(() => {
                this.router.navigate(['/secured/']);
            },3000)
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
            this.router.navigate(['/login']);
        }

    }

}