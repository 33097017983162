import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SecuredModule } from '../../layout/secured.module';
import { RouterModule } from '@angular/router';
import { CasaComunitariaRoutingModule } from './casacomunitaria-routing.module';
import { CasaComunitariaComponent } from './casacomunitaria.component';
import { CasaComunitariaEditComponent } from './casacomunitaria.edit.component';
import { CasascomunitariaComponent } from './casascomunitaria.component';
import { CasaComunitariaService } from './service/casacomunitaria.service';

@NgModule({
    declarations: [ 
        CasaComunitariaComponent,
        CasascomunitariaComponent,
        CasaComunitariaEditComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        CasaComunitariaRoutingModule,
        FormsModule,
        SecuredModule
    ],
    providers: [
        CasaComunitariaService
    ]
})
export class CasaComunitariaModule {

}