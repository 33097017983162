/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./components/left-sidebar-aside-container/left-sidebar-aside-container.component.ngfactory";
import * as i2 from "./components/left-sidebar-aside-container/left-sidebar-aside-container.component";
import * as i3 from "../header/service/logged-in-user-shared-data.service";
import * as i4 from "../../local/mensagem-rl/service/image.haddler.service";
import * as i5 from "./components/left-sidebar-menu/left-sidebar-menu.component.ngfactory";
import * as i6 from "./components/left-sidebar-menu/left-sidebar-menu.component";
import * as i7 from "./components/left-sidebar-menu-item/left-sidebar-menu-item.component.ngfactory";
import * as i8 from "./components/left-sidebar-menu-item/left-sidebar-menu-item.component";
import * as i9 from "./left-sidebar-assistente-formacao.component";
var styles_LeftSidebarAssistenteFormacaoComponent = [];
var RenderType_LeftSidebarAssistenteFormacaoComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LeftSidebarAssistenteFormacaoComponent, data: {} });
export { RenderType_LeftSidebarAssistenteFormacaoComponent as RenderType_LeftSidebarAssistenteFormacaoComponent };
export function View_LeftSidebarAssistenteFormacaoComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 27, "left-sidebar-aside-container-component", [], null, null, null, i1.View_LeftSidebarAsideContainerComponent_0, i1.RenderType_LeftSidebarAsideContainerComponent)), i0.ɵdid(1, 114688, null, 0, i2.LeftSidebarAsideContainerComponent, [i3.LoggedInUserSharedDataService, i4.ImageHandlerService], null, null), (_l()(), i0.ɵeld(2, 0, null, 0, 25, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 2, "li", [["class", "nav-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["MENU"])), (_l()(), i0.ɵeld(6, 0, null, null, 8, "left-sidebar-menu-component", [], null, null, null, i5.View_LeftSidebarMenuComponent_0, i5.RenderType_LeftSidebarMenuComponent)), i0.ɵdid(7, 114688, null, 0, i6.LeftSidebarMenuComponent, [], { icon: [0, "icon"], name: [1, "name"], isMenuActive: [2, "isMenuActive"] }, null), (_l()(), i0.ɵeld(8, 0, null, 0, 6, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "left-sidebar-menu-item-component", [], null, [[null, "isActiveEventEmmiter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("isActiveEventEmmiter" === en)) {
        var pd_0 = (_co.updateIsActive("Pessoa", $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_LeftSidebarMenuItemComponent_0, i7.RenderType_LeftSidebarMenuItemComponent)), i0.ɵdid(10, 4308992, null, 0, i8.LeftSidebarMenuItemComponent, [], { path: [0, "path"], name: [1, "name"] }, { isActiveEventEmmiter: "isActiveEventEmmiter" }), (_l()(), i0.ɵeld(11, 0, null, null, 1, "left-sidebar-menu-item-component", [], null, [[null, "isActiveEventEmmiter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("isActiveEventEmmiter" === en)) {
        var pd_0 = (_co.updateIsActive("Pessoa", $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_LeftSidebarMenuItemComponent_0, i7.RenderType_LeftSidebarMenuItemComponent)), i0.ɵdid(12, 4308992, null, 0, i8.LeftSidebarMenuItemComponent, [], { path: [0, "path"], name: [1, "name"] }, { isActiveEventEmmiter: "isActiveEventEmmiter" }), (_l()(), i0.ɵeld(13, 0, null, null, 1, "left-sidebar-menu-item-component", [], null, [[null, "isActiveEventEmmiter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("isActiveEventEmmiter" === en)) {
        var pd_0 = (_co.updateIsActive("Pessoa", $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_LeftSidebarMenuItemComponent_0, i7.RenderType_LeftSidebarMenuItemComponent)), i0.ɵdid(14, 4308992, null, 0, i8.LeftSidebarMenuItemComponent, [], { path: [0, "path"], name: [1, "name"] }, { isActiveEventEmmiter: "isActiveEventEmmiter" }), (_l()(), i0.ɵeld(15, 0, null, null, 4, "left-sidebar-menu-component", [], null, null, null, i5.View_LeftSidebarMenuComponent_0, i5.RenderType_LeftSidebarMenuComponent)), i0.ɵdid(16, 114688, null, 0, i6.LeftSidebarMenuComponent, [], { icon: [0, "icon"], name: [1, "name"], isMenuActive: [2, "isMenuActive"] }, null), (_l()(), i0.ɵeld(17, 0, null, 0, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(18, 0, null, null, 1, "left-sidebar-menu-item-component", [], null, [[null, "isActiveEventEmmiter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("isActiveEventEmmiter" === en)) {
        var pd_0 = (_co.updateIsActive("C\u00E9lula", $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_LeftSidebarMenuItemComponent_0, i7.RenderType_LeftSidebarMenuItemComponent)), i0.ɵdid(19, 4308992, null, 0, i8.LeftSidebarMenuItemComponent, [], { path: [0, "path"], name: [1, "name"] }, { isActiveEventEmmiter: "isActiveEventEmmiter" }), (_l()(), i0.ɵeld(20, 0, null, null, 2, "li", [["class", "nav-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(21, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["CONFIGURA\u00C7\u00C2O"])), (_l()(), i0.ɵeld(23, 0, null, null, 4, "left-sidebar-menu-component", [], null, null, null, i5.View_LeftSidebarMenuComponent_0, i5.RenderType_LeftSidebarMenuComponent)), i0.ɵdid(24, 114688, null, 0, i6.LeftSidebarMenuComponent, [], { icon: [0, "icon"], name: [1, "name"], isMenuActive: [2, "isMenuActive"] }, null), (_l()(), i0.ɵeld(25, 0, null, 0, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(26, 0, null, null, 1, "left-sidebar-menu-item-component", [], null, [[null, "isActiveEventEmmiter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("isActiveEventEmmiter" === en)) {
        var pd_0 = (_co.updateIsActive("Usu\u00E1rio", $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_LeftSidebarMenuItemComponent_0, i7.RenderType_LeftSidebarMenuItemComponent)), i0.ɵdid(27, 4308992, null, 0, i8.LeftSidebarMenuItemComponent, [], { path: [0, "path"], name: [1, "name"] }, { isActiveEventEmmiter: "isActiveEventEmmiter" })], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = "fas fa-id-card"; var currVal_1 = "Pessoa"; var currVal_2 = _co.isMenuActive("Pessoa"); _ck(_v, 7, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = "/secured/perfil"; var currVal_4 = "Perfil"; _ck(_v, 10, 0, currVal_3, currVal_4); var currVal_5 = "/secured/dadosvocacionais"; var currVal_6 = "Dados Vocacionais"; _ck(_v, 12, 0, currVal_5, currVal_6); var currVal_7 = "/secured/saude"; var currVal_8 = "Sa\u00FAde"; _ck(_v, 14, 0, currVal_7, currVal_8); var currVal_9 = "fas fa-thumbtack"; var currVal_10 = "C\u00E9lula"; var currVal_11 = _co.isMenuActive("C\u00E9lula"); _ck(_v, 16, 0, currVal_9, currVal_10, currVal_11); var currVal_12 = "/secured/af/celulas"; var currVal_13 = "Listar"; _ck(_v, 19, 0, currVal_12, currVal_13); var currVal_14 = "fas fa-thumbtack"; var currVal_15 = "Usu\u00E1rio"; var currVal_16 = _co.isMenuActive("Usu\u00E1rio"); _ck(_v, 24, 0, currVal_14, currVal_15, currVal_16); var currVal_17 = "/secured/meuusuario"; var currVal_18 = "Meu Usu\u00E1rio"; _ck(_v, 27, 0, currVal_17, currVal_18); }, null); }
export function View_LeftSidebarAssistenteFormacaoComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "left-sidebar-assistente-formacao-component", [], null, null, null, View_LeftSidebarAssistenteFormacaoComponent_0, RenderType_LeftSidebarAssistenteFormacaoComponent)), i0.ɵdid(1, 114688, null, 0, i9.LeftSidebarAssistenteFormacaoComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LeftSidebarAssistenteFormacaoComponentNgFactory = i0.ɵccf("left-sidebar-assistente-formacao-component", i9.LeftSidebarAssistenteFormacaoComponent, View_LeftSidebarAssistenteFormacaoComponent_Host_0, {}, {}, []);
export { LeftSidebarAssistenteFormacaoComponentNgFactory as LeftSidebarAssistenteFormacaoComponentNgFactory };
