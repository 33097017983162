import { Component, OnInit, OnChanges } from '@angular/core';
import { Usuario } from 'src/app/model/usuario.model';
import { UsuarioService } from './service/usuario.service';
import { Alerta } from 'src/app/model/alerta.model';
import { Autorizacao } from 'src/app/model/autorizacao.model';
import { AutorizacaoService } from '../autorizacao/service/autorizacao.service';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';

@Component({
    selector: 'usuarios',
    templateUrl: './usuarios.component.html'
})
export class UsuariosComponent implements OnInit {

    public autorizacoes: Array<Autorizacao>;
    public usuarios: Array<Usuario>;
    public busca: any;
    public totalDePaginas: number;
    public usuarioAtual: Usuario;

    constructor(
        private usuarioService: UsuarioService,
        private autorizacaoService: AutorizacaoService,
        private swtAlert2Service: SwtAlert2Service
    ) {
        this.autorizacoes = new Array<Autorizacao>();
        this.usuarios = new Array<Usuario>();
        this.busca = {
            username: undefined,
            email: undefined,
            autorizacaoId: null,
            isAccountNonLocked: true,
            isAccountNonExpired: true,
            isCredentialsNonExpired: true,
            isEnabled: true,
            pageNumber: 0,
            quantityOfElements: 10
        };
    }

    ngOnInit() {
        this.getUsuarioAtual();
        this.buscarUsuarios();
        this.carregarAutorizacoes();
    }

    async getUsuarioAtual() : Promise<any> {

        try 
        {
            let response = await this.usuarioService.getCurrentUser();   
            this.usuarioAtual = response.entity;        
        }
        catch(e)
        {
            console.log(e);
        }

    }

    clear(): void {
        for (var prop in this.busca) {
            if (this.busca.hasOwnProperty(prop)) {
                this.busca[prop] = '';
            }
        }
    }

    carregarAutorizacoes(): void {
        this.autorizacaoService.findAll().then((lista: any) => {
            this.autorizacoes = lista.entity;
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    cliqueNoBotaoListener(botao) {
        this.busca.pageNumber = botao.numero - 1;
        this.buscarUsuarios();
    }

    buscarUsuarios(): void {
        this.usuarioService.buscarUsuarios(this.busca).then((response: any) => {
            this.usuarios = response.content;
            this.totalDePaginas = response.totalPages;
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    deletarUsuario(id: number): void {

        if (confirm('Deseja deletar este usuário?')) {
            this.usuarioService.delete(id)
                .then((response: any) => {
                    this.swtAlert2Service.successAlert(response.message);
                    this.buscarUsuarios();
                })
                .catch((err) => {
                    this.swtAlert2Service.errorAlert(err.error.errors);
                    console.log(err);
                });
        }
    }
}