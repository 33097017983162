import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { AutorizacaoBuilder } from 'src/app/builder/autorizacao.builder';
export class AutorizacaoLocalEditComponent {
    constructor(autorizacaoService, permissaoService, swtAlert2Service, route, router) {
        this.autorizacaoService = autorizacaoService;
        this.permissaoService = permissaoService;
        this.swtAlert2Service = swtAlert2Service;
        this.route = route;
        this.router = router;
        this.autorizacaoBuilder = new AutorizacaoBuilder();
        this.autorizacao = this.autorizacaoBuilder.getInstance();
        this.permissoes = new Array();
        this.permissoesNaoVinculadas = new Array();
        this.busca = {
            autorizacaoId: this.autorizacaoId,
            pageNumber: 0,
            quantityOfElements: 10
        };
        this.permissaoSelecionada = null;
    }
    ngOnInit() {
        this.autorizacaoId = Number(this.route.snapshot.paramMap.get('id'));
        this.copiarPermissoesRequest = {
            autorizacaoId: this.autorizacaoId,
            copiarDeAutorizacaoId: null
        };
        this.carregarAutorizacoes();
        this.carregarAutorizacao(this.autorizacaoId);
        this.carregarPermissoesNaoVinculadas(this.autorizacaoId);
        this.buscarPermissoesPorAutorizacao();
    }
    carregarAutorizacoes() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.autorizacaoService.findAll();
                this.autorizacoes = response.entity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    copiarPermissoes() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.copiarPermissoesRequest.copiarDeAutorizacaoId == null) {
                this.swtAlert2Service.warningAlert("Selecione uma autorização para realizar a copia das permissões.");
            }
            else if (confirm("Deseja copiar as permissões da autorização selecionada para a autorização atual?")) {
                try {
                    let response = yield this.autorizacaoService.copiarPermissoes(this.copiarPermissoesRequest);
                    this.carregarPermissoesNaoVinculadas(this.autorizacaoId);
                    this.buscarPermissoesPorAutorizacao();
                    this.swtAlert2Service.successAlert(response.entity);
                }
                catch (err) {
                    this.swtAlert2Service.errorAlert(err.error.errors);
                }
            }
        });
    }
    vincularPermissao() {
        if (this.permissaoSelecionada != null) {
            let vincularForm = {
                autorizacaoId: this.autorizacaoId,
                permissaoId: this.permissaoSelecionada
            };
            this.permissaoService.vincular(vincularForm).then((response) => {
                this.carregarPermissoesNaoVinculadas(this.autorizacaoId);
                this.buscarPermissoesPorAutorizacao();
                this.permissaoSelecionada = null;
                this.swtAlert2Service.successAlert(response.message);
            }).catch((err) => {
                this.swtAlert2Service.errorAlert(err.error.errors);
            });
        }
        else {
            this.swtAlert2Service.warningAlert('Selecione uma permissão');
        }
    }
    desvincularPermissao(id) {
        if (confirm('Deseja desvincular esta permissão?')) {
            let vincularForm = {
                autorizacaoId: this.autorizacaoId,
                permissaoId: id
            };
            this.permissaoService.desvincular(vincularForm).then((response) => {
                this.carregarPermissoesNaoVinculadas(this.autorizacaoId);
                this.buscarPermissoesPorAutorizacao();
                this.permissaoSelecionada = null;
                this.swtAlert2Service.successAlert(response.message);
            }).catch((err) => {
                this.swtAlert2Service.errorAlert(err.error.errors);
            });
        }
    }
    carregarPermissoesNaoVinculadas(id) {
        this.permissaoService.buscarPermissoesNaoVinculadasPorAutorizacaoId(id).then((response) => {
            this.permissoesNaoVinculadas = response.entity;
        }).catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }
    carregarAutorizacao(id) {
        this.autorizacaoService.find(id).then((response) => {
            this.autorizacao = response.entity;
        }).catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }
    salvar(autorizacao) {
        this.autorizacaoService.update(autorizacao.id, autorizacao).then((response) => {
            this.swtAlert2Service.successAlert(response.message);
            setTimeout(() => {
                this.router.navigate(['../secured/local/autorizacoes']);
            }, 2000);
        }).catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }
    buscarPermissoesPorAutorizacao() {
        this.busca.autorizacaoId = this.autorizacaoId;
        this.permissaoService.buscarPermissoesPorAutorizacaoId(this.busca).then((response) => {
            this.permissoes = response.content;
            this.totalDePaginas = response.totalPages;
        }).catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }
    cliqueNoBotaoListener(botao) {
        this.busca.pageNumber = botao.numero - 1;
        this.buscarPermissoesPorAutorizacao();
    }
}
