import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PessoaComponent } from './pessoa.component';
import { PessoasComponent } from './pessoas.component';
import { PessoaEditComponent } from './pessoa.edit.component';

const pessoaRoutes: Routes = [
    {
        path: 'secured/pessoa',
        component: PessoaComponent
    },
    {
        path: 'secured/pessoas',
        component: PessoasComponent
    },
    {
        path: 'secured/pessoa/:id',
        component: PessoaEditComponent
    }
] 

@NgModule({
    imports: [
        RouterModule.forChild(pessoaRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class PessoaRoutingModule {
    
}