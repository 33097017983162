import { AbstractBuilder } from './abstract.builder';
import { FamiliaInfo } from '../model/familiainfo.model';
import { Injectable } from '@angular/core';

@Injectable()
export class FamiliaInfoBuilder extends AbstractBuilder<FamiliaInfo> {

    reset() : void {
        this.entity = new FamiliaInfo(null,null,null,null,null,null);
    }

}