import { Component, OnInit } from '@angular/core';
import { PaisService } from './service/pais.service';
import { Pais } from 'src/app/model/pais.model';
import { PaisBuilder } from 'src/app/builder/pais.builder';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';

@Component({
    selector: 'pais',
    templateUrl: './pais.component.html'
})
export class PaisComponent implements OnInit {

    public pais: Pais;
    public paisBuilder: PaisBuilder;

    constructor(
        public paisService: PaisService,
        public swtAlert2Service: SwtAlert2Service
    ) 
    {
        this.paisBuilder = new PaisBuilder();
        this.pais = this.paisBuilder.getInstance();
    }

    ngOnInit() {        
    }

    salvar(pais: Pais) : void {

        this.paisService.create(pais).then( (response:any) => {
            this.pais = new Pais(null,null,[]);
            this.swtAlert2Service.successAlert(response.message);

        }).catch( (err:any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

}