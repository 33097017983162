import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PermissaoLocalService } from './service/permissao.local.service';
import { AutorizacaoLocalService } from '../autorizacao/service/autorizacao.local.service';
import { CommonModule } from '@angular/common';
import { SecuredModule } from '../../layout/secured.module';
import { RouterModule } from '@angular/router';
import { PermissaoRoutingModule } from './permissao-local-routing.module';
import { PermissaoLocalComponent } from './permissao.local.component';
import { PermissoesLocalComponent } from './permissoes.local.component';
import { PermissaoLocalEditComponent } from './permissao.local.edit.component';

@NgModule({
    declarations: [ 
        PermissaoLocalComponent,
        PermissoesLocalComponent,
        PermissaoLocalEditComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        PermissaoRoutingModule,
        FormsModule,
        SecuredModule
    ],
    providers: [
        PermissaoLocalService,
        AutorizacaoLocalService
    ]
})
export class PermissaoLocalModule {

}