export class Saude {

    constructor(
        public id: number,
        public pessoaId: number,
        public temEnfermidadeFisica: boolean,
        public temEnfermidadePsicologica: boolean,
        public tomaMedicacao: boolean,
        public fazTerapia: boolean,
        public enfermidadesFisicas: string,
        public enfermidadesPsicologicas: string,
        public terapiaDuracaoAnos: number,
        public terapiaDuracaoMeses: number     
    ){}

}