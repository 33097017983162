<ng-container>
  <div class="card card-gray-dark">
    <div class="card-header">
      <h3 class="card-title">
        Cadastrar Membro da Comissão de Discernimento
      </h3>
    </div>
    <div class="card-body">
      <div class="row" *ngIf="!comissaoDiscernimento?.id">
        <div class="col-12">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="form-group">
                <label i18n for="npastor_pessoa">Pessoa</label>
                <span class="form-control">{{pessoaComissaoDiscernimentoSelecionado?.nome}}
                  {{pessoaComissaoDiscernimentoSelecionado?.sobrenome}}</span>
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <label i18n for="button-add-pessoa">&nbsp;</label>
                <button name="button-add-pessoa" data-toggle="modal"
                  data-target="#listagem-pessoa-comissaodiscernimento" style="cursor: pointer" class="btn btn-primary"
                  i18n>Selecionar o Membro da Comissão de Discernimento</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <button type="submit" (click)="adicionarComissaoDiscernimento()" name="button-add-cord-col"
          class="form-control btn btn-primary" i18n>Adicionar Membro da Comissão de Discernimento</button>
      </div>
    </div>
    <div class="card-footer">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>#</th>
            <th>Foto</th>
            <th>Nome</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let membroComissaoDiscernimento of membrosComissaoDiscernimento">
            <td>{{membroComissaoDiscernimento.id}}</td>
            <td>
              <img class="profile-user-img img-fluid img-circle"
                [src]="membroComissaoDiscernimento.thumbnail == null ? 'assets/images/ppl.png' : membroComissaoDiscernimento.thumbnail"
                alt="Imagem do membro do comissaoDiscernimento">
            </td>
            <td>{{membroComissaoDiscernimento.pessoaNome}}</td>
            <td>
              <button class="btn btn-sm btn-danger ml-1"
                (click)="removerComissaoDiscernimento(membroComissaoDiscernimento.id)"
                title="Remover Membro do ComissaoDiscernimento" alt="Remover Membro do ComissaoDiscernimento"><i
                  class="fas fa-trash-alt"></i></button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <listagem-pessoainfo-modal-component [modalId]="'listagem-pessoa-comissaodiscernimento'" [onlyMissionaries]="true"
    [bringNotInformedWayOfLife]="false" [filterByUserAccess]="true" [bringEntitiesWithoutLinkedUser]="false"
    [modalTitle]="'Selecione o Membro da Comissão de Discernimento'" [buttonText]="'Selecionar'"
    [altButtonText]="'Selecionar Membro'" (eventEmitter)="selecionarComissaoDiscernimento($event)">
  </listagem-pessoainfo-modal-component>
</ng-container>