import { Injectable } from '@angular/core';
import { AbstractBuilder } from './abstract.builder';
import { Missionario } from '../model/missionario.model';

@Injectable()
export class MissionarioBuilder extends AbstractBuilder<Missionario> {

    reset() : void {
        this.entity = new Missionario(null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,null,false,null);
    }

}