import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
export class ConfirmacaoCadastroComponent {
    constructor(confirmacaoCadastroService, route, router, swtAlert2Service) {
        this.confirmacaoCadastroService = confirmacaoCadastroService;
        this.route = route;
        this.router = router;
        this.swtAlert2Service = swtAlert2Service;
        this.mensagemConfirmacao = "Aguardando confirmação";
    }
    ngOnInit() {
        this.token = this.route.snapshot.paramMap.get('token');
        this.confirmacaoCadastro();
    }
    confirmacaoCadastro() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.confirmacaoCadastroService.confirmacao(this.token);
                this.mensagemConfirmacao = response.message;
                setTimeout(() => {
                    this.router.navigate(['../login']);
                }, 5000);
            }
            catch (e) {
                this.mensagemConfirmacao = "Não foi possível confirmar seu cadastro, entre em contato com os responsáveis do sistema.";
                this.swtAlert2Service.errorAlert(e.error.errors);
                setTimeout(() => {
                    this.router.navigate(['../login']);
                }, 10000);
            }
        });
    }
}
