<secured>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h3 i18n>1º Questionario - CAL</h3>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="card card-primary card-outline card-outline-tabs">
                <div class="card-header p-0 border-bottom-0">
                    <ul class="nav nav-tabs" id="tabs-questionario-form" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" id="dados-pessoais-tab" data-toggle="pill"
                                href="#dados-pessoais-tab-content" role="tab" aria-controls="dados-pessoais-tab"
                                aria-selected="true">Dados Pessoais</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="questionario-tab" data-toggle="pill"
                                href="#questionario-tab-content" role="tab" aria-controls="questionario-tab"
                                aria-selected="false">Questionário</a>
                        </li>
                    </ul>
                </div>
                <div class="card-body">
                    <div class="tab-content" id="tabs-pessoa-formContent">
                        <div class="tab-pane fade show active" id="dados-pessoais-tab-content" role="tabpanel"
                            aria-labelledby="dados-pessoais-tab">
                            <form #dadosPessoaisForm="ngForm">
                                <div class="row">
                                    <div *ngIf="(pessoa.id != undefined)" class="col-sm-3 col-md-3 col-lg-3">
                                        <div style="padding: 1rem 0rem 1rem 1rem;">
                                            <div class="card card-default">
                                                <div class="card-body">
                                                    <div class="text-center" style="margin: 1rem auto 2rem;">
                                                        <div class="widget-user-image">
                                                            <img class="profile-user-img img-fluid img-circle elevation-2"
                                                                [src]="pessoa.thumbnail == null ? 'assets/images/ppl.png' : pessoa.thumbnail"
                                                                alt="Imagem da pessoa" style="width: auto;">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        [ngClass]="(pessoa.id != undefined) ? 'col-sm-9 col-md-9 col-lg-9' : 'col-sm-12 col-md-12 col-lg-12'">
                                        <div style="padding: 1rem;">
                                            <div class="card card-default">
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md-6 col-lg-6">
                                                            <div class="form-group">
                                                                <label i18n for="npessoa_nome">Nome</label>
                                                                <span class="form-control">{{pessoa.nome}}</span>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-6 col-lg-6">
                                                            <div class="form-group">
                                                                <label i18n for="npessoa_sobrenome">Sobrenome</label>
                                                                <span class="form-control">{{pessoa.sobrenome}}</span>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-6 col-lg-6">
                                                            <div class="form-group">
                                                                <label i18n for="npessoa_dtnas">Data de
                                                                    Nascimento</label>
                                                                <span class="form-control">{{pessoa.dataNascimento |
                                                                    date : 'dd/MM/yyyy'}}</span>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-6 col-lg-6">
                                                            <div class="form-group">
                                                                <label i18n for="npessoa_missao_atual">Missão
                                                                    Atual</label>
                                                                <select class="form-control" id="npessoa_missao_atual"
                                                                    [(ngModel)]="pessoa.vinculoMissao.missaoAtualId"
                                                                    name="npessoa_missao_atual" disabled>
                                                                    <option disabled value="null" i18n>Selecione uma
                                                                        missão</option>
                                                                    <option *ngFor="let missao of missoesVinculo"
                                                                        [value]="missao.id">{{missao.nome}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-8 col-lg-8">
                                                            <div class="form-group">
                                                                <div class="checkbox icheck-concrete">
                                                                    <input [(ngModel)]="dadoFormandoCA.ja_permaneceu"
                                                                        type="checkbox" name="nja_permaneceu"
                                                                        id="nja_permaneceu" disabled />
                                                                    <label class="form-check-label ml-2"
                                                                        for="nja_permaneceu">Você já permaneceu em algum
                                                                        Nível Formativo?</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                                            <div class="form-group"
                                                                *ngIf="dadoFormandoCA.ja_permaneceu">
                                                                <label i18n for="nivel_permaneceu">Em qual nível
                                                                    permaneceu?</label>
                                                                <select class="form-control" name="nivel_form"
                                                                    name="nivel_permaneceu" id="nivel_permaneceu"
                                                                    [(ngModel)]="dadoFormandoCA.nivel_permaneceu"
                                                                    disabled>
                                                                    <option disabled [value]="null">Selecione um nível
                                                                        de
                                                                        formação
                                                                    </option>
                                                                    <option *ngFor="let nivelFormacao of niveisFormacao"
                                                                        [value]="nivelFormacao.id">
                                                                        {{nivelFormacao.nome}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-8">
                                                            <div class="form-group">
                                                                <label for="form_pessoal">Formador Pessoal</label>
                                                                <span class="form-control"
                                                                    name="form_pessoal">{{pessoaFormadorPessoalSelecionado.nome}}
                                                                    {{pessoaFormadorPessoalSelecionado.sobrenome}}</span>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                                            <div class="form-group">
                                                                <label i18n for="nqtdeform_pessoais">Nº de Formações
                                                                    Pessoais</label>
                                                                <span
                                                                    class="form-control">{{caminhoFormativo.qtde_formacao_pessoal}}</span>
                                                            </div>
                                                        </div>
                                                        <!--div class="col-sm-12 col-md-4 col-lg-4">
                                                            <div class="form-group">
                                                                <label i18n for="nacomp_comunitario">Há quanto tempo ele
                                                                    é seu Formador Pessoal</label>
                                                                <input type="text" class="form-control"
                                                                    placeholder="Digite..." i18n-placeholder required>
                                                            </div>
                                                        </div-->
                                                        <div class="col-8">
                                                            <div class="form-group">
                                                                <label for="form_comunitario">Formador
                                                                    Comunitário</label>
                                                                <span
                                                                    class="form-control">{{caminhoFormativo.nome_formador_comunitario}}</span>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                                            <div class="form-group">
                                                                <label i18n for="nacomp_comunitario">Nº de
                                                                    Acompanhamentos Comunitários</label>
                                                                <span
                                                                    class="form-control">{{caminhoFormativo.qtde_acompanhamento_comunitario}}</span>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-6 col-lg-6">
                                                            <div class="form-group">
                                                                <label i18n for="nacompanhadoPor">Quem te acompanha
                                                                    comunitariamente?</label>
                                                                <select class="form-control" id="nacompanhadoPor"
                                                                    name="nacompanhadoPor"
                                                                    [(ngModel)]="caminhoFormativo.acompanhado_por"
                                                                    required disabled>
                                                                    <option value="1" i18n>Formador Comunitário</option>
                                                                    <option value="2">Formador Assistente</option>
                                                                    <option value="3">Coordenador do Colegiado</option>
                                                                    <option value="4">Responsável Local</option>
                                                                    <option value="5">Outro Missionário</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-6 col-lg-6">
                                                            <div class="form-group">
                                                                <label i18n for="nform_pessoais">Qual a sua situação
                                                                    profissional?</label>
                                                                <select class="form-control" id="nsituacao_profissional"
                                                                    name="nsituacao_profissional"
                                                                    [(ngModel)]="dadoFormandoCA.situacao_profissional"
                                                                    required disabled>
                                                                    <option value="0" i18n>Nenhuma das opções</option>
                                                                    <option value="1">Trabalha</option>
                                                                    <option value="2">Estuda</option>
                                                                    <option value="3">Aposentado</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-6 col-lg-6">
                                                            <div class="form-group">
                                                                <label i18n for="npessoa_sobrenome">Exerce função de
                                                                    autoridade apostolica? Qual?</label>
                                                                <span
                                                                    class="form-control">{{caminhoFormativo.funcao_autoridade_apostolica}}</span>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-6 col-lg-6">
                                                            <div class="form-group">
                                                                <div class="checkbox icheck-concrete">
                                                                    <input
                                                                        [(ngModel)]="dadoFormandoCA.alianca_missionaria"
                                                                        type="checkbox" name="nalianca_missionaria"
                                                                        id="nalianca_missionaria" disabled />
                                                                    <label class="form-check-label ml-2"
                                                                        for="nalianca_missionaria">Está atualmente como
                                                                        Aliança Missionária?</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-6 col-lg-6"
                                                            *ngIf="dadoFormandoCA.alianca_missionaria">
                                                            <div class="form-group">
                                                                <label i18n for="casa_comunitaria_desc">Informe em qual
                                                                    Casa Comunitária você está.</label>
                                                                <span
                                                                    class="form-control">dadoFormandoCA.casa_comunitaria_desc</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card-footer text-right">
                                                    <!-- <button type="submit" class="btn"
                                                        [ngClass]="pessoa.id != undefined ? 'btn-primary' : 'btn-success'"
                                                        [disabled]="!dadosPessoaisForm.valid" i18n>Salvar</button>&nbsp; -->
                                                    <button type="button" class="btn btn-default"
                                                        (click)="activateTab('questionario-tab-content')"
                                                        *ngIf="pessoa.id != undefined">Próximo</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="tab-pane fade" id="questionario-tab-content" role="tabpanel"
                            aria-labelledby="questionario-tab">
                            <form *ngIf="pessoa.id != undefined" #questionarioForm="ngForm" novalidate>
                                <div class="row">
                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                        <div style="padding: 1rem 1rem 1rem 1rem;">
                                            <div class="card card-default">
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md12 col-lg-12">
                                                            <h3 class="card-title">1. Fale-nos sobre os seguintes
                                                                aspectos:</h3>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md12 col-lg-12">
                                                            <div class="form-group">
                                                                <label for="nresposta39">
                                                                    1. Você recebeu o feedback do pastoreio de 2023?
                                                                </label>
                                                                <select class="form-control" id="nresposta39"
                                                                    name="nresposta39" [(ngModel)]="resposta39.resposta"
                                                                    disabled>
                                                                    <option value="1" i18n>Sim</option>
                                                                    <option value="2">Não</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md12 col-lg-12">
                                                            <div class="form-group">
                                                                <label for="nresposta40">
                                                                    2. Como foi pra você ter recebido este feedback,
                                                                    você acredita que os pontos recebidos tem a ver com
                                                                    o caminho que você tem trilhado?
                                                                </label>
                                                                <select class="form-control" id="nresposta40"
                                                                    name="nresposta40" [(ngModel)]="resposta40.resposta"
                                                                    disabled>
                                                                    <option value="1" i18n>Me senti conhecido pela
                                                                        Comunidade. Acredito que estes são mesmo os
                                                                        pontos que preciso trabalhar.</option>
                                                                    <option value="2">Fiquei surpreso, não esperava. Mas
                                                                        acolhi bem, concordo com o que me foi dito.
                                                                    </option>
                                                                    <option value="3">Não me senti confortável com o que
                                                                        recebi, não me percebo da forma como foi dito.
                                                                    </option>
                                                                    <option value="4">Acredito que o feedback
                                                                        corresponde a mim apenas parcialmente. Sinto que
                                                                        preciso ser melhor conhecido pelas minhas
                                                                        autoridades.</option>
                                                                    <option value="5">Não recebi nenhum feedback de
                                                                        pastoreio ainda.</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md12 col-lg-12">
                                                            <div class="form-group">
                                                                <label for="nresposta41">
                                                                    3. Você se sente ajudado no seu caminho de formação?
                                                                </label>
                                                                <select class="form-control" id="nresposta41"
                                                                    name="nresposta41" [(ngModel)]="resposta41.resposta"
                                                                    disabled>
                                                                    <option value="1" i18n>Sim, me sinto ajudado pelas
                                                                        minhas autoridades. Sinto que tenho o suporte
                                                                        necessário.</option>
                                                                    <option value="2">Sinto que sou ajudado, mas preciso
                                                                        buscar mais aqueles que podem me auxiliar.
                                                                    </option>
                                                                    <option value="3">Sinto que não sou ajudado, porque
                                                                        evito buscar ajuda.</option>
                                                                    <option value="4">Sinto que não recebo a ajuda
                                                                        necessária.</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md12 col-lg-12">
                                                            <div class="form-group">
                                                                <label for="descricao">
                                                                    4. Em que aspectos você sente mais necessidade de
                                                                    ser ajudado?
                                                                </label>
                                                                <span class="form-control" style="height: fit-content;"
                                                                    disabled>
                                                                    {{resposta42.resposta?.trim()}}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md12 col-lg-12">
                                                            <div class="form-group">
                                                                <label for="descricao">
                                                                    5. Como você percebe a sua vivência dos conselhos
                                                                    evangelicos:
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md12 col-lg-12">
                                                            <div class="form-group">
                                                                <label for="nresposta43">
                                                                    5.1 Como você percebe a sua vivência da pobreza?
                                                                </label>
                                                                <select class="form-control" id="nresposta43"
                                                                    name="nresposta43" [(ngModel)]="resposta43.resposta"
                                                                    disabled>
                                                                    <option value="1" i18n>Me sinto vivendo bem.
                                                                    </option>
                                                                    <option value="2">Me sinto vivendo bem, mas ainda
                                                                        preciso crescer</option>
                                                                    <option value="3">Sinto que preciso de ajuda para
                                                                        dar mais passos.</option>
                                                                    <option value="4">Sinto que preciso crescer no meu
                                                                        caminho de conversão.</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md12 col-lg-12">
                                                            <div class="form-group">
                                                                <label for="nresposta44">
                                                                    5.2 Como você percebe a sua vivência da castidade?
                                                                </label>
                                                                <select class="form-control" id="nresposta44"
                                                                    name="nresposta44" [(ngModel)]="resposta44.resposta"
                                                                    disabled>
                                                                    <option value="1" i18n>Me sinto vivendo bem.
                                                                    </option>
                                                                    <option value="2">Me sinto vivendo bem, mas ainda
                                                                        preciso crescer</option>
                                                                    <option value="3">Sinto que preciso de ajuda para
                                                                        dar mais passos.</option>
                                                                    <option value="4">Sinto que preciso crescer no meu
                                                                        caminho de conversão.</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md12 col-lg-12">
                                                            <div class="form-group">
                                                                <label for="descricao">
                                                                    5.3 Como você percebe a sua vivência da obediência?
                                                                </label>
                                                                <select class="form-control" id="nresposta45"
                                                                    name="nresposta45" [(ngModel)]="resposta45.resposta"
                                                                    disabled>
                                                                    <option value="1" i18n>Me sinto vivendo bem.
                                                                    </option>
                                                                    <option value="2">Me sinto vivendo bem, mas ainda
                                                                        preciso crescer</option>
                                                                    <option value="3">Sinto que preciso de ajuda para
                                                                        dar mais passos.</option>
                                                                    <option value="4">Sinto que preciso crescer no meu
                                                                        caminho de conversão.</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md12 col-lg-12">
                                                            <div class="form-group">
                                                                <label for="descricao">
                                                                    5.4 Ainda sobre os conselhos evangelicos, existe
                                                                    algo que você gostaria de partilhar?
                                                                </label>
                                                                <span class="form-control" style="height: fit-content;"
                                                                    disabled>
                                                                    {{resposta46.resposta?.trim()}}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md12 col-lg-12">
                                                            <div class="form-group">
                                                                <label for="descricao">
                                                                    6. Como você se vê na vivência do tripé da vocação?
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md12 col-lg-12">
                                                            <div class="form-group">
                                                                <label for="descricao">
                                                                    6.1 Como você se percebe na vivencia da sua vida de
                                                                    contemplação, intimidade com Deus?
                                                                </label>
                                                                <select class="form-control" id="nresposta47"
                                                                    name="nresposta47" [(ngModel)]="resposta47.resposta"
                                                                    disabled>
                                                                    <option value="1" i18n>Me sinto vivendo bem.
                                                                    </option>
                                                                    <option value="2">Me sinto vivendo bem, mas ainda
                                                                        preciso crescer</option>
                                                                    <option value="3">Sinto que preciso de ajuda para
                                                                        dar mais passos.</option>
                                                                    <option value="4">Sinto que não tenho conseguido ser
                                                                        fiel a minha vida de contemplação.</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md12 col-lg-12">
                                                            <div class="form-group">
                                                                <label for="descricao">
                                                                    6.2 Como você se percebe na tua vivencia apostolica?
                                                                </label>
                                                                <select class="form-control" id="nresposta48"
                                                                    name="nresposta48" [(ngModel)]="resposta48.resposta"
                                                                    disabled>
                                                                    <option value="1" i18n>Me sinto vivendo bem.
                                                                    </option>
                                                                    <option value="2">Me sinto vivendo bem, mas ainda
                                                                        preciso crescer</option>
                                                                    <option value="3">Sinto que preciso de ajuda para
                                                                        dar mais passos.</option>
                                                                    <option value="4">Sinto que não tenho conseguido me
                                                                        dedicar aos meus compromissos apostólicos.
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md12 col-lg-12">
                                                            <div class="form-group">
                                                                <label for="descricao">
                                                                    6.3 Como você se percebe na vivencia fraterna, na
                                                                    unidade?
                                                                </label>
                                                                <select class="form-control" id="nresposta49"
                                                                    name="nresposta49" [(ngModel)]="resposta49.resposta"
                                                                    disabled>
                                                                    <option value="1" i18n>Me sinto vivendo bem.
                                                                    </option>
                                                                    <option value="2">Me sinto vivendo bem, mas ainda
                                                                        preciso crescer</option>
                                                                    <option value="3">Sinto que preciso de ajuda para
                                                                        dar mais passos.</option>
                                                                    <option value="4">Sinto que não tenho conseguido
                                                                        viver bem a minha vida fraterna</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card-footer text-right">
                                                    <button type="button" class="btn btn-default float-left"
                                                        style="margin-left: 0.5rem;"
                                                        (click)="activateTab('dados-pessoais-tab-content')"
                                                        i18n>Voltar</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</secured>