<aside id="members-messages-sidebar">
  <div id="search">
    <div class="input-group">
      <input type="text" class="form-control" (input)="filterByInput($event)" placeholder="Digite o nome da pessoa...">
      <span class="input-group-append">
        <button type="button" class="btn btn-info">
          <i class="fas fa-search"></i>
        </button>
      </span>
    </div>
  </div>
  <div id="filter">
    <button class="btn"
      [ngClass]="{'btn-info': selectedFilterButton === 'Todas', 'btn-default' : selectedFilterButton !== 'Todas'}"
      (click)="setFilterButtonState('Todas')">
      Todas
    </button>
    <button class="btn"
      [ngClass]="{'btn-info': selectedFilterButton === 'Não Lidas', 'btn-default' : selectedFilterButton !== 'Não Lidas'}"
      (click)="setFilterButtonState('Não Lidas')">
      Não Lidas
    </button>

    <button class="btn"
      [ngClass]="{'btn-info': selectedFilterButton === 'Lidas', 'btn-default' : selectedFilterButton !== 'Lidas'}"
      (click)="setFilterButtonState('Lidas')">
      Lidas
    </button>
  </div>
  <ul id="members-list">
    <li *ngFor="let summary of displayableSummaryList">
      <app-mensagem-rl-membro [imageSrc]="!summary.thumbnail ? 'assets/images/ppl.png' :summary.thumbnail"
        [memberName]="summary.pessoaNome" [lastMessage]="summary.ultimaMensagem"
        [notReadedMessageCounter]="summary.quantidadeMensagensNaoLidas" [memberPessoaId]="summary.pessoaId"
        (click)="selectMemberSummary(summary)">
      </app-mensagem-rl-membro>
    </li>
  </ul>
</aside>