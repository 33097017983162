export class Membro {

    constructor(
        public id: number,
        public anoIngresso: number,
        public anoPromessa: number,
        public nivelFormacaoId: number,
        public nivelFormacaoNome: string,
        public regionalId: number,
        public regionalNome: string,
        public missaoOrigemId: number,
        public missaoOrigemNome: string,
        public difusaoOrigemId: number,
        public difusaoOrigemNome: string,
        public estadoVidaId: number,
        public estadoVidaNome: string,
        public pessoaId: number
    )
    {}

}