import { Injectable } from '@angular/core';
import { AbstractBuilder } from './abstract.builder';
import { Mensagem } from '../model/mensagem.model';

@Injectable()
export class MensagemBuilder extends AbstractBuilder<Mensagem> {

    reset() : void {
        this.entity = new Mensagem(null,null,null,null,null);
    }

}