import { Obra } from '../model/obra.model';
import { AbstractBuilder } from './abstract.builder';


export class ObraBuilder extends AbstractBuilder<Obra> {

    reset() : void {
        this.entity = new Obra(null,null,null);
    }

}