export class CaminhoFormativo {
  constructor(
    public id: number,
    public pessoaId: number,
    public ano_formativo_id: number,
    public missao_atual_id: number,
    public nome_formador_comunitario: string,
    public nome_formador_pessoal: string,
    public qtde_formacao_pessoal: number,
    public qtde_acompanhamento_comunitario: number,
    public qtde_acompanhamento_comunitario_fc: number,
    public funcao_autoridade_casa: string,
    public funcao_autoridade_apostolica: string,
    public caminhoFormativoSugeridoFC: string,
    public caminhoFormativoFeedback: string,
    public acompanhado_por: string,
    public acompanhado_por_nome: string
  ) {}
}
