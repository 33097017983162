import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PaisService } from './service/pais.service';
import { CommonModule } from '@angular/common';
import { SecuredModule } from '../../layout/secured.module';
import { RouterModule } from '@angular/router';
import { PaisComponent } from './pais.component';
import { PaisRoutingModule } from './pais-routing.module';
import { PaisesComponent } from './paises.component';
import { PaisEditComponent } from './pais.edit.component';

@NgModule({
    declarations: [ 
        PaisComponent,
        PaisesComponent,
        PaisEditComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        PaisRoutingModule,
        FormsModule,
        SecuredModule
    ],
    providers: [
        PaisService
    ]
})
export class PaisModule {

}