import { Component, OnInit } from '@angular/core';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { Autorizacao } from 'src/app/model/autorizacao.model';
import { AcessoUsuarioEstrutura } from 'src/app/model/acessousuarioestrutura.model';
import { AcessoUsuarioEstruturaService } from '../service/acessousuarioestrutura.service';
import { AutorizacaoService } from '../../autorizacao/service/autorizacao.service';

@Component({
    selector: 'acessousuariosestruturasassistenteapostolico',
    templateUrl: './acessousuariosestruturas.aa.component.html'
})
export class AcessoUsuariosEstruturasListagemAssistenteApostolicoComponent implements OnInit {

    public acessos: Array<AcessoUsuarioEstrutura>;
    public autorizacoes: Array<Autorizacao>;
    public busca: any;
    public totalDePaginas: number;

    constructor(
        public acessoUsuarioEstruturaService: AcessoUsuarioEstruturaService,
        public autorizacaoService: AutorizacaoService,
        public swtAlert2Service: SwtAlert2Service
    ) 
    {
        this.autorizacoes = new Array<Autorizacao>();
        this.busca = {
            autorizacaoId: null,
            username: null,
            nome: undefined,
            pageNumber : 0, 
            quantityOfElements : 10
        };
    }

    ngOnInit() {
        this.init();
    }   

    async init() : Promise<any> {
        await this.carregarAutorizacao();
        await this.buscarAcessos();
    }

    async carregarAutorizacao() : Promise<any> {

        try 
        {
            let response = await this.autorizacaoService.findAll();
            this.autorizacoes = response.entity;
            
        } catch (err) {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }

    }

    cliqueNoBotaoListener(botao) {
        this.busca.pageNumber = botao.numero-1;
        this.buscarAcessos();
    }

    async buscarAcessos() : Promise<any> {

        try 
        {
            let response = await this.acessoUsuarioEstruturaService.buscar(this.busca);
            this.acessos = response.entity;
            this.totalDePaginas = response.quantity;
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }

    }
    
    async deletarAcesso(id: number) : Promise<any> {


        try 
        {
            if(confirm('Deseja deletar este acesso?'))
            {
                let response = await this.acessoUsuarioEstruturaService.delete(id);
                this.swtAlert2Service.successAlert(response.message);
                this.buscarAcessos();
            }
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }

    }
}