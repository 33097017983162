import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { PessoaBuilder } from 'src/app/builder/pessoa.builder';
export class AdicaoMembroCasaComunitariaComponent {
    constructor(imagemService, salvarMissionarioMissaoAtualBuilder, missionarioMissaoAtualService, pessoaService, route, router, swtAlert2Service) {
        this.imagemService = imagemService;
        this.salvarMissionarioMissaoAtualBuilder = salvarMissionarioMissaoAtualBuilder;
        this.missionarioMissaoAtualService = missionarioMissaoAtualService;
        this.pessoaService = pessoaService;
        this.route = route;
        this.router = router;
        this.swtAlert2Service = swtAlert2Service;
        this.eventEmitter = new EventEmitter();
        this.pessoaBuilder = new PessoaBuilder();
        this.pessoaMembroSelecionada = this.pessoaBuilder.getInstance();
        this.membrosCasaComunitaria = new Array();
        this.salvarMissionarioMissaoAtual = this.salvarMissionarioMissaoAtualBuilder.getInstance();
        this.busca = {
            casaComunitariaId: null,
            formaVidaId: null,
            pageNumber: 0,
            quantityOfElements: 10
        };
    }
    ngOnInit() {
        this.initializer();
    }
    initializer() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.casaComunitaria.id != undefined) {
                yield this.carregarMembrosCasaComunitaria();
            }
            this.temAcessoARemocao();
        });
    }
    /*** MEMBROS ***/
    selecionarMembro(pessoa) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.salvarMissionarioMissaoAtual.pessoaId = pessoa.id;
            this.pessoaMembroSelecionada = pessoa;
            $('#listagem-pessoa-membro').modal('hide');
        });
    }
    recarregarMembros(comando) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (comando == 'recarregar_membros_casa_comunitaria') {
                this.membroSelecionado = null;
                this.carregarMembrosCasaComunitaria();
                $('#listagem-pessoa-membro').modal('hide');
            }
        });
    }
    adicionarMembroCasaComunitaria() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.salvarMissionarioMissaoAtual.casaComunitariaId = this.casaComunitaria.id;
                if (this.salvarMissionarioMissaoAtual.dataChegada != null && this.salvarMissionarioMissaoAtual.pessoaId != null) {
                    let response = yield this.missionarioMissaoAtualService.adicionarMembroNaCasaComunitaria(this.salvarMissionarioMissaoAtual);
                    this.swtAlert2Service.successAlert("Missionário adicionado a casa comunitária com sucesso!");
                    this.salvarMissionarioMissaoAtual = this.salvarMissionarioMissaoAtualBuilder.getInstance();
                    this.pessoaMembroSelecionada = this.pessoaBuilder.getInstance();
                    this.carregarMembrosCasaComunitaria();
                }
                else {
                    if (this.salvarMissionarioMissaoAtual.pessoaId == null) {
                        this.swtAlert2Service.errorAlert("O campo pessoa é obrigatório.");
                    }
                    else if (this.salvarMissionarioMissaoAtual.dataChegada == null) {
                        this.swtAlert2Service.errorAlert("O campo data de entrada é obrigatório.");
                    }
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarMembrosCasaComunitaria() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.busca.casaComunitariaId = this.casaComunitaria.id;
                // this.busca.formaVidaId = 17; // Comunidade de Vida
                let response = yield this.pessoaService.buscarMembrosCasaComunitaria(this.busca);
                this.membrosCasaComunitaria = response.entity;
                this.totalDePaginas = response.quantity;
                for (let i = 0; i < this.membrosCasaComunitaria.length; i++) {
                    if (this.membrosCasaComunitaria[i].imagemId != null) {
                        let blogImage = yield this.getPessoaImagem(this.membrosCasaComunitaria[i].imagemId);
                        this.imagemService.createImage(blogImage, this.membrosCasaComunitaria[i]);
                    }
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    getPessoaImagem(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.imagemService.getPessoaPhoto(id);
                return response.body;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    cliqueNoBotaoListener(botao) {
        this.busca.pageNumber = botao.numero - 1;
        this.carregarMembrosCasaComunitaria();
    }
    temAcessoARemocao() {
        this.podeRemoverMissionario = false;
        for (let authNome of this.usuarioLogado.autorizacoesNome) {
            if (authNome.localeCompare("ROLE_ASSISTENTE_MISSIONARIO") == 0
                ||
                    authNome.localeCompare("ROLE_ACOMPANHADOR_MISSAO") == 0) {
                this.podeRemoverMissionario = true;
            }
        }
    }
}
