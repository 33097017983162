import * as i0 from "@angular/core";
export class CacheService {
    constructor() {
        this.cache = {};
    }
    // Salva os dados no cache com uma chave específica
    set(key, data) {
        this.cache[key] = data;
    }
    // Recupera os dados do cache com base na chave
    get(key) {
        return this.cache[key];
    }
    // Verifica se há dados no cache
    has(key) {
        return this.cache.hasOwnProperty(key);
    }
    // Limpa o cache (se precisar)
    clear() {
        this.cache = {};
    }
}
CacheService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CacheService_Factory() { return new CacheService(); }, token: CacheService, providedIn: "root" });
