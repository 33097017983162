<secured>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h3 i18n>Listagem de Irradiações</h3>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <form (ngSubmit)="buscar()" #permissoesForm="ngForm" novalidate>
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title">Buscar Irradiações</h3>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label i18n for="lmis_r">Regional</label>
                                                <select class="form-control" id="lmis_r" [(ngModel)]="busca.regionalId" (change)="carregarMissoes()" name="lmis_r">
                                                            <option [value]="null" i18n>Selecione um Regional</option>
                                                            <option *ngFor="let reg of regionais" [value]="reg.id">{{reg.nome}}</option>
                                                        </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label i18n for="nir_missao">Missão</label>
                                                <select class="form-control" id="nir_missao" [(ngModel)]="busca.missaoId" name="nir_missao">
                                                    <option [value]="null" i18n>Selecione uma Missão</option>
                                                    <option *ngFor="let missao of missoes" [value]="missao.id">{{missao.nome}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label i18n for="lmis_pais">País</label>
                                                <select class="form-control" id="lmis_pais" [(ngModel)]="busca.paisId" (change)="carregarEstados()" name="lmis_pais">
                                                            <option [value]="null" i18n>Selecione um País</option>
                                                            <option *ngFor="let pais of paises" [value]="pais.id">{{pais.nome}}</option>
                                                        </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label i18n for="lmis_estado">Estado</label>
                                                <select class="form-control" id="lmis_estado" [(ngModel)]="busca.estadoId" (change)="carregarCidades()" name="lmis_estado">
                                                            <option [value]="null" i18n>Selecione um Estado</option>
                                                            <option *ngFor="let estado of estados" [value]="estado.id">{{estado.nome}}</option>
                                                        </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label i18n for="lmis_cidade">Cidade</label>
                                                <select class="form-control" id="lmis_cidade" [(ngModel)]="busca.cidadeId" name="lmis_cidade">
                                                            <option [value]="null" i18n>Selecione uma Cidade</option>
                                                            <option *ngFor="let cidade of cidades" [value]="cidade.id">{{cidade.nome}}</option>
                                                        </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label i18n for="lmis_nome">Nome da Irradiação</label>
                                                <input [(ngModel)]="busca.nomeIrradiacao" name="lmis_nome" type="text" class="form-control" id="lmis_nome" placeholder="Digite o nome" i18n-placeholder>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label i18n for="lir_f">Fase da Irradiação</label>
                                                <select class="form-control" id="lir_f" [(ngModel)]="busca.faseIrradiacaoId" name="lir_f">
                                                            <option [value]="null" i18n>Selecione uma fase</option>
                                                            <option *ngFor="let fase of fasesIrradiacao" [value]="fase.id">{{fase.nome}}</option>
                                                        </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label i18n for="lmis_dtfund">Data de Fundação</label>
                                                <input [(ngModel)]="busca.dataFundacaoField" name="lmis_dtfund" type="date" class="form-control" id="lmis_dtfund" i18n-placeholder>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-footer">
                                    <button type="submit" class="btn btn-primary float-right" i18n>Buscar</button>
                                    <button type="reset" class="btn btn-default float-right" style="margin-right: 0.5rem;" i18n>Limpar</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <table-component [tituloSessao]="'Irradiações'" [cols]="7" [rows]="busca.quantityOfElements" [totalDePaginas]="totalDePaginas" (eventoBotao)="cliqueNoBotaoListener($event)">
                            <tr tableheader>
                                <th style="width: 10px">#</th>
                                <th>Irradiação</th>
                                <th>Missão</th>
                                <th>Cidade</th>
                                <th>Data da Fundação</th>
                                <th></th>
                            </tr>
                            <tr tablebody *ngFor="let entity of entities">
                                <td>{{entity.id}}</td>
                                <td>{{entity.nome}}</td>
                                <td>{{entity.missaoNome}}</td>
                                <td>{{entity.cidadeNome}}</td>
                                <td>{{entity.dataFundacao | date: 'dd/MM/yyyy'}}</td>
                                <td class="text-center">
                                    <button class="btn btn-sm btn-primary" routerLink="../irradiacao/{{entity.id}}" title="Editar irradiação" alt="Editar irradiação"><i class="fas fa-edit"></i></button>&nbsp;
                                    <!--button class="btn btn-sm btn-danger" (click)="deletar(entity.id)" title="Deletar irradiação" alt="Deletar irradiação"><i class="fas fa-trash-alt"></i></button-->
                                </td>
                            </tr>
                        </table-component>
                    </div>
                </div>
            </div>
        </section>
    </div>
</secured>