import { Injectable } from '@angular/core';
import { AbstractBuilder } from './abstract.builder';
import { RespostaParecer } from '../model/respostaparecer.model';

@Injectable()
export class RespostaParecerBuilder extends AbstractBuilder<RespostaParecer> {

    reset() : void {
        this.entity = new RespostaParecer(null,null,null,null);
    }

}