import { Component, OnInit } from '@angular/core';
import { CentroEvangelizacao } from 'src/app/model/centroevangelizacao.model';
import { PaisService } from '../pais/service/pais.service';
import { EstadoService } from '../estado/service/estado.service';
import { Estado } from 'src/app/model/estado.model';
import { Pais } from 'src/app/model/pais.model';
import { Cidade } from 'src/app/model/cidade.model';
import { RegionalService } from '../regional/service/regional.service';
import { MissaoService } from '../missao/service/missao.service';
import { Missao } from 'src/app/model/missao.model';
import { Regional } from 'src/app/model/regional.model';
import { CentroEvangelizacaoService } from '../centroevangelizacao/service/centroevangelizacao.service';
import { Ministerio } from 'src/app/model/ministerio.model';
import { MinisterioService } from './service/ministerio.service';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';

@Component({
    selector: 'ministerios',
    templateUrl: './ministerios.component.html'
})
export class MinisteriosComponent implements OnInit {

    public estados : Array<Estado>;
    public paises : Array<Pais>;
    public cidades: Array<Cidade>;
    public regionais: Array<Regional>;
    public missoes: Array<Missao>;
    public centrosEvangelizacao: Array<CentroEvangelizacao>;
    public ministerios: Array<Ministerio>;
    public regionalSelecionadoId : number;
    public missaoSelecionadoId : number;
    public busca: any;
    public totalDePaginas: number;

    constructor(
        public regionalService: RegionalService,
        public missaoService: MissaoService,
        public paisService: PaisService,
        public estadoService: EstadoService,
        public centroEvangelizacaoService: CentroEvangelizacaoService,
        public ministeriosService: MinisterioService,
        public swtAlert2Service: SwtAlert2Service
    ) 
    {
        this.estados = new Array<Estado>();
        this.paises = new Array<Pais>();
        this.cidades = new Array<Cidade>();
        this.regionais = new Array<Regional>();
        this.missoes = new Array<Missao>();
        this.centrosEvangelizacao = new Array<CentroEvangelizacao>();    
            
        this.busca = {            
            nome: null,
            ano: null,
            regionalId: null,
            missaoRegionalId: null,
            paisId: null,
            estadoId: null,
            centroEvangelizacaoId: null,
            pageNumber : 0, 
            quantityOfElements : 10
        };
    }

    ngOnInit() {
        this.carregarRegionais(); 
        this.carregarPaises(); 
        this.buscarMinisterio();
    }   

    cliqueNoBotaoListener(botao) {
        this.busca.pageNumber = botao.numero-1;
        this.buscarMinisterio();
    }

    carregarRegionais() : void {
        this.regionalService.findAll().then( (lista: any) => {
            this.regionais = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    carregarCev(missaoId : number): void {
        this.centroEvangelizacaoService.porMissao(missaoId).then((response: any) => {
             this.centrosEvangelizacao = response.entity;          
         }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
         });;
     }

    carregarMissoes(regionalSelecionadoId : number): void {
        this.missaoService.porRegional(regionalSelecionadoId).then((response: any) => {
            this.missoes = response.entity;          
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }

    carregarPaises() : void {
        this.paisService.findAll().then( (lista: any) => {
            this.paises = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    carregarEstados(paisSelecionadoId : number) : void {

        let busca = {
            paisId: paisSelecionadoId,
            nome: undefined,
            pageNumber : 0, 
            quantityOfElements : 1000
        };

        this.estadoService.buscarEstados(busca).then( (lista: any) => {
            this.estados = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });

    }


    buscarMinisterio() : void {
        this.ministeriosService.buscarMinisterios(this.busca).then( (response: any) => {
            this.ministerios = response.entity;
            this.totalDePaginas = response.quantity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }
    
    deletarMinisterio(id: number) : void {

        if(confirm('Deseja deletar este Ministério?'))
        {
            this.ministeriosService.delete(id)
            .then( (response: any) => {
                this.swtAlert2Service.successAlert(response.message);
                this.buscarMinisterio();
            })
            .catch((err) => {
                this.swtAlert2Service.errorAlert(err.error.errors);
            });
        }
    }
}