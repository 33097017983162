import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
export class ParecerCoordenadorColegiadoViewComponent {
    constructor(wopGetAllPublic, pessoaService, imagemService, paisService, estadoService, cidadeService, elementoGrupoDominioService, elementoGrupoDominioLocalService, missaoService, swtAlert2Service, usuarioService, pessoaBuilder, pessoaInfoBuilder, dadoVocacionalService, dadoFormandoCAService, caminhoFormativoService, respostaParecerService, questionarioFormandoService, parecerResponsavelService, dadoFormandoCABuilder, caminhoFormativoFormandoBuilder, respostaParecerBuilder, questionarioFormandoBuilder, route, router) {
        this.wopGetAllPublic = wopGetAllPublic;
        this.pessoaService = pessoaService;
        this.imagemService = imagemService;
        this.paisService = paisService;
        this.estadoService = estadoService;
        this.cidadeService = cidadeService;
        this.elementoGrupoDominioService = elementoGrupoDominioService;
        this.elementoGrupoDominioLocalService = elementoGrupoDominioLocalService;
        this.missaoService = missaoService;
        this.swtAlert2Service = swtAlert2Service;
        this.usuarioService = usuarioService;
        this.pessoaBuilder = pessoaBuilder;
        this.pessoaInfoBuilder = pessoaInfoBuilder;
        this.dadoVocacionalService = dadoVocacionalService;
        this.dadoFormandoCAService = dadoFormandoCAService;
        this.caminhoFormativoService = caminhoFormativoService;
        this.respostaParecerService = respostaParecerService;
        this.questionarioFormandoService = questionarioFormandoService;
        this.parecerResponsavelService = parecerResponsavelService;
        this.dadoFormandoCABuilder = dadoFormandoCABuilder;
        this.caminhoFormativoFormandoBuilder = caminhoFormativoFormandoBuilder;
        this.respostaParecerBuilder = respostaParecerBuilder;
        this.questionarioFormandoBuilder = questionarioFormandoBuilder;
        this.route = route;
        this.router = router;
        this.intlTelInputCalled = false;
        this.missoesVinculo = new Array();
        this.missoes = new Array();
        this.formasVida = new Array();
        this.niveisFormacao = new Array();
        this.dadoFormandoCA = this.dadoFormandoCABuilder.getInstance();
        this.caminhoFormativoFormando =
            this.caminhoFormativoFormandoBuilder.getInstance();
        this.imageChangedEvent = '';
        this.croppedImage = '';
        this.nextClicked = false;
        this.pessoaResponsavel = this.pessoaBuilder.getInstance();
        this.formando = this.pessoaBuilder.getInstance();
        this.urlBaseImagem = environment.imagem.urlBaseImagem;
        this.url = environment.usuario.selectPorUsername;
        this.questionarioFormando = this.questionarioFormandoBuilder.getInstance();
        this.tabNames = ['dados-pessoais-tab-content', 'questionario-tab-content'];
        this.activeTabNumber = 0;
        this.resposta21 = respostaParecerBuilder.getInstance();
        this.resposta22 = respostaParecerBuilder.getInstance();
        this.resposta23 = respostaParecerBuilder.getInstance();
        this.resposta24 = respostaParecerBuilder.getInstance();
        this.resposta25 = respostaParecerBuilder.getInstance();
        this.resposta26 = respostaParecerBuilder.getInstance();
        this.resposta27 = respostaParecerBuilder.getInstance();
        this.resposta28 = respostaParecerBuilder.getInstance();
        this.resposta29 = respostaParecerBuilder.getInstance();
        this.resposta30 = respostaParecerBuilder.getInstance();
        this.resposta31 = respostaParecerBuilder.getInstance();
        this.resposta32 = respostaParecerBuilder.getInstance();
        this.resposta33 = respostaParecerBuilder.getInstance();
        this.resposta34 = respostaParecerBuilder.getInstance();
        this.resposta35 = respostaParecerBuilder.getInstance();
    }
    ngOnInit() {
        this.initialization();
    }
    imprimirValorDoForm() {
        console.log('Valor do Form', JSON.stringify(this.dadosPessoaisForm.value));
    }
    initialization() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.getUsuarioAtual();
            this.carregarFormasVida();
            this.carregarNiveisFormacao();
            yield this.carregarMissoesVinculo();
            this.parecerResponsavelId = Number(this.route.snapshot.paramMap.get('id'));
            yield this.carregarParecerResponsavel();
            yield this.carregarQuestionarioFormando();
            yield this.carregarPessoaFormando(this.questionarioFormando.pessoaId);
            this.pessoaResponsavel = yield this.carregarPessoaResponsavel(this.usuarioAtual.id);
            yield this.carregarDadoVocacional();
            yield this.carregarDadoFormandoCA();
            yield this.carregarCaminhoFormativo();
            yield this.carregarRespostas();
            this.imprimirValorDoForm();
        });
    }
    getUsuarioAtual() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.usuarioService.getCurrentUser();
                this.usuarioAtual = response.entity;
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    carregarMissoesVinculo() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.wopGetAllPublic.getAllMissao();
                this.missoesVinculo = response.entity;
                this.missoesVinculo = this.missoesVinculo.sort((a, b) => a.nome.localeCompare(b.nome));
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    carregarPessoaFormando(pessoaId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.pessoaService.buscaPorId(pessoaId);
                this.formando = response.entity;
                this.pessoaFormandoId = this.formando.id;
                if (this.formando.imagemId != null) {
                    let blogImage = yield this.getPessoaImagem(this.formando.imagemId);
                    this.imagemService.createImage(blogImage, this.formando);
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarPessoaResponsavel(pessoaId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.pessoaService.buscaPorId(pessoaId);
                return response.entity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarFormasVida() {
        let buscaFormaVida = {
            grupoNome: 'FORMA_VIDA',
        };
        this.elementoGrupoDominioService
            .buscarPorGrupoDominioNome(buscaFormaVida)
            .then((lista) => {
            this.formasVida = lista.entity;
        })
            .catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }
    carregarNiveisFormacao() {
        let busca = {
            grupoNome: 'NIVEL_FORMACAO',
        };
        this.elementoGrupoDominioService
            .buscarPorGrupoDominioNome(busca)
            .then((lista) => {
            this.niveisFormacao = lista.entity;
        })
            .catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }
    carregarPaises() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.paisService.findAll();
                this.paises = response.entity;
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    carregarEstados(paisId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let request = {
                    paisId: paisId,
                    nome: undefined,
                    pageNumber: 0,
                    quantityOfElements: 1000,
                };
                let response = yield this.estadoService.buscarEstados(request);
                this.estados = response.entity;
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    carregarCidades(estadoId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let request = {
                    estadoId: estadoId,
                    nome: undefined,
                    pageNumber: 0,
                    quantityOfElements: 1000,
                };
                let response = yield this.cidadeService.buscarCidades(request);
                this.cidades = response.entity;
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    logOnConsole(dadosPessoaisForm) {
        console.log(dadosPessoaisForm);
    }
    activateTab(tab) {
        $('.nav-tabs a[href="#' + tab + '"]').removeClass('disabled');
        $('.nav-tabs a[href="#' + tab + '"]').tab('show');
    }
    activateTab2(action) {
        if (action.localeCompare('previous') == 0) {
            if (this.activeTabNumber > 0) {
                this.activeTabNumber -= 1;
            }
        }
        else if (action.localeCompare('next') == 0) {
            if (this.activeTabNumber < this.tabNames.length - 1) {
                this.activeTabNumber += 1;
            }
        }
        $('.nav-tabs a[href="#' + this.tabNames[this.activeTabNumber] + '"]').removeClass('disabled');
        $('.nav-tabs a[href="#' + this.tabNames[this.activeTabNumber] + '"]').tab('show');
    }
    salvar() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            //this.atualizarCaminhoFormativo();
            this.atualizarParecerResponsavel('INICIADO');
        });
    }
    fileChangeEvent(event) {
        this.imageChangedEvent = event;
        console.log(event);
    }
    imageCropped(event) {
        this.croppedImage = event.base64;
    }
    imageLoaded() {
        console.log('Imagem carregada');
    }
    cropperReady() {
        console.log('Imagem cortada');
    }
    loadImageFailed() {
        console.log('Carregamento da imagem falhou!');
    }
    carregarDadoVocacional() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.dadoVocacionalService.porPessoaId(this.formando.id);
                if (response.entity != undefined && response.entity != null) {
                    this.dadoVocacional = response.entity;
                    this.pessoaFormadorPessoalSelecionado =
                        this.pessoaInfoBuilder.getInstance();
                    this.pessoaFormadorPessoalSelecionado.nome =
                        this.dadoVocacional.formadorPessoalPessoaNome;
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    selecionarFormadorComunitario(pessoa) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            //this.dadoVocacional.formadorPessoalPessoaId = pessoa.id;
            //this.pessoaFormadorComunitarioSelecionado = pessoa;
            //$('#listagem-pessoa-formador-comunitario').modal('hide');
        });
    }
    getPessoaImagem(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.imagemService.getPessoaPhoto(id);
                return response.body;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarDadoFormandoCA() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.dadoFormandoCAService.porPessoaId(this.formando.id);
                if (response != null && response != undefined) {
                    this.dadoFormandoCA = response;
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarCaminhoFormativo() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.caminhoFormativoService.porPessoaId(this.formando.id);
                if (response != null && response != undefined) {
                    this.caminhoFormativoFormando = response;
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    atualizarCaminhoFormativo() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (this.caminhoFormativoFormando.id != null) {
                    let response = yield this.caminhoFormativoService.atualizar(this.caminhoFormativoFormando);
                    this.caminhoFormativoFormando = response;
                    this.swtAlert2Service.successAlert(response.message);
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarRespostas() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.respostaParecerService.porPessoaId(this.pessoaFormandoId);
                if (response != null && response != undefined) {
                    this.respostas = response.content;
                    this.respostas.forEach((resposta) => {
                        switch (resposta.parecer_pergunta_id) {
                            case 21:
                                this.resposta21 = resposta;
                                break;
                            case 22:
                                this.resposta22 = resposta;
                                break;
                            case 23:
                                this.resposta23 = resposta;
                                break;
                            case 24:
                                this.resposta24 = resposta;
                                break;
                            case 25:
                                this.resposta25 = resposta;
                                break;
                            case 26:
                                this.resposta26 = resposta;
                                break;
                            case 27:
                                this.resposta27 = resposta;
                                break;
                            case 28:
                                this.resposta28 = resposta;
                                break;
                            case 29:
                                this.resposta29 = resposta;
                                break;
                            case 30:
                                this.resposta30 = resposta;
                                break;
                            case 31:
                                this.resposta31 = resposta;
                                break;
                            case 32:
                                this.resposta32 = resposta;
                                break;
                            case 33:
                                this.resposta33 = resposta;
                                break;
                            case 34:
                                this.resposta34 = resposta;
                                break;
                            case 35:
                                this.resposta35 = resposta;
                                break;
                        }
                    });
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarParecerResponsavel() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.parecerResponsavelService.buscarPorId(this.parecerResponsavelId);
                if (response != null && response != undefined) {
                    this.parecerResponsavel = response;
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarQuestionarioFormando() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.questionarioFormandoService.buscarPorId(this.parecerResponsavel.questionarioFormandoId);
                if (response != null && response != undefined) {
                    this.questionarioFormando = response;
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    atualizarResposta(resposta) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                resposta.pessoaId = this.pessoaFormandoId;
                if (resposta.id == null) {
                    let response = yield this.respostaParecerService.cadastrar(resposta);
                    resposta = response;
                    this.swtAlert2Service.successAlert(response.message);
                }
                else {
                    let response = yield this.respostaParecerService.atualizar(resposta.id, resposta);
                    resposta = response;
                    this.swtAlert2Service.successAlert(response.message);
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    salvarQuestionario() {
        this.resposta21.parecer_pergunta_id = 21;
        this.atualizarResposta(this.resposta21);
        this.resposta22.parecer_pergunta_id = 22;
        this.atualizarResposta(this.resposta22);
        this.resposta23.parecer_pergunta_id = 23;
        this.atualizarResposta(this.resposta23);
        this.resposta24.parecer_pergunta_id = 24;
        this.atualizarResposta(this.resposta24);
        this.resposta25.parecer_pergunta_id = 25;
        this.atualizarResposta(this.resposta25);
        this.resposta26.parecer_pergunta_id = 26;
        this.atualizarResposta(this.resposta26);
        this.resposta27.parecer_pergunta_id = 27;
        this.atualizarResposta(this.resposta27);
        this.resposta28.parecer_pergunta_id = 28;
        this.atualizarResposta(this.resposta28);
        this.resposta29.parecer_pergunta_id = 29;
        this.atualizarResposta(this.resposta29);
        this.resposta30.parecer_pergunta_id = 30;
        this.atualizarResposta(this.resposta30);
        this.resposta31.parecer_pergunta_id = 31;
        this.atualizarResposta(this.resposta31);
        this.resposta32.parecer_pergunta_id = 32;
        this.atualizarResposta(this.resposta32);
        this.resposta33.parecer_pergunta_id = 33;
        this.atualizarResposta(this.resposta33);
        this.resposta34.parecer_pergunta_id = 34;
        this.atualizarResposta(this.resposta34);
        this.resposta35.parecer_pergunta_id = 35;
        this.atualizarResposta(this.resposta35);
        this.atualizarParecerResponsavel('INICIADO');
        this.atualizarCaminhoFormativo();
    }
    enviarQuestionario() {
        this.salvarQuestionario();
        this.atualizarParecerResponsavel('ENVIADO');
        this.router.navigate(['secured/parecer-agradecimento']);
    }
    atualizarParecerResponsavel(status) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (this.parecerResponsavel.id != null) {
                    this.parecerResponsavel.status = status;
                    if (status == 'ENVIADO') {
                        this.parecerResponsavel.data_envio = new Date();
                    }
                    let response = yield this.parecerResponsavelService.atualizar(this.parecerResponsavel.id, this.parecerResponsavel);
                    this.parecerResponsavel = response;
                    this.swtAlert2Service.successAlert(response.message);
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
}
