import { Component, OnInit } from '@angular/core';
import { IsActiveMenuItem } from './components/left-sidebar-menu-item/left-sidebar-menu-item.component';

@Component({
  selector: 'left-sidebar-assistente-formacao-component',
  templateUrl: './left-sidebar-assistente-formacao.component.html',
})
export class LeftSidebarAssistenteFormacaoComponent implements OnInit {
  private mapActiveItems = new Map<string, Array<IsActiveMenuItem>>();

  ngOnInit(): void {}

  updateIsActive(menuName: string, data: IsActiveMenuItem) {
    const isActiveList = this.mapActiveItems.get(menuName);

    if (!isActiveList) {
      this.mapActiveItems.set(menuName, [data]);
    } else {
      const newIsActiveList = isActiveList.filter(
        (iac) => iac.path != data.path
      );

      this.mapActiveItems.set(menuName, [...newIsActiveList, data]);
    }
  }

  isMenuActive(menuName: string) {
    const activeItems = this.mapActiveItems.get(menuName);
    return activeItems ? true : false;
  }
}
