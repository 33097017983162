import { GenericService } from 'src/app/core/services/generic.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from "@angular/core";
import { Questionario } from 'src/app/model/questionario.model';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';


@Injectable()
export class QuestionarioService extends GenericService<Questionario> {
    
    constructor(protected http: HttpClient, protected router:Router) {
        super(http,router);
        this.setEntityType('questionario');
    }

    porPessoaId(id: number) : Promise<any> {
        return this.http.get<any>(`${this.apiURL}/porpessoa/${id}`, { 'headers' : this.getHeaders() }).toPromise();
    }  
    
    salvarQuestionario(questionario: Questionario): Observable<Questionario> {
        return this.http.post<Questionario>(this.apiURL, questionario);
    }

    getQuestionario(questionarioId: number): Observable<Questionario> {
        const url = `${this.apiURL}/${questionarioId}`;
        return this.http.get<Questionario>(url);
    }
    
    buscarAbertos(): Promise<any> {
        return this.http
          .get<any>(
            `${environment.moduloFormacao.urlModuloFormacao}questionario/aberta`,
            { headers: this.getHeaders() }
          )
          .toPromise();
      }
}