import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SecuredModule } from '../../layout/secured.module';
import { RouterModule } from '@angular/router';
import { AceiteTermoComponent } from './aceitetermouso.component';
import { AceiteTermoRoutingModule } from './aceitetermouso-routing.module';
import { AceiteTermoService } from './service/aceitetermouso.service';
import { AceiteTermoBuilder } from 'src/app/builder/aceitetermo.builder';
import { NgxSummernoteModule } from 'ngx-summernote';

@NgModule({
    declarations: [ 
        AceiteTermoComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        AceiteTermoRoutingModule,
        NgxSummernoteModule,
        FormsModule,
        SecuredModule
    ],
    providers: [
        AceiteTermoService,
        AceiteTermoBuilder
    ]
})
export class AceiteTermoModule {
}