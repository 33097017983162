import { Component, Input, OnInit } from '@angular/core';
import { DetalhesDashboardCelula } from 'src/app/model/DetalhesDashboardCelula.model';

@Component({
  selector: 'app-dashboard-info-box',
  templateUrl: './dashboard-info-box.component.html',
  styleUrls: ['./dashboard-info-box.component.scss'],
})
export class DashboardInfoBoxComponent implements OnInit {
  @Input()
  detalhesDashboardCelula: DetalhesDashboardCelula;

  constructor() {}

  ngOnInit() {}
}
