import { Component, OnInit } from '@angular/core';
import { PermissaoLocalService } from './service/permissao.local.service';
import { Permissao } from 'src/app/model/permissao.model';
import { PermissaoBuilder } from 'src/app/builder/permissao.builder';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';

@Component({
    selector: 'permissao-local',
    templateUrl: './permissao.local.component.html'
})
export class PermissaoLocalComponent implements OnInit {

    public permissao: Permissao;
    public permissaoBuilder: PermissaoBuilder;

    constructor(
        public permissaoService: PermissaoLocalService,
        public swtAlert2Service: SwtAlert2Service
    ) 
    {
        this.permissaoBuilder = new PermissaoBuilder();
        this.permissao = this.permissaoBuilder.getInstance();
    }

    ngOnInit() {        
    }

    salvar(permissao: Permissao) : void {

        this.permissaoService.create(permissao).then( (response:any) => {
            this.permissao = new Permissao(null,null,null,[]);
            this.swtAlert2Service.successAlert(response.message);

        }).catch( (err:any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

}