import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IgxTabsModule } from 'igniteui-angular';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ApostoladoBuilder } from 'src/app/builder/apostolado.builder';
import { CelibatarioBuilder } from 'src/app/builder/celibatario.builder';
import { DadoVocacionalBuilder } from 'src/app/builder/dadovocacional.builder';
import { FilhoBuilder } from 'src/app/builder/filho.builder';
import { HistoricoMissionarioMissaoBuilder } from 'src/app/builder/historicomissionariomissao.builder';
import { HistoricoMissionarioMissaoApostoladoBuilder } from 'src/app/builder/historicomissionariomissaoapostolado.builder';
import { RelacionamentoBuilder } from 'src/app/builder/relacionamento.builder';
import { SacerdoteBuilder } from 'src/app/builder/sacerdote.builder';
import { SecuredModule } from '../../layout/secured.module';
import { AptidaoService } from '../aptidao/service/aptidao.service';
import { EscolaridadeService } from '../escolaridade/service/escolaridade.service';
import { MembroComunidadeVidaService } from '../membrocomunidadevida/servico/membrocomunidadevida.service';
import { ListagemPessoaInfoModalModule } from '../pessoa/modulo/listagem-pessoainfo-modal/listagempessoainfo.modal.module';
import { PessoaService } from '../pessoa/service/pessoa.service';
import { TrabalhoService } from '../trabalho/service/trabalho.service';
import { DadosVocacionaisRoutingModule } from './dadosvocacionais-routing.module';
import { DadosVocacionaisEditComponent } from './dadosvocacionais.edit.component';
import { MissionarioMissaoAtualModule } from './modulo-missionario-missao-atual/missionariomissaoatual.module';
import { ApostoladoService } from './service/apostolado.service';
import { CelibatarioService } from './service/celibatario.service';
import { DadoVocacionalService } from './service/dadovocacional.service';
import { FilhoService } from './service/filho.service';
import { HistoricoMissionarioMissaoService } from './service/historicomissionariomissao.service';
import { HistoricoMissionarioMissaoApostoladoService } from './service/historicomissionariomissaoapostolado.service';
import { RelacionamentoService } from './service/relacionamento.service';
import { SacerdoteService } from './service/sacerdote.service';

@NgModule({
    declarations:[
        DadosVocacionaisEditComponent
    ],
    imports:[
        CommonModule,
        RouterModule,
        DadosVocacionaisRoutingModule,
        FormsModule,
        SecuredModule,
        IgxTabsModule,
        ImageCropperModule,
        ListagemPessoaInfoModalModule,
        MissionarioMissaoAtualModule
    ],
     providers:[
         PessoaService,
         MembroComunidadeVidaService,
         AptidaoService,
         EscolaridadeService,
         TrabalhoService,
         FilhoService,
         FilhoBuilder,
         ApostoladoBuilder,
         ApostoladoService,
         CelibatarioBuilder,
         CelibatarioService,
         DadoVocacionalBuilder,
         DadoVocacionalService,
         HistoricoMissionarioMissaoBuilder,
         HistoricoMissionarioMissaoService,
         HistoricoMissionarioMissaoApostoladoBuilder,
         HistoricoMissionarioMissaoApostoladoService,
         RelacionamentoBuilder,
         RelacionamentoService,
         SacerdoteBuilder,
         SacerdoteService
     ]
 })
  export class DadosVocacionaisModule {
     
 }