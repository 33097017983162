import { Component, OnInit } from '@angular/core';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { AcessoUsuarioEstruturaService } from './service/acessousuarioestrutura.service';
import { AutorizacaoService } from '../autorizacao/service/autorizacao.service';
import { Autorizacao } from 'src/app/model/autorizacao.model';
import { environment } from 'src/environments/environment';
import { AcessoUsuarioEstrutura } from 'src/app/model/acessousuarioestrutura.model';
import { AcessoUsuarioEstruturaBuilder } from 'src/app/builder/acessousuarioestrutura.builder';

@Component({
    selector: 'acessousuarioestrutura',
    templateUrl: './acessousuarioestrutura.component.html'
})
export class AcessoUsuarioEstruturaComponent implements OnInit {

    public acessoUsuarioEstrutura: AcessoUsuarioEstrutura;
    public autorizacoes: Array<Autorizacao>;
    public pessoaSearchUrl: string;

    constructor(
        public acessoUsuarioEstruturaBuilder: AcessoUsuarioEstruturaBuilder,
        public acessoUsuarioEstruturaService: AcessoUsuarioEstruturaService,
        public autorizacaoService: AutorizacaoService,
        public swtAlert2Service: SwtAlert2Service
    ) 
    {
        this.acessoUsuarioEstruturaBuilder = new AcessoUsuarioEstruturaBuilder();
        this.acessoUsuarioEstrutura = this.acessoUsuarioEstruturaBuilder.getInstance();
        this.pessoaSearchUrl = environment.pessoa.usuarioIdPorPessoaNomeURL;
    }

    ngOnInit() { 
        this.init();
    }

    async init(): Promise<any> {
        await this.carregarAutorizacao();
    }

    async carregarAutorizacao() : Promise<any> {

        try 
        {
            let response = await this.autorizacaoService.findAll();
            this.autorizacoes = response.entity;
            
        } catch (err) {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }

    }

    async salvar(acessoUsuarioEstrutura: AcessoUsuarioEstrutura) : Promise<any> {

        try 
        {
            let response = await this.acessoUsuarioEstruturaService.create(acessoUsuarioEstrutura);
            this.acessoUsuarioEstrutura = this.acessoUsuarioEstruturaBuilder.getInstance();
            this.swtAlert2Service.successAlert(response.message);
        } catch (err) {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }

    }

    setarElementoSelecionado(valor) {
        this.acessoUsuarioEstrutura.usuarioId = valor;
    }

}