<secured>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h3 i18n>Listagem de Autorizações - WoP</h3>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <form (ngSubmit)="buscarAutorizacoes()" #autorizacoesForm="ngForm" novalidate>
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title">Buscar Autorizações</h3>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label i18n for="la_nome_exibicao">Nome de exibição</label>
                                                <input [(ngModel)]="busca.nomeExibicao" name="nome_exibicao" type="text" class="form-control" id="la_nome_exibicao" placeholder="Digite o nome de exibição" i18n-placeholder>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label i18n for="la_nome">Nome</label>
                                                <input [(ngModel)]="busca.nome" name="na_nome" type="text" class="form-control" id="la_nome" placeholder="Digite o nome" i18n-placeholder>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-footer">
                                    <button type="submit" class="btn btn-primary float-right" i18n>Buscar</button>
                                    <button type="reset" class="btn btn-default float-right" style="margin-right: 0.5rem;" i18n>Limpar</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">

                        <table-component [tituloSessao]="'Autorizações'" [cols]="4" [rows]="busca.quantityOfElements" [totalDePaginas]="totalDePaginas" (eventoBotao)="cliqueNoBotaoListener($event)">
                            <tr tableheader>
                                <th style="width: 10px">#</th>
                                <th>Nome de exibição</th>
                                <th>Nome</th>
                                <th></th>
                            </tr>
                            <tr tablebody *ngFor="let autorizacao of autorizacoes">
                                <td>{{autorizacao.id}}</td>
                                <td>{{autorizacao.nomeExibicao}}</td>
                                <td>{{autorizacao.nome}}</td>
                                <td class="text-center">
                                    <button class="btn btn-sm btn-primary" routerLink="../autorizacao/{{autorizacao.id}}" title="Editar autorização" alt="Editar autorização"><i class="fas fa-edit"></i></button>&nbsp;
                                    <button class="btn btn-sm btn-danger" (click)="deletar(autorizacao.id)" title="Deletar autorização" alt="Deletar autorização"><i class="fas fa-trash-alt"></i></button>
                                </td>
                            </tr>
                        </table-component>
                    </div>
                </div>
            </div>
        </section>
    </div>
</secured>