export class CadastroPublico {

    constructor(
        public email: string,
        public username: string,
        public password: string,
        public confPassword: string,
        public nome: string,
        public sobrenome: string,
        public telefone: string,
        public sexo: string,
        public dataNascimento: Date,
        public nacionalidadeId: number,
        public missaoAtualId: number,
        public difusaoAtualId: number,
        public aceiteTermo: boolean,
        public aceiteTermoTratamentoDados: boolean,
        public svesId: number,
        public nivelFormacaoId: number,
        public formaVidaId: number,
        public token: string
    )
    {}

}