<secured>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h3 i18n>Irradiação</h3>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">

            <form (ngSubmit)="salvar(irradiacao)" #usuarioForm="ngForm" novalidate>
                <div class="card card-default color-pallete-box">
                    <div class="card-header">
                        <h3 class="card-title">
                            <i class="fas fa-map-marked-alt"></i>
                            <a i18n> Dados da Irradiação </a>
                        </h3>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-12 col-md-3 col-lg-3">
                                <div class="form-group">
                                    <label i18n for="nir_r">Regional</label>
                                    <select class="form-control" id="nir_r" [(ngModel)]="regionalSelecionadoId" (change)="carregarMissoes()" name="nir_r">
                                        <option [value]="null" i18n>Selecione um Regional</option>
                                        <option *ngFor="let reg of regionais" [value]="reg.id">{{reg.nome}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-3 col-lg-3">
                                <div class="form-group">
                                    <label i18n for="nir_missao">Missão</label>
                                    <select class="form-control" id="nir_missao" [(ngModel)]="irradiacao.missaoId" name="nir_missao">
                                        <option [value]="null" i18n>Selecione uma Missão</option>
                                        <option *ngFor="let missao of missoes" [value]="missao.id">{{missao.nome}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-3 col-lg-3">
                                <div class="form-group">
                                    <label i18n for="nir_pais">País</label>
                                    <select class="form-control" id="nir_pais" [(ngModel)]="paisSelecionadoId" (change)="carregarEstados()" name="nir_pais">
                                        <option [value]="null" i18n>Selecione um País</option>
                                        <option *ngFor="let pais of paises" [value]="pais.id">{{pais.nome}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-3 col-lg-3">
                                <div class="form-group">
                                    <label i18n for="nir_estado">Estado</label>
                                    <select class="form-control" id="nir_estado" [(ngModel)]="estadoSelecionadoId" (change)="carregarCidades()" name="nir_estado">
                                        <option [value]="null" i18n>Selecione um Estado</option>
                                        <option *ngFor="let estado of estados" [value]="estado.id">{{estado.nome}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-3 col-lg-3">
                                <div class="form-group">
                                    <label i18n for="nir_cidade">Cidade</label>
                                    <select class="form-control" id="nir_cidade" [(ngModel)]="irradiacao.cidadeId" name="nir_cidade">
                                        <option [value]="null" i18n>Selecione uma Cidade</option>
                                        <option *ngFor="let cidade of cidades" [value]="cidade.id">{{cidade.nome}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-3 col-lg-3">
                                <div class="form-group">
                                    <label i18n for="nir_nome">Nome da Irradiacao</label>
                                    <input [(ngModel)]="irradiacao.nome" name="nir_nome" type="text" class="form-control" id="nir_nome" placeholder="Digite o nome" i18n-placeholder>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-3 col-lg-3">
                                <div class="form-group">
                                    <label i18n for="nir_f">Fase da Irradiação</label>
                                    <select class="form-control" id="nir_f" [(ngModel)]="irradiacao.faseIrradiacaoId" name="nir_f">
                                        <option [value]="null" i18n>Selecione uma fase</option>
                                        <option *ngFor="let fase of fasesIrradiacao" [value]="fase.id">{{fase.nome}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-3 col-lg-3">
                                <div class="form-group">
                                    <label i18n for="nir_dtfund">Data de Fundação</label>
                                    <input [(ngModel)]="irradiacao.dataFundacao" name="nir_dtfund" type="date" class="form-control" id="nir_dtfund" i18n-placeholder>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <button type="submit" class="btn btn-primary float-right" i18n>Salvar</button>
                    </div>
                </div>
            </form>
        </section>
    </div>
</secured>