import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MissionariosComponent } from './missionarios.component';
import { MissionariosBuscaAvancadaComponent } from './missionarios.buscaavancada.component';
import { MissionarioViewComponent } from './missionario.view.component';

const pessoaRoutes: Routes = [
    {
        path: 'secured/missionarios',
        component: MissionariosComponent
    },
    {
        path: 'secured/missionarios/buscaavancada',
        component: MissionariosBuscaAvancadaComponent
    }
    ,
    {
        path: 'secured/missionario/:id',
        component: MissionarioViewComponent
    }
] 

@NgModule({
    imports: [
        RouterModule.forChild(pessoaRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class MissionariosRoutingModule {
    
}