export class Envio {
    
    constructor(
        public id: number,
        public pessoaId: number,
        public pessoaNome: string,
        public regionalId: number,
        public regionalNome: string,
        public missaoId: number,
        public missaoNome: string,
        public casaComunitariaId: number,
        public casaComunitariaNome: string,
        public apostoladoId: number,
        public apostoladoNome: string,
        public statusId: number,
        public statusNome: string,
    )
    {}

}