import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
export class RemocaoMembroCasaComunitariaComponent {
    constructor(imagemService, missionarioMissaoAtualService, pessoaService, salvarMissionarioMissaoAtualBuilder, remocaoMembroCasaComunitariaBuilder, elementoGrupoDominioService, route, router, swtAlert2Service) {
        this.imagemService = imagemService;
        this.missionarioMissaoAtualService = missionarioMissaoAtualService;
        this.pessoaService = pessoaService;
        this.salvarMissionarioMissaoAtualBuilder = salvarMissionarioMissaoAtualBuilder;
        this.remocaoMembroCasaComunitariaBuilder = remocaoMembroCasaComunitariaBuilder;
        this.elementoGrupoDominioService = elementoGrupoDominioService;
        this.route = route;
        this.router = router;
        this.swtAlert2Service = swtAlert2Service;
        this.eventEmitter = new EventEmitter();
        this.remocaoMembroCasaComunitaria = this.remocaoMembroCasaComunitariaBuilder.getInstance();
        this.motivosSaida = new Array();
    }
    ngOnInit() {
        this.initializer();
    }
    initializer() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.carregarMotivoSaidaCasaComunitaria();
            if (this.membroCasaComunitaria != null && this.membroCasaComunitaria.imagemId != null) {
                let blogImage = yield this.getPessoaImagem(this.membroCasaComunitaria.imagemId);
                this.imagemService.createImage(blogImage, this.membroCasaComunitaria);
            }
        });
    }
    carregarMotivoSaidaCasaComunitaria() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            let busca = {
                grupoNome: 'MOTIVO_SAIDA_CASA_COMUNITARIA'
            };
            try {
                let response = yield this.elementoGrupoDominioService.buscarPorGrupoDominioNome(busca);
                this.motivosSaida = response.entity;
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    removerMembroCasaComunitaria(membroCasaComunitaria) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (confirm("Confirma a remoção do missionário da casa comunitária?")) {
                try {
                    this.remocaoMembroCasaComunitaria.missionarioMissaoAtualId = membroCasaComunitaria.missionarioMissaoAtualId;
                    let response = yield this.missionarioMissaoAtualService.removerMembroDaCasaComunitaria(this.remocaoMembroCasaComunitaria);
                    this.resetarFormulario();
                    this.swtAlert2Service.successAlert("Missionário removido da casa comunitária com sucesso!");
                    this.eventEmitter.emit("recarregar_membros_casa_comunitaria");
                }
                catch (err) {
                    this.swtAlert2Service.errorAlert(err.error.errors);
                }
            }
        });
    }
    resetarFormulario() {
        this.remocaoMembroCasaComunitaria = this.remocaoMembroCasaComunitariaBuilder.getInstance();
        this.exibirCampoDescricaoMotivoSaida = false;
        $('#' + this.modalId).modal('hide');
    }
    getPessoaImagem(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.imagemService.getPessoaPhoto(id);
                return response.body;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    exibirSeMotivoSaidaForOutro(idMotivoSaida) {
        if (this.motivosSaida.length > 0) {
            for (let motivo of this.motivosSaida) {
                if (motivo.id == idMotivoSaida) {
                    if (motivo.valor == 'O') {
                        this.exibirCampoDescricaoMotivoSaida = true;
                        break;
                    }
                    else {
                        this.exibirCampoDescricaoMotivoSaida = false;
                        break;
                    }
                }
            }
        }
        else {
            this.exibirCampoDescricaoMotivoSaida = false;
        }
    }
}
