/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
import * as i3 from "./left-sidebar-user-panel.component";
var styles_LeftSidebarUserPanelComponent = [];
var RenderType_LeftSidebarUserPanelComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_LeftSidebarUserPanelComponent, data: {} });
export { RenderType_LeftSidebarUserPanelComponent as RenderType_LeftSidebarUserPanelComponent };
export function View_LeftSidebarUserPanelComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "user-panel mt-3 pb-3 mb-3 d-flex"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["class", "image"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "img", [["alt", "User Avatar"], ["class", "img-circle elevation-2"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 4, "div", [["class", "info"]], null, null, null, null, null)), (_l()(), i0.ɵeld(4, 0, null, null, 3, "a", [["class", "d-block"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 5).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(5, 671744, null, 0, i1.RouterLinkWithHref, [i1.Router, i1.ActivatedRoute, i2.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵeld(6, 0, null, null, 1, "small", [], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["", ""]))], function (_ck, _v) { var currVal_3 = "/secured/perfil"; _ck(_v, 5, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.thumbnail == null) ? "assets/images/ppl.png" : _co.thumbnail); _ck(_v, 2, 0, currVal_0); var currVal_1 = i0.ɵnov(_v, 5).target; var currVal_2 = i0.ɵnov(_v, 5).href; _ck(_v, 4, 0, currVal_1, currVal_2); var currVal_4 = ((_co.nome != null) ? _co.nome : ""); _ck(_v, 7, 0, currVal_4); }); }
export function View_LeftSidebarUserPanelComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "left-sidebar-user-panel-component", [], null, null, null, View_LeftSidebarUserPanelComponent_0, RenderType_LeftSidebarUserPanelComponent)), i0.ɵdid(1, 114688, null, 0, i3.LeftSidebarUserPanelComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LeftSidebarUserPanelComponentNgFactory = i0.ɵccf("left-sidebar-user-panel-component", i3.LeftSidebarUserPanelComponent, View_LeftSidebarUserPanelComponent_Host_0, { thumbnail: "thumbnail", nome: "nome" }, {}, []);
export { LeftSidebarUserPanelComponentNgFactory as LeftSidebarUserPanelComponentNgFactory };
