<secured>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h3 i18n>Difusão</h3>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">

            <form (ngSubmit)="salvar(difusao)" #usuarioForm="ngForm" novalidate>
                <div class="card card-default color-pallete-box">
                    <div class="card-header">
                        <h3 class="card-title">
                            <i class="fas fa-map-marked-alt"></i>
                            <a i18n> Dados da Difusão </a>
                        </h3>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-12 col-md-3 col-lg-3">
                                <div class="form-group">
                                    <label i18n for="ndifusao_r">Regional</label>
                                    <select class="form-control" id="ndifusao_r" [(ngModel)]="difusao.regionalId" name="ndifusao_r">
                                        <option [value]="null" i18n>Selecione um Regional</option>
                                        <option *ngFor="let reg of regionais" [value]="reg.id">{{reg.nome}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-3 col-lg-3">
                                <div class="form-group">
                                    <label i18n for="ndifusao_pais">País</label>
                                    <select class="form-control" id="ndifusao_pais" [(ngModel)]="paisSelecionadoId" (change)="carregarEstados()" name="ndifusao_pais">
                                        <option [value]="null" i18n>Selecione um País</option>
                                        <option *ngFor="let pais of paises" [value]="pais.id">{{pais.nome}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-3 col-lg-3">
                                <div class="form-group">
                                    <label i18n for="ndifusao_estado">Estado</label>
                                    <select class="form-control" id="ndifusao_estado" [(ngModel)]="estadoSelecionadoId" (change)="carregarCidades()" name="ndifusao_estado">
                                        <option [value]="null" i18n>Selecione um Estado</option>
                                        <option *ngFor="let estado of estados" [value]="estado.id">{{estado.nome}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-3 col-lg-3">
                                <div class="form-group">
                                    <label i18n for="ndifusao_cidade">Cidade</label>
                                    <select class="form-control" id="ndifusao_cidade" [(ngModel)]="difusao.cidadeId" name="ndifusao_cidade">
                                        <option [value]="null" i18n>Selecione uma Cidade</option>
                                        <option *ngFor="let cidade of cidades" [value]="cidade.id">{{cidade.nome}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-3 col-lg-3">
                                <div class="form-group">
                                    <label i18n for="ndifusao_nome">Nome da Difusão</label>
                                    <input [(ngModel)]="difusao.nome" name="ndifusao_nome" type="text" class="form-control" id="ndifusao_nome" placeholder="Digite o nome" i18n-placeholder>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-3 col-lg-3">
                                <div class="form-group">
                                    <label i18n for="ndifusao_etapa">Etapa da difusao</label>
                                    <select class="form-control" id="ndifusao_etapa" [(ngModel)]="difusao.etapaDifusaoId" name="ndifusao_etapa">
                                        <option [value]="null" i18n>Selecione uma etapa</option>
                                        <option *ngFor="let etapa of etapasDifusao" [value]="etapa.id">{{etapa.nome}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-3 col-lg-3">
                                <div class="form-group">
                                    <label i18n for="ndifusao_f">Fase da difusao</label>
                                    <select class="form-control" id="ndifusao_f" [(ngModel)]="difusao.faseDifusaoId" name="ndifusao_f">
                                        <option [value]="null" i18n>Selecione uma fase</option>
                                        <option *ngFor="let fase of fasesDifusao" [value]="fase.id">{{fase.nome}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-3 col-lg-3">
                                <div class="form-group">
                                    <label i18n for="ndifusao_dtfund">Data de Fundação</label>
                                    <input [(ngModel)]="difusao.dataFundacao" name="ndifusao_dtfund" type="date" class="form-control" id="ndifusao_dtfund" i18n-placeholder>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <button type="submit" class="btn btn-primary float-right" i18n>Salvar</button>
                    </div>
                </div>
            </form>
        </section>
    </div>
</secured>