import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UsuarioAssistenteApostolicoRoutingModule } from './usuario-routing.aa.module';
import { CommonModule } from '@angular/common';
import { UsuariosAssistenteApostolicoComponent } from './usuarios.aa.component';
import { RouterModule } from '@angular/router';
import { UsuarioEditAssistenteApostolicoComponent } from './usuario.edit.aa.component';
import { SecuredModule } from 'src/app/secured/layout/secured.module';
import { UsuarioService } from 'src/app/secured/wop/usuario/service/usuario.service';
import { AutorizacaoService } from 'src/app/secured/wop/autorizacao/service/autorizacao.service';

@NgModule({
    declarations: [ 
        UsuarioEditAssistenteApostolicoComponent,
        UsuariosAssistenteApostolicoComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        UsuarioAssistenteApostolicoRoutingModule,
        FormsModule,
        SecuredModule
    ],
    providers: [
        UsuarioService,
        AutorizacaoService
    ]
})
export class UsuarioAssistenteApostolicoModule {

}