import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment';


@Injectable()
export class ConfirmacaoCadastroService {
    
    constructor(protected http: HttpClient, protected router:Router) {
    }

    public getHeaders() : HttpHeaders {
        return new HttpHeaders({
            'Content-Type' : 'application/json; charset=UTF-8'            
        }); 
    }

    confirmacao(token: string): Promise<any> {
        return this.http.get<any>(environment.confirmacaoCadastro.confirmacaoURL+token).toPromise();
    }

}