<secured>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h3 i18n>Listagem de Usuários</h3>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <form (ngSubmit)="buscarUsuarios()" #usuarioForm="ngForm" novalidate>
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title">Buscar Usuários</h3>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-6 col-lg-6">
                                            <div class="form-group">
                                                <label i18n for="lu_email">E-mail</label>
                                                <input [(ngModel)]="busca.email" name="email" type="email" class="form-control" id="lu_email" placeholder="Digite o e-mail" i18n-placeholder>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-6 col-lg-6">
                                            <div class="form-group">
                                                <label i18n for="lu_username">Usuário</label>
                                                <input [(ngModel)]="busca.username" name="lu_username" type="text" class="form-control" id="lu_username" placeholder="Digite o nome do usuário" i18n-placeholder>
                                            </div>
                                        </div>
                                        <!-- <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label i18n for="lu_autorizacao">Autorização</label>
                                                <select class="form-control" id="lu_autorizacao" [(ngModel)]="busca.autorizacaoId" name="lu_autorizacao">
                                                    <option [value]="null" i18n>Selecione uma Autorizacao</option>
                                                    <option *ngFor="let aut of autorizacoes" [value]="aut.id">{{aut.nomeExibicao}}</option>
                                                </select>
                                            </div>
                                        </div> -->
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <div class="form-check">
                                                    <input [(ngModel)]="busca.isAccountNonExpired" name="isaccountnonexpired" id="isaccountnonexpired" class="form-check-input" type="checkbox" checked>
                                                    <label for="isaccountnonexpired" class="form-check-label" i18n>Conta não expirada</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <div class="form-check">
                                                    <input [(ngModel)]="busca.isAccountNonLocked" name="isaccountnonlocked" id="isaccountnonlocked" class="form-check-input" type="checkbox" checked>
                                                    <label for="isaccountnonlocked" class="form-check-label" i18n>Conta não fechada</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <div class="form-check">
                                                    <input [(ngModel)]="busca.isCredentialsNonExpired" name="iscredentialsnonexpired" id="iscredentialsnonexpired" class="form-check-input" type="checkbox" checked>
                                                    <label for="iscredentialsnonexpired" class="form-check-label" i18n>Credenciais não expiradas</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <div class="form-check">
                                                    <input [(ngModel)]="busca.isEnabled" name="isenabled" id="isenabled" class="form-check-input" type="checkbox" checked>
                                                    <label for="isenabled" class="form-check-label" i18n>Conta liberada</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-footer">
                                    <button type="submit" class="btn btn-primary float-right" i18n>Buscar</button>
                                    <button type="reset" class="btn btn-default float-right" style="margin-right: 0.5rem;" i18n>Limpar</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">

                        <table-component [tituloSessao]="'Usuários'" [cols]="4" [rows]="busca.quantityOfElements" [totalDePaginas]="totalDePaginas" (eventoBotao)="cliqueNoBotaoListener($event)">
                            <tr tableheader>
                                <th style="width: 10px">#</th>
                                <th>Username</th>
                                <th>E-mail</th>
                                <th></th>
                            </tr>
                            <tr tablebody *ngFor="let usuario of usuarios">
                                <td>{{usuario.id}}</td>
                                <td>{{usuario.username}}</td>
                                <td>{{usuario.email}}</td>
                                <td class="text-center">
                                    <button class="btn btn-sm btn-primary" routerLink="../usuario/{{usuario.id}}" title="Editar usuário" alt="Editar usuário"><i class="fas fa-user-edit"></i></button>&nbsp;
                                    <button class="btn btn-sm btn-danger" (click)="deletarUsuario(usuario.id)" title="Deletar usuário" alt="Deletar usuário"><i class="fas fa-trash-alt"></i></button>
                                </td>
                            </tr>
                        </table-component>
                    </div>
                </div>
            </div>
        </section>
    </div>
</secured>