<body class="hold-transition login-page">
    <div class="login-page-item">

        <div class="callout callout-info">
            <div class="login-logo">
                <a i18n><b>Way Of </b>PEACE<b> System </b></a>
            </div>

            <p><i class="fas fa-info"></i>&nbsp;&nbsp;&nbsp;&nbsp;{{mensagemConfirmacao}}</p>

        </div>
    </div>
</body>