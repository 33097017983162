import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';

export type IsActiveMenuItem = {
  path: string;
  isActive: boolean;
};

@Component({
  selector: 'left-sidebar-menu-item-component',
  templateUrl: './left-sidebar-menu-item.component.html',
  styleUrls: ['./style/styles.scss'],
})
export class LeftSidebarMenuItemComponent implements OnInit {
  @Input()
  path: string;
  @Input()
  name: string;
  @ViewChild('menuItem', { static: false })
  menuItem: ElementRef;
  @Output()
  isActiveEventEmmiter = new EventEmitter<IsActiveMenuItem>();

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    const observer = new MutationObserver((mutations) => {
      if (
        mutations[0].target instanceof Element &&
        mutations[0].target.classList.contains('active')
      ) {
        this.isActiveEventEmmiter.emit({
          path: this.path,
          isActive: true,
        });
      } else {
        this.isActiveEventEmmiter.emit({
          path: this.path,
          isActive: false,
        });
      }
    });

    observer.observe(this.menuItem.nativeElement, {
      attributes: true,
      attributeFilter: ['class'],
    });
  }
}
