import { Injectable } from '@angular/core';
import { CriarLinkCadastroRequest } from '../request/criarlinkcadastro.request';
import { AbstractBuilder } from 'src/app/builder/abstract.builder';

@Injectable()
export class CriarLinkCadastroRequestBuilder extends AbstractBuilder<CriarLinkCadastroRequest> {
  reset(): void {
    this.entity = new CriarLinkCadastroRequest(null, null, null);
  }
}
