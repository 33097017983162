import { GrupoDominio } from '../model/grupodominio.model';
import { AbstractBuilder } from './abstract.builder';


export class GrupoDominioBuilder extends AbstractBuilder<GrupoDominio> {

    reset() : void {
        this.entity = new GrupoDominio(null,null,[]);
    }

}