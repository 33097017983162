import { Component, OnInit } from '@angular/core';
import { AutorizacaoService } from './service/autorizacao.service';
import { Autorizacao } from 'src/app/model/autorizacao.model';
import { Router, ActivatedRoute } from '@angular/router';
import { Permissao } from 'src/app/model/permissao.model';
import { PermissaoService } from '../permissao/service/permissao.service';
import { AutorizacaoBuilder } from 'src/app/builder/autorizacao.builder';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';


@Component({
    selector: 'autorizacao-edit',
    templateUrl: './autorizacao.edit.component.html'
})
export class AutorizacaoEditComponent implements OnInit {

    public autorizacoes: Array<Autorizacao>;
    public autorizacao: Autorizacao;
    public autorizacaoId: number;
    public copiarPermissoesRequest: any;
    public permissoes: Array<Permissao>;
    public permissoesNaoVinculadas: Array<Permissao>;
    public busca: any;
    public totalDePaginas: number;
    public permissaoSelecionada: Permissao;
    public autorizacaoBuilder: AutorizacaoBuilder;

    constructor(
        private autorizacaoService: AutorizacaoService,
        private permissaoService: PermissaoService,
        public swtAlert2Service: SwtAlert2Service,
        private route: ActivatedRoute,
        private router: Router
    ) {
        this.autorizacaoBuilder = new AutorizacaoBuilder();
        this.autorizacao = this.autorizacaoBuilder.getInstance();
        this.permissoes = new Array<Permissao>();
        this.permissoesNaoVinculadas = new Array<Permissao>();
        this.busca = {
            autorizacaoId: this.autorizacaoId,
            pageNumber: 0,
            quantityOfElements: 10
        };
        this.permissaoSelecionada = null;
    }

    ngOnInit() {
        this.autorizacaoId = Number(this.route.snapshot.paramMap.get('id'));
        this.copiarPermissoesRequest = {
            autorizacaoId : this.autorizacaoId,
            copiarDeAutorizacaoId: null
        }
        this.carregarAutorizacoes();
        this.carregarAutorizacao(this.autorizacaoId);
        this.carregarPermissoesNaoVinculadas(this.autorizacaoId);
        this.buscarPermissoesPorAutorizacao();
    }

    async carregarAutorizacoes() : Promise<any> {

        try 
        {
            let response = await this.autorizacaoService.findAll();
            this.autorizacoes = response.entity;
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }

    }

    async copiarPermissoes() : Promise<any> {

        if(this.copiarPermissoesRequest.copiarDeAutorizacaoId == null)
        {
            this.swtAlert2Service.warningAlert("Selecione uma autorização para realizar a copia das permissões.")
        }
        else if(confirm("Deseja copiar as permissões da autorização selecionada para a autorização atual?"))
        {
            try 
            {
                let response = await this.autorizacaoService.copiarPermissoes(this.copiarPermissoesRequest);                
                this.carregarPermissoesNaoVinculadas(this.autorizacaoId);
                this.buscarPermissoesPorAutorizacao(); 
                this.swtAlert2Service.successAlert(response.entity);
            } 
            catch (err) 
            {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        }        

    }

    vincularPermissao(): void {

        if (this.permissaoSelecionada != null) {

            let vincularForm = {
                autorizacaoId: this.autorizacaoId,
                permissaoId: this.permissaoSelecionada
            }

            this.permissaoService.vincular(vincularForm).then((response: any) => {

                this.carregarPermissoesNaoVinculadas(this.autorizacaoId);
                this.buscarPermissoesPorAutorizacao();
                this.permissaoSelecionada = null;
                this.swtAlert2Service.successAlert(response.message);
                
            }).catch((err: any) => {
                this.swtAlert2Service.errorAlert(err.error.errors);
            });

        }
        else {
            this.swtAlert2Service.warningAlert('Selecione uma permissão');
        }

    }

    desvincularPermissao(id: number): void {

        if (confirm('Deseja desvincular esta permissão?')) {

            let vincularForm = {
                autorizacaoId: this.autorizacaoId,
                permissaoId: id
            }

            this.permissaoService.desvincular(vincularForm).then((response: any) => {

                this.carregarPermissoesNaoVinculadas(this.autorizacaoId);
                this.buscarPermissoesPorAutorizacao();
                this.permissaoSelecionada = null;
                this.swtAlert2Service.successAlert(response.message);
            }).catch((err: any) => {
                this.swtAlert2Service.errorAlert(err.error.errors);
            });

        }

    }

    carregarPermissoesNaoVinculadas(id: number): void {
        this.permissaoService.buscarPermissoesNaoVinculadasPorAutorizacaoId(id).then((response: any) => {
            this.permissoesNaoVinculadas = response.entity;
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }

    carregarAutorizacao(id: number): void {
        this.autorizacaoService.find(id).then((response: any) => {
            this.autorizacao = response.entity;
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }

    salvar(autorizacao: Autorizacao): void {

        this.autorizacaoService.update(autorizacao.id, autorizacao).then((response: any) => {
            this.swtAlert2Service.successAlert(response.message);
            setTimeout(() => {
                this.router.navigate(['../secured/autorizacoes']);
            }, 2000);

        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }

    buscarPermissoesPorAutorizacao(): void {

        this.busca.autorizacaoId = this.autorizacaoId;

        this.permissaoService.buscarPermissoesPorAutorizacaoId(this.busca).then((response: any) => {
            this.permissoes = response.content;
            this.totalDePaginas = response.totalPages;
        }).catch((err: any) => {
           this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }

    cliqueNoBotaoListener(botao) {
        this.busca.pageNumber = botao.numero - 1;
        this.buscarPermissoesPorAutorizacao();
    }

}