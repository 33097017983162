import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { EstadoService } from './service/estado.service';
import { CommonModule } from '@angular/common';
import { SecuredModule } from '../../layout/secured.module';
import { RouterModule } from '@angular/router';
import { EstadoComponent } from './estado.component';
import { EstadoRoutingModule } from './estado-routing.module';
import { EstadosComponent } from './estados.component';
import { EstadoEditComponent } from './estado.edit.component';

@NgModule({
    declarations: [ 
        EstadoComponent,
        EstadosComponent,
        EstadoEditComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        EstadoRoutingModule,
        FormsModule,
        SecuredModule
    ],
    providers: [
        EstadoService
    ]
})
export class EstadoModule {

}