import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MensagemRlComponent } from './mensagem-rl.component';
import { MensagemRlRoutingModule } from './mensagem-rl-routing.module';
import { SecuredModule } from '../../layout/secured.module';
import { MensagemRlSidebarComponent } from './components/mensagem-rl-sidebar/mensagem-rl-sidebar.component';
import { MensagemRlContentComponent } from './components/mensagem-rl-content/mensagem-rl-content.component';
import { MensagemRlMembroComponent } from './components/mensagem-rl-membro/mensagem-rl-membro.component';
import { MensagemRlSharedStateService } from './service/mensagem-rl-shared-state.service';
import { MessageContentComponentComponent } from './components/message-content-component/message-content-component.component';
import { MensagemRLService } from './service/mensagemrl.service';
import { ImageHandlerService } from './service/image.haddler.service';

@NgModule({
  imports: [CommonModule, MensagemRlRoutingModule, SecuredModule],
  declarations: [
    MensagemRlComponent,
    MensagemRlSidebarComponent,
    MensagemRlContentComponent,
    MensagemRlMembroComponent,
    MessageContentComponentComponent,
  ],
  providers: [
    MensagemRlSharedStateService,
    MensagemRLService,
    ImageHandlerService,
  ],
})
export class MensagemRlModule {}
