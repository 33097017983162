import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Usuario } from 'src/app/model/usuario.model';

@Injectable()
export class RouterManagerService {

    private jaExecutado: boolean;

    constructor(        
        private router: Router
    ){}

    async verificarUsuario(usuarioAtual: Usuario) : Promise<any> {

        if(!this.jaExecutado && window.location.pathname.match('/secured*') != null)
        {
            this.jaExecutado = true;            

            if(usuarioAtual.autorizacoesNome.includes('ROLE_ADMIN'))
            {
                this.router.navigate(['/secured/']);
            }
            else if(usuarioAtual.autorizacoesNome.includes('ROLE_MEMBRO_GRUPO_ORACAO'))
            {
                this.router.navigate(['/secured/local/bemvindo']);
            }
        }

    }

}