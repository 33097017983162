import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { CentroEvangelizacaoBuilder } from 'src/app/builder/cenntroevangelizacao.builder';
export class CentroEvangelizacaoComponent {
    constructor(centroEvangelizacaoService, elementoGrupoDominioService, regionalService, missaoService, difusaoService, irradiacaoService, paisService, estadoService, cidadeService, swtAlert2Service) {
        this.centroEvangelizacaoService = centroEvangelizacaoService;
        this.elementoGrupoDominioService = elementoGrupoDominioService;
        this.regionalService = regionalService;
        this.missaoService = missaoService;
        this.difusaoService = difusaoService;
        this.irradiacaoService = irradiacaoService;
        this.paisService = paisService;
        this.estadoService = estadoService;
        this.cidadeService = cidadeService;
        this.swtAlert2Service = swtAlert2Service;
        this.centroevangelizacaoBuilder = new CentroEvangelizacaoBuilder();
        this.centroEvangelizacao = this.centroevangelizacaoBuilder.getInstance();
        this.fases = new Array();
        this.regionais = new Array();
        this.missoes = new Array();
        this.difusoes = new Array();
        this.irradiacoes = new Array();
        this.paises = new Array();
        this.estados = new Array();
        this.cidades = new Array();
    }
    ngOnInit() {
        this.carregarRegionais();
        this.carregarFasesDoCentreDeEvangelizacao();
        this.carregarPaises();
        this.regionalSelecionadoId = null;
    }
    carregarRegionais() {
        this.regionalService.findAll().then((lista) => {
            this.regionais = lista.entity;
        }).catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }
    carregarMissoes() {
        this.missaoService.porRegional(this.regionalSelecionadoId).then((response) => {
            this.missoes = response.entity;
        }).catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }
    carregarDifusoes() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.difusaoService.porRegional(this.regionalSelecionadoId);
                this.difusoes = response.entity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarIrradiacoes() {
        this.irradiacaoService.porMissao(this.centroEvangelizacao.missaoId).then((response) => {
            this.irradiacoes = response.entity;
        }).catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }
    carregarFasesDoCentreDeEvangelizacao() {
        let buscaFase = {
            grupoNome: 'FASE_CENTRO_EVANGELIZACAO'
        };
        this.elementoGrupoDominioService.buscarPorGrupoDominioNome(buscaFase).then((lista) => {
            this.fases = lista.entity;
        }).catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }
    carregarPaises() {
        this.paisService.findAll().then((lista) => {
            this.paises = lista.entity;
        }).catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }
    carregarEstados() {
        let busca = {
            paisId: this.centroEvangelizacao.endereco.paisId,
            nome: undefined,
            pageNumber: 0,
            quantityOfElements: 1000
        };
        this.estadoService.buscarEstados(busca).then((lista) => {
            this.estados = lista.entity;
        }).catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }
    carregarCidades() {
        let busca = {
            estadoId: this.centroEvangelizacao.endereco.estadoId,
            nome: undefined,
            pageNumber: 0,
            quantityOfElements: 1000
        };
        this.cidadeService.buscarCidades(busca).then((lista) => {
            this.cidades = lista.entity;
        }).catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }
    salvar(centroEvangelizacao) {
        centroEvangelizacao.dataFundacao = new Date(centroEvangelizacao.dataFundacao);
        this.centroEvangelizacaoService.create(centroEvangelizacao).then((response) => {
            this.regionalSelecionadoId = null;
            this.centroEvangelizacao = this.centroevangelizacaoBuilder.getInstance();
            this.swtAlert2Service.successAlert(response.message);
        }).catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }
}
