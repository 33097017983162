import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PessoaBuilder } from 'src/app/builder/pessoa.builder';
import { SalvarMissionarioMissaoAtualBuilder } from 'src/app/builder/salvarmissionariomissaoatual.builder';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { CasaComunitaria } from 'src/app/model/casacomunitaria.model';
import { Pessoa } from 'src/app/model/pessoa.model';
import { SalvarMissionarioMissaoAtual } from 'src/app/model/salvarmissionariomissaoatual.model';
import { Usuario } from 'src/app/model/usuario.model';
import { MissionarioMissaoAtualService } from 'src/app/secured/wop/dadosvocacionais/service/missionariomissaoatual.service';
import { ImagemService } from 'src/app/secured/wop/imagem/service/imagem.service';
import { PessoaService } from 'src/app/secured/wop/pessoa/service/pessoa.service';

@Component({
    selector: 'adicao-membro-casa-comunitaria-component',
    templateUrl: './adicao-membro-casa-comunitaria.component.html'
})
export class AdicaoMembroCasaComunitariaComponent implements OnInit {

    @Input() 
    public usuarioLogado: Usuario;
    @Input()
    public casaComunitaria: CasaComunitaria;

    @Output() 
    public eventEmitter = new EventEmitter();

    public busca: any;
    public totalDePaginas: number;

    public salvarMissionarioMissaoAtual: SalvarMissionarioMissaoAtual;
    public membrosCasaComunitaria: Array<any>;
    public pessoaMembroSelecionada: Pessoa;
    public pessoaBuilder : PessoaBuilder;   
    public membroSelecionado: any; 
    public podeRemoverMissionario: boolean;

    constructor(
        public imagemService: ImagemService,     
        public salvarMissionarioMissaoAtualBuilder: SalvarMissionarioMissaoAtualBuilder,
        public missionarioMissaoAtualService: MissionarioMissaoAtualService,
        public pessoaService: PessoaService,
        public route: ActivatedRoute,
        public router: Router,
        public swtAlert2Service: SwtAlert2Service
    ){
        this.pessoaBuilder = new PessoaBuilder();
        this.pessoaMembroSelecionada = this.pessoaBuilder.getInstance();    
        this.membrosCasaComunitaria = new Array<any>();  
        this.salvarMissionarioMissaoAtual = this.salvarMissionarioMissaoAtualBuilder.getInstance()
        this.busca = {
            casaComunitariaId: null,
            formaVidaId: null,
            pageNumber: 0,
            quantityOfElements : 10
        };
    }

    ngOnInit() {
        this.initializer();
    }

    async initializer() : Promise<any> {
        if(this.casaComunitaria.id != undefined)
        {   
            await this.carregarMembrosCasaComunitaria();
        }        
        this.temAcessoARemocao();
    }

    /*** MEMBROS ***/

    async selecionarMembro(pessoa): Promise<any> {
        this.salvarMissionarioMissaoAtual.pessoaId = pessoa.id;
        this.pessoaMembroSelecionada = pessoa;
        $('#listagem-pessoa-membro').modal('hide');
    }

    async recarregarMembros(comando: string): Promise<any> {
        if(comando == 'recarregar_membros_casa_comunitaria')
        {
            this.membroSelecionado = null;
            this.carregarMembrosCasaComunitaria();
            $('#listagem-pessoa-membro').modal('hide');
        }
        
    }

    async adicionarMembroCasaComunitaria() : Promise<any> {
        try
        {
            this.salvarMissionarioMissaoAtual.casaComunitariaId = this.casaComunitaria.id;

            if(this.salvarMissionarioMissaoAtual.dataChegada != null && this.salvarMissionarioMissaoAtual.pessoaId != null)
            {
                let response = await this.missionarioMissaoAtualService.adicionarMembroNaCasaComunitaria(this.salvarMissionarioMissaoAtual);
                this.swtAlert2Service.successAlert("Missionário adicionado a casa comunitária com sucesso!");
                this.salvarMissionarioMissaoAtual = this.salvarMissionarioMissaoAtualBuilder.getInstance();
                this.pessoaMembroSelecionada = this.pessoaBuilder.getInstance();
                this.carregarMembrosCasaComunitaria();
            }
            else
            {
                if(this.salvarMissionarioMissaoAtual.pessoaId == null)
                {
                    this.swtAlert2Service.errorAlert("O campo pessoa é obrigatório.");
                }
                else if(this.salvarMissionarioMissaoAtual.dataChegada == null)
                {
                    this.swtAlert2Service.errorAlert("O campo data de entrada é obrigatório.");
                }
                
            }       
        }
        catch(err)
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }
    }

    async carregarMembrosCasaComunitaria() : Promise<any> {

        try
        {
            this.busca.casaComunitariaId = this.casaComunitaria.id;
            // this.busca.formaVidaId = 17; // Comunidade de Vida
            let response = await this.pessoaService.buscarMembrosCasaComunitaria(this.busca);
            this.membrosCasaComunitaria = response.entity;

            this.totalDePaginas = response.quantity;

            for(let i = 0; i < this.membrosCasaComunitaria.length ; i++)
            {

                if(this.membrosCasaComunitaria[i].imagemId != null)
                {
                    let blogImage = await this.getPessoaImagem(this.membrosCasaComunitaria[i].imagemId);

                    this.imagemService.createImage(blogImage, this.membrosCasaComunitaria[i]);
                }  
                
            } 

        }
        catch(err)
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }

    }

    async getPessoaImagem(id:number): Promise<any> {

        try
        {
            let response = await this.imagemService.getPessoaPhoto(id);
            return response.body;
        }
        catch(err)
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }

    }

    cliqueNoBotaoListener(botao) {
        this.busca.pageNumber = botao.numero-1;
        this.carregarMembrosCasaComunitaria();
    }

    temAcessoARemocao() : void {

        this.podeRemoverMissionario = false;

        for(let authNome of this.usuarioLogado.autorizacoesNome)
        {
            if(
                authNome.localeCompare("ROLE_ASSISTENTE_MISSIONARIO") == 0
                ||
                authNome.localeCompare("ROLE_ACOMPANHADOR_MISSAO") == 0
            )
            {
                this.podeRemoverMissionario = true;
            }
        }
        
    }

}