import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SecuredModule } from '../../layout/secured.module';
import { SearchCelulaAssistenteFormacaoRoutingModule } from './assistente-formacao-routing.module';
import { SearchCelulaAssistenteFormacaoComponent } from './component/search-celula/search-celula.assistenteformacao.component';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { ParecerCelulaAssistenteFormacaoComponent } from './component/discernimento/parecer.celula.assistenteformacao.component';
import { DashboardInfoBoxComponent } from './component/dashboard-info-box/dashboard-info-box.component';

@NgModule({
  imports: [
    CommonModule,
    SearchCelulaAssistenteFormacaoRoutingModule,
    SecuredModule,
    FormsModule,
    BrowserModule,
  ],
  declarations: [
    SearchCelulaAssistenteFormacaoComponent,
    ParecerCelulaAssistenteFormacaoComponent,
    DashboardInfoBoxComponent,
  ],
  providers: [],
  exports: [DashboardInfoBoxComponent],
})
export class SearchCelulaAssistenteFormacaoModule {}
