import { Component, OnDestroy, OnInit } from '@angular/core';
import { Pessoa } from 'src/app/model/pessoa.model';
import { MissaoConfiguracaoSharedDataService } from '../../service/missao-configuracao-shared-data.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MissaoConfiguracao } from '../../service/missao-config.service';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { ImagemService } from 'src/app/secured/wop/imagem/service/imagem.service';
import { ComissaoDiscernimento } from 'src/app/model/comissaodiscernimento.model';
import { ComissaoDiscernimentoService } from '../../service/comissaodiscernimento.service';

@Component({
  selector: 'app-cadastro-comissao-discernimento',
  templateUrl: './cadastro-comissao-discernimento.component.html',
  styleUrls: ['./cadastro-comissao-discernimento.component.css'],
})
export class CadastroComissaoDiscernimentoComponent
  implements OnInit, OnDestroy
{
  unsubscribe$ = new Subject<null>();
  comissaoDiscernimento: ComissaoDiscernimento = {} as ComissaoDiscernimento;
  membrosComissaoDiscernimento: Array<ComissaoDiscernimento> =
    new Array<ComissaoDiscernimento>();
  pessoaComissaoDiscernimentoSelecionado: Pessoa;
  missaoConfiguracao: MissaoConfiguracao;

  constructor(
    private comissaoDiscernimentoService: ComissaoDiscernimentoService,
    private missaoConfiguracaoSharedDataService: MissaoConfiguracaoSharedDataService,
    private imagemService: ImagemService,
    private swtAlert: SwtAlert2Service
  ) {}

  ngOnInit() {
    this.missaoConfiguracaoSharedDataService.missaoConfiguracao$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (missaoConfiguracao) => {
          if (missaoConfiguracao) {
            this.missaoConfiguracao = missaoConfiguracao;
            this.carregarComissaoDiscernimentoPorMissaoConfiguracaoId(
              missaoConfiguracao.id
            );
          }
        },
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  async selecionarComissaoDiscernimento(pessoa): Promise<any> {
    this.comissaoDiscernimento.pessoaId = pessoa.id;
    this.pessoaComissaoDiscernimentoSelecionado = pessoa;
    $('#listagem-pessoa-comissaodiscernimento').modal('hide');
  }

  async adicionarComissaoDiscernimento(): Promise<any> {
    try {
      this.comissaoDiscernimento.missaoConfiguracaoId =
        this.missaoConfiguracao.id;
      const response = await this.comissaoDiscernimentoService
        .cadastrar(this.comissaoDiscernimento)
        .toPromise();
      this.comissaoDiscernimento = {} as ComissaoDiscernimento;
      this.pessoaComissaoDiscernimentoSelecionado = undefined;
      await this.carregarComissaoDiscernimentoPorMissaoConfiguracaoId(
        this.missaoConfiguracao.id
      );
      this.swtAlert.successAlert(
        'Membro da Comissão de Discernimento cadastrado com sucesso!'
      );
    } catch (err) {
      this.swtAlert.errorAlert(err.error.errors);
    }
  }

  async carregarComissaoDiscernimentoPorMissaoConfiguracaoId(
    id: number
  ): Promise<any> {
    try {
      const response = await this.comissaoDiscernimentoService
        .obterPorMissaoConfiguracaoId(id)
        .toPromise();
      if (response) {
        this.membrosComissaoDiscernimento = response;

        if (
          this.membrosComissaoDiscernimento != null &&
          this.membrosComissaoDiscernimento.length > 0
        ) {
          for (let i = 0; i < this.membrosComissaoDiscernimento.length; i++) {
            if (this.membrosComissaoDiscernimento[i].imagemId != null) {
              let responseImage = await this.imagemService.getPessoaPhoto(
                this.membrosComissaoDiscernimento[i].imagemId
              );
              let blogImage = responseImage.body;
              this.imagemService.createImage(
                blogImage,
                this.membrosComissaoDiscernimento[i]
              );
            }
          }
        }
      }
    } catch (err) {
      this.swtAlert.errorAlert(err.error.errors);
    }
  }

  async removerComissaoDiscernimento(
    comissaoDiscernimentoId: number
  ): Promise<any> {
    if (confirm('Deseja remover o membro da Comissão de Discernimento?')) {
      try {
        await this.comissaoDiscernimentoService
          .remover(comissaoDiscernimentoId)
          .toPromise();
        await this.carregarComissaoDiscernimentoPorMissaoConfiguracaoId(
          this.missaoConfiguracao.id
        );
        this.swtAlert.successAlert(
          'Membro do Comissão de Discernimento removido com sucesso!'
        );
        this.comissaoDiscernimento = {} as ComissaoDiscernimento;
      } catch (err) {
        this.swtAlert.errorAlert(err.error.errors);
      }
    }
  }
}
