import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
export class SaudeEditComponent {
    constructor(usuarioService, pessoaService, saudeService, pessoaBuilder, saudeBuilder, swtAlert2Service, route, router) {
        this.usuarioService = usuarioService;
        this.pessoaService = pessoaService;
        this.saudeService = saudeService;
        this.pessoaBuilder = pessoaBuilder;
        this.saudeBuilder = saudeBuilder;
        this.swtAlert2Service = swtAlert2Service;
        this.route = route;
        this.router = router;
        this.pessoa = this.pessoaBuilder.getInstance();
        this.saude = this.saudeBuilder.getInstance();
    }
    ngOnInit() {
        this.initialization();
    }
    initialization() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.getUsuarioAtual();
            yield this.carregarPessoa();
            yield this.carregarSaude();
        });
    }
    getUsuarioAtual() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.usuarioService.getCurrentUser();
                this.usuarioAtual = response.entity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarPessoa() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.pessoaService.buscaPorUsuarioId(this.usuarioAtual.id);
                this.pessoa = response.entity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarSaude() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.saudeService.porPessoaId(this.pessoa.id);
                if (response.entity != undefined && response.entity != null) {
                    this.saude = response.entity;
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    atualizarSaude() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (this.saude.id == null) {
                    this.saude.pessoaId = this.pessoa.id;
                    let response = yield this.saudeService.create(this.saude);
                    this.saude = response.entity;
                    this.swtAlert2Service.successAlert(response.message);
                }
                else {
                    let response = yield this.saudeService.update(this.saude.id, this.saude);
                    this.saude = response.entity;
                    this.swtAlert2Service.successAlert(response.message);
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
}
