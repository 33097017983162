import { Component, OnInit } from '@angular/core';
import { CentroEvangelizacao } from 'src/app/model/centroevangelizacao.model';
import { EstadoService } from '../estado/service/estado.service';
import { Estado } from 'src/app/model/estado.model';
import { Pais } from 'src/app/model/pais.model';
import { PaisService } from '../pais/service/pais.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ElementoGrupoDominio } from 'src/app/model/elementogrupodominio.model';
import { Missao } from 'src/app/model/missao.model';
import { Regional } from 'src/app/model/regional.model';
import { ElementoGrupoDominioService } from '../grupodominio/service/elementogrupodominio.service';
import { RegionalService } from '../regional/service/regional.service';
import { MissaoService } from '../missao/service/missao.service';
import { Cidade } from 'src/app/model/cidade.model';
import { CidadeService } from '../cidade/service/cidade.service';
import { GrupoDeOracao } from 'src/app/model/grupodeoracao.model';
import { GrupoDeOracaoService } from './grupodeoracao.service';
import { CentroEvangelizacaoService } from '../centroevangelizacao/service/centroevangelizacao.service';
import { GrupoDeOracaoBuilder } from 'src/app/builder/grupodeoracao.builder';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { Difusao } from 'src/app/model/difusao.model';
import { DifusaoService } from '../difusao/service/difusao.service';

@Component({
    selector: 'grupodeoracaoedit',
    templateUrl: './grupodeoracao.component.html'
})
export class GrupoDeOracaoEditComponent implements OnInit {

    public grupoDeOracao: GrupoDeOracao;
    public centrosEvangelizacao: Array<CentroEvangelizacao>;
    public fases : Array<ElementoGrupoDominio>;
    public publicos : Array<ElementoGrupoDominio>;
    public missoes : Array<Missao>;
    public difusoes : Array<Difusao>;
    public regionais: Array<Regional>;
    public paises: Array<Pais>;
    public estados: Array<Estado>;
    public cidades: Array<Cidade>;
    public grupoDeOracaoId: number;
    public centroEvangelizacaoId: number;
    public grupodeoracaoBuilder: GrupoDeOracaoBuilder;

    constructor(
        public grupoDeOracaoService: GrupoDeOracaoService,
        public centroEvangelizacaoService: CentroEvangelizacaoService,
        public elementoGrupoDominioService: ElementoGrupoDominioService,
        public regionalService: RegionalService,
        public missaoService: MissaoService,
        public difusaoService: DifusaoService,
        public paisService: PaisService,
        public estadoService: EstadoService,
        public cidadeService: CidadeService,
        public route: ActivatedRoute,
        public router: Router,
        public swtAlert2Service: SwtAlert2Service
    ) 
    {
        this.grupodeoracaoBuilder = new GrupoDeOracaoBuilder();
        this.grupoDeOracao = this.grupodeoracaoBuilder.getInstance();
        this.centrosEvangelizacao = new Array<CentroEvangelizacao>();
        this.fases = new Array<ElementoGrupoDominio>();
        this.publicos = new Array<ElementoGrupoDominio>();
        this.regionais = new Array<Regional>();
        this.missoes = new Array<Missao>();
        this.difusoes = new Array<Difusao>();
        this.paises = new Array<Pais>();
        this.estados = new Array<Estado>();
        this.cidades = new Array<Cidade>();
    }

    ngOnInit() { 
        this.grupoDeOracaoId = Number(this.route.snapshot.paramMap.get('id'));
        this.carregarFasesDoGrupoDeOracao(); 
        this.carregarPublicosDoGrupoDeOracao();
        this.carregarGrupoDeOracao(this.grupoDeOracaoId);
    }

    async carregarRegionais() : Promise<any> {

        try 
        {
            let response = await this.regionalService.findAll();
            this.regionais = response.entity;
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }

    }

    async carregarMissoes(regionalSelecionadoId : number): Promise<any> {

        try 
        {
            let response = await this.missaoService.porRegional(regionalSelecionadoId);
            this.missoes = response.entity;
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }

    }

    async carregarDifusoes(regionalSelecionadoId : number): Promise<any> {

        try 
        {
            let response = await this.difusaoService.porRegional(regionalSelecionadoId);
            this.difusoes = response.entity;
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }

    }

    async carregarCevPorMissao(missaoId : number): Promise<any> {

        try 
        {
            let response = await this.centroEvangelizacaoService.porMissao(missaoId);
            this.centrosEvangelizacao = response.entity;
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }

    } 

    async carregarCevPorDifusao(difusaoId : number): Promise<any> {

        try 
        {
            let response = await this.centroEvangelizacaoService.porDifusao(difusaoId);
            this.centrosEvangelizacao = response.entity;
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }

    }

    carregarFasesDoGrupoDeOracao() : void {

        let buscaFase = {
            grupoNome : 'FASE_GRUPO_DE_ORACAO'
        }

        this.elementoGrupoDominioService.buscarPorGrupoDominioNome(buscaFase).then( (lista: any) => {
            this.fases = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });

    }

    carregarPublicosDoGrupoDeOracao() : void {

        let buscaPublico = {
            grupoNome : 'PUBLICO_GRUPO_DE_ORACAO'
        }

        this.elementoGrupoDominioService.buscarPorGrupoDominioNome(buscaPublico).then( (lista: any) => {
            this.publicos = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });

    }

    async carregarGrupoDeOracao(id : number): Promise<any> {

        try 
        {
            let response = await this.grupoDeOracaoService.find(id);
            this.grupoDeOracao = response.entity; 

            await this.carregarRegionais();

            if(this.grupoDeOracao.missaoId != null)
            {
                await this.carregarMissoes(this.grupoDeOracao.regionalId);
                await this.carregarCevPorMissao(this.grupoDeOracao.missaoId);
            }

            if(this.grupoDeOracao.difusaoId != null)
            {
                await this.carregarDifusoes(this.grupoDeOracao.regionalId);
                await this.carregarCevPorDifusao(this.grupoDeOracao.difusaoId);
            }

        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }

    }
    
    salvar(grupoDeOracao: GrupoDeOracao) : void {

        //Apenas para atualizar os grupos já salvos que não possuem a entrada inserida
        if(grupoDeOracao.grupoEncerrado != true)
        {
            grupoDeOracao.grupoEncerrado = false;
        }

        this.grupoDeOracaoService.update(grupoDeOracao.id, grupoDeOracao).then( (response:any) => {
            this.swtAlert2Service.successAlert(response.message);
            setTimeout(() => {
                this.router.navigate(['../secured/gruposdeoracao']);
            }, 2000);

        }).catch( (err:any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    } 

}