import { AbstractBuilder } from './abstract.builder';
import { AcessoUsuarioEstrutura } from '../model/acessousuarioestrutura.model';
import { Injectable } from '@angular/core';

@Injectable()
export class AcessoUsuarioEstruturaBuilder extends AbstractBuilder<AcessoUsuarioEstrutura> {

    reset() : void {
        this.entity = new AcessoUsuarioEstrutura(null,null,null,null,null,null,new Array<number>(),new Array<number>(),new Array<number>(),new Array<number>(),new Array<number>(),new Array<number>(),new Array<number>(),new Array<number>(),new Array<number>(),new Array<number>());
    }

}