export class Regional {

    constructor(
        public id: number,
        public nome: string,
        public paisesId: number[],
        public estadosId: number[],
        public missoesId: number[]
    ){}

}