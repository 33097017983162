import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { CasaComunitariaBuilder } from 'src/app/builder/casacomunitaria.builder';
export class CasaComunitariaComponent {
    constructor(casaComunitariaService, regionalService, missaoService, paisService, estadoService, cidadeService, elementoGrupoDominioService, swtAlert2Service) {
        this.casaComunitariaService = casaComunitariaService;
        this.regionalService = regionalService;
        this.missaoService = missaoService;
        this.paisService = paisService;
        this.estadoService = estadoService;
        this.cidadeService = cidadeService;
        this.elementoGrupoDominioService = elementoGrupoDominioService;
        this.swtAlert2Service = swtAlert2Service;
        this.casacomunitariaBuilder = new CasaComunitariaBuilder();
        this.casaComunitaria = this.casacomunitariaBuilder.getInstance();
        this.regionais = new Array();
        this.missoes = new Array();
        this.paises = new Array();
        this.estados = new Array();
        this.cidades = new Array();
        this.statusCasaComunitaria = new Array();
    }
    ngOnInit() {
        this.carregarRegionais();
        this.carregarPaises();
        this.carregarStatusCasaComunitaria();
        this.regionalSelecionadoId = null;
    }
    carregarStatusCasaComunitaria() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let request = {
                    grupoNome: 'CASA_COMUNITARIA_STATUS'
                };
                let response = yield this.elementoGrupoDominioService.buscarPorGrupoDominioNome(request);
                this.statusCasaComunitaria = response.entity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
            return null;
        });
    }
    carregarRegionais() {
        this.regionalService.findAll().then((lista) => {
            this.regionais = lista.entity;
        }).catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }
    carregarMissoes() {
        this.missaoService.porRegional(this.regionalSelecionadoId).then((response) => {
            this.missoes = response.entity;
        }).catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }
    carregarPaises() {
        this.paisService.findAll().then((lista) => {
            this.paises = lista.entity;
        }).catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }
    carregarEstados() {
        let busca = {
            paisId: this.casaComunitaria.endereco.paisId,
            nome: undefined,
            pageNumber: 0,
            quantityOfElements: 1000
        };
        this.estadoService.buscarEstados(busca).then((lista) => {
            this.estados = lista.entity;
        }).catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }
    carregarCidades() {
        let busca = {
            estadoId: this.casaComunitaria.endereco.estadoId,
            nome: undefined,
            pageNumber: 0,
            quantityOfElements: 1000
        };
        this.cidadeService.buscarCidades(busca).then((lista) => {
            this.cidades = lista.entity;
        }).catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }
    salvar(casaComunitaria) {
        casaComunitaria.dataFundacao = new Date(casaComunitaria.dataFundacao);
        this.casaComunitariaService.create(casaComunitaria).then((response) => {
            this.regionalSelecionadoId = null;
            this.casaComunitaria = this.casacomunitariaBuilder.getInstance();
            this.swtAlert2Service.successAlert(response.message);
        }).catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }
}
