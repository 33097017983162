import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { MissaoBuilder } from 'src/app/builder/missao.builder';
export class MissaoEditComponent {
    constructor(irradiacaoService, missaoService, regionalService, elementoGrupoDominioService, paisService, estadoService, cidadeService, swtAlert2Service, route, router) {
        this.irradiacaoService = irradiacaoService;
        this.missaoService = missaoService;
        this.regionalService = regionalService;
        this.elementoGrupoDominioService = elementoGrupoDominioService;
        this.paisService = paisService;
        this.estadoService = estadoService;
        this.cidadeService = cidadeService;
        this.swtAlert2Service = swtAlert2Service;
        this.route = route;
        this.router = router;
        this.missaoBuilder = new MissaoBuilder();
        this.missao = this.missaoBuilder.getInstance();
        this.regionais = new Array();
        this.paisSelecionadoId = null;
        this.estadoSelecionadoId = null;
        this.paises = new Array();
        this.estados = new Array();
        this.cidades = new Array();
        this.fasesMissao = new Array();
        this.statusMissao = new Array();
        this.entities = new Array();
        this.busca = {
            pageNumber: 0,
            quantityOfElements: 10
        };
    }
    ngOnInit() {
        this.missaoId = Number(this.route.snapshot.paramMap.get('id'));
        this.initialize();
    }
    initialize() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.carregarRegionais();
            yield this.carregarFasesMissao();
            yield this.carregarStatusMissao();
            yield this.carregarMissao(this.missaoId);
            this.carregarPaises();
            this.carregarEstados();
            this.carregarCidades();
            this.buscar();
            return null;
        });
    }
    buscar() {
        this.busca = {
            regionalId: null,
            missaoId: this.missaoId,
            paisId: null,
            estadoId: null,
            cidadeId: null,
            nomeIrradiacao: null,
            dataFundacao: Date,
            pageNumber: 0,
            quantityOfElements: 10
        };
        this.irradiacaoService.buscar(this.busca).then((response) => {
            this.entities = response.entity.content;
            this.totalDePaginas = response.entity.totalPages;
        }).catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }
    cliqueNoBotaoListener(botao) {
        this.busca.pageNumber = botao.numero - 1;
        this.buscar();
    }
    deletar(id) {
        if (confirm('Deseja deletar esta irradiacao?')) {
            this.irradiacaoService.delete(id)
                .then((response) => {
                this.swtAlert2Service.successAlert(response.message);
                this.buscar();
            })
                .catch((err) => {
                this.swtAlert2Service.errorAlert(err.error.errors);
            });
        }
    }
    carregarMissao(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.missaoService.find(id);
                this.missao = response.entity;
                this.paisSelecionadoId = response.entity.cidadeEstadoPaisId;
                this.carregarEstados();
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
            return null;
        });
    }
    carregarRegionais() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.regionalService.findAll();
                this.regionais = response.entity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
            return null;
        });
    }
    carregarFasesMissao() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let buscaFase = {
                    grupoNome: 'FASE_MISSAO'
                };
                let response = yield this.elementoGrupoDominioService.buscarPorGrupoDominioNome(buscaFase);
                this.fasesMissao = response.entity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
            return null;
        });
    }
    carregarStatusMissao() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let request = {
                    grupoNome: 'MISSAO_STATUS'
                };
                let response = yield this.elementoGrupoDominioService.buscarPorGrupoDominioNome(request);
                this.statusMissao = response.entity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
            return null;
        });
    }
    carregarPaises() {
        this.paisService.findAll().then((lista) => {
            this.paises = lista.entity;
            this.carregarMissao(this.missaoId);
        }).catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }
    carregarEstados() {
        let busca = {
            paisId: this.paisSelecionadoId,
            nome: undefined,
            pageNumber: 0,
            quantityOfElements: 1000
        };
        this.estadoService.buscarEstados(busca).then((lista) => {
            this.estados = lista.entity;
            this.carregarCidades();
        }).catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }
    carregarCidades() {
        let busca = {
            estadoId: this.estadoSelecionadoId,
            nome: undefined,
            pageNumber: 0,
            quantityOfElements: 1000
        };
        this.cidadeService.buscarCidades(busca).then((lista) => {
            this.cidades = lista.entity;
        }).catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }
    salvar(missao) {
        missao.dataFundacao = new Date(missao.dataFundacao);
        this.missaoService.update(missao.id, missao).then((response) => {
            this.missao = this.missaoBuilder.getInstance();
            this.paisSelecionadoId = null;
            this.estadoSelecionadoId = null;
            this.swtAlert2Service.successAlert(response.message);
            setTimeout(() => {
                this.router.navigate(['../secured/missoes']);
            }, 2000);
        }).catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }
}
