import { Injectable } from '@angular/core';
import { Visto } from '../model/visto.model';
import { AbstractBuilder } from './abstract.builder';

@Injectable()
export class VistoBuilder extends AbstractBuilder<Visto> {

    reset() : void {
        this.entity = new Visto(null,null,null,null,null,null,null,null,null);
    }

}