import { GenericService } from 'src/app/core/services/generic.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from "@angular/core";
import { Escolaridade } from 'src/app/model/escolaridade.model';

@Injectable()
export class EscolaridadeService extends GenericService<Escolaridade> {    
    
    constructor(protected http: HttpClient, protected router:Router) {
        super(http,router);
        this.setEntityType('r/escolaridade');
    }

    async findByAptidaoId(id: number): Promise<any> {
        return this.http.get<Escolaridade>(this.apiURL+'/poraptidao/'+id, { 'headers' : this.getHeaders() }).toPromise();
    }
    
} 