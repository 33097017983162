import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Pessoa } from 'src/app/model/pessoa.model';
import { Usuario } from 'src/app/model/usuario.model';

@Injectable()
export class LoggedInUserSharedDataService {
  loggedInUser$ = new BehaviorSubject<Usuario>(null);
  loggedInPerson$ = new BehaviorSubject<Pessoa>(null);

  constructor() {}

  updateLoggedInUser(user: Usuario) {
    this.loggedInUser$.next(user);
    this.loggedInUser$.complete();
  }

  updateLoggedInPerson(person: Pessoa) {
    this.loggedInPerson$.next(person);
  }
}
