import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SecuredModule } from '../../layout/secured.module';
import { RouterModule } from '@angular/router';
import { ChartsModule } from 'ng2-charts';
import { GruposDeOracaoComponent } from './gruposdeoracao.component';
import { GrupoDeOracaoComponent } from './grupodeoracao.component';
import { GrupoDeOracaoEditComponent } from './grupodeoracao.edit.component';
import { GrupoDeOracaoService } from './grupodeoracao.service';
import { GrupoDeOracaoRoutingModule } from './grupodeoracao-routing.module';

@NgModule({
    declarations: [ 
        GrupoDeOracaoComponent,
        GruposDeOracaoComponent,
        GrupoDeOracaoEditComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        GrupoDeOracaoRoutingModule,
        FormsModule,
        SecuredModule,
        ChartsModule
    ],
    providers: [
        GrupoDeOracaoService
    ]
})
export class GrupoDeOracaoModule {

}