import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProjetoComponent } from './projeto.component';
import { ProjetosComponent } from './projetos.component';
import { ProjetoEditComponent } from './projeto.edit.component';


const projetoRoutes: Routes = [
    {
        path: 'secured/projeto',
        component: ProjetoComponent
    },
    {
        path: 'secured/projetos',
        component: ProjetosComponent
    },
    {
        path: 'secured/projeto/:id',
        component: ProjetoEditComponent
    }
]

@NgModule({
    imports: [
        RouterModule.forChild(projetoRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class ProjetoRoutingModule {
    
}