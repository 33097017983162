import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SecuredModule } from '../../layout/secured.module';
import { RouterModule } from '@angular/router';
import { CidadeComponent } from './cidade.component';
import { CidadeRoutingModule } from './cidade-routing.module';
import { CidadeService } from './service/cidade.service';
import { CidadesComponent } from './cidades.component';
import { CidadeEditComponent } from './cidade.edit.component';

@NgModule({
    declarations: [ 
        CidadeComponent,
        CidadesComponent,
        CidadeEditComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        CidadeRoutingModule,
        FormsModule,
        SecuredModule
    ],
    providers: [
        CidadeService
    ]
})
export class CidadeModule {

}