import { GenericService } from 'src/app/core/services/generic.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from "@angular/core";
import { Trabalho } from 'src/app/model/trabalho.model';
import { AceiteTermo } from 'src/app/model/aceitetermo.model';

@Injectable()
export class AceiteTermoService extends GenericService<AceiteTermo> {    
    
    constructor(protected http: HttpClient, protected router:Router) {
        super(http,router);
        this.setEntityType('r/aceitetermo');
    }

    async aceitarTermoVigente(): Promise<any> {
        return this.http.get<Trabalho>(this.apiURL+'/aceitartermovigente', { 'headers' : this.getHeaders() }).toPromise();
    }

    async usuarioAceitouTermo(): Promise<any> {
        return this.http.get<Trabalho>(this.apiURL+'/usuarioaceitoutermovigente', { 'headers' : this.getHeaders() }).toPromise();
    }
    
} 