import { Component, Input, OnInit } from '@angular/core';
import { IsActiveMenuItem } from '../left-sidebar-menu-item/left-sidebar-menu-item.component';

@Component({
  selector: 'left-sidebar-menu-component',
  templateUrl: './left-sidebar-menu.component.html',
  styleUrls: ['./style/styles.scss'],
})
export class LeftSidebarMenuComponent implements OnInit {
  @Input()
  icon: string;
  @Input()
  name: String;
  @Input()
  isMenuActive?: boolean = false;

  ngOnInit(): void {}
}
