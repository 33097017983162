import { GenericService } from 'src/app/core/services/generic.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from "@angular/core";
import { Filho } from 'src/app/model/filho.model';


@Injectable()
export class FilhoService extends GenericService<Filho> {
    
    constructor(protected http: HttpClient, protected router:Router) {
        super(http,router);
        this.setEntityType('filho');
    }

    porPaiId(paiId: number) : Promise<any> {
        return this.http.get<any>(this.apiURL+'/porpai/'+paiId, { 'headers' : this.getHeaders() }).toPromise();
    }

    porMaeId(maeId: number) : Promise<any> {
        return this.http.get<any>(this.apiURL+'/pormae/'+maeId, { 'headers' : this.getHeaders() }).toPromise();
    }

    porFilhoId(filhoId: number) : Promise<any> {
        return this.http.get<any>(this.apiURL+'/porfilho/'+filhoId, { 'headers' : this.getHeaders() }).toPromise();
    }

    
 
}