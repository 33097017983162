import { Injectable } from '@angular/core';
import { Apostolado } from '../model/apostolado.model';
import { AbstractBuilder } from './abstract.builder';

@Injectable()
export class ApostoladoBuilder extends AbstractBuilder<Apostolado> {

    reset() : void {
        this.entity = new Apostolado(null,null,null,true,null,null);
    }

}