/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./footer.component";
var styles_FooterComponent = [];
var RenderType_FooterComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FooterComponent, data: {} });
export { RenderType_FooterComponent as RenderType_FooterComponent };
export function View_FooterComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "footer", [["class", "main-footer"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 4, "strong", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Copyright \u00A9 2022 "])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "a", [["href", "https://www.comshalom.org"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Way Of Peace"])), (_l()(), i0.ɵted(-1, null, ["."])), (_l()(), i0.ɵeld(6, 0, null, null, 1, "a", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["All rights reserved."])), (_l()(), i0.ɵeld(8, 0, null, null, 3, "div", [["class", "float-right d-none d-sm-inline-block"]], null, null, null, null, null)), (_l()(), i0.ɵeld(9, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Version"])), (_l()(), i0.ɵted(-1, null, [" 1.1.1 "]))], null, null); }
export function View_FooterComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "footer-comp", [], null, null, null, View_FooterComponent_0, RenderType_FooterComponent)), i0.ɵdid(1, 49152, null, 0, i1.FooterComponent, [], null, null)], null, null); }
var FooterComponentNgFactory = i0.ɵccf("footer-comp", i1.FooterComponent, View_FooterComponent_Host_0, {}, {}, []);
export { FooterComponentNgFactory as FooterComponentNgFactory };
