export class Cidade {

    constructor(
        public id: number,
        public paisId: number,
        public paisNome: string,
        public estadoId: number,
        public estadoNome: string,
        public nome: string        
    ){}

}