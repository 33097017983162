import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SecuredModule } from '../../layout/secured.module';
import { RouterModule } from '@angular/router';
import { GrupoDominioComponent } from './grupodominio.component';
import { GrupoDominioRoutingModule } from './grupodominio-routing.module';
import { GrupoDominioService } from './service/grupodominio.service';
import { GruposDominioComponent } from './gruposdominio.component';
import { GrupoDominioEditComponent } from './grupodominio.edit.component';
import { ElementoGrupoDominioService } from './service/elementogrupodominio.service';

@NgModule({
    declarations: [ 
        GrupoDominioComponent,
        GruposDominioComponent,
        GrupoDominioEditComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        GrupoDominioRoutingModule,
        FormsModule,
        SecuredModule
    ],
    providers: [
        GrupoDominioService,
        ElementoGrupoDominioService
    ]
})
export class GrupoDominioModule {

}