<secured>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h3 i18n>Listagem de Acessos Usuário/Estrutura</h3>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <form (ngSubmit)="buscarAcessos()" #acessoForm="ngForm" novalidate>
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title">Buscar Acessos</h3>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-6 col-lg-6">
                                            <div class="form-group">
                                                <label i18n for="laue_nome">Nome</label>
                                                <input [(ngModel)]="busca.nome" name="laue_nome" type="text" class="form-control" id="laue_nome" placeholder="Digite o nome" i18n-placeholder>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label i18n for="laue_username">Nome de usuário</label>
                                                <input [(ngModel)]="busca.username" name="laue_username" type="text" class="form-control" id="laue_username" placeholder="Digite o nome de usuário" i18n-placeholder>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label i18n for="naue_autorizacao">Autorização</label>
                                                <select class="form-control" id="laue_autorizacao" [(ngModel)]="busca.autorizacaoId" name="laue_autorizacao">
                                                    <option [value]="null" i18n>Selecione uma autorização</option>
                                                    <option *ngFor="let autorizacao of autorizacoes" [value]="autorizacao.id">{{autorizacao.nomeExibicao}}</option>
                                                </select>
                                            </div>
                                        </div>                                       
                                    </div>
                                </div>
                                <div class="card-footer">
                                    <button type="submit" class="btn btn-primary float-right" i18n>Buscar</button>
                                    <button type="reset" class="btn btn-default float-right" style="margin-right: 0.5rem;" i18n>Limpar</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <table-component [tituloSessao]="'Acessos'" [cols]="5" [rows]="busca.quantityOfElements" [totalDePaginas]="totalDePaginas" (eventoBotao)="cliqueNoBotaoListener($event)">
                            <tr tableheader>
                                <th style="width: 10px">#</th>
                                <th>Nome</th>
                                <th>Usuário</th>
                                <th>Autorizacao</th>
                                <th></th>
                            </tr>
                            <tr tablebody *ngFor="let acesso of acessos">
                                <td>{{acesso.id}}</td>
                                <td>{{acesso.pessoaNome}}</td>
                                <td>{{acesso.usuarioUsername}}</td>
                                <td>{{acesso.autorizacaoNomeExibicao}}</td>
                                <td class="text-center">
                                    <button class="btn btn-sm btn-primary" routerLink="../acessousuarioestrutura/{{acesso.id}}" title="Editar acesso" alt="Editar acesso"><i class="fas fa-edit"></i></button>
                                </td>
                            </tr>
                        </table-component>
                    </div>
                </div>
            </div>
        </section>
    </div>
</secured>