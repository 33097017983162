export class Visto {

    constructor(
        public id: number,
        public pessoaId: number,
        public pessoaNome: string,
        public paisId: number,
        public paisNome: string,
        public tipoVistoId: number,
        public tipoVistoNome: string,
        public tipoVistoNaoListado: string,
        public dataValidade: Date
    ){}

}