import { Endereco } from './endereco.model';

export class CentroEvangelizacao {

    constructor(
        public id: number,
        public nome: string,
        public faseCentroEvangelizacaoId: number,
        public dataFundacao: Date,
        public missaoId: number,
        public difusaoId: number,
        public irradiacaoId: number,
        public endereco: Endereco
    ){}

}