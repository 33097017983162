import { AbstractBuilder } from './abstract.builder';
import { AceiteTermo } from '../model/aceitetermo.model';
import { Injectable } from '@angular/core';

@Injectable()
export class AceiteTermoBuilder extends AbstractBuilder<AceiteTermo> {

    reset() : void {
        this.entity = new AceiteTermo(null,null,null,null,null);
    }

}