export class VinculoDifusao {

    constructor(
        public id: number,
        public pessoaId: number,
        public difusaoOrigemId: number,
        public difusaoOrigemNome: string,
        public difusaoAtualId: number,
        public difusaoAtualNome: string
    )
    {}

}