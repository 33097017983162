import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/login/service/login.service';
import { Usuario } from 'src/app/model/usuario.model';
import { PessoaService } from '../../wop/pessoa/service/pessoa.service';
import { UsuarioService } from '../../wop/usuario/service/usuario.service';
import { LoggedInUserSharedDataService } from './service/logged-in-user-shared-data.service';

@Component({
  selector: 'header-comp',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
  private loggedInUserObject?: Usuario;

  constructor(
    private loginService: LoginService,
    private usuarioService: UsuarioService,
    private pessoaService: PessoaService,
    private loggedInUserSharedDataService: LoggedInUserSharedDataService
  ) {}

  ngOnInit(): void {
    this.initialize();
  }

  async initialize() {
    await this.loadLoggedInUser();
    if (this.loggedInUserObject) {
      await this.loadLoggedInPerson();
    }
  }

  async loadLoggedInUser(): Promise<any> {
    try {
      const response = await this.usuarioService.getCurrentUser();
      if (response.entity) {
        this.loggedInUserObject = response.entity;
        this.loggedInUserSharedDataService.updateLoggedInUser(
          this.loggedInUserObject
        );
      }
    } catch (error) {
      console.log(error);
    }
  }

  async loadLoggedInPerson() {
    try {
      const response = await this.pessoaService.buscaPorUsuarioId(
        this.loggedInUserObject.id
      );
      if (response.entity) {
        this.loggedInUserSharedDataService.updateLoggedInPerson(
          response.entity
        );
      }
    } catch (error) {
      console.log(error);
    }
  }

  logout(): void {
    this.loggedInUserSharedDataService.updateLoggedInUser(null);
    this.loggedInUserSharedDataService.updateLoggedInPerson(null);
    this.loginService.fazerLogout();
  }
}
