/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./mensagem-rl-sidebar.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../mensagem-rl-membro/mensagem-rl-membro.component.ngfactory";
import * as i3 from "../mensagem-rl-membro/mensagem-rl-membro.component";
import * as i4 from "../../service/mensagem-rl-shared-state.service";
import * as i5 from "@angular/common";
import * as i6 from "./mensagem-rl-sidebar.component";
import * as i7 from "../../service/mensagemrl.service";
import * as i8 from "../../service/image.haddler.service";
var styles_MensagemRlSidebarComponent = [i0.styles];
var RenderType_MensagemRlSidebarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MensagemRlSidebarComponent, data: {} });
export { RenderType_MensagemRlSidebarComponent as RenderType_MensagemRlSidebarComponent };
function View_MensagemRlSidebarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-mensagem-rl-membro", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectMemberSummary(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MensagemRlMembroComponent_0, i2.RenderType_MensagemRlMembroComponent)), i1.ɵdid(2, 245760, null, 0, i3.MensagemRlMembroComponent, [i4.MensagemRlSharedStateService], { imageSrc: [0, "imageSrc"], memberName: [1, "memberName"], lastMessage: [2, "lastMessage"], notReadedMessageCounter: [3, "notReadedMessageCounter"], memberPessoaId: [4, "memberPessoaId"] }, null)], function (_ck, _v) { var currVal_0 = (!_v.context.$implicit.thumbnail ? "assets/images/ppl.png" : _v.context.$implicit.thumbnail); var currVal_1 = _v.context.$implicit.pessoaNome; var currVal_2 = _v.context.$implicit.ultimaMensagem; var currVal_3 = _v.context.$implicit.quantidadeMensagensNaoLidas; var currVal_4 = _v.context.$implicit.pessoaId; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_MensagemRlSidebarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 25, "aside", [["id", "members-messages-sidebar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["id", "search"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "input-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "input", [["class", "form-control"], ["placeholder", "Digite o nome da pessoa..."], ["type", "text"]], null, [[null, "input"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (_co.filterByInput($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "span", [["class", "input-group-append"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "button", [["class", "btn btn-info"], ["type", "button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [["class", "fas fa-search"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 15, "div", [["id", "filter"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 4, "button", [["class", "btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setFilterButtonState("Todas") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i5.ɵNgClassImpl, i5.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(10, 278528, null, 0, i5.NgClass, [i5.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(11, { "btn-info": 0, "btn-default": 1 }), (_l()(), i1.ɵted(-1, null, [" Todas "])), (_l()(), i1.ɵeld(13, 0, null, null, 4, "button", [["class", "btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setFilterButtonState("N\u00E3o Lidas") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i5.ɵNgClassImpl, i5.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(15, 278528, null, 0, i5.NgClass, [i5.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(16, { "btn-info": 0, "btn-default": 1 }), (_l()(), i1.ɵted(-1, null, [" N\u00E3o Lidas "])), (_l()(), i1.ɵeld(18, 0, null, null, 4, "button", [["class", "btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setFilterButtonState("Lidas") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i5.ɵNgClassImpl, i5.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(20, 278528, null, 0, i5.NgClass, [i5.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(21, { "btn-info": 0, "btn-default": 1 }), (_l()(), i1.ɵted(-1, null, [" Lidas "])), (_l()(), i1.ɵeld(23, 0, null, null, 2, "ul", [["id", "members-list"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MensagemRlSidebarComponent_1)), i1.ɵdid(25, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "btn"; var currVal_1 = _ck(_v, 11, 0, (_co.selectedFilterButton === "Todas"), (_co.selectedFilterButton !== "Todas")); _ck(_v, 10, 0, currVal_0, currVal_1); var currVal_2 = "btn"; var currVal_3 = _ck(_v, 16, 0, (_co.selectedFilterButton === "N\u00E3o Lidas"), (_co.selectedFilterButton !== "N\u00E3o Lidas")); _ck(_v, 15, 0, currVal_2, currVal_3); var currVal_4 = "btn"; var currVal_5 = _ck(_v, 21, 0, (_co.selectedFilterButton === "Lidas"), (_co.selectedFilterButton !== "Lidas")); _ck(_v, 20, 0, currVal_4, currVal_5); var currVal_6 = _co.displayableSummaryList; _ck(_v, 25, 0, currVal_6); }, null); }
export function View_MensagemRlSidebarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-mensagem-rl-sidebar", [], null, null, null, View_MensagemRlSidebarComponent_0, RenderType_MensagemRlSidebarComponent)), i1.ɵdid(1, 245760, null, 0, i6.MensagemRlSidebarComponent, [i4.MensagemRlSharedStateService, i7.MensagemRLService, i8.ImageHandlerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MensagemRlSidebarComponentNgFactory = i1.ɵccf("app-mensagem-rl-sidebar", i6.MensagemRlSidebarComponent, View_MensagemRlSidebarComponent_Host_0, {}, {}, []);
export { MensagemRlSidebarComponentNgFactory as MensagemRlSidebarComponentNgFactory };
