import { Component, OnInit } from '@angular/core';
import { RegionalService } from './service/regional.service';
import { Regional } from 'src/app/model/regional.model';
import { Alerta } from 'src/app/model/alerta.model';
import { RegionalBuilder } from 'src/app/builder/regional.builder';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';

@Component({
    selector: 'regional',
    templateUrl: './regional.component.html'
})
export class RegionalComponent implements OnInit {

    public regional: Regional;
    public regionalBuilder: RegionalBuilder;

    constructor(
        public regionalService: RegionalService,
        public swtAlert2Service: SwtAlert2Service
    ) 
    {
        this.regionalBuilder = new RegionalBuilder();
        //this.regional = new Regional(null,null,[],[],[]);
        this.regional = this.regionalBuilder.getInstance();
    }

    ngOnInit() {        
    }

    salvar(regional: Regional) : void {

        this.regionalService.create(regional).then( (response:any) => {
            this.regional = new Regional(null,null,[],[],[]);
            this.swtAlert2Service.successAlert(response.message);

        }).catch( (err:any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

}