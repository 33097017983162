export class MembroComunidade {
  constructor(
    public id: number,
    public tipo: number, //1 - Casa Comunitária | 2 - Celula
    public casaCelulaId: number,
    public pessoaId: number,
    public pessoaNome: string,
    public pessoaImagemId: number,
    public thumbnail: string,
    public dataEntrada: Date,
    public dataSaida: Date,
    public motivoId: number,
    public descricaoMotivo: string,
    public nivelFormativoId: number,
    public nivelFormativoNome: string,
    public editando: boolean
  ) {}
}
