import { Component, Input, OnInit } from '@angular/core';
import { DadosDetalhamentoMissionarioMissaoAtualBuilder } from 'src/app/builder/dadosdetalhamentomissionariomissaoatual.builder';
import { SalvarMissionarioMissaoApostoladoAtualBuilder } from 'src/app/builder/salvarmissionariomissaoapostoladoatual.builder';
import { SalvarMissionarioMissaoAtualBuilder } from 'src/app/builder/salvarmissionariomissaoatual.builder';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { Apostolado } from 'src/app/model/apostolado.model';
import { CasaComunitaria } from 'src/app/model/casacomunitaria.model';
import { DadosDetalhamentoMissionarioMissaoApostoladoAtual } from 'src/app/model/dadosdetalhamentomissionariomissaoapostoladoatual.model';
import { DadosDetalhamentoMissionarioMissaoAtual } from 'src/app/model/dadosdetalhamentomissionariomissaoatual.model';
import { ElementoGrupoDominio } from 'src/app/model/elementogrupodominio.model';
import { Missao } from 'src/app/model/missao.model';
import { SalvarMissionarioMissaoApostoladoAtual } from 'src/app/model/salvarmissionariomissaoapostoladoatual.model';
import { SalvarMissionarioMissaoAtual } from 'src/app/model/salvarmissionariomissaoatual.model';
import { CasaComunitariaService } from '../../casacomunitaria/service/casacomunitaria.service';
import { ElementoGrupoDominioService } from '../../grupodominio/service/elementogrupodominio.service';
import { MissaoService } from '../../missao/service/missao.service';
import { ApostoladoService } from '../service/apostolado.service';
import { MissionarioMissaoAtualService } from '../service/missionariomissaoatual.service';


@Component({
    selector: 'missionario-missao-atual',
    templateUrl: './missionariomissaoatual.component.html'
})
export class MissionarioMissaoAtualComponent implements OnInit {
    
    @Input() 
    public pessoaId: number;
    public salvarMissionarioMissaoAtual: SalvarMissionarioMissaoAtual;
    public dadosDetalhamentoMissionarioMissaoAtual: DadosDetalhamentoMissionarioMissaoAtual;
    public missoes: Array<Missao>;
    public casasComunitarias: Array<CasaComunitaria>;
    public salvarMissionarioMissaoApostoladoAtual: SalvarMissionarioMissaoApostoladoAtual;
    public listaDetalhamentoMissionarioMissaoApostoladoAtual: Array<DadosDetalhamentoMissionarioMissaoApostoladoAtual>;
    public apostolados: Array<Apostolado>;
    public apostoladosSelecionados: Array<Apostolado>;
    public tiposApostolado: Array<ElementoGrupoDominio>;
    public tipoApostoladoSelecionadoId: number;

    constructor(
        public missaoService: MissaoService,
        public casaComunitariaService: CasaComunitariaService,
        public apostoladoService: ApostoladoService,
        public elementoGrupoDominioService: ElementoGrupoDominioService,
        public missionarioMissaoAtualService: MissionarioMissaoAtualService,
        public salvarMissionarioMissaoAtualBuilder: SalvarMissionarioMissaoAtualBuilder,
        public dadosDetalhementoMissionarioMissaoAtualBuilder: DadosDetalhamentoMissionarioMissaoAtualBuilder,
        public missionarioMissaoApostoladoAtualBuilder: SalvarMissionarioMissaoApostoladoAtualBuilder,
        public swtAlert2Service: SwtAlert2Service      
    ) 
    {
        this.tipoApostoladoSelecionadoId = null;
        this.salvarMissionarioMissaoAtual = this.salvarMissionarioMissaoAtualBuilder.getInstance();
        this.dadosDetalhamentoMissionarioMissaoAtual = this.dadosDetalhementoMissionarioMissaoAtualBuilder.getInstance();
        this.salvarMissionarioMissaoApostoladoAtual = this.missionarioMissaoApostoladoAtualBuilder.getInstance();
        this.listaDetalhamentoMissionarioMissaoApostoladoAtual = new Array<DadosDetalhamentoMissionarioMissaoApostoladoAtual>();
        this.apostolados = new Array<Apostolado>();
    }
    ngOnInit() {      
        this.initialization();
    }
    async initialization() : Promise<any> {
        this.carregarMissoes(); 
        this.carregarApostolados();
        this.carregarTipoApostolado();
        this.carregarDetalhamentoDoUsuarioLogado();   
    }

    async carregarMissoes() : Promise<any> 
    {
        try 
        {
            let response = await this.missaoService.getAllAnyStatus();
            this.missoes = response.entity;
            this.missoes = this.missoes.sort((a, b) => a.nome.localeCompare(b.nome, 'fi'));
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors)
        }
    }
    async carregarCasasComunitarias(missaoId: number) : Promise<any> 
    {
        try 
        {
            let response = await this.casaComunitariaService.porMissao(missaoId);
            this.casasComunitarias = response.entity;
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors)
        }
    }

    async salvar(salvarMissionarioMissaoAtual: SalvarMissionarioMissaoAtual): Promise<any> {
        
        try
        {
            this.salvarMissionarioMissaoAtual.pessoaId = this.pessoaId;
            let response = await this.missionarioMissaoAtualService.create(salvarMissionarioMissaoAtual);   
            this.dadosDetalhamentoMissionarioMissaoAtual = response;         
            this.swtAlert2Service.successAlert("Missionário vinculado a missão informada com sucesso!");            
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async atualizar(atualizarMissionarioMissaoAtual: DadosDetalhamentoMissionarioMissaoAtual): Promise<any> {
        
        try
        {
            let response = await this.missionarioMissaoAtualService.update(atualizarMissionarioMissaoAtual.id, atualizarMissionarioMissaoAtual);            
            this.swtAlert2Service.successAlert("Atualização realizada com sucesso!");            
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async salvarApostolado(salvarMissionarioMissaoApostoladoAtual: SalvarMissionarioMissaoApostoladoAtual): Promise<any> {
        
        try
        {
            let response = await this.missionarioMissaoAtualService.salvarApostolado(salvarMissionarioMissaoApostoladoAtual);            
            let missionarioMissaoAtualId = this.salvarMissionarioMissaoApostoladoAtual.missionarioMissaoAtualId;
            this.salvarMissionarioMissaoApostoladoAtual = this.missionarioMissaoApostoladoAtualBuilder.getInstance();
            this.configModalApostoladoAtual(missionarioMissaoAtualId);
            this.swtAlert2Service.successAlert("Apostolado salvo com sucesso!");            
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }

    }

    async atualizarApostolado(atualizarMissionarioMissaoApostoladoAtual: DadosDetalhamentoMissionarioMissaoApostoladoAtual): Promise<any> {
        
        try
        {
            let response = await this.missionarioMissaoAtualService.atualizarApostolado(atualizarMissionarioMissaoApostoladoAtual); 
            this.carregarMissionarioMissaoApostolado(this.dadosDetalhamentoMissionarioMissaoAtual.id);        
            this.swtAlert2Service.successAlert("Apostolado atualizado com sucesso!");            
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }
        
    }

    async deletarMissionarioMissaoApostoladoAtual(id: number) : Promise<any> {

        if(confirm("Deseja deletar esse registro de apostolado?"))
        {
            try
            {
                let response = await this.missionarioMissaoAtualService.deletarApostolado(id); 
                this.carregarMissionarioMissaoApostolado(this.dadosDetalhamentoMissionarioMissaoAtual.id);        
                this.swtAlert2Service.successAlert("Apostolado deletado com sucesso!");            
            }
            catch(e)
            {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        }        

    }

    async carregarDetalhamentoDoUsuarioLogado() : Promise<any> 
    {
        try 
        {
            let response = await this.missionarioMissaoAtualService.detalhamentoPorUsuarioLogado();
            this.dadosDetalhamentoMissionarioMissaoAtual = response;            
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors)
        }
    }

    async configModalApostoladoAtual(missionarioMissaoAtualId: number) : Promise<any> {
        this.salvarMissionarioMissaoApostoladoAtual.missionarioMissaoAtualId = missionarioMissaoAtualId;
        this.carregarMissionarioMissaoApostolado(missionarioMissaoAtualId);
    }

    async carregarMissionarioMissaoApostolado(missionarioMissaoAtualId: number) : Promise<any> 
    {
        try 
        {
            let response = await this.missionarioMissaoAtualService.listarApostoladosPorMissionarioMissaoAtualId(missionarioMissaoAtualId);
            if(response != undefined && response != null)
            {
                this.listaDetalhamentoMissionarioMissaoApostoladoAtual = response;                
            }
            
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors)
        }
    }

    async carregarTipoApostolado() : Promise<any> 
    {
        let request = {
            grupoNome : 'TIPO_APOSTOLADO'
        }
        try 
        {
            let response = await this.elementoGrupoDominioService.buscarPorGrupoDominioNome(request);
            this.tiposApostolado = response.entity;
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors)
        }
    }

    async carregarApostolados() : Promise<any> 
    {
        let request = {
            ativo : null
        }
        try 
        {
            let response = await this.apostoladoService.pesquisar(request);
            this.apostolados = response.entity;
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors)
        }
    }

    async carregarApostoladosPorTipo(tipoApostoladoId: number) : Promise<any> 
    {
        this.apostoladosSelecionados = this.apostolados.filter(a => a.tipoId == tipoApostoladoId);
    }


}