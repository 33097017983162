import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MissaoService } from './service/missao.service';
import { CommonModule } from '@angular/common';
import { SecuredModule } from '../../layout/secured.module';
import { RouterModule } from '@angular/router';
import { MissaoComponent } from './missao.component';
import { MissaoRoutingModule } from './missao-routing.module';
import { MissoesComponent } from './missoes.component';
import { MissaoEditComponent } from './missao.edit.component';

@NgModule({
    declarations: [ 
        MissaoComponent,
        MissoesComponent,
        MissaoEditComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        MissaoRoutingModule,
        FormsModule,
        SecuredModule
    ],
    providers: [
        MissaoService
    ]
})
export class MissaoModule {

}