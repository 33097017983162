import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { EnvioBuilder } from 'src/app/builder/envio.builder';
export class EnvioLocalComponent {
    constructor(envioService, regionalService, missaoService, casaComunitariaService, apostoladoService, envioBuilder, pessoaInfoBuilder, swtAlert2Service) {
        this.envioService = envioService;
        this.regionalService = regionalService;
        this.missaoService = missaoService;
        this.casaComunitariaService = casaComunitariaService;
        this.apostoladoService = apostoladoService;
        this.envioBuilder = envioBuilder;
        this.pessoaInfoBuilder = pessoaInfoBuilder;
        this.swtAlert2Service = swtAlert2Service;
        this.envioBuilder = new EnvioBuilder();
        this.envio = this.envioBuilder.getInstance();
        this.regionais = new Array();
        this.missoes = new Array();
        this.pessoaMissionariaSelecionada = this.pessoaInfoBuilder.getInstance();
    }
    ngOnInit() {
        this.initialize();
    }
    initialize() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.carregarRegionais();
            this.carregarApostolados();
        });
    }
    salvar(envio) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (this.validarEnvio(envio)) {
                    let response = yield this.envioService.create(envio);
                    this.envio = this.envioBuilder.getInstance();
                    this.pessoaMissionariaSelecionada = this.pessoaInfoBuilder.getInstance();
                    this.swtAlert2Service.successAlert(response.message);
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarRegionais() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.regionalService.findAll();
                this.regionais = response.entity.sort((a, b) => a.nome.localeCompare(b.nome));
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarMissoes() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.missaoService.porRegional(this.envio.regionalId);
                this.missoes = response.entity.sort((a, b) => a.nome.localeCompare(b.nome));
                ;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarCasasComunitarias() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.casaComunitariaService.porMissao(this.envio.missaoId);
                this.casasComunitarias = response.entity.sort((a, b) => a.nome.localeCompare(b.nome));
                ;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarApostolados() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.apostoladoService.pesquisar({ ativo: true });
                this.apostolados = response.entity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    selecionarMissionario(pessoa) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.envio.pessoaId = pessoa.id;
            this.pessoaMissionariaSelecionada = pessoa;
            $('#listagem-pessoa-missionario').modal('hide');
        });
    }
    validarEnvio(envio) {
        if (envio.pessoaId == null) {
            this.swtAlert2Service.warningAlert("Selecione o missionário para o aenvio");
            return false;
        }
        if (envio.regionalId == null) {
            this.swtAlert2Service.warningAlert("Informe o regional para o envio.");
            return false;
        }
        if (envio.missaoId == null) {
            this.swtAlert2Service.warningAlert("Informe a missão aenvio");
            return false;
        }
        return true;
    }
}
