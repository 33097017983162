<aside class="main-sidebar sidebar-light-orange elevation-4">
    <!-- Brand Logo -->
    <a [routerLink]="'/secured/perfil'" class="brand-link navbar-orange">
        <img src="assets/images/logo-comshalom.png" alt="Missionária" class="brand-image img-circle elevation-3" style="opacity: .8">
        <span class="brand-text font-weight-strong">MISSIONÁRIA</span>
    </a>

    <!-- Sidebar -->
    <div class="sidebar scrollbar">
        <!-- Sidebar user panel (optional) -->
        <div class="user-panel mt-3 pb-3 mb-3 d-flex">
            <div class="image">
                <img class="img-circle elevation-2" [src]="pessoaAtual?.thumbnail == null ? 'assets/images/ppl.png' : pessoaAtual?.thumbnail" alt="User Avatar">
            </div>
            <div class="info">
                <a [routerLink]="'/secured/perfil'" class="d-block">
                    <small>{{pessoaAtual != null ? pessoaAtual.nome : ''}}</small>
                </a>
            </div>
        </div>

        <!-- Sidebar Menu -->
        <nav class="mt-2">
            <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                <li class="nav-header">
                    <strong>MENU</strong>
                </li>

                <li class="nav-item has-treeview" routerLinkActive="menu-open" [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link" [ngClass]="{'active': rlperfil.isActive || rldadosvocacionais.isActive }">
                        <i class="nav-icon fas fa-id-card"></i>
                        <p i18n>
                            Pessoa
                            <i class="fas fa-angle-left right"></i>
                        </p>
                    </a>
                    <ul class="nav nav-treeview">
                        <li class="nav-item">
                            <a [routerLink]="'/secured/perfil'" routerLinkActive="active" #rlperfil="routerLinkActive" class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Perfil</p>
                            </a>
                        </li>
                    </ul>
                    <ul class="nav nav-treeview">
                        <li class="nav-item">
                            <a [routerLink]="'/secured/dadosvocacionais'" routerLinkActive="active" #rldadosvocacionais="routerLinkActive" class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Dados Vocacionais</p>
                            </a>
                        </li>
                    </ul>     
                    <ul class="nav nav-treeview">
                        <li class="nav-item">
                            <a [routerLink]="'/secured/saude'" routerLinkActive="active" #rlsaude="routerLinkActive" class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Saúde</p>
                            </a>
                        </li>
                    </ul>                                 
                </li>
                <!--li class="nav-item">
                    <a routerLink="/home" routerLinkActive="active" class="nav-link ">
                        <i class="nav-icon fas fa-home"></i>
                        <p>Home</p>
                    </a>
                </li-->
                <li class="nav-item has-treeview" routerLinkActive="menu-open" [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link" [ngClass]="{'active': rlrn.isActive || rlrl.isActive }">
                        <i class="nav-icon fas fa-map-marked-alt"></i>
                        <p i18n>
                            Regional
                            <i class="fas fa-angle-left right"></i>
                        </p>
                    </a>
                    <ul class="nav nav-treeview">
                        <li class="nav-item">
                            <a routerLink="/secured/regional" routerLinkActive="active" #rlrn="routerLinkActive" class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Novo</p>
                            </a>
                        </li>
                        <li class="nav-item ">
                            <a routerLink="/secured/regionais" routerLinkActive="active" #rlrl="routerLinkActive" class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Listar</p>
                            </a>
                        </li>
                    </ul>
                </li>

                <li class="nav-item has-treeview" routerLinkActive="menu-open" [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link" [ngClass]="{'active': rlmn.isActive || rlml.isActive }">
                        <i class="nav-icon fas fa-map-marked"></i>
                        <p i18n>
                            Missão
                            <i class="fas fa-angle-left right"></i>
                        </p>
                    </a>
                    <ul class="nav nav-treeview">
                        <li class="nav-item">
                            <a routerLink="/secured/missao" routerLinkActive="active" #rlmn="routerLinkActive" class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Novo</p>
                            </a>
                        </li>
                        <li class="nav-item ">
                            <a routerLink="/secured/missoes" routerLinkActive="active" #rlml="routerLinkActive" class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Listar</p>
                            </a>
                        </li>
                    </ul>
                </li>

                <li class="nav-item has-treeview" routerLinkActive="menu-open" [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link" [ngClass]="{'active': rlin.isActive || rlil.isActive }">
                        <i class="nav-icon fas fa-broadcast-tower"></i>
                        <p i18n>
                            Irradiação
                            <i class="fas fa-angle-left right"></i>
                        </p>
                    </a>
                    <ul class="nav nav-treeview">
                        <li class="nav-item">
                            <a routerLink="/secured/irradiacao" routerLinkActive="active" #rlin="routerLinkActive" class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Novo</p>
                            </a>
                        </li>
                        <li class="nav-item ">
                            <a routerLink="/secured/irradiacoes" routerLinkActive="active" #rlil="routerLinkActive" class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Listar</p>
                            </a>
                        </li>
                    </ul>
                </li>

                <li class="nav-item has-treeview" routerLinkActive="menu-open" [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link" [ngClass]="{'active': rldn.isActive || rldl.isActive }">
                        <i class="nav-icon fas fa-wifi"></i>
                        <p i18n>
                            Difusão
                            <i class="fas fa-angle-left right"></i>
                        </p>
                    </a>
                    <ul class="nav nav-treeview">
                        <li class="nav-item">
                            <a routerLink="/secured/difusao" routerLinkActive="active" #rldn="routerLinkActive" class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Novo</p>
                            </a>
                        </li>
                        <li class="nav-item ">
                            <a routerLink="/secured/difusoes" routerLinkActive="active" #rldl="routerLinkActive" class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Listar</p>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="nav-item has-treeview" routerLinkActive="menu-open" [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link" [ngClass]="{'active': rlccn.isActive || rlccl.isActive }">
                        <i class="nav-icon fas fa-home"></i>
                        <p i18n>
                            Casa comunitaria
                            <i class="fas fa-angle-left right"></i>
                        </p>
                    </a>
                    <ul class="nav nav-treeview">
                        <li class="nav-item">
                            <a routerLink="/secured/casacomunitaria" routerLinkActive="active" #rlccn="routerLinkActive" class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Novo</p>
                            </a>
                        </li>
                        <li class="nav-item ">
                            <a routerLink="/secured/casascomunitaria" routerLinkActive="active" #rlccl="routerLinkActive" class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Listar</p>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="nav-item has-treeview" routerLinkActive="menu-open" [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link" [ngClass]="{'active': rlmissionarios.isActive || rlmissionariosba.isActive }">
                        <i class="nav-icon fas fa-users"></i>
                        <p i18n>
                            Missionários
                            <i class="fas fa-angle-left right"></i>
                        </p>
                    </a>
                    <ul class="nav nav-treeview">
                        <li class="nav-item">
                            <a [routerLink]="'/secured/missionarios'" routerLinkActive="active" #rlmissionarios="routerLinkActive" class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Listar</p>
                            </a>
                        </li>
                    </ul>
                    <ul class="nav nav-treeview">
                        <li class="nav-item">
                            <a [routerLink]="'/secured/missionarios/buscaavancada'" routerLinkActive="active" #rlmissionariosba="routerLinkActive" class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Busca Avançada</p>
                            </a>
                        </li>
                    </ul>
                </li>
                <!--li class="nav-item has-treeview" routerLinkActive="menu-open" [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link" [ngClass]="{'active': rlnovademanda.isActive || rlremanejamentos.isActive }">
                        <i class="nav-icon fas fa-share-square"></i>
                        <p i18n>
                            Remanejamentos
                            <i class="fas fa-angle-left right"></i>
                        </p>
                    </a>
                    <ul class="nav nav-treeview">
                        <li class="nav-item">
                            <a [routerLink]="'/secured/remanejamento/demanda'" routerLinkActive="active" #rlnovademanda="routerLinkActive" class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Nova Demanda</p>
                            </a>
                        </li>
                    </ul>
                    <ul class="nav nav-treeview">
                        <li class="nav-item">
                            <a [routerLink]="'/secured/remanejamentos'" routerLinkActive="active" #rlremanejamentos="routerLinkActive" class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Listar</p>
                            </a>
                        </li>
                    </ul>
                </li--> 
                <li class="nav-item has-treeview" routerLinkActive="menu-open" [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link" [ngClass]="{'active': rlnovoenvio.isActive || rlenvios.isActive }">
                        <i class="nav-icon fas fa-share"></i>
                        <p i18n>
                            Envios
                            <i class="fas fa-angle-left right"></i>
                        </p>
                    </a>
                    <ul class="nav nav-treeview">
                        <li class="nav-item">
                            <a [routerLink]="'/secured/local/envio'" routerLinkActive="active" #rlnovoenvio="routerLinkActive" class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Novo</p>
                            </a>
                        </li>
                    </ul>
                    <ul class="nav nav-treeview">
                        <li class="nav-item">
                            <a [routerLink]="'/secured/local/envios'" routerLinkActive="active" #rlenvios="routerLinkActive" class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Listar</p>
                            </a>
                        </li>
                    </ul>                    
                </li>
                <!--li class="nav-item">
                    <a routerLink="/pendencies" routerLinkActive="active" class="nav-link ">
                        <i class="nav-icon fa fa-exclamation-triangle"></i>
                        <p>Pendências</p>
                    </a>
                </li>                
                <li class="nav-item">
                    <a routerLink="/users" routerLinkActive="active" class="nav-link ">
                        <i class="nav-icon fas fa-users"></i>
                        <p>Usuários</p>
                    </a>
                </li-->
                <li class="nav-header">
                    <strong>CONFIGURAÇÃO</strong>
                </li>
                <li class="nav-item has-treeview" routerLinkActive="menu-open" [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link" [ngClass]="{'active': rlmu.isActive }">
                        <i class="nav-icon fas fa-user"></i>
                        <p i18n>
                            Usuário
                            <i class="fas fa-angle-left right"></i>
                        </p>
                    </a>
                    <ul class="nav nav-treeview">
                        <li class="nav-item">
                            <a routerLink="/secured/meuusuario" routerLinkActive="active" #rlmu="routerLinkActive" class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Meu Usuário</p>
                            </a>
                        </li>
                        <!--li class="nav-item ">
                            <a routerLink="/secured/local/aa/usuarios" routerLinkActive="active" #rltclu="routerLinkActive" class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Listar</p>
                            </a>
                        </li-->
                    </ul>
                </li>
            </ul>
        </nav>
        <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
</aside>