import * as tslib_1 from "tslib";
import { OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
export class PessoasComponent {
    constructor(pessoaService, paisService, swtAlert2Service, imagemService) {
        this.pessoaService = pessoaService;
        this.paisService = paisService;
        this.swtAlert2Service = swtAlert2Service;
        this.imagemService = imagemService;
        this.pessoas = new Array();
        this.urlBaseImagem = environment.imagem.urlBaseImagem;
        this.paises = new Array();
        this.busca = {
            nome: null,
            sobrenome: null,
            genero: null,
            anonascimento: null,
            nacionalidadeId: null,
            pageNumber: 0,
            quantityOfElements: 10
        };
    }
    ngOnInit() {
        this.carregarPaises();
        this.buscarPessoas();
    }
    carregarPaises() {
        this.paisService.findAll().then((lista) => {
            this.paises = lista.entity;
        }).catch((err) => {
            console.log(err);
        });
    }
    buscarPessoas() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.pessoaService.buscarPessoas(this.busca);
                this.pessoas = response.entity;
                this.totalDePaginas = response.quantity;
                for (let i = 0; i < this.pessoas.length; i++) {
                    if (this.pessoas[i].imagemId != null) {
                        let blogImage = yield this.getPessoaImagem(this.pessoas[i].imagemId);
                        this.imagemService.createImage(blogImage, this.pessoas[i]);
                    }
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    getPessoaImagem(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.imagemService.getPessoaPhoto(id);
                return response.body;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    deletarPessoa(id) {
        if (confirm('Deseja deletar esta pessoa?')) {
            this.pessoaService.delete(id)
                .then((response) => {
                this.swtAlert2Service.successAlert(response.message);
                this.buscarPessoas();
            })
                .catch((err) => {
                this.swtAlert2Service.errorAlert(err.error.errors);
            });
        }
    }
    setarRegistroDuplicado(pessoaId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.pessoaService.setarRegistroDuplicado(pessoaId);
                this.swtAlert2Service.successAlert(response.message);
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    cliqueNoBotaoListener(botao) {
        this.busca.pageNumber = botao.numero - 1;
        this.buscarPessoas();
    }
    getUrlImagem(imagemId) {
        return this.urlBaseImagem + imagemId;
    }
}
