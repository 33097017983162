import { Injectable } from '@angular/core';
import { DadoVocacional } from '../model/dadovocacional.model';
import { AbstractBuilder } from './abstract.builder';

@Injectable()
export class DadoVocacionalBuilder extends AbstractBuilder<DadoVocacional> {

    reset() : void {
        this.entity = new DadoVocacional(null,null,null,null,null,null,null,null,null,null,null);
    }

}