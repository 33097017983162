import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AutorizacaoService } from './service/autorizacao.service';
import { CommonModule } from '@angular/common';
import { SecuredModule } from '../../layout/secured.module';
import { RouterModule } from '@angular/router';
import { AutorizacaoComponent } from './autorizacao.component';
import { AutorizacaoRoutingModule } from './autorizacao-routing.module';
import { PermissaoService } from '../permissao/service/permissao.service';
import { AutorizacoesComponent } from './autorizacoes.component';
import { AutorizacaoEditComponent } from './autorizacao.edit.component';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { NgpSortModule } from 'ngp-sort-pipe';

@NgModule({
    declarations: [ 
        AutorizacaoComponent,
        AutorizacoesComponent,
        AutorizacaoEditComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        AutorizacaoRoutingModule,
        FormsModule,
        SecuredModule,
        NgpSortModule
    ],
    providers: [
        PermissaoService,
        AutorizacaoService,
        SwtAlert2Service
    ]
})
export class AutorizacaoModule {

}