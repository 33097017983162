import { GenericService } from 'src/app/core/services/generic.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from "@angular/core";
import { DadoFormandoCV } from 'src/app/model/dadoformandocv.model';
import { environment } from 'src/environments/environment';


@Injectable()
export class DadoFormandoCVService extends GenericService<DadoFormandoCV> {
    
    
    constructor(protected http: HttpClient, protected router:Router) {
        super(http,router);
        this.setEntityType('dadofomandocv');
    }

    porPessoaId(id: number) : Promise<any> {
        return this.http.get<any>(`${environment.moduloFormacao.buscarDadosFormandoCV}${id}`).toPromise();
    }  

    cadastrar(request: any) {
        return this.http.post<any>(environment.moduloFormacao.cadastrarDadosFormandoCV, JSON.stringify(request), { 'headers' : this.getHeaders() }).toPromise();
    }

    atualizar(request: any) {
        return this.http.put<any>(environment.moduloFormacao.atualizarDadosFormandoCV, JSON.stringify(request), { 'headers' : this.getHeaders() }).toPromise();
    }  
 
}