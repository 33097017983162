import { Autorizacao } from './autorizacao.model';

export class Permissao {

    constructor(
        public id: number,
        public nome: string,
        public nomeExibicao: string,
        public autorizacoes: Autorizacao[]
    ){}

}