import { CentroEvangelizacao } from "../model/centroevangelizacao.model";
import { Endereco } from "../model/endereco.model";
import { AbstractBuilder } from "./abstract.builder";



export class CentroEvangelizacaoBuilder extends AbstractBuilder<CentroEvangelizacao> {

    reset() : void {
        this.entity = new CentroEvangelizacao(null, null, null, null, null, null, null, new Endereco(null,null,null,null,null,null,null,null));
    }

}