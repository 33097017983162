<div class="modal fade" [id]="modalId">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">{{modalTitle}}</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12">
                            <form (ngSubmit)="buscarPessoas()" #permissoesForm="ngForm" novalidate>
                                <div class="card">
                                    <div class="card-header">
                                        <h6 class="card-title">Buscar Pessoas</h6>
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-sm-12 col-md-4 col-lg-4">
                                                <div class="form-group">
                                                    <label i18n for="lpessoa_nome">Nome</label>
                                                    <input [(ngModel)]="busca.nome" name="lpessoa_nome" type="text" class="form-control" id="lpessoa_nome" placeholder="Digite o nome" i18n-placeholder>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-4 col-lg-4">
                                                <div class="form-group">
                                                    <label i18n for="lpessoa_sobrenome">Sobrenome</label>
                                                    <input [(ngModel)]="busca.sobrenome" name="lpessoa_sobrenome" type="text" class="form-control" id="lpessoa_sobrenome" placeholder="Digite o sobrenome" i18n-placeholder>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-4 col-lg-4">
                                                <div class="form-group">
                                                    <label i18n for="lpessoa_genero">Sexo</label>
                                                    <select class="form-control" id="lpessoa_genero" [(ngModel)]="busca.genero" name="lpessoa_genero">
                                                        <option value="null" i18n>Selecione o sexo</option>
                                                        <option value="masculino" i18n>Masculino</option>
                                                        <option value="feminino" i18n>Feminino</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-4 col-lg-4">
                                                <div class="form-group">
                                                    <label i18n for="lpessoa_nacionalidade">Nacionalidade</label>
                                                    <select class="form-control" id="lpessoa_nacionalidade" [(ngModel)]="busca.nacionalidadeId" name="lpessoa_nacionalidade">
                                                        <option [value]="null" i18n>Selecione a Nacionalidade</option>
                                                        <option *ngFor="let pais of paises" [value]="pais.id">{{pais.nome}}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-4 col-lg-4" *ngIf="liberarFiltro('missao')">
                                                <div class="form-group">
                                                    <label i18n for="lpessoa_missao">Missão</label>
                                                    <select class="form-control" id="lpessoa_missao" [(ngModel)]="busca.missaoAtualId" name="lpessoa_missao">
                                                        <option [value]="null" i18n>Selecione a Missão</option>
                                                        <option *ngFor="let missao of missoes" [value]="missao.id">{{missao.nome}}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-footer">
                                        <button type="submit" class="btn btn-primary float-right" i18n>Buscar</button>
                                        <button type="reset" class="btn btn-default float-right" style="margin-right: 0.5rem;" i18n>Limpar</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12" style='text-align: center'>
                            <table-component [cardBodyClass]="'table-responsive p-0'" [tableClass]="'table-striped'" [tituloSessao]="'Lista das Pessoas'" [cols]="6" [rows]="busca.quantityOfElements" [totalDePaginas]="totalDePaginas" (eventoBotao)="cliqueNoBotaoListener($event)">
                                <tr tableheader>
                                    <th style="width: 10px">#</th>
                                    <th>Foto de perfil</th>
                                    <th>Nome</th>
                                    <th>Sobrenome</th>
                                    <th>Data Nascimento</th>
                                    <th></th>
                                </tr>
                                <tr tablebody *ngFor="let pessoa of pessoas">
                                    <th>{{pessoa.id}}</th>
                                    <td>
                                        <div class="widget-user-image">
                                            <img class="profile-user-img img-fluid img-circle image-size-list elevation-2" [src]="pessoa.thumbnail == null ? 'assets/images/ppl.png' : pessoa.thumbnail" alt="User Avatar">
                                        </div>
                                    </td>
                                    <td>{{pessoa.nome}}</td>
                                    <td>{{pessoa.sobrenome}}</td>
                                    <td>{{pessoa.dataNascimento | date : 'dd/MM/yyyy'}}</td>
                                    <td class="text-center">
                                        <button class="btn btn-sm btn-primary" (click)="pessoaSelecionada(pessoa)" [title]="altButtonText">{{buttonText}}</button>
                                    </td>
                                </tr>
                            </table-component>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer justify-content-right">
                <button type="button" class="btn btn-default" data-dismiss="modal">Fechar</button>
            </div>
        </div>
    </div>
</div>