import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { QuestionariosComponent } from './questionarios/questionarios.component';
import { QuestionarioAgradecimentoComponent } from './missionario/questionario-agradecimento.component';
import { QuestionarioD2AnswerComponent } from './missionario/questionariod2-answer.component';
import { QuestionarioD2ViewAnswerComponent } from './missionario/questionariod2-view-answer.component';
import { QuestionarioCALViewAnswerComponent } from './missionario/questionario-cal-view-answer.component';
import { QuestionarioCelulaComponent } from './celula/questionario-celula.component';
import { QuestionarioComponent } from './questionario.component';

const pessoaRoutes: Routes = [
  {
    path: 'secured/questionariod2-answer/:id',
    component: QuestionarioD2AnswerComponent,
  },
  {
    path: 'secured/questionarios',
    component: QuestionariosComponent,
  },
  {
    path: 'secured/rl/celula/questionarios',
    component: QuestionarioComponent
  },
  {
    path: 'secured/questionario/celula/:id',
    component: QuestionarioCelulaComponent
  },
  {
    path: 'secured/questionarios/celula',
    component: QuestionarioCelulaComponent,
  },
  {
    path: 'secured/questionario-agradecimento',
    component: QuestionarioAgradecimentoComponent,
  },
  {
    path: 'secured/questionariod2-view-answer/:id',
    component: QuestionarioD2ViewAnswerComponent,
  },
  {
    path: 'secured/questionariocal-view-answer/:id',
    component: QuestionarioCALViewAnswerComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(pessoaRoutes)],
  exports: [RouterModule],
})
export class QuestionarioRoutingModule {}
