import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MinisterioComponent } from './ministerio.component';
import { MinisteriosComponent } from './ministerios.component';
import { MinisterioEditComponent } from './ministerio.edit.component';


const ministerioRoutes: Routes = [
    {
        path: 'secured/ministerio',
        component: MinisterioComponent
    },
    {
        path: 'secured/ministerios',
        component: MinisteriosComponent
    },
    {
        path: 'secured/ministerio/:id',
        component: MinisterioEditComponent
    }
]

@NgModule({
    imports: [
        RouterModule.forChild(ministerioRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class MinisterioRoutingModule {
    
}