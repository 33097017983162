import { Autorizacao } from '../model/autorizacao.model';
import { AbstractBuilder } from './abstract.builder';


export class AutorizacaoBuilder extends AbstractBuilder<Autorizacao> {

    reset() : void {
        this.entity = new Autorizacao(null,null,null,[],null);
    }

}