<div class="card card-gray-dark color-pallete-box">
    <div class="card-header">
        <h6 class="card-title">{{tituloSessao}}</h6>
    </div>
    <!-- /.card-header -->
    <div class="card-body overflow-auto {{cardBodyClass != undefined ? cardBodyClass : ''}}">
        <table class="table {{tableClass != undefined ? tableClass : ''}}" [ngClass]="{'table-bordered': tableClass == undefined }">
            <thead>
                <ng-content select="[tableheader]"></ng-content>
            </thead>
            <tbody>
                <ng-content select="[tablebody]"></ng-content>
            </tbody>
            <tfoot>
                <tr>
                    <td [colSpan]="cols">
                        <ul class="pagination pagination-sm m-0 float-right">
                            <li class="page-item" [ngClass]="{'active': botao.status == 'active'}" *ngFor="let botao of botoes">
                                <a class="page-link" (click)="cliqueNoBotao(botao)">{{botao.simbolo}}</a>
                            </li>
                        </ul>
                    </td>
                </tr>
            </tfoot>
        </table>
    </div>
    <!-- /.card-body -->
    <!-- <div class="card-footer clearfix">
    </div> -->
</div>