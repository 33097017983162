<secured>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h3 i18n>Listagem das Célula</h3>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <form (ngSubmit)="buscarCelulas()" #permissoesForm="ngForm" novalidate>
                            <div class="card card-gray-dark color-pallete-box collapsed-card">
                                <div class="card-header">
                                    <h3 class="card-title">Buscar Células</h3>
                                    <div class="card-tools">
                                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                            <i class="fas fa-plus"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label i18n for="lcit_nome">Nome</label>
                                                <input [(ngModel)]="busca.nome" name="lcit_nome" type="text"
                                                    class="form-control" id="lcit_nome" placeholder="Digite o nome"
                                                    i18n-placeholder>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label i18n for="ncelula_dtfund">Ano De Fundação</label>
                                                <input [(ngModel)]="busca.ano" name="ncelula_dtfund" type="number"
                                                    class="form-control" id="ncelula_dtfund" i18n-placeholder>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label i18n for="ncelula_fase">Nível da célula</label>
                                                <select class="form-control" id="ncelula_fase"
                                                    [(ngModel)]="busca.faseId" name="ncelula_fase">
                                                    <option [value]="null" i18n>Selecione um Nível</option>
                                                    <option *ngFor="let fase of fases" [value]="fase.id">{{fase.nome}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                            <div class="form-group">
                                                <label i18n for="ncelula_cev">Centro De Evangelização</label>
                                                <select class="form-control" id="ncelula_cev"
                                                    [(ngModel)]="busca.centroEvangelizacaoId" name="ncelula_cev">
                                                    <option [value]="null" i18n>Selecione um centro de Evangelização
                                                    </option>
                                                    <option *ngFor="let cev of centrosEvangelizacao" [value]="cev.id">
                                                        {{cev.nome}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-footer">
                                    <button type="submit" class="btn btn-primary float-right" i18n>Buscar</button>
                                    <button type="reset" class="btn btn-default float-right"
                                        style="margin-right: 0.5rem;" i18n>Limpar</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="row">
                    <app-dashboard-info-box *ngIf="detalhesDashboardCelula"
                        [detalhesDashboardCelula]="detalhesDashboardCelula"></app-dashboard-info-box>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <table-component [tituloSessao]="'Células Comunitárias'" [cols]="5"
                            [rows]="busca.quantityOfElements" [totalDePaginas]="totalDePaginas"
                            (eventoBotao)="cliqueNoBotaoListener($event)">
                            <tr tableheader>
                                <th style="width: 10px">#</th>
                                <th>Nome</th>
                                <th>Nivel da Célula</th>
                                <th>Centro De Evangelização</th>
                                <th></th>
                            </tr>
                            <tr tablebody *ngFor="let cel of celulas">
                                <td>{{cel.id}}</td>
                                <td>{{cel.nome}}</td>
                                <td>{{cel.nivelCelulaNome}}</td>
                                <td>{{cel.centroEvangelizacaoNome}}
                                <td class="text-center">
                                    <button class="btn btn-sm btn-primary" routerLink="../../celula/{{cel.id}}"
                                        title="Editar célula" alt="Editar célula"><i class="fas fa-edit"></i></button>
                                    <button class="btn btn-sm btn-default ml-1"
                                        routerLink="../../celula/{{cel.id}}/gerenciar" title="Gerenciar célula"
                                        alt="Gerenciar célula"><i class="fas fa-cog"></i></button>
                                    <button class="btn btn-sm btn-primary ml-1"
                                        routerLink="../../celula/discernimento/{{cel.id}}"
                                        title="Discernimentos da Célula" alt="Discernimentos da Célula"><i
                                            class="fas fa-list"></i></button>
                                </td>
                            </tr>
                        </table-component>
                    </div>
                </div>
            </div>
        </section>
    </div>


</secured>