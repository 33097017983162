/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./select2.component";
var styles_Select2Component = [".select2[_ngcontent-%COMP%] { max-width: 100% !important; }"];
var RenderType_Select2Component = i0.ɵcrt({ encapsulation: 0, styles: styles_Select2Component, data: {} });
export { RenderType_Select2Component as RenderType_Select2Component };
export function View_Select2Component_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "select", [["class", "form-control select2"]], [[8, "name", 0], [8, "required", 0]], null, null, null, null)), i0.ɵncd(null, 0)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.name; var currVal_1 = _co.required; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_Select2Component_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "select2-component", [], null, null, null, View_Select2Component_0, RenderType_Select2Component)), i0.ɵdid(1, 8503296, null, 0, i1.Select2Component, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var Select2ComponentNgFactory = i0.ɵccf("select2-component", i1.Select2Component, View_Select2Component_Host_0, { name: "name", url: "url", multiplo: "multiplo", required: "required" }, { elementoSelecionado: "elementoSelecionado" }, ["*"]);
export { Select2ComponentNgFactory as Select2ComponentNgFactory };
