import { Component, OnInit } from '@angular/core';
import { EstadoService } from './service/estado.service';
import { Estado } from 'src/app/model/estado.model';
import { PaisService } from '../pais/service/pais.service';
import { Pais } from 'src/app/model/pais.model';
import { EstadoBuilder } from 'src/app/builder/estado.builder';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';

@Component({
    selector: 'estado',
    templateUrl: './estado.component.html'
})
export class EstadoComponent implements OnInit {

    public estado: Estado;
    public paises : Array<Pais>;
    public estadoBuilder: EstadoBuilder;

    constructor(
        public estadoService: EstadoService,
        public paisService: PaisService,
        public swtAlert2Service: SwtAlert2Service
    ) 
    {
        this.estadoBuilder = new EstadoBuilder();
        this.estado = this.estadoBuilder.getInstance();
        this.paises = new Array<Pais>();
    }

    ngOnInit() { 
        this.carregarPaises();      
    }

    carregarPaises() : void {
        this.paisService.findAll().then( (lista: any) => {
            this.paises = lista.entity;
        }).catch( (err: any) => {
            console.log(err);
        });
    }

    salvar(estado: Estado) : void {

        this.estadoService.create(estado).then( (response:any) => {
            this.estado = new Estado(null,null,null,[]);
            this.swtAlert2Service.successAlert(response.message);

        }).catch( (err:any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

}