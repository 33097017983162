<aside class="main-sidebar sidebar-light-orange elevation-4">
    <!-- Brand Logo -->
    <a [routerLink]="'/secured/perfil'" class="brand-link navbar-orange">
        <img src="assets/images/logo-comshalom.png" alt="Missionária" class="brand-image img-circle elevation-3"
            style="opacity: .8">
        <span class="brand-text font-weight-strong">MISSIONÁRIA</span>
    </a>

    <!-- Sidebar -->
    <div class="sidebar scrollbar">
        <!-- Sidebar user panel (optional) -->
        <div class="user-panel mt-3 pb-3 mb-3 d-flex">
            <div class="image">
                <img class="img-circle elevation-2"
                    [src]="pessoaAtual?.thumbnail == null ? 'assets/images/ppl.png' : pessoaAtual?.thumbnail"
                    alt="Imagem da pessoa">
            </div>
            <div class="info" style="white-space: normal !important;">
                <a [routerLink]="'/secured/perfil'" class="d-block">
                    <small>{{pessoaAtual?.id != null ? pessoaAtual?.nome+' '+pessoaAtual?.sobrenome : ''}}</small>
                </a>
            </div>
        </div>

        <!-- Sidebar Menu -->
        <nav class="mt-2">
            <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                <li class="nav-header">
                    <strong>CADASTROS</strong>
                </li>

                <li class="nav-item has-treeview" routerLinkActive="menu-open"
                    [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link" [ngClass]="{'active': rlpessoan.isActive || rlpessoal.isActive }">
                        <i class="nav-icon fas fa-id-card"></i>
                        <p i18n>
                            Pessoa
                            <i class="fas fa-angle-left right"></i>
                        </p>
                    </a>
                    <ul class="nav nav-treeview">
                        <li class="nav-item">
                            <a routerLink="/secured/pessoa" routerLinkActive="active" #rlpessoan="routerLinkActive"
                                class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Novo</p>
                            </a>
                        </li>
                        <li class="nav-item ">
                            <a routerLink="/secured/pessoas" routerLinkActive="active" #rlpessoal="routerLinkActive"
                                class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Listar</p>
                            </a>
                        </li>
                    </ul>
                </li>

                <li class="nav-item has-treeview" routerLinkActive="menu-open"
                    [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link" [ngClass]="{'active': rlrn.isActive || rlrl.isActive }">
                        <i class="nav-icon fas fa-map-marked-alt"></i>
                        <p i18n>
                            Regional
                            <i class="fas fa-angle-left right"></i>
                        </p>
                    </a>
                    <ul class="nav nav-treeview">
                        <li class="nav-item">
                            <a routerLink="/secured/regional" routerLinkActive="active" #rlrn="routerLinkActive"
                                class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Novo</p>
                            </a>
                        </li>
                        <li class="nav-item ">
                            <a routerLink="/secured/regionais" routerLinkActive="active" #rlrl="routerLinkActive"
                                class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Listar</p>
                            </a>
                        </li>
                    </ul>
                </li>

                <li class="nav-item has-treeview" routerLinkActive="menu-open"
                    [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link" [ngClass]="{'active': rlmn.isActive || rlml.isActive }">
                        <i class="nav-icon fas fa-map-marked"></i>
                        <p i18n>
                            Missão
                            <i class="fas fa-angle-left right"></i>
                        </p>
                    </a>
                    <ul class="nav nav-treeview">
                        <li class="nav-item">
                            <a routerLink="/secured/missao" routerLinkActive="active" #rlmn="routerLinkActive"
                                class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Novo</p>
                            </a>
                        </li>
                        <li class="nav-item ">
                            <a routerLink="/secured/missoes" routerLinkActive="active" #rlml="routerLinkActive"
                                class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Listar</p>
                            </a>
                        </li>
                    </ul>
                </li>

                <li class="nav-item has-treeview" routerLinkActive="menu-open"
                    [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link" [ngClass]="{'active': rlin.isActive || rlil.isActive }">
                        <i class="nav-icon fas fa-broadcast-tower"></i>
                        <p i18n>
                            Irradiação
                            <i class="fas fa-angle-left right"></i>
                        </p>
                    </a>
                    <ul class="nav nav-treeview">
                        <li class="nav-item">
                            <a routerLink="/secured/irradiacao" routerLinkActive="active" #rlin="routerLinkActive"
                                class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Novo</p>
                            </a>
                        </li>
                        <li class="nav-item ">
                            <a routerLink="/secured/irradiacoes" routerLinkActive="active" #rlil="routerLinkActive"
                                class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Listar</p>
                            </a>
                        </li>
                    </ul>
                </li>

                <li class="nav-item has-treeview" routerLinkActive="menu-open"
                    [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link" [ngClass]="{'active': rldn.isActive || rldl.isActive }">
                        <i class="nav-icon fas fa-wifi"></i>
                        <p i18n>
                            Difusão
                            <i class="fas fa-angle-left right"></i>
                        </p>
                    </a>
                    <ul class="nav nav-treeview">
                        <li class="nav-item">
                            <a routerLink="/secured/difusao" routerLinkActive="active" #rldn="routerLinkActive"
                                class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Novo</p>
                            </a>
                        </li>
                        <li class="nav-item ">
                            <a routerLink="/secured/difusoes" routerLinkActive="active" #rldl="routerLinkActive"
                                class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Listar</p>
                            </a>
                        </li>
                    </ul>
                </li>

                <li class="nav-item has-treeview" routerLinkActive="menu-open"
                    [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link" [ngClass]="{'active': rlcevn.isActive || rlcevl.isActive }">
                        <i class="nav-icon fas fa-place-of-worship"></i>
                        <p i18n>
                            CEV
                            <i class="fas fa-angle-left right"></i>
                        </p>
                    </a>
                    <ul class="nav nav-treeview">
                        <li class="nav-item">
                            <a routerLink="/secured/centroevangelizacao" routerLinkActive="active"
                                #rlcevn="routerLinkActive" class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Novo</p>
                            </a>
                        </li>
                        <li class="nav-item ">
                            <a routerLink="/secured/centrosevangelizacao" routerLinkActive="active"
                                #rlcevl="routerLinkActive" class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Listar</p>
                            </a>
                        </li>
                    </ul>
                </li>

                <li class="nav-item has-treeview" routerLinkActive="menu-open"
                    [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link" [ngClass]="{'active': rlgon.isActive || rlgol.isActive }">
                        <i class="nav-icon fas fa-users"></i>
                        <p i18n>
                            Grupo de Oração
                            <i class="fas fa-angle-left right"></i>
                        </p>
                    </a>
                    <ul class="nav nav-treeview">
                        <li class="nav-item">
                            <a routerLink="/secured/grupodeoracao" routerLinkActive="active" #rlgon="routerLinkActive"
                                class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Novo</p>
                            </a>
                        </li>
                        <li class="nav-item ">
                            <a routerLink="/secured/gruposdeoracao" routerLinkActive="active" #rlgol="routerLinkActive"
                                class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Listar</p>
                            </a>
                        </li>
                    </ul>
                </li>

                <li class="nav-item has-treeview" routerLinkActive="menu-open"
                    [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link" [ngClass]="{'active': rlceln.isActive || rlcell.isActive }">
                        <i class="nav-icon fas fa-thumbtack"></i>
                        <p i18n>
                            Célula
                            <i class="fas fa-angle-left right"></i>
                        </p>
                    </a>
                    <ul class="nav nav-treeview">
                        <li class="nav-item">
                            <a routerLink="/secured/celula" routerLinkActive="active" #rlceln="routerLinkActive"
                                class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Novo</p>
                            </a>
                        </li>
                        <li class="nav-item ">
                            <a routerLink="/secured/celulas" routerLinkActive="active" #rlcell="routerLinkActive"
                                class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Listar</p>
                            </a>
                        </li>
                    </ul>
                </li>

                <li class="nav-item has-treeview" routerLinkActive="menu-open"
                    [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link" [ngClass]="{'active': rlccn.isActive || rlccl.isActive }">
                        <i class="nav-icon fas fa-home"></i>
                        <p i18n>
                            Casa comunitaria
                            <i class="fas fa-angle-left right"></i>
                        </p>
                    </a>
                    <ul class="nav nav-treeview">
                        <li class="nav-item">
                            <a routerLink="/secured/casacomunitaria" routerLinkActive="active" #rlccn="routerLinkActive"
                                class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Novo</p>
                            </a>
                        </li>
                        <li class="nav-item ">
                            <a routerLink="/secured/casascomunitaria" routerLinkActive="active"
                                #rlccl="routerLinkActive" class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Listar</p>
                            </a>
                        </li>
                    </ul>
                </li>

                <li class="nav-item has-treeview" routerLinkActive="menu-open"
                    [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link" [ngClass]="{'active': rlminn.isActive || rlminl.isActive }">
                        <i class="nav-icon fas fa-guitar"></i>
                        <p i18n>
                            Ministério
                            <i class="fas fa-angle-left right"></i>
                        </p>
                    </a>
                    <ul class="nav nav-treeview">
                        <li class="nav-item">
                            <a routerLink="/secured/ministerio" routerLinkActive="active" #rlminn="routerLinkActive"
                                class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Novo</p>
                            </a>
                        </li>
                        <li class="nav-item ">
                            <a routerLink="/secured/ministerios" routerLinkActive="active" #rlminl="routerLinkActive"
                                class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Listar</p>
                            </a>
                        </li>
                    </ul>
                </li>

                <li class="nav-item has-treeview" routerLinkActive="menu-open"
                    [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link" [ngClass]="{'active': rlservicon.isActive || rlservicol.isActive }">
                        <i class="nav-icon fas fa-folder"></i>
                        <p i18n>
                            Projeto
                            <i class="fas fa-angle-left right"></i>
                        </p>
                    </a>
                    <ul class="nav nav-treeview">
                        <li class="nav-item">
                            <a routerLink="/secured/projeto" routerLinkActive="active" #rlservicon="routerLinkActive"
                                class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Novo</p>
                            </a>
                        </li>
                        <li class="nav-item ">
                            <a routerLink="/secured/projetos" routerLinkActive="active" #rlservicol="routerLinkActive"
                                class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Listar</p>
                            </a>
                        </li>
                    </ul>
                </li>

                <li class="nav-header">
                    <strong>CONFIGURAÇÃO MISSIONÁRIA</strong>
                </li>

                <li class="nav-item has-treeview" routerLinkActive="menu-open"
                    [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link" [ngClass]="{'active': rlun.isActive || rlul.isActive }">
                        <i class="nav-icon fas fa-user"></i>
                        <p i18n>
                            Usuário
                            <i class="fas fa-angle-left right"></i>
                        </p>
                    </a>
                    <ul class="nav nav-treeview">
                        <li class="nav-item">
                            <a routerLink="/secured/usuario" routerLinkActive="active" #rlun="routerLinkActive"
                                class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Novo</p>
                            </a>
                        </li>
                        <li class="nav-item ">
                            <a routerLink="/secured/usuarios" routerLinkActive="active" #rlul="routerLinkActive"
                                class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Listar</p>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="nav-item has-treeview" routerLinkActive="menu-open"
                    [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link" [ngClass]="{'active': rlanl.isActive || rlall.isActive }">
                        <i class="nav-icon fas fa-key"></i>
                        <p i18n>
                            Autorização
                            <i class="fas fa-angle-left right"></i>
                        </p>
                    </a>
                    <ul class="nav nav-treeview">
                        <li class="nav-item">
                            <a routerLink="/secured/local/autorizacao" routerLinkActive="active"
                                #rlanl="routerLinkActive" class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Novo</p>
                            </a>
                        </li>
                        <li class="nav-item ">
                            <a routerLink="/secured/local/autorizacoes" routerLinkActive="active"
                                #rlall="routerLinkActive" class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Listar</p>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="nav-item has-treeview" routerLinkActive="menu-open"
                    [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link" [ngClass]="{'active': rlpnl.isActive || rlpll.isActive }">
                        <i class="nav-icon fas fa-user-lock"></i>
                        <p i18n>
                            Permissão
                            <i class="fas fa-angle-left right"></i>
                        </p>
                    </a>
                    <ul class="nav nav-treeview">
                        <li class="nav-item">
                            <a routerLink="/secured/local/permissao" routerLinkActive="active" #rlpnl="routerLinkActive"
                                class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Novo</p>
                            </a>
                        </li>
                        <li class="nav-item ">
                            <a routerLink="/secured/local/permissoes" routerLinkActive="active"
                                #rlpll="routerLinkActive" class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Listar</p>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="nav-item has-treeview" routerLinkActive="menu-open"
                    [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link" [ngClass]="{'active': rlgdln.isActive || rlgdll.isActive }">
                        <i class="nav-icon far fa-object-group"></i>
                        <p i18n>
                            Grupo Domínio
                            <i class="fas fa-angle-left right"></i>
                        </p>
                    </a>
                    <ul class="nav nav-treeview">
                        <li class="nav-item">
                            <a routerLink="/secured/local/grupodominio" routerLinkActive="active"
                                #rlgdln="routerLinkActive" class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Novo</p>
                            </a>
                        </li>
                        <li class="nav-item ">
                            <a routerLink="/secured/local/gruposdominio" routerLinkActive="active"
                                #rlgdll="routerLinkActive" class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Listar</p>
                            </a>
                        </li>
                    </ul>
                </li>

                <li class="nav-header">
                    <strong>CONFIGURAÇÃO WOP</strong>
                </li>
                <li class="nav-item has-treeview" routerLinkActive="menu-open"
                    [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link" [ngClass]="{'active': rlan.isActive || rlal.isActive }">
                        <i class="nav-icon fas fa-key"></i>
                        <p i18n>
                            Autorização
                            <i class="fas fa-angle-left right"></i>
                        </p>
                    </a>
                    <ul class="nav nav-treeview">
                        <li class="nav-item">
                            <a routerLink="/secured/autorizacao" routerLinkActive="active" #rlan="routerLinkActive"
                                class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Novo</p>
                            </a>
                        </li>
                        <li class="nav-item ">
                            <a routerLink="/secured/autorizacoes" routerLinkActive="active" #rlal="routerLinkActive"
                                class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Listar</p>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="nav-item has-treeview" routerLinkActive="menu-open"
                    [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link" [ngClass]="{'active': rlpn.isActive || rlpl.isActive }">
                        <i class="nav-icon fas fa-user-lock"></i>
                        <p i18n>
                            Permissão
                            <i class="fas fa-angle-left right"></i>
                        </p>
                    </a>
                    <ul class="nav nav-treeview">
                        <li class="nav-item">
                            <a routerLink="/secured/permissao" routerLinkActive="active" #rlpn="routerLinkActive"
                                class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Novo</p>
                            </a>
                        </li>
                        <li class="nav-item ">
                            <a routerLink="/secured/permissoes" routerLinkActive="active" #rlpl="routerLinkActive"
                                class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Listar</p>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="nav-item has-treeview" routerLinkActive="menu-open"
                    [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link" [ngClass]="{'active': rlauen.isActive || rlauel.isActive }">
                        <i class="nav-icon fas fa-user-lock"></i>
                        <p i18n>
                            Acesso Estruturas
                            <i class="fas fa-angle-left right"></i>
                        </p>
                    </a>
                    <ul class="nav nav-treeview">
                        <li class="nav-item">
                            <a routerLink="/secured/acessousuarioestrutura" routerLinkActive="active"
                                #rlauen="routerLinkActive" class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Novo</p>
                            </a>
                        </li>
                        <li class="nav-item ">
                            <a routerLink="/secured/acessosusuarioestrutura" routerLinkActive="active"
                                #rlauel="routerLinkActive" class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Listar</p>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="nav-item has-treeview" routerLinkActive="menu-open"
                    [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link" [ngClass]="{'active': rlpaisn.isActive || rlpaisl.isActive }">
                        <i class="nav-icon fas fa-globe-americas"></i>
                        <p i18n>
                            País
                            <i class="fas fa-angle-left right"></i>
                        </p>
                    </a>
                    <ul class="nav nav-treeview">
                        <li class="nav-item">
                            <a routerLink="/secured/pais" routerLinkActive="active" #rlpaisn="routerLinkActive"
                                class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Novo</p>
                            </a>
                        </li>
                        <li class="nav-item ">
                            <a routerLink="/secured/paises" routerLinkActive="active" #rlpaisl="routerLinkActive"
                                class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Listar</p>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="nav-item has-treeview" routerLinkActive="menu-open"
                    [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link" [ngClass]="{'active': rlestn.isActive || rlestl.isActive }">
                        <i class="nav-icon fas fa-globe-americas"></i>
                        <p i18n>
                            Estado
                            <i class="fas fa-angle-left right"></i>
                        </p>
                    </a>
                    <ul class="nav nav-treeview">
                        <li class="nav-item">
                            <a routerLink="/secured/estado" routerLinkActive="active" #rlestn="routerLinkActive"
                                class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Novo</p>
                            </a>
                        </li>
                        <li class="nav-item ">
                            <a routerLink="/secured/estados" routerLinkActive="active" #rlestl="routerLinkActive"
                                class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Listar</p>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="nav-item has-treeview" routerLinkActive="menu-open"
                    [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link" [ngClass]="{'active': rlcidn.isActive || rlcidl.isActive }">
                        <i class="nav-icon fas fa-globe-americas"></i>
                        <p i18n>
                            Cidade
                            <i class="fas fa-angle-left right"></i>
                        </p>
                    </a>
                    <ul class="nav nav-treeview">
                        <li class="nav-item">
                            <a routerLink="/secured/cidade" routerLinkActive="active" #rlcidn="routerLinkActive"
                                class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Novo</p>
                            </a>
                        </li>
                        <li class="nav-item ">
                            <a routerLink="/secured/cidades" routerLinkActive="active" #rlcidl="routerLinkActive"
                                class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Listar</p>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="nav-item has-treeview" routerLinkActive="menu-open"
                    [routerLinkActiveOptions]="{exact: true}">
                    <a class="nav-link" [ngClass]="{'active': rlgdn.isActive || rlgdl.isActive }">
                        <i class="nav-icon far fa-object-group"></i>
                        <p i18n>
                            Grupo Domínio
                            <i class="fas fa-angle-left right"></i>
                        </p>
                    </a>
                    <ul class="nav nav-treeview">
                        <li class="nav-item">
                            <a routerLink="/secured/grupodominio" routerLinkActive="active" #rlgdn="routerLinkActive"
                                class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Novo</p>
                            </a>
                        </li>
                        <li class="nav-item ">
                            <a routerLink="/secured/gruposdominio" routerLinkActive="active" #rlgdl="routerLinkActive"
                                class="nav-link ">
                                <i class="far fa-circle nav-icon "></i>
                                <p i18n>Listar</p>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </nav>
        <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
</aside>