import { iGenericService } from './generic.service.interface';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Token } from 'src/app/model/token.model';
import { environment } from 'src/environments/environment';
import { Login } from 'src/app/model/login.model';
import { Router } from '@angular/router';
import { Injectable } from "@angular/core";

@Injectable()
export class GenericService<T> implements iGenericService<T> {

    protected apiURL: string = environment.configServidor.apiUrl;
    private tokenURL: string = environment.configServidor.tokenUrl;
    
    protected token : Token;
    protected headers: HttpHeaders;
    protected tokenHeaders: HttpHeaders;

    constructor(
        protected http: HttpClient, 
        protected router: Router
    ) 
    {
        this.token = JSON.parse(localStorage.getItem("token"));
        this.tokenHeaders = new HttpHeaders({
            'Content-Type' : 'application/x-www-form-urlencoded; charset=UTF-8',
            'Authorization' : environment.authorization                                               
        });
        this.getHeaders();
    }

    setEntityType(entityType : string) : void {
        this.apiURL += entityType;
    }
    
    create(entity: T): Promise<any> {
        return this.http.post<T>(this.apiURL, entity, { 'headers' : this.getHeaders() }).toPromise();        
    }  
    
    async createAsync(entity: T): Promise<any> {
        return this.http.post<T>(this.apiURL, entity, { 'headers' : this.getHeaders() }).toPromise();        
    } 
    
    update(id: number, entity: T): Promise<any> {
        return this.http.put<T>(this.apiURL+'/'+id, entity, { 'headers' : this.getHeaders() }).toPromise(); 
    }

    async updateAsync(id: number, entity: T): Promise<any> {
        return this.http.put<T>(this.apiURL+'/'+id, entity, { 'headers' : this.getHeaders() }).toPromise(); 
    }

    delete(id: number): Promise<any> {
        return this.http.delete<T>(this.apiURL+'/'+id, { 'headers' : this.getHeaders() }).toPromise();
    }

    async deleteAsync(id: number): Promise<any> {
        return this.http.delete<T>(this.apiURL+'/'+id, { 'headers' : this.getHeaders() }).toPromise();
    }

    find(id: number): Promise<any> {
        return this.http.get<T>(this.apiURL+'/'+id, { 'headers' : this.getHeaders() }).toPromise();
    }

    async findAsync(id: number): Promise<any> {
        return this.http.get<T>(this.apiURL+'/'+id, { 'headers' : this.getHeaders() }).toPromise();
    }

    findAll(): Promise<any> {
        return this.http.get<T[]>(this.apiURL, { 'headers' : this.getHeaders() }).toPromise();
    }

    async findAllAsync(): Promise<any> {
        return this.http.get<T[]>(this.apiURL, { 'headers' : this.getHeaders() }).toPromise();
    }

    getToken(login: Login) : Promise<any> {
        return this.http.post(this.tokenURL, login.toString(), { 'headers' : this.tokenHeaders })
                .toPromise()
                .then(response => {
                   localStorage.setItem("token",JSON.stringify(response));
                   this.getHeaders();
                   return response;
                })
                .catch(err => this.handleError(err));
    }

    refreshToken() : Promise<any> {
        return this.http.post(this.tokenURL,{ "grant_type" : "refresh_token", "refresh_token" : this.token.refresh_token },{ 'headers' : this.tokenHeaders })
        .toPromise()
        .then(response => {
            localStorage.setItem("token",JSON.stringify(response));
            this.getHeaders();
        })
        .catch(err => this.handleError(err));
    }

    private handleError(response: any): Promise<any> {
        return Promise.reject(response);
    }

    public getHeaders() : HttpHeaders {
        this.token = JSON.parse(localStorage.getItem("token"));
        return new HttpHeaders({
            'Content-Type' : 'application/json; charset=UTF-8',
            'Authorization' : 'Bearer '+ (this.token ? this.token.access_token : '')
        }); 
    }

    public getCustomHeaders(enctype: string) : HttpHeaders {
        this.token = JSON.parse(localStorage.getItem("token"));
        return new HttpHeaders({
            'Content-Type' : enctype,
            'Authorization' : 'Bearer '+ (this.token ? this.token.access_token : '')
        }); 
    }

    public getHeadersNoContentType() : HttpHeaders {
        this.token = JSON.parse(localStorage.getItem("token"));
        return new HttpHeaders({
            'Authorization' : 'Bearer '+ (this.token ? this.token.access_token : '')
        }); 
    }

}