<secured>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h3 i18n>Grupo Domínio - Missionária</h3>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">

            <form (ngSubmit)="salvar(grupoDominio)" #gdForm="ngForm" novalidate>
                <div class="card card-default color-pallete-box">
                    <div class="card-header">
                        <h3 class="card-title">
                            <i class="fas fa-globe-americas"></i>
                            <a i18n> Dados do Grupo Domínio </a>
                        </h3>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-12 col-md-3 col-lg-3">
                                <div class="form-group">
                                    <label i18n for="ngd_nome">Nome</label>
                                    <input [(ngModel)]="grupoDominio.nome" name="ngd_nome" type="text" class="form-control" id="ngd_nome" placeholder="Digite o nome" i18n-placeholder>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <button type="submit" class="btn btn-primary float-right" i18n>Salvar</button>
                    </div>
                </div>
            </form>
        </section>
        <section class="content-header" name="gerencia-elemento-grupo-dominio" *ngIf="grupoDominio.id!=null">
            <div class="card card-default color-pallete-box">
                <div class="card-header">
                    <h3 class="card-title">
                        <i class="fas fa-user-lock"></i>
                        <a i18n> Elementos do Grupo Domínio </a>
                    </h3>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title">Adicionar Elemento</h3>
                                </div>
                                <div class="card-body">
                                    <form (ngSubmit)="salvarElemento(elementoGrupoDominio)" #egdForm="ngForm" novalidate>
                                        <div class="row">
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <label i18n for="negd_nome">Nome</label>
                                                    <input [(ngModel)]="elementoGrupoDominio.nome" name="ngd_nome" type="text" class="form-control" id="ngd_nome" placeholder="Digite o nome" i18n-placeholder>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <label i18n for="negd_valor">Valor</label>
                                                    <input [(ngModel)]="elementoGrupoDominio.valor" name="negd_valor" type="text" class="form-control" id="negd_valor" placeholder="Digite o valor" i18n-placeholder>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <label i18n for="negd_valor_alternativo">Valor Alternativo</label>
                                                    <input [(ngModel)]="elementoGrupoDominio.valorAlternativo" name="negd_valor_alternativo" type="text" class="form-control" id="negd_valor_alternativo" placeholder="Digite o valor alternativo" i18n-placeholder>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-group">
                                                    <label i18n for="negd_sequencia">Sequencia</label>
                                                    <input [(ngModel)]="elementoGrupoDominio.sequencia" name="negd_sequencia" type="number" class="form-control" id="negd_sequencia" placeholder="Digite a sequência" i18n-placeholder>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <div class="form-check">
                                                    <input [(ngModel)]="elementoGrupoDominio.valorFixo" name="isvalorfixo" id="isvalorfixo" class="form-check-input" type="checkbox" checked>
                                                    <label for="isvalorfixo" class="form-check-label" i18n>Valor Fixo</label>
                                                </div>
                                            </div>
                                            <div class="col-md-10">
                                                <button type="submit" class="btn btn-primary float-right" i18n>Salvar</button>
                                                <button type="reset" class="btn btn-default float-right" style="margin-right: 0.5rem;" i18n>Limpar</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <table-component [tituloSessao]="'Elementos'" [cols]="6" [rows]="busca.quantityOfElements" [totalDePaginas]="totalDePaginas" (eventoBotao)="cliqueNoBotaoListener($event)">
                                <tr tableheader>
                                    <th style="width: 16.66%;">Nome</th>
                                    <th style="width: 16.66%;">Valor</th>
                                    <th style="width: 16.66%;">Valor Alternativo</th>
                                    <th style="width: 16.66%;">Sequência</th>
                                    <th style="width: 16.66%;">Valor Fixo</th>
                                    <th style="width: 16.66%;" class="text-center"></th>
                                </tr>
                                <tr tablebody *ngFor="let entity of elementosGrupoDominio">

                                    <!-- Exibido sem edição -->

                                    <td style="width: 16.66%;" *ngIf="entity.editar != true">{{entity.nome}}</td>
                                    <td style="width: 16.66%;" *ngIf="entity.editar != true">{{entity.valor}}</td>
                                    <td style="width: 16.66%;" *ngIf="entity.editar != true">{{entity.valorAlternativo}}</td>
                                    <td style="width: 16.66%;" *ngIf="entity.editar != true">{{entity.sequencia}}</td>
                                    <td style="width: 16.66%;" *ngIf="entity.editar != true">{{entity.valorFixo ? 'Sim' : 'Não'}}</td>

                                    <!-- Exibido com edição -->

                                    <td style="width:16.66%;" *ngIf="entity.editar == true"><input [(ngModel)]="entity.nome" name="edegd_nome" type="text" class="form-control" id="ngd_nome" placeholder="Digite o nome" i18n-placeholder></td>
                                    <td style="width:16.66%;" *ngIf="entity.editar == true"><input [(ngModel)]="entity.valor" name="edegd_valor" type="text" class="form-control" id="edegd_valor" placeholder="Digite o valor" i18n-placeholder></td>
                                    <td style="width:16.66%;" *ngIf="entity.editar == true"><input [(ngModel)]="entity.valorAlternativo" name="edegd_valor_alternativo" type="text" class="form-control" id="edegd_valor_alternativo" placeholder="Digite o valor alternativo" i18n-placeholder></td>
                                    <td style="width:16.66%;" *ngIf="entity.editar == true"><input [(ngModel)]="entity.sequencia" name="edegd_sequencia" type="number" class="form-control" id="edegd_sequencia" placeholder="Digite a sequência" i18n-placeholder></td>
                                    <td style="width:16.66%;" class="text-center" *ngIf="entity.editar==true"><input [(ngModel)]="entity.valorFixo" name="edisvalorfixo " id="edisvalorfixo" class="form-check-input" type="checkbox" checked></td>

                                    <td style="width:16.66%;" class="text-center">
                                        <button *ngIf="entity.editar == true" class="btn btn-sm btn-success" style="margin-right:0.5rem;" (click)="entity.editar=false; atualizarElemento(entity);" title="Salvar elemento" alt="Salvar elemento"><i class="fas fa-save"></i></button>
                                        <button *ngIf="entity.editar != true" class="btn btn-sm btn-primary" style="margin-right:0.5rem;" (click)="entity.editar=true;" title="Editar elemento" alt="Editar elemento"><i class="fas fa-edit"></i></button>
                                        <button class="btn btn-sm btn-danger" (click)="deletarElementoGrupoDominio(entity.id)" title="Excluir elemento" alt="Excluir elemento"><i class="fas fa-trash-alt"></i></button>
                                    </td>
                                </tr>
                            </table-component>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</secured>