<aside class="main-sidebar sidebar-light-orange elevation-4">
  <!-- Brand Logo -->
  <a [routerLink]="'/secured/perfil'" class="brand-link navbar-orange">
    <img src="assets/images/logo-comshalom.png" alt="Missionária" class="brand-image img-circle elevation-3"
      style="opacity: .8">
    <span class="brand-text font-weight-strong">MISSIONÁRIA</span>
  </a>
  <div class="sidebar scrollbar">
    <left-sidebar-user-panel-component [thumbnail]="personThumbnail"
      [nome]="person?.nome"></left-sidebar-user-panel-component>
    <nav class="mt-2">
      <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
        <ng-content #menucontent select="[menu-content]"></ng-content>
      </ul>
    </nav>
  </div>
</aside>