import { Injectable } from '@angular/core';
import { MembroComunidade } from '../model/membro.comunidade.model';
import { AbstractBuilder } from './abstract.builder';

@Injectable()
export class MembroComunidadeBuilder extends AbstractBuilder<MembroComunidade> {
  reset(): void {
    this.entity = new MembroComunidade(
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );
  }
}
