import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
export class CadastroCoordenadorColegiadoComponent {
    constructor(coordenadorColegiadoService, missaoConfiguracaoSharedDataService, imagemService, swtAlert) {
        this.coordenadorColegiadoService = coordenadorColegiadoService;
        this.missaoConfiguracaoSharedDataService = missaoConfiguracaoSharedDataService;
        this.imagemService = imagemService;
        this.swtAlert = swtAlert;
        this.unsubscribe$ = new Subject();
        this.coordenadorColegiado = {};
    }
    ngOnInit() {
        this.missaoConfiguracaoSharedDataService.missaoConfiguracao$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
            next: (missaoConfiguracao) => {
                if (missaoConfiguracao) {
                    this.missaoConfiguracao = missaoConfiguracao;
                    this.carregarCoordenadorColegiadoPorMissaoConfiguracaoId(missaoConfiguracao.id);
                }
            },
        });
    }
    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
    selecionarCoordenadorColegiado(pessoa) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.coordenadorColegiado.pessoaId = pessoa.id;
            this.pessoaCoordenadorColegiadoSelecionado = pessoa;
            $('#listagem-pessoa-coordenador-colegiado').modal('hide');
        });
    }
    adicionarCoordenadorColegiado() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.coordenadorColegiado.missaoConfiguracaoId =
                    this.missaoConfiguracao.id;
                const response = yield this.coordenadorColegiadoService
                    .cadastrar(this.coordenadorColegiado)
                    .toPromise();
                this.coordenadorColegiado = response;
                this.pessoaCoordenadorColegiadoSelecionado = undefined;
                this.swtAlert.successAlert('Coordenador de Colegiado cadastrado com sucesso!');
            }
            catch (err) {
                this.swtAlert.errorAlert(err.error.errors);
            }
        });
    }
    carregarCoordenadorColegiadoPorMissaoConfiguracaoId(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield this.coordenadorColegiadoService
                    .obterPorMissaoConfiguracaoId(id)
                    .toPromise();
                if (response) {
                    this.coordenadorColegiado = response;
                    if (this.coordenadorColegiado.imagemId != null) {
                        let responseImage = yield this.imagemService.getPessoaPhoto(this.coordenadorColegiado.imagemId);
                        let blogImage = responseImage.body;
                        this.imagemService.createImage(blogImage, this.coordenadorColegiado);
                    }
                }
            }
            catch (err) {
                this.swtAlert.errorAlert(err.error.errors);
            }
        });
    }
    removerCoordenadorColegiado(coordenadorColegiadoId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (confirm('Deseja remover o coordenador de colegiado?')) {
                try {
                    yield this.coordenadorColegiadoService
                        .remover(coordenadorColegiadoId)
                        .toPromise();
                    this.swtAlert.successAlert('Coordenador de Colegiado removido com sucesso!');
                    this.coordenadorColegiado = {};
                }
                catch (err) {
                    this.swtAlert.errorAlert(err.error.errors);
                }
            }
        });
    }
}
