import { Routes, RouterModule } from '@angular/router';
import { MissaoConfigComponent } from './missao-config.component';

const routes: Routes = [
  {
    path: '',
    component: MissaoConfigComponent,
  },
  {
    path: 'secured/rl/minhamissao',
    loadChildren: () =>
      import('./missao-config.module').then(
        (module) => module.MissaoConfigModule
      ),
  },
];

export const MissaoConfigRoutes = RouterModule.forChild(routes);
