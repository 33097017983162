import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Login } from 'src/app/model/login.model';
import { GenericService } from 'src/app/core/services/generic.service';
import { Router } from '@angular/router';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';



@Injectable()
export class LoginService extends GenericService<Login> {

    constructor(
        protected http: HttpClient, 
        protected router: Router,
        protected swtAlert2Service: SwtAlert2Service
    ) 
    {
        super(http,router);
    }

    async  fazerLogin(login: Login) : Promise<any> {
        try
        {
            localStorage.clear();
            sessionStorage.clear();
            let response = await this.getToken(login);
            // this.router.navigate(['/', 'secured']);
            window.location.replace("secured");
        }
        catch(e)
        {
            if ( e.error.error === "unauthorized")
                this.swtAlert2Service.errorAlert('Acesso Negado' + ' - ' + e.error.error_description);
            else
                this.swtAlert2Service.errorAlert(e.error.error + ' - ' + e.error.error_description);
            console.log(e);
        }
    }

    fazerLogout() : void {
        localStorage.clear();
        sessionStorage.clear();
        this.token = undefined;
        this.router.navigate(['/', 'login']);
        //window.location.replace("/");
    }

}