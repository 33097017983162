import { SafeUrl } from '@angular/platform-browser';
import { VinculoDifusao } from './vinculodifusao.model';
import { VinculoMissao } from './vinculomissao.model';

export class Pessoa {

    constructor(
        public id: number,
        public nome: string,
        public sobrenome: string,
        public genero: string,
        public dataNascimento: Date,
        public nacionalidadeId: number,
        public enderecoId: number,
        public imagemId: number,
        public documentosId: Array<number>,
        public parentescosId: Array<number>,
        public usuarioId: number,
        public usuarioUsername: string,
        public telefones: Array<string>,
        public estadoCivilId: number,
        public estadoCivilNome: string,
        public formaVidaId: number,
        public formaVidaNome: string,
        public formaVidaValor: string,
        public estadoVidaId: number,
        public estadoVidaNome: string,
        public estadoVidaValor: string,
        public statusId: number,
        public statusNome: string,
        public statusValor: string,
        public obraId: number,
        public membroId: number,
        public aptidaoId: number,
        public trabalhoIds: Array<number>,
        public thumbnail: SafeUrl,
        public vinculoMissao: VinculoMissao,
        public vinculoDifusao: VinculoDifusao,
        public registroDuplicado: boolean
    ){}

}