import { Injectable } from '@angular/core';
import { Saude } from '../model/saude.model';
import { AbstractBuilder } from './abstract.builder';

@Injectable()
export class SaudeBuilder extends AbstractBuilder<Saude> {

    reset() : void {
        this.entity = new Saude(null,null,null,null,null,null,null,null,0,0);
    }

}