import { Injectable } from '@angular/core';
import { HistoricoMissionarioMissaoApostolado } from '../model/historicomissionariomissaoapostolado.model';
import { AbstractBuilder } from './abstract.builder';

@Injectable()
export class HistoricoMissionarioMissaoApostoladoBuilder extends AbstractBuilder<HistoricoMissionarioMissaoApostolado> {

    reset() : void {
        this.entity = new HistoricoMissionarioMissaoApostolado(null,null,null,null,0,0,false,null,false);
    }

}