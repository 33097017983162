import { RouterModule } from '@angular/router';
import { MissaoConfigComponent } from './missao-config.component';
const ɵ0 = () => import("./missao-config.module.ngfactory").then((module) => module.MissaoConfigModuleNgFactory);
const routes = [
    {
        path: '',
        component: MissaoConfigComponent,
    },
    {
        path: 'secured/rl/minhamissao',
        loadChildren: ɵ0,
    },
];
export const MissaoConfigRoutes = RouterModule.forChild(routes);
export { ɵ0 };
