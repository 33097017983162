export class FormadorAssistente {

    constructor(
        public id: number,
        public tipo: number, //1 - Casa Comunitária | 2 - Celula
        public casacelulaId: number,
        public pessoaId: number,
        public pessoaNome: string,
        public pessoaImagemId: number,
        public thumbnail: string,
        public dataEntrada: Date,
        public dataSaida: Date,
        public motivoSaidaId: number,
        public obsSaida: string,
        public editando: boolean
    )
    {}

}