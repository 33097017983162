<secured>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h3 i18n>Listagem dos Grupos De Oração</h3>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <form (ngSubmit)="buscarGrupoDeOracao()" #permissoesForm="ngForm" novalidate>
                            <div class="card card-gray-dark color-pallete-box">
                                <div class="card-header">
                                    <h3 class="card-title">Buscar Grupos De Oração</h3>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label i18n for="lcit_nome">Nome</label>
                                                <input [(ngModel)]="busca.nome" name="lcit_nome" type="text" class="form-control" id="lcit_nome" placeholder="Digite o nome" i18n-placeholder>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label i18n for="ngo_dtfund">Ano De Inicio</label>
                                                <input [(ngModel)]="busca.ano" name="ncgo_dtfund" type="number" class="form-control" id="ngo_dtfund" i18n-placeholder>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label i18n for="ngo_fase">Fase Do Grupo</label>
                                                <select class="form-control" id="ngo_fase" [(ngModel)]="busca.faseId" name="ngo_fase">
                                                    <option [value]="null" i18n>Selecione uma Fase</option>
                                                    <option *ngFor="let fase of fases" [value]="fase.id">{{fase.nome}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label i18n for="ngo_publico">Público</label>
                                                <select class="form-control" id="ngo_publico" [(ngModel)]="busca.publicoId" name="ngo_publico">
                                                    <option [value]="null" i18n>Selecione um público</option>
                                                    <option *ngFor="let publico of publicos" [value]="publico.id">{{publico.nome}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label i18n for="ngo_r">Regional</label>
                                                <select class="form-control" id="ngo_r" [(ngModel)]="busca.regionalId" (change)="carregarMissoes(busca.regionalId); carregarDifusoes(busca.regionalId);" name="ngo_r">
                                                    <option [value]="null" i18n>Selecione um Regional</option>
                                                    <option *ngFor="let reg of regionais" [value]="reg.id">{{reg.nome}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label i18n for="ngo_missao">Missão</label>
                                                <select class="form-control" id="ngo_missao" [(ngModel)]="busca.missaoId" (change)="busca.difusaoId = null; carregarCevPorMissao(busca.missaoId);" name="ngo_missao">
                                                    <option [value]="null" i18n>Selecione uma Missão</option>
                                                    <option *ngFor="let missao of missoes" [value]="missao.id">{{missao.nome}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label i18n for="ngo_difusao">Difusão</label>
                                                <select class="form-control" id="ngo_difusao" [(ngModel)]="busca.difusaoId" (change)="busca.missaoId = null; carregarCevPorDifusao(busca.difusaoId);" name="ngo_difusao">
                                                    <option [value]="null" i18n>Selecione uma Difusão</option>
                                                    <option *ngFor="let difusao of difusoes" [value]="difusao.id">{{difusao.nome}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label i18n for="ngo_cev">Centro De Evangelização</label>
                                                <select class="form-control" id="ngo_cev" [(ngModel)]="busca.centroEvangelizacaoId" name="ngo_cev">
                                                    <option [value]="null" i18n>Selecione um centro de Evangelização</option>
                                                    <option *ngFor="let cev of centrosEvangelizacao" [value]="cev.id">{{cev.nome}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label i18n for="ngo_pais">País</label>
                                                <select class="form-control" id="ngo_pais" [(ngModel)]="busca.paisId" (change)="carregarEstados(busca.paisId)" name="ngo_pais">
                                                    <option [value]="null" i18n>Selecione um País</option>
                                                    <option *ngFor="let pais of paises" [value]="pais.id">{{pais.nome}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label i18n for="ngo_estado">Estado</label>
                                                <select class="form-control" id="ngo_estado" [(ngModel)]="busca.estadoId" name="ngo_estado">
                                                    <option [value]="null" i18n>Selecione um Estado</option>
                                                    <option *ngFor="let estado of estados" [value]="estado.id">{{estado.nome}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label i18n for="ngo_encerrado">&nbsp;</label><br>
                                                <div class="form-check">
                                                    <input [(ngModel)]="busca.grupoEncerrado" name="isGrupoEncerrado" id="isGrupoEncerrado" class="form-check-input" type="checkbox">
                                                    <label for="isGrupoEncerrado" class="form-check-label" i18n>Grupo Encerrado?</label>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="card-footer">
                                    <button type="submit" class="btn btn-primary float-right" i18n>Buscar</button>
                                    <button type="reset" class="btn btn-default float-right" style="margin-right: 0.5rem;" i18n>Limpar</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">

                        <table-component [tituloSessao]="'Grupos De Oração'" [cols]="6" [rows]="busca.quantityOfElements" [totalDePaginas]="totalDePaginas" (eventoBotao)="cliqueNoBotaoListener($event)">
                            <tr tableheader>
                                <th style="width: 10px">#</th>
                                <th>Nome</th>
                                <th>Fase</th>
                                <th>Público</th>
                                <th>Centro De Evangelização</th>
                                <th></th>
                            </tr>
                            <tr tablebody *ngFor="let go of gruposDeOracao">
                                <td>{{go.id}}</td>
                                <td>{{go.nome}}</td>
                                <td>{{go.faseGrupoDeOracaoNome}}</td>
                                <td>{{go.publicoGrupoDeOracaoNome}}</td>
                                <td>{{go.centroEvangelizacaoNome}}</td>
                                <td class="text-center">
                                    <button class="btn btn-sm btn-primary ml-1" routerLink="../grupodeoracao/{{go.id}}" title="Editar grupo de oração" alt="Editar grupo de oração"><i class="fas fa-edit"></i></button>
                                    <button class="btn btn-sm btn-danger ml-1" (click)="deletarGrupoDeOracao(go.id)" title="Deletar grupo de oração" alt="Deletar grupo de oração"><i class="fas fa-trash-alt"></i></button>
                                </td>
                            </tr>
                        </table-component>
                    </div>
                </div>
            </div>
        </section>
    </div>
</secured>