import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CadastroPublicoComponent } from './cadastro.publico.component';
import { ConfirmacaoCadastroComponent } from './confirmacao.cadastro.component';

const cadastroPublicoRoutes: Routes = [
    {
        path: 'cadastro',
        component: CadastroPublicoComponent
    },
    {
        path: 'confirmacaoCadastro',
        component: ConfirmacaoCadastroComponent
    }
]

@NgModule({
    imports: [
        RouterModule.forChild(cadastroPublicoRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class CadastroPublicoRoutingModule {

}