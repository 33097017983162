<secured>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h3 i18n>Ministério</h3>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">

            <form (ngSubmit)="salvar(ministerio)" #ministerioForm="ngForm" novalidate>
                <div class="card card-default color-pallete-box">
                    <div class="card-header">
                        <h3 class="card-title">
                            <i class="fas fa-globe-americas"></i>
                            <a i18n> Dados do Ministério </a>
                        </h3>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-12 col-md-4 col-lg-4">
                                <div class="form-group">
                                    <label i18n for="nministerio_nome">Nome</label>
                                    <input [(ngModel)]="ministerio.nome" name="nministerio_nome" type="text" class="form-control" id="nministerio_nome" placeholder="Digite o nome do Ministério" i18n-placeholder>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-4 col-lg-4">
                                <div class="form-group">
                                    <label i18n for="nministerio_dtfund">Data de Fundação</label>
                                    <input [(ngModel)]="ministerio.dataFundacao" name="nministerio_dtfund" type="date" class="form-control" id="nministerio_dtfund" i18n-placeholder>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-4 col-lg-4">
                                <div class="form-group">
                                    <label i18n for="nministerio_r">Regional</label>
                                    <select class="form-control" id="nministerio_r" [(ngModel)]="ministerio.regionalId" (change)="carregarMissoes(ministerio.regionalId)" name="nministerio_r">
                                        <option [value]="null" i18n>Selecione um Regional</option>
                                        <option *ngFor="let reg of regionais" [value]="reg.id">{{reg.nome}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-4 col-lg-4">
                                <div class="form-group">
                                    <label i18n for="nministerio_missao">Missão</label>
                                    <select class="form-control" id="nministerio_missao" [(ngModel)]="ministerio.missaoId" (change)="carregarCev(ministerio.missaoId)" name="nministerio_missao">
                                        <option [value]="null" i18n>Selecione uma Missão</option>
                                        <option *ngFor="let missao of missoes" [value]="missao.id">{{missao.nome}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-4 col-lg-4">
                                <div class="form-group">
                                    <label i18n for="nministerio_cev">Centro de Evangelização</label>
                                    <select class="form-control" id="nministerio_cev" [(ngModel)]="ministerio.centroEvangelizacaoId" name="nministerio_cev">
                                        <option [value]="null" i18n>Selecione um centro de Evangelização</option>
                                        <option *ngFor="let cev of centrosEvangelizacao" [value]="cev.id">{{cev.nome}}</option>
                                    </select>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="card-footer">
                        <button type="submit" class="btn btn-primary float-right" i18n>Salvar</button>
                    </div>
                </div>
            </form>
        </section>

    </div>
</secured>