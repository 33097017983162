import { GenericService } from 'src/app/core/services/generic.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from "@angular/core";
import { Documento } from 'src/app/model/documento.model';

@Injectable()
export class DocumentoService extends GenericService<Documento> {
    
    constructor(protected http: HttpClient, protected router:Router) {
        super(http,router);
        this.setEntityType('r/documento');
    }    

    buscarPorPessoaId(id: number): Promise<Documento[]> {
        return this.http.get<Documento[]>(this.apiURL+'/porpessoaid/'+id, { 'headers' : this.getHeaders() }).toPromise();
    }

} 