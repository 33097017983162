import * as tslib_1 from "tslib";
import { GenericService } from 'src/app/core/services/generic.service';
import { environment } from 'src/environments/environment';
export class CelulaService extends GenericService {
    constructor(http, router) {
        super(http, router);
        this.http = http;
        this.router = router;
        this.setEntityType('r/celula');
    }
    buscarCelulas(busca) {
        return this.http
            .post(this.apiURL + '/buscar', JSON.stringify(busca), {
            headers: this.getHeaders(),
        })
            .toPromise();
    }
    getAllNotInAcessoUsuarioEstrutura(id) {
        return this.http
            .get(`${this.apiURL}/getallnotinacessousuarioestrutura/${id}`, {
            headers: this.getHeaders(),
        })
            .toPromise();
    }
    getAllFromAcessoUsuarioEstrutura(id) {
        return this.http
            .get(`${this.apiURL}/getallfromacessousuarioestrutura/${id}`, {
            headers: this.getHeaders(),
        })
            .toPromise();
    }
    criarLinkCadastroCelula(request) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http
                .post(`${environment.moduloFormacao.urlModuloFormacao}celula/criarlinkcadastro`, JSON.stringify(request), { headers: this.getHeaders() })
                .toPromise();
        });
    }
    obterLinkCadastroCelula(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.http
                .get(`${environment.moduloFormacao.urlModuloFormacao}celula/obterlinkcadastro/${id}`, { headers: this.getHeaders() })
                .toPromise();
        });
    }
    /**
     * Formadores Comunitários
     */
    cadastrarFormadorComunitario(data) {
        return this.http
            .post(`${this.apiURL}/cadastrar/formadorcomunitario`, JSON.stringify(data), { headers: this.getHeaders() })
            .toPromise();
    }
    listarFormadorComunitarioPorCelulaId(id) {
        return this.http
            .get(`${this.apiURL}/${id}/formadorescomunitarios`, {
            headers: this.getHeaders(),
        })
            .toPromise();
    }
    deletarFormadorComunitario(id) {
        return this.http
            .delete(`${this.apiURL}/deletar/formadorcomunitario/${id}`, {
            headers: this.getHeaders(),
        })
            .toPromise();
    }
    atualizarFormadorComunitario(data) {
        return this.http
            .put(`${this.apiURL}/atualizar/formadorcomunitario`, JSON.stringify(data), { headers: this.getHeaders() })
            .toPromise();
    }
    /**
     * Formadores Assistentes
     */
    cadastrarFormadorAssistente(data) {
        return this.http
            .post(`${this.apiURL}/cadastrar/formadorassistente`, JSON.stringify(data), { headers: this.getHeaders() })
            .toPromise();
    }
    listarFormadorAssistentePorCelulaId(id) {
        return this.http
            .get(`${this.apiURL}/${id}/formadoresassistentes`, {
            headers: this.getHeaders(),
        })
            .toPromise();
    }
    deletarFormadorAssistente(id) {
        return this.http
            .delete(`${this.apiURL}/deletar/formadorassistente/${id}`, {
            headers: this.getHeaders(),
        })
            .toPromise();
    }
    atualizarFormadorAssistente(data) {
        return this.http
            .put(`${this.apiURL}/atualizar/formadorassistente`, JSON.stringify(data), { headers: this.getHeaders() })
            .toPromise();
    }
}
