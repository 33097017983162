import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SecuredModule } from '../../layout/secured.module';
import { RouterModule } from '@angular/router';
import { AceiteTermoTratamentoDadosComponent } from './aceitetermotratamentodados.component';
import { AceiteTermoTratamentoDadosRoutingModule } from './aceitetermotratamentodados-routing.module';
import { NgxSummernoteModule } from 'ngx-summernote';
import { AceiteTermoTratamentoDadosService } from './service/aceitetermotratamentodados.service';
import { AceiteTermoTratamentoDadosBuilder } from 'src/app/builder/aceitetermotratamentodados.builder';

@NgModule({
    declarations: [ 
        AceiteTermoTratamentoDadosComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        AceiteTermoTratamentoDadosRoutingModule,
        NgxSummernoteModule,
        FormsModule,
        SecuredModule
    ],
    providers: [
        AceiteTermoTratamentoDadosService,
        AceiteTermoTratamentoDadosBuilder
    ]
})
export class AceiteTermoTratamentoDadosModule {
}