import { Component, OnInit } from '@angular/core';
import { GrupoDominioLocalService } from './service/grupodominio.local.service';
import { GrupoDominio } from 'src/app/model/grupodominio.model';
import { GrupoDominioBuilder } from 'src/app/builder/grupodominio.builder';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';

@Component({
    selector: 'grupodominiolocal',
    templateUrl: './grupodominio.local.component.html'
})
export class GrupoDominioLocalComponent implements OnInit {

    public grupoDominio: GrupoDominio;
    public grupoDominioBuilder: GrupoDominioBuilder;

    constructor(
        public grupoDominioService: GrupoDominioLocalService,
        public swtAlert2Service: SwtAlert2Service
    ) 
    {
        this.grupoDominioBuilder = new GrupoDominioBuilder();
        this.grupoDominio = this.grupoDominioBuilder.getInstance();
    }

    ngOnInit() {        
    }

    salvar(grupoDominio: GrupoDominio) : void {

        this.grupoDominioService.create(grupoDominio).then( (response:any) => {
            this.grupoDominio = new GrupoDominio(null,null,[]);
            this.swtAlert2Service.successAlert(response.message);

        }).catch( (err:any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

}