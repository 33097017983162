import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Token } from "src/app/model/token.model";
import { environment } from "src/environments/environment";

@Injectable()
export class ArquivoService {
  private token: Token;

  constructor(protected http: HttpClient) {}

  obterDetalhesArquivoPDF(nomeArquivo: string) {
    return this.http.get<Blob>(
      `${environment.moduloFormacao.urlModuloFormacao}arquivo/pdf/detalhes/${nomeArquivo}`,
      {
        headers: this.getHeaders(),
        responseType: "blob" as "json",
      }
    );
  }

  private getHeaders(): HttpHeaders {
    this.token = JSON.parse(localStorage.getItem("token"));
    return new HttpHeaders({
      Authorization: "Bearer " + (this.token ? this.token.access_token : ""),
    });
  }
}
