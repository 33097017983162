import { Component, OnInit } from '@angular/core';
import { MissaoService } from './service/missao.service';
import { Missao } from 'src/app/model/missao.model';
import { Router, ActivatedRoute } from '@angular/router';
import { Regional } from 'src/app/model/regional.model';
import { Pais } from 'src/app/model/pais.model';
import { Estado } from 'src/app/model/estado.model';
import { Cidade } from 'src/app/model/cidade.model';
import { ElementoGrupoDominio } from 'src/app/model/elementogrupodominio.model';
import { RegionalService } from '../regional/service/regional.service';
import { ElementoGrupoDominioService } from '../grupodominio/service/elementogrupodominio.service';
import { PaisService } from '../pais/service/pais.service';
import { EstadoService } from '../estado/service/estado.service';
import { CidadeService } from '../cidade/service/cidade.service';
import { MissaoBuilder } from 'src/app/builder/missao.builder';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { Irradiacao } from 'src/app/model/irradiacao.model';
import { IrradiacaoService } from '../irradiacao/service/irradiacao.service';

@Component({
    selector: 'missao-edit',
    templateUrl: './missao.edit.component.html'
})
export class MissaoEditComponent implements OnInit {

    public missaoId: number;
    public missao: Missao;
    public regionais: Array<Regional>;
    public paises: Array<Pais>;
    public estados: Array<Estado>;
    public cidades: Array<Cidade>;
    public paisSelecionadoId: number;
    public estadoSelecionadoId: number;
    public fasesMissao: Array<ElementoGrupoDominio>;
    public statusMissao: Array<ElementoGrupoDominio>;
    public missaoBuilder: MissaoBuilder;
    public entities: Array<Irradiacao>;
    public busca: any;
    public totalDePaginas: number;

    constructor(
        public irradiacaoService: IrradiacaoService,
        public missaoService: MissaoService,
        public regionalService: RegionalService,
        public elementoGrupoDominioService: ElementoGrupoDominioService,
        public paisService: PaisService,
        public estadoService: EstadoService,
        public cidadeService: CidadeService,
        public swtAlert2Service: SwtAlert2Service,
        public route: ActivatedRoute,
        public router: Router
    ) 
    {
        this.missaoBuilder = new MissaoBuilder();
        this.missao = this.missaoBuilder.getInstance();
        this.regionais = new Array<Regional>();
        this.paisSelecionadoId = null;
        this.estadoSelecionadoId = null;
        this.paises = new Array<Pais>();
        this.estados = new Array<Estado>();
        this.cidades = new Array<Cidade>();
        this.fasesMissao = new Array<ElementoGrupoDominio>();  
        this.statusMissao = new Array<ElementoGrupoDominio>();    
        this.entities = new Array<Irradiacao>();      
        this.busca =  {
            pageNumber : 0, 
            quantityOfElements : 10
        }
    }

    ngOnInit() {  
        this.missaoId = Number(this.route.snapshot.paramMap.get('id'));
        this.initialize();
    }

    async initialize() : Promise<null> {

        await this.carregarRegionais();
        await this.carregarFasesMissao();
        await this.carregarStatusMissao();
        await this.carregarMissao(this.missaoId);
        this.carregarPaises();
        this.carregarEstados();
        this.carregarCidades();
        this.buscar();
        
        return null;
    }

    buscar() : void {

        this.busca = {
            regionalId: null,
            missaoId: this.missaoId,
            paisId: null,
            estadoId: null,
            cidadeId: null,
            nomeIrradiacao: null,
            dataFundacao: Date,             
            pageNumber : 0, 
            quantityOfElements : 10
        };

        this.irradiacaoService.buscar(this.busca).then( (response: any) => {
            this.entities = response.entity.content;
            this.totalDePaginas = response.entity.totalPages;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    cliqueNoBotaoListener(botao) {
        this.busca.pageNumber = botao.numero-1;
        this.buscar();
    }
    
    deletar(id: number) : void {

        if(confirm('Deseja deletar esta irradiacao?'))
        {
            this.irradiacaoService.delete(id)
            .then( (response: any) => {
                this.swtAlert2Service.successAlert(response.message);
                this.buscar();
            })
            .catch((err) => {
                this.swtAlert2Service.errorAlert(err.error.errors);
            });
        }
    }

    async carregarMissao(id: number): Promise<Missao> {

        try
        {
                let response = await this.missaoService.find(id);
                this.missao = response.entity;
                this.paisSelecionadoId = response.entity.cidadeEstadoPaisId;
                this.carregarEstados();
        }
        catch(err)
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }

        return null;
    }

    async carregarRegionais() : Promise<null> {

        try
        {
            let response = await this.regionalService.findAll();
            this.regionais = response.entity;
        }
        catch(err)
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }

        return null;
    }

    async carregarFasesMissao() : Promise<null> {

        try{
            let buscaFase = {
                grupoNome : 'FASE_MISSAO'
            }
            let response = await this.elementoGrupoDominioService.buscarPorGrupoDominioNome(buscaFase);
            this.fasesMissao = response.entity;

        }
        catch(err){
            this.swtAlert2Service.errorAlert(err.error.errors);
        }

        return null;
    }

    async carregarStatusMissao() : Promise<null> {

        try{
            let request = {
                grupoNome : 'MISSAO_STATUS'
            }
            let response = await this.elementoGrupoDominioService.buscarPorGrupoDominioNome(request);
            this.statusMissao = response.entity;

        }
        catch(err){
            this.swtAlert2Service.errorAlert(err.error.errors);
        }

        return null;
    }

    carregarPaises() : void {

        this.paisService.findAll().then((lista: any) => {
            this.paises = lista.entity;
            this.carregarMissao(this.missaoId);
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
       
    }

    carregarEstados() : void {

            let busca = {
                paisId: this.paisSelecionadoId,
                nome: undefined,
                pageNumber : 0, 
                quantityOfElements : 1000
            };
            this.estadoService.buscarEstados(busca).then((lista: any) => {
                this.estados = lista.entity;
                this.carregarCidades();
            }).catch((err: any) => {
                this.swtAlert2Service.errorAlert(err.error.errors);
                console.log(err);
            });       
    }

    carregarCidades() : void {

        let busca = {
            estadoId: this.estadoSelecionadoId,
            nome: undefined,
            pageNumber : 0, 
            quantityOfElements : 1000
        };

        this.cidadeService.buscarCidades(busca).then( (lista: any) => {
            this.cidades = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });

    }

    salvar(missao: Missao) : void {

        missao.dataFundacao = new Date(missao.dataFundacao);

        this.missaoService.update(missao.id,missao).then( (response:any) => {
            this.missao = this.missaoBuilder.getInstance();
            this.paisSelecionadoId = null;
            this.estadoSelecionadoId = null;
            this.swtAlert2Service.successAlert(response.message);
            setTimeout(() => {
                this.router.navigate(['../secured/missoes']);
            }, 2000);
        }).catch( (err:any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }


}