<secured>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h3 i18n>Parecer do Formador Comunitário 1º Questionario | Pastoreio | CAL</h3>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="card card-primary card-outline card-outline-tabs">
                <div class="card-header p-0 border-bottom-0">
                    <ul class="nav nav-tabs" id="tabs-questionario-form" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" id="dados-pessoais-tab" data-toggle="pill"
                                href="#dados-pessoais-tab-content" role="tab" aria-controls="dados-pessoais-tab"
                                aria-selected="true">Dados Pessoais Formando</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="questionario-tab" data-toggle="pill"
                                href="#questionario-tab-content" role="tab" aria-controls="questionario-tab"
                                aria-selected="false">Questionário</a>
                        </li>
                    </ul>
                </div>
                <div class="card-body">
                    <div class="tab-content" id="tabs-pessoa-formContent">
                        <div class="tab-pane fade show active" id="dados-pessoais-tab-content" role="tabpanel"
                            aria-labelledby="dados-pessoais-tab">
                            <form #dadosPessoaisForm="ngForm" (ngSubmit)="salvar()" novalidate>
                                <div class="row">
                                    <div *ngIf="(formando.id != undefined)" class="col-sm-3 col-md-3 col-lg-3">
                                        <div style="padding: 1rem 0rem 1rem 1rem;">
                                            <div class="card card-default">
                                                <div class="card-body">
                                                    <div class="text-center" style="margin: 1rem auto 2rem;">
                                                        <div class="widget-user-image">
                                                            <img class="profile-user-img img-fluid img-circle elevation-2"
                                                                [src]="formando.thumbnail == null ? 'assets/images/ppl.png' : formando.thumbnail"
                                                                alt="Imagem da pessoa" style="width: auto;">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        [ngClass]="(formando.id != undefined) ? 'col-sm-8 col-md-9 col-lg-9' : 'col-sm-12 col-md-12 col-lg-12'">
                                        <div style="padding: 1rem;">
                                            <div class="card card-default">
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md-12 col-lg-12">
                                                            <div class="form-group">
                                                                <label i18n for="nformando_nome">Nome</label>
                                                                <br>
                                                                {{formando.nome}} {{formando.sobrenome}}
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-6 col-lg-6">
                                                            <div class="form-group">
                                                                <label i18n for="npessoa_dtnas">Data de
                                                                    Nascimento</label>
                                                                <br>
                                                                {{formando.dataNascimento}}
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-6 col-lg-6">
                                                            <div class="form-group">
                                                                <label i18n for="nformando_missao_atual">Missão
                                                                    Atual</label>
                                                                <select disabled class="form-control"
                                                                    id="nformando_missao_atual"
                                                                    [(ngModel)]="formando.vinculoMissao.missaoAtualId"
                                                                    name="nformando_missao_atual">
                                                                    <option disabled value="null" i18n>Selecione uma
                                                                        missão</option>
                                                                    <option *ngFor="let missao of missoesVinculo"
                                                                        [value]="missao.id">{{missao.nome}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-8 col-lg-8">
                                                            <div class="form-group">
                                                                <div class="checkbox icheck-concrete">
                                                                    <input [(ngModel)]="dadoFormandoCA.ja_permaneceu"
                                                                        disabled type="checkbox" name="nja_permaneceu"
                                                                        id="nja_permaneceu" />&nbsp;
                                                                    <label class="form-check-label"
                                                                        for="nja_permaneceu">Você já permaneceu em algum
                                                                        Nível Formativo?</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                                            <div class="form-group"
                                                                *ngIf="dadoFormandoCA.ja_permaneceu">
                                                                <label i18n for="nnivel_permaneceu">Em qual nível
                                                                    permaneceu?</label>
                                                                <select disabled class="form-control" name="nivel_form"
                                                                    [(ngModel)]="dadoFormandoCA.nivel_permaneceu">
                                                                    <option disabled value="null">Selecione um nível de
                                                                        formação
                                                                    </option>
                                                                    <option *ngFor="let nivelFormacao of niveisFormacao"
                                                                        [value]="nivelFormacao.id">
                                                                        {{nivelFormacao.nome}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-8">
                                                            <div class="form-group">
                                                                <label for="form_pessoal">Formador Pessoal</label><br>
                                                                {{pessoaFormadorPessoalSelecionado?.nome}}&nbsp;{{pessoaFormadorPessoalSelecionado?.sobrenome}}
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                                            <div class="form-group">
                                                                <label i18n for="nform_pessoais">Nº de Formações
                                                                    Pessoais</label><br>
                                                                {{caminhoFormativoFormando.qtde_formacao_pessoal}}
                                                            </div>
                                                        </div>
                                                        <div class="col-8">
                                                            <div class="form-group">
                                                                <label for="form_comunitario">Formador
                                                                    Comunitário</label>
                                                                <br>
                                                                {{caminhoFormativoFormando.nome_formador_comunitario}}
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                                            <div class="form-group">
                                                                <label i18n for="nacomp_comunitario">Nº de
                                                                    Acompanhamentos Comunitários</label>
                                                                <br>
                                                                {{caminhoFormativoFormando.qtde_acompanhamento_comunitario}}
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-6 col-lg-6">
                                                            <div class="form-group">
                                                                <label i18n for="nacompanhadoPor">Quem o acompanha
                                                                    comunitariamente.</label>
                                                                <select disabled class="form-control"
                                                                    id="nacompanhadoPor" name="nacompanhadoPor"
                                                                    [(ngModel)]="caminhoFormativoFormando.acompanhado_por"
                                                                    required>
                                                                    <option value="1" i18n>Formador Comunitário</option>
                                                                    <option value="2">Formador Assistente</option>
                                                                    <option value="3">Coordenador do Colegiado</option>
                                                                    <option value="4">Responsável Local</option>
                                                                    <option value="5">Outro Missionário</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-6 col-lg-6">
                                                            <div class="form-group">
                                                                <label i18n for="nform_pessoais">Situação
                                                                    profissional</label>
                                                                <select disabled class="form-control"
                                                                    id="nsituacao_profissional"
                                                                    name="nsituacao_profissional"
                                                                    [(ngModel)]="dadoFormandoCA.situacao_profissional"
                                                                    required>
                                                                    <option value="0" i18n>Nenhuma das opções</option>
                                                                    <option value="1">Trabalha</option>
                                                                    <option value="2">Estuda</option>
                                                                    <option value="3">Aposentado</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-6 col-lg-6">
                                                            <div class="form-group">
                                                                <label i18n for="npessoa_sobrenome">Função de
                                                                    autoridade apostolica.</label><br>
                                                                {{caminhoFormativoFormando.funcao_autoridade_apostolica}}
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-6 col-lg-6">
                                                            <div class="form-group">
                                                                <div class="checkbox icheck-concrete">
                                                                    <input disabled
                                                                        [(ngModel)]="dadoFormandoCA.alianca_missionaria"
                                                                        type="checkbox" name="nalianca_missionaria"
                                                                        id="nalianca_missionaria" />&nbsp;
                                                                    <label class="form-check-label"
                                                                        for="nalianca_missionaria">Está atualmente como
                                                                        Aliança Missionária?</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-6 col-lg-6"
                                                            *ngIf="dadoFormandoCA.alianca_missionaria">
                                                            <div class="form-group">
                                                                <label i18n for="nform_pessoais">Casa Comunitária onde
                                                                    está.</label><br>
                                                                {{dadoFormandoCA.casa_comunitaria_desc}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card-footer text-right">
                                                    <button type="submit" class="btn"
                                                        [ngClass]="formando.id != undefined ? 'btn-primary' : 'btn-success'"
                                                        [disabled]="!dadosPessoaisForm.valid" i18n>Salvar</button>&nbsp;
                                                    <button type="button" class="btn btn-default"
                                                        (click)="activateTab('questionario-tab-content')"
                                                        *ngIf="formando.id != undefined">Proximo</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="tab-pane fade" id="questionario-tab-content" role="tabpanel"
                            aria-labelledby="questionario-tab">
                            <form *ngIf="formando.id != undefined" #questionarioForm="ngForm"
                                (ngSubmit)="salvarQuestionario()" novalidate>
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <h4 class="card-title">A partir de agora responda com sinceridade e
                                            transparência as seguintes perguntas, ajudando a
                                            Comunidade na formação dos irmãos da CAL.
                                        </h4>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <div class="form-group">
                                            <label for="descricao">1. O Seu formando recebeu o feedback do pastoreio de
                                                2023?</label>
                                            <select class="form-control" id="nresposta21" name="nresposta21"
                                                [(ngModel)]="resposta21.resposta" required>
                                                <option value="1" i18n>Sim</option>
                                                <option value="2">Não</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <div class="form-group">
                                            <label for="descricao">2. Como você percebe que o seu formando recebeu o
                                                feedback de pastoreio? Como você acredita que ele se sentiu?</label>
                                            <select class="form-control" id="nresposta22" name="nresposta22"
                                                [(ngModel)]="resposta22.resposta" required>
                                                <option value="1" i18n>Acredito que se sentiu bem,
                                                    conhecido pela comunidade e concordou com os
                                                    pontos que precisa trabalhar.</option>
                                                <option value="2">Acredito que ele ficou surpreso,
                                                    não esperava. Mas acolheu bem, concordou com
                                                    o que foi dito.
                                                </option>
                                                <option value="3">Acredito que não se sentiu confortável
                                                    com o que recebeu, não se percebe da forma como
                                                    foi dito.
                                                </option>
                                                <option value="4">Acredito que concordou apenas parcialmente.
                                                    Percebo que para ele foi dificil acolher.</option>
                                                <option value="5">Eu não consegui dar o feedback do meu
                                                    formando.</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <div class="form-group">
                                            <label for="descricao">3. Você acredita que o teu formando se sente ajudado
                                                no seu caminho de formação?</label>
                                            <select class="form-control" id="nresposta23" name="nresposta23"
                                                [(ngModel)]="resposta23.resposta" required>
                                                <option value="1" i18n>Sim, percebo que ele se sente ajudado
                                                    pelas suas autoridades.</option>
                                                <option value="2">Acredito que ele se sente ajudado,
                                                    mas precisa buscar mais ajuda, pedir,
                                                    se expressar mais..
                                                </option>
                                                <option value="3">Acredito que ele não se sente ajudado,
                                                    penso que ele evita buscar ajuda.
                                                </option>
                                                <option value="4">Acredito que ele não se sente ajudado.</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <div class="form-group">
                                            <label for="descricao">4. Em que aspectos você acredita que o teu formando
                                                tem mais necessidade de ser ajudado?</label>
                                            <input [(ngModel)]="resposta24.resposta" type="text" id="resposta24"
                                                name="resposta24" required class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                        <div class="form-group">
                                            <label for="descricao">5. Como você percebe a sua vivência dos conselhos
                                                evangelicos:</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <div class="form-group">
                                            <label for="descricao">5.1 Como você percebe a sua vivência da
                                                pobreza?</label>
                                            <select class="form-control" id="nresposta25" name="nresposta25"
                                                [(ngModel)]="resposta25.resposta" required>
                                                <option value="1" i18n>Acredito que vive bem.</option>
                                                <option value="2">Acredito que vive bem, mas ainda
                                                    precisa crescer em muitos aspectos.
                                                </option>
                                                <option value="3">Sinto que preciso ajudá-lo para
                                                    que possa dar mais passos.
                                                </option>
                                                <option value="4">Acredito que precisa crescer no
                                                    seu caminho de conversão e adesão.</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <div class="form-group">
                                            <label for="descricao">5.2 Como você percebe a sua vivência da
                                                castidade?</label>
                                            <select class="form-control" id="nresposta26" name="nresposta26"
                                                [(ngModel)]="resposta26.resposta" required>
                                                <option value="1" i18n>Acredito que vive bem.</option>
                                                <option value="2">Acredito que vive bem, mas ainda
                                                    precisa crescer em muitos aspectos.
                                                </option>
                                                <option value="3">Sinto que preciso ajudá-lo para
                                                    que possa dar mais passos.
                                                </option>
                                                <option value="4">Acredito que precisa crescer no
                                                    seu caminho de conversão e adesão.</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <div class="form-group">
                                            <label for="descricao">5.3 Como você percebe a sua vivência da obediência?
                                            </label>
                                            <select class="form-control" id="nresposta27" name="nresposta27"
                                                [(ngModel)]="resposta27.resposta" required>
                                                <option value="1" i18n>Acredito que vive bem.</option>
                                                <option value="2">Acredito que vive bem, mas ainda
                                                    precisa crescer em muitos aspectos.
                                                </option>
                                                <option value="3">Sinto que preciso ajudá-lo para
                                                    que possa dar mais passos.
                                                </option>
                                                <option value="4">Acredito que precisa crescer no
                                                    seu caminho de conversão e adesão.</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <div class="form-group">
                                            <label for="descricao">5.4 Ainda sobre os conselhos evangelicos, existe algo
                                                que você gostaria de partilhar?</label>
                                            <input [(ngModel)]="resposta28.resposta" type="text" id="resposta28"
                                                name="resposta28" required class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <div class="form-group">
                                            <label for="descricao">6. Como você se vê na vivência do tripé da vocação?
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <div class="form-group">
                                            <label for="descricao">6.1 Como você se percebe na vivencia da sua vida de
                                                contemplação, intimidade com Deus?
                                            </label>
                                            <select class="form-control" id="nresposta29" name="nresposta29"
                                                [(ngModel)]="resposta29.resposta" required>
                                                <option value="1" i18n>Acredito que vive bem.</option>
                                                <option value="2">Acredito que vive bem, mas ainda
                                                    precisa crescer em muitos aspectos.
                                                </option>
                                                <option value="3">Sinto que preciso ajudá-lo para
                                                    que possa dar mais passos.
                                                </option>
                                                <option value="4">Acredito que não tem conseguido
                                                    ser fiel à sua vida de contemplação.</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <div class="form-group">
                                            <label for="descricao">6.2 Como você se percebe na tua vivencia apostolica?
                                            </label>
                                            <select class="form-control" id="nresposta30" name="nresposta30"
                                                [(ngModel)]="resposta30.resposta" required>
                                                <option value="1" i18n>Acredito que vive bem.</option>
                                                <option value="2">Acredito que vive bem, mas ainda
                                                    precisa crescer em muitos aspectos.
                                                </option>
                                                <option value="3">Sinto que preciso ajudá-lo para
                                                    que possa dar mais passos.
                                                </option>
                                                <option value="4">Acredito que não tem conseguido se
                                                    dedicar aos seus compromissos apostólicos.</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <div class="form-group">
                                            <label for="descricao">6.3 Como você se percebe na vivencia fraterna, na
                                                unidade?
                                            </label>
                                            <select class="form-control" id="nresposta31" name="nresposta31"
                                                [(ngModel)]="resposta31.resposta" required>
                                                <option value="1" i18n>Acredito que vive bem.</option>
                                                <option value="2">Acredito que vive bem, mas ainda
                                                    precisa crescer em muitos aspectos.
                                                </option>
                                                <option value="3">Sinto que preciso ajudá-lo para
                                                    que possa dar mais passos.
                                                </option>
                                                <option value="4">Acredito que não tem conseguido
                                                    viver bem a sua vida fraterna.</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <div class="form-group">
                                            <label for="descricao">7. De modo geral, Como você percebe o caminho deste
                                                formando?
                                            </label>
                                            <select class="form-control" id="nresposta32" name="nresposta32"
                                                [(ngModel)]="resposta32.resposta" required>
                                                <option value="1" i18n>Acredito que tem vivido bem.
                                                </option>
                                                <option value="2">Acredito que precisa de um pastoreio
                                                    mais próximo.
                                                </option>
                                                <option value="3">Acredito que precisa de intervenção
                                                    mais direta.
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <div class="form-group">
                                            <label for="descricao">8. Qual o caminho formativo você sugere para este
                                                formando?
                                            </label>
                                            <input [(ngModel)]="caminhoFormativoFormando.caminhoFormativoSugeridoFC"
                                                type="text" id="caminho_formativo_fc" name="caminho_formativo_fc"
                                                required class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <div class="form-group">
                                            <label for="descricao">9. Com relação ao exercicio da sua função na vida
                                                deste irmão, como você se vê?
                                            </label>
                                            <select class="form-control" id="nresposta34" name="nresposta34"
                                                [(ngModel)]="resposta34.resposta" required>
                                                <option value="1" i18n>Acredito que tenho conseguido
                                                    conduzir bem.
                                                </option>
                                                <option value="2">Sinto que preciso de ajuda na condução
                                                    do processo deste irmão.
                                                </option>
                                                <option value="3">É um caso desafiante, mas tenho recebido
                                                    ajuda necessária.
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <div class="form-group">
                                            <label for="descricao">Existe algo mais que você gostaria de expressar sobre
                                                a vivência deste irmão? Partilhe livremente.
                                            </label>
                                            <input [(ngModel)]="resposta35.resposta" type="text" id="resposta35"
                                                name="resposta35" class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="card-footer text-right">
                                    <button type="button" class="btn btn-default float-left"
                                        style="margin-left: 0.5rem;" (click)="activateTab('dados-pessoais-tab-content')"
                                        i18n>Voltar</button>&nbsp;
                                    <button type="button" (click)="salvarQuestionario()" class="btn btn-primary"
                                        i18n>Salvar</button>
                                    <button type="submit" (click)="enviarQuestionario()" class="btn btn-primary ml-1"
                                        [disabled]="questionarioForm.invalid" i18n>Enviar</button>
                                    <!-- <button type="button" class="btn btn-default" [disabled]="questionarioForm.invalid"
                                        (click)="enviarQuestionario()" i18n>Enviar</button> -->
                                    <!--button type="button" class="btn btn-default" [disabled]="questionarioForm.invalid || endereco.id == undefined" (click)="activateTab('endereco-tab-content')" i18n>Proximo</button-->
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <!-- /.modal-dialog -->
</secured>