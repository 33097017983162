import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SecuredModule } from '../../layout/secured.module';
import { RouterModule } from '@angular/router';
import { CelulaComponent } from './celula.component';
import { CelulasComponent } from './celulas.component';
import { CelulaEditComponent } from './celula.edit.component';
import { CelulaRoutingModule } from './celula-routing.module';
import { CelulaService } from './service/celula.service';
import { CelulasRLComponent } from './telas-rl/celulas.rl.component';
import { CelulaManageComponent } from './tela-gerenciar/celula.manage.component';
import { CelulaBuilder } from 'src/app/builder/celula.builder';
import { MembroComunidadeBuilder } from 'src/app/builder/membro.comunidade.builder';
import { MembroComunidadeService } from './service/membro.comunidade.service';
import { FormadorComunitarioService } from './service/formador.comunitario.service';
import { ListagemPessoaInfoModalModule } from '../pessoa/modulo/listagem-pessoainfo-modal/listagempessoainfo.modal.module';
import { FormadorAssistenteService } from './service/formador.assist.service';
import { CriarLinkCadastroRequestBuilder } from './request/criarlinkcadastro.request.builder';
import { GerenciamentoRLCelulaComponent } from './telas-rl/gerenciamento.rl.celula.component';
import { ParecerCelulaAutoridadeComponent } from './parecer/parecer.celula.autoridade.component';

@NgModule({
  declarations: [
    CelulaComponent,
    CelulasComponent,
    CelulaEditComponent,
    CelulasRLComponent,
    CelulaManageComponent,
    GerenciamentoRLCelulaComponent,
    ParecerCelulaAutoridadeComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    CelulaRoutingModule,
    FormsModule,
    SecuredModule,
    ListagemPessoaInfoModalModule,
  ],
  providers: [
    CelulaService,
    CelulaBuilder,
    MembroComunidadeService,
    MembroComunidadeBuilder,
    FormadorComunitarioService,
    FormadorAssistenteService,
    CriarLinkCadastroRequestBuilder,
  ],
})
export class CelulaModule {}
