import { NgModule } from '@angular/core';
import { AutorizacaoLocalComponent } from './autorizacao.local.component';
import { Routes, RouterModule } from '@angular/router';
import { AutorizacoesLocalComponent } from './autorizacoes.local.component';
import { AutorizacaoLocalEditComponent } from './autorizacao.local.edit.component';

const autorizacaoRoutes: Routes = [
    {
        path: 'secured/local/autorizacao',
        component: AutorizacaoLocalComponent
    },
    {
        path: 'secured/local/autorizacoes',
        component: AutorizacoesLocalComponent
    },
    {
        path: 'secured/local/autorizacao/:id',
        component: AutorizacaoLocalEditComponent
    }
]

@NgModule({
    imports: [
        RouterModule.forChild(autorizacaoRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class AutorizacaoLocalRoutingModule {
    
}