import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
export class UsuariosAssistenteApostolicoComponent {
    constructor(usuarioService, autorizacaoService, swtAlert2Service) {
        this.usuarioService = usuarioService;
        this.autorizacaoService = autorizacaoService;
        this.swtAlert2Service = swtAlert2Service;
        this.autorizacoes = new Array();
        this.usuarios = new Array();
        this.busca = {
            username: undefined,
            email: undefined,
            autorizacaoId: null,
            isAccountNonLocked: true,
            isAccountNonExpired: true,
            isCredentialsNonExpired: true,
            isEnabled: true,
            pageNumber: 0,
            quantityOfElements: 10
        };
    }
    ngOnInit() {
        this.getUsuarioAtual();
        this.buscarUsuarios();
        this.carregarAutorizacoes();
    }
    getUsuarioAtual() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.usuarioService.getCurrentUser();
                this.usuarioAtual = response.entity;
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    clear() {
        for (var prop in this.busca) {
            if (this.busca.hasOwnProperty(prop)) {
                this.busca[prop] = '';
            }
        }
    }
    carregarAutorizacoes() {
        this.autorizacaoService.findAll().then((lista) => {
            this.autorizacoes = lista.entity;
        }).catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }
    cliqueNoBotaoListener(botao) {
        this.busca.pageNumber = botao.numero - 1;
        this.buscarUsuarios();
    }
    buscarUsuarios() {
        this.usuarioService.buscarUsuarios(this.busca).then((response) => {
            this.usuarios = response.content;
            this.totalDePaginas = response.totalPages;
        }).catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }
    deletarUsuario(id) {
        if (confirm('Deseja deletar este usuário?')) {
            this.usuarioService.delete(id)
                .then((response) => {
                this.swtAlert2Service.successAlert(response.message);
                this.buscarUsuarios();
            })
                .catch((err) => {
                this.swtAlert2Service.errorAlert(err.error.errors);
                console.log(err);
            });
        }
    }
}
