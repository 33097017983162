import { Component, OnInit } from '@angular/core';
import { CentroEvangelizacao } from 'src/app/model/centroevangelizacao.model';
import { EstadoService } from '../estado/service/estado.service';
import { Estado } from 'src/app/model/estado.model';
import { Pais } from 'src/app/model/pais.model';
import { PaisService } from '../pais/service/pais.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Missao } from 'src/app/model/missao.model';
import { Regional } from 'src/app/model/regional.model';
import { RegionalService } from '../regional/service/regional.service';
import { MissaoService } from '../missao/service/missao.service';
import { Cidade } from 'src/app/model/cidade.model';
import { CidadeService } from '../cidade/service/cidade.service';
import { CentroEvangelizacaoService } from '../centroevangelizacao/service/centroevangelizacao.service';
import { Ministerio } from 'src/app/model/ministerio.model';
import { MinisterioService } from './service/ministerio.service';
import { MinisterioBuilder } from 'src/app/builder/ministerio.builder';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';

@Component({
    selector: 'ministerioedit',
    templateUrl: './ministerio.component.html'
})
export class MinisterioEditComponent implements OnInit {

    public ministerio: Ministerio;
    public centrosEvangelizacao: Array<CentroEvangelizacao>;
    public missoes : Array<Missao>;
    public regionalSelecionadoId : number;
    public missaoSelecionadoId : number;
    public regionais: Array<Regional>;
    public paises: Array<Pais>;
    public estados: Array<Estado>;
    public cidades: Array<Cidade>;
    public ministerioId: number;
    public centroEvangelizacaoId: number;
    public ministerioBuilder: MinisterioBuilder;

    constructor(
        public ministerioService: MinisterioService,
        public centroEvangelizacaoService: CentroEvangelizacaoService,
        public regionalService: RegionalService,
        public missaoService: MissaoService,
        public paisService: PaisService,
        public estadoService: EstadoService,
        public cidadeService: CidadeService,
        public route: ActivatedRoute,
        public router: Router,
        public swtAlert2Service: SwtAlert2Service
    ) 
    {
        this.ministerioBuilder = new MinisterioBuilder();
        this.ministerio = this.ministerioBuilder.getInstance();
        this.centrosEvangelizacao = new Array<CentroEvangelizacao>();
        this.regionais = new Array<Regional>();
        this.missoes = new Array<Missao>();
        this.paises = new Array<Pais>();
        this.estados = new Array<Estado>();
        this.cidades = new Array<Cidade>();
    }

    ngOnInit() { 
        this.ministerioId = Number(this.route.snapshot.paramMap.get('id'));
        this.regionalSelecionadoId = null;
        this.carregarMinisterio(this.ministerioId);
    }

    carregarRegionais() : void {
        this.regionalService.findAll().then( (lista: any) => {
            console.log(lista);
            this.regionais = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    carregarMissoes(regionalSelecionadoId: number): void {
        this.missaoService.porRegional(regionalSelecionadoId).then((response: any) => {
            this.missoes = response.entity;          
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }

    carregarCev(missaoSelecionadoId: number): void {
        this.centroEvangelizacaoService.porMissao(missaoSelecionadoId).then((response: any) => {
             this.centrosEvangelizacao = response.entity;          
         }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
         });;
     } 

    carregarMinisterio(id : number): void {
        this.ministerioService.find(id).then((response: any) => {
            this.ministerio = response.entity;   
            this.regionalSelecionadoId = response.entity.missaoRegionalId;
            this.carregarRegionais();
            this.carregarMissoes(this.ministerio.regionalId);
            this.carregarCev(this.ministerio.centroEvangelizacaoId);
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }
    
    salvar(ministerio: Ministerio) : void {

        this.ministerioService.update(ministerio.id, ministerio).then( (response:any) => {
            this.swtAlert2Service.successAlert(response.message);
            setTimeout(() => {
                this.router.navigate(['../secured/ministerios']);
            }, 2000);

        }).catch( (err:any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    } 

}