import { GenericService } from 'src/app/core/services/generic.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { MensagemRlModule } from '../mensagem-rl.module';

export type DetalhesMensagemParaRL = {
  pessoaId: number;
  pessoaImagemId: number;
  pessoaNome: string;
  ultimaMensagem: string;
  possuiMensagensNaoLidas: boolean;
  quantidadeMensagensNaoLidas: number;
  thumbnail: string;
};

export type MensagemDeMembroParaRLOrigem = {
  pessoaId: number;
  origem: string;
  mensagem: string;
};

@Injectable()
export class MensagemRLService extends GenericService<DetalhesMensagemParaRL> {
  constructor(protected http: HttpClient, protected router: Router) {
    super(http, router);
    this.setEntityType('mensagemrl');
  }

  public obterMensagemsParaRLLogado(): Observable<
    Array<DetalhesMensagemParaRL>
  > {
    return this.http.get<Array<DetalhesMensagemParaRL>>(
      `${environment.moduloFormacao.urlModuloFormacao}mensagemrl/listar/resumo/porrllogado`,
      {
        headers: this.getHeaders(),
      }
    );
  }

  public obterMensagemDeMembroParaRLPorPessoaId(
    pessoaId: number
  ): Observable<Array<MensagemDeMembroParaRLOrigem>> {
    return this.http.get<Array<MensagemDeMembroParaRLOrigem>>(
      `${environment.moduloFormacao.urlModuloFormacao}mensagemrl/listar/porpessoaid/${pessoaId}`,
      {
        headers: this.getHeaders(),
      }
    );
  }
}
