<secured>
    <ng-container *ngIf="isLoaded; else elseTemplate">
        <div class="content-wrapper">
            <section class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-12">
                            <h3 i18n>Gerenciamento - Célula</h3>
                        </div>
                    </div>
                </div>
            </section>
            <section class="content" name="celula">
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <div class="card card-gray-dark color-pallete-box">
                            <div class="card-header">
                                <h3 class="card-title">
                                    <i class="fas fa-users"></i> Minha Célula
                                </h3>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-12 col-md-3 col-lg-3">
                                        <div class="form-group">
                                            <label>Nome:</label>
                                            <br>
                                            <span class="form-control">{{ celula?.nome != undefined ? celula?.nome : '' }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-3 col-lg-3">
                                        <div class="form-group">
                                            <label>Data de Fundação:</label>
                                            <br>
                                            <span class="form-control">{{ celula?.dataFundacao | date : 'dd/MM/yyyy' }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-3 col-lg-3">
                                        <div class="form-group">
                                            <label>Nível Formativo:</label>
                                            <br>
                                            <span class="form-control">{{ celula?.nivelCelulaNome != undefined ? celula?.nivelCelulaNome : '' }}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-3 col-lg-3">
                                        <div class="form-group">
                                            <label>Centro de Evangelização:</label>
                                            <br>
                                            <span class="form-control">{{ celula?.centroEvangelizacaoNome != undefined ? celula?.centroEvangelizacaoNome : '' }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <div class="card card-gray-dark color-pallete-box">
                            <div class="card-header">
                                <h3 class="card-title">
                                    <i class="fas fa-link"></i> Link para página de cadastro de membros na célula
                                </h3>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-12 col-md-4 col-lg-4">
                                        <div class="form-group">
                                            <label i18n for="validade_inicio_data">Link válido a partir de:</label>
                                            <input type="date" class="form-control" name="validade_inicio_data" [(ngModel)]="celulaCriarLinkCadastroRequest.dataValidadeInicioCadastro">
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-4 col-lg-4">
                                        <div class="form-group">
                                            <label i18n for="validade_fim_data">Link válido até:</label>
                                            <input type="date" class="form-control" name="validade_fim_data" [(ngModel)]="celulaCriarLinkCadastroRequest.dataValidadeFimCadastro">
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-4 col-lg-4">
                                        <div class="form-group">
                                            <label>&nbsp;</label>
                                            <br>
                                            <button class="btn btn-primary" (click)="criarLinkCadastroCelula()">Criar Link</button>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md-6 col-lg-6">
                                        <div class="form-group">
                                            <label>Link de cadastro:</label>
                                            <br>
                                            <span class="form-control">{{ tokenLinkCelulaResponse?.urlCadastro != undefined ? tokenLinkCelulaResponse?.urlCadastro : '' }}</span>&nbsp;<i class="far fa-copy cursor-pointer" title="Copiar Link" (click)="copyToClipboard()"></i>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-3 col-lg-3">
                                        <div class="form-group">
                                            <label>Data Validade Link Início:</label>
                                            <br>
                                            <span class="form-control">{{tokenLinkCelulaResponse?.dataValidadeInicio | date : 'dd/MM/yyyy'}}</span>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-3 col-lg-3">
                                        <div class="form-group">
                                            <label>Data Validade Link Fim:</label>
                                            <br>
                                            <span class="form-control">{{tokenLinkCelulaResponse?.dataValidadeFim | date : 'dd/MM/yyyy'}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <div class="card card-gray-dark color-pallete-box">
                            <div class="card-header">
                                <h3 class="card-title">
                                    <i class="fas fa-user-circle"></i> Responsáveis
                                </h3>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-12 col-md-3 col-lg-3">
                                        <div class="card card-success card-outline">
                                            <div class="card-body box-profile">
                                                <div class="text-center">
                                                    <img class="profile-user-img img-fluid img-circle" [src]="formadorComunitario?.thumbnail == null ? 'assets/images/ppl.png' : formadorComunitario?.thumbnail" alt="Foto pastor grupo de oração">
                                                </div>
                                                <h3 class="profile-username text-center">{{formadorComunitario?.pessoaNome}}</h3>
                                                <p class="text-muted text-center"><b>Formador Comunitario</b></p>
                                                <ul class="list-group list-group-unbordered mb-3">
                                                    <li class="list-group-item">
                                                        <b>Data de entrada:</b> <a class="float-right">{{formadorComunitario?.dataEntrada | date : 'dd/MM/yyyy'}}</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-3 col-lg-3" *ngFor="let formadorassistente of formadoresAssistente">
                                        <div class="card card-yellow card-outline">
                                            <div class="card-body box-profile">
                                                <div class="text-center">
                                                    <img class="profile-user-img img-fluid img-circle" [src]="formadorassistente?.thumbnail == null ? 'assets/images/ppl.png' : formadorassistente?.thumbnail" alt="Foto pastor grupo de oração">
                                                </div>
                                                <h3 class="profile-username text-center">{{formadorassistente?.pessoaNome}}</h3>
                                                <p class="text-muted text-center"><b>Formador Assistente</b></p>
                                                <ul class="list-group list-group-unbordered mb-3">
                                                    <li class="list-group-item">
                                                        <b>Data de entrada:</b> <a class="float-right">{{formadorassistente?.dataEntrada | date : 'dd/MM/yyyy'}}</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <div class="card card-gray-dark color-pallete-box">
                            <div class="card-header">
                                <h3 class="card-title">
                                    <i class="fas fa-child"></i>
                                    <a i18n> Membros </a>
                                </h3>
                                <div class="card-tools"><button class="btn btn-tool" data-card-widget="collapse" type="button"><i class="fas fa-minus"></i></button></div>
                            </div>
                            <!--div class="card-body">
                                <div class="row">
                                    <div class="col-sm-12 col-md-6 col-lg-6">
                                        <div class="row">
                                            <div class="col-sm-12 col-md-12 col-lg-12">
                                                <div class="form-group">
                                                    <label i18n for="npastor_pessoa">Pessoa</label>
                                                    <span class="form-control">{{pessoaMembroSelecionada.nome}} {{pessoaMembroSelecionada.sobrenome}}</span>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12 col-lg-12">
                                                <div class="form-group">
                                                    <label i18n for="button-add-pessoa">&nbsp;</label>
                                                    <button name="button-add-pessoa" data-toggle="modal" data-target="#listagem-pessoa-membro" style="cursor: pointer" class="btn btn-primary" i18n>Selecionar Membro</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-3 col-lg-3">
                                        <div class="form-group">
                                            <label i18n for="nmembro_dtentrada">Data de Entrada</label>
                                            <input [(ngModel)]="membroComunidade.dataEntrada" name="nmembro_dtentrada" type="date" class="form-control" id="nmembro_dtentrada" i18n-placeholder>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-3">
                                        <div class="form-group">
                                            <label i18n for="button-part-submit">&nbsp;</label>
                                            <button type="submit" (click)="salvarMembro()" name="button-part-submit" class="form-control btn btn-primary" i18n>Adicionar Membro</button>
                                        </div>
                                    </div>
                                </div>
                            </div-->
                            <div class="card-footer">
                                <div class="row">
                                    <div class="col-md-12">
                                        <table-component [cardBodyClass]="'table-responsive p-0'" [tableClass]="'table-striped'" [tituloSessao]="'Membros'" [cols]="6" [rows]="buscaMembros.quantityOfElements" [totalDePaginas]="totalDePaginasMembros" (eventoBotao)="cliqueNoBotaoMembrosListener($event)">
                                            <tr tableheader>
                                                <th>#</th>
                                                <th>Imagem</th>
                                                <th>Nome</th>
                                                <th>Data Entrada Célula</th>
                                                <th>Data Saída do Célula</th>
                                                <th></th>
                                            </tr>
                                            <tr tablebody *ngFor="let membro of membrosCelula">
                                                <td>{{membro.id}}</td>
                                                <td>
                                                    <div class="text-center">
                                                        <!--a routerLink="/secured/local/pgo/celula/membro/{{membro.id}}/detalhes"-->
                                                            <img class="profile-user-img img-fluid img-circle" [src]="membro.thumbnail == null ? 'assets/images/ppl.png' : membro.thumbnail" alt="Imagem do membro">
                                                        <!--/a-->
                                                    </div>
                                                </td>
                                                <td><a routerLink="/secured/local/pgo/celula/membro/{{membro.id}}/detalhes">{{membro.pessoaNome}}</a></td>
                                                <td>{{membro.dataEntrada | date : 'dd/MM/yyyy'}}</td>
                                                <td>
                                                    <ng-container *ngIf="membro.editando == false || membro.editando == undefined">
                                                        {{membro.dataSaida != null ? (membro.dataSaida | date : 'dd/MM/yyyy') : 'Membro Atual'}}
                                                    </ng-container>
                                                    <ng-container *ngIf="membro.editando == true">
                                                        <input [(ngModel)]="membro.dataSaida" name="nmembro_dtsaida" type="date" class="form-control" id="nmembro_dtsaida">
                                                    </ng-container>
                                                </td>
                                                <td class="text-center">
                                                    <!--button *ngIf="membro.editando == true" class="btn btn-sm btn-success" (click)="atualizarMembro(membro)" title="Atualizar membro" alt="Atualizar membro"><i class="fas fa-save"></i></button>
                                                    <button *ngIf="membro.editando == false || membro.editando == undefined" class="btn btn-sm btn-primary" (click)="membro.editando = true" title="Editar membro" alt="Editar membro"><i class="fas fa-edit"></i></button>
                                                    <button class="btn btn-sm btn-danger ml-1" (click)="removerMembro(membro.id)" title="Remover membro" alt="Remover membro"><i class="fas fa-trash-alt"></i></button-->
                                                </td>
                                            </tr>
                                        </table-component>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--div class="col-sm-12 col-md-12 col-lg-12">
                        <div class="card card-gray-dark color-pallete-box">
                            <div class="card-header">
                                <h3 class="card-title">
                                    <i class="fas fa-birthday-cake"></i> Aniversariantes
                                </h3>
                            </div>
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-sm-12 col-md-3 col-lg-3" *ngFor="let aniversariante of listaAniversariantesMes">
                                        <div class="card card-yellow card-outline">
                                            <div class="card-body box-profile">
                                                <div class="text-center">
                                                    <img class="profile-user-img img-fluid img-circle" [src]="aniversariante?.thumbnail == null ? 'assets/images/ppl.png' : aniversariante?.thumbnail" alt="Foto aniversariante">
                                                </div>
                                                <h4 class="profile-username text-center">{{aniversariante?.pessoaNome}}</h4>
                                                <p class="text-muted text-center">Aniversariante</p>
                                                <ul class="list-group list-group-unbordered mb-3">
                                                    <li class="list-group-item">
                                                        <b>Aniversário:</b> <a class="float-right">{{aniversariante?.dataAniversario | date : 'dd/MM/yyyy'}}</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div-->
                </div>
            </section>
        </div>
    </ng-container>
    <ng-template #elseTemplate>
        <ng-container *ngIf="hasCelulaAssociada; else elseNoHasCelula">
            <div class="content-wrapper">
                <section class="content-header">
                    <div class="container-fluid">
                        <h1 class="loading">Carregando...</h1>
                    </div>
                </section>
            </div>
        </ng-container>
        <ng-template #elseNoHasCelula>
            <div class="content-wrapper">
                <section class="content-header">
                    <div class="container-fluid">
                        <h1 class="loading">Você ainda não está vinculado a uma célula comunitaria como formador.</h1>
                    </div>
                </section>
            </div>
        </ng-template>
    </ng-template>
    <listagem-pessoainfo-modal-component [modalId]="'listagem-pessoa-membro'" [onlyMissionaries]="false" [bringNotInformedWayOfLife]="true" [filterByUserAccess]="true"
            [modalTitle]="'Selecione o Membro do Grupo'" [buttonText]="'Selecionar'" [altButtonText]="'Selecionar Membro'"
            (eventEmitter)="selecionarMembro($event)"></listagem-pessoainfo-modal-component>
</secured>