import { StringMap } from '@angular/compiler/src/compiler_facade_interface';

export class Documento {
    
    constructor (
        public elementoGrupoDominioTipoId: number,
        public elementoGrupoDominioValor: number,
        public tipoNaoListado: string,
        public valor: string,
        public dataValidade: Date,
        public pessoaId: number
    ){}

}