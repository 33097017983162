import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
export class AppRouteChangeService {
    constructor(router) {
        this.router = router;
        this.router.events.subscribe((event) => {
            // console.log(event);
            if ($('body').width() <= 991) {
                $('body.igx-typography').removeClass("sidebar-open");
                $('body.igx-typography').addClass("sidebar-collapse");
            }
        });
    }
}
AppRouteChangeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppRouteChangeService_Factory() { return new AppRouteChangeService(i0.ɵɵinject(i1.Router)); }, token: AppRouteChangeService, providedIn: "root" });
