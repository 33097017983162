export class Relacionamento {

    constructor(
        public id: number,
        public pessoa1Id: number,
        public pessoa1Nome: string,
        public pessoa2Id: number,
        public pessoa2Nome: string,
        public pessoa1IsMembro: boolean,
        public pessoa2IsMembro: boolean,
        public tipoRelacionamentoId: number,
        public tipoRelacionamentoNome: string,
        public dataInicioCaminhada: Date,
        public dataInicioNamoro: Date,
        public dataInicioNoivado: Date,
        public dataMatrimonio : Date     
    ){}

}