import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NovaSenhaEsqueceuSenhaComponent } from './novasenha.esqueceusenha.component';

const esqueceuSenhaPublicoRoutes: Routes = [
    {
        path: 'esqueceusenha/novasenha/:token',
        component: NovaSenhaEsqueceuSenhaComponent
    }
]

@NgModule({
    imports: [
        RouterModule.forChild(esqueceuSenhaPublicoRoutes)
    ],
    exports: [
        RouterModule
    ]
})

export class EsqueceusenhaRoutingModule {
}
