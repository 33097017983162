import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MensagemRlSharedStateService } from '../../service/mensagem-rl-shared-state.service';
import { DetalhesMensagemParaRL } from '../../service/mensagemrl.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-mensagem-rl-membro',
  templateUrl: './mensagem-rl-membro.component.html',
  styleUrls: ['./mensagem-rl-membro.component.css'],
})
export class MensagemRlMembroComponent implements OnInit, OnDestroy {
  @Input()
  imageSrc: string;
  @Input()
  memberName: string;
  @Input()
  lastMessage: string;
  @Input()
  notReadedMessageCounter?: number;
  @Input()
  memberPessoaId: number;

  public selectedMemberResumePessoaId?: number;
  public selectedMemberResumePessoaIdSubscription: Subscription;

  constructor(
    public mensagemRlSharedStateService: MensagemRlSharedStateService
  ) {}

  ngOnInit() {
    this.selectedMemberResumePessoaIdSubscription =
      this.mensagemRlSharedStateService.currentMessageSummary.subscribe(
        (summary: DetalhesMensagemParaRL) => {
          this.selectedMemberResumePessoaId = summary ? summary.pessoaId : null;
        }
      );
  }

  ngOnDestroy(): void {
    this.selectedMemberResumePessoaIdSubscription.unsubscribe();
  }
}
