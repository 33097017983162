import { GenericService } from 'src/app/core/services/generic.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from "@angular/core";
import { PessoaNacionalidade } from 'src/app/model/pessoanacionalidade.model';

@Injectable()
export class PessoaNacionalidadeService extends GenericService<PessoaNacionalidade> {
    
    
    constructor(protected http: HttpClient, protected router:Router) {
        super(http,router);
        this.setEntityType('pessoanacionalidade');
    }
    
    porPessoaId(id: number): Promise<any> {
        return this.http.get<any>(`${this.apiURL}/porpessoa/${id}`, { 'headers' : this.getHeaders() }).toPromise();
    }

} 