import { Component, OnInit } from '@angular/core';
import { Usuario } from 'src/app/model/usuario.model';
import { UsuarioService } from './service/usuario.service';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { UsuarioBuilder } from 'src/app/builder/usuario.builder';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'usuario',
    templateUrl: './usuario.component.html'
})
export class UsuarioComponent implements OnInit {

    public usuarioBuilder: UsuarioBuilder;
    public usuario: Usuario;
    public total: number;
    public usuarioAtual: Usuario;

    constructor(
        public usuarioService: UsuarioService, 
        public swtAlert2Service: SwtAlert2Service,
        private route: ActivatedRoute,
        private router: Router
    ) 
    {
        this.usuarioBuilder = new UsuarioBuilder();
        this.usuario = this.usuarioBuilder.getInstance();
    }

    ngOnInit() {
        this.getUsuarioAtual();
    }

    async getUsuarioAtual() : Promise<any> {

        try 
        {
            let response = await this.usuarioService.getCurrentUser();   
            this.usuarioAtual = response.entity;        
        }
        catch(e)
        {
            console.log(e);
        }

    }

    salvar(usuario: Usuario) : void {

        this.usuarioService.create(usuario).then( (response:any) => {
            this.usuario = this.usuarioBuilder.getInstance();
            this.swtAlert2Service.successAlert(response.message);
            setTimeout(() => {
                this.router.navigate(['../secured/usuario/'+response.entity.id]);
            },2000); 
        }).catch( (err:any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }

}