import { GenericService } from 'src/app/core/services/generic.service';
import { HttpClient } from '@angular/common/http';
import { Pessoa } from 'src/app/model/pessoa.model';
import { Router } from '@angular/router';
import { Injectable } from "@angular/core";

@Injectable()
export class PessoaService extends GenericService<Pessoa> {
    
    
    constructor(protected http: HttpClient, protected router:Router) {
        super(http,router);
        this.setEntityType('r/pessoa');
    }
    
    atualizarFormaVida(request: any) {
        return this.http.post<any>(`${this.apiURL}/atualizarformavida`, JSON.stringify(request), { 'headers' : this.getHeaders() }).toPromise();
    }

    atualizarEstadoVida(request: any) {
        return this.http.post<any>(`${this.apiURL}/atualizarestadovida`, JSON.stringify(request), { 'headers' : this.getHeaders() }).toPromise();
    }

    buscarPessoas(busca: any) {
        return this.http.post<any>(this.apiURL+'/buscar', JSON.stringify(busca), { 'headers' : this.getHeaders() }).toPromise();
    }

    buscarMembrosCasaComunitaria(busca: any) {
        return this.http.post<any>(this.apiURL+'/buscarmembroscasacomunitaria', JSON.stringify(busca), { 'headers' : this.getHeaders() }).toPromise();
    }

    pesquisarPessoasInfoListagem (busca: any) {
        return this.http.post<any>(this.apiURL+'/pesquisarpessoainfolistagem', JSON.stringify(busca), { 'headers' : this.getHeaders() }).toPromise();
    }

    buscaPorUsuarioId(id: number): Promise<any> {
        return this.http.get<any>(`${this.apiURL}/porusuario/${id}`, { 'headers' : this.getHeaders() }).toPromise();
    }

    buscaPorId(id: number): Promise<any> {
        return this.http.get<any>(`${this.apiURL}/${id}`, { 'headers' : this.getHeaders() }).toPromise();
    }

    buscaPessoaPorUsuarioCriador(): Promise<any> {
        return this.http.get<any>(`${this.apiURL}/porusuariocriador`, { 'headers' : this.getHeaders() }).toPromise();
    }

    acompanhadoresGrupoOracao(id: number): Promise<any> {
        return this.http.get<any>(`${this.apiURL}/acompanhadoresgrupooracao/${id}`, { 'headers' : this.getHeaders() }).toPromise();
    }

    setarRegistroDuplicado(id: number): Promise<any> {
        return this.http.get<any>(`${this.apiURL}/registroduplicado/${id}`, { 'headers' : this.getHeaders() }).toPromise();
    }

} 