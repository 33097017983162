import { Component, OnInit } from '@angular/core';
import { PaisService } from './service/pais.service';
import { Pais } from 'src/app/model/pais.model';
import { Router, ActivatedRoute } from '@angular/router';
import { PaisBuilder } from 'src/app/builder/pais.builder';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';

@Component({
    selector: 'pais-edit',
    templateUrl: './pais.component.html'
})
export class PaisEditComponent implements OnInit {

    public pais: Pais;
    public paisId: number;
    public paisBuilder: PaisBuilder;
    public busca: any;
    public totalDePaginas: number;

    constructor(
        public paisService: PaisService,
        public swtAlert2Service: SwtAlert2Service,
        public route: ActivatedRoute,
        public router: Router
    ) {
        this.paisBuilder = new PaisBuilder();
        this.pais = this.paisBuilder.getInstance();
    }

    ngOnInit() {
        this.paisId = Number(this.route.snapshot.paramMap.get('id'));
        this.carregarPais(this.paisId);
    }

    carregarPais(id: number): void {
        this.paisService.find(id).then((response: any) => {
            this.pais = response.entity;
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }

    salvar(pais: Pais): void {

        this.paisService.update(pais.id, pais).then((response: any) => {
            this.swtAlert2Service.successAlert(response.message);
            setTimeout(() => {
                this.router.navigate(['../secured/paises']);
            }, 2000);

        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }


}