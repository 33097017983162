import { GrupoDeOracao } from "../model/grupodeoracao.model";
import { GrupoDeOracaoVinculoOrigem } from "../model/grupodeoracaovinculoorigem.model";
import { AbstractBuilder } from "./abstract.builder";


export class GrupoDeOracaoBuilder extends AbstractBuilder<GrupoDeOracao> {

    reset() : void {
        this.entity = new GrupoDeOracao(null,null,null,null,null,null,null,null,null,null,null,null,new GrupoDeOracaoVinculoOrigem(null,null,null), false, null);
    }
}