import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import 'select2';
import { environment } from "src/environments/environment";
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { WopGetAllService } from 'src/app/service/getall.service';
import { ImagemService } from "../../wop/imagem/service/imagem.service";
import { Missao } from 'src/app/model/missao.model';
import { Pais } from 'src/app/model/pais.model';
import { ElementoGrupoDominio } from 'src/app/model/elementogrupodominio.model';
import { Pessoa } from 'src/app/model/pessoa.model';
import { PessoaService } from '../../wop/pessoa/service/pessoa.service';
import { PaisService } from '../../wop/pais/service/pais.service';
import { Difusao } from 'src/app/model/difusao.model';
import { CasaComunitaria } from 'src/app/model/casacomunitaria.model';
import { CasaComunitariaService } from '../../wop/casacomunitaria/service/casacomunitaria.service';



@Component({
  selector: 'missionarios',
  templateUrl: './missionarios.component.html',
  styleUrls: ['../../wop/pessoa/style/style.css', '../../wop/pessoa/style/normalize.css']  
})
export class MissionariosComponent implements OnInit {

    public pessoas: Array<Pessoa>;
    public paises: Array<Pais>;
    public missoes: Array<Missao>;
    public difusoes: Array<Difusao>;
    public casasComunitarias: Array<CasaComunitaria>;
    public formasVida: Array<ElementoGrupoDominio>;
    public estadoVida: Array<ElementoGrupoDominio>;
    public busca: any;
    public totalDePaginas: number; 
    public urlBaseImagem: string;

    constructor(
        public pessoaService: PessoaService,
        public wopGetAllService: WopGetAllService,
        public paisService: PaisService,
        public casaComunitariaService: CasaComunitariaService,
        public swtAlert2Service: SwtAlert2Service,
        public imagemService: ImagemService
    ){
        this.pessoas = new Array<Pessoa>();
        this.urlBaseImagem = environment.imagem.urlBaseImagem;
        this.paises = new Array<Pais>();
        this.missoes = new Array<Missao>();
        this.difusoes = new Array<Difusao>();
        this.casasComunitarias = new Array<CasaComunitaria>();
        this.busca = {
            nome: null,
            sobrenome: null,
            genero: null,
            anonascimento: null,
            nacionalidadeId: null,
            missaoAtualId: null,
            difusaoAtualId: null,
            formaVidaId: 17, //comunidade de Vida
            estadoVidaId: null,
            casaComunitariaId: null,
            pageNumber: 0,
            quantityOfElements : 10
        };

    }

    ngOnInit(): void {
        this.carregarPaises();
        this.carregarFormasVida();
        this.carregarEstadoVida();
        this.carregarMissoes();        
        this.carregarDifusoes(); 
        this.buscarMissionarios();
    }

    carregarPaises() : void {
        this.paisService.findAll().then( (lista: any) => {
            this.paises = lista.entity;
        }).catch( (err: any) => {
            console.log(err);
        });
    }

    async carregarFormasVida() : Promise<any> {

        let request = {
            grupoNome: 'FORMA_VIDA'
        }
    
        try
        {
            let response = await this.wopGetAllService.elementosGrupoDominioPorNomeGrupo(request);
            this.formasVida = response.entity;
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }    
    } 
    
    async carregarEstadoVida() : Promise<any> {

        let request = {
            grupoNome: 'ESTADO_VIDA'
        }
    
        try
        {
            let response = await this.wopGetAllService.elementosGrupoDominioPorNomeGrupo(request);
            this.estadoVida = response.entity;
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }
    }    

    async carregarMissoes(): Promise<any> {

        try 
        {
            let response = await this.wopGetAllService.getAllMissao();   
            this.missoes = response.entity;     
            this.missoes = this.missoes.sort( (a:Missao, b:Missao) => a.nome.localeCompare(b.nome));
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }
    } 
    
    async carregarDifusoes(): Promise<any> {

        try 
        {
            let response = await this.wopGetAllService.getAllDifusao();   
            this.difusoes = response.entity;     
            this.difusoes = this.difusoes.sort( (a:Difusao, b:Difusao) => a.nome.localeCompare(b.nome));
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }
    } 
    
    async carregarCasasComunitarias(): Promise<any> {

        try 
        {
            let response = await this.casaComunitariaService.porMissao(this.busca.missaoAtualId);   
            this.casasComunitarias = response.entity;     
            this.casasComunitarias = this.casasComunitarias.sort( (a:CasaComunitaria, b:CasaComunitaria) => a.nome.localeCompare(b.nome));
        }
        catch(e)
        {
            this.swtAlert2Service.errorAlert(e.error.errors);
        }
    } 

    async buscarMissionarios() : Promise<any> {

        try
        {
            let response = await this.pessoaService.buscarPessoas(this.busca);

            this.pessoas = response.entity;

            this.totalDePaginas = response.quantity;

            for(let i = 0; i < this.pessoas.length ; i++)
            {

                if(this.pessoas[i].imagemId != null)
                {
                    let blogImage = await this.getPessoaImagem(this.pessoas[i].imagemId);

                    this.imagemService.createImage(blogImage, this.pessoas[i]);
                }  
                
            } 

        }
        catch(err)
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }

    }

    async getPessoaImagem(id:number): Promise<any> {

        try
        {
            let response = await this.imagemService.getPessoaPhoto(id);
            return response.body;
        }
        catch(err)
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }

    }

    deletarPessoa(id: number) : void {

        if(confirm('Deseja deletar esta pessoa?'))
        {
            this.pessoaService.delete(id)
            .then( (response: any) => {
                this.swtAlert2Service.successAlert(response.message);
                this.buscarMissionarios();
            })
            .catch((err) => {
                this.swtAlert2Service.errorAlert(err.error.errors);
            });
        }
    }

    async setarRegistroDuplicado(pessoaId: number) : Promise<any> {

        try 
        {
            let response = await this.pessoaService.setarRegistroDuplicado(pessoaId);   
            this.swtAlert2Service.successAlert(response.message);
        } 
        catch (err) {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }
    }

    
    cliqueNoBotaoListener(botao) {
        this.busca.pageNumber = botao.numero-1;
        this.buscarMissionarios();
    }

    getUrlImagem(imagemId) : string {
        return this.urlBaseImagem+imagemId;
    }
 }