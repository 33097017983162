<div class="container" [ngClass]="{'sumary-selected': memberPessoaId === selectedMemberResumePessoaId}">
  <img [src]="imageSrc" alt="imagem do membro da comunidade">
  <div class="member-message-data">
    <strong>{{memberName}}</strong>
    <div class="message-preview">
      <p>{{lastMessage}}</p>
      <div class="member-message-counter" *ngIf="notReadedMessageCounter">
        <span>{{notReadedMessageCounter}}</span>
      </div>
    </div>
  </div>
</div>