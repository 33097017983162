<ng-container *ngIf="selectedSummary == null">
  <main id="welcome-message">
    <h1>Mensagens para o Responsável Local</h1>
    <p>Clique na mensagem do formando para visualizar as mensagens enviadas.</p>
  </main>
</ng-container>
<ng-container *ngIf="selectedSummary != null">
  <main id="member-message-content"
    [ngClass]="{'make-it-visible': selectedSummary !== null && hideMessageContent === false, 'make-it-hidden': hideMessageContent === true}">
    <div class="member-info">
      <div>
        <img [src]="imageSrc" alt="imagem do membro da comunidade">
        <strong>{{selectedSummary?.pessoaNome}}</strong>
      </div>
      <button class="btn btn-danger close-message-content-btn" (click)="hideMessageContent = true"
        alt="Fechar janela de mensagem">
        <i class="far fa-window-close"></i>
      </button>
    </div>
    <div class="messages">
      <div class="messages-wrapper">
        <app-message-content-component *ngFor="let mensagem of mensagensMembro" [source]="mensagem.origem"
          [message]="mensagem.mensagem"></app-message-content-component>
      </div>
    </div>
  </main>
</ng-container>