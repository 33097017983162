import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
export class AcessoUsuariosEstruturasListagemCAPComponent {
    constructor(acessoUsuarioEstruturaService, autorizacaoService, swtAlert2Service) {
        this.acessoUsuarioEstruturaService = acessoUsuarioEstruturaService;
        this.autorizacaoService = autorizacaoService;
        this.swtAlert2Service = swtAlert2Service;
        this.autorizacoes = new Array();
        this.busca = {
            autorizacaoId: null,
            username: null,
            nome: undefined,
            pageNumber: 0,
            quantityOfElements: 10
        };
    }
    ngOnInit() {
        this.init();
    }
    init() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.carregarAutorizacao();
            yield this.buscarAcessos();
        });
    }
    carregarAutorizacao() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.autorizacaoService.findAll();
                this.autorizacoes = response.entity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    cliqueNoBotaoListener(botao) {
        this.busca.pageNumber = botao.numero - 1;
        this.buscarAcessos();
    }
    buscarAcessos() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.acessoUsuarioEstruturaService.buscar(this.busca);
                this.acessos = response.entity;
                this.totalDePaginas = response.quantity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    deletarAcesso(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (confirm('Deseja deletar este acesso?')) {
                    let response = yield this.acessoUsuarioEstruturaService.delete(id);
                    this.swtAlert2Service.successAlert(response.message);
                    this.buscarAcessos();
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
}
