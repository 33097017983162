import { Component, OnInit } from '@angular/core';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { Pais } from 'src/app/model/pais.model';
import { PaisService } from './service/pais.service';

@Component({
    selector: 'paises',
    templateUrl: './paises.component.html'
})
export class PaisesComponent implements OnInit {

    public paises: Array<Pais>;
    public busca: any;
    public totalDePaginas: number;

    constructor(
        public paisService: PaisService,
        public swtAlert2Service: SwtAlert2Service
    ) 
    {
        this.paises = new Array<Pais>();
        this.busca = {
            nome: undefined,
            pageNumber : 0, 
            quantityOfElements : 10
        };
    }

    ngOnInit() {
        this.buscarPaises();
    }   

    cliqueNoBotaoListener(botao) {
        this.busca.pageNumber = botao.numero-1;
        this.buscarPaises();
    }

    buscarPaises() : void {
        this.paisService.buscarPaises(this.busca).then( (response: any) => {
            this.paises = response.entity;
            this.totalDePaginas = response.quantity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }
    
    deletarPais(id: number) : void {

        if(confirm('Deseja deletar este país?'))
        {
            this.paisService.delete(id)
            .then( (response: any) => {
                this.swtAlert2Service.successAlert(response.message);
                this.buscarPaises();
            })
            .catch((err) => {
                this.swtAlert2Service.errorAlert(err.error.errors);
            });
        }
    }
}