import { Injectable } from '@angular/core';
import { DadosDetalhamentoMissionarioMissaoAtual } from '../model/dadosdetalhamentomissionariomissaoatual.model';
import { AbstractBuilder } from './abstract.builder';

@Injectable()
export class DadosDetalhamentoMissionarioMissaoAtualBuilder extends AbstractBuilder<DadosDetalhamentoMissionarioMissaoAtual> {

    reset() : void {
        this.entity = new DadosDetalhamentoMissionarioMissaoAtual(null,null,null,null,null,null,null,null,null,null,null,null);
    }

}