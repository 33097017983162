import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
export class AceiteTermoComponent {
    constructor(cadastroPublicoService, aceiteTermoUsoService, termoUsoBuilder, aceiteTermoUsoBuilder, swtAlert2Service, router) {
        this.cadastroPublicoService = cadastroPublicoService;
        this.aceiteTermoUsoService = aceiteTermoUsoService;
        this.termoUsoBuilder = termoUsoBuilder;
        this.aceiteTermoUsoBuilder = aceiteTermoUsoBuilder;
        this.swtAlert2Service = swtAlert2Service;
        this.router = router;
        this.termoVigente = this.termoUsoBuilder.getInstance();
        this.aceiteTermoUso = this.aceiteTermoUsoBuilder.getInstance();
    }
    ngOnInit() {
        this.initialize();
    }
    initialize() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.didUserAcceptUserTerm();
            yield this.carregarTermoVigente();
        });
    }
    didUserAcceptUserTerm() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.aceiteTermoUsoService.usuarioAceitouTermo();
                let usuarioAceitouTermoUso = response.entity;
                if (usuarioAceitouTermoUso) {
                    this.router.navigate(['/secured/']);
                }
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
                this.router.navigate(['/login']);
            }
        });
    }
    carregarTermoVigente() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.cadastroPublicoService.getTermoVigente();
                this.termoVigente = response.entity;
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    aceitarTermoUso() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.aceiteTermoUsoService.aceitarTermoVigente();
                this.swtAlert2Service.successAlert(response.entity);
                sessionStorage.setItem("usuarioAceitouTermoUso", "1");
                setTimeout(() => {
                    this.router.navigate(['/secured/']);
                }, 3000);
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
                this.router.navigate(['/login']);
            }
        });
    }
}
