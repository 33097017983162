import * as tslib_1 from "tslib";
export class ImageHandlerService {
    constructor(imagemService, swtAlert2Service) {
        this.imagemService = imagemService;
        this.swtAlert2Service = swtAlert2Service;
    }
    inserirThumbnailsEmDetalhesMensagemParaRL(detalhes) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            detalhes.thumbnail = yield this.getPessoaImagem(detalhes.pessoaImagemId);
            return detalhes;
        });
    }
    getPessoaImagem(imagemId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.imagemService.getPessoaPhoto(imagemId);
                return response.body;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
}
