import { Usuario } from '../model/usuario.model';
import { AbstractBuilder } from './abstract.builder';


export class UsuarioBuilder extends AbstractBuilder<Usuario> {

    reset() : void {
        this.entity = new Usuario(null,null,null,null,null,null,null,null,new Array<number>(),new Array<string>());
    }

}