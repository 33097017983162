import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

//import { AcompanhamentoCasaComunitariaLocalComponent } from './acompanhamento.casacomunitaria.local.component';
import { CasaComunitariaLocalManageComponent } from './casacomunitaria.manager.component';

const casacomunitariaRoutes: Routes = [
    {
        path: 'secured/local/casacomunitaria/gerenciar/:id',
        component: CasaComunitariaLocalManageComponent
    },
    {
        path: 'secured/local/casacomunitaria/gerenciamento',
        component: CasaComunitariaLocalManageComponent
    }/*,
    {
        path: 'secured/local/casacomunitaria/:id/acomp_visita',
        component: AcompanhamentoCasaComunitariaLocalComponent
    }*/
]

@NgModule({
    imports: [
        RouterModule.forChild(casacomunitariaRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class CasaComunitariaLocalRoutingModule {
    
}