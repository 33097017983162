import { GenericService } from 'src/app/core/services/generic.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from "@angular/core";
import { SalvarMissionarioMissaoAtual } from 'src/app/model/salvarmissionariomissaoatual.model';


@Injectable()
export class MissionarioMissaoAtualService extends GenericService<SalvarMissionarioMissaoAtual> {
    
    constructor(protected http: HttpClient, protected router:Router) {
        super(http,router);
        this.setEntityType('missionariomissaoatual');
    }

    adicionarMembroNaCasaComunitaria(salvarMissionarioMissaoAtual: any) : Promise<any> {
        return this.http.post<any>(`${this.apiURL}/adicionarmembrocasacomunitaria`, JSON.stringify(salvarMissionarioMissaoAtual), { 'headers' : this.getHeaders() }).toPromise();
    } 

    removerMembroDaCasaComunitaria(removerMissionarioMissaoAtual: any) : Promise<any> {
        return this.http.post<any>(`${this.apiURL}/removermembrocasacomunitaria`, JSON.stringify(removerMissionarioMissaoAtual), { 'headers' : this.getHeaders() }).toPromise();
    } 

    listarDetalhesDeMissionariosNaMissaoPorCasaComunitaria(casaComunitariaId: number) : Promise<any> {
        return this.http.get<any>(`${this.apiURL}/listarporcasacomunitaria/${casaComunitariaId}`, { 'headers' : this.getHeaders() }).toPromise();
    }
 
    detalhamento(id: number) : Promise<any> {
        return this.http.get<any>(`${this.apiURL}/detalhamento/${id}`, { 'headers' : this.getHeaders() }).toPromise();
    }  
    
    detalhamentoPorUsuarioLogado() : Promise<any> {
        return this.http.get<any>(`${this.apiURL}/detalhamento/porusuariologado`, { 'headers' : this.getHeaders() }).toPromise();
    } 

    salvarApostolado(missionatioMissaoApostoladoAtual: any) : Promise<any> {
        return this.http.post<any>(`${this.apiURL}/apostolado`, JSON.stringify(missionatioMissaoApostoladoAtual), { 'headers' : this.getHeaders() }).toPromise();
    } 

    atualizarApostolado(missionatioMissaoApostoladoAtual: any) : Promise<any> {
        return this.http.put<any>(`${this.apiURL}/apostolado`, JSON.stringify(missionatioMissaoApostoladoAtual), { 'headers' : this.getHeaders() }).toPromise();
    } 

    detalhamentoApostolado(id: number) : Promise<any> {
        return this.http.get<any>(`${this.apiURL}/apostolado/detalhamento/${id}`, { 'headers' : this.getHeaders() }).toPromise();
    } 

    listarApostoladosPorMissionarioMissaoAtualId(id: number) : Promise<any> {
        return this.http.get<any>(`${this.apiURL}/apostolado/listar/pormissionariomissaoatual/${id}`, { 'headers' : this.getHeaders() }).toPromise();
    } 

    deletarApostolado(id: number) : Promise<any> {
        return this.http.delete<any>(`${this.apiURL}/apostolado/${id}`, { 'headers' : this.getHeaders() }).toPromise();
    } 

    inserirApostoladoEquipeFormacao(formulario: any) : Promise<any> {
        return this.http.post<any>(`${this.apiURL}/apostolado/inserirapostoladoequipeformacao`, JSON.stringify(formulario) ,{ 'headers' : this.getHeaders() }).toPromise();
    }

    findEquipeFormacaoPorCasaComunitaria(id: number) : Promise<any> {
        return this.http.get<any>(`${this.apiURL}/apostolado/equipeformacaoporcasacomunitaria/${id}`, { 'headers' : this.getHeaders() }).toPromise();
    } 

}