import { Component, OnInit } from '@angular/core';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { Envio } from 'src/app/model/envio.model';
import { EnvioLocalService } from './service/envio.local.service';
import { Regional } from 'src/app/model/regional.model';
import { Missao } from 'src/app/model/missao.model';
import { CasaComunitaria } from 'src/app/model/casacomunitaria.model';
import { Apostolado } from 'src/app/model/apostolado.model';
import { RegionalService } from '../../wop/regional/service/regional.service';
import { MissaoService } from '../../wop/missao/service/missao.service';
import { CasaComunitariaService } from '../../wop/casacomunitaria/service/casacomunitaria.service';
import { ApostoladoService } from '../../wop/dadosvocacionais/service/apostolado.service';
import { ElementoGrupoDominioLocalService } from '../grupodominio/service/elementogrupodominio.local.service';
import { ElementoGrupoDominio } from 'src/app/model/elementogrupodominio.model';

@Component({
  selector: 'envios-local',
  templateUrl: './envios.local.component.html',
})
export class EnviosLocalComponent implements OnInit {
  public envios: Array<Envio>;
  public regionais: Array<Regional>;
  public missoes: Array<Missao>;
  public casasComunitarias: Array<CasaComunitaria>;
  public apostolados: Array<Apostolado>;
  public listaStatus: Array<ElementoGrupoDominio>;
  public busca: any;
  public totalDePaginas: number;

  private statusPendenteId = 21;

  constructor(
    public envioService: EnvioLocalService,
    public regionalService: RegionalService,
    public missaoService: MissaoService,
    public casaComunitariaService: CasaComunitariaService,
    public apostoladoService: ApostoladoService,
    public elementoGrupoDominioService: ElementoGrupoDominioLocalService,
    public swtAlert2Service: SwtAlert2Service
  ) {
    this.envios = new Array<Envio>();
    this.busca = {
      nome: null,
      sobrenome: null,
      regionalId: null,
      missaoId: null,
      casaComunitariaId: null,
      apostoladoId: null,
      statusId: this.statusPendenteId,
      pageNumber: 0,
      quantityOfElements: 10,
    };
  }

  ngOnInit() {
    this.carregarRegionais();
    this.carregarStatus();
    this.carregarApostolados();
    this.buscarEnvios();
  }

  cliqueNoBotaoListener(botao) {
    this.busca.pageNumber = botao.numero - 1;
    this.buscarEnvios();
  }

  async buscarEnvios(): Promise<any> {
    try {
      let response = await this.envioService.pesquisar(this.busca);
      this.envios = response.entity;
      this.totalDePaginas = response.quantity;
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async deletarEnvio(id: number): Promise<any> {
    try {
      if (confirm('Deseja deletar este envio?')) {
        let response = await this.envioService.delete(id);
        this.swtAlert2Service.successAlert(response.message);
        this.buscarEnvios();
      }
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async carregarStatus(): Promise<any> {
    try {
      let request = {
        grupoNome: 'STATUS_ENVIO',
      };

      let response =
        await this.elementoGrupoDominioService.buscarPorGrupoDominioNome(
          request
        );
      this.listaStatus = response.entity.filter(
        (st) => st.valor.localeCompare('CONCL') != 0
      );
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async carregarRegionais(): Promise<any> {
    try {
      let response = await this.regionalService.findAll();
      this.regionais = response.entity.sort((a, b) =>
        a.nome.localeCompare(b.nome)
      );
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async carregarMissoes(): Promise<any> {
    try {
      let response = await this.missaoService.porRegional(
        this.busca.regionalId
      );
      this.missoes = response.entity.sort((a, b) =>
        a.nome.localeCompare(b.nome)
      );
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async carregarCasasComunitarias(): Promise<any> {
    try {
      let response = await this.casaComunitariaService.porMissao(
        this.busca.missaoId
      );
      this.casasComunitarias = response.entity.sort((a, b) =>
        a.nome.localeCompare(b.nome)
      );
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  async carregarApostolados(): Promise<any> {
    try {
      let response = await this.apostoladoService.pesquisar({ ativo: true });
      this.apostolados = response.entity;
    } catch (err) {
      this.swtAlert2Service.errorAlert(err.error.errors);
    }
  }

  validaStatus(id: number): boolean {
    let retorno = true;
    this.listaStatus.forEach((el) => {
      if (el.id == id) {
        if (el.valor.localeCompare('DIVUL') == 0) {
          retorno = false;
        }
      }
    });
    return retorno;
  }
}
