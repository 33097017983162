import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PermissaoService } from './service/permissao.service';
import { AutorizacaoService } from '../autorizacao/service/autorizacao.service';
import { CommonModule } from '@angular/common';
import { SecuredModule } from '../../layout/secured.module';
import { RouterModule } from '@angular/router';
import { PermissaoRoutingModule } from './permissao-routing.module';
import { PermissaoComponent } from './permissao.component';
import { PermissoesComponent } from './permissoes.component';
import { PermissaoEditComponent } from './permissao.edit.component';

@NgModule({
    declarations: [ 
        PermissaoComponent,
        PermissoesComponent,
        PermissaoEditComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        PermissaoRoutingModule,
        FormsModule,
        SecuredModule
    ],
    providers: [
        PermissaoService,
        AutorizacaoService
    ]
})
export class PermissaoModule {

}