export class RemocaoMembroCasaComunitaria {
    
    constructor(
        public missionarioMissaoAtualId: number,
        public elementoGrupoDominioMotivoSaidaId: number,
        public descricaoMotivoSaida: string,
        public dataSaida: Date
    )
    {}

}