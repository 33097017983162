import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AcessoUsuarioEstruturaComponent } from './acessousuarioestrutura.component';
import { AcessoUsuarioEstruturaRoutingModule } from './acessousuarioestrutura-routing.module';
import { AcessoUsuarioEstruturaService } from './service/acessousuarioestrutura.service';
import { AcessoUsuariosEstruturasListagemComponent } from './acessousuariosestruturas.component';
import { AcessoUsuarioEstruturaEditComponent } from './acessousuarioestrutura.edit.component';
import { SecuredModule } from '../../layout/secured.module';
import { AcessoUsuarioEstruturaBuilder } from 'src/app/builder/acessousuarioestrutura.builder';
import { AcessoUsuarioEstruturaEditCAPComponent } from './telas-cap/acessousuarioestrutura.edit.cap.component';
import { AcessoUsuariosEstruturasListagemCAPComponent } from './telas-cap/acessousuariosestruturas.cap.component';
import { AcessoUsuarioEstruturaEditAssistenteApostolicoComponent } from './telas-assistente-apostolico/acessousuarioestrutura.edit.aa.component';
import { AcessoUsuariosEstruturasListagemAssistenteApostolicoComponent } from './telas-assistente-apostolico/acessousuariosestruturas.aa.component';

@NgModule({
    declarations: [ 
        AcessoUsuarioEstruturaComponent,
        AcessoUsuarioEstruturaEditComponent,
        AcessoUsuariosEstruturasListagemComponent,
        AcessoUsuariosEstruturasListagemCAPComponent,
        AcessoUsuarioEstruturaEditCAPComponent,
        AcessoUsuarioEstruturaEditAssistenteApostolicoComponent,
        AcessoUsuariosEstruturasListagemAssistenteApostolicoComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        AcessoUsuarioEstruturaRoutingModule,
        FormsModule,
        SecuredModule
    ],
    providers: [
        AcessoUsuarioEstruturaService,
        AcessoUsuarioEstruturaBuilder
    ]
})
export class AcessoUsuarioEstruturaModule {
}