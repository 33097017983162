<div class="row">
  <div class="col-lg-3 col-6">
    <div class="small-box bg-verde-oliva">
      <div class="inner">
        <h3>{{detalhesDashboardCelula.totalCelulas}}</h3>
        <p>Total de Células</p>
      </div>
      <div class="icon">
        <i class="fas fa-thumbtack"></i>
      </div>
    </div>
  </div>
  <div class="col-lg-3 col-6">
    <div class="small-box bg-azul-petroleo-pastel">
      <div class="inner">
        <h3>{{detalhesDashboardCelula.totalMembros}}</h3>
        <p>Total de Membros</p>
      </div>
      <div class="icon">
        <i class="fas fa-users"></i>
      </div>
    </div>
  </div>
  <div class="col-lg-3 col-6">
    <div class="small-box bg-amarelo-mostarda-pastel">
      <div class="inner">
        <h3>{{detalhesDashboardCelula.totalFormadoresComunitarios}}</h3>
        <p>Total de Formadores Comunitários</p>
      </div>
      <div class="icon">
        <i class="fas fa-user-graduate"></i>
      </div>
    </div>
  </div>
  <div class="col-lg-3 col-6">
    <div class="small-box bg-bordo-pastel">
      <div class="inner">
        <h3>{{detalhesDashboardCelula.totalFormadoresAssistents}}</h3>
        <p>Total de Formadores Assistentes</p>
      </div>
      <div class="icon">
        <i class="fas fa-people-carry"></i>
      </div>
    </div>
  </div>
  <div class="col-lg-3 col-6">
    <div class="small-box bg-bege">
      <div class="inner">
        <h3>{{detalhesDashboardCelula.totalPostulantes}}</h3>
        <p>Total de Postulantes</p>
      </div>
      <div class="icon">
        <i class="fas fa-book"></i>
      </div>
    </div>
  </div>
  <div class="col-lg-3 col-6">
    <div class="small-box bg-secondary">
      <div class="inner">
        <h3>{{detalhesDashboardCelula.totalDiscipulos}}</h3>
        <p>Total de Discipulos</p>
      </div>
      <div class="icon">
        <i class="fas fa-user-edit"></i>
      </div>
    </div>
  </div>
  <div class="col-lg-3 col-6">
    <div class="small-box bg-light">
      <div class="inner">
        <h3>{{detalhesDashboardCelula.totalTemporarios}}</h3>
        <p>Total de Temporários</p>
      </div>
      <div class="icon">
        <i class="fas fa-user-clock"></i>
      </div>
    </div>
  </div>
  <div class="col-lg-3 col-6">
    <div class="small-box bg-marrom">
      <div class="inner">
        <h3>{{detalhesDashboardCelula.totalDefinitivos}}</h3>
        <p>Total de Definitivos</p>
      </div>
      <div class="icon">
        <i class="fas fa-user-check"></i>
      </div>
    </div>
  </div>
</div>