import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IgxTabsModule } from 'igniteui-angular';
import { ImageCropperModule } from 'ngx-image-cropper';
import { SecuredModule } from 'src/app/secured/layout/secured.module';
import { ListagemPessoaInfoModalComponent } from './listagempessoainfo.modal.component';

 @NgModule({
     declarations:[
        ListagemPessoaInfoModalComponent
     ],
     imports:[
        CommonModule,
        RouterModule,
        FormsModule,
        SecuredModule,
        IgxTabsModule,
        ImageCropperModule
    ],
     exports:[
        ListagemPessoaInfoModalComponent
     ]
 })
 export class ListagemPessoaInfoModalModule {
     
 }