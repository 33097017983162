import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "../../../../service/rest.service";
export class ResumoCelulaService {
    constructor(restService) {
        this.restService = restService;
    }
    obterDashboard(request) {
        return this.restService.postRequest(`${environment.moduloFormacao.urlModuloFormacao}celula/dashboard`, request);
    }
}
ResumoCelulaService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ResumoCelulaService_Factory() { return new ResumoCelulaService(i0.ɵɵinject(i1.RestService)); }, token: ResumoCelulaService, providedIn: "root" });
