import { Component, OnInit } from '@angular/core';
import { Usuario } from 'src/app/model/usuario.model';
import { UsuarioService } from './../service/usuario.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UsuarioBuilder } from 'src/app/builder/usuario.builder';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';

@Component({
    selector: 'usuario-edit-membro',
    templateUrl: './usuario.edit.membro.component.html'
})
export class UsuarioEditMembroComponent implements OnInit {

    public usuario: Usuario;
    public usuarioBuilder: UsuarioBuilder;
    public total: number;

    constructor(
        private usuarioService: UsuarioService,
        private swtAlert2Service: SwtAlert2Service,
        private route: ActivatedRoute,
        private router: Router
    ) {
        this.usuarioBuilder = new UsuarioBuilder();
        this.usuario = this.usuarioBuilder.getInstance();
    }

    ngOnInit() {    
        this.getUsuarioAtual();   
    }

    async getUsuarioAtual() : Promise<any> {

        try 
        {
            let response = await this.usuarioService.getCurrentUser();   
            this.usuario = response.entity;    
        }
        catch(e)
        {
            console.log(e);
        }

    }

    salvar(usuario: Usuario): void {    

        this.usuarioService.update(usuario.id, usuario).then((response: any) => {
            this.swtAlert2Service.successAlert(response.message);      
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert( err.error.errors);
        });

    }

}