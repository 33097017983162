import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { ConfirmacaoCadastroService } from './service/confirmacaocadastro.service';

@Component({
    selector: 'confirmacaocadastro',
    templateUrl: './confirmacao.cadastro.component.html'
})
export class ConfirmacaoCadastroComponent implements OnInit {

    public token: string;
    public mensagemConfirmacao: string;

    constructor(
        public confirmacaoCadastroService: ConfirmacaoCadastroService,
        public route: ActivatedRoute,
        public router: Router,
        public swtAlert2Service: SwtAlert2Service
    ) {
        this.mensagemConfirmacao = "Aguardando confirmação";
    }

    ngOnInit(): void {
        this.token = this.route.snapshot.paramMap.get('token');
        this.confirmacaoCadastro();
    }

    async confirmacaoCadastro() : Promise<any> {

        try
        {
            let response = await this.confirmacaoCadastroService.confirmacao(this.token);
            this.mensagemConfirmacao = response.message;
            setTimeout(() => {
                this.router.navigate(['../login']);
            }, 5000);
        }
        catch(e)
        {
            this.mensagemConfirmacao = "Não foi possível confirmar seu cadastro, entre em contato com os responsáveis do sistema."
            this.swtAlert2Service.errorAlert(e.error.errors);
            setTimeout(() => {
                this.router.navigate(['../login']);
            }, 10000);
        }

    }

}