import { GenericService } from 'src/app/core/services/generic.service';
import { HttpClient } from '@angular/common/http';
import { Estado } from 'src/app/model/estado.model';
import { Router } from '@angular/router';
import { Injectable } from "@angular/core";


@Injectable()
export class EstadoService extends GenericService<Estado> {
    
    constructor(protected http: HttpClient, protected router:Router) {
        super(http,router);
        this.setEntityType('r/estado');
    }

    buscarEstados(busca: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/porpais', JSON.stringify(busca), { 'headers' : this.getHeaders() }).toPromise();
    }

}