/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./style/styles.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "../left-sidebar-user-panel/left-sidebar-user-panel.component.ngfactory";
import * as i5 from "../left-sidebar-user-panel/left-sidebar-user-panel.component";
import * as i6 from "./left-sidebar-aside-container.component";
import * as i7 from "../../../header/service/logged-in-user-shared-data.service";
import * as i8 from "../../../../local/mensagem-rl/service/image.haddler.service";
var styles_LeftSidebarAsideContainerComponent = [i0.styles];
var RenderType_LeftSidebarAsideContainerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LeftSidebarAsideContainerComponent, data: {} });
export { RenderType_LeftSidebarAsideContainerComponent as RenderType_LeftSidebarAsideContainerComponent };
export function View_LeftSidebarAsideContainerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "aside", [["class", "main-sidebar sidebar-light-orange elevation-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "a", [["class", "brand-link navbar-orange"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["alt", "Mission\u00E1ria"], ["class", "brand-image img-circle elevation-3"], ["src", "assets/images/logo-comshalom.png"], ["style", "opacity: .8"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "brand-text font-weight-strong"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["MISSION\u00C1RIA"])), (_l()(), i1.ɵeld(6, 0, null, null, 5, "div", [["class", "sidebar scrollbar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "left-sidebar-user-panel-component", [], null, null, null, i4.View_LeftSidebarUserPanelComponent_0, i4.RenderType_LeftSidebarUserPanelComponent)), i1.ɵdid(8, 114688, null, 0, i5.LeftSidebarUserPanelComponent, [], { thumbnail: [0, "thumbnail"], nome: [1, "nome"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 2, "nav", [["class", "mt-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "ul", [["class", "nav nav-pills nav-sidebar flex-column"], ["data-accordion", "false"], ["data-widget", "treeview"], ["role", "menu"]], null, null, null, null, null)), i1.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "/secured/perfil"; _ck(_v, 2, 0, currVal_2); var currVal_3 = _co.personThumbnail; var currVal_4 = ((_co.person == null) ? null : _co.person.nome); _ck(_v, 8, 0, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).target; var currVal_1 = i1.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_LeftSidebarAsideContainerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "left-sidebar-aside-container-component", [], null, null, null, View_LeftSidebarAsideContainerComponent_0, RenderType_LeftSidebarAsideContainerComponent)), i1.ɵdid(1, 114688, null, 0, i6.LeftSidebarAsideContainerComponent, [i7.LoggedInUserSharedDataService, i8.ImageHandlerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LeftSidebarAsideContainerComponentNgFactory = i1.ɵccf("left-sidebar-aside-container-component", i6.LeftSidebarAsideContainerComponent, View_LeftSidebarAsideContainerComponent_Host_0, {}, {}, ["[menu-content]"]);
export { LeftSidebarAsideContainerComponentNgFactory as LeftSidebarAsideContainerComponentNgFactory };
