/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./mensagem-rl-content.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../message-content-component/message-content-component.component.ngfactory";
import * as i3 from "../message-content-component/message-content-component.component";
import * as i4 from "@angular/common";
import * as i5 from "./mensagem-rl-content.component";
import * as i6 from "../../service/mensagem-rl-shared-state.service";
import * as i7 from "../../service/mensagemrl.service";
var styles_MensagemRlContentComponent = [i0.styles];
var RenderType_MensagemRlContentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MensagemRlContentComponent, data: {} });
export { RenderType_MensagemRlContentComponent as RenderType_MensagemRlContentComponent };
function View_MensagemRlContentComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "main", [["id", "welcome-message"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Mensagens para o Respons\u00E1vel Local"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Clique na mensagem do formando para visualizar as mensagens enviadas."]))], null, null); }
function View_MensagemRlContentComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-message-content-component", [], null, null, null, i2.View_MessageContentComponentComponent_0, i2.RenderType_MessageContentComponentComponent)), i1.ɵdid(1, 114688, null, 0, i3.MessageContentComponentComponent, [], { source: [0, "source"], message: [1, "message"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.origem; var currVal_1 = _v.context.$implicit.mensagem; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_MensagemRlContentComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 14, "main", [["id", "member-message-content"]], null, null, null, null, null)), i1.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(4, { "make-it-visible": 0, "make-it-hidden": 1 }), (_l()(), i1.ɵeld(5, 0, null, null, 6, "div", [["class", "member-info"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "img", [["alt", "imagem do membro da comunidade"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["", ""])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "button", [["alt", "Fechar janela de mensagem"], ["class", "btn btn-danger close-message-content-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.hideMessageContent = true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "i", [["class", "far fa-window-close"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 3, "div", [["class", "messages"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 2, "div", [["class", "messages-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MensagemRlContentComponent_3)), i1.ɵdid(15, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 4, 0, ((_co.selectedSummary !== null) && (_co.hideMessageContent === false)), (_co.hideMessageContent === true)); _ck(_v, 3, 0, currVal_0); var currVal_3 = _co.mensagensMembro; _ck(_v, 15, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.imageSrc; _ck(_v, 7, 0, currVal_1); var currVal_2 = ((_co.selectedSummary == null) ? null : _co.selectedSummary.pessoaNome); _ck(_v, 9, 0, currVal_2); }); }
export function View_MensagemRlContentComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_MensagemRlContentComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MensagemRlContentComponent_2)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.selectedSummary == null); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.selectedSummary != null); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_MensagemRlContentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-mensagem-rl-content", [], null, null, null, View_MensagemRlContentComponent_0, RenderType_MensagemRlContentComponent)), i1.ɵdid(1, 245760, null, 0, i5.MensagemRlContentComponent, [i6.MensagemRlSharedStateService, i7.MensagemRLService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MensagemRlContentComponentNgFactory = i1.ɵccf("app-mensagem-rl-content", i5.MensagemRlContentComponent, View_MensagemRlContentComponent_Host_0, {}, {}, []);
export { MensagemRlContentComponentNgFactory as MensagemRlContentComponentNgFactory };
