import { GenericService } from 'src/app/core/services/generic.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from "@angular/core";
import { FormadorAssistente } from 'src/app/model/formadorassistente.model';
import { environment } from 'src/environments/environment';


@Injectable()
export class FormadorAssistenteService extends GenericService<FormadorAssistente> {
    
    constructor(protected http: HttpClient, protected router:Router) {
        super(http,router);
        this.setEntityType('formadorassistente');
    }

    buscarPorCelula(busca: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/porcelula', JSON.stringify(busca), { 'headers' : this.getHeaders() }).toPromise();
    }

    buscarPorPessoaId(id: number): Promise<any> {
        return this.http.get<any>(`${this.apiURL}/porpessoa/${id}`, { 'headers' : this.getHeaders() }).toPromise();
    }
    buscarCelulaPorPessoaId(id:number):Promise<any>{
        return this.http.get<any>(`${environment.moduloFormacao.urlModuloFormacao}api/secured/formadorassistente/celula/porpessoaid/${id}`, { 'headers' : this.getHeaders() }).toPromise();
    }

    buscarCelulaPorId(id:number):Promise<any>{
        return this.http.get<any>(`${environment.moduloFormacao.urlModuloFormacao}api/secured/formadorassistente/celula/porid/${id}`, { 'headers' : this.getHeaders() }).toPromise();
    }      

}