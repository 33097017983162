export class VinculoMissao {

    constructor(
        public id: number,
        public pessoaId: number,
        public missaoOrigemId: number,
        public missaoOrigemNome: string,
        public missaoAtualId: number,
        public missaoAtualNome: string
    )
    {}

}