export class Filho {

    constructor(
        public id: number,
        public paiId: number,
        public maeId: number,
        public isMembro: boolean,
        public filhoId: number,
        public filhoNome: string,
        public dataNascimento: Date,
        public cidadeNascimento: number,
        public cidadeNascimentoNome: string,
        public cidadeNaoCadastrada: string,
        public observacao: string
    )
    {}

}