import { GenericService } from 'src/app/core/services/generic.service';
import { HttpClient } from '@angular/common/http';
import { Permissao } from 'src/app/model/permissao.model';
import { Router } from '@angular/router';
import { Injectable } from "@angular/core";


@Injectable()
export class PermissaoLocalService extends GenericService<Permissao> {
    
    constructor(protected http: HttpClient, protected router:Router) {
        super(http,router);
        this.setEntityType('permissao');
    }

    getCurrentUser(): Promise<Permissao> {
        return this.http.get<Permissao>(this.apiURL, { 'headers' : this.getHeaders() }).toPromise();
    }

    create(entity: Permissao): Promise<Permissao> {
        return this.http.post<Permissao>(this.apiURL+'/save', entity, { 'headers' : this.getHeaders() }).toPromise();
    } 

    buscarPermissoes(busca: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/buscar', JSON.stringify(busca), { 'headers' : this.getHeaders() }).toPromise();
    }

    buscarPermissoesPorAutorizacaoId(busca: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/porautorizacao', JSON.stringify(busca), { 'headers' : this.getHeaders() }).toPromise();
    }

    buscarPermissoesNaoVinculadasPorAutorizacaoId(id: number) : Promise<any> {
        return this.http.get<any>(this.apiURL+'/notvinculated/'+id, { 'headers' : this.getHeaders() }).toPromise();
    }

    vincular(obj: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/vincular/', JSON.stringify(obj), { 'headers' : this.getHeaders() }).toPromise();
    }

    desvincular(obj: any) : Promise<any> {
        return this.http.post<any>(this.apiURL+'/desvincular/', JSON.stringify(obj), { 'headers' : this.getHeaders() }).toPromise();
    }
    
}