import { Regional } from '../model/regional.model';
import { AbstractBuilder } from './abstract.builder';


export class RegionalBuilder extends AbstractBuilder<Regional> {

    reset() : void {
        this.entity = new Regional(null,null,[],[],[]);
    }

}