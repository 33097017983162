import { AbstractBuilder } from './abstract.builder';
import { Trabalho } from '../model/trabalho.model';


export class TrabalhoBuilder extends AbstractBuilder<Trabalho> {

    reset() : void {
        this.entity = new Trabalho(null,null,null,null,null,null);
    }

}