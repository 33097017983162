import { Component, OnInit } from '@angular/core';
import { CentroEvangelizacao } from 'src/app/model/centroevangelizacao.model';
import { EstadoService } from '../estado/service/estado.service';
import { Estado } from 'src/app/model/estado.model';
import { Pais } from 'src/app/model/pais.model';
import { PaisService } from '../pais/service/pais.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ElementoGrupoDominio } from 'src/app/model/elementogrupodominio.model';
import { Missao } from 'src/app/model/missao.model';
import { Regional } from 'src/app/model/regional.model';
import { ElementoGrupoDominioService } from '../grupodominio/service/elementogrupodominio.service';
import { RegionalService } from '../regional/service/regional.service';
import { MissaoService } from '../missao/service/missao.service';
import { Cidade } from 'src/app/model/cidade.model';
import { CidadeService } from '../cidade/service/cidade.service';
import { CentroEvangelizacaoService } from '../centroevangelizacao/service/centroevangelizacao.service';
import { Projeto } from 'src/app/model/projeto.model';
import { ProjetoService } from './service/projeto.service';
import { ProjetoBuilder } from 'src/app/builder/projeto.builder';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';

@Component({
    selector: 'projetoedit',
    templateUrl: './projeto.component.html'
})
export class ProjetoEditComponent implements OnInit {

    public projeto: Projeto;
    public centrosEvangelizacao: Array<CentroEvangelizacao>;
    public fases : Array<ElementoGrupoDominio>;
    public missoes : Array<Missao>;
    public regionalSelecionadoId : number;
    public missaoSelecionadoId : number;
    public regionais: Array<Regional>;
    public paises: Array<Pais>;
    public estados: Array<Estado>;
    public cidades: Array<Cidade>;
    public projetoId: number;
    public centroEvangelizacaoId: number;
    public projetoBuilder: ProjetoBuilder;

    constructor(
        public projetoService: ProjetoService,
        public centroEvangelizacaoService: CentroEvangelizacaoService,
        public elementoGrupoDominioService: ElementoGrupoDominioService,
        public regionalService: RegionalService,
        public missaoService: MissaoService,
        public paisService: PaisService,
        public estadoService: EstadoService,
        public cidadeService: CidadeService,
        public route: ActivatedRoute,
        public router: Router,
        public swtAlert2Service: SwtAlert2Service
    ) 
    {
        this.projetoBuilder = new ProjetoBuilder();
        this.projeto = this.projetoBuilder.getInstance();
        this.centrosEvangelizacao = new Array<CentroEvangelizacao>();
        this.fases = new Array<ElementoGrupoDominio>();
        this.regionais = new Array<Regional>();
        this.missoes = new Array<Missao>();
        this.paises = new Array<Pais>();
        this.estados = new Array<Estado>();
        this.cidades = new Array<Cidade>();
    }

    ngOnInit() { 
        this.projetoId = Number(this.route.snapshot.paramMap.get('id'));
        this.carregarTiposProjeto(); 
        this.regionalSelecionadoId = null;
        this.carregarProjeto(this.projetoId);
    }

    carregarRegionais() : void {
        this.regionalService.findAll().then( (lista: any) => {
            console.log(lista);
            this.regionais = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    carregarMissoes(regionalSelecionadoId: number): void {
        this.missaoService.porRegional(regionalSelecionadoId).then((response: any) => {
            this.missoes = response.entity;          
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }

    carregarCev(missaoSelecionadoId: number): void {
        this.centroEvangelizacaoService.porMissao(missaoSelecionadoId).then((response: any) => {
             this.centrosEvangelizacao = response.entity;          
         }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
         });;
     } 

     carregarTiposProjeto() : void {

        let buscaFase = {
            grupoNome : 'TIPO_PROJETO'
        }

        this.elementoGrupoDominioService.buscarPorGrupoDominioNome(buscaFase).then( (lista: any) => {
            this.fases = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });

    }


    carregarProjeto(id : number): void {
        this.projetoService.find(id).then((response: any) => {
            this.projeto = response.entity;   
            this.regionalSelecionadoId = response.entity.missaoRegionalId;
            this.carregarRegionais();
            this.carregarMissoes(this.projeto.regionalId);
            this.carregarCev(this.projeto.centroEvangelizacaoId);
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }
    
    salvar(projeto: Projeto) : void {

        this.projetoService.update(projeto.id, projeto).then( (response:any) => {
            this.swtAlert2Service.successAlert(response.message);
            setTimeout(() => {
                this.router.navigate(['../secured/projetos']);
            }, 2000);

        }).catch( (err:any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    } 

}