import { Component, OnInit } from '@angular/core';
import { CentroEvangelizacao } from 'src/app/model/centroevangelizacao.model';

import { PaisService } from '../pais/service/pais.service';
import { EstadoService } from '../estado/service/estado.service';
import { Estado } from 'src/app/model/estado.model';
import { Pais } from 'src/app/model/pais.model';
import { Cidade } from 'src/app/model/cidade.model';
import { RegionalService } from '../regional/service/regional.service';
import { MissaoService } from '../missao/service/missao.service';
import { Missao } from 'src/app/model/missao.model';
import { Regional } from 'src/app/model/regional.model';
import { ElementoGrupoDominioService } from '../grupodominio/service/elementogrupodominio.service';
import { ElementoGrupoDominio } from 'src/app/model/elementogrupodominio.model';
import { CentroEvangelizacaoService } from '../centroevangelizacao/service/centroevangelizacao.service';
import { GrupoDeOracaoService } from './grupodeoracao.service';
import { GrupoDeOracao } from 'src/app/model/grupodeoracao.model';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { Difusao } from 'src/app/model/difusao.model';
import { DifusaoService } from '../difusao/service/difusao.service';

@Component({
    selector: 'gruposdeoracao',
    templateUrl: './gruposdeoracao.component.html'
})
export class GruposDeOracaoComponent implements OnInit {

    public fases : Array<ElementoGrupoDominio>;
    public publicos : Array<ElementoGrupoDominio>;
    public estados : Array<Estado>;
    public paises : Array<Pais>;
    public cidades: Array<Cidade>;
    public regionais: Array<Regional>;
    public missoes: Array<Missao>;
    public difusoes: Array<Difusao>;
    public centrosEvangelizacao: Array<CentroEvangelizacao>;
    public gruposDeOracao: Array<GrupoDeOracao>;
    public busca: any;
    public totalDePaginas: number;

    constructor(
        public regionalService: RegionalService,
        public missaoService: MissaoService,
        public difusaoService: DifusaoService,
        public paisService: PaisService,
        public estadoService: EstadoService,
        public centroEvangelizacaoService: CentroEvangelizacaoService,
        public grupoDeOracaoService: GrupoDeOracaoService,
        public elementoGrupoDominioService: ElementoGrupoDominioService,
        public swtAlert2Service: SwtAlert2Service
    ) 
    {
        this.fases = new Array<ElementoGrupoDominio>();
        this.publicos = new Array<ElementoGrupoDominio>();
        this.estados = new Array<Estado>();
        this.paises = new Array<Pais>();
        this.cidades = new Array<Cidade>();
        this.regionais = new Array<Regional>();
        this.missoes = new Array<Missao>();
        this.difusoes = new Array<Difusao>();
        this.centrosEvangelizacao = new Array<CentroEvangelizacao>();    
            
        this.busca = {            
            nome: null,
            ano: null,
            faseId: null,
            publicoId: null,
            regionalId: null,
            missaoId: null,
            difusaoId: null,
            paisId: null,
            estadoId: null,
            centroEvangelizacaoId: null,
            grupoEncerrado: null,
            pageNumber : 0, 
            quantityOfElements : 10
        };
    }

    ngOnInit() {
        this.carregarRegionais();  
        this.carregarFasesDoGrupoDeOracao();  
        this.carregarPublicosDoGrupoDeOracao(); 
        this.carregarPaises(); 
        this.buscarGrupoDeOracao();
    }   

    cliqueNoBotaoListener(botao) {
        this.busca.pageNumber = botao.numero-1;
        this.buscarGrupoDeOracao();
    }

    carregarRegionais() : void {
        this.regionalService.findAll().then( (lista: any) => {
            this.regionais = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    carregarCevPorMissao(missaoId : number): void {
        this.centroEvangelizacaoService.porMissao(missaoId).then((response: any) => {
             this.centrosEvangelizacao = response.entity;          
         }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
         });;
    }

    carregarCevPorDifusao(difusaoId : number): void {
        this.centroEvangelizacaoService.porDifusao(difusaoId).then((response: any) => {
             this.centrosEvangelizacao = response.entity;          
         }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
         });;
    }

    carregarMissoes(regionalSelecionadoId: number): void {
        this.missaoService.porRegional(regionalSelecionadoId).then((response: any) => {
            this.missoes = response.entity;          
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    carregarDifusoes(regionalSelecionadoId: number): void {
        this.difusaoService.porRegional(regionalSelecionadoId).then((response: any) => {
            this.difusoes = response.entity;          
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    carregarFasesDoGrupoDeOracao() : void {

        let buscaFase = {
            grupoNome : 'FASE_GRUPO_DE_ORACAO'
        }

        this.elementoGrupoDominioService.buscarPorGrupoDominioNome(buscaFase).then( (lista: any) => {
            this.fases = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });

    }

    carregarPublicosDoGrupoDeOracao() : void {

        let buscaPublico = {
            grupoNome : 'PUBLICO_GRUPO_DE_ORACAO'
        }

        this.elementoGrupoDominioService.buscarPorGrupoDominioNome(buscaPublico).then( (lista: any) => {
            this.publicos = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });

    }

    carregarPaises() : void {
        this.paisService.findAll().then( (lista: any) => {
            this.paises = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    carregarEstados(paisSelecionadoId : number) : void {

        let busca = {
            paisId: paisSelecionadoId,
            nome: undefined,
            pageNumber : 0, 
            quantityOfElements : 1000
        };

        this.estadoService.buscarEstados(busca).then( (lista: any) => {
            this.estados = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });

    }


    buscarGrupoDeOracao() : void {
        this.grupoDeOracaoService.buscarGruposDeOracao(this.busca).then( (response: any) => {
            this.gruposDeOracao = response.entity;
            this.totalDePaginas = response.quantity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }
    
    deletarGrupoDeOracao(id: number) : void {

        if(confirm('Deseja deletar este grupo de oração?'))
        {
            this.grupoDeOracaoService.delete(id)
            .then( (response: any) => {
                this.swtAlert2Service.successAlert(response.message);
                this.buscarGrupoDeOracao();
            })
            .catch((err) => {
                this.swtAlert2Service.errorAlert(err.error.errors);
            });
        }
    }
}