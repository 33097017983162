import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { SecuredModule } from '../../layout/secured.module';
import { PessoaService } from '../pessoa/service/pessoa.service';
import { IgxTabsModule } from 'igniteui-angular';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ImagemService } from '../imagem/service/imagem.service';
import { EnderecoService } from '../endereco/service/endereco.service';
import { DocumentoService } from '../documento/service/documento.service';
import { ObraService } from '../obra/service/obra.service';
import { MembroComunidadeAliancaService } from '../membrocomunidadealianca/servico/membrocomunidadealianca.service';
import { MembroComunidadeVidaService } from '../membrocomunidadevida/servico/membrocomunidadevida.service';
import { AptidaoService } from '../aptidao/service/aptidao.service';
import { EscolaridadeService } from '../escolaridade/service/escolaridade.service';
import { TrabalhoService } from '../trabalho/service/trabalho.service';
import { RelacionamentosEditComponent } from './relacionamentos.edit.component';
import { RelacionamentosRoutingModule } from './relacionamentos-routing.module';

 @NgModule({
     declarations:[
         RelacionamentosEditComponent
     ],
     imports:[
         CommonModule,
         RouterModule,
         RelacionamentosRoutingModule,
         FormsModule,
         SecuredModule,
         IgxTabsModule,
         ImageCropperModule
     ],
     providers:[
         PessoaService,
         EnderecoService,
         ImagemService,
         DocumentoService,
         ObraService,
         MembroComunidadeAliancaService,
         MembroComunidadeVidaService,
         AptidaoService,
         EscolaridadeService,
         TrabalhoService
     ]
 })
 export class RelacionamentosModule {
     
 }