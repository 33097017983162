import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
export class AceiteTermoTratamentoDadosComponent {
    constructor(cadastroPublicoService, aceiteTermoTratamentoDadosService, termoTratamentoDadosBuilder, aceiteTermoTratamentoDadosBuilder, swtAlert2Service, router) {
        this.cadastroPublicoService = cadastroPublicoService;
        this.aceiteTermoTratamentoDadosService = aceiteTermoTratamentoDadosService;
        this.termoTratamentoDadosBuilder = termoTratamentoDadosBuilder;
        this.aceiteTermoTratamentoDadosBuilder = aceiteTermoTratamentoDadosBuilder;
        this.swtAlert2Service = swtAlert2Service;
        this.router = router;
        this.termoVigente = this.termoTratamentoDadosBuilder.getInstance();
        this.aceiteTermoUso = this.aceiteTermoTratamentoDadosBuilder.getInstance();
    }
    ngOnInit() {
        this.initialize();
    }
    initialize() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.didUserAcceptDataProcessingTerm();
            yield this.carregarTermoVigente();
        });
    }
    didUserAcceptDataProcessingTerm() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.aceiteTermoTratamentoDadosService.usuarioAceitouTermo();
                let usuarioAceitouTermoTratamentoDados = response.entity;
                if (usuarioAceitouTermoTratamentoDados) {
                    this.router.navigate(['/secured/']);
                }
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
                this.router.navigate(['/login']);
            }
        });
    }
    carregarTermoVigente() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.cadastroPublicoService.getTermoTratamentoDadosVigente();
                this.termoVigente = response.entity;
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    aceitarTermoTratamentoDados() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.aceiteTermoTratamentoDadosService.aceitarTermoVigente();
                this.swtAlert2Service.successAlert(response.entity);
                sessionStorage.setItem("usuarioAceitouTermoTratamentoDados", "1");
                setTimeout(() => {
                    this.router.navigate(['/secured/']);
                }, 3000);
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
                this.router.navigate(['/login']);
            }
        });
    }
}
