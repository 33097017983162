<div class="modal fade" [id]="modalId">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">{{modalTitle}}</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12">
                            <form (ngSubmit)="removerMembroCasaComunitaria(membroCasaComunitaria)" #removerMembroCasaComunitariaForm="ngForm" novalidate>
                                <div class="card">
                                    <div class="card-header">
                                        <h6 class="card-title">Formulário de remoção</h6>
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-sm-12 col-md-4 col-lg-4">
                                                <div class="form-group">
                                                    <label i18n for="lpessoa_nome">Nome do Missionário</label>
                                                    <br>
                                                    {{membroCasaComunitaria?.pessoaNome}}
                                                </div>
                                            </div>                                            
                                            <div class="col-sm-12 col-md-4 col-lg-4">
                                                <div class="form-group">
                                                    <label i18n for="data_saida">Data de Saída</label>
                                                    <input type="date" name="data_saida" class="form-control" [(ngModel)]="remocaoMembroCasaComunitaria.dataSaida">
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-4 col-lg-4">
                                                <div class="form-group">
                                                    <label i18n for="lista_motivo_saida">Motivo da saída</label>
                                                    <select class="form-control" id="lista_motivo_saida" [(ngModel)]="remocaoMembroCasaComunitaria.elementoGrupoDominioMotivoSaidaId" (change)="exibirSeMotivoSaidaForOutro(remocaoMembroCasaComunitaria.elementoGrupoDominioMotivoSaidaId)" name="lista_motivo_saida">
                                                        <option [value]="null" i18n>Selecione o motivo</option>
                                                        <option *ngFor="let motivo of motivosSaida" [value]="motivo.id">{{motivo.nome}}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12 col-lg-12" *ngIf="exibirCampoDescricaoMotivoSaida == true">
                                                <div class="form-group">
                                                    <label i18n for="descricao_motivo_outro">Descrição do Motivo da Saída</label>
                                                    <textarea rows="5" class="form-control" [(ngModel)]="remocaoMembroCasaComunitaria.descricaoMotivoSaida" name="descricao_motivo_outro" placeholder="Descreva o motivo da saída">
                                                    </textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-footer">
                                        <button type="submit" class="btn btn-primary float-right" i18n>Registrar Saída</button>
                                        <button type="reset" class="btn btn-default float-right" style="margin-right: 0.5rem;" i18n>Limpar</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer justify-content-right">
                <button type="button" class="btn btn-default" data-dismiss="modal">Fechar</button>
            </div>
        </div>
    </div>
</div>