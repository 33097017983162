import { Component } from '@angular/core';
import { AutorizacaoLocalService } from './service/autorizacao.local.service';
import { Autorizacao } from 'src/app/model/autorizacao.model';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { AutorizacaoBuilder } from 'src/app/builder/autorizacao.builder';

@Component({
    selector: 'autorizacao-local',
    templateUrl: './autorizacao.local.component.html'
})
export class AutorizacaoLocalComponent{

    public autorizacao: Autorizacao;
    public autorizacaoBuilder: AutorizacaoBuilder;

    constructor(
        public autorizacaoService: AutorizacaoLocalService,
        public swtAlert2Service: SwtAlert2Service
    ) 
    {
        this.autorizacaoBuilder = new AutorizacaoBuilder();
        this.autorizacao = this.autorizacaoBuilder.getInstance();
    }

    salvar(autorizacao: Autorizacao) : void {

        this.autorizacaoService.create(autorizacao).then( (response:any) => {
            this.autorizacao = this.autorizacaoBuilder.getInstance();
            this.swtAlert2Service.successAlert(response.message);

        }).catch( (err:any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

}