<secured>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h3 i18n>Parecer do Formador Comunitário 1º Questionario | Pastoreio | D2 - CV</h3>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="card card-primary card-outline card-outline-tabs">
                <div class="card-header p-0 border-bottom-0">
                    <ul class="nav nav-tabs" id="tabs-questionario-form" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" id="dados-pessoais-tab" data-toggle="pill" href="#dados-pessoais-tab-content" role="tab" aria-controls="dados-pessoais-tab" aria-selected="true">Dados Pessoais</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="questionario-tab" data-toggle="pill" href="#questionario-tab-content" role="tab" aria-controls="questionario-tab" aria-selected="false">Questionário</a>
                        </li>
                    </ul>
                </div>
                <div class="card-body">
                    <div class="tab-content" id="tabs-pessoa-formContent">
                        <div class="tab-pane fade show active" id="dados-pessoais-tab-content" role="tabpanel" aria-labelledby="dados-pessoais-tab">
                            <form #dadosPessoaisForm="ngForm" (ngSubmit)="salvar()" novalidate>
                                <div class="row">
                                    <div *ngIf="(formando.id != undefined)" class="col-sm-3 col-md-3 col-lg-3">
                                        <div style="padding: 1rem 0rem 1rem 1rem;">
                                            <div class="card card-default">
                                                <div class="card-body">
                                                    <div class="text-center" style="margin: 1rem auto 2rem;">
                                                        <div class="widget-user-image">
                                                            <img class="profile-user-img img-fluid img-circle elevation-2" [src]="formando.thumbnail == null ? 'assets/images/ppl.png' : formando.thumbnail" alt="Imagem da pessoa" style="width: auto;">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div [ngClass]="(formando.id != undefined) ? 'col-sm-8 col-md-8 col-lg-8' : 'col-sm-12 col-md-12 col-lg-12'">
                                        <div style="padding: 1rem;">
                                            <div class="card card-default">
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md-6 col-lg-6">
                                                            <div class="form-group">
                                                                <label i18n for="nformando_nome">Nome</label>
                                                                {{formando.nome}}
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-6 col-lg-6">
                                                            <div class="form-group">
                                                                <label i18n for="nformando_sobrenome">Sobrenome</label>
                                                                {{formando.sobrenome}}
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                                            <div class="form-group">
                                                                <label i18n for="pais">País de Origem</label>
                                                                <select disabled class="form-control" id="pais"
                                                                    [(ngModel)]="dadoFormandoCV.pais_origem_id"
                                                                    (change)="carregarEstados(dadoFormandoCV.pais_origem_id)"
                                                                    name="pais" required>
                                                                    <option [value]="null" i18n>
                                                                        Selecione um País</option>
                                                                    <option *ngFor="let pais of paises"
                                                                        [value]="pais.id">{{pais.nome}}
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                                            <div class="form-group">
                                                                <label i18n for="nestado">Estado de Origem</label>
                                                                <select disabled class="form-control"
                                                                    id="nestado"
                                                                    [(ngModel)]="dadoFormandoCV.estado_origem_id"
                                                                    (change)="carregarCidades(dadoFormandoCV.estado_origem_id)"
                                                                    name="nestado" required>
                                                                    <option [value]="null" i18n>
                                                                        Selecione um Estado</option>
                                                                    <option
                                                                        *ngFor="let estado of estados"
                                                                        [value]="estado.id">
                                                                        {{estado.nome}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                                            <div class="form-group">
                                                                <label i18n for="ncidade_origem_id">Cidade de Origem</label>
                                                                <select  disabled class="form-control"
                                                                    id="ncidade_origem_id"
                                                                    [(ngModel)]="dadoFormandoCV.cidade_origem_id"
                                                                    name="ncidade_origem_id" required>
                                                                    <option [value]="null" i18n>
                                                                        Selecione uma Cidade</option>
                                                                    <option
                                                                        *ngFor="let cidade of cidades"
                                                                        [value]="cidade.id">
                                                                        {{cidade.nome}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-12 col-lg-12">
                                                            <div class="form-group">
                                                                <label for="cidade_nao_cadastrada">Se
                                                                    sua cidade não estiver cadastrada,
                                                                    escreva abaixo o País, Estado e
                                                                    Cidade</label>
                                                                {{dadoFormandoCV.cidade_nao_cadastrada}}
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-6 col-lg-6">
                                                            <div class="form-group">
                                                                <label i18n for="nformando_missao_atual">Missão Atual</label>
                                                                <select disabled class="form-control" id="nformando_missao_atual" [(ngModel)]="formando.vinculoMissao.missaoAtualId" name="nformando_missao_atual">
                                                                    <option disabled value="null" i18n>Selecione uma missão</option>
                                                                    <option *ngFor="let missao of missoesVinculo" [value]="missao.id">{{missao.nome}}</option>
                                                                </select>
                                                            </div>
                                                        </div>                                                        
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <label for="form_comunitario">Formador Comunitário</label>
                                                                {{caminhoFormativoFormando.nome_formador_comunitario}}
                                                            </div>
                                                        </div>                                                        
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <label for="form_comunitario">E-mail do Formador Comunitário</label>
                                                                {{usuarioAtual.email}}
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <label for="form_comunitario">Telefone do Formador Comunitário (WhatsApp):</label>
                                                                <input value="" name="form_comunitario" type="text" class="form-control" id="form_comunitario" placeholder="Digite..." i18n-placeholder required>
                                                                <!--span class="form-control"
                                                                    name="form_comunitario">{{pessoaFormadorComunitarioSelecionado.nome}}
                                                                    {{pessoaFormadorComunitarioSelecionado.sobrenome}}</span-->
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                                            <div class="form-group">
                                                                <label i18n for="nacomp_comunitario">Nº de Acompanhamentos Comunitários</label>
                                                                <input [(ngModel)]="caminhoFormativoFormando.qtde_acompanhamento_comunitario" name="nacomp_comunitario" type="text" class="form-control" id="nacomp_comunitario" placeholder="Digite..." i18n-placeholder required>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card-footer text-right">
                                                    <button type="submit" class="btn" [ngClass]="formando.id != undefined ? 'btn-primary' : 'btn-success'" [disabled]="!dadosPessoaisForm.valid" i18n>Salvar</button>&nbsp;
                                                    <button type="button" class="btn btn-default" (click)="activateTab('questionario-tab-content')" *ngIf="formando.id != undefined" [disabled]="!dadosPessoaisForm.valid">Proximo</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>                                
                            </form>
                        </div>
                        <div class="tab-pane fade" id="questionario-tab-content" role="tabpanel" aria-labelledby="questionario-tab">
                            <form *ngIf="formando.id != undefined" #questionarioForm="ngForm" (ngSubmit)="salvarQuestionario()" novalidate>
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <h4 class="card-title">A partir de agora responda com sinceridade e transparência as seguintes perguntas, ajudando a
                                            Comunidade na formação dos discípulos.
                                        </h4>
                                    </div>
                                </div>  
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <div class="form-group">        
                                            <label for="descricao">1. Partilhe um pouco sobre a chegada do(a) discípulo(a) na missão, sobre o seu
                                                processo de adaptação (se o(a) discípulo(a) estiver em tempo de prova, partilhe sobre
                                                como ele(a) reagiu ao discernimento)</label>
                                            <input [(ngModel)]="resposta1.resposta" type="text" id="resposta1" name="resposta1" required class="form-control">
                                        </div>
                                    </div>
                                </div> 
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <h4 class="card-title">2. Como percebe o(a) discípulo(a) nos seguintes aspectos:</h4>
                                    </div>
                                </div>                                          
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <div class="form-group">        
                                            <label for="descricao">a) Vida de Oração (zelo e compromisso com sua vida de oração,
                                                respeito pelo sagrado, silêncio da manhã, oração comunitária, eucaristia diária, terço
                                                diário etc.):</label>
                                            <input [(ngModel)]="resposta2.resposta" type="text" id="resposta2" name="resposta2" required class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <div class="form-group">        
                                            <label for="descricao">b) Vida Comunitária (capacidade de diálogo, relacionamentos dentro da casa
                                                comunitária e com as autoridades, capacidade de amar e sacrificar-se em vista do
                                                outro):</label>
                                            <input [(ngModel)]="resposta3.resposta" type="text" id="resposta3" name="resposta3" required class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <div class="form-group">        
                                            <label for="descricao">c) Evangelização (zelo apostólico e ardor evangelizador, acolhimento e responsabilidade
                                                dos apostolados confiados, relacionamentos com os irmãos da Obra):</label>
                                            <input [(ngModel)]="resposta4.resposta" type="text" id="resposta4" name="resposta4" required class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md-4 col-lg-4">
                                        <div class="form-group">
                                            <div class="checkbox icheck-concrete">
                                                <input [(ngModel)]="resposta5.resposta" type="checkbox" id="resposta5" name="resposta5"
                                                        />&nbsp;
                                                <label class="form-check-label" for="resposta5">Ele renovou o celibato formativo?</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-12 col-md-8 col-lg-8">
                                        <div class="form-group">        
                                            <label for="descricao">d) Como você percebe que o (a) discípulo(a) tem respondido à castidade? Como tem sido sua adesão e resposta ao celibato formativo?</label>
                                            <input [(ngModel)]="resposta6.resposta" type="text" id="resposta6" name="resposta6" required class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <div class="form-group">        
                                            <label for="descricao">e) Como você percebe que o discípulo tem respondido à obediência?</label>
                                            <input [(ngModel)]="resposta7.resposta" type="text" id="resposta7" name="resposta7" required class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <div class="form-group">        
                                            <label for="descricao">f) Como você percebe que o discípulo tem respondido à pobreza?</label>
                                            <input [(ngModel)]="resposta8.resposta" type="text" id="resposta8" name="resposta8" required class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <div class="form-group">        
                                            <label for="descricao">3. Como você percebe o relacionamento do(a) discípulo(a) com as autoridades da
                                                missão? Você acredita que elas o(a) têm ajudado nas dificuldades próprias deste
                                                tempo?
                                                </label>
                                            <input [(ngModel)]="resposta9.resposta" type="text" id="resposta9" name="resposta9" required class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <div class="form-group">        
                                            <label for="descricao">4. Como você acredita que o(a) discípulo(a) tem vivido o processo de discernimento do
                                                seu estado de vida?</label>
                                            <input [(ngModel)]="resposta10.resposta" type="text" id="resposta10" name="resposta10" required class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <div class="form-group">        
                                            <label for="descricao">5. Quais os horários da formação do(a) discípulo(a)? Eles são assistidos em relação ao
                                                material definido (Estudo Bíblico e leituras próprias)? Você o tem acompanhado em
                                                relação a isso? Há atrasos? O que será feito para pôr a formação específica dele em
                                                dia?
                                                </label>
                                            <input [(ngModel)]="resposta11.resposta" type="text" id="resposta11" name="resposta11" required class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <div class="form-group">        
                                            <label for="descricao">6. Você vê que a formação pessoal tem sido proveitosa ao(à) discípulo(a), tem lhe
                                                ajudado?
                                                </label>
                                            <input [(ngModel)]="resposta12.resposta" type="text" id="resposta12" name="resposta12" required class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <div class="form-group">        
                                            <label for="descricao">7. Você considera que o(a) discípulo(a) tem crescido, dado passos e respostas próprias
                                                do tempo de formação que vive, isto é, o caminho para as Primeiras Promessas?
                                                Comente.
                                                </label>
                                            <input [(ngModel)]="resposta13.resposta" type="text" id="resposta13" name="resposta13" required class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <div class="form-group">        
                                            <label for="descricao">8. Você tem conhecimento dos pontos que precisam ser trabalhados na vida deste
                                                irmão(ã) enviados pelo Discipulado de origem dele? Obs.: No caso do(a) discípulo(a)estar em tempo de prova, partilhe se ele(a) tem consciência dos motivos que o(a)
                                                fizeram permanecer e se o(a) tem trabalhado.                                                            
                                                </label>
                                            <input [(ngModel)]="resposta14.resposta" type="text" id="resposta14" name="resposta14" required class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <div class="form-group">        
                                            <label for="descricao">9. Você tem conhecimento de dívidas abertas, contas em banco ou cartão de crédito
                                                que ele (a) ainda tenha no seu nome? Isso é importante para os compromisso que vai
                                                assumir na vida consagrada.                                                            
                                                </label>
                                            <input [(ngModel)]="resposta15.resposta" type="text" id="resposta15" name="resposta15" required class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <div class="form-group">        
                                            <label for="descricao">10. Como você percebe a qualidade da saúde física e emocional do(a) discípulo(a)?
                                                Você tocou em realidades afetivas ou psicológicas delicadas na vida deste irmão(ã)?
                                                Percebeu sinais de ansiedade ou qualquer outro problema de saúde? Em caso positivo,
                                                como você vê que o discípulo lida com essa realidade?                                                            
                                                </label>
                                            <input [(ngModel)]="resposta16.resposta" type="text" id="resposta16" name="resposta16" required class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <div class="form-group">        
                                            <label for="descricao">11. O Discípulo está com processo de discernimento vocacional aberto? Se sim,
                                                partilhe um pouco sobre as razões, se o processo foi aberto no discipulado ou na
                                                missão, como ele tem vivido. Como você o tem conduzido?
                                                </label>
                                            <input [(ngModel)]="resposta17.resposta" type="text" id="resposta17" name="resposta17" required class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-sm-12 col-md12 col-lg-12">
                                        <div class="form-group">        
                                            <label for="descricao">12. Há algo mais que você deseja partilhar sobre a vida deste(a) irmão(ã)? (alguma
                                                questão familiar que julgue importante, algum episódio que possa ter ocorrido durante
                                                estes meses, ou fato que ele tenha expressado só agora) Escreva abaixo.
                                                </label>
                                            <input [(ngModel)]="resposta18.resposta" type="text" id="resposta18" name="resposta18" class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="card-footer text-right">
                                    <button type="button" class="btn btn-default float-left" style="margin-left: 0.5rem;" (click)="activateTab('dados-pessoais-tab-content')" i18n>Voltar</button>
                                    <button type="submit" class="btn btn-primary" [disabled]="questionarioForm.invalid" i18n>Salvar</button>&nbsp;
                                    <button type="button" class="btn btn-default" [disabled]="questionarioForm.invalid" (click)="enviarQuestionario()" i18n>Enviar</button>
                                    <!--button type="button" class="btn btn-default" [disabled]="questionarioForm.invalid || endereco.id == undefined" (click)="activateTab('endereco-tab-content')" i18n>Proximo</button-->
                                </div>
                            </form>
                        </div>                            
                    </div>
                </div>
            </div>
        </section>
    </div>
    <!-- /.modal-dialog -->
</secured>