import { GrupoDeOracao } from "./grupodeoracao.model";

export class GrupoDeOracaoVinculoOrigem {

    constructor(
        public id: number,
        public grupoId: number,
        public gruposFonteOrigem: Array<GrupoDeOracao>
    ) {}

}