<secured>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h3 i18n>Listagem das Difusões</h3>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12">
                        <form (ngSubmit)="buscar()" #permissoesForm="ngForm" novalidate>
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title">Buscar Difusões</h3>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label i18n for="ldifusao_r">Regional</label>
                                                <select class="form-control" id="ldifusao_r" [(ngModel)]="busca.regionalId" name="ldifusao_r">
                                                            <option [value]="null" i18n>Selecione um Regional</option>
                                                            <option *ngFor="let reg of regionais" [value]="reg.id">{{reg.nome}}</option>
                                                        </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label i18n for="ldifusao_pais">País</label>
                                                <select class="form-control" id="ldifusao_pais" [(ngModel)]="busca.paisId" (change)="carregarEstados()" name="ldifusao_pais">
                                                            <option [value]="null" i18n>Selecione um País</option>
                                                            <option *ngFor="let pais of paises" [value]="pais.id">{{pais.nome}}</option>
                                                        </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label i18n for="ldifusao_estado">Estado</label>
                                                <select class="form-control" id="ldifusao_estado" [(ngModel)]="busca.estadoId" (change)="carregarCidades()" name="ldifusao_estado">
                                                            <option [value]="null" i18n>Selecione um Estado</option>
                                                            <option *ngFor="let estado of estados" [value]="estado.id">{{estado.nome}}</option>
                                                        </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label i18n for="ldifusao_cidade">Cidade</label>
                                                <select class="form-control" id="ldifusao_cidade" [(ngModel)]="busca.cidadeId" name="ldifusao_cidade">
                                                            <option [value]="null" i18n>Selecione uma Cidade</option>
                                                            <option *ngFor="let cidade of cidades" [value]="cidade.id">{{cidade.nome}}</option>
                                                        </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label i18n for="ldifusao_nome">Nome da Difusão</label>
                                                <input [(ngModel)]="busca.nomeDifusao" name="ldifusao_nome" type="text" class="form-control" id="ldifusao_nome" placeholder="Digite o nome" i18n-placeholder>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label i18n for="ldifusao_etapa">Etapa da Difusão</label>
                                                <select class="form-control" id="ldifusao_etapa" [(ngModel)]="busca.elementoGrupoDominioEtapaDifusaoId" name="ldifusao_etapa">
                                                            <option [value]="null" i18n>Selecione uma etapa</option>
                                                            <option *ngFor="let etapa of etapasDifusao" [value]="etapa.id">{{etapa.nome}}</option>
                                                        </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label i18n for="ldifusao_f">Fase da Difusão</label>
                                                <select class="form-control" id="ldifusao_f" [(ngModel)]="busca.elementoGrupoDominioFaseDifusaoId" name="ldifusao_f">
                                                            <option [value]="null" i18n>Selecione uma fase</option>
                                                            <option *ngFor="let fase of fasesDifusao" [value]="fase.id">{{fase.nome}}</option>
                                                        </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 col-md-3 col-lg-3">
                                            <div class="form-group">
                                                <label i18n for="ldifusao_dtfund">Data de Fundação</label>
                                                <input [(ngModel)]="busca.dataFundacaoField" name="ldifusao_dtfund" type="date" class="form-control" id="ldifusao_dtfund" i18n-placeholder>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-footer">
                                    <button type="submit" class="btn btn-primary float-right" i18n>Buscar</button>
                                    <button type="reset" class="btn btn-default float-right" style="margin-right: 0.5rem;" i18n>Limpar</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">

                        <table-component [tituloSessao]="'Difusões'" [cols]="8" [rows]="busca.quantityOfElements" [totalDePaginas]="totalDePaginas" (eventoBotao)="cliqueNoBotaoListener($event)">
                            <tr tableheader>
                                <th style="width: 10px">#</th>
                                <th>Difusão</th>
                                <th>Regional</th>
                                <th>Cidade</th>
                                <th>Etapa da Difusão</th>
                                <th>Fase da Difusão</th>
                                <th>Data da Fundação</th>
                                <th></th>
                            </tr>
                            <tr tablebody *ngFor="let entity of entities">
                                <td>{{entity.id}}</td>
                                <td>{{entity.nome}}</td>
                                <td>{{entity.regionalNome}}</td>
                                <td>{{entity.cidadeNome}}</td>
                                <td>{{entity.etapaDifusaoNome}}</td>
                                <td>{{entity.faseDifusaoNome}}</td>
                                <td>{{entity.dataFundacao | date: 'dd/MM/yyyy'}}</td>
                                <td class="text-center">
                                    <!--button class="btn btn-sm btn-default ml-1" routerLink="../../../local/difusao/{{entity.id}}/gerenciar" title="Gerenciar Difusão" alt="Gerenciar Difusão"><i class="fas fa-cog"></i></button-->
                                    <button class="btn btn-sm btn-primary" routerLink="../difusao/{{entity.id}}" title="Editar difusão" alt="Editar difusão"><i class="fas fa-edit"></i></button>&nbsp;
                                    <!--button class="btn btn-sm btn-danger" (click)="deletar(entity.id)" title="Deletar difusão" alt="Deletar difusão"><i class="fas fa-trash-alt"></i></button-->
                                </td>
                            </tr>
                        </table-component>
                    </div>
                </div>
            </div>
        </section>
    </div>
</secured>