import { GrupoDeOracaoVinculoOrigem } from "./grupodeoracaovinculoorigem.model";

export class GrupoDeOracao {

    constructor(
        public id: number,
        public nome: string,
        public faseGrupoDeOracaoId: number,
        public faseGrupoDeOracaoNome: string,
        public publicoGrupoDeOracaoId: number,
        public publicoGrupoDeOracaoNome: string,
        public dataFundacao: Date,
        public centroEvangelizacaoId: number,
        public centroEvangelizacaoNome: string,
        public regionalId: number,
        public missaoId: number,
        public difusaoId: number,
        public grupoVinculoOrigem: GrupoDeOracaoVinculoOrigem,
        public grupoEncerrado: boolean,
        public dataEncerramento: Date
    ) {}

}