export class DadoFormandoCV {

    constructor(
        public id: number,
        public pessoaId: number,
        public cidade_origem_id: number,
        public estado_origem_id: number,
        public pais_origem_id: number,
        public cidade_nao_cadastrada: number,
        public discipulado_origem_id: number     
    ){}

}