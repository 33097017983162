import { GenericService } from 'src/app/core/services/generic.service';
import { HttpClient } from '@angular/common/http';
import { Missionario } from 'src/app/model/missionario.model';
import { Router } from '@angular/router';
import { Injectable } from "@angular/core";

@Injectable()
export class MissionarioService extends GenericService<Missionario> {
    
    
    constructor(protected http: HttpClient, protected router:Router) {
        super(http,router);
        this.setEntityType('r/pessoa');
    }
    
    buscarMissionarios(busca: any) {
        return this.http.post<any>(this.apiURL+'/buscar/missionarios', JSON.stringify(busca), { 'headers' : this.getHeaders() }).toPromise();
    }

} 