import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { SecuredModule } from '../../layout/secured.module';
import { IgxTabsModule } from 'igniteui-angular';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ParecerComponent } from './parecer.component';
import { DadoFormandoCVService } from './service/dadoformandocv.service';
import { CaminhoFormativoService } from './service/caminhoformativo.service';
import { DadoFormandoCVBuilder } from 'src/app/builder/dadoformandocv.builder';
import { CaminhoFormativoBuilder } from 'src/app/builder/caminhoformativo.builder';
import { ParecerRoutingModule } from './parecer-routing.module';
import { ParecerD2FCAnswerComponent } from './formador_comunitario/parecerd2-fc-answer.component';
import { ParecerResponsavelService } from './service/parecerresponsavel.service';
import { ParecerResponsavelBuilder } from 'src/app/builder/parecerresponsavel.builder';
import { ParecerService } from './service/parecer.service';
import { RespostaParecerBuilder } from 'src/app/builder/respostaparecer.builder';
import { RespostaParecerService } from './service/respostaparecer.service';
import { ParecerAgradecimentoComponent } from './formador_comunitario/parecer-agradecimento.component';
import { ParecerCelulaComponent } from './parecer.celula.component';
import { ParecerCelulaAutoridadeComponent } from './parecer.celula.autoridade.component';
import { ParecerCoordenadorColegiadoCelulaComponent } from './coordenador_colegiado/parecer.cc.celula.component';
import { ParecerCALFCAnswerComponent } from './formador_comunitario/parecer-cal-fc-answer.component';
import { DadoFormandoCABuilder } from 'src/app/builder/dadoformandoca.builder';
import { DadoFormandoCAService } from './service/dadoformandoca.service';
import { ParecerCoordenadorColegiadoViewComponent } from './coordenador_colegiado/parecer-cc-view.component';
import { ListagemPessoaInfoModalModule } from '../../wop/pessoa/modulo/listagem-pessoainfo-modal/listagempessoainfo.modal.module';
import { PessoaService } from '../../wop/pessoa/service/pessoa.service';
import { ImagemService } from '../../wop/imagem/service/imagem.service';
import { CoordenadorColegiadoService } from '../colegiado/coordenadorcolegiado.service';
import { DiscernimentoService } from '../discernimentos/service/discernimento.service';
import { ParecerCALAnswerDefaultViewComponent } from './parecer-cal-answer-default-view.component';

@NgModule({
  declarations: [
    ParecerComponent,
    ParecerD2FCAnswerComponent,
    ParecerCALFCAnswerComponent,
    ParecerAgradecimentoComponent,
    ParecerCelulaComponent,
    ParecerCelulaAutoridadeComponent,
    ParecerCoordenadorColegiadoCelulaComponent,
    ParecerCoordenadorColegiadoViewComponent,
    ParecerCALAnswerDefaultViewComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    RouterModule,
    ParecerRoutingModule,
    SecuredModule,
    IgxTabsModule,
    ImageCropperModule,
    ListagemPessoaInfoModalModule,
  ],
  providers: [
    PessoaService,
    ImagemService,
    DadoFormandoCVService,
    CaminhoFormativoService,
    ParecerService,
    ParecerResponsavelService,
    RespostaParecerService,
    DadoFormandoCVBuilder,
    DadoFormandoCABuilder,
    CaminhoFormativoBuilder,
    ParecerResponsavelBuilder,
    RespostaParecerBuilder,
    CoordenadorColegiadoService,
    DadoFormandoCAService,
    DiscernimentoService,
  ],
})
export class ParecerModule {}
