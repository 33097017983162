import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Pessoa } from 'src/app/model/pessoa.model';
import { PessoaBuilder } from 'src/app/builder/pessoa.builder';
import { Usuario } from 'src/app/model/usuario.model';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { UsuarioService } from '../../wop/usuario/service/usuario.service';
import { PessoaService } from '../../wop/pessoa/service/pessoa.service';
import { Saude } from 'src/app/model/saude.model';
import { SaudeBuilder } from 'src/app/builder/saude.builder';
import { SaudeService } from './service/saude.service';

@Component({
  selector: 'saudeedit',
  templateUrl: './saude.edit.component.html',
  styleUrls: ['../../wop/pessoa/style/style.css', '../../wop/pessoa/style/normalize.css']  
})
export class SaudeEditComponent implements OnInit {
  
  public usuarioAtual: Usuario;
  public pessoa: Pessoa; 
  public saude: Saude;

  constructor(
    public usuarioService: UsuarioService,
    public pessoaService: PessoaService,
    public saudeService: SaudeService,
    public pessoaBuilder: PessoaBuilder,
    public saudeBuilder: SaudeBuilder,
    public swtAlert2Service: SwtAlert2Service,        
    public route: ActivatedRoute,
    public router: Router  
  ) { 
    this.pessoa = this.pessoaBuilder.getInstance();  
    this.saude = this.saudeBuilder.getInstance();
  }

  ngOnInit() {
    this.initialization();
  }

  async initialization() : Promise<any> {
      await this.getUsuarioAtual();   
      await this.carregarPessoa();  
      await this.carregarSaude();
  }

  async getUsuarioAtual() : Promise<any> {
    try 
    {
        let response = await this.usuarioService.getCurrentUser();   
        this.usuarioAtual = response.entity;      
    }
    catch(err)
    {
        this.swtAlert2Service.errorAlert(err.error.errors)
    }
  }
  async carregarPessoa() : Promise<any> {
      try
      {
          let response = await this.pessoaService.buscaPorUsuarioId(this.usuarioAtual.id);
          this.pessoa = response.entity;
      }
      catch(err)
      {
          this.swtAlert2Service.errorAlert(err.error.errors);
      }
  }

  async carregarSaude() : Promise<any> 
  {
      try 
      {
          let response = await this.saudeService.porPessoaId(this.pessoa.id);
          if(response.entity != undefined && response.entity != null)
          {
              this.saude = response.entity;
          }
          
      } 
      catch (err) 
      {
          this.swtAlert2Service.errorAlert(err.error.errors)
      }
  }

  async atualizarSaude(): Promise<any>
  {
      try
      {
          if(this.saude.id == null)
          {
              this.saude.pessoaId = this.pessoa.id;
              let response = await this.saudeService.create(this.saude);
              this.saude = response.entity;
              this.swtAlert2Service.successAlert(response.message);
          }
          else
          {
              let response = await this.saudeService.update(this.saude.id,this.saude);
              this.saude = response.entity;
              this.swtAlert2Service.successAlert(response.message);
          }
      }
      catch(err)
      {
          this.swtAlert2Service.errorAlert(err.error.errors);
      }      
  }
}
