export class Aptidao {

    constructor(
        public id: number,
        public pessoaId: number,
        public escolaridadeIds: Array<number>,
        public habilidadeIds: any,
        public linguaIds: any
    )
    {}

}