import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
export class MissaoDisplayDataComponent {
    constructor(missaoService, swtAlert) {
        this.missaoService = missaoService;
        this.swtAlert = swtAlert;
    }
    ngOnInit() { }
    ngAfterViewInit() {
        this.carregarMissao();
    }
    carregarMissao() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield this.missaoService.find(this.missaoId);
                this.missao = response.entity;
            }
            catch (e) {
                this.swtAlert.errorAlert(e.error.errors);
            }
        });
    }
}
