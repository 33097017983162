import * as tslib_1 from "tslib";
import { ElementRef, OnInit } from '@angular/core';
import { MembroComunidadeBuilder } from 'src/app/builder/membro.comunidade.builder';
import { PessoaBuilder } from 'src/app/builder/pessoa.builder';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
export class ParecerCoordenadorColegiadoCelulaComponent {
    constructor(missaoConfiguracaoService, usuarioService, imagemService, pessoaService, celulaService, membroService, coordenadorColegiadoService, formadorComunitarioService, formadorAssistenteService, questionarioFormandoBuilder, questionarioFormandoService, parecerResponsavelBuilder, parecerResponsavelService, caminhoFormativoService, discernimentoService, arquivoService, sanitizer, route, router, swtAlert2Service) {
        this.missaoConfiguracaoService = missaoConfiguracaoService;
        this.usuarioService = usuarioService;
        this.imagemService = imagemService;
        this.pessoaService = pessoaService;
        this.celulaService = celulaService;
        this.membroService = membroService;
        this.coordenadorColegiadoService = coordenadorColegiadoService;
        this.formadorComunitarioService = formadorComunitarioService;
        this.formadorAssistenteService = formadorAssistenteService;
        this.questionarioFormandoBuilder = questionarioFormandoBuilder;
        this.questionarioFormandoService = questionarioFormandoService;
        this.parecerResponsavelBuilder = parecerResponsavelBuilder;
        this.parecerResponsavelService = parecerResponsavelService;
        this.caminhoFormativoService = caminhoFormativoService;
        this.discernimentoService = discernimentoService;
        this.arquivoService = arquivoService;
        this.sanitizer = sanitizer;
        this.route = route;
        this.router = router;
        this.swtAlert2Service = swtAlert2Service;
        this.unsubscribe$ = new Subject();
        this.totalDePaginas = 1;
        this.selectedFile = null;
        this.errorMessage = '';
        this.isFormValidUpload = false;
        this.pessoaBuilder = new PessoaBuilder();
        this.membrosCelula = new Array();
        this.membroComunidadeBuilder = new MembroComunidadeBuilder();
        this.membroComunidade = this.membroComunidadeBuilder.getInstance();
        this.questionarioFormando = this.questionarioFormandoBuilder.getInstance();
        this.parecerResponsavel = this.parecerResponsavelBuilder.getInstance();
    }
    ngOnInit() {
        this.initializer();
        this.hasCelulaAssociada = false;
        this.paginacaoConfig = {
            page: 0,
            size: 10,
            totalPages: 0,
            totalElements: 0,
        };
    }
    initializer() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.carregarUsuario();
            yield this.carregarPessoa();
            yield this.carregarFormadoresCelula();
        });
    }
    carregarQuestionarioConfiguracao(questionarioId) {
        const missaoId = this.pessoa.vinculoMissao.missaoAtualId;
        this.missaoConfiguracaoService
            .obterConfiguracaoQuestionarioPorMissaoEQuestionario(missaoId, questionarioId)
            .subscribe((questionarioConfiguracao) => (this.questionarioConfiguracao = questionarioConfiguracao));
    }
    carregarUsuario() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.usuarioService.getCurrentUser();
                this.usuario = response.entity;
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    carregarPessoa() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.pessoaService.buscaPorUsuarioId(this.usuario.id);
                this.pessoa = response.entity;
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    carregarFormadoresCelula() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.discernimentoService.obterMembrosParaDiscernimentoDoResponsavelLocal(this.paginacaoConfig.page, this.paginacaoConfig.size);
                this.formadoresDaMissao = response.content;
                this.paginacaoConfig.totalPages = response.totalPages;
                this.paginacaoConfig.totalElements = response.totalElements;
                const idMembros = this.formadoresDaMissao.map((membro) => membro.id);
                yield this.carregarQuestionarioColegiado(idMembros);
                if (this.formadoresDaMissao.length > 0) {
                    this.formadoresDaMissao = this.formadoresDaMissao.sort((a, b) => a.pessoaNome.localeCompare(b.pessoaNome));
                }
                for (let i = 0; i < this.formadoresDaMissao.length; i++) {
                    if (this.formadoresDaMissao[i].imagemId != null) {
                        let blogImage = yield this.getPessoaImagem(this.formadoresDaMissao[i].imagemId);
                        this.imagemService.createImage(blogImage, this.formadoresDaMissao[i]);
                    }
                    const questionario = this.questionariosColegiado.filter((questionarioFormando) => questionarioFormando.id === this.formadoresDaMissao[i].id);
                    if (questionario.length > 0) {
                        this.formadoresDaMissao[i].statusQuestionario =
                            questionario[0].status_questionario;
                        this.formadoresDaMissao[i].idQuestionarioFormando =
                            questionario[0].questionarioFormandoId;
                        this.formadoresDaMissao[i].questionarioId =
                            questionario[0].questionarioId;
                        this.formadoresDaMissao[i].statusParecerFC =
                            questionario[0].status_parecer_fc;
                        this.formadoresDaMissao[i].idParecerFC = questionario[0].parecerIdFC;
                        this.formadoresDaMissao[i].acompanhadoPor =
                            questionario[0].acompanhadoPor;
                        this.formadoresDaMissao[i].acompanhadoPorNome =
                            questionario[0].acompanhadoPorNome;
                        this.formadoresDaMissao[i].arquivo = questionario[0].arquivo;
                        this.formadoresDaMissao[i].dataEnvioParecerFC =
                            questionario[0].dataEnvioParecerFC;
                        this.formadoresDaMissao[i].dataEnvioQuestionario =
                            questionario[0].dataEnvioQuestionario;
                        this.formadoresDaMissao[i].missaoAtualId =
                            questionario[0].pessoamissaoAtualId;
                        this.formadoresDaMissao[i].carta = questionario[0].carta;
                        if (this.formadoresDaMissao[i].statusQuestionario == 'NAOINICIADO') {
                            this.formadoresDaMissao[i].statusQuestionario = 'Não Iniciado';
                        }
                        if (this.formadoresDaMissao[i].statusParecerFC == 'NAOINICIADO') {
                            this.formadoresDaMissao[i].statusParecerFC = 'Não Iniciado';
                        }
                    }
                    else {
                        this.formadoresDaMissao[i].statusQuestionario = null;
                        this.formadoresDaMissao[i].idQuestionarioFormando = null;
                        this.formadoresDaMissao[i].questionarioId = null;
                        this.formadoresDaMissao[i].statusParecerFC = null;
                        this.formadoresDaMissao[i].idParecerFC = null;
                        this.formadoresDaMissao[i].arquivo = null;
                        this.formadoresDaMissao[i].dataEnvioParecerFC = null;
                        this.formadoresDaMissao[i].dataEnvioQuestionario = null;
                    }
                    /*if (!this.questionarioFormando || !this.questionarioFormando.id) {
                      this.questionarioFormando = await this.carregarQuestionario(
                        this.formadoresDaMissao[i].pessoaId
                      );
            
                      if (this.questionarioFormando) {
                        if (!this.questionarioConfiguracao) {
                          this.carregarQuestionarioConfiguracao(
                            this.questionarioFormando.questionarioId
                          );
                        }
                      }
                    } else if (!this.questionarioConfiguracao) {
                      this.carregarQuestionarioConfiguracao(
                        this.questionarioFormando.questionarioId
                      );
                    }*/
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarQuestionarioColegiado(idMembros) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.questionarioFormandoService.buscarPorMembrosId(idMembros);
                this.questionariosColegiado = response;
            }
            catch (e) {
                console.log(e);
            }
        });
    }
    consultarCaminhoFormativo(formando) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.caminhoFormativoService.porPessoaId(formando.pessoaId);
                if (response != null && response != undefined) {
                    this.caminhoFormativo = response;
                }
                else {
                    this.caminhoFormativo = {};
                    this.caminhoFormativo.pessoaId = formando.pessoaId;
                    this.caminhoFormativo.missao_atual_id = formando.missaoAtualId;
                    this.caminhoFormativo.ano_formativo_id = 2;
                    this.caminhoFormativo.nome_formador_comunitario =
                        this.pessoa.nome + ' ' + this.pessoa.sobrenome;
                    let response = yield this.caminhoFormativoService.cadastrar(this.caminhoFormativo);
                    this.caminhoFormativo = response;
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    cadastrarVinculoQuestionarioCAL(formando) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                yield this.consultarCaminhoFormativo(formando);
                let responsePorPessoa = yield this.questionarioFormandoService.buscarPorPessoaIdEQuestionarioId(formando.pessoaId, formando.questionarioId);
                if (responsePorPessoa != undefined &&
                    responsePorPessoa != null &&
                    responsePorPessoa.questionarioId == formando.questionarioId) {
                    this.questionarioFormando = responsePorPessoa;
                    this.questionarioFormandoSelectedId = this.questionarioFormando.id;
                    //this.cadastrarVinculoParecerCAL(this.questionarioFormando.id);
                }
                else {
                    this.questionarioFormando.anoFormativoId = 2;
                    this.questionarioFormando.questionarioId = formando.questionarioId;
                    this.questionarioFormando.pessoaId = formando.pessoaId;
                    let response = yield this.questionarioFormandoService.cadastrar(this.questionarioFormando);
                    if (response != undefined && response != null) {
                        this.questionarioFormando = response;
                        this.questionarioFormandoSelectedId = this.questionarioFormando.id;
                        formando.idQuestionarioFormando = this.questionarioFormando.id;
                    }
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    cadastrarVinculoParecerCAL(questionarioFormandoId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.parecerResponsavel.questionarioFormandoId = questionarioFormandoId;
                this.parecerResponsavel.parecer_id = 3;
                this.parecerResponsavel.pessoaResponsavelId = this.pessoa.id;
                this.parecerResponsavel.tipo = 'FC';
                let response = yield this.parecerResponsavelService.cadastrar(this.parecerResponsavel);
                if (response != undefined && response != null) {
                    this.parecerResponsavel = response;
                    this.router.navigate([
                        '/secured/parecer-cal-answer-default' +
                            '/' +
                            this.parecerResponsavel.id,
                    ]);
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    getPessoaImagem(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.imagemService.getPessoaPhoto(id);
                return response.body;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    consultarVinculoQuestionarioCAL(formando) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (formando.idQuestionarioFormando != undefined &&
                    formando.idQuestionarioFormando != null) {
                    if (formando.idParecerFC != undefined && formando.idParecerFC != null) {
                        this.router.navigate([
                            '/secured/parecer-cal-answer-default' + '/' + formando.idParecerFC,
                        ]);
                    }
                    else {
                        this.cadastrarVinculoParecerCAL(formando.idQuestionarioFormando);
                    }
                }
                else if (formando.idQuestionarioFormando == undefined ||
                    formando.idQuestionarioFormando == null) {
                    yield this.cadastrarVinculoQuestionarioCAL(formando);
                    yield this.cadastrarVinculoParecerCAL(formando.idQuestionarioFormando);
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarArquivoPDF(arquivoPDFNome) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const blobResponse = yield this.arquivoService
                .obterDetalhesArquivoPDF(arquivoPDFNome)
                .toPromise();
            const url = URL.createObjectURL(blobResponse);
            this.sanitizedPdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
        });
    }
    configModalUploadQuestionario(formando) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (formando.questionarioFormandoId != undefined &&
                formando.questionarioFormandoId != null) {
                this.questionarioFormandoSelectedId = formando.questionarioFormandoId;
                this.questionarioSelectedId = formando.questionarioId;
            }
            else {
                yield this.cadastrarVinculoQuestionarioCAL(formando);
            }
        });
    }
    onFileSelected(event) {
        const file = event.target.files[0];
        // Verificar se é um arquivo PDF e se o tamanho é menor que 3 MB (3 * 1024 * 1024 bytes)
        if (file &&
            file.type === 'application/pdf' &&
            file.size <= 3 * 1024 * 1024) {
            this.selectedFile = file;
            this.errorMessage = '';
        }
        else {
            this.selectedFile = null;
            this.errorMessage = 'O arquivo deve ser um PDF com no máximo 3 MB.';
        }
        this.checkFormUploadValidity();
    }
    onUpload() {
        if (this.selectedFile && this.pedidoFormandoId) {
            this.questionarioFormandoService
                .uploadQuestionario(this.questionarioFormandoSelectedId, this.pedidoFormandoId, this.selectedFile)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe({
                next: (data) => {
                    this.swtAlert2Service.successAlert(data);
                    this.carregarFormadoresCelula();
                    $('#modal-upload-questionario').modal('hide');
                },
                error: (error) => {
                    this.swtAlert2Service.errorAlert('Erro ao realizar o upload do arquivo.');
                },
                complete: () => {
                    this.unsubscribe$.next();
                },
            });
            this.pedidoFormandoId = null;
        }
        else {
            if (!this.pedidoFormandoId) {
                this.errorMessage =
                    "O campo 'Qual Pedido o seu do Formando está fazendo' deve ser informado.";
            }
            else {
                this.errorMessage =
                    'Selecione um arquivo válido para enviar. Ele deve estar em formato PDF e deve ter tamanho máximo de 3Mb';
            }
        }
    }
    onTipoPedidoChange() {
        this.checkFormUploadValidity();
    }
    // Verifica se tanto o arquivo quanto o tipo de pedido foram selecionados
    checkFormUploadValidity() {
        this.isFormValidUpload = !!this.pedidoFormandoId && !!this.selectedFile;
    }
    /**
     * CARTA
     */
    configModalUploadCarta(formando) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (formando.questionarioFormandoId != undefined &&
                formando.questionarioFormandoId != null) {
                this.questionarioFormandoSelectedId = formando.questionarioFormandoId;
            }
            else {
                yield this.cadastrarVinculoQuestionarioCAL(formando);
            }
        });
    }
    carregarCartaPDF(arquivoPDFNome) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const blobResponse = yield this.arquivoService
                .obterDetalhesArquivoPDF(arquivoPDFNome)
                .toPromise();
            const url = URL.createObjectURL(blobResponse);
            this.sanitizedPdfCartaUrl =
                this.sanitizer.bypassSecurityTrustResourceUrl(url);
        });
    }
    onCartaFileSelected(event) {
        const file = event.target.files[0];
        // Verificar se é um arquivo PDF e se o tamanho é menor que 3 MB (3 * 1024 * 1024 bytes)
        if (file &&
            file.type === 'application/pdf' &&
            file.size <= 3 * 1024 * 1024) {
            this.selectedFile = file;
        }
        else {
            this.selectedFile = null;
        }
    }
    onCartaUpload() {
        if (this.selectedFile) {
            this.questionarioFormandoService
                .uploadCarta(this.questionarioFormandoSelectedId, this.selectedFile)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe({
                next: (data) => {
                    this.swtAlert2Service.successAlert(data);
                    this.carregarFormadoresCelula();
                    $('#modal-upload-carta').modal('hide');
                },
                error: (error) => {
                    this.swtAlert2Service.errorAlert('Erro ao realizar o upload do arquivo.');
                },
                complete: () => {
                    this.unsubscribe$.next();
                },
            });
        }
    }
    cliqueNoBotaoListener(botao) {
        this.paginacaoConfig.page = botao.numero - 1;
        this.carregarFormadoresCelula();
    }
}
