<secured>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h3 i18n>Usuário</h3>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">

            <form (ngSubmit)="salvar(usuario)" #usuarioForm="ngForm" novalidate>
                <div class="card card-default color-pallete-box">
                    <div class="card-header">
                        <h3 class="card-title">
                            <i class="fas fa-user"></i>
                            <a i18n> Dados do Usuário </a>
                        </h3>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-12 col-md-4 col-lg-4">
                                <div class="form-group">
                                    <label i18n for="nu_email">E-mail</label>
                                    <input [(ngModel)]="usuario.email" name="email" type="email" class="form-control" id="nu_email" placeholder="Digite o e-mail" i18n-placeholder>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-4 col-lg-4">
                                <div class="form-group">
                                    <label i18n for="nu_username">Usuário</label>
                                    <input [(ngModel)]="usuario.username" name="nu_username" type="text" class="form-control" id="nu_username" placeholder="Digite o nome do usuário" i18n-placeholder>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-4 col-lg-4">
                                <div class="form-group">
                                    <label i18n for="nu_password">Senha</label>
                                    <input [(ngModel)]="usuario.password" type="password" class="form-control" id="nu_password" name="nu_password" placeholder="Digite a senha do usuário" i18n-placeholder/>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-3 col-lg-3">
                                <div class="form-group">
                                    <div class="form-check">
                                        <input [(ngModel)]="usuario.isAccountNonExpired" name="isaccountnonexpired" id="isaccountnonexpired" class="form-check-input" type="checkbox" checked>
                                        <label for="isaccountnonexpired" class="form-check-label" i18n>Conta não expirada</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-3 col-lg-3">
                                <div class="form-group">
                                    <div class="form-check">
                                        <input [(ngModel)]="usuario.isAccountNonLocked" name="isaccountnonlocked" id="isaccountnonlocked" class="form-check-input" type="checkbox" checked>
                                        <label for="isaccountnonlocked" class="form-check-label" i18n>Conta não fechada</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-3 col-lg-3">
                                <div class="form-group">
                                    <div class="form-check">
                                        <input [(ngModel)]="usuario.isCredentialsNonExpired" name="iscredentialsnonexpired" id="iscredentialsnonexpired" class="form-check-input" type="checkbox" checked>
                                        <label for="iscredentialsnonexpired" class="form-check-label" i18n>Credenciais não expiradas</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-3 col-lg-3">
                                <div class="form-group">
                                    <div class="form-check">
                                        <input [(ngModel)]="usuario.isEnabled" name="isenabled" id="isenabled" class="form-check-input" type="checkbox" checked>
                                        <label for="isenabled" class="form-check-label" i18n>Conta liberada</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-footer">
                        <button type="submit" class="btn btn-primary float-right" i18n>Salvar</button>
                    </div>
                </div>
            </form>
        </section>
        <section class="content-header" name="gerencia-elemento-grupo-dominio" *ngIf="usuario.id!=null">
            <div class="card card-default color-pallete-box">
                <div class="card-header">
                    <h3 class="card-title">
                        <i class="fas fa-user-lock"></i>
                        <a i18n> Autorizações </a>
                    </h3>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-header">
                                    <h3 class="card-title">Adicionar Autorização</h3>
                                </div>
                                <div class="card-body">
                                    <form (ngSubmit)="adicionarAutorizacao()" #egdForm="ngForm" novalidate>
                                        <div class="row">
                                            <div class="col-sm-12 col-md-3 col-lg-3">
                                                <div class="form-group">
                                                    <label i18n for="nu_autorizacao">Autorização</label>
                                                    <select class="form-control" id="nu_autorizacao" [(ngModel)]="autorizacaoSelecionada" name="nu_autorizacao">
                                                            <option [value]="null" i18n>Selecione uma Autorizacao</option>
                                                            <option *ngFor="let aut of autorizacoes" [value]="aut.id">{{aut.nomeExibicao}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-3 col-lg-3">
                                                <label>&nbsp;</label><br>
                                                <button class="btn btn-md btn-primary" type="submit" title="Adicionar Autorização" alt="Adicionar Autorização"><i class="fas fa-plus-square"></i></button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <table class="table table-bordered">
                                <thead>
                                    <tr>
                                        <th style="width: 16.66%;">Nome Exibição</th>
                                        <th style="width: 16.66%;">Nome</th>
                                        <th style="width: 16.66%;" class="text-center"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let entity of autorizacoesVinculadas">
                                        <td style="width: 16.66%;">{{entity.nomeExibicao}}</td>
                                        <td style="width: 16.66%;">{{entity.nome}}</td>
                                        <td style="width:16.66%;" class="text-center">
                                            <button class="btn btn-sm btn-danger" (click)="removerAutorizacao(entity.id)" title="Remover autorização" alt="Remover autorização"><i class="fas fa-trash-alt"></i></button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</secured>