import { Component, OnInit } from '@angular/core';
import { SwtAlert2Service } from '../core/swtalert2/swtalert2.service';
import { Login } from '../model/login.model';
import { EsqueceuSenhaService } from '../secured/wop/usuario/esqueceusenha/service/esqueceusenha.service';
import { LoginService } from './service/login.service';
import { ImportLoginSh } from '../model/importloginsh.model';
import { TermoUso } from '../model/termouso.model';
import { TermoUsoBuilder } from 'src/app/builder/termouso.builder';
import { CadastroPublicoLocalService } from '../secured/local/cadastropublico/service/cadastro.publico.local.service';
import { TermoTratamentoDados } from '../model/termotratamentodados.model';
import { TermoTratamentoDadosBuilder } from '../builder/termotratamentodados.builder';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit {
  public login: Login;
  public emailRecuperacao: string;
  passwordFieldType: string = 'password';
  public importLoginShForm: ImportLoginSh;
  public termoVigente: TermoUso;
  public termoTratamentoDadosVigente: TermoTratamentoDados;

  constructor(
    private esqueceuSenhaService: EsqueceuSenhaService,
    private loginService: LoginService,
    public termoUsoBuilder: TermoUsoBuilder,
    public termoTratamentoDadosBuilder: TermoTratamentoDadosBuilder,
    public cadastroPublicoService: CadastroPublicoLocalService,
    private swtAlert2Service: SwtAlert2Service
  ) {
    this.importLoginShForm = new ImportLoginSh(
      false,
      null,
      null,
      false,
      false,
      false
    );
    this.termoVigente = this.termoUsoBuilder.getInstance();
    this.termoTratamentoDadosVigente =
      this.termoTratamentoDadosBuilder.getInstance();
  }

  ngOnInit(): void {
    this.login = new Login('', '');
    this.carregarTermoVigente();
    this.carregarTermoTratamentoDadosVigente();
  }

  public async fazerLogin(): Promise<any> {
    this.login.username = this.login.username.trim();
    this.loginService.fazerLogin(this.login);
  }

  togglePasswordVisibility() {
    this.passwordFieldType =
      this.passwordFieldType === 'password' ? 'text' : 'password';
  }

  async recuperarSenha(): Promise<any> {
    if (
      this.emailRecuperacao != '' &&
      this.emailRecuperacao != undefined &&
      this.emailRecuperacao != null
    ) {
      try {
        let response = await this.esqueceuSenhaService.esqueceuSenha(
          this.emailRecuperacao
        );
        this.swtAlert2Service.infoAlert(response.message);
        $('#modal-esqueceu-senha').modal('hide');
      } catch (err) {
        this.swtAlert2Service.errorAlert(err.error.errors);
      }
    } else {
      this.swtAlert2Service.warningAlert('Informe o e-mail de recuperação!');
    }
  }

  async carregarTermoVigente(): Promise<any> {
    try {
      let response = await this.cadastroPublicoService.getTermoVigente();
      this.termoVigente = response.entity;
    } catch (e) {
      this.swtAlert2Service.errorAlert(e.error.errors);
    }
  }

  async carregarTermoTratamentoDadosVigente(): Promise<any> {
    try {
      let response =
        await this.cadastroPublicoService.getTermoTratamentoDadosVigente();
      this.termoTratamentoDadosVigente = response.entity;
    } catch (e) {
      this.swtAlert2Service.errorAlert(e.error.errors);
    }
  }

  async importarDadosLoginSh(): Promise<any> {
    if (this.importLoginShForm.username.length > 20) {
      this.swtAlert2Service.warningAlert(
        'O nome de usuário deve conter no máximo 20 characteres.'
      );
    } else if (!this.validarImportacao(this.importLoginShForm)) {
      this.swtAlert2Service.warningAlert(
        'Para continuar com a importação preencha os campos usuario wop e e-mail e aceite os termos para importação dos seus dados.'
      );
    } else {
      try {
        let response = await this.cadastroPublicoService.importarDadosLoginSH(
          this.importLoginShForm
        );
        this.importLoginShForm = new ImportLoginSh(
          false,
          null,
          null,
          false,
          false,
          false
        );
        $('#modal-import-loginsh').modal('hide');
        $('#confirmacao-importacao-loginsh').modal('show');
      } catch (e) {
        this.swtAlert2Service.errorAlert(e.error.errors);
      }
    }
  }

  validarImportacao(importLoginShForm: ImportLoginSh): boolean {
    let retorno = true;

    if (
      importLoginShForm.username == null ||
      importLoginShForm.username == '' ||
      importLoginShForm.username.length > 20 ||
      importLoginShForm.email == null ||
      importLoginShForm.email == '' ||
      (this.termoVigente != null &&
        importLoginShForm.aceiteTermoUso == false) ||
      (this.termoTratamentoDadosVigente != null &&
        importLoginShForm.aceiteTermoTratamentoDados == false) ||
      importLoginShForm.aceiteImportacaoLoginSh == false
    ) {
      retorno = false;
    }

    return retorno;
  }
}
