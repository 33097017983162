import { AbstractBuilder } from './abstract.builder';
import { Aptidao } from '../model/aptidao.model';


export class AptidaoBuilder extends AbstractBuilder<Aptidao> {

    reset() : void {
        this.entity = new Aptidao(null,null,null,null,null);
    }

}