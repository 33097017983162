import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SecuredModule } from '../../layout/secured.module';
import { RouterModule } from '@angular/router';
import { CentroEvangelizacaoRoutingModule } from './centroevangelizacao-routing.module';
import { CentroEvangelizacaoService } from './service/centroevangelizacao.service';
import { CentrosEvangelizacaoComponent } from './centrosevangelizacao.component';
import { CentroEvangelizacaoEditComponent } from './centroevangelizacao.edit.component';
import { CentroEvangelizacaoComponent } from './centroevangelizacao.component';

@NgModule({
    declarations: [ 
        CentroEvangelizacaoComponent,
        CentrosEvangelizacaoComponent,
        CentroEvangelizacaoEditComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        CentroEvangelizacaoRoutingModule,
        FormsModule,
        SecuredModule
    ],
    providers: [
        CentroEvangelizacaoService
    ]
})
export class CentroEvangelizacaoModule {

}