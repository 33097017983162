<secured>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <div class="text-center">
                            <h3 i18n>Obrigada pela sua resposta!</h3>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="card card-primary card-outline card-outline-tabs">
                <div class="card-header p-0 border-bottom-0">
                    <div class="text-center">“Quem deixa entrar Cristo, não perde nada, nada, absolutamente nada daquilo que faz a vida livre, bela
                        e grande. Não! Só nesta amizade se escancaram as portas da vida”<br>
                        (Bento XVI).</div>                    
                </div>                
            </div>
        </section>
    </div>    
</secured>