import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'remanejamentos',
  templateUrl: './remanejamento.component.html',
  styleUrls: ['./remanejamento.component.css']
})
export class RemanejamentoComponent implements OnInit {
  exibirModal = false;

  constructor() { }

  ngOnInit() {
  }

  abrirModal(): void {
    this.exibirModal = true;
  }

  fecharModal(): void {
    this.exibirModal = false;
  }

  carregarRelatorios():void{
    
  }


}
