<body class="hold-transition login-page">
    <div class="login-page-item">
        <div class="row">
            <div class="col-sm-12 col-md-10 offset-md-1 col-lg-10 offset-lg-1">
                <div class="card card-default color-pallete-box">
                    <div class="card-header">
                        <h3 class="card-title">
                            <i class="fas fa-user"></i>
                            <a i18n> Cadastro </a>
                        </h3>
                    </div>
                    <div class="card-body">
                        <form #dadosPessoaisForm="ngForm" (ngSubmit)="cadastrar(cadastroPublico)">
                            <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                    <div class="card card-default">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-sm-12 col-md-4 col-lg-4">
                                                    <div class="form-group">
                                                        <label i18n for="npessoa_nome">Nome</label>
                                                        <input [(ngModel)]="cadastroPublico.nome" name="npessoa_nome" type="text" class="form-control" id="ncev_nome" placeholder="Digite o seu nome" i18n-placeholder required>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-4 col-lg-4">
                                                    <div class="form-group">
                                                        <label i18n for="npessoa_sobrenome">Sobrenome</label>
                                                        <input [(ngModel)]="cadastroPublico.sobrenome" name="npessoa_sobrenome" type="text" class="form-control" id="npessoa_sobrenome" placeholder="Digite o seu sobrenome" i18n-placeholder required>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-4 col-lg-4">
                                                    <div class="form-group">
                                                        <label i18n for="npessoa_genero">Sexo</label>
                                                        <select class="form-control" id="npessoa_genero" [(ngModel)]="cadastroPublico.sexo" name="npessoa_genero" required>
                                                        <option disabled value="null" i18n>Selecione o sexo</option>
                                                        <option value="masculino" i18n>Masculino</option>
                                                        <option value="feminino" i18n>Feminino</option>
                                                    </select>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-4 col-lg-4">
                                                    <div class="form-group">
                                                        <label i18n for="npessoa_dtnas">Data de Nascimento</label>
                                                        <input [(ngModel)]="cadastroPublico.dataNascimento" name="npessoa_dtnas" type="date" class="form-control" id="npessoa_dtnas" i18n-placeholder required>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-4 col-lg-4">
                                                    <div class="form-group">
                                                        <label i18n for="npessoa_nacionalidade">Nacionalidade</label>
                                                        <select class="form-control" id="npessoa_nacionalidade" [(ngModel)]="cadastroPublico.nacionalidadeId" name="npessoa_nacionalidade" required>
                                                            <option disabled value="null" i18n>Selecione a sua nacionalidade</option>
                                                            <option *ngFor="let pais of paises" [value]="pais.id">{{pais.nome}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-4 col-lg-4">
                                                    <div class="form-group">
                                                        <label i18n for="npessoa_telefone">Telefone</label>
                                                        <input [(ngModel)]="cadastroPublico.telefone" name="npessoa_telefone" type="tel" class="form-control" id="npessoa_telefone" i18n-placeholder required>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-sm-12 col-md-4 col-lg-4">
                                                    <div class="form-group">
                                                        <label i18n for="npessoa_user">Nome de usuário</label>
                                                        <input [(ngModel)]="cadastroPublico.username" name="npessoa_user" type="text" class="form-control" id="npessoa_user" i18n-placeholder required>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-4 col-lg-4">
                                                    <div class="form-group">
                                                        <label i18n for="npessoa_pass">Senha</label>
                                                        <input [(ngModel)]="cadastroPublico.password" name="npessoa_pass" type="password" class="form-control" id="npessoa_pass" i18n-placeholder required>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-4 col-lg-4">
                                                    <div class="form-group">
                                                        <label i18n for="npessoa_pass">Confirmar Senha</label>
                                                        <input [(ngModel)]="cadastroPublico.confPassword" name="npessoa_confpass" type="password" class="form-control" id="npessoa_confpass" i18n-placeholder required>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-4 col-lg-4">
                                                    <div class="form-group">
                                                        <label i18n for="npessoa_mail">E-mail</label>
                                                        <input [(ngModel)]="cadastroPublico.email" name="npessoa_mail" type="email" class="form-control" id="npessoa_mail" i18n-placeholder required>
                                                    </div>
                                                </div>
                                                <!--div class="col-sm-12 col-md-4 col-lg-4">
                                                    <div class="form-group">
                                                        <label i18n for="npessoa_mail">Confirmação de E-mail</label>
                                                        <input name="npessoa_confmail" type="email" class="form-control" id="npessoa_confmail" i18n-placeholder required>
                                                    </div>
                                                </div-->  
                                                <div class="col-sm-12 col-md-4 col-lg-4">
                                                    <div class="form-group">
                                                        <label i18n for="npessoa_formavida">Forma de Vida</label>
                                                        <select class="form-control" id="npessoa_formavida" [(ngModel)]="cadastroPublico.formaVidaId" name="npessoa_formavida" required>
                                                            <option disabled value="null" i18n>Selecione a sua forma de vida</option>
                                                            <option *ngFor="let formaVida of formasVida" [value]="formaVida.id">{{formaVida.nome}}</option>
                                                        </select>
                                                    </div>
                                                </div> 
                                                <div class="col-sm-12 col-md-4 col-lg-4">
                                                    <div class="form-group">
                                                        <label i18n for="npessoa_nivelformativo">Nível Formativo</label>
                                                        <select class="form-control" id="npessoa_nivelformativo" [(ngModel)]="cadastroPublico.nivelFormacaoId" name="npessoa_nivelformativo" required>
                                                            <option disabled value="null" i18n>Selecione a seu nível formativo</option>
                                                            <option *ngFor="let nivel of niveisFormacao" [value]="nivel.id">{{nivel.nome}}</option>
                                                        </select>
                                                    </div>
                                                </div>                                                
                                            </div>
                                            <div class="row">                                             
                                                <div class="col-sm-12 col-md-4 col-lg-4">
                                                    <div class="form-group">
                                                        <label i18n for="npessoa_missao_atual">Se você participa de uma missão shalom, selecione sua missão atual</label>
                                                        <select class="form-control" id="npessoa_missao_atual" [(ngModel)]="cadastroPublico.missaoAtualId" name="npessoa_missao_atual">
                                                            <option [ngValue]="null" i18n>Selecione uma missão</option>
                                                            <option *ngFor="let missao of missoesVinculo" [value]="missao.id">{{missao.nome}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-4 col-lg-4">
                                                    <div class="form-group">
                                                        <label i18n for="npessoa_difusao_atual">Se você participa de uma difusão shalom, selecione sua difusão atual</label>
                                                        <select class="form-control" id="npessoa_difusao_atual" [(ngModel)]="cadastroPublico.difusaoAtualId" name="npessoa_difusao_atual">
                                                            <option [ngValue]="null" i18n>Selecione uma difusão</option>
                                                            <option *ngFor="let difusao of difusoesVinculo" [value]="difusao.id">{{difusao.nome}}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-12 col-lg-12">
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" [(ngModel)]="cadastroPublico.aceiteTermo" [ngModelOptions]="{standalone: true}">
                                                        <label class="form-check-label">Li e concordo com os <a i18n data-toggle="modal" data-target="#termo-uso" style="cursor: pointer" class="text-center text-bold">Termos de uso</a></label>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-md-12 col-lg-12" *ngIf="termoTratamentoDadosVigente != null">                            
                                                    <div class="form-check">
                                                        <input class="form-check-input" type="checkbox" [(ngModel)]="cadastroPublico.aceiteTermoTratamentoDados" [ngModelOptions]="{standalone: true}">
                                                        <label class="form-check-label">Li e concordo com os <a i18n data-toggle="modal" data-target="#termo-tratamento-dados" style="cursor: pointer" class="text-center text-bold">Termos de tratamento de dados</a></label>
                                                    </div>                            
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-footer text-right">
                                            <button type="submit" class="btn btn-primary" [disabled]="(dadosPessoaisForm.valid==false || cadastroPublico.aceiteTermo==false)" i18n>Cadastrar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <!-- /.login-card-body -->
                </div>
            </div>
        </div>
    </div>
    <!-- /.login-logo -->
    <div class="modal fade" id="termo-uso">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Termo de Uso</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div [ngxSummernoteView]="termoVigente?.termo"></div>
                </div>
                <div class="modal-footer justify-content-right">
                    <button type="button" class="btn btn-default" data-dismiss="modal">Fechar</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="termo-tratamento-dados">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Termo de Tratamento de Dados</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div [ngxSummernoteView]="termoTratamentoDadosVigente?.termo"></div>
                </div>
                <div class="modal-footer justify-content-right">
                    <button type="button" class="btn btn-default" data-dismiss="modal">Fechar</button>
                </div>
            </div>
        </div>
    </div>
</body>