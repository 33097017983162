import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
export class CadastroComissaoDiscernimentoComponent {
    constructor(comissaoDiscernimentoService, missaoConfiguracaoSharedDataService, imagemService, swtAlert) {
        this.comissaoDiscernimentoService = comissaoDiscernimentoService;
        this.missaoConfiguracaoSharedDataService = missaoConfiguracaoSharedDataService;
        this.imagemService = imagemService;
        this.swtAlert = swtAlert;
        this.unsubscribe$ = new Subject();
        this.comissaoDiscernimento = {};
        this.membrosComissaoDiscernimento = new Array();
    }
    ngOnInit() {
        this.missaoConfiguracaoSharedDataService.missaoConfiguracao$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
            next: (missaoConfiguracao) => {
                if (missaoConfiguracao) {
                    this.missaoConfiguracao = missaoConfiguracao;
                    this.carregarComissaoDiscernimentoPorMissaoConfiguracaoId(missaoConfiguracao.id);
                }
            },
        });
    }
    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }
    selecionarComissaoDiscernimento(pessoa) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.comissaoDiscernimento.pessoaId = pessoa.id;
            this.pessoaComissaoDiscernimentoSelecionado = pessoa;
            $('#listagem-pessoa-comissaodiscernimento').modal('hide');
        });
    }
    adicionarComissaoDiscernimento() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                this.comissaoDiscernimento.missaoConfiguracaoId =
                    this.missaoConfiguracao.id;
                const response = yield this.comissaoDiscernimentoService
                    .cadastrar(this.comissaoDiscernimento)
                    .toPromise();
                this.comissaoDiscernimento = {};
                this.pessoaComissaoDiscernimentoSelecionado = undefined;
                yield this.carregarComissaoDiscernimentoPorMissaoConfiguracaoId(this.missaoConfiguracao.id);
                this.swtAlert.successAlert('Membro da Comissão de Discernimento cadastrado com sucesso!');
            }
            catch (err) {
                this.swtAlert.errorAlert(err.error.errors);
            }
        });
    }
    carregarComissaoDiscernimentoPorMissaoConfiguracaoId(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield this.comissaoDiscernimentoService
                    .obterPorMissaoConfiguracaoId(id)
                    .toPromise();
                if (response) {
                    this.membrosComissaoDiscernimento = response;
                    if (this.membrosComissaoDiscernimento != null &&
                        this.membrosComissaoDiscernimento.length > 0) {
                        for (let i = 0; i < this.membrosComissaoDiscernimento.length; i++) {
                            if (this.membrosComissaoDiscernimento[i].imagemId != null) {
                                let responseImage = yield this.imagemService.getPessoaPhoto(this.membrosComissaoDiscernimento[i].imagemId);
                                let blogImage = responseImage.body;
                                this.imagemService.createImage(blogImage, this.membrosComissaoDiscernimento[i]);
                            }
                        }
                    }
                }
            }
            catch (err) {
                this.swtAlert.errorAlert(err.error.errors);
            }
        });
    }
    removerComissaoDiscernimento(comissaoDiscernimentoId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (confirm('Deseja remover o membro da Comissão de Discernimento?')) {
                try {
                    yield this.comissaoDiscernimentoService
                        .remover(comissaoDiscernimentoId)
                        .toPromise();
                    yield this.carregarComissaoDiscernimentoPorMissaoConfiguracaoId(this.missaoConfiguracao.id);
                    this.swtAlert.successAlert('Membro do Comissão de Discernimento removido com sucesso!');
                    this.comissaoDiscernimento = {};
                }
                catch (err) {
                    this.swtAlert.errorAlert(err.error.errors);
                }
            }
        });
    }
}
