import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { PessoaBuilder } from 'src/app/builder/pessoa.builder';
export class CasaComunitariaLocalManageComponent {
    constructor(usuarioService, pessoaService, casaComunitariaService, imagemService, missionarioMissaoAtualService, historicoMissionarioMissaoBuilder, historicoMissionarioMissaoService, route, router, swtAlert2Service) {
        this.usuarioService = usuarioService;
        this.pessoaService = pessoaService;
        this.casaComunitariaService = casaComunitariaService;
        this.imagemService = imagemService;
        this.missionarioMissaoAtualService = missionarioMissaoAtualService;
        this.historicoMissionarioMissaoBuilder = historicoMissionarioMissaoBuilder;
        this.historicoMissionarioMissaoService = historicoMissionarioMissaoService;
        this.route = route;
        this.router = router;
        this.swtAlert2Service = swtAlert2Service;
        this.pessoaBuilder = new PessoaBuilder();
        this.pessoaMembroSelecionada = this.pessoaBuilder.getInstance();
    }
    ngOnInit() {
        this.casaComunitariaId = Number(this.route.snapshot.paramMap.get('id'));
        this.isLoaded = false;
        this.initializer();
    }
    initializer() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            yield this.carregarUsuario();
            yield this.carregarPessoa();
            yield this.carregarDadosDeDetalhamentoDaMissaoAtualDoUsuarioLogado();
            if (this.dadosDetalhamentoMissaoAtualUsuarioLogado != undefined && this.dadosDetalhamentoMissaoAtualUsuarioLogado.id != undefined) {
                yield this.carregarCasaComunitaria();
            }
            else if (this.casaComunitariaId != undefined && this.casaComunitariaId != 0) {
                yield this.carregarCasaComunitaria();
            }
            else {
                this.swtAlert2Service.errorAlert("Não foi possível obter as informações da casa comunitária");
            }
            this.isLoaded = true;
        });
    }
    carregarUsuario() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.usuarioService.getCurrentUser();
                this.usuario = response.entity;
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    carregarPessoa() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.pessoaService.buscaPorUsuarioId(this.usuario.id);
                this.pessoa = response.entity;
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    carregarDadosDeDetalhamentoDaMissaoAtualDoUsuarioLogado() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.missionarioMissaoAtualService.detalhamentoPorUsuarioLogado();
                this.dadosDetalhamentoMissaoAtualUsuarioLogado = response;
                if (this.casaComunitariaId == undefined || this.casaComunitariaId == 0) {
                    this.casaComunitariaId = this.dadosDetalhamentoMissaoAtualUsuarioLogado.casaComunitariaId;
                }
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    carregarCasaComunitaria() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                if (this.casaComunitariaId != undefined) {
                    let response = yield this.casaComunitariaService.find(this.casaComunitariaId);
                    this.casaComunitaria = response.entity;
                }
            }
            catch (e) {
                this.swtAlert2Service.errorAlert(e.error.errors);
            }
        });
    }
    carregamentoConcluido($event) {
        this.isLoaded = $event;
    }
}
