export class Missao {
  constructor(
    public id: number,
    public nome: string,
    public regionalId: number,
    public regionalNome: string,
    public cidadeId: number,
    public cidadeNome: string,
    public cidadeEstadoId: number,
    public cidadeEstadoPaisId: number,
    public faseMissaoId: number,
    public faseMissaoNome: string,
    public dataFundacao: Date,
    public statusId: number,
    public statusNome: string,
    public centrosEvangelizacaoIds: Array<number>
  ) {
    this.centrosEvangelizacaoIds = new Array<number>();
  }
}
