import { Injectable } from '@angular/core';
import { Celibatario } from '../model/celibatario.model';
import { AbstractBuilder } from './abstract.builder';

@Injectable()
export class CelibatarioBuilder extends AbstractBuilder<Celibatario> {

    reset() : void {
        this.entity = new Celibatario(null,null,null,null);
    }

}