<secured>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h3 i18n>Família e Relacionamentos</h3>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="card card-primary card-outline card-outline-tabs">
                <div class="card card-default color-pallete-box">
                    <div class="card-header">
                        <h3 class="card-title">
                            <i class="fas fa-users"></i>
                            <a i18n> Família </a>
                        </h3>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-12 col-md-4 col-lg-4">
                                <div class="form-group">
                                    <label i18n >Nome completo do pai</label>
                                    <input name="nomePai" type="text" class="form-control" id="nomePai" i18n-placeholder>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-4 col-lg-4">
                                <div class="form-group">
                                    <label i18n >Nome completo da mãe</label>
                                    <input name="nomeMae" type="text" class="form-control" id="nomeMae" i18n-placeholder>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-4 col-lg-4">
                                <div class="form-group">
                                    <label i18n >Telefone da Família</label>
                                    <input name="telefoneFamilia" type="text" class="form-control" id="telefoneFamilia" i18n-placeholder>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 col-md-6 col-lg-6">
                                <div class="form-group">
                                    <label i18n >Endereço da Família</label>
                                    <input name="enderecoFamilia" type="text" class="form-control" id="enderecoFamilia" i18n-placeholder>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-6">
                                <div class="form-group">
                                    <label></label>
                                    <div class="checkbox icheck-concrete">
                                        <input type="checkbox" id="hasFilho" />&nbsp;
                                        <label class="form-check-label" for="hasFilho">Tem filhos?</label>
                                    </div>
                                </div>                                
                            </div>
                        </div>                    
                        <div class="row">
                            <div class="col-sm-12 col-md-6 col-lg-6">
                                <div class="form-group">
                                    <label>Estado de vida</label>
                                    <select class="form-control" (change)="setarEstadoVidaSelecionado()">
                                        <option value="0">Em discernimento</option>
                                        <option value="1">Solteiro</option>
                                        <option value="2">Solteiro (caminhando)</option>
                                        <option value="3">Solteiro (namorando)</option>
                                        <option value="4">Matrimônio</option>
                                        <option value="5">Celibato Formativo</option>
                                        <option value="6">Celibato Votos Temporário</option>
                                        <option value="7">Celibato Votos Perpétuos</option>
                                        <option value="8">Seminarista</option>
                                        <option value="9">Sacerdócio</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <!--div class="row" *ngIf="estadoVidaSelecionado != null && estadoVidaSelecionado.valor == 5"-->
                        <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-sm-12 col-md-4 col-lg-4">
                                                <div class="form-group">
                                                    <label>Nome do(a) caminhante ou namorado(a)</label>
                                                    <input type="text" class="form-control" />
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-4 col-lg-4">
                                                <div class="form-group">                                            
                                                    <label></label>
                                                    <div class="checkbox icheck-concrete">
                                                        <input type="checkbox" id="hasFilho" />&nbsp;
                                                        <label class="form-check-label" for="hasFilho">É da comunidade?</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-4 col-lg-4">
                                                <div class="form-group">
                                                    <label>Missão do(a) caminhante ou namorado(a)</label>
                                                    <input type="text" class="form-control" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--div class="row" *ngIf="formaVidaSelecionada != null && formaVidaSelecionada.valor == 3"-->
                        <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-sm-12 col-md-4 col-lg-4">
                                                <div class="form-group">
                                                    <label>Nome do cônjuge</label>
                                                    <input type="text" class="form-control" />
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-4 col-lg-4">
                                                <div class="form-group">                                            
                                                    <label></label>
                                                    <div class="checkbox icheck-concrete">
                                                        <input type="checkbox" id="hasFilho" />&nbsp;
                                                        <label class="form-check-label" for="hasFilho">É da comunidade?</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                        
                    </div>                        
                </div>
            </div>
        </section>
    </div>
</secured>