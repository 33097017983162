import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment';


@Injectable()
export class EsqueceuSenhaService {
    
    constructor(protected http: HttpClient, protected router:Router) {
    }

    public getHeaders() : HttpHeaders {
        return new HttpHeaders({
            'Content-Type' : 'application/json; charset=UTF-8'            
        }); 
    }

    atualizarSenha(obj: any) : Promise<any> {
        return this.http.post<any>(environment.esqueceuSenha.atualizarNovaSenha, JSON.stringify(obj), { 'headers' : this.getHeaders() }).toPromise();
    }

    esqueceuSenha(email:string): Promise<any> {
        return this.http.get<any>(environment.esqueceuSenha.recuperar + email).toPromise();
    }

}