import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UsuariosAssistenteApostolicoComponent } from './usuarios.aa.component';
import { UsuarioEditAssistenteApostolicoComponent } from './usuario.edit.aa.component';

const usuarioTutorRoutes: Routes = [
    {
        path: 'secured/local/aa/usuario/:id',
        component: UsuarioEditAssistenteApostolicoComponent
    },
    {
        path: 'secured/local/aa/usuarios',
        component: UsuariosAssistenteApostolicoComponent
    }
]

@NgModule({
    imports: [
        RouterModule.forChild(usuarioTutorRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class UsuarioAssistenteApostolicoRoutingModule {
    
}