import { Injectable } from '@angular/core';
import { Sacerdote } from '../model/sacerdote.model';
import { AbstractBuilder } from './abstract.builder';

@Injectable()
export class SacerdoteBuilder extends AbstractBuilder<Sacerdote> {

    reset() : void {
        this.entity = new Sacerdote(null,null,null,null);
    }

}