export class Escolaridade {

    constructor(
        public id: number,
        public aptidaoId: number,
        public nivelEscolaridadeId: number,
        public nivelEscolaridadeNome: string,
        public nomeGraduacao: string,
        public anoFormacao: number
    )
    {}

}