import { NgModule } from '@angular/core';
import { EnvioLocalComponent } from './envio.local.component';
import { Routes, RouterModule } from '@angular/router';
import { EnviosLocalComponent } from './envios.local.component';
import { EnvioLocalEditComponent } from './envio.local.edit.component';

const envioRoutes: Routes = [
    {
        path: 'secured/local/envio',
        component: EnvioLocalComponent
    },
    {
        path: 'secured/local/envios',
        component: EnviosLocalComponent
    },
    {
        path: 'secured/local/envio/:id',
        component: EnvioLocalEditComponent
    }
]

@NgModule({
    imports: [
        RouterModule.forChild(envioRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class EnvioRoutingModule {
    
}