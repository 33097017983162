import { Component, OnInit } from '@angular/core';
import { Alerta } from 'src/app/model/alerta.model';
import { PaisService } from '../pais/service/pais.service';
import { EstadoService } from '../estado/service/estado.service';
import { Estado } from 'src/app/model/estado.model';
import { Pais } from 'src/app/model/pais.model';
import { Cidade } from 'src/app/model/cidade.model';
import { CidadeService } from '../cidade/service/cidade.service';
import { RegionalService } from '../regional/service/regional.service';
import { MissaoService } from '../missao/service/missao.service';
import { Missao } from 'src/app/model/missao.model';
import { Regional } from 'src/app/model/regional.model';
import { CasaComunitaria } from 'src/app/model/casacomunitaria.model';
import { CasaComunitariaService } from './service/casacomunitaria.service';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { ElementoGrupoDominio } from 'src/app/model/elementogrupodominio.model';
import { ElementoGrupoDominioService } from '../grupodominio/service/elementogrupodominio.service';

@Component({
    selector: 'casascomunitaria',
    templateUrl: './casascomunitaria.component.html'
})
export class CasascomunitariaComponent implements OnInit {

    public estados : Array<Estado>;
    public paises : Array<Pais>;
    public cidades: Array<Cidade>;
    public regionais: Array<Regional>;
    public missoes: Array<Missao>;
    public casasComunitaria: Array<CasaComunitaria>;
    public statusCasaComunitaria: Array<ElementoGrupoDominio>;
    public busca: any;
    public totalDePaginas: number;

    constructor(
        public regionalService: RegionalService,
        public missaoService: MissaoService,
        public paisService: PaisService,
        public estadoService: EstadoService,
        public cidadeService: CidadeService,
        public casaComunitariaService: CasaComunitariaService,
        public elementoGrupoDominioService: ElementoGrupoDominioService,
        public swtAlert2Service: SwtAlert2Service
    ) 
    {
        this.estados = new Array<Estado>();
        this.paises = new Array<Pais>();
        this.cidades = new Array<Cidade>();
        this.regionais = new Array<Regional>();
        this.missoes = new Array<Missao>();
        this.casasComunitaria = new Array<CasaComunitaria>();   
        this.statusCasaComunitaria = new Array<ElementoGrupoDominio>();        
        this.busca = {            
            nome: null,
            ano: null,
            regionalId: null,
            missaoId: null,
            paisId: null,
            estadoId: null,
            cidadeId: null,
            statusId: null,
            pageNumber : 0, 
            quantityOfElements : 10
        };
    }

    ngOnInit() {
        this.carregarRegionais(); 
        this.carregarPaises(); 
        this.carregarStatusCasaComunitaria();
        this.buscarCasasComunitaria();
    }   

    cliqueNoBotaoListener(botao) {
        this.busca.pageNumber = botao.numero-1;
        this.buscarCasasComunitaria();
    }

    async carregarStatusCasaComunitaria() : Promise<null> {

        try{
            let request = {
                grupoNome : 'CASA_COMUNITARIA_STATUS'
            }
            let response = await this.elementoGrupoDominioService.buscarPorGrupoDominioNome(request);
            this.statusCasaComunitaria = response.entity;

        }
        catch(err){
            this.swtAlert2Service.errorAlert(err.error.errors);
        }

        return null;
    }

    carregarRegionais() : void {
        this.regionalService.findAll().then( (lista: any) => { 
            this.regionais = lista.entity;
        }).catch( (err: any) => {
            console.log(err);
        });
    }

    carregarMissoes(): void {
        this.missaoService.porRegional(this.busca.regionalId).then((response: any) => {
            this.missoes = response.entity;          
        }).catch((err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }

    carregarPaises() : void {
        this.paisService.findAll().then( (lista: any) => {
            this.paises = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

    carregarEstados() : void {

        let busca = {
            paisId: this.busca.paisId,
            nome: undefined,
            pageNumber : 0, 
            quantityOfElements : 1000
        };

        this.estadoService.buscarEstados(busca).then( (lista: any) => {
            this.estados = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });

    }

    carregarCidades() : void {

        let busca = {
            estadoId: this.busca.estadoId,
            nome: undefined,
            pageNumber : 0, 
            quantityOfElements : 1000
        };

        this.cidadeService.buscarCidades(busca).then( (lista: any) => {
            this.cidades = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });

    }

    buscarCasasComunitaria() : void {
        this.casaComunitariaService.buscarCasasComunitaria(this.busca).then( (response: any) => {
            this.casasComunitaria = response.entity;
            this.totalDePaginas = response.quantity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
        });
    }
    
    deletarCasaComunitaria(id: number) : void {

        if(confirm('Deseja deletar esta casa comunitaria?'))
        {
            this.casaComunitariaService.delete(id)
            .then( (response: any) => {
                this.swtAlert2Service.successAlert(response.message);
                this.buscarCasasComunitaria();
            })
            .catch((err) => {
                this.swtAlert2Service.errorAlert(err.error.errors);
            });
        }
    }
}