<secured>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h5 i18n><i class="fa fa-list"></i>&nbsp;&nbsp;&nbsp;Discernimentos</h5>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="container-fluid">
                <!-- <div class="row">
                    <div class="col-12">
                        Não há nenhum questionário disponível para informar parecer.
                    </div>
                </div> -->
                <div class="row">
                    <div class="col-md-12">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-12" style='text-align: center'>
                                    <table-component [cardBodyClass]="'table-responsive p-0'"
                                        [tableClass]="'table-striped'"
                                        [tituloSessao]="'Questionários disponíveis para informar parecer'" [cols]="11"
                                        [rows]="buscaMembros.quantityOfElements"
                                        [totalDePaginas]="totalDePaginasMembros"
                                        (eventoBotao)="cliqueNoBotaoListener($event)">
                                        <tr tableheader>
                                            <th>Foto de perfil</th>
                                            <th>Nome</th>
                                            <th>Nível Formativo</th>
                                            <th *ngIf="!formadorAssistente">Questionário - Formando</th>
                                            <th *ngIf="!formadorAssistente">Carta - Formando</th>
                                            <th>Parecer</th>
                                            <!--th *ngIf="questionarioConfiguracao?.status == 'EM_DISCERNIMENTO'">Status
                                                Questionario</th>
                                            <th *ngIf="questionarioConfiguracao?.status == 'EM_DISCERNIMENTO'">Parecer
                                            </th>
                                            <th *ngIf="questionarioConfiguracao?.status == 'DIVULGADO'">Feedback</th-->
                                        </tr>
                                        <tr tablebody *ngFor="let membro of membrosCelula">
                                            <td>
                                                <div class="widget-user-image">
                                                    <img class="profile-user-img img-fluid img-circle image-size-list elevation-2"
                                                        [src]="membro.thumbnail == null ? 'assets/images/ppl.png' : membro.thumbnail"
                                                        alt="User Avatar">
                                                </div>
                                            </td>
                                            <td>{{membro.pessoaNome}}</td>
                                            <td>{{membro.nivelFormativoNome}}</td>
                                            <td *ngIf="!formadorAssistente">
                                                <div
                                                    *ngIf="!membro.arquivo">
                                                    <button type="button" class="btn btn-info"
                                                        [disabled]="!membro.thumbnail || membro.isAutoridade"
                                                        (click)="configModalUploadQuestionario(membro)"
                                                        data-toggle="modal" data-target="#modal-upload-questionario">
                                                        Enviar Questionário do Formando
                                                    </button>
                                                    <p *ngIf="membro.thumbnail == null">Campo foto é obrigatório</p>
                                                </div>
                                                <!--button *ngIf="membro.statusQuestionario === 'ENVIADO'" type="button" class="btn btn-info"
                                                    (click)="visualizarQuestionario(membro.questionarioFormandoId)">
                                                    Visualizar
                                                </button-->
                                                <!-- <button *ngIf="membro.statusQuestionario === 'ENVIADO'" type="button"
                                                    class="btn btn-info" disabled>
                                                    Enviado em {{membro.dataEnvioQuestionario | date : 'dd/MM/yyyy'}}
                                                </button> -->
                                                <button *ngIf="membro.arquivo" type="button"
                                                    [disabled]="membro.isFC || !formadorAssistente"
                                                    class="btn btn-info" (click)="carregarArquivoPDF(membro.arquivo)"
                                                    data-toggle="modal" data-target="#modal-view-questionario">
                                                    Visualizar questionário
                                                </button>
                                                <p *ngIf="membro.isFC == 1">Acompanhado por outra autoridade</p>
                                            </td>
                                            <td *ngIf="!formadorAssistente">
                                                <div *ngIf="!membro.carta">
                                                    <button type="button" class="btn btn-info"
                                                        [disabled]="!membro.thumbnail || membro.isAutoridade"
                                                        (click)="configModalUploadCarta(membro)" data-toggle="modal"
                                                        data-target="#modal-upload-carta">
                                                        Enviar Carta do Formando
                                                    </button>
                                                    <p *ngIf="membro.thumbnail == null">Campo foto é obrigatório</p>
                                                </div>
                                                <button *ngIf="membro.carta" type="button"
                                                    [disabled]="membro.isFC" class="btn btn-info"
                                                    (click)="carregarCartaPDF(membro.carta)" data-toggle="modal"
                                                    data-target="#modal-view-carta">
                                                    Visualizar Carta
                                                </button>
                                                <p *ngIf="membro.isFC == 1">Acompanhado por outra autoridade</p>
                                            </td>
                                            <td>
                                                <p *ngIf="membro.statusParecerFC == 'ENVIADO'">
                                                    Enviado em {{membro.dataEnvioParecerFC | date : 'dd/MM/yyyy'}}
                                                </p>
                                                <button *ngIf="membro.statusParecerFC != 'ENVIADO'"
                                                    [disabled]="membro.isFC" class="btn btn-primary"
                                                    (click)="consultarVinculoQuestionarioCAL(membro)"
                                                    title="Parecer CAL" alt="Parecer CAL">Responder</button>
                                                <p *ngIf="membro.isFC == 1">Acompanhado por outra autoridade</p>
                                            </td>
                                            <!--td *ngIf="questionarioConfiguracao?.status == 'EM_DISCERNIMENTO'">
                                                <button *ngIf="membro.statusQuestionario == 'ENVIADO'"
                                                    class="btn btn-primary"
                                                    routerLink="/secured/questionariocal-view-answer/{{membro.idQuestionario}}"
                                                    title="Questionario CAL" alt="Questionario CAL">Visualizar</button>
                                                <button *ngIf="membro.statusQuestionario != 'ENVIADO'"
                                                    class="btn btn-primary" disabled title="Questionario CAL"
                                                    alt="Questionario CAL">{{membro.statusQuestionario}}</button>
                                            </td>
                                            <td *ngIf="questionarioConfiguracao?.status == 'EM_DISCERNIMENTO'">
                                                <button *ngIf="membro.statusParecer == 'ENVIADO'"
                                                    class="btn btn-primary" disabled title="Parecer CAL"
                                                    alt="Parecer CAL">Enviado</button>
                                                <button *ngIf="membro.statusParecer != 'ENVIADO'"
                                                    class="btn btn-primary"
                                                    (click)="consultarVinculoQuestionarioCAL(membro)"
                                                    title="Parecer CAL" alt="Parecer CAL">Responder</button>
                                            </td>
                                            <td *ngIf="questionarioConfiguracao?.status == 'DIVULGADO'">
                                                {{membro.caminhoFormativoFeedback ? membro.caminhoFormativoFeedback :
                                                'Feedback não informado'}}
                                            </td-->
                                        </tr>
                                    </table-component>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="modal fade" id="modal-upload-questionario" style="display: none;" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Enviar Questionário do Formando</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12">
                                <div class="card card-secondary">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-sm-12 col-md-12 col-lg-12">
                                                <label for="fileType">Qual pedido o seu formando está
                                                    fazendo?</label><br>
                                                <select class="form-control" [(ngModel)]="pedidoFormandoId"
                                                    name="pedidoFormandoId" (change)="onTipoPedidoChange()">
                                                    <option disabled value="null" i18n>Selecione</option>
                                                    <option value="1"
                                                        *ngIf="questionarioId == 6 || questionarioId == 7 || questionarioId == 8"
                                                        i18n>Mudança de Nível</option>
                                                    <option value="2"
                                                        *ngIf="questionarioId == 4 || questionarioId == 6 || questionarioId == 7"
                                                        i18n>Permanecer no Nível</option>
                                                    <option value="3" *ngIf="questionarioId == 4" i18n>Realizar
                                                        Primeiras Promessas</option>
                                                    <option value="4" *ngIf="questionarioId == 5" i18n>Realizar
                                                        Promessas Definitivas</option>
                                                    <option value="5" i18n>Desligamento CAL</option>
                                                    <option value="6" i18n>Outro</option>
                                                </select>

                                                <br>
                                                <input type="file" accept=".pdf" (change)="onFileSelected($event)" />
                                                <div *ngIf="errorMessage" class="error-message">
                                                    {{ errorMessage }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-footer text-right">
                                        <button type="button" class="btn btn-primary" (click)="onUpload()"
                                            [disabled]="!selectedFile">Enviar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer justify-content-right">
                        <button type="button" class="btn btn-default" data-dismiss="modal">Fechar</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="modal-upload-carta" style="display: none;" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title">Enviar Carta do Formando</h4>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12">
                                <div class="card card-secondary">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-sm-12 col-md-12 col-lg-12">
                                                <input type="file" accept=".pdf"
                                                    (change)="onCartaFileSelected($event)" />
                                                <div *ngIf="errorMessage" class="error-message">
                                                    {{ errorMessage }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-footer text-right">
                                        <button type="button" class="btn btn-primary" (click)="onCartaUpload()"
                                            [disabled]="!selectedFile">Enviar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer justify-content-right">
                        <button type="button" class="btn btn-default" data-dismiss="modal">Fechar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="modal-view-questionario" style="display: none;" aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Visualização do questionário</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <iframe *ngIf="sanitizedPdfUrl" [src]="sanitizedPdfUrl" width="100%" height="600px"
                        (contextmenu)="false;"></iframe>
                    <!-- <object [data]="sanitizedPdfUrl" type="application/pdf" width="100%" height="500px">
                        <p>Seu navegador não suporta exibição de PDFs.</p>
                    </object> -->

                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="modal-view-carta" style="display: none;" aria-hidden="true">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Visualização da carta</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <iframe *ngIf="sanitizedPdfCartaUrl" [src]="sanitizedPdfCartaUrl" width="100%" height="600px"
                        (contextmenu)="false;"></iframe>
                    <!-- <object [data]="sanitizedPdfUrl" type="application/pdf" width="100%" height="500px">
                        <p>Seu navegador não suporta exibição de PDFs.</p>
                    </object> -->

                </div>
            </div>
        </div>
    </div>
</secured>