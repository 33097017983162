import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { GrupoDeOracaoBuilder } from 'src/app/builder/grupodeoracao.builder';
export class GrupoDeOracaoEditComponent {
    constructor(grupoDeOracaoService, centroEvangelizacaoService, elementoGrupoDominioService, regionalService, missaoService, difusaoService, paisService, estadoService, cidadeService, route, router, swtAlert2Service) {
        this.grupoDeOracaoService = grupoDeOracaoService;
        this.centroEvangelizacaoService = centroEvangelizacaoService;
        this.elementoGrupoDominioService = elementoGrupoDominioService;
        this.regionalService = regionalService;
        this.missaoService = missaoService;
        this.difusaoService = difusaoService;
        this.paisService = paisService;
        this.estadoService = estadoService;
        this.cidadeService = cidadeService;
        this.route = route;
        this.router = router;
        this.swtAlert2Service = swtAlert2Service;
        this.grupodeoracaoBuilder = new GrupoDeOracaoBuilder();
        this.grupoDeOracao = this.grupodeoracaoBuilder.getInstance();
        this.centrosEvangelizacao = new Array();
        this.fases = new Array();
        this.publicos = new Array();
        this.regionais = new Array();
        this.missoes = new Array();
        this.difusoes = new Array();
        this.paises = new Array();
        this.estados = new Array();
        this.cidades = new Array();
    }
    ngOnInit() {
        this.grupoDeOracaoId = Number(this.route.snapshot.paramMap.get('id'));
        this.carregarFasesDoGrupoDeOracao();
        this.carregarPublicosDoGrupoDeOracao();
        this.carregarGrupoDeOracao(this.grupoDeOracaoId);
    }
    carregarRegionais() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.regionalService.findAll();
                this.regionais = response.entity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarMissoes(regionalSelecionadoId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.missaoService.porRegional(regionalSelecionadoId);
                this.missoes = response.entity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarDifusoes(regionalSelecionadoId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.difusaoService.porRegional(regionalSelecionadoId);
                this.difusoes = response.entity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarCevPorMissao(missaoId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.centroEvangelizacaoService.porMissao(missaoId);
                this.centrosEvangelizacao = response.entity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarCevPorDifusao(difusaoId) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.centroEvangelizacaoService.porDifusao(difusaoId);
                this.centrosEvangelizacao = response.entity;
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    carregarFasesDoGrupoDeOracao() {
        let buscaFase = {
            grupoNome: 'FASE_GRUPO_DE_ORACAO'
        };
        this.elementoGrupoDominioService.buscarPorGrupoDominioNome(buscaFase).then((lista) => {
            this.fases = lista.entity;
        }).catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }
    carregarPublicosDoGrupoDeOracao() {
        let buscaPublico = {
            grupoNome: 'PUBLICO_GRUPO_DE_ORACAO'
        };
        this.elementoGrupoDominioService.buscarPorGrupoDominioNome(buscaPublico).then((lista) => {
            this.publicos = lista.entity;
        }).catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }
    carregarGrupoDeOracao(id) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                let response = yield this.grupoDeOracaoService.find(id);
                this.grupoDeOracao = response.entity;
                yield this.carregarRegionais();
                if (this.grupoDeOracao.missaoId != null) {
                    yield this.carregarMissoes(this.grupoDeOracao.regionalId);
                    yield this.carregarCevPorMissao(this.grupoDeOracao.missaoId);
                }
                if (this.grupoDeOracao.difusaoId != null) {
                    yield this.carregarDifusoes(this.grupoDeOracao.regionalId);
                    yield this.carregarCevPorDifusao(this.grupoDeOracao.difusaoId);
                }
            }
            catch (err) {
                this.swtAlert2Service.errorAlert(err.error.errors);
            }
        });
    }
    salvar(grupoDeOracao) {
        //Apenas para atualizar os grupos já salvos que não possuem a entrada inserida
        if (grupoDeOracao.grupoEncerrado != true) {
            grupoDeOracao.grupoEncerrado = false;
        }
        this.grupoDeOracaoService.update(grupoDeOracao.id, grupoDeOracao).then((response) => {
            this.swtAlert2Service.successAlert(response.message);
            setTimeout(() => {
                this.router.navigate(['../secured/gruposdeoracao']);
            }, 2000);
        }).catch((err) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }
}
