import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { LeftSidebarAsideContainerComponent } from './components/left-sidebar-aside-container/left-sidebar-aside-container.component';
import { LeftSidebarMenuItemComponent } from './components/left-sidebar-menu-item/left-sidebar-menu-item.component';
import { LeftSidebarMenuComponent } from './components/left-sidebar-menu/left-sidebar-menu.component';
import { LeftSidebarUserPanelComponent } from './components/left-sidebar-user-panel/left-sidebar-user-panel.component';
import { LeftSidebarAssistenteFormacaoComponent } from './left-sidebar-assistente-formacao.component';

@NgModule({
  declarations: [
    LeftSidebarAssistenteFormacaoComponent,
    LeftSidebarAsideContainerComponent,
    LeftSidebarUserPanelComponent,
    LeftSidebarMenuComponent,
    LeftSidebarMenuItemComponent,
  ],
  imports: [CommonModule, RouterModule, RouterModule],
  exports: [
    LeftSidebarAssistenteFormacaoComponent,
    LeftSidebarAsideContainerComponent,
    LeftSidebarUserPanelComponent,
    LeftSidebarMenuComponent,
    LeftSidebarMenuItemComponent,
  ],
})
export class LeftSidebarAssistenteFormacaoModel {}
