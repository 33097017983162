import { Injectable } from '@angular/core';
import { TermoUso } from '../model/termouso.model';
import { AbstractBuilder } from './abstract.builder';

@Injectable()
export class TermoUsoBuilder extends AbstractBuilder<TermoUso> {

    reset() : void {
        this.entity = new TermoUso(null,null,null,null);
    }

}