import { Component, OnDestroy, OnInit } from '@angular/core';
import { CoordenadorColegiado } from 'src/app/model/coordenadorcolegiado.model';
import { Pessoa } from 'src/app/model/pessoa.model';
import { MissaoConfiguracaoSharedDataService } from '../../service/missao-configuracao-shared-data.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MissaoConfiguracao } from '../../service/missao-config.service';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { CoordenadorColegiadoService } from '../../../colegiado/coordenadorcolegiado.service';
import { ImagemService } from 'src/app/secured/wop/imagem/service/imagem.service';

@Component({
  selector: 'app-cadastro-coordenador-colegiado',
  templateUrl: './cadastro-coordenador-colegiado.component.html',
  styleUrls: ['./cadastro-coordenador-colegiado.component.css'],
})
export class CadastroCoordenadorColegiadoComponent
  implements OnInit, OnDestroy
{
  unsubscribe$ = new Subject<null>();
  coordenadorColegiado: CoordenadorColegiado = {} as CoordenadorColegiado;
  pessoaCoordenadorColegiadoSelecionado: Pessoa;
  missaoConfiguracao: MissaoConfiguracao;

  constructor(
    private coordenadorColegiadoService: CoordenadorColegiadoService,
    private missaoConfiguracaoSharedDataService: MissaoConfiguracaoSharedDataService,
    private imagemService: ImagemService,
    private swtAlert: SwtAlert2Service
  ) {}

  ngOnInit() {
    this.missaoConfiguracaoSharedDataService.missaoConfiguracao$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (missaoConfiguracao) => {
          if (missaoConfiguracao) {
            this.missaoConfiguracao = missaoConfiguracao;
            this.carregarCoordenadorColegiadoPorMissaoConfiguracaoId(
              missaoConfiguracao.id
            );
          }
        },
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  async selecionarCoordenadorColegiado(pessoa): Promise<any> {
    this.coordenadorColegiado.pessoaId = pessoa.id;
    this.pessoaCoordenadorColegiadoSelecionado = pessoa;
    $('#listagem-pessoa-coordenador-colegiado').modal('hide');
  }

  async adicionarCoordenadorColegiado(): Promise<any> {
    try {
      this.coordenadorColegiado.missaoConfiguracaoId =
        this.missaoConfiguracao.id;
      const response = await this.coordenadorColegiadoService
        .cadastrar(this.coordenadorColegiado)
        .toPromise();
      this.coordenadorColegiado = response;
      this.pessoaCoordenadorColegiadoSelecionado = undefined;
      this.swtAlert.successAlert(
        'Coordenador de Colegiado cadastrado com sucesso!'
      );
    } catch (err) {
      this.swtAlert.errorAlert(err.error.errors);
    }
  }

  async carregarCoordenadorColegiadoPorMissaoConfiguracaoId(
    id: number
  ): Promise<any> {
    try {
      const response = await this.coordenadorColegiadoService
        .obterPorMissaoConfiguracaoId(id)
        .toPromise();
      if (response) {
        this.coordenadorColegiado = response;
        if (this.coordenadorColegiado.imagemId != null) {
          let responseImage = await this.imagemService.getPessoaPhoto(
            this.coordenadorColegiado.imagemId
          );
          let blogImage = responseImage.body;
          this.imagemService.createImage(blogImage, this.coordenadorColegiado);
        }
      }
    } catch (err) {
      this.swtAlert.errorAlert(err.error.errors);
    }
  }

  async removerCoordenadorColegiado(
    coordenadorColegiadoId: number
  ): Promise<any> {
    if (confirm('Deseja remover o coordenador de colegiado?')) {
      try {
        await this.coordenadorColegiadoService
          .remover(coordenadorColegiadoId)
          .toPromise();
        this.swtAlert.successAlert(
          'Coordenador de Colegiado removido com sucesso!'
        );
        this.coordenadorColegiado = {} as CoordenadorColegiado;
      } catch (err) {
        this.swtAlert.errorAlert(err.error.errors);
      }
    }
  }
}
