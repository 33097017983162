export class Difusao {

    constructor(
        public id: number,
        public nome: string,
        public regionalId: number,
        public cidadeId: number,
        public cidadeEstadoId: number,
        public cidadeEstadoPaisId: number,
        public faseDifusaoId: number,
        public etapaDifusaoId: number,
        public dataFundacao: Date,
        
    ){
        
    }

}