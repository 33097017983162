export class AceiteTermo {
    
    constructor(
        public id: number,
        public usuarioId: number,
        public usuarioUsername: string,
        public termoUsoId: number,
        public dataAceiteTermo: Date
    )
    {}

}