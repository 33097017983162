import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';

@Component({
    selector: 'confirmacaoCadastro',
    templateUrl: './confirmacao.cadastro.component.html'
})
export class ConfirmacaoCadastroComponent implements OnInit {

    ngOnInit(): void {
        
    }

    constructor(
        protected router: Router,
        public swtAlert2Service: SwtAlert2Service
    ) {
    }
}