import { Component, OnInit } from '@angular/core';
import { CentroEvangelizacao } from 'src/app/model/centroevangelizacao.model';
import { ElementoGrupoDominio } from 'src/app/model/elementogrupodominio.model';
import { ElementoGrupoDominioService } from '../grupodominio/service/elementogrupodominio.service';
import { MissaoService } from '../missao/service/missao.service';
import { RegionalService } from '../regional/service/regional.service';
import { Missao } from 'src/app/model/missao.model';
import { Regional } from 'src/app/model/regional.model';
import { GrupoDeOracao } from 'src/app/model/grupodeoracao.model';
import { GrupoDeOracaoService } from './grupodeoracao.service';
import { CentroEvangelizacaoService } from '../centroevangelizacao/service/centroevangelizacao.service';
import { GrupoDeOracaoBuilder } from 'src/app/builder/grupodeoracao.builder';
import { SwtAlert2Service } from 'src/app/core/swtalert2/swtalert2.service';
import { Difusao } from 'src/app/model/difusao.model';
import { DifusaoService } from '../difusao/service/difusao.service';

@Component({
    selector: 'grupodeoracao',
    templateUrl: './grupodeoracao.component.html'
})
export class GrupoDeOracaoComponent implements OnInit {

    public grupoDeOracao: GrupoDeOracao;
    public fases : Array<ElementoGrupoDominio>; 
    public publicos : Array<ElementoGrupoDominio>; 
    public missoes : Array<Missao>;
    public difusoes : Array<Difusao>;
    public centroEvangelizacaoId : number;
    public regionais: Array<Regional>;
    public centrosEvangelizacao: Array<CentroEvangelizacao>;
    public grupodeoracaoBuilder: GrupoDeOracaoBuilder;

    constructor(
        private grupoDeOracaoService: GrupoDeOracaoService,
        private centroEvangelizacaoService: CentroEvangelizacaoService,
        private elementoGrupoDominioService: ElementoGrupoDominioService,
        private regionalService: RegionalService,
        private missaoService: MissaoService,
        private difusaoService: DifusaoService,
        public swtAlert2Service: SwtAlert2Service
    ) 
    {
        this.grupodeoracaoBuilder = new GrupoDeOracaoBuilder();
        this.grupoDeOracao = this.grupodeoracaoBuilder.getInstance();
        this.fases = new Array<ElementoGrupoDominio>();
        this.publicos = new Array<ElementoGrupoDominio>();
        this.regionais = new Array<Regional>();
        this.missoes = new Array<Missao>();
        this.difusoes = new Array<Difusao>();
        this.centrosEvangelizacao = new Array<CentroEvangelizacao>();
    }

    ngOnInit() { 
        this.carregarFasesDoGrupoDeOracao(); 
        this.carregarPublicosDoGrupoDeOracao();  
        this.carregarRegionais();
    }

    async carregarRegionais() : Promise<any> {

        try 
        {
            let response = await this.regionalService.findAll();
            this.regionais = response.entity;
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }

    }

    async carregarMissoes(regionalSelecionadoId : number): Promise<any> {

        try 
        {
            let response = await this.missaoService.porRegional(regionalSelecionadoId);
            this.missoes = response.entity;
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }

    }

    async carregarDifusoes(regionalSelecionadoId : number): Promise<any> {

        try 
        {
            let response = await this.difusaoService.porRegional(regionalSelecionadoId);
            this.difusoes = response.entity;
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }

    }

    carregarFasesDoGrupoDeOracao() : void {

        let buscaFase = {
            grupoNome : 'FASE_GRUPO_DE_ORACAO'
        }

        this.elementoGrupoDominioService.buscarPorGrupoDominioNome(buscaFase).then( (lista: any) => {
            this.fases = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });

    }
   
    carregarPublicosDoGrupoDeOracao() : void {

        let buscaPublico = {
            grupoNome : 'PUBLICO_GRUPO_DE_ORACAO'
        }

        this.elementoGrupoDominioService.buscarPorGrupoDominioNome(buscaPublico).then( (lista: any) => {
            this.publicos = lista.entity;
        }).catch( (err: any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });

    }

    async carregarCevPorMissao(missaoId : number): Promise<any> {

        try 
        {
            let response = await this.centroEvangelizacaoService.porMissao(missaoId);
            this.centrosEvangelizacao = response.entity;
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }

    }
 
    async carregarCevPorDifusao(difusaoId : number): Promise<any> {

        try 
        {
            let response = await this.centroEvangelizacaoService.porDifusao(difusaoId);
            this.centrosEvangelizacao = response.entity;
        } 
        catch (err) 
        {
            this.swtAlert2Service.errorAlert(err.error.errors);
        }

    }

    salvar(grupoDeOracao: GrupoDeOracao) : void {

        grupoDeOracao.dataFundacao = new Date(grupoDeOracao.dataFundacao);
        this.grupoDeOracaoService.create(grupoDeOracao).then( (response:any) => {
            this.grupoDeOracao = this.grupodeoracaoBuilder.getInstance();
            this.swtAlert2Service.successAlert(response.message);

        }).catch( (err:any) => {
            this.swtAlert2Service.errorAlert(err.error.errors);
            console.log(err);
        });
    }

}