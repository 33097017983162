<secured>
    <div class="content-wrapper">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-12">
                        <h3 i18n>Parecer do Formador Comunitário 1º Questionario | Pastoreio | CAL</h3>
                    </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="card card-primary card-outline card-outline-tabs">
                <div class="card-header p-0 border-bottom-0">
                    <ul class="nav nav-tabs" id="tabs-questionario-form" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" id="dados-pessoais-tab" data-toggle="pill"
                                href="#dados-pessoais-tab-content" role="tab" aria-controls="dados-pessoais-tab"
                                aria-selected="true">Dados Pessoais</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="questionario-tab" data-toggle="pill"
                                href="#questionario-tab-content" role="tab" aria-controls="questionario-tab"
                                aria-selected="false">Questionário</a>
                        </li>
                    </ul>
                </div>
                <div class="card-body">
                    <div class="tab-content" id="tabs-pessoa-formContent">
                        <div class="tab-pane fade show active" id="dados-pessoais-tab-content" role="tabpanel"
                            aria-labelledby="dados-pessoais-tab">
                            <form #dadosPessoaisForm="ngForm" (ngSubmit)="salvar()" novalidate>
                                <div class="row">
                                    <div *ngIf="(formando.id != undefined)" class="col-sm-3 col-md-3 col-lg-3">
                                        <div style="padding: 1rem 0rem 1rem 1rem;">
                                            <div class="card card-default">
                                                <div class="card-body">
                                                    <div class="text-center" style="margin: 1rem auto 2rem;">
                                                        <div class="widget-user-image">
                                                            <img class="profile-user-img img-fluid img-circle elevation-2"
                                                                [src]="formando.thumbnail == null ? 'assets/images/ppl.png' : formando.thumbnail"
                                                                alt="Imagem da pessoa" style="width: auto;">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        [ngClass]="(formando.id != undefined) ? 'col-sm-8 col-md-9 col-lg-9' : 'col-sm-12 col-md-12 col-lg-12'">
                                        <div style="padding: 1rem;">
                                            <div class="card card-default">
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-sm-12 col-md-12 col-lg-12">
                                                            <div class="form-group">
                                                                <label i18n for="nformando_nome">Nome</label>
                                                                <br>
                                                                {{formando.nome}} {{formando.sobrenome}}
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-6 col-lg-6">
                                                            <div class="form-group">
                                                                <label i18n for="nformando_missao_atual">Missão
                                                                    Atual</label>
                                                                <select disabled class="form-control"
                                                                    id="nformando_missao_atual"
                                                                    [(ngModel)]="formando.vinculoMissao.missaoAtualId"
                                                                    name="nformando_missao_atual">
                                                                    <option disabled value="null" i18n>Selecione uma
                                                                        missão</option>
                                                                    <option *ngFor="let missao of missoesVinculo"
                                                                        [value]="missao.id">{{missao.nome}}</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-8">
                                                            <div class="form-group">
                                                                <label for="form_pessoal">Formador Pessoal</label>
                                                                <span class="form-control"
                                                                    name="form_pessoal">{{pessoaFormadorPessoalSelecionado?.nome}}
                                                                    {{pessoaFormadorPessoalSelecionado?.sobrenome}}</span>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                                            <div class="form-group">
                                                                <label i18n for="nform_pessoais">Nº de Formações
                                                                    Pessoais</label>
                                                                <span class="form-control"
                                                                    id="nform_pessoais">{{caminhoFormativoFormando.qtde_formacao_pessoal}}</span>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-6 col-lg-6">
                                                            <div class="form-group">
                                                                <label i18n for="nacomp_comunitario">Há quanto tempo ele
                                                                    é seu Formador Pessoal</label>
                                                                <br>
                                                                Onde esse dado será salvo, definir???
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-6 col-lg-6">
                                                            <div class="form-group">
                                                                <label i18n for="nform_pessoais">Qual a sua situação
                                                                    profissional?</label>
                                                                <select disabled class="form-control"
                                                                    id="nsituacao_profissional"
                                                                    name="nsituacao_profissional"
                                                                    [(ngModel)]="dadoFormandoCA.situacao_profissional">
                                                                    <option value="null" i18n>Nenhuma das opções
                                                                    </option>
                                                                    <option value="1">Trabalha</option>
                                                                    <option value="2">Estuda</option>
                                                                    <option value="3">Aposentado</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <label for="form_comunitario">Formador
                                                                    Comunitário</label>
                                                                <br>
                                                                {{caminhoFormativoFormando.nome_formador_comunitario}}
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <label for="form_comunitario">E-mail do Formador
                                                                    Comunitário</label>
                                                                <br>
                                                                {{usuarioAtual?.email}}
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class="form-group">
                                                                <label for="form_comunitario">Telefone do Formador
                                                                    Comunitário (WhatsApp):</label>
                                                                <input value="" name="form_comunitario" type="text"
                                                                    class="form-control" id="form_comunitario"
                                                                    placeholder="Digite..." i18n-placeholder required
                                                                    disabled>
                                                                <!--span class="form-control"
                                                                    name="form_comunitario">{{pessoaFormadorComunitarioSelecionado.nome}}
                                                                    {{pessoaFormadorComunitarioSelecionado.sobrenome}}</span-->
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-4 col-lg-4">
                                                            <div class="form-group">
                                                                <label i18n for="nacomp_comunitario">Nº de
                                                                    Acompanhamentos Comunitários</label>
                                                                <br>
                                                                {{caminhoFormativoFormando.qtde_acompanhamento_comunitario}}
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-6 col-lg-6">
                                                            <div class="form-group">
                                                                <label i18n for="npessoa_sobrenome">Exerce função de
                                                                    autoridade apostolica? Qual?</label>
                                                                <br>
                                                                {{caminhoFormativoFormando.funcao_autoridade_apostolica}}
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-12 col-md-6 col-lg-6">
                                                            <div class="form-group">
                                                                <label i18n for="npessoa_sobrenome">Exerce função de
                                                                    autoridade apostolica? Qual?</label>
                                                                <br>
                                                                {{caminhoFormativoFormando.funcao_autoridade_apostolica}}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card-footer text-right">
                                                    <button type="button" class="btn btn-default"
                                                        (click)="activateTab('questionario-tab-content')"
                                                        *ngIf="formando.id != undefined">Proximo</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="tab-pane fade" id="questionario-tab-content" role="tabpanel"
                            aria-labelledby="questionario-tab">

                            <div class="row">
                                <div class="col-sm-12 col-md12 col-lg-12">
                                    <h4 class="card-title">A partir de agora responda com sinceridade e
                                        transparência as seguintes perguntas, ajudando a
                                        Comunidade na formação dos irmãos da CAL.
                                    </h4>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md12 col-lg-12">
                                    <div class="form-group">
                                        <label for="descricao">1. O Seu formando recebeu o feedback do pastoreio de
                                            2023?</label>
                                        <select class="form-control" id="nresposta21" name="nresposta21"
                                            [(ngModel)]="resposta21.resposta" disabled>
                                            <option value="1" i18n>Sim</option>
                                            <option value="2">Não</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md12 col-lg-12">
                                    <div class="form-group">
                                        <label for="descricao">2. Como você percebe que o seu formando recebeu o
                                            feedback de pastoreio? Como você acredita que ele se sentiu?</label>
                                        <select class="form-control" id="nresposta22" name="nresposta22"
                                            [(ngModel)]="resposta22.resposta" disabled>
                                            <option value="1" i18n>Acredito que se sentiu bem,
                                                conhecido pela comunidade e concordou com os
                                                pontos que precisa trabalhar.</option>
                                            <option value="2">Acredito que ele ficou surpreso,
                                                não esperava. Mas acolheu bem, concordou com
                                                o que foi dito.
                                            </option>
                                            <option value="3">Acredito que não se sentiu confortável
                                                com o que recebeu, não se percebe da forma como
                                                foi dito.
                                            </option>
                                            <option value="4">Acredito que concordou apenas parcialmente.
                                                Percebo que para ele foi dificil acolher.</option>
                                            <option value="5">Eu não consegui dar o feedback do meu
                                                formando.</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md12 col-lg-12">
                                    <div class="form-group">
                                        <label for="descricao">3. Você acredita que o teu formando se sente ajudado
                                            no seu caminho de formação?</label>
                                        <select class="form-control" id="nresposta23" name="nresposta23"
                                            [(ngModel)]="resposta23.resposta" disabled>
                                            <option value="1" i18n>Sim, percebo que ele se sente ajudado
                                                pelas suas autoridades.</option>
                                            <option value="2">Acredito que ele se sente ajudado,
                                                mas precisa buscar mais ajuda, pedir,
                                                se expressar mais..
                                            </option>
                                            <option value="3">Acredito que ele não se sente ajudado,
                                                penso que ele evita buscar ajuda.
                                            </option>
                                            <option value="4">Acredito que ele não se sente ajudado.</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md12 col-lg-12">
                                    <div class="form-group">
                                        <label for="descricao">4. Em que aspectos você acredita que o teu formando
                                            tem mais necessidade de ser ajudado?</label>
                                        <span class="form-control" style="height: fit-content;" disabled>
                                            {{resposta24.resposta?.trim()}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                    <div class="form-group">
                                        <label for="descricao">5. Como você percebe a sua vivência dos conselhos
                                            evangelicos:</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md12 col-lg-12">
                                    <div class="form-group">
                                        <label for="descricao">5.1 Como você percebe a sua vivência da
                                            pobreza?</label>
                                        <select class="form-control" id="nresposta25" name="nresposta25"
                                            [(ngModel)]="resposta25.resposta" disabled>
                                            <option value="1" i18n>Acredito que vive bem.</option>
                                            <option value="2">Acredito que vive bem, mas ainda
                                                precisa crescer em muitos aspectos.
                                            </option>
                                            <option value="3">Sinto que preciso ajudá-lo para
                                                que possa dar mais passos.
                                            </option>
                                            <option value="4">Acredito que precisa crescer no
                                                seu caminho de conversão e adesão.</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md12 col-lg-12">
                                    <div class="form-group">
                                        <label for="descricao">5.2 Como você percebe a sua vivência da
                                            castidade?</label>
                                        <select class="form-control" id="nresposta26" name="nresposta26"
                                            [(ngModel)]="resposta26.resposta" disabled>
                                            <option value="1" i18n>Acredito que vive bem.</option>
                                            <option value="2">Acredito que vive bem, mas ainda
                                                precisa crescer em muitos aspectos.
                                            </option>
                                            <option value="3">Sinto que preciso ajudá-lo para
                                                que possa dar mais passos.
                                            </option>
                                            <option value="4">Acredito que precisa crescer no
                                                seu caminho de conversão e adesão.</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md12 col-lg-12">
                                    <div class="form-group">
                                        <label for="descricao">5.3 Como você percebe a sua vivência da obediência?
                                        </label>
                                        <select class="form-control" id="nresposta27" name="nresposta27"
                                            [(ngModel)]="resposta27.resposta" disabled>
                                            <option value="1" i18n>Acredito que vive bem.</option>
                                            <option value="2">Acredito que vive bem, mas ainda
                                                precisa crescer em muitos aspectos.
                                            </option>
                                            <option value="3">Sinto que preciso ajudá-lo para
                                                que possa dar mais passos.
                                            </option>
                                            <option value="4">Acredito que precisa crescer no
                                                seu caminho de conversão e adesão.</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md12 col-lg-12">
                                    <div class="form-group">
                                        <label for="descricao">5.4 Ainda sobre os conselhos evangelicos, existe algo
                                            que você gostaria de partilhar?</label>
                                        <span class="form-control" style="height: fit-content;" disabled>
                                            {{resposta28.resposta?.trim()}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md12 col-lg-12">
                                    <div class="form-group">
                                        <label for="descricao">6. Como você se vê na vivência do tripé da vocação?
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md12 col-lg-12">
                                    <div class="form-group">
                                        <label for="descricao">6.1 Como você se percebe na vivencia da sua vida de
                                            contemplação, intimidade com Deus?
                                        </label>
                                        <select class="form-control" id="nresposta29" name="nresposta29"
                                            [(ngModel)]="resposta29.resposta" disabled>
                                            <option value="1" i18n>Acredito que vive bem.</option>
                                            <option value="2">Acredito que vive bem, mas ainda
                                                precisa crescer em muitos aspectos.
                                            </option>
                                            <option value="3">Sinto que preciso ajudá-lo para
                                                que possa dar mais passos.
                                            </option>
                                            <option value="4">Acredito que não tem conseguido
                                                ser fiel à sua vida de contemplação.</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md12 col-lg-12">
                                    <div class="form-group">
                                        <label for="descricao">6.2 Como você se percebe na tua vivencia apostolica?
                                        </label>
                                        <select class="form-control" id="nresposta30" name="nresposta30"
                                            [(ngModel)]="resposta30.resposta" disabled>
                                            <option value="1" i18n>Acredito que vive bem.</option>
                                            <option value="2">Acredito que vive bem, mas ainda
                                                precisa crescer em muitos aspectos.
                                            </option>
                                            <option value="3">Sinto que preciso ajudá-lo para
                                                que possa dar mais passos.
                                            </option>
                                            <option value="4">Acredito que não tem conseguido se
                                                dedicar aos seus compromissos apostólicos.</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md12 col-lg-12">
                                    <div class="form-group">
                                        <label for="descricao">6.3 Como você se percebe na vivencia fraterna, na
                                            unidade?
                                        </label>
                                        <select class="form-control" id="nresposta31" name="nresposta31"
                                            [(ngModel)]="resposta31.resposta" disabled>
                                            <option value="1" i18n>Acredito que vive bem.</option>
                                            <option value="2">Acredito que vive bem, mas ainda
                                                precisa crescer em muitos aspectos.
                                            </option>
                                            <option value="3">Sinto que preciso ajudá-lo para
                                                que possa dar mais passos.
                                            </option>
                                            <option value="4">Acredito que não tem conseguido
                                                viver bem a sua vida fraterna.</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md12 col-lg-12">
                                    <div class="form-group">
                                        <label for="descricao">7. De modo geral, Como você percebe o caminho deste
                                            formando?
                                        </label>
                                        <select class="form-control" id="nresposta32" name="nresposta32"
                                            [(ngModel)]="resposta32.resposta" disabled>
                                            <option value="1" i18n>Acredito que tem vivido bem.
                                            </option>
                                            <option value="2">Acredito que precisa de um pastoreio
                                                mais próximo.
                                            </option>
                                            <option value="3">Acredito que precisa de intervenção
                                                mais direta.
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md12 col-lg-12">
                                    <div class="form-group">
                                        <label for="descricao">8. Qual o caminho formativo você sugere para este
                                            formando?
                                        </label>
                                        <span class="form-control" style="height: fit-content;" disabled>
                                            {{caminhoFormativoFormando.caminhoFormativoSugeridoFC?.trim()}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md12 col-lg-12">
                                    <div class="form-group">
                                        <label for="descricao">9. Com relação ao exercicio da sua função na vida
                                            deste irmão, como você se vê?
                                        </label>
                                        <select class="form-control" id="nresposta34" name="nresposta34"
                                            [(ngModel)]="resposta34.resposta" disabled>
                                            <option value="1" i18n>Acredito que tenho conseguido
                                                conduzir bem.
                                            </option>
                                            <option value="2">Sinto que preciso de ajuda na condução
                                                do processo deste irmão.
                                            </option>
                                            <option value="3">É um caso desafiante, mas tenho recebido
                                                ajuda necessária.
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md12 col-lg-12">
                                    <div class="form-group">
                                        <label for="descricao">Existe algo mais que você gostaria de expressar sobre
                                            a vivência deste irmão? Partilhe livremente.
                                        </label>
                                        <span class="form-control" style="height: fit-content;" disabled>
                                            {{resposta35.resposta?.trim()}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer text-right">
                                <button type="button" class="btn btn-default"
                                    (click)="activateTab('dados-pessoais-tab-content')" i18n>Voltar</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <!-- /.modal-dialog -->
</secured>