import { NgModule } from '@angular/core';
import { GrupoDominioComponent } from './grupodominio.component';
import { Routes, RouterModule } from '@angular/router';
import { GruposDominioComponent } from './gruposdominio.component';
import { GrupoDominioEditComponent } from './grupodominio.edit.component';

const grupoDominioRoutes: Routes = [
    {
        path: 'secured/grupodominio',
        component: GrupoDominioComponent
    },
    {
        path: 'secured/gruposdominio',
        component: GruposDominioComponent
    },
    {
        path: 'secured/grupodominio/:id',
        component: GrupoDominioEditComponent
    }
]

@NgModule({
    imports: [
        RouterModule.forChild(grupoDominioRoutes)
    ],
    exports: [
        RouterModule
    ]
})
export class GrupoDominioRoutingModule {
    
}