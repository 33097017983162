import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SecuredModule } from '../../layout/secured.module';
import { RouterModule } from '@angular/router';
import { MinisterioComponent } from './ministerio.component';
import { MinisteriosComponent } from './ministerios.component';
import { MinisterioEditComponent } from './ministerio.edit.component';
import { MinisterioService } from './service/ministerio.service';
import { MinisterioRoutingModule } from './ministerio-routing.module';



@NgModule({
    declarations: [ 
        MinisterioComponent,
        MinisteriosComponent,
        MinisterioEditComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        MinisterioRoutingModule,
        FormsModule,
        SecuredModule
    ],
    providers: [
        MinisterioService
    ]
})
export class MinisterioModule {

}